import { FC } from 'react';

import { Create } from '@shared';

import { useJiraIntegrationCreate } from '../../../../lib';
import { JiraIntegrationCreateLeftForm } from '../JiraIntegrationCreateLeftForm';
import { JiraIntegrationCreateRightForm } from '../JiraIntegrationCreateRightForm';

import styles from './JiraIntegrationCreate.module.scss';

interface JiraIntegrationCreateProps {
  isModal: boolean;
  toggleModal(): void;
}

export const JiraIntegrationCreate: FC<JiraIntegrationCreateProps> = ({
  isModal,
  toggleModal,
}) => {
  const form = useJiraIntegrationCreate({ toggleModal });

  const { disabledSubmit } = form.state;

  const { createJiraIntegrationHandler } = form.methods;

  return (
    <Create
      toggleModal={toggleModal}
      isModal={isModal}
      title="Интеграция"
      createTitle="Создать"
      disabledSubmit={disabledSubmit}
      subModalText="создание интеграции"
      className={styles.jiraIntegrationCreate}
      onSubmit={createJiraIntegrationHandler}
    >
      <div className={styles.jiraIntegrationCreate__wrapper}>
        <JiraIntegrationCreateLeftForm form={form} />
        <JiraIntegrationCreateRightForm form={form} />
      </div>
    </Create>
  );
};
