import { useJiraIntegrationCreate } from '../lib';

export enum JiraIntegrationTabType {
  integration = 'integration',
  synchronization = 'synchronization',
}

export type JiraIntegrationCreateHookType = ReturnType<
  typeof useJiraIntegrationCreate
>;
