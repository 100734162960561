import { Reducer } from 'redux';

import { Organization } from '@entities/organization';
import {
  DEFAULT_PAGINATION_PARAMS,
  PaginationType,
  ReducerMap,
  SelectOption,
} from '@shared';

import {
  Article,
  CategoryArticle,
  KBArticleTableFilter,
  KBArticleTableFilterToRequest,
} from '../types';

import {
  ArticlesTable,
  ArticlesTableActionsTypes,
  FetchArticlesJoinSuccessRightArticlesTableAction,
  FetchArticlesSuccessRightArticlesTableAction,
  FetchArticlesTableCategorySuccessAction,
  FetchArticlesTableSuccessAction,
  FetchCategoriesSuccessRightArticlesTableAction,
  FetchOrganizationsSuccessArticleTableAction,
  FetchOrganizationsSuccessRightArticlesTableAction,
  SetArticlesFilterRightArticlesTableAction,
  SetArticlesPageRightArticlesTableAction,
  SetArticlesTableFilterAction,
  SetCurrentArticlesTablePageAction,
  SetCurrentArticleTableIdAction,
  SetSearchValueRightArticlesTableAction,
  SetSelectedOrganizationRightArticlesTableAction,
  SetSizePageAction,
  SetSortArticlesTableAction,
} from './actionTypes';
import { KBDataTable } from './types';

export interface ArticlesTableReducerState {
  articles?: Article[];
  loading?: boolean;
  pagination?: PaginationType;
  sort?: string;
  article?: KBDataTable;
  articlesFilter?: KBArticleTableFilter;
  currentCategory?: CategoryArticle;
  organizations?: Organization[];

  articlesFilterRightTable?: KBArticleTableFilterToRequest;
  articlesRightTable?: Article[];
  loadingArticlesRightTable?: boolean;
  loadingOrganizationsRightTable?: boolean;
  articlesPaginationRightTable?: PaginationType;
  organizationsRightTable?: Organization[];
  organizationSelected?: SelectOption | null;
  searchValue?: string;
  categoriesRightTable?: CategoryArticle[];
  categoriesLoadingRightTable?: boolean;
}

const initialState: ArticlesTableReducerState = {
  articles: [],
  loading: false,
  pagination: DEFAULT_PAGINATION_PARAMS,
  sort: 'datecreate_DESC',
  articlesFilter: {},
  organizations: [],

  organizationsRightTable: [],
  loadingArticlesRightTable: false,
  loadingOrganizationsRightTable: false,
  articlesRightTable: [],
  articlesPaginationRightTable: {
    ...DEFAULT_PAGINATION_PARAMS,
    pageSize: 20,
  },
  organizationSelected: null,
  categoriesRightTable: [],
  categoriesLoadingRightTable: false,
};

const articlesTableReducerMap: ReducerMap<
  ArticlesTableReducerState,
  ArticlesTableActionsTypes
> = {
  [ArticlesTable.FETCH_ARTICLES_SUCCESS_ARTICLES_TABLE]: (state, action) => {
    const { payload } = action as FetchArticlesTableSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      articles: content,
      pagination: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [ArticlesTable.FETCH_CATEGORY_SUCCESS_ARTICLES_TABLE]: (state, action) => {
    const { payload } = action as FetchArticlesTableCategorySuccessAction;
    return {
      ...state,
      currentCategory: payload,
    };
  },
  [ArticlesTable.ARTICLES_TABLE_LOADING_SHOW]: (state) => ({
    ...state,
    loading: true,
  }),
  [ArticlesTable.ARTICLES_TABLE_LOADING_HIDE]: (state) => ({
    ...state,
    loading: false,
  }),

  [ArticlesTable.SET_CURRENT_ARTICLES_PAGE_ARTICLES_TABLE]: (state, action) => {
    const { payload } = action as SetCurrentArticlesTablePageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageNum: payload,
      },
    };
  },
  [ArticlesTable.SET_SIZE_PAGE_ARTICLES_ARTICLES_TABLE]: (state, action) => {
    const { payload } = action as SetSizePageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageSize: payload,
      },
    };
  },

  [ArticlesTable.SET_SORT_ARTICLES_ARTICLES_TABLE]: (state, action) => {
    const { payload } = action as SetSortArticlesTableAction;
    return {
      ...state,
      sort: payload,
    };
  },
  [ArticlesTable.RESET_STATE_ARTICLES_TABLE]: () => ({
    ...initialState,
  }),
  [ArticlesTable.SET_CURRENT_ARTICLE_ID_ARTICLES_TABLE]: (state, action) => {
    const { payload } = action as SetCurrentArticleTableIdAction;
    return {
      ...state,
      article: payload,
    };
  },
  [ArticlesTable.FETCH_ORGANIZATIONS_SUCCESS_ARTICLES_TABLE]: (
    state,
    action
  ) => {
    const { payload } = action as FetchOrganizationsSuccessArticleTableAction;
    return {
      ...state,
      organizations: payload,
    };
  },
  [ArticlesTable.SET_ARTICLES_FILTER_ARTICLES_TABLE]: (state, action) => {
    const { payload } = action as SetArticlesTableFilterAction;
    return {
      ...state,
      articlesFilter: payload,
    };
  },
  [ArticlesTable.SET_FILTER_ARTICLES_RIGHT_ARTICLES_TABLE]: (state, action) => {
    const { payload } = action as SetArticlesFilterRightArticlesTableAction;
    return {
      ...state,
      articlesFilterRightTable: payload,
    };
  },
  [ArticlesTable.FETCH_SUCCESS_ARTICLES_RIGHT_ARTICLES_TABLE]: (
    state,
    action
  ) => {
    const { payload } = action as FetchArticlesSuccessRightArticlesTableAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      articlesRightTable: content,
      articlesPaginationRightTable: {
        ...state?.articlesPaginationRightTable,
        pageNum: page,
        totalElements,
      },
    };
  },
  [ArticlesTable.FETCH_ARTICLES_JOIN_SUCCESS_RIGHT_ARTICLES_TABLE]: (
    state,
    action
  ) => {
    const { payload } =
      action as FetchArticlesJoinSuccessRightArticlesTableAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      articlesRightTable: [...(state?.articlesRightTable || []), ...content],
      articlesPaginationRightTable: {
        ...state?.articlesPaginationRightTable,
        pageNum: page,
        totalElements,
      },
    };
  },
  [ArticlesTable.SET_ARTICLES_PAGE_RIGHT_ARTICLES_TABLE]: (state, action) => {
    const { payload } = action as SetArticlesPageRightArticlesTableAction;
    return {
      ...state,
      articlesPaginationRightTable: {
        ...state?.articlesPaginationRightTable,
        pageNum: payload,
      },
    };
  },
  [ArticlesTable.LOADING_SHOW_ARTICLES_RIGHT_ARTICLES_TABLE]: (state) => ({
    ...state,
    loadingArticlesRightTable: true,
  }),
  [ArticlesTable.LOADING_HIDE_ARTICLES_RIGHT_ARTICLES_TABLE]: (state) => ({
    ...state,
    loadingArticlesRightTable: false,
  }),
  [ArticlesTable.FETCH_ORGANIZATIONS_SUCCESS_RIGHT_ARTICLES_TABLE]: (
    state,
    action
  ) => {
    const { payload } =
      action as FetchOrganizationsSuccessRightArticlesTableAction;
    return {
      ...state,
      organizationsRightTable: payload,
    };
  },
  [ArticlesTable.ORGANIZATIONS_LOADING_SHOW_RIGHT_ARTICLES_TABLE]: (state) => ({
    ...state,
    loadingOrganizationsRightTable: true,
  }),
  [ArticlesTable.ORGANIZATIONS_LOADING_HIDE_RIGHT_ARTICLES_TABLE]: (state) => ({
    ...state,
    loadingOrganizationsRightTable: false,
  }),
  [ArticlesTable.CATEGORIES_LOADING_SHOW_RIGHT_ARTICLES_TABLE]: (state) => ({
    ...state,
    categoriesLoadingRightTable: true,
  }),
  [ArticlesTable.CATEGORIES_LOADING_HIDE_RIGHT_ARTICLES_TABLE]: (state) => ({
    ...state,
    categoriesLoadingRightTable: false,
  }),
  [ArticlesTable.FETCH_CATEGORIES_SUCCESS_RIGHT_ARTICLES_TABLE]: (
    state,
    action
  ) => {
    const { payload } =
      action as FetchCategoriesSuccessRightArticlesTableAction;
    return {
      ...state,
      categoriesRightTable: payload,
    };
  },
  [ArticlesTable.RESET_ARTICLES_STATE_RIGHT_ARTICLES_TABLE]: (state) => {
    const {
      articlesRightTable,
      articlesPaginationRightTable,
      searchValue,
      articlesFilterRightTable,
    } = initialState;
    return {
      ...state,
      searchValue,
      articlesRightTable,
      articlesPaginationRightTable,
      articlesFilterRightTable,
    };
  },
  [ArticlesTable.RESET_CATEGORIES_STATE_RIGHT_ARTICLES_TABLE]: (state) => {
    const {
      categoriesRightTable,
      organizationSelected,
      organizationsRightTable,
    } = initialState;
    return {
      ...state,
      categoriesRightTable,
      organizationSelected,
      organizationsRightTable,
    };
  },
  [ArticlesTable.SET_SEARCH_VALUE_RIGHT_ARTICLES_TABLE]: (state, action) => {
    const { payload } = action as SetSearchValueRightArticlesTableAction;
    return {
      ...state,
      searchValue: payload,
    };
  },
  [ArticlesTable.SET_ORGANIZATION_SELECTED_RIGHT_ARTICLES_TABLE]: (
    state,
    action
  ) => {
    const { payload } =
      action as SetSelectedOrganizationRightArticlesTableAction;
    return {
      ...state,
      organizationSelected: payload,
    };
  },
  [ArticlesTable.RESET_ONLY_TABLE_STATE_ARTICLES_TABLE]: (state) => {
    const { pagination, articles, articlesFilter, sort } = initialState;
    return {
      ...state,
      pagination,
      articles,
      articlesFilter,
      sort,
    };
  },
};

export const articlesTableSlice: Reducer<
  ArticlesTableReducerState,
  ArticlesTableActionsTypes
> = (state = initialState, action) => {
  const reducer = articlesTableReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
