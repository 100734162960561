import { Reducer } from 'redux';

import {
  User,
  UsersFilterData,
  UsersTicketsPayload,
} from '@entities/user/model/types';
import {
  DEFAULT_PAGINATION_PARAMS,
  PAGE_SIZE_SEARCH_SELECT,
  PaginationType,
  ReducerMap,
} from '@shared';

import {
  CheckIsExistEmailSuccessAction,
  CheckIsExistLoginSuccessAction,
  CheckIsExistPhoneSuccessAction,
  FetchCurrentUserSuccessAction,
  FetchSpecialistsRequestAction,
  FetchSubordinatesSuccessAction,
  FetchUserClientsRequestAction,
  FetchUsersAddSuccessJoinAction,
  FetchUsersAddSuccessUpdateAction,
  FetchUsersClientsSuccessJoinAction,
  FetchUsersClientsSuccessUpdateAction,
  FetchUsersSpecialistsSuccessJoinAction,
  FetchUsersSpecialistsSuccessUpdateAction,
  FetchUsersSuccessJoinAction,
  FetchUsersSuccessUpdateAction,
  SetSizePageAction,
  SetSizePageAddAction,
  SetSortUsersAction,
  SetSortUsersAddAction,
  SetSubordinatesFilterAction,
  SetUserIdAction,
  SetUserLoginAction,
  SetUsersAddFilterAction,
  SetUsersAddPageAction,
  SetUsersFilterAction,
  SetUsersPageAction,
  Users,
  UsersActionsTypes,
} from './actionTypes';

export interface UsersReducerState {
  users?: User[];
  usersClients?: User[];
  usersSpecialists?: User[];
  usersFilter?: UsersFilterData;
  subordinatesFilter?: UsersFilterData;
  clientsFilter?: UsersTicketsPayload;
  specialistFilter?: UsersTicketsPayload;
  loading?: boolean;
  loadingAdd?: boolean;
  loadingUser?: boolean;
  user?: User;
  userId?: string;
  orgIdUser?: string;
  userLogin?: string;
  pagination?: PaginationType;
  paginationUserClients?: PaginationType;
  paginationUserSpecialists?: PaginationType;
  paginationSubordinates?: PaginationType;
  sort?: string;
  sortClient?: string;
  sortSpecialist?: string;
  usersAdd?: User[];
  usersAddFilter?: UsersFilterData;
  sortAdd?: string;
  sortSubordinates?: string;
  paginationAdd?: PaginationType;
  subordinates?: User[];
  isExistLogin?: boolean;
  isExistEmail?: boolean;
  isExistPhone?: boolean;
}

const initialState: UsersReducerState = {
  users: [],
  usersClients: [],
  usersSpecialists: [],
  loading: false,
  loadingAdd: false,
  loadingUser: false,
  user: undefined,
  sort: 'FIRSTNAME_ASC',
  sortClient: 'FIRSTNAME_ASC',
  sortSpecialist: 'FIRSTNAME_ASC',
  sortAdd: 'FIRSTNAME_ASC',
  subordinates: [],
  usersFilter: {},
  subordinatesFilter: {},
  sortSubordinates: 'FIRSTNAME_ASC',
  usersAddFilter: {},
  isExistLogin: false,
  isExistEmail: false,
  isExistPhone: false,
  pagination: DEFAULT_PAGINATION_PARAMS,
  paginationUserClients: {
    pageSize: PAGE_SIZE_SEARCH_SELECT,
  },
  paginationUserSpecialists: {
    pageSize: PAGE_SIZE_SEARCH_SELECT,
  },
  paginationAdd: {
    pageSize: PAGE_SIZE_SEARCH_SELECT,
  },
};

const usersReducerMap: ReducerMap<UsersReducerState, UsersActionsTypes> = {
  [Users.USERS_LOADING_SHOW]: (state) => ({
    ...state,
    loading: true,
  }),
  [Users.USERS_LOADING_HIDE]: (state) => ({
    ...state,
    loading: false,
  }),
  [Users.USERS_ADD_LOADING_SHOW]: (state) => ({
    ...state,
    loadingAdd: true,
  }),
  [Users.USERS_ADD_LOADING_HIDE]: (state) => ({
    ...state,
    loadingAdd: false,
  }),
  [Users.USER_LOADING_SHOW]: (state) => ({
    ...state,
    loadingUser: true,
  }),
  [Users.USER_LOADING_HIDE]: (state) => ({
    ...state,
    loadingUser: false,
  }),
  [Users.SET_USERS_FILTER]: (state, action) => {
    const { payload } = action as SetUsersFilterAction;
    return {
      ...state,
      usersFilter: payload,
    };
  },
  [Users.FETCH_USERS_SUCCESS_UPDATE]: (state, action) => {
    const { payload } = action as FetchUsersSuccessUpdateAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      users: content,
      pagination: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [Users.FETCH_USERS_SUCCESS_JOIN]: (state, action) => {
    const { payload } = action as FetchUsersSuccessJoinAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      users: [...(state?.users || []), ...content],
      pagination: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [Users.FETCH_USERS_CLIENTS_SUCCESS_UPDATE]: (state, action) => {
    const { payload } = action as FetchUsersClientsSuccessUpdateAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      usersClients: content,
      paginationUserClients: {
        ...state?.paginationUserClients,
        pageNum: page,
        totalElements,
      },
    };
  },
  [Users.FETCH_USERS_CLIENTS_SUCCESS_JOIN]: (state, action) => {
    const { payload } = action as FetchUsersClientsSuccessJoinAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      usersClients: [...(state?.usersClients || []), ...content],
      paginationUserClients: {
        ...state?.paginationUserClients,
        pageNum: page,
        totalElements,
      },
    };
  },
  [Users.FETCH_USERS_SPECIALISTS_SUCCESS_UPDATE]: (state, action) => {
    const { payload } = action as FetchUsersSpecialistsSuccessUpdateAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      usersSpecialists: content,
      paginationUserSpecialists: {
        ...state?.paginationUserSpecialists,
        pageNum: page,
        totalElements,
      },
    };
  },
  [Users.FETCH_USERS_SPECIALISTS_SUCCESS_JOIN]: (state, action) => {
    const { payload } = action as FetchUsersSpecialistsSuccessJoinAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      usersSpecialists: [...(state?.usersSpecialists || []), ...content],
      paginationUserSpecialists: {
        ...state?.paginationUserSpecialists,
        pageNum: page,
        totalElements,
      },
    };
  },
  [Users.FETCH_SUBORDINATES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchSubordinatesSuccessAction;
    return {
      ...state,
      subordinates: payload,
    };
  },
  [Users.SET_USERS_PAGE]: (state, action) => {
    const { payload } = action as SetUsersPageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageNum: payload,
      },
    };
  },
  [Users.SET_SIZE_PAGE_USERS]: (state, action) => {
    const { payload } = action as SetSizePageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageSize: payload,
      },
    };
  },
  [Users.SET_SORT_USERS]: (state, action) => {
    const { payload } = action as SetSortUsersAction;
    return {
      ...state,
      sort: payload,
    };
  },
  [Users.FETCH_CURRENT_USER_SUCCESS]: (state, action) => {
    const { payload } = action as FetchCurrentUserSuccessAction;
    return {
      ...state,
      user: payload,
    };
  },
  [Users.SET_USER_ID]: (state, action) => {
    const { payload } = action as SetUserIdAction;
    return {
      ...state,
      userId: payload,
    };
  },
  [Users.RESET_USERS_STATE]: () => ({
    ...initialState,
  }),
  [Users.SET_USER_LOGIN]: (state, action) => {
    const { payload } = action as SetUserLoginAction;
    return {
      ...state,
      userLogin: payload,
    };
  },
  [Users.RESET_CURRENT_USER_STATE]: (state) => ({
    ...state,
    userId: undefined,
    userLogin: undefined,
  }),
  [Users.SET_USERS_ADD_FILTER]: (state, action) => {
    const { payload } = action as SetUsersAddFilterAction;
    return {
      ...state,
      usersAddFilter: payload,
    };
  },
  [Users.FETCH_USERS_ADD_SUCCESS_UPDATE]: (state, action) => {
    const { payload } = action as FetchUsersAddSuccessUpdateAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      usersAdd: content,
      paginationAdd: {
        ...state?.paginationAdd,
        pageNumber: page,
        totalElements,
      },
    };
  },
  [Users.FETCH_USERS_ADD_SUCCESS_JOIN]: (state, action) => {
    const { payload } = action as FetchUsersAddSuccessJoinAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      usersAdd: [...(state?.usersAdd || []), ...content],
      paginationAdd: {
        ...state?.paginationAdd,
        pageNumber: page,
        totalElements,
      },
    };
  },
  [Users.FETCH_USER_CLIENTS_REQUEST]: (state, action) => {
    const { payload } = action as FetchUserClientsRequestAction;
    return {
      ...state,
      clientsFilter: payload,
    };
  },
  [Users.FETCH_USER_SPECIALISTS_REQUEST]: (state, action) => {
    const { payload } = action as FetchSpecialistsRequestAction;
    return {
      ...state,
      specialistFilter: payload,
    };
  },
  [Users.SET_SUBORDINATES_FILTER]: (state, action) => {
    const { payload } = action as SetSubordinatesFilterAction;
    return {
      ...state,
      subordinatesFilter: payload,
    };
  },
  [Users.SET_SIZE_PAGE_USERS_ADD]: (state, action) => {
    const { payload } = action as SetSizePageAddAction;
    return {
      ...state,
      paginationAdd: {
        ...state?.paginationAdd,
        pageSize: payload,
      },
    };
  },
  [Users.SET_USERS_ADD_PAGE]: (state, action) => {
    const { payload } = action as SetUsersAddPageAction;
    return {
      ...state,
      paginationAdd: {
        ...state?.paginationAdd,
        pageNum: payload,
      },
    };
  },
  [Users.SET_SORT_USERS_ADD]: (state, action) => {
    const { payload } = action as SetSortUsersAddAction;
    return {
      ...state,
      sortAdd: payload,
    };
  },
  [Users.RESET_USERS_ADD_STATE]: (state) => {
    const { usersAdd, usersAddFilter, sortAdd, paginationAdd } = initialState;
    return {
      ...state,
      usersAdd,
      usersAddFilter,
      sortAdd,
      paginationAdd,
    };
  },
  [Users.CHECK_IS_EXIST_LOGIN_SUCCESS]: (state, action) => {
    const { payload } = action as CheckIsExistLoginSuccessAction;
    return {
      ...state,
      isExistLogin: payload,
    };
  },
  [Users.CHECK_IS_EXIST_EMAIL_SUCCESS]: (state, action) => {
    const { payload } = action as CheckIsExistEmailSuccessAction;
    return {
      ...state,
      isExistEmail: payload,
    };
  },
  [Users.CHECK_IS_EXIST_PHONE_SUCCESS]: (state, action) => {
    const { payload } = action as CheckIsExistPhoneSuccessAction;
    return {
      ...state,
      isExistPhone: payload,
    };
  },
  [Users.RESET_CURRENT_USER_AFTER_DELETE]: (state) => ({
    ...state,
    user: initialState.user,
    userId: initialState.userId,
    pagination: initialState.pagination,
  }),
};

export const usersSlice: Reducer<UsersReducerState, UsersActionsTypes> = (
  state = initialState,
  action
) => {
  const reducer = usersReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
