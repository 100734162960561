import { NumberBlock, RouterHref } from '@shared';

import styles from '../../../ui/tickets/TicketsTableContainer/TicketsTableContainer.module.scss';

export const getTicketsTabs = {
  myTickets: (isRU: boolean, notificationsCount?: number) => ({
    to: RouterHref.Tickets,
    exact: true,
    title: isRU ? 'Мои тикеты' : 'My tickets',
    content: notificationsCount ? (
      <NumberBlock
        number={notificationsCount}
        isPurple
        isRound
        className={styles.ticketsTableContainer__numberEl}
      />
    ) : null,
  }),
  allTickets: (isRU: boolean, notificationsCount?: number) => ({
    to: RouterHref.TicketsAll,
    exact: false,
    title: isRU ? 'Все тикеты' : 'All tickets',
    content: notificationsCount ? (
      <NumberBlock
        number={notificationsCount}
        className={styles.ticketsTableContainer__numberEl}
      />
    ) : null,
  }),
  groupTickets: (isRU: boolean) => ({
    to: RouterHref.TicketsGroup,
    exact: false,
    title: isRU ? 'Тикеты моей группы' : 'Group tickets',
  }),
};
