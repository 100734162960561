import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchAccessesByRespIdRequest,
  resetAccessesState,
} from '@entities/accesses';
import {
  fetchActionsByRespIdRequest,
  resetActionsState,
} from '@entities/actions';
import { ActionList, getIsAccessedAction } from '@entities/auth';
import {
  deleteResponsibilityRequest,
  fetchRespByGroupIdRequest,
  fetchRespByRoleIdRequest,
  fetchResponsibilitiesAddRequest,
  fetchResponsibilitiesRequest,
  fetchResponsibilityByIdRequest,
  getCurrentResponsibility,
  getCurrentResponsibilityId,
  getPropsResponsibilities,
  getResponsibilities,
  getResponsibilitiesFilter,
  setCurrentResponsibilitiesPage,
  setCurrentResponsibilityId,
  setSizePage,
  setSortResponsibilities,
  TableResponsibilitiesTypes,
} from '@entities/responsibilities';
import { getCurrentRoleId } from '@entities/roles';
import { getCurrentGroupId, getGroupTitle } from '@entities/work-group';
import { checkObjectIdentity, getChangeButtonProps } from '@shared';

export const fetchFunction = {
  [TableResponsibilitiesTypes.FULL]: fetchResponsibilitiesRequest,
  [TableResponsibilitiesTypes.FROM_GROUPS]: fetchRespByGroupIdRequest,
  [TableResponsibilitiesTypes.FROM_ROLES]: fetchRespByRoleIdRequest,
  [TableResponsibilitiesTypes.ADD_MODAL]: fetchResponsibilitiesAddRequest,
};

export const useResponsibilitiesTableContainer = (
  tableType: TableResponsibilitiesTypes
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const {
    pageNum,
    pageSize,
    sortResponsibilities,
    totalElements,
    loadingResponsibilities,
  } = useSelector(getPropsResponsibilities);
  const responsibilities = useSelector(getResponsibilities);
  const currentResponsibilityId = useSelector(getCurrentResponsibilityId);
  const currentGroupId = useSelector(getCurrentGroupId);
  const groupTitle = useSelector(getGroupTitle)?.substring(0, 7);
  const currentRoleId = useSelector(getCurrentRoleId);
  const isAccessToCreateResponsibility = useSelector(
    getIsAccessedAction(ActionList.CreateAuthority)
  );
  const isAccessToDeleteResponsibility = useSelector(
    getIsAccessedAction(ActionList.DeleteEntries)
  );
  const responsibility = useSelector(getCurrentResponsibility);
  const filterValues = useSelector(getResponsibilitiesFilter);

  const [modalCreateOpen, setModalOpen] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [changeModals, setOpenChangeModals] = useState({
    isEditMode: false,
    isOpenModal: false,
  });

  const toggleIsOpenChangeModals = () => {
    setOpenChangeModals((prev) => ({
      ...prev,
      isOpenModal: !prev.isOpenModal,
    }));
  };

  const setIsEditModeModal = () => {
    setOpenChangeModals((prev) => ({ ...prev, isEditMode: true }));
  };

  const setIsCreateModeModal = () => {
    setOpenChangeModals((prev) => ({ ...prev, isEditMode: false }));
  };

  const handleEditClick = () => {
    setIsEditModeModal();
    toggleIsOpenChangeModals();
  };

  const handleCreateClick = () => {
    setIsCreateModeModal();
    toggleIsOpenChangeModals();
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const isFilterActive = !checkObjectIdentity(filterValues, {});

  const searchDisabled =
    !responsibilities?.length || (showFilterRow && isFilterActive);

  const getActiveId = () => {
    const keys = {
      [TableResponsibilitiesTypes.FROM_GROUPS]: currentGroupId,
      [TableResponsibilitiesTypes.FROM_ROLES]: currentRoleId,
      [TableResponsibilitiesTypes.ADD_MODAL]: '',
      [TableResponsibilitiesTypes.FULL]: '',
    };
    return keys[tableType];
  };

  useEffect(() => {
    if (showFilterRow) {
      setShowFilterRow(false);
    }
  }, [getActiveId()]);

  const responsibilitiesTitle =
    tableType === TableResponsibilitiesTypes.FROM_GROUPS
      ? 'Полномочия группы'
      : 'Полномочия';

  const toggleFilterRow = () => {
    setShowFilterRow((prevState) => !prevState);
  };

  const dispatch = useDispatch();

  const handleSort = (value: string) => {
    dispatch(setSortResponsibilities(value));
    dispatch(fetchFunction[tableType]());
  };

  const handleRowFullTableClick = (value: string) => {
    if (currentResponsibilityId !== value) {
      dispatch(resetAccessesState());
      dispatch(resetActionsState());
      dispatch(setCurrentResponsibilityId(value));
      dispatch(fetchResponsibilityByIdRequest(value));
      dispatch(fetchAccessesByRespIdRequest());
      dispatch(fetchActionsByRespIdRequest());
    }
  };

  const handleDelete = () => {
    if (responsibility?.id) {
      dispatch(deleteResponsibilityRequest(responsibility?.id));
    }
  };

  const handleShowModalDelete = () => {
    if (responsibility?.id) {
      setShowDeleteModal(true);
    }
  };

  const handleChangePage = (page: number) => {
    dispatch(setCurrentResponsibilitiesPage(page));
    dispatch(fetchFunction[tableType]());
  };

  const handleChangePageSize = (arg: number) => {
    dispatch(setSizePage(arg));
  };

  const handleAddResponsibility = () => {
    setModalOpen((prevState) => !prevState);
  };

  const getCreateTableDisable = {
    [TableResponsibilitiesTypes.FULL]: !isAccessToCreateResponsibility,
    [TableResponsibilitiesTypes.FROM_GROUPS]: !currentGroupId,
    [TableResponsibilitiesTypes.FROM_ROLES]: !currentRoleId,
  };

  const getCreateFunction = () => {
    if (tableType === TableResponsibilitiesTypes.FULL) {
      return handleCreateClick;
    }
    return handleAddResponsibility;
  };

  const { createTitle, icon } = getChangeButtonProps(totalElements || 0);

  const createTableTitle =
    tableType === TableResponsibilitiesTypes.FULL ? 'Создать' : createTitle;
  const createIcon =
    tableType === TableResponsibilitiesTypes.FULL ? undefined : icon;

  return {
    methods: {
      toggleIsOpenChangeModals,
      handleEditClick,
      toggleDeleteModal,
      toggleFilterRow,
      handleSort,
      onRowClick: handleRowFullTableClick,
      handleDelete,
      handleShowModalDelete,
      handleChangePage,
      handleChangePageSize,
      handleAddResponsibility,
      getCreateFunction,
    },
    state: {
      pageNum,
      pageSize,
      sortResponsibilities,
      totalElements,
      responsibilities,
      currentGroupId,
      groupTitle,
      isAccessToDeleteResponsibility,
      responsibility,
      modalCreateOpen,
      showFilterRow,
      showDeleteModal,
      changeModals,
      isFilterActive,
      searchDisabled,
      responsibilitiesTitle,
      getCreateTableDisable,
      createTableTitle,
      createIcon,
      currentResponsibilityId,
      loadingResponsibilities,
    },
  };
};
