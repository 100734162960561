import { forwardRef } from 'react';
import { useDispatch } from 'react-redux';

import { SLA } from '@entities/sla';
import { fetchTicketDetailRequest, Ticket } from '@entities/ticket';
import { Card } from '@shared';

import styles from './TicketSla.module.scss';

interface TicketSLAProps {
  ticket: Ticket;
}

export const TicketSLA = forwardRef<HTMLDivElement, TicketSLAProps>(
  ({ ticket }, ref) => {
    const dispatch = useDispatch();

    const updateTicket = () => {
      if (ticket.id) {
        dispatch(fetchTicketDetailRequest({ ticketId: ticket.id }));
      }
    };

    return (
      <Card ref={ref} className={styles.ticketSla}>
        <SLA
          ticket={ticket}
          updateTicket={updateTicket}
          size="small"
          isTicketPage
        />
      </Card>
    );
  }
);
