import { call, put, takeEvery } from 'redux-saga/effects';

import { Organization } from '@entities/organization/model/types';
import { System } from '@entities/system/model';
import {
  createError,
  createErrorAlert,
  createSuccessAlert,
  downloadFileWithToken,
  setAlert,
} from '@shared';

import { requests } from '../api';

import {
  downloadTicketsRequest,
  fetchOrganizationsRequest,
  fetchOrganizationsSuccess,
  fetchOrganizationsWorkGroupRequest,
  fetchSystemsRequest,
  fetchSystemsSuccess,
  setIsTicketsLoading,
} from './slice';

function* downloadTickets(action: ReturnType<typeof downloadTicketsRequest>) {
  try {
    const { fileName, path } = action.payload;
    yield put(
      setAlert(
        createSuccessAlert(`Запущен процесс выгрузки тикетов в файл Excel`)
      )
    );
    yield put(setIsTicketsLoading(true));
    yield downloadFileWithToken(fileName, path);
    yield put(setIsTicketsLoading(false));
  } catch (error) {
    yield put(
      setAlert(
        createErrorAlert(
          `Произошла ошибка при попытке выгрузки тикетов в файл Excel`
        )
      )
    );
    createError(error);
  }
}

function* fetchOrganizations() {
  try {
    const data: Organization[] = yield call(requests.fetchOrganizations);
    yield put(fetchOrganizationsSuccess(data));
  } catch (error) {
    createError(error);
  }
}

function* fetchSystems(action: ReturnType<typeof fetchSystemsRequest>) {
  try {
    const data: System[] = yield call(requests.fetchSystems, action.payload);
    yield put(fetchSystemsSuccess(data));
  } catch (error) {
    createError(error);
  }
}

function* fetchOrganizationsWorkId(
  action: ReturnType<typeof fetchOrganizationsWorkGroupRequest>
) {
  try {
    const data: Organization[] = yield call(
      requests.fetchOrganizationsWorkGroup,
      action.payload
    );
    yield put(fetchOrganizationsSuccess(data));
  } catch (error) {
    createError(error);
  }
}

export function* desktopSaga() {
  yield takeEvery(downloadTicketsRequest.type, downloadTickets);
  yield takeEvery(fetchOrganizationsRequest.type, fetchOrganizations);
  yield takeEvery(fetchSystemsRequest.type, fetchSystems);
  yield takeEvery(
    fetchOrganizationsWorkGroupRequest.type,
    fetchOrganizationsWorkId
  );
}
