import _, { debounce } from 'lodash';
import { FC, memo, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  fetchOrganizationsAddRequest,
  setCurrentOrganizationsAddPage,
  setOrganizationsAddFilterAction,
} from '@entities/organization/model';
import { OrganizationSelect } from '@entities/organization/ui';
import {
  TableWorkGroupsTypes,
  WorkGroupFilter,
} from '@entities/work-group/model';
import {
  DEFAULT_DEBOUNCE_DELAY,
  Input,
  Size,
  TableBodyCell,
  TableFilter,
} from '@shared';

import { INITIAL_GROUPS_FILTER } from '../../config';

interface WorkGroupsFilterProps {
  tableType: TableWorkGroupsTypes;
  onFilter: (filter: WorkGroupFilter) => void;
}

export const WorkGroupsFilter: FC<WorkGroupsFilterProps> = memo(
  ({ tableType, onFilter }) => {
    const { register, handleSubmit, reset, watch, control } =
      useForm<WorkGroupFilter>({
        mode: 'onChange',
        defaultValues: INITIAL_GROUPS_FILTER,
      });
    const dispatch = useDispatch();
    const filterValues = watch();

    const resetFilter = () => {
      onFilter({});
      if (tableType === TableWorkGroupsTypes.FULL) {
        dispatch(setCurrentOrganizationsAddPage(0));
        dispatch(setOrganizationsAddFilterAction({}));
        dispatch(fetchOrganizationsAddRequest({ updateType: 'update' }));
      }
      reset();
    };

    const formSubmitHandler = handleSubmit((data) => {
      const { title, organizationId, description } = data;
      const preparedData = {
        title: title || undefined,
        organizationId: organizationId || undefined,
        description: description || undefined,
      };
      onFilter(preparedData);
    });

    const formSubmitHandlerDebounced = useMemo(
      () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
      [onFilter]
    );

    const titleToInput = register('title', {
      onChange: formSubmitHandlerDebounced,
    });
    const descriptionToInput = register('description', {
      onChange: formSubmitHandlerDebounced,
    });

    const tableBodyWrapper = (elements: JSX.Element[]) =>
      elements.map((item) => (
        <TableBodyCell filterCell key={item.key}>
          {item}
        </TableBodyCell>
      ));

    const filterElements = {
      [TableWorkGroupsTypes.FULL]: () => [
        <Input size={Size.xs} type="title" key="title" {...titleToInput} />,
        <Controller
          control={control}
          name="organizationId"
          key="organizationId"
          render={({ field }) => (
            <OrganizationSelect
              size={Size.xs}
              onChange={(value) => {
                field.onChange(value);
                formSubmitHandler();
              }}
              value={field.value}
            />
          )}
        />,
        <Input
          size={Size.xs}
          type="description"
          key="description"
          {...descriptionToInput}
        />,
      ],
      [TableWorkGroupsTypes.FROM_ORGANIZATIONS]: () => [
        <Input size={Size.xs} type="title" key="title1" {...titleToInput} />,
        <Input
          size={Size.xs}
          type="description"
          key="description"
          {...descriptionToInput}
        />,
      ],
      [TableWorkGroupsTypes.FROM_USERS]: () => [
        <Input
          size={Size.xs}
          type="title"
          key="titleUsers"
          {...titleToInput}
        />,
        <Input
          size={Size.xs}
          type="description"
          key="descriptionUsers"
          {...descriptionToInput}
        />,
      ],
      [TableWorkGroupsTypes.ADD_GROUPS]: () => [
        <div />,
        <Input size={Size.xs} type="title" key="title" {...titleToInput} />,
        <Controller
          control={control}
          name="organizationId"
          key="organizationId"
          render={({ field }) => (
            <OrganizationSelect
              size={Size.xs}
              onChange={(value) => {
                field.onChange(value);
                formSubmitHandler();
              }}
              value={field.value}
              disabled
            />
          )}
        />,
        <Input
          size={Size.xs}
          type="description"
          key="descriptionGroups"
          {...descriptionToInput}
        />,
      ],
    };

    const filterComponents = (
      <>
        {tableBodyWrapper(
          filterElements[tableType as keyof typeof filterElements]()
        )}
      </>
    );

    const disableReset = _.isEqual(
      _.omitBy(filterValues, (value, key) =>
        _.isNil(value) || _.isEmpty(value) ? key : false
      ),
      {}
    );
    return (
      <TableFilter
        filterComponent={filterComponents}
        onReset={resetFilter}
        disableReset={disableReset}
      />
    );
  }
);
