import {
  CreateCustomFieldData,
  CustomFieldType,
} from '@entities/custom-fields';
import { OrganizationType } from '@entities/organization';
import { RenderTypes, TableColumns } from '@shared';

export const TABLE_HEADER_CUSTOM_FIELDS: TableColumns = [
  {
    title: 'Название',
    sortField: 'title',
    fieldName: 'title',
  },
  {
    title: 'Доступ',
    fieldName: 'accessType',
    renderType: RenderTypes.CONDITION,
  },
  {
    title: 'Тип',
    fieldName: 'type',
    renderType: RenderTypes.ICON_BLOCK,
  },
  {
    title: 'Организация',
    fieldName: 'organization',
  },
  {
    title: 'Система',
    fieldName: 'system',
    renderType: RenderTypes.CONDITION,
  },
];

export const SLIDE_RADIO_TABS = [
  {
    title: 'Текст',
    value: CustomFieldType.FIELD_TEXT,
  },
  {
    title: 'Дата',
    value: CustomFieldType.FIELD_DATE,
  },
  {
    title: 'Флаг',
    value: CustomFieldType.FIELD_FLAG,
  },
  {
    title: 'Список',
    value: CustomFieldType.FIELD_LIST,
  },
];

export const CUSTOM_FIELD_ACCESS_OPTIONS = [
  {
    value: OrganizationType.CUSTOMER,
    title: 'Для клиента',
  },
  {
    value: OrganizationType.SERVICE,
    title: 'Для специалиста',
  },
];

export const CUSTOM_FIELD_ACCESS_TABLE = {
  [OrganizationType.SERVICE]: 'Специалист',
  [OrganizationType.CUSTOMER]: 'Клиент',
};

export const INITIAL_CUSTOM_FIELDS_FILTER = {
  title: '',
  organizationId: null,
  systemId: null,
  customFieldType: null,
  accessType: null,
};

export const DEFAULT_CUSTOM_FIELDS_FORM_VALUES: CreateCustomFieldData = {
  organizationId: null,
  systemId: null,
  customFieldType: CustomFieldType.FIELD_TEXT,
  accessType: OrganizationType.CUSTOMER,
  required: false,
};

export const PAGINATION_RANGE_MAP: number | undefined = 1;

export const MAX_LIST_FIELD = 10;
export const MIN_LIST_FIELD = 2;
