import cn from 'clsx';
import { FC } from 'react';

import { PriorityBlock, StatusBlock } from '@entities/ticket';
import {
  ArrowHorizontalIcon,
  DataEmpty,
  getValueFromValueType,
  Select,
  Size,
  Typography,
  TypographyVariants,
} from '@shared';

import { JiraIntegrationCreateHookType } from '../../../../model';

import styles from './JiraIntegration.module.scss';

type JiraIntegrationProps = { form: JiraIntegrationCreateHookType };

export const JiraIntegration: FC<JiraIntegrationProps> = ({ form }) => {
  const { state, methods } = form;

  const {
    jiraPrioritiesOptions,
    jiraStatusesOptions,
    isSuccessConnect,
    spPriorities,
    spStatuses,
    ticketTypeId,
    isJiraTicketTypeDisabled,
    jiraTicketTypesOptions,
    statuses,
    priorities,
    jiraTicketType,
    statusesDisabled,
  } = state;

  const {
    getIsPriorityAvailable,
    fetchJiraStatuses,
    onChangeStatuses,
    onChangePriorities,
    onChangeJiraTicketType,
  } = methods;

  const statusMapList = spStatuses?.map(
    ({ id, defaultStatus, name, color }) => {
      const status = statuses.find((item) => item.ticketStatusIds.includes(id));
      const value = { title: status?.name ?? '', value: status?.idStatus ?? 0 };

      return (
        <div className={styles.jiraIntegration__mapItem} key={id}>
          <StatusBlock
            label={name}
            status={defaultStatus}
            color={color}
            className={styles.jiraIntegration__mapStatus}
          />
          <div className={styles.jiraIntegration__mapSelectWrapper}>
            <ArrowHorizontalIcon className={styles.jiraIntegration__mapIcon} />
            <Select<number>
              size={Size.xs}
              className={styles.jiraIntegration__mapSelect}
              disabled={!isSuccessConnect || statusesDisabled}
              options={jiraStatusesOptions}
              onChange={onChangeStatuses(id)}
              mobileModalTitle="статус"
              isTooltip={false}
              value={value}
            />
          </div>
        </div>
      );
    }
  );

  const priorityMapList = spPriorities.map(({ priority }) => {
    const isPriorityAvailable = getIsPriorityAvailable(priority);
    const priorite = priorities.find((item) =>
      item?.ticketPriorities?.includes(priority)
    );
    const value = {
      title: priorite?.name ?? '',
      value: priorite?.idPriority ?? 0,
    };

    return (
      <div className={styles.jiraIntegration__mapItem} key={priority}>
        <PriorityBlock
          priority={priority}
          className={styles.jiraIntegration__mapPriority}
        />
        <div className={styles.jiraIntegration__mapSelectWrapper}>
          <ArrowHorizontalIcon className={styles.jiraIntegration__mapIcon} />
          <Select<number>
            size={Size.xs}
            className={styles.jiraIntegration__mapSelect}
            disabled={
              !isSuccessConnect || !isPriorityAvailable || !spStatuses?.length
            }
            options={jiraPrioritiesOptions}
            onChange={onChangePriorities(priority)}
            mobileModalTitle="приоритет"
            isTooltip={false}
            value={value}
          />
        </div>
      </div>
    );
  });

  const statusesList = statusMapList?.length ? statusMapList : <DataEmpty />;

  return (
    <div className={styles.jiraIntegration}>
      <div className={styles.jiraIntegration__titleContainer}>
        <Typography
          variant={TypographyVariants.h4}
          className={styles.jiraIntegration__title}
        >
          SupPortal
        </Typography>
        <Typography
          variant={TypographyVariants.h4}
          className={styles.jiraIntegration__title}
        >
          Jira
        </Typography>
      </div>
      <div className={styles.jiraIntegration__mapBlock}>
        <Typography
          variant={TypographyVariants.h4}
          className={styles.jiraIntegration__mapTitle}
        >
          Маппинг типов тикета
        </Typography>

        <div className={styles.jiraIntegration__mapItem}>
          <Select
            size={Size.xs}
            value={ticketTypeId}
            options={[]}
            disabled
            className={styles.jiraIntegration__selectType}
          />
          <div className={styles.jiraIntegration__mapSelectWrapper}>
            <ArrowHorizontalIcon className={styles.jiraIntegration__mapIcon} />
            <Select<string>
              size={Size.xs}
              mobileModalTitle="тип тикета"
              className={styles.jiraIntegration__mapSelect}
              options={jiraTicketTypesOptions}
              disabled={isJiraTicketTypeDisabled}
              onChange={(value) => {
                fetchJiraStatuses(getValueFromValueType(value) ?? '');
                onChangeJiraTicketType(value);
              }}
              value={jiraTicketType}
              isTooltip={false}
            />
          </div>
        </div>
      </div>
      <div className={styles.jiraIntegration__mapBlock}>
        <Typography
          variant={TypographyVariants.h4}
          className={styles.jiraIntegration__mapTitle}
        >
          Маппинг статуса
        </Typography>
        <div
          className={cn(styles.jiraIntegration__mapWrapper, {
            [styles.jiraIntegration__mapWrapper_empty]: !statusMapList?.length,
          })}
        >
          {statusesList}
        </div>
      </div>
      <div className={styles.jiraIntegration__mapBlock}>
        <Typography
          variant={TypographyVariants.h4}
          className={styles.jiraIntegration__mapTitle}
        >
          Маппинг приоритета
        </Typography>
        <div className={styles.jiraIntegration__mapWrapper}>
          {priorityMapList}
        </div>
      </div>
    </div>
  );
};
