import { Contract } from '@entities/contract/model';
import { Environment } from '@entities/environment/model/types';
import {
  Organization,
  OrganizationsFilterToRequest,
} from '@entities/organization/model/types';
import { Priority, TicketType } from '@entities/ticket/model/types';
import { ProcedureType, TicketsTabType, ValueType } from '@shared';

import { SystemReducerState } from './slice';

export type SystemState = { system: SystemReducerState };

export type Version = {
  id: string;
  version: string;
};

export type System = {
  id: string;
  title: string;
  description: string;
  index: string;
  contractList?: Contract[];
  environmentList: Environment[];
  typeList: TicketType[];
  organization?: Organization;
  organizationId?: ValueType<string>;
  version?: Version;
  versionId?: string;
  versionTitle?: string;
  deleted?: boolean;
  activeContract?: Contract;
};

export type SystemsFilterToRequest = {
  title?: string;
  description?: string;
  versionTitle?: string;
  organizationId?: string;
  deleted?: boolean | null;
  contractTitle?: string;
  withOnlyActiveContract?: boolean;
  ticketType?: TicketType[];
  environmentIds?: string[];
  ticketTab?: TicketsTabType;
} & Pick<OrganizationsFilterToRequest, 'organizationTypes'>;

export type SystemsFilter = Omit<
  SystemsFilterToRequest,
  'organizationId' | 'environmentIds'
> & {
  organizationId?: ValueType<string>;
  environmentIds?: ValueType<string>;
};

export type SystemPriority = {
  id: string;
  title: string;
  value: Priority;
  type: ProcedureType;
};

export enum TableSystemsTypes {
  FULL = 'FULL',
  FROM_CONTRACTS = 'FROM_CONTRACTS',
}

export interface SystemFormData {
  system: SystemData;
  ticketType: TicketType[];
  environment: Environment[];
}

type SystemForCreate = Pick<
  System,
  'title' | 'versionTitle' | 'description' | 'index'
>;

export type CreateTicketType = {
  ticketTypes: string[];
  systemId: string;
};

export type CreateEnvironment = {
  environments: string[];
  systemId: string;
};

export type SystemData = {
  id?: string;
  organization: ValueType<string>;
} & SystemForCreate;

export type CreateSystem = {
  system: CreateSystemData;
} & Omit<SystemFormData, 'system'>;

export type CreateSystemData = {
  id?: string;
  organization: {
    id: string;
  };
} & SystemForCreate;

export type EditSystemData = {
  id: string;
} & CreateSystemData;

export type EnvironmentResponse = {
  environmentId: string;
  name: string;
};

export type FieldArrayUpdate = {
  id: string;
  index: number;
  value: string;
};
