import { FC } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';

import { KpiFormData } from '@entities/desktop';
import { Select, SelectOption } from '@shared';

import { KPI_PERIOD_OPTIONS } from '../../config';
import { ResetFilterButton } from '../ResetFilterButton';

import styles from './KpiForm.module.scss';

interface KpiFormProps {
  control: Control<KpiFormData>;
  workGroupsIds?: SelectOption[];
  organizationsIds?: SelectOption[];
  systemsIds?: SelectOption[];
  workGroupsOptions: SelectOption[];
  organizationsOptions: SelectOption[];
  systemsOptions: SelectOption[];
  ticketTypesOptions: SelectOption[];
  fetchKpiHandler: () => void;
  setValue: UseFormSetValue<KpiFormData>;
  reset: () => void;
}

export const KpiForm: FC<KpiFormProps> = (props) => {
  const {
    control,
    systemsOptions,
    organizationsOptions,
    organizationsIds,
    systemsIds,
    workGroupsOptions,
    workGroupsIds,
    ticketTypesOptions,
    setValue,
    fetchKpiHandler,
    reset,
  } = props;

  const isDisabledOrganization = !!workGroupsIds?.length;
  const isDisabledSystem =
    !!workGroupsIds?.length && !!organizationsIds?.length;
  const isDisabledTicketType =
    !!workGroupsIds?.length &&
    !!organizationsIds?.length &&
    !!systemsIds?.length;

  return (
    <>
      <Controller
        control={control}
        name="period"
        render={({ field }) => (
          <Select<string>
            label="Период"
            mobileModalTitle="период"
            value={field.value}
            options={KPI_PERIOD_OPTIONS}
            className={styles.kpiForm__multiSelect}
            onChange={(event) => {
              field.onChange(event);
              fetchKpiHandler();
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="workGroupsIds"
        render={({ field }) => (
          <Select<string>
            allowClear
            label="Рабочая группа"
            mobileModalTitle="рабочую группу"
            value={field.value}
            className={styles.kpiForm__multiSelect}
            options={workGroupsOptions}
            onChange={(event) => {
              setValue('organizationsIds', []);
              setValue('systemsIds', []);
              setValue('ticketTypesIds', []);
              field.onChange(event);
              fetchKpiHandler();
            }}
            isMulti
          />
        )}
      />
      <Controller
        control={control}
        name="organizationsIds"
        render={({ field }) => (
          <Select<string>
            allowClear
            label="Организация"
            mobileModalTitle="организация"
            value={field.value}
            className={styles.kpiForm__multiSelect}
            options={organizationsOptions}
            onChange={(event) => {
              setValue('systemsIds', []);
              setValue('ticketTypesIds', []);
              field.onChange(event);
              fetchKpiHandler();
            }}
            disabled={!isDisabledOrganization}
            isMulti
          />
        )}
      />
      <Controller
        control={control}
        name="systemsIds"
        render={({ field }) => (
          <Select<string>
            allowClear
            label="Система"
            mobileModalTitle="систему"
            value={field.value}
            className={styles.kpiForm__multiSelect}
            options={systemsOptions}
            onChange={(event) => {
              setValue('ticketTypesIds', []);
              field.onChange(event);
              fetchKpiHandler();
            }}
            disabled={!isDisabledSystem}
            isMulti
          />
        )}
      />
      <Controller
        control={control}
        name="ticketTypesIds"
        render={({ field }) => (
          <Select<string>
            allowClear
            label="Тип тикета"
            mobileModalTitle="тип тикета"
            value={field.value}
            options={ticketTypesOptions}
            className={styles.kpiForm__multiSelect}
            onChange={(event) => {
              field.onChange(event);
              fetchKpiHandler();
            }}
            disabled={!isDisabledTicketType}
            isMulti
          />
        )}
      />
      <ResetFilterButton reset={reset} />
    </>
  );
};
