import { call, debounce, put, select, takeEvery } from 'redux-saga/effects';

import {
  getFilterValues,
  getPrepareFilterTicketsToRequest,
  TicketsFilterForRender,
} from '@entities/ticket';
import { Ticket } from '@entities/ticket/model/types';
import { createError, DEFAULT_DEBOUNCE_DELAY, ResponseWithMeta } from '@shared';

import { requestsCurrentState } from '../../api/desktop-current-state';
import { mergeDesktopFilters } from '../../lib';
import { getDesktopFilters } from '../selectors';
import {
  DesktopFilterRequest,
  DesktopFilters,
  TicketsStatistic,
} from '../types';

import {
  getCurrentStatePageNumber,
  getCurrentStatePageSize,
  getCurrentStateSort,
} from './selectors';
import {
  fetchTicketsRequestCurrentState,
  fetchTicketsStatisticRequestCurrentState,
  fetchTicketsStatisticSuccessCurrentState,
  fetchTicketsSuccessCurrentState,
  setIsTicketsLoadingCurrentState,
} from './slice';

function* ticketsFetch() {
  try {
    yield put(setIsTicketsLoadingCurrentState(true));
    const currentPage: ReturnType<typeof getCurrentStatePageNumber> =
      yield select(getCurrentStatePageNumber);
    const pageSize: ReturnType<typeof getCurrentStatePageSize> = yield select(
      getCurrentStatePageSize
    );
    const sort: ReturnType<typeof getCurrentStateSort> = yield select(
      getCurrentStateSort
    );
    const desktopFilter: DesktopFilters = yield select(getDesktopFilters);
    const ticketsReportFilter: TicketsFilterForRender = yield select(
      getFilterValues
    );

    const filter: DesktopFilterRequest = mergeDesktopFilters(
      desktopFilter,
      getPrepareFilterTicketsToRequest({
        filter: ticketsReportFilter,
      })
    );
    const data: ResponseWithMeta<Ticket[]> = yield call(
      requestsCurrentState.fetchCurrentStateTickets,
      currentPage,
      pageSize,
      sort,
      filter
    );
    yield put(fetchTicketsSuccessCurrentState(data));
    yield put(setIsTicketsLoadingCurrentState(false));
  } catch (error) {
    createError(error);
  }
}

function* ticketsStatisticCurrentStateFetch() {
  try {
    const filter: DesktopFilters = yield select(getDesktopFilters);
    const data: TicketsStatistic = yield call(
      requestsCurrentState.fetchCurrentStateTicketsStatistic,
      filter
    );
    yield put(fetchTicketsStatisticSuccessCurrentState(data));
  } catch (error) {
    createError(error);
  }
}

export function* desktopCurrentStateSaga() {
  yield debounce(
    DEFAULT_DEBOUNCE_DELAY,
    fetchTicketsRequestCurrentState.type,
    ticketsFetch
  );
  yield takeEvery(
    fetchTicketsStatisticRequestCurrentState.type,
    ticketsStatisticCurrentStateFetch
  );
}
