import { Organization } from '@entities/organization';
import { getKeyValueFromIdTitle, getMoreTag } from '@shared';

type Args = {
  canCreateArticle: boolean;
  organizations?: Organization[];
  userOrganization?: Organization;
};

export const getOrgByUserOrgId = ({
  canCreateArticle,
  organizations,
  userOrganization,
}: Args) => {
  if (canCreateArticle) {
    return getMoreTag(getKeyValueFromIdTitle(organizations));
  }

  if (!userOrganization?.id) {
    return '-';
  }

  return (
    organizations?.find(
      (organization) => organization?.id === userOrganization?.id
    )?.title || '-'
  );
};
