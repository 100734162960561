import cn from 'clsx';
import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  ContractStatus,
  getAttachmentsContractDetail,
  getContractDetail,
} from '@entities/contract';
import { Button, DataEmpty, PlusIcon } from '@shared';

import { useContractId } from '../../../lib';
import { AccessToSections } from '../AccessToSections';
import { AddAttachmentsModal } from '../AddAttachmentsModal';
import { AttachmentDetail } from '../AttachmentDetail';
import { SupplementaryAgreementCreate } from '../SupplementaryAgreementCreate';
import { SupplementaryAgreementsTableContainer } from '../SupplementaryAgreementTable';

import styles from './SupplementaryAgreementsAndAttachments.module.scss';
import { SupplementaryAgreementsTabType as TabType } from './types';

export const SupplementaryAgreementsAndAttachments: FC = () => {
  const attachments = useSelector(getAttachmentsContractDetail) || [];
  const contractId = useContractId();
  const contract = useSelector(getContractDetail);

  const [isModal, setIsModal] = useState(false);
  const [tab, setTab] = useState<TabType>(TabType.supplementary_agreements);

  const isSupplementaryAgreements = tab === TabType.supplementary_agreements;
  const isAttachments = tab === TabType.attachments;
  const isAccessToSections = tab === TabType.access_to_sections;

  const toggleHeaderTab = (value: TabType) => () => {
    setTab(value);
  };

  const toggleModal = () => {
    setIsModal((prev) => !prev);
  };

  const addHandler = () => {
    toggleModal();
  };

  const tabs = useMemo(
    () => [
      {
        title: 'Допсоглашения',
        onClick: toggleHeaderTab(TabType.supplementary_agreements),
        className: cn(
          styles.supplementaryAgreementsAndAttachments__titleButton,
          {
            [styles.supplementaryAgreementsAndAttachments__titleButton_active]:
              isSupplementaryAgreements,
          }
        ),
      },
      {
        title: 'Вложения',
        onClick: toggleHeaderTab(TabType.attachments),
        className: cn(
          styles.supplementaryAgreementsAndAttachments__titleButton,
          {
            [styles.supplementaryAgreementsAndAttachments__titleButton_active]:
              isAttachments,
          }
        ),
      },
      {
        title: 'Доступ к разделам',
        onClick: toggleHeaderTab(TabType.access_to_sections),
        className: cn(
          styles.supplementaryAgreementsAndAttachments__titleButton,
          {
            [styles.supplementaryAgreementsAndAttachments__titleButton_active]:
              isAccessToSections,
          }
        ),
      },
    ],
    [tab, isSupplementaryAgreements, isAttachments, isAccessToSections]
  );

  const contentAddAttachments = attachments.length ? (
    attachments?.map((attachment) => (
      <AttachmentDetail attachment={attachment} key={attachment.id} />
    ))
  ) : (
    <DataEmpty
      className={styles.supplementaryAgreementsAndAttachments__empty}
    />
  );

  const modalAttachments = isModal && (
    <AddAttachmentsModal
      isModal={isModal}
      toggleModal={toggleModal}
      contractId={contract?.id}
    />
  );

  const modalSupplementaryAgreement = isModal && (
    <SupplementaryAgreementCreate isModal={isModal} toggleModal={toggleModal} />
  );

  const tabsList: Record<TabType, JSX.Element | JSX.Element[]> = {
    [TabType.supplementary_agreements]: (
      <SupplementaryAgreementsTableContainer />
    ),
    [TabType.attachments]: contentAddAttachments,
    [TabType.access_to_sections]: <AccessToSections />,
  };

  const content = tabsList[tab];

  const modalContent = isSupplementaryAgreements
    ? modalSupplementaryAgreement
    : modalAttachments;

  const getAddBtnDisabled = () => {
    if (isSupplementaryAgreements || isAccessToSections) {
      return !contractId || contract?.status === ContractStatus.NOT_SIGNED;
    }
    return !contractId;
  };

  return (
    <div className={styles.supplementaryAgreementsAndAttachments}>
      <div className={styles.supplementaryAgreementsAndAttachments__header}>
        <div
          className={styles.supplementaryAgreementsAndAttachments__headerStart}
        >
          {tabs.map(({ onClick, className, title }) => (
            <button onClick={onClick} className={className} key={title}>
              {title}
            </button>
          ))}
        </div>
        {contractId && !isAccessToSections && (
          <Button
            appearance="flat"
            onClick={addHandler}
            icon={
              <PlusIcon
                className={
                  styles.supplementaryAgreementsAndAttachments__plusIcon
                }
              />
            }
            disabled={getAddBtnDisabled()}
            className={styles.supplementaryAgreementsAndAttachments__button}
          >
            <span
              className={
                styles.supplementaryAgreementsAndAttachments__buttonTitle
              }
            >
              Добавить
            </span>
          </Button>
        )}
      </div>
      <div className={styles.supplementaryAgreementsAndAttachments__list}>
        {content}
      </div>
      {modalContent}
    </div>
  );
};
