import { createSelector } from 'reselect';

import { Organization } from '@entities/organization/model/types';
import { System } from '@entities/system/model';
import { getArraySelectOptions, SelectOption, ValueType } from '@shared';

import { DesktopState } from './types';

export const getDesktopFilters = (state: DesktopState) => state.desktop.filters;

export const getOrganizations = (state: DesktopState) =>
  state.desktop.organizations;
export const getOrganizationsOptions = createSelector<
  DesktopState,
  Organization[] | undefined,
  SelectOption[]
>([getOrganizations], (organizations): SelectOption[] => {
  if (organizations) {
    return organizations.map(
      (organization: Organization): SelectOption => ({
        title: organization.title,
        value: organization.id || '',
      })
    );
  }
  return [];
});

export const getSystems = (state: DesktopState) => state.desktop.systems;
export const getSystemsOptions = createSelector<
  DesktopState,
  System[] | undefined,
  SelectOption[]
>([getSystems], (systemsOrganization): SelectOption[] => {
  if (systemsOrganization) {
    return systemsOrganization.map(
      (system: System): SelectOption => ({
        title: system.title,
        value: system.id,
      })
    );
  }
  return [];
});

export const getIsDesktopTicketsLoading = (state: DesktopState) =>
  state.desktop.isTicketsLoading;

export const getTransformedTypeList = createSelector(
  [getSystems, (_: DesktopState, systemId: ValueType<string>) => systemId],
  (systems, systemId): SelectOption[] => {
    if (Array.isArray(systemId)) {
      return [];
    }

    const matchedSystem = systems?.find(
      (system) => system.id === systemId?.value
    );
    return getArraySelectOptions(matchedSystem?.typeList);
  }
);
