import { Ticket } from '@entities/ticket';
import {
  CreateUserData,
  User,
  UsersExecutorTicketsPayload,
  UsersFilterData,
} from '@entities/user/model/types';
import { DeleteDataType, ResponseWithMeta } from '@shared';

import {
  CheckIsExistEmailRequestAction,
  CheckIsExistEmailSuccessAction,
  CheckIsExistLoginRequestAction,
  CheckIsExistLoginSuccessAction,
  CheckIsExistPhoneRequestAction,
  CheckIsExistPhoneSuccessAction,
  CreateUserAction,
  DeleteUserRequestAction,
  EditUserAction,
  FetchCurrentUserSuccessAction,
  FetchSpecialistsRequestAction,
  FetchSubordinatesRequestAction,
  FetchSubordinatesSuccessAction,
  FetchUserClientsRequestAction,
  FetchUserRequestAction,
  FetchUsersAddByContractIdRequestAction,
  FetchUsersAddByOrgIdRequestAction,
  FetchUsersByGroupsIdRequestAction,
  FetchUsersClientsSuccessJoinAction,
  FetchUsersClientsSuccessUpdateAction,
  FetchUsersRequestAction,
  FetchUsersSpecialistsSuccessJoinAction,
  FetchUsersSpecialistsSuccessUpdateAction,
  FetchUsersSuccessJoinAction,
  FetchUsersSuccessUpdateAction,
  HideUserLoadingAction,
  HideUsersAddLoadingAction,
  HideUsersLoadingAction,
  ResetCurrentUserAction,
  ResetCurrentUserAfterDeleteAction,
  ResetUsersAddStateAction,
  ResetUsersStateAction,
  SetSizePageAction,
  SetSizePageAddAction,
  SetSortUsersAction,
  SetSortUsersAddAction,
  SetUserIdAction,
  SetUserLoginAction,
  SetUsersAddPageAction,
  SetUsersPageAction,
  ShowUserLoadingAction,
  ShowUsersAddLoadingAction,
  ShowUsersLoadingAction,
  UpdateUserDataRequestAction,
  Users,
} from './actionTypes';

export const showUsersLoading = (): ShowUsersLoadingAction => ({
  type: Users.USERS_LOADING_SHOW,
});

export const hideUsersLoading = (): HideUsersLoadingAction => ({
  type: Users.USERS_LOADING_HIDE,
});

export const showUsersAddLoading = (): ShowUsersAddLoadingAction => ({
  type: Users.USERS_ADD_LOADING_SHOW,
});

export const hideUsersAddLoading = (): HideUsersAddLoadingAction => ({
  type: Users.USERS_ADD_LOADING_HIDE,
});

export const showUserLoading = (): ShowUserLoadingAction => ({
  type: Users.USER_LOADING_SHOW,
});

export const hideUserLoading = (): HideUserLoadingAction => ({
  type: Users.USER_LOADING_HIDE,
});

export const fetchUsersRequest = (): FetchUsersRequestAction => ({
  type: Users.FETCH_USERS_REQUEST,
});

export const fetchUsersSuccessUpdate = (
  data: ResponseWithMeta<User[]>
): FetchUsersSuccessUpdateAction => ({
  type: Users.FETCH_USERS_SUCCESS_UPDATE,
  payload: data,
});

export const fetchUsersSuccessJoin = (
  data: ResponseWithMeta<User[]>
): FetchUsersSuccessJoinAction => ({
  type: Users.FETCH_USERS_SUCCESS_JOIN,
  payload: data,
});

export const fetchUsersClientsSuccessUpdate = (
  data: ResponseWithMeta<User[]>
): FetchUsersClientsSuccessUpdateAction => ({
  type: Users.FETCH_USERS_CLIENTS_SUCCESS_UPDATE,
  payload: data,
});

export const fetchUsersClientsSuccessJoin = (
  data: ResponseWithMeta<User[]>
): FetchUsersClientsSuccessJoinAction => ({
  type: Users.FETCH_USERS_CLIENTS_SUCCESS_JOIN,
  payload: data,
});

export const fetchUsersSpecialistsSuccessUpdate = (
  data: ResponseWithMeta<User[]>
): FetchUsersSpecialistsSuccessUpdateAction => ({
  type: Users.FETCH_USERS_SPECIALISTS_SUCCESS_UPDATE,
  payload: data,
});

export const fetchUsersSpecialistsSuccessJoinAction = (
  data: ResponseWithMeta<User[]>
): FetchUsersSpecialistsSuccessJoinAction => ({
  type: Users.FETCH_USERS_SPECIALISTS_SUCCESS_JOIN,
  payload: data,
});

export const fetchUsersClientsRequest = (
  payload: UsersExecutorTicketsPayload
): FetchUserClientsRequestAction => ({
  type: Users.FETCH_USER_CLIENTS_REQUEST,
  payload,
});

export const fetchUsersSpecialistsRequest = (
  payload: UsersExecutorTicketsPayload
): FetchSpecialistsRequestAction => ({
  type: Users.FETCH_USER_SPECIALISTS_REQUEST,
  payload,
});

export const fetchSubordinatesRequest = (
  payload: Ticket
): FetchSubordinatesRequestAction => ({
  type: Users.FETCH_SUBORDINATES_REQUEST,
  payload,
});

export const fetchSubordinatesSuccess = (
  data: User[]
): FetchSubordinatesSuccessAction => ({
  type: Users.FETCH_SUBORDINATES_SUCCESS,
  payload: data,
});

export const setUsersPage = (page: number): SetUsersPageAction => ({
  type: Users.SET_USERS_PAGE,
  payload: page,
});

export const setSizePage = (size: number): SetSizePageAction => ({
  type: Users.SET_SIZE_PAGE_USERS,
  payload: size,
});

export const setSortUsers = (sort: string): SetSortUsersAction => ({
  type: Users.SET_SORT_USERS,
  payload: sort,
});

export const fetchCurrentUser = (id: string): FetchUserRequestAction => ({
  type: Users.FETCH_USER_REQUEST,
  payload: id,
});

export const setCurrentUserSuccess = (
  data?: User
): FetchCurrentUserSuccessAction => ({
  type: Users.FETCH_CURRENT_USER_SUCCESS,
  payload: data,
});

export const createUser = (data: CreateUserData): CreateUserAction => ({
  type: Users.CREATE_USER,
  payload: data,
});

export const editUser = (data: CreateUserData): EditUserAction => ({
  type: Users.EDIT_USER,
  payload: data,
});

export const setUserId = (id?: string): SetUserIdAction => ({
  type: Users.SET_USER_ID,
  payload: id,
});

export const resetUsersState = (): ResetUsersStateAction => ({
  type: Users.RESET_USERS_STATE,
});

export const fetchUsersByGroupId = (): FetchUsersByGroupsIdRequestAction => ({
  type: Users.FETCH_USERS_BY_GROUPS_ID_REQUEST,
});

export const setUserLogin = (login?: string): SetUserLoginAction => ({
  type: Users.SET_USER_LOGIN,
  payload: login,
});

export const resetUserCurrentState = (): ResetCurrentUserAction => ({
  type: Users.RESET_CURRENT_USER_STATE,
});

export const updateUserDataRequest = (
  data: User
): UpdateUserDataRequestAction => ({
  type: Users.UPDATE_USER_DATA_REQUEST,
  payload: data,
});

export const setUsersAddFilter = (data: UsersFilterData) => ({
  type: Users.SET_USERS_ADD_FILTER,
  payload: data,
});

export const setSubordinatesFilter = (data: UsersFilterData) => ({
  type: Users.SET_SUBORDINATES_FILTER,
  payload: data,
});

export const fetchUsersAddSuccessUpdate = (data: ResponseWithMeta<User[]>) => ({
  type: Users.FETCH_USERS_ADD_SUCCESS_UPDATE,
  payload: data,
});

export const fetchUsersAddSuccessJoin = (data: ResponseWithMeta<User[]>) => ({
  type: Users.FETCH_USERS_ADD_SUCCESS_JOIN,
  payload: data,
});

export const setUsersFilter = (data: UsersFilterData) => ({
  type: Users.SET_USERS_FILTER,
  payload: data,
});

export const updateUserRolesInGroup = (data: string[]) => ({
  type: Users.UPDATE_USER_ROLES_IN_GROUP,
  payload: data,
});

export const updateUserRolesInSystem = (data: string[]) => ({
  type: Users.UPDATE_USER_ROLES_IN_SYSTEM,
  payload: data,
});

export const resetUsersAddState = (): ResetUsersAddStateAction => ({
  type: Users.RESET_USERS_ADD_STATE,
});

export const fetchUsersAddByOrgId = (): FetchUsersAddByOrgIdRequestAction => ({
  type: Users.FETCH_USERS_ADD_BY_ORG_ID_REQUEST,
});

export const setUsersAddPage = (page: number): SetUsersAddPageAction => ({
  type: Users.SET_USERS_ADD_PAGE,
  payload: page,
});

export const setSizePageUsersAdd = (size: number): SetSizePageAddAction => ({
  type: Users.SET_SIZE_PAGE_USERS_ADD,
  payload: size,
});

export const setSortUsersAdd = (sort: string): SetSortUsersAddAction => ({
  type: Users.SET_SORT_USERS_ADD,
  payload: sort,
});

export const checkIsExistLoginRequest = (
  login: string
): CheckIsExistLoginRequestAction => ({
  type: Users.CHECK_IS_EXIST_LOGIN_REQUEST,
  payload: login,
});

export const checkIsExistLoginSuccess = (
  isExist: boolean
): CheckIsExistLoginSuccessAction => ({
  type: Users.CHECK_IS_EXIST_LOGIN_SUCCESS,
  payload: isExist,
});

export const checkIsExistEmailRequest = (
  email: string
): CheckIsExistEmailRequestAction => ({
  type: Users.CHECK_IS_EXIST_EMAIL_REQUEST,
  payload: email,
});

export const checkIsExistEmailSuccess = (
  isExist: boolean
): CheckIsExistEmailSuccessAction => ({
  type: Users.CHECK_IS_EXIST_EMAIL_SUCCESS,
  payload: isExist,
});

export const fetchUsersAddByContractId =
  (): FetchUsersAddByContractIdRequestAction => ({
    type: Users.FETCH_USERS_ADD_BY_CONTRACT_ID_REQUEST,
  });

export const checkIsExistPhoneRequest = (
  phone: string
): CheckIsExistPhoneRequestAction => ({
  type: Users.CHECK_IS_EXIST_PHONE_REQUEST,
  payload: phone,
});

export const checkIsExistPhoneSuccess = (
  isExist: boolean
): CheckIsExistPhoneSuccessAction => ({
  type: Users.CHECK_IS_EXIST_PHONE_SUCCESS,
  payload: isExist,
});

export const deleteUserRequest = (
  data: DeleteDataType
): DeleteUserRequestAction => ({
  type: Users.DELETE_USER_BY_ID_REQUEST,
  payload: data,
});

export const resetCurrentUserAfterDelete =
  (): ResetCurrentUserAfterDeleteAction => ({
    type: Users.RESET_CURRENT_USER_AFTER_DELETE,
  });
