import {
  DesktopAdditionalFilter,
  DesktopFilterRequest,
  DesktopFilters,
} from '@entities/desktop/model';
import { getCleanObjectValues } from '@shared';

export const mergeDesktopFilters = (
  baseFilter: DesktopFilters,
  additionalFilter: DesktopAdditionalFilter
): DesktopFilterRequest =>
  getCleanObjectValues({ ...baseFilter, ...additionalFilter });
