import { FC, useState } from 'react';

import { JIRA_TABS } from '../../../../config';
import {
  JiraIntegrationCreateHookType,
  JiraIntegrationTabType,
} from '../../../../model';
import { JiraSynchronization } from '../../../JiraSynchronization';
import { JiraTabs } from '../../../JiraTabs';
import { JiraIntegration } from '../JiraIntegration';

type JiraIntegrationCreateRightFormProps = {
  form: JiraIntegrationCreateHookType;
};

export const JiraIntegrationCreateRightForm: FC<
  JiraIntegrationCreateRightFormProps
> = ({ form }) => {
  const [tab, setTab] = useState<JiraIntegrationTabType>(
    JiraIntegrationTabType.integration
  );

  const { state } = form;
  const { synchronizationProps } = state;

  const toggleTab = (activeKey: JiraIntegrationTabType) => {
    setTab(activeKey);
  };

  const jiraSyncProps = {
    state: {
      control: synchronizationProps.control,
      synchronizationProps,
    },
    methods: {
      getValues: synchronizationProps.getValues,
    },
  };

  const getTab = () => {
    const tabList: Record<JiraIntegrationTabType, JSX.Element> = {
      [JiraIntegrationTabType.integration]: <JiraIntegration form={form} />,
      [JiraIntegrationTabType.synchronization]: (
        <JiraSynchronization jiraSyncProps={jiraSyncProps} />
      ),
    };

    return tabList[tab];
  };

  return (
    <JiraTabs tabs={JIRA_TABS} onClick={toggleTab} activeKey={tab} create>
      {getTab()}
    </JiraTabs>
  );
};
