import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { Organization } from '@entities/organization';
import { createError, ResponseWithMeta } from '@shared';

import { requests } from '../../api/kb-main';
import {
  createCategory,
  deleteArticle,
  deleteCategory,
  editArticle,
  editCategory,
} from '../saga';
import { Article, CategoryArticle } from '../types';

import {
  categoriesLoadingHideArticlesMain,
  categoriesLoadingShowArticlesMain,
  createCategoryRequestArticlesMain,
  deleteArticleRequestArticlesMain,
  deleteCategoryRequestArticlesMain,
  editArticleRequestArticlesMain,
  editCategoryRequestArticlesMain,
  fetchArticlesMainRequest,
  fetchArticlesMainSuccess,
  fetchCategoriesRequestArticlesMain,
  fetchCategoriesSuccessArticlesMain,
  fetchJoinSuccessArticlesMain,
  fetchOrganizationsArticlesMainRequest,
  fetchOrganizationsArticlesMainSuccess,
  loadingHideArticlesMain,
  loadingShowArticlesMain,
  organizationsLoadingHideArticlesMain,
  organizationsLoadingShowArticlesMain,
} from './actions';
import { ArticlesMain } from './actionTypes';
import {
  getFilterArticlesMain,
  getPaginationArticlesMain,
  getSelectedOrgIdArticlesMain,
} from './selectors';

function* articlesMainFetch({
  payload,
}: ReturnType<typeof fetchArticlesMainRequest>) {
  try {
    const filter: ReturnType<typeof getFilterArticlesMain> = yield select(
      getFilterArticlesMain
    );
    const { pageSize }: ReturnType<typeof getPaginationArticlesMain> =
      yield select(getPaginationArticlesMain);
    yield put(loadingShowArticlesMain());
    const articles: ResponseWithMeta<Article[]> = yield call(
      requests.fetchArticlesMainRequest,
      payload?.page,
      pageSize,
      'title_desc',
      filter
    );
    yield put(
      payload?.updateType === 'update'
        ? fetchArticlesMainSuccess(articles)
        : fetchJoinSuccessArticlesMain(articles)
    );
    yield put(loadingHideArticlesMain());
  } catch (e) {
    yield put(loadingHideArticlesMain());
    createError(e);
  }
}

function* organizationsFetch({
  payload,
}: ReturnType<typeof fetchOrganizationsArticlesMainRequest>) {
  try {
    yield put(organizationsLoadingShowArticlesMain());
    const organizations: Organization[] = yield call(
      requests.fetchOrganizationsArticlesMainRequest,
      payload
    );
    yield put(fetchOrganizationsArticlesMainSuccess(organizations));
    yield put(organizationsLoadingHideArticlesMain());
  } catch (e) {
    yield put(organizationsLoadingHideArticlesMain());
    createError(e);
  }
}

function* fetchCategories({
  payload,
}: ReturnType<typeof fetchCategoriesRequestArticlesMain>) {
  try {
    yield put(categoriesLoadingShowArticlesMain());
    const categories: CategoryArticle[] = yield call(
      requests.fetchCategoriesRequest,
      payload
    );
    yield put(fetchCategoriesSuccessArticlesMain(categories));
    yield put(categoriesLoadingHideArticlesMain());
  } catch (e) {
    yield put(categoriesLoadingHideArticlesMain());
    yield put(fetchCategoriesSuccessArticlesMain([]));
    createError(e);
  }
}

function* createCategoryFunction({
  payload,
}: ReturnType<typeof createCategoryRequestArticlesMain>) {
  try {
    const orgIds: string[] = yield select(getSelectedOrgIdArticlesMain);
    yield call(createCategory, payload);
    yield put(fetchCategoriesRequestArticlesMain(orgIds));
  } catch (e) {
    createError(e);
  }
}

function* editCategoryFunction({
  payload,
}: ReturnType<typeof editCategoryRequestArticlesMain>) {
  try {
    const orgIds: string[] = yield select(getSelectedOrgIdArticlesMain);
    yield call(editCategory, payload);
    yield put(fetchCategoriesRequestArticlesMain(orgIds));
  } catch (e) {
    createError(e);
  }
}

function* deleteCategoryFunction({
  payload,
}: ReturnType<typeof deleteCategoryRequestArticlesMain>) {
  try {
    const orgIds: string[] = yield select(getSelectedOrgIdArticlesMain);
    yield call(deleteCategory, payload);
    yield put(fetchCategoriesRequestArticlesMain(orgIds));
  } catch (e) {
    createError(e);
  }
}

function* editArticleFunction({
  payload,
}: ReturnType<typeof editArticleRequestArticlesMain>) {
  try {
    const orgIds: string[] = yield select(getSelectedOrgIdArticlesMain);
    yield call(editArticle, payload);
    yield put(fetchCategoriesRequestArticlesMain(orgIds));
  } catch (e) {
    createError(e);
  }
}

function* deleteArticleFunction({
  payload,
}: ReturnType<typeof deleteArticleRequestArticlesMain>) {
  try {
    const orgIds: string[] = yield select(getSelectedOrgIdArticlesMain);
    yield call(deleteArticle, payload);
    yield put(fetchCategoriesRequestArticlesMain(orgIds));
  } catch (e) {
    createError(e);
  }
}

export function* articlesMainSaga(): Generator<StrictEffect> {
  yield takeEvery(ArticlesMain.FETCH_REQUEST_ARTICLES_MAIN, articlesMainFetch);
  yield takeEvery(
    ArticlesMain.FETCH_ORGANIZATIONS_REQUEST_ARTICLES_MAIN,
    organizationsFetch
  );
  yield takeEvery(
    ArticlesMain.FETCH_CATEGORIES_REQUEST_ARTICLES_MAIN,
    fetchCategories
  );
  yield takeEvery(
    ArticlesMain.CREATE_CATEGORY_REQUEST_ARTICLES_MAIN,
    createCategoryFunction
  );
  yield takeEvery(
    ArticlesMain.EDIT_CATEGORY_REQUEST_ARTICLES_MAIN,
    editCategoryFunction
  );
  yield takeEvery(
    ArticlesMain.DELETE_CATEGORY_REQUEST_ARTICLES_MAIN,
    deleteCategoryFunction
  );
  yield takeEvery(
    ArticlesMain.EDIT_ARTICLE_REQUEST_ARTICLES_MAIN,
    editArticleFunction
  );
  yield takeEvery(
    ArticlesMain.DELETE_ARTICLE_REQUEST_ARTICLES_MAIN,
    deleteArticleFunction
  );
}
