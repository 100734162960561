import { MouseEvent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  fetchTicketsRequestCurrentState,
  fetchTicketsRequestHistory,
} from '@entities/desktop';
import { setTicketsFilter, TicketsFilterForRender } from '@entities/ticket';
import {
  CURRENT_TICKETS_REPORT_FILTER_FIELD,
  HISTORY_TICKETS_REPORT_FILTER_FIELD,
  INITIAL_TICKETS_REPORT_VALUES,
} from '@features/ticket/config';
import { checkPathnameWithTrailingSlash, RouterHref } from '@shared';

export const useTicketsFilterDesktop = () => {
  const { control, handleSubmit, reset } = useForm<TicketsFilterForRender>({
    defaultValues: INITIAL_TICKETS_REPORT_VALUES,
  });

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const isDesktopCurrentPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.DesktopCurrentState
  );

  const isDesktopHistoryPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.DesktopHistory
  );

  const currentTicketReportFilters = isDesktopCurrentPage
    ? CURRENT_TICKETS_REPORT_FILTER_FIELD
    : [];

  const historyReportFilter = isDesktopHistoryPage
    ? HISTORY_TICKETS_REPORT_FILTER_FIELD
    : [];

  const formSubmitHandler = handleSubmit((data) => {
    dispatch(setTicketsFilter(data));
    if (isDesktopCurrentPage) {
      dispatch(fetchTicketsRequestCurrentState());
    }
    if (isDesktopHistoryPage) {
      dispatch(fetchTicketsRequestHistory());
    }
  });

  const resetFilter = (event?: MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
    }
    reset();
    dispatch(setTicketsFilter(INITIAL_TICKETS_REPORT_VALUES));
    if (isDesktopCurrentPage) {
      dispatch(fetchTicketsRequestCurrentState());
    }
    if (isDesktopHistoryPage) {
      dispatch(fetchTicketsRequestHistory());
    }
  };

  useEffect(
    () => () => {
      dispatch(setTicketsFilter(INITIAL_TICKETS_REPORT_VALUES));
      reset();
    },
    []
  );

  return {
    state: { currentTicketReportFilters, historyReportFilter },
    methods: { control, formSubmitHandler, resetFilter },
  };
};
