import { FC } from 'react';

import { TypographyNew, TypographyVariantsNew } from '@shared/ui-new';

import styles from './SaveOrganizationTitle.module.scss';

type SaveOrganizationTitleProps = {
  title: string;
};

export const SaveOrganizationTitle: FC<SaveOrganizationTitleProps> = ({
  title,
}) => (
  <div className={styles.saveOrganizationTitle}>
    <TypographyNew variant={TypographyVariantsNew.b2}>
      Организация с похожим названием {`"${title}"`} уже <br />
      существует. Для доступа к существующей организации <br />
      необходимо обратиться к администратору.
    </TypographyNew>
    <TypographyNew variant={TypographyVariantsNew.b2}>
      Вы уверены, что хотите создать новую?
    </TypographyNew>
  </div>
);
