import { Reducer } from 'redux';

import { Contract } from '@entities/contract/model/contract/types';
import { SLAType } from '@entities/sla/model/types';
import { System } from '@entities/system/model';
import { DEFAULT_PAGINATION_PARAMS, PaginationType, ReducerMap } from '@shared';

import { DEFAULT_FILTER } from '../../config';

import {
  Contracts,
  ContractsActionsTypes,
  FetchContractsSuccessAction,
  FetchContractSuccessAction,
  FetchSLAByContractIdBySystemIdSuccessContractsAction,
  SetContractFilterAction,
  SetCurrentContractIdAction,
  SetCurrentContractsPageAction,
  SetSizePageAction,
  SetSortContractsAction,
  SetSystemsForContractAction,
} from './acttionTypes';
import { ContractsFilter } from './types';

export interface ContractsReducerState {
  contracts?: Contract[];
  contract?: Contract;
  loading?: boolean;
  loadingContract?: boolean;
  pagination?: PaginationType;
  sort?: string;
  contractId?: string;
  contractsFilter?: ContractsFilter;
  systemsForContract?: System[];
  sla?: SLAType;
}

const initialState: ContractsReducerState = {
  contracts: [],
  loading: false,
  loadingContract: false,
  pagination: DEFAULT_PAGINATION_PARAMS,
  sort: 'date_create_DESC',
  systemsForContract: [],
  contractsFilter: DEFAULT_FILTER,
};

const contractReducerMap: ReducerMap<
  ContractsReducerState,
  ContractsActionsTypes
> = {
  [Contracts.CONTRACTS_LOADING_SHOW]: (state) => ({
    ...state,
    loading: true,
  }),
  [Contracts.CONTRACTS_LOADING_HIDE]: (state) => ({
    ...state,
    loading: false,
  }),
  [Contracts.CONTRACT_LOADING_SHOW]: (state) => ({
    ...state,
    loadingContract: true,
  }),
  [Contracts.CONTRACT_LOADING_HIDE]: (state) => ({
    ...state,
    loadingContract: false,
  }),
  [Contracts.FETCH_CONTRACTS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchContractsSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      contracts: content,
      pagination: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [Contracts.SET_CURRENT_CONTRACTS_PAGE]: (state, action) => {
    const { payload } = action as SetCurrentContractsPageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageNum: payload,
      },
    };
  },
  [Contracts.SET_SIZE_PAGE_CONTRACTS]: (state, action) => {
    const { payload } = action as SetSizePageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageSize: payload,
      },
    };
  },
  [Contracts.SET_SORT_CONTRACTS]: (state, action) => {
    const { payload } = action as SetSortContractsAction;
    return {
      ...state,
      sort: payload,
    };
  },
  [Contracts.FETCH_CONTRACT_SUCCESS]: (state, action) => {
    const { payload } = action as FetchContractSuccessAction;
    return {
      ...state,
      contract: payload,
    };
  },
  [Contracts.RESET_CONTRACTS_STATE]: () => ({
    ...initialState,
  }),
  [Contracts.SET_CURRENT_CONTRACT_ID]: (state, action) => {
    const { payload } = action as SetCurrentContractIdAction;
    return {
      ...state,
      contractId: payload,
    };
  },
  [Contracts.SET_CONTRACT_FILTER]: (state, action) => {
    const { payload } = action as SetContractFilterAction;
    return {
      ...state,
      contractsFilter: payload,
    };
  },
  [Contracts.SET_SYSTEMS_FOR_CONTRACT]: (state, action) => {
    const { payload } = action as SetSystemsForContractAction;
    return {
      ...state,
      systemsForContract: payload,
    };
  },
  [Contracts.RESET_CURRENT_CONTRACT]: (state) => ({
    ...state,
    contract: initialState.contract,
    contractId: initialState.contractId,
    pagination: initialState.pagination,
  }),
  [Contracts.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS]: (
    state,
    action
  ) => {
    const { payload } =
      action as FetchSLAByContractIdBySystemIdSuccessContractsAction;
    return {
      ...state,
      sla: payload,
    };
  },
  [Contracts.RESET_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID]: (state) => ({
    ...state,
    sla: undefined,
  }),
};

export const contractsSlice: Reducer<
  ContractsReducerState,
  ContractsActionsTypes
> = (state = initialState, action) => {
  const reducer = contractReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
