import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

import { JiraIntegrationEditData } from '@entities/jira-integrations';
import {
  Accordion,
  ArrowHorizontalIcon,
  getValueFromValueType,
  Select,
  SelectOption,
  Size,
  ValueType,
} from '@shared';

import styles from './JiraIntegrationsTicketTypes.module.scss';

interface JiraIntegrationsTicketTypesProps {
  ticketTypeId: ValueType<string> | undefined;
  jiraTicketTypesOptions: SelectOption<number>[];
  control: Control<JiraIntegrationEditData>;
  isEditMode: boolean;
  fetchJiraStatuses: () => void;
  fetchJiraStatusesByPropertyId: (value: string) => void;
  resetStatuses: () => void;
}

export const JiraIntegrationsTicketTypes: FC<
  JiraIntegrationsTicketTypesProps
> = ({
  ticketTypeId,
  jiraTicketTypesOptions,
  control,
  isEditMode,
  fetchJiraStatuses,
  fetchJiraStatusesByPropertyId,
  resetStatuses,
}) => (
  <Accordion title="Маппинг типов тикета" defaultHidden>
    <div className={styles.jiraIntegrationsTicketTypes}>
      <div className={styles.jiraIntegrationsTicketTypes__content}>
        <Select
          size={Size.xs}
          value={ticketTypeId}
          options={[]}
          disabled
          className={styles.jiraIntegrationsTicketTypes__selectType}
        />
        <div className={styles.jiraIntegrationsTicketTypes__selectWrapper}>
          <ArrowHorizontalIcon
            className={styles.jiraIntegrationsTicketTypes__icon}
          />
          <Controller
            control={control}
            rules={{ required: true }}
            name="jiraTicketType"
            render={({ field }) => (
              <Select<number>
                size={Size.xs}
                mobileModalTitle="тип тикета"
                options={jiraTicketTypesOptions}
                disabled={!isEditMode}
                onChange={(value) => {
                  fetchJiraStatuses();
                  field.onChange(value);
                  resetStatuses();
                  fetchJiraStatusesByPropertyId(
                    getValueFromValueType(value)?.toString() ?? ''
                  );
                }}
                value={field.value}
                isTooltip={false}
                className={styles.jiraIntegrationsTicketTypes__select}
              />
            )}
          />
        </div>
      </div>
    </div>
  </Accordion>
);
