import cn from 'clsx';
import DOMPurify from 'dompurify';
import { nanoid } from 'nanoid';
import { useSelector } from 'react-redux';

import { ParentType } from '@entities/attachment/model/types';
import {
  FileChip,
  Solution,
  Tag,
  Typography,
  TypographyVariants,
} from '@shared';

import { getSimilarTicketAttachments, getTicket } from '../../model/selectors';

import styles from './SimilarTicketContent.module.scss';

export const SimilarTicketContent = () => {
  const ticket = useSelector(getTicket);
  const ticketAttachments = useSelector(getSimilarTicketAttachments);

  const attachments = Boolean(ticketAttachments?.length) && (
    <div className={styles.ticketInfo__attachments}>
      {ticketAttachments
        ?.find(
          (ticketAttachment) =>
            ticketAttachment.parentType === ParentType.TICKET
        )
        ?.attachmentsFiles?.map((file) => (
          <FileChip
            key={nanoid()}
            title={file.fileName}
            className={styles.ticketInfo__attachmentsItem}
            filePath={file.path}
          />
        ))}
    </div>
  );

  return (
    <div className={styles.similarTicketContent}>
      <Tag
        text={ticket?.ticketType?.value}
        className={styles.similarTicketContent__tag}
      />
      <Typography
        variant={TypographyVariants.h3}
        className={styles.similarTicketContent__title}
      >
        {ticket?.theme}
      </Typography>
      <p
        className={cn('ck-content', styles.similarTicketContent__description)}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(ticket?.description || ''),
        }}
      />
      {attachments}
      {ticket?.solution?.textSolution && (
        <Solution text={ticket?.solution?.textSolution} withCopy />
      )}
    </div>
  );
};
