import cn from 'clsx';
import { ChangeEvent, FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ADD_MODAL_FROM_GROUPS_FIELDS,
  fetchUsersAddByOrgId,
  getLoadingAdd,
  getPropsUsersAdd,
  getUsersAdd,
  getUsersAddFilter,
  resetUsersAddState,
  setSizePageUsersAdd,
  setUsersAddFilter,
  setUsersAddPage,
  TableUsersTypes,
  User,
  UsersFilter,
  UsersFilterData,
} from '@entities/user';
import { getWorkGroup, updateWorkGroup } from '@entities/work-group';
import {
  AddModal,
  Checkbox,
  checkObjectIdentity,
  MailIcon,
  NotificationLink,
  PhoneIcon,
  UserIcon,
} from '@shared';

import styles from './UsersAddToGroup.module.scss';

const getDataTable = (
  changeCheckbox: (
    item: User
  ) => (event: ChangeEvent<HTMLInputElement>) => void,
  selected: string[],
  data?: User[]
) =>
  data?.map((user) => {
    const { login, firstName, lastName, middleName, id, email, phoneNumber } =
      user;
    return {
      checkbox: (
        <Checkbox
          name="rolesCheckbox"
          onChange={changeCheckbox(user)}
          checked={selected.includes(id || '')}
        />
      ),
      id,
      login: {
        icon: (
          <UserIcon
            className={cn(
              styles.usersAddToGroup__icon,
              styles.usersAddToGroup__icon_blue
            )}
          />
        ),
        children: <NotificationLink to={`/admin/users/${id}`} title={login} />,
        to: `/admin/users/${id}`,
      },
      firstName,
      lastName,
      middleName,
      email: {
        text: email,
        icon: <MailIcon className={styles.usersAddToGroup__icon} />,
      },
      phoneNumber: {
        text: phoneNumber,
        icon: <PhoneIcon className={styles.usersAddToGroup__icon} />,
      },
    };
  }) || [];

interface UsersAddToGroupProps {
  isModal: boolean;
  toggleModal: () => void;
}

export const UsersAddToGroup: FC<UsersAddToGroupProps> = ({
  isModal,
  toggleModal,
}) => {
  const dispatch = useDispatch();
  const usersAll = useSelector(getUsersAdd) || [];
  const groupData = useSelector(getWorkGroup);
  const filterAddValues = useSelector(getUsersAddFilter);
  const dataLoading = useSelector(getLoadingAdd);
  const { pageNum, pageSize, totalElements } = useSelector(getPropsUsersAdd);

  const isFilterActive = !checkObjectIdentity(filterAddValues, {});

  useEffect(
    () => () => {
      dispatch(resetUsersAddState());
    },
    []
  );

  const onSubmit = (data: User[]) => {
    if (groupData) {
      dispatch(
        updateWorkGroup({
          ...groupData,
          userList: data,
        })
      );
    }
    toggleModal();
  };

  const handleChangePage = (page: number) => {
    dispatch(setUsersAddPage(page));
    dispatch(fetchUsersAddByOrgId());
  };

  const onChangeFilter = (filter: UsersFilterData) => {
    dispatch(
      setUsersAddFilter({
        ...filter,
        idsUsersDelete: filterAddValues.idsUsersDelete,
      })
    );
    dispatch(setUsersAddPage(0));
    dispatch(fetchUsersAddByOrgId());
  };

  const handleChangePageSize = (size: number) => {
    dispatch(setSizePageUsersAdd(size));
  };

  const onChangeIdsForDelete = (data: User[]) => {
    dispatch(
      setUsersAddFilter({
        ...filterAddValues,
        idsUsersDelete: data.map((item) => item.id),
      })
    );
    dispatch(fetchUsersAddByOrgId());
  };

  return (
    <AddModal<User>
      leftData={usersAll}
      rightData={groupData?.userList || []}
      getDataTable={getDataTable}
      classNameLeftTable={styles.usersAddToGroup__leftTable}
      columns={ADD_MODAL_FROM_GROUPS_FIELDS}
      mainTitle="пользователей"
      subModalText="пользователя"
      leftTableTitle="Все пользователи"
      isModal={isModal}
      toggleModal={toggleModal}
      loadingData={dataLoading}
      onSubmit={onSubmit}
      isFilterActive={isFilterActive}
      onChangeIdsForDelete={onChangeIdsForDelete}
      paginationLeftTableProps={{
        pageNum,
        pageSize,
        elementsCount: totalElements,
        handleChangePage,
        handleChangePageSize,
      }}
      filterComponent={
        <UsersFilter
          tableType={TableUsersTypes.ADD_MODAL}
          onFilter={onChangeFilter}
        />
      }
    />
  );
};
