import cn from 'clsx';
import { debounce } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  AddEntityModal,
  Checkbox,
  DataEmpty,
  DEFAULT_DEBOUNCE_DELAY,
  Input,
  Loader,
  SearchBigIcon,
  Size,
  Typography,
  TypographyVariants,
} from '@shared';

import {
  fetchCustomFieldsExportRequest,
  fetchSystemsExportRequest,
  getCustomFieldsLoading,
  getCustomFieldsTicketsExport,
  getSelectedCustomFields,
  getSystems,
  getSystemsLoading,
  resetCustomFieldsExport,
  resetSelectedCustomFields,
  selectAllCustomFields,
  selectCustomField,
  setExtraExportFields,
} from '../../model';

import styles from './AddCustomFieldsModal.module.scss';

interface AddCustomFieldsModalProps {
  isModal: boolean;
  toggleIsModal(): void;
  classNameFormPortal?: string;
  classNameFormPortalCancel?: string;
}

export const AddCustomFieldsModal: FC<AddCustomFieldsModalProps> = ({
  isModal,
  toggleIsModal,
  classNameFormPortal,
  classNameFormPortalCancel,
}) => {
  const systems = useSelector(getSystems);
  const systemsLoading = useSelector(getSystemsLoading);
  const customFields = useSelector(getCustomFieldsTicketsExport);
  const customFieldsLoading = useSelector(getCustomFieldsLoading);
  const selectedCustomFields = useSelector(getSelectedCustomFields);

  const [activeSystemId, setActiveSystemId] = useState<string | null>();

  const dispatch = useDispatch();

  const { register, handleSubmit, watch } = useForm<{ title: string }>({
    mode: 'onChange',
    defaultValues: {
      title: '',
    },
  });

  const { title } = watch();

  const formSubmitHandler = handleSubmit((data) => {
    dispatch(fetchSystemsExportRequest(data.title));
  });

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    []
  );

  const titleInput = register('title', {
    onChange: formSubmitHandlerDebounced,
  });

  const isAllChecked = useMemo(
    () =>
      !!customFields?.length &&
      customFields.length === selectedCustomFields?.length,
    [customFields?.length, selectedCustomFields?.length]
  );

  const changeActiveSystemIdHandler = (systemId?: string) => {
    if (systemId) {
      setActiveSystemId(systemId);
    }
  };

  const onSubmitModal = () => {
    dispatch(setExtraExportFields(selectedCustomFields));
    toggleIsModal();
  };

  const selectAllCustomFieldsHandler = () => {
    dispatch(selectAllCustomFields());
  };

  useEffect(() => {
    if (isModal) {
      dispatch(fetchSystemsExportRequest(title));
    }
  }, [isModal]);

  useEffect(() => {
    const isSystemId = systems?.find((system) => system.id === activeSystemId);

    if (!isSystemId && activeSystemId) {
      setActiveSystemId(null);
    }
  }, [systems, activeSystemId]);

  useEffect(() => {
    if (activeSystemId) {
      dispatch(fetchCustomFieldsExportRequest(activeSystemId));
      dispatch(resetSelectedCustomFields());
    }
    if (!activeSystemId && customFields?.length) {
      dispatch(resetCustomFieldsExport());
      dispatch(resetSelectedCustomFields());
    }
  }, [activeSystemId]);

  const systemsContent =
    !systemsLoading &&
    !!systems?.length &&
    systems?.map((system) => (
      <li
        key={system.id}
        aria-hidden
        onClick={() => changeActiveSystemIdHandler(system.id)}
        className={cn(styles.addCustomFieldsModal__systemsListElement, {
          [styles.addCustomFieldsModal__systemsListElement_active]:
            activeSystemId === system.id,
        })}
      >
        {system.title}
      </li>
    ));

  const systemsLoader = !!systemsLoading && <Loader />;

  const systemsDataEmpty = !title.length &&
    !systemsLoading &&
    !systems?.length && <DataEmpty />;

  const systemsSearchDataEmpty = !!title.length &&
    !systemsLoading &&
    !systems?.length && (
      <div className={styles.addCustomFieldsModal__noSearchWrapper}>
        <SearchBigIcon className={styles.addCustomFieldsModal__noSearchIcon} />
        <Typography
          variant={TypographyVariants.h3}
          className={styles.addCustomFieldsModal__noSearchTitle}
        >
          Ничего не найдено
        </Typography>
        <Typography
          variant={TypographyVariants.s2}
          className={styles.addCustomFieldsModal__noSearchText}
        >
          Попробуйте изменить запрос
        </Typography>
      </div>
    );

  const customFieldsContent =
    !customFieldsLoading &&
    !!customFields?.length &&
    customFields?.map((customField) => {
      const selectCustomFieldHandler = () => {
        dispatch(selectCustomField(customField));
      };

      const checked = !!selectedCustomFields?.find(
        (selectedCustomField) => selectedCustomField.id === customField.id
      );

      return (
        <div
          key={customField.id}
          className={styles.addCustomFieldsModal__checkboxContainer}
        >
          <Checkbox
            key={customField.id}
            onChange={selectCustomFieldHandler}
            checked={checked}
            className={styles.addCustomFieldsModal__checkbox}
          />
          <Input label={customField.title} disabled />
        </div>
      );
    });

  const customFieldsLoader = customFieldsLoading && <Loader />;

  const customFieldsDataEmpty = !customFieldsLoading &&
    !customFields?.length && <DataEmpty />;

  return (
    <AddEntityModal
      toggleModal={toggleIsModal}
      isModal={isModal}
      title="Добавить кастомное поле"
      subModalText="кастомных полей"
      onSubmit={onSubmitModal}
      disabledSubmit={false}
      classNameFormPortal={classNameFormPortal}
      classNameFormPortalCancel={classNameFormPortalCancel}
      classNameForm={styles.addCustomFieldsModal}
      classNameButtonsBlock={styles.addCustomFieldsModal__buttonsBlock}
      bottomElement={
        <span className={styles.addCustomFieldsModal__footerTitle}>
          {`Выбрано ${selectedCustomFields?.length || 0} полей`}
        </span>
      }
      withHideBodyScroll={false}
      withHideBodyScrollSubModal={false}
    >
      <div className={styles.addCustomFieldsModal__content}>
        <div className={styles.addCustomFieldsModal__leftContainer}>
          <Typography
            variant={TypographyVariants.h4}
            className={styles.addCustomFieldsModal__title}
          >
            Выберите систему
          </Typography>
          <Input
            {...titleInput}
            size={Size.s}
            label="Поиск"
            className={styles.addCustomFieldsModal__input}
          />
          <ul
            className={cn(styles.addCustomFieldsModal__systemsList, {
              [styles.addCustomFieldsModal__systemsList_loading]:
                systemsLoading,
            })}
          >
            {systemsContent}
            {systemsLoader}
            {systemsDataEmpty}
            {systemsSearchDataEmpty}
          </ul>
        </div>
        <div className={styles.addCustomFieldsModal__rightContainer}>
          <Typography
            variant={TypographyVariants.h4}
            className={styles.addCustomFieldsModal__title}
          >
            Кастомные поля
          </Typography>
          <div
            className={cn(
              styles.addCustomFieldsModal__checkboxContainer,
              styles.addCustomFieldsModal__checkboxContainer_checkAll
            )}
          >
            <Checkbox
              label="Выбрать все"
              checked={isAllChecked}
              onChange={selectAllCustomFieldsHandler}
              className={styles.addCustomFieldsModal__checkbox}
            />
          </div>
          <div
            className={cn(styles.addCustomFieldsModal__fieldsList, {
              [styles.addCustomFieldsModal__fieldsList_loading]:
                customFieldsLoading,
            })}
          >
            {customFieldsContent}
            {customFieldsDataEmpty}
            {customFieldsLoader}
          </div>
        </div>
      </div>
    </AddEntityModal>
  );
};
