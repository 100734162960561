import { Organization } from '@entities/organization';
import { ResponseWithMeta, SelectOption } from '@shared';

import {
  Article,
  CategoryArticle,
  FetchArticlesParams,
  KBArticleTableFilter,
  KBArticleTableFilterToRequest,
  KBOrganizationsFilter,
} from '../types';

import { KBDataTable } from './types';

export enum ArticlesTable {
  FETCH_ARTICLES_REQUEST_ARTICLES_TABLE = 'KB_TABLE/FETCH_ARTICLES_REQUEST_ARTICLES_TABLE',
  FETCH_ARTICLES_SUCCESS_ARTICLES_TABLE = 'KB_TABLE/FETCH_ARTICLES_SUCCESS_ARTICLES_TABLE',

  FETCH_CATEGORY_REQUEST_ARTICLES_TABLE = 'KB_TABLE/FETCH_CATEGORY_REQUEST_ARTICLES_TABLE',
  FETCH_CATEGORY_SUCCESS_ARTICLES_TABLE = 'KB_TABLE/FETCH_CATEGORY_SUCCESS_ARTICLES_TABLE',

  ARTICLES_TABLE_LOADING_SHOW = 'KB_TABLE/ARTICLES_TABLE_LOADING_SHOW',
  ARTICLES_TABLE_LOADING_HIDE = 'KB_TABLE/ARTICLES_TABLE_LOADING_HIDE',

  SET_CURRENT_ARTICLES_PAGE_ARTICLES_TABLE = 'KB_TABLE/SET_CURRENT_ARTICLES_PAGE_ARTICLES_TABLE',
  SET_SORT_ARTICLES_ARTICLES_TABLE = 'KB_TABLE/SET_SORT_ARTICLES_ARTICLES_TABLE',
  SET_SIZE_PAGE_ARTICLES_ARTICLES_TABLE = 'KB_TABLE/SET_SIZE_PAGE_ARTICLES_ARTICLES_TABLE',

  SET_CURRENT_ARTICLE_ID_ARTICLES_TABLE = 'KB_TABLE/SET_CURRENT_ARTICLE_ID_ARTICLES_TABLE',

  RESET_ONLY_TABLE_STATE_ARTICLES_TABLE = 'KB_TABLE/RESET_ONLY_TABLE_STATE_ARTICLES_TABLE',
  RESET_STATE_ARTICLES_TABLE = 'KB_TABLE/RESET_STATE_ARTICLES_TABLE',

  SET_ARTICLES_FILTER_ARTICLES_TABLE = 'KB_TABLE/SET_ARTICLES_FILTER_ARTICLES_TABLE',

  FETCH_ORGANIZATIONS_REQUEST_ARTICLES_TABLE = 'KB_TABLE/FETCH_ORGANIZATIONS_REQUEST_ARTICLES_TABLE',
  FETCH_ORGANIZATIONS_SUCCESS_ARTICLES_TABLE = 'KB_TABLE/FETCH_ORGANIZATIONS_SUCCESS_ARTICLES_TABLE',

  DELETE_ARTICLE_BY_ID_REQUEST_ARTICLES_TABLE = 'KB_TABLE/DELETE_ARTICLE_BY_ID_REQUEST_ARTICLES_TABLE',

  SET_FILTER_ARTICLES_RIGHT_ARTICLES_TABLE = 'KB_TABLE/SET_FILTER_ARTICLES_RIGHT_ARTICLES_TABLE',

  FETCH_REQUEST_ARTICLES_RIGHT_ARTICLES_TABLE = 'KB_TABLE/FETCH_REQUEST_ARTICLES_RIGHT_ARTICLES_TABLE',
  FETCH_SUCCESS_ARTICLES_RIGHT_ARTICLES_TABLE = 'KB_TABLE/FETCH_SUCCESS_ARTICLES_RIGHT_ARTICLES_TABLE',
  FETCH_ARTICLES_JOIN_SUCCESS_RIGHT_ARTICLES_TABLE = 'KB_TABLE/FETCH_ARTICLES_JOIN_SUCCESS_RIGHT_ARTICLES_TABLE',
  LOADING_SHOW_ARTICLES_RIGHT_ARTICLES_TABLE = 'KB_TABLE/LOADING_SHOW_ARTICLES_RIGHT_ARTICLES_TABLE',
  LOADING_HIDE_ARTICLES_RIGHT_ARTICLES_TABLE = 'KB_TABLE/LOADING_HIDE_ARTICLES_RIGHT_ARTICLES_TABLE',
  SET_ARTICLES_PAGE_RIGHT_ARTICLES_TABLE = 'KB_TABLE/SET_ARTICLES_PAGE_RIGHT_ARTICLES_TABLE',
  RESET_ARTICLES_STATE_RIGHT_ARTICLES_TABLE = 'KB_TABLE/RESET_ARTICLES_STATE_RIGHT_ARTICLES_TABLE',

  FETCH_ORGANIZATIONS_REQUEST_RIGHT_ARTICLES_TABLE = 'KB_TABLE/FETCH_ORGANIZATIONS_REQUEST_RIGHT_ARTICLES_TABLE',
  FETCH_ORGANIZATIONS_SUCCESS_RIGHT_ARTICLES_TABLE = 'KB_TABLE/FETCH_ORGANIZATIONS_SUCCESS_RIGHT_ARTICLES_TABLE',
  ORGANIZATIONS_LOADING_SHOW_RIGHT_ARTICLES_TABLE = 'KB_TABLE/ORGANIZATIONS_LOADING_SHOW_RIGHT_ARTICLES_TABLE',
  ORGANIZATIONS_LOADING_HIDE_RIGHT_ARTICLES_TABLE = 'KB_TABLE/ORGANIZATIONS_LOADING_HIDE_RIGHT_ARTICLES_TABLE',

  FETCH_CATEGORIES_REQUEST_RIGHT_ARTICLES_TABLE = 'KB_TABLE/FETCH_CATEGORIES_REQUEST_RIGHT_ARTICLES_TABLE',
  FETCH_CATEGORIES_SUCCESS_RIGHT_ARTICLES_TABLE = 'KB_TABLE/FETCH_CATEGORIES_SUCCESS_RIGHT_ARTICLES_TABLE',
  CATEGORIES_LOADING_SHOW_RIGHT_ARTICLES_TABLE = 'KB_TABLE/CATEGORIES_LOADING_SHOW_RIGHT_ARTICLES_TABLE',
  CATEGORIES_LOADING_HIDE_RIGHT_ARTICLES_TABLE = 'KB_TABLE/CATEGORIES_LOADING_HIDE_RIGHT_ARTICLES_TABLE',
  RESET_CATEGORIES_STATE_RIGHT_ARTICLES_TABLE = 'KB_TABLE/RESET_CATEGORIES_STATE_RIGHT_ARTICLES_TABLE',

  SET_ORGANIZATION_SELECTED_RIGHT_ARTICLES_TABLE = 'KB_TABLE/SET_ORGANIZATION_SELECTED_RIGHT_ARTICLES_TABLE',
  SET_SEARCH_VALUE_RIGHT_ARTICLES_TABLE = 'KB_TABLE/SET_SEARCH_VALUE_RIGHT_ARTICLES_TABLE',

  CREATE_CATEGORY_REQUEST_RIGHT_ARTICLES_TABLE = 'KB_TABLE/CREATE_CATEGORY_REQUEST_RIGHT_ARTICLES_TABLE',
  EDIT_CATEGORY_REQUEST_RIGHT_ARTICLES_TABLE = 'KB_TABLE/EDIT_CATEGORY_REQUEST_RIGHT_ARTICLES_TABLE',
  DELETE_CATEGORY_REQUEST_RIGHT_ARTICLES_TABLE = 'KB_TABLE/DELETE_CATEGORY_REQUEST_RIGHT_ARTICLES_TABLE',

  EDIT_ARTICLE_REQUEST_RIGHT_ARTICLES_TABLE = 'KB_TABLE/EDIT_ARTICLE_REQUEST_RIGHT_ARTICLES_TABLE',
  DELETE_ARTICLE_REQUEST_RIGHT_ARTICLES_TABLE = 'KB_TABLE/DELETE_ARTICLE_REQUEST_RIGHT_ARTICLES_TABLE',
}
export interface FetchArticlesTableRequestAction {
  type: ArticlesTable.FETCH_ARTICLES_REQUEST_ARTICLES_TABLE;
  payload: string;
}

export interface FetchArticlesTableSuccessAction {
  type: ArticlesTable.FETCH_ARTICLES_SUCCESS_ARTICLES_TABLE;
  payload: ResponseWithMeta<Article[]>;
}

export interface FetchArticlesTableCategoryRequestAction {
  type: ArticlesTable.FETCH_CATEGORY_REQUEST_ARTICLES_TABLE;
  payload: string;
}

export interface FetchArticlesTableCategorySuccessAction {
  type: ArticlesTable.FETCH_CATEGORY_SUCCESS_ARTICLES_TABLE;
  payload: CategoryArticle;
}

export interface ShowArticlesTableLoadingAction {
  type: ArticlesTable.ARTICLES_TABLE_LOADING_SHOW;
}

export interface HideArticlesTableLoadingAction {
  type: ArticlesTable.ARTICLES_TABLE_LOADING_HIDE;
}

export interface SetCurrentArticlesTablePageAction {
  type: ArticlesTable.SET_CURRENT_ARTICLES_PAGE_ARTICLES_TABLE;
  payload: number;
}

export interface SetSortArticlesTableAction {
  type: ArticlesTable.SET_SORT_ARTICLES_ARTICLES_TABLE;
  payload: string;
}

export interface SetSizePageAction {
  type: ArticlesTable.SET_SIZE_PAGE_ARTICLES_ARTICLES_TABLE;
  payload: number;
}

export interface SetCurrentArticleTableIdAction {
  type: ArticlesTable.SET_CURRENT_ARTICLE_ID_ARTICLES_TABLE;
  payload?: KBDataTable;
}

export interface ResetOnlyTableArticlesTableStateAction {
  type: ArticlesTable.RESET_ONLY_TABLE_STATE_ARTICLES_TABLE;
}
export interface ResetArticlesTableStateAction {
  type: ArticlesTable.RESET_STATE_ARTICLES_TABLE;
}

export interface SetArticlesTableFilterAction {
  type: ArticlesTable.SET_ARTICLES_FILTER_ARTICLES_TABLE;
  payload: KBArticleTableFilter;
}

export interface FetchOrganizationsRequestArticleTableAction {
  type: ArticlesTable.FETCH_ORGANIZATIONS_REQUEST_ARTICLES_TABLE;
  payload: KBOrganizationsFilter;
}

export interface FetchOrganizationsSuccessArticleTableAction {
  type: ArticlesTable.FETCH_ORGANIZATIONS_SUCCESS_ARTICLES_TABLE;
  payload: Organization[];
}

export interface DeleteArticlesTableRequestAction {
  type: ArticlesTable.DELETE_ARTICLE_BY_ID_REQUEST_ARTICLES_TABLE;
  payload: string;
}

export interface SetArticlesFilterRightArticlesTableAction {
  type: ArticlesTable.SET_FILTER_ARTICLES_RIGHT_ARTICLES_TABLE;
  payload: KBArticleTableFilterToRequest;
}

export interface FetchArticlesRightArticlesTableRequestAction {
  type: ArticlesTable.FETCH_REQUEST_ARTICLES_RIGHT_ARTICLES_TABLE;
  payload: FetchArticlesParams;
}

export interface FetchArticlesSuccessRightArticlesTableAction {
  type: ArticlesTable.FETCH_SUCCESS_ARTICLES_RIGHT_ARTICLES_TABLE;
  payload: ResponseWithMeta<Article[]>;
}

export interface FetchArticlesJoinSuccessRightArticlesTableAction {
  type: ArticlesTable.FETCH_ARTICLES_JOIN_SUCCESS_RIGHT_ARTICLES_TABLE;
  payload: ResponseWithMeta<Article[]>;
}

export interface SetArticlesPageRightArticlesTableAction {
  type: ArticlesTable.SET_ARTICLES_PAGE_RIGHT_ARTICLES_TABLE;
  payload: number;
}

export interface ResetArticlesStateRightArticlesTableAction {
  type: ArticlesTable.RESET_ARTICLES_STATE_RIGHT_ARTICLES_TABLE;
}

export interface LoadingShowRightArticlesTableAction {
  type: ArticlesTable.LOADING_SHOW_ARTICLES_RIGHT_ARTICLES_TABLE;
}

export interface LoadingHideRightArticlesTableAction {
  type: ArticlesTable.LOADING_HIDE_ARTICLES_RIGHT_ARTICLES_TABLE;
}

export interface FetchOrganizationsRequestRightArticlesTableAction {
  type: ArticlesTable.FETCH_ORGANIZATIONS_REQUEST_RIGHT_ARTICLES_TABLE;
  payload?: KBOrganizationsFilter;
}

export interface FetchOrganizationsSuccessRightArticlesTableAction {
  type: ArticlesTable.FETCH_ORGANIZATIONS_SUCCESS_RIGHT_ARTICLES_TABLE;
  payload: Organization[];
}

export interface OrganizationsLoadingShowRightArticlesTableAction {
  type: ArticlesTable.ORGANIZATIONS_LOADING_SHOW_RIGHT_ARTICLES_TABLE;
}

export interface OrganizationsLoadingHideRightArticlesTableAction {
  type: ArticlesTable.ORGANIZATIONS_LOADING_HIDE_RIGHT_ARTICLES_TABLE;
}

export interface FetchCategoriesRequestRightArticlesTableAction {
  type: ArticlesTable.FETCH_CATEGORIES_REQUEST_RIGHT_ARTICLES_TABLE;
  payload?: string;
}

export interface FetchCategoriesSuccessRightArticlesTableAction {
  type: ArticlesTable.FETCH_CATEGORIES_SUCCESS_RIGHT_ARTICLES_TABLE;
  payload: CategoryArticle[];
}

export interface CategoriesLoadingShowRightArticlesTableAction {
  type: ArticlesTable.CATEGORIES_LOADING_SHOW_RIGHT_ARTICLES_TABLE;
}

export interface CategoriesLoadingHideRightArticlesTableAction {
  type: ArticlesTable.CATEGORIES_LOADING_HIDE_RIGHT_ARTICLES_TABLE;
}

export interface ResetCategoriesStateRightArticlesTableAction {
  type: ArticlesTable.RESET_CATEGORIES_STATE_RIGHT_ARTICLES_TABLE;
}

export interface SetSelectedOrganizationRightArticlesTableAction {
  type: ArticlesTable.SET_ORGANIZATION_SELECTED_RIGHT_ARTICLES_TABLE;
  payload: SelectOption | null;
}

export interface SetSearchValueRightArticlesTableAction {
  type: ArticlesTable.SET_SEARCH_VALUE_RIGHT_ARTICLES_TABLE;
  payload: string;
}

export interface CreateCategoryRequestRightArticlesTableAction {
  type: ArticlesTable.CREATE_CATEGORY_REQUEST_RIGHT_ARTICLES_TABLE;
  payload: CategoryArticle;
}

export interface EditCategoryRequestRightArticlesTableAction {
  type: ArticlesTable.EDIT_CATEGORY_REQUEST_RIGHT_ARTICLES_TABLE;
  payload: CategoryArticle;
}

export interface DeleteCategoryRequestRightArticlesTableAction {
  type: ArticlesTable.DELETE_CATEGORY_REQUEST_RIGHT_ARTICLES_TABLE;
  payload: string;
}

export interface EditArticleRequestRightArticlesTableAction {
  type: ArticlesTable.EDIT_ARTICLE_REQUEST_RIGHT_ARTICLES_TABLE;
  payload: Partial<Article>;
}

export interface DeleteArticleRequestRightArticlesTableAction {
  type: ArticlesTable.DELETE_ARTICLE_REQUEST_RIGHT_ARTICLES_TABLE;
  payload: string;
}

export type ArticlesTableActionsTypes =
  | FetchArticlesTableRequestAction
  | FetchArticlesTableSuccessAction
  | FetchArticlesTableCategoryRequestAction
  | FetchArticlesTableCategorySuccessAction
  | ShowArticlesTableLoadingAction
  | HideArticlesTableLoadingAction
  | SetCurrentArticlesTablePageAction
  | ResetOnlyTableArticlesTableStateAction
  | SetSortArticlesTableAction
  | SetSizePageAction
  | SetCurrentArticleTableIdAction
  | ResetArticlesTableStateAction
  | SetArticlesTableFilterAction
  | FetchOrganizationsRequestArticleTableAction
  | FetchOrganizationsSuccessArticleTableAction
  | DeleteArticlesTableRequestAction
  | FetchArticlesRightArticlesTableRequestAction
  | FetchArticlesJoinSuccessRightArticlesTableAction
  | FetchArticlesSuccessRightArticlesTableAction
  | SetArticlesPageRightArticlesTableAction
  | ResetArticlesStateRightArticlesTableAction
  | LoadingShowRightArticlesTableAction
  | LoadingHideRightArticlesTableAction
  | FetchOrganizationsRequestRightArticlesTableAction
  | FetchOrganizationsSuccessRightArticlesTableAction
  | OrganizationsLoadingShowRightArticlesTableAction
  | OrganizationsLoadingHideRightArticlesTableAction
  | FetchCategoriesRequestRightArticlesTableAction
  | FetchCategoriesSuccessRightArticlesTableAction
  | CategoriesLoadingShowRightArticlesTableAction
  | CategoriesLoadingHideRightArticlesTableAction
  | ResetCategoriesStateRightArticlesTableAction
  | SetArticlesFilterRightArticlesTableAction
  | SetSelectedOrganizationRightArticlesTableAction
  | SetSearchValueRightArticlesTableAction
  | CreateCategoryRequestRightArticlesTableAction
  | EditCategoryRequestRightArticlesTableAction
  | DeleteCategoryRequestRightArticlesTableAction
  | EditArticleRequestRightArticlesTableAction
  | DeleteArticleRequestRightArticlesTableAction;
