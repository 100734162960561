import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchSystemPrioritiesRequest,
  getPriorityByTitleRadioTabs,
  resetSystemPriorities,
} from '@entities/system/model';
import { Create, RadioGroup } from '@shared';

import { getPriorityByTitle } from '../../lib';
import { editTicketPriority, getTicket } from '../../model/ticket';
import { Priority } from '../../model/types';
import { PriorityBlock } from '../PriorityBlock';

import styles from './TicketPriorityEdit.module.scss';

interface TicketPriorityEditProps {
  isModal: boolean;
  toggleModal(): void;
}

export const TicketPriorityEdit: FC<TicketPriorityEditProps> = ({
  isModal,
  toggleModal,
}) => {
  const closeModal = () => {
    toggleModal();
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isDirty, isValid },
  } = useForm<{ priority: Priority }>({
    mode: 'onChange',
  });

  const { priority } = watch();

  const radioPriorityTabs = useSelector(getPriorityByTitleRadioTabs);
  const ticket = useSelector(getTicket);

  const dispatch = useDispatch();

  useEffect(() => {
    if (ticket?.systemId?.key && isModal) {
      dispatch(fetchSystemPrioritiesRequest(ticket?.systemId?.key));
    }
  }, [ticket?.systemId?.key, isModal]);

  useEffect(
    () => () => {
      dispatch(resetSystemPriorities());
    },
    []
  );

  useEffect(() => {
    if (ticket?.priority) {
      setValue('priority', ticket?.priority);
    }
  }, [ticket, isModal]);

  const priorityRadioOptions = register('priority', { required: true });

  const formSubmitHandler = handleSubmit((data) => {
    if (ticket?.id) {
      dispatch(
        editTicketPriority({ priority: data.priority, ticketId: ticket?.id })
      );
    }
    closeModal();
    reset();
  });

  const renderRadioGroupComponent = (title?: string) => (
    <PriorityBlock
      priority={getPriorityByTitle(title)}
      className={styles.ticketPriorityEdit__priorityBlock}
    />
  );

  return (
    <>
      <Create
        toggleModal={toggleModal}
        isModal={isModal && !ticket?.isDeleted}
        title="Приоритет"
        createTitle="Применить"
        cancelTitle="Отменить"
        onSubmit={formSubmitHandler}
        disabledSubmit={!isDirty || !isValid || priority === ticket?.priority}
        subModalText="изменение приоритета"
      >
        <RadioGroup
          {...priorityRadioOptions}
          items={radioPriorityTabs}
          renderComponent={renderRadioGroupComponent}
        />
      </Create>
    </>
  );
};
