import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import {
  fetchEmployeesStatisticRequest,
  getEmployeesPageNumber,
  getEmployeesPageSize,
  getIsEmployeesLoading,
  setPageNumEmployees,
  setPageSizeEmployees,
} from '@entities/desktop';
import {
  fetchRoleInGroupByUserId,
  fetchRolesByUserIdRequest,
  resetRolesState,
} from '@entities/roles';
import { getIsSearchOpen, toggleTicketsSearch } from '@entities/ticket';
import {
  deleteUserRequest,
  fetchCurrentUser,
  fetchUsersAddByOrgId,
  fetchUsersByGroupId,
  fetchUsersRequest,
  getCurrentUserId,
  getPropsUsers,
  getSortUsers,
  getUser,
  getUsers,
  getUsersAdd,
  getUsersFilter,
  setSizePage,
  setSortUsers,
  setUserId,
  setUserLogin,
  setUsersPage,
  TableUsersTypes,
  User,
} from '@entities/user';
import {
  fetchGroupsByUserIdRequest,
  getCurrentGroupId,
  getGroupTitle,
  resetWorkGroupsState,
} from '@entities/work-group';
import { checkObjectIdentity, getChangeButtonProps, RouterHref } from '@shared';

export const fetchFunctions = {
  [TableUsersTypes.FULL]: fetchUsersRequest,
  [TableUsersTypes.FROM_GROUPS]: fetchUsersByGroupId,
  [TableUsersTypes.FROM_DESKTOP]: fetchEmployeesStatisticRequest,
  [TableUsersTypes.ADD_MODAL]: fetchUsersAddByOrgId,
};

interface UseUsersTableContainerProps {
  tableType: TableUsersTypes;
  usersList?: User[];
  totalUsers?: number;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useUsersTableContainer = (props: UseUsersTableContainerProps) => {
  const { tableType, usersList, totalUsers } = props;

  const dispatch = useDispatch();

  const { pageNum, pageSize, totalElements, loading } =
    useSelector(getPropsUsers);
  const employeesPageNumber = useSelector(getEmployeesPageNumber);
  const employeesPageSize = useSelector(getEmployeesPageSize);
  const employeesLoading = useSelector(getIsEmployeesLoading);
  const users = useSelector(getUsers);
  const { push } = useHistory();
  const { pathname } = useLocation();
  const currentUserId = useSelector(getCurrentUserId);
  const currentGroupId = useSelector(getCurrentGroupId);
  const sort = useSelector(getSortUsers);
  const groupTitle = useSelector(getGroupTitle)?.substring(0, 7);
  const isAccessToCreateUser = useSelector(
    getIsAccessedAction(ActionList.CreateUser)
  );
  const user = useSelector(getUser);
  const filterValues = useSelector(getUsersFilter);
  const isAccessToDeleteUser = useSelector(
    getIsAccessedAction(ActionList.DeleteEntries)
  );

  const isSearchOpen = useSelector(getIsSearchOpen);

  const isDesktopEmployeesPage = pathname === RouterHref.DesktopEmployees;

  const currentUsers = isDesktopEmployeesPage ? usersList : users;
  const currentTotalUsers = isDesktopEmployeesPage ? totalUsers : totalElements;
  const currentPageNumber = isDesktopEmployeesPage
    ? employeesPageNumber
    : pageNum;
  const currentPageSize = isDesktopEmployeesPage ? employeesPageSize : pageSize;
  const currentLoading = isDesktopEmployeesPage ? employeesLoading : loading;

  const usersAdd = useSelector(getUsersAdd);

  const tableTitle =
    tableType === TableUsersTypes.FROM_GROUPS
      ? 'Пользователи группы'
      : 'Пользователи';

  const [isModal, setIsModal] = useState<boolean>(false);
  const [showFilterRow, setShowFilterRow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toggleSearch = () => {
    dispatch(toggleTicketsSearch());
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const handleDelete = () => {
    if (user?.id) {
      dispatch(deleteUserRequest({ id: user?.id }));
    }
  };

  const handleShowModalDelete = () => {
    if (user) {
      setShowDeleteModal(true);
    }
  };
  const isFilterActive = !checkObjectIdentity(filterValues, {});

  const getActiveId = () => {
    const keys = {
      [TableUsersTypes.FULL]: '',
      [TableUsersTypes.FROM_GROUPS]: currentGroupId,
      [TableUsersTypes.ADD_MODAL]: '',
      [TableUsersTypes.FROM_DESKTOP]: '',
    };
    return keys[tableType];
  };

  useEffect(() => {
    if (showFilterRow) {
      setShowFilterRow(false);
    }
  }, [getActiveId()]);

  const searchDisabled =
    !currentUsers?.length || (showFilterRow && isFilterActive);

  const toggleModal = () => setIsModal(!isModal);

  const onRowClickFunctions = {
    [TableUsersTypes.FULL]: (value: string) => {
      if (currentUserId !== value) {
        const userLogin = currentUsers?.find(
          (item) => item.id === value
        )?.login;
        dispatch(resetWorkGroupsState());
        dispatch(resetRolesState());
        dispatch(setUserId(value));
        dispatch(setUserLogin(userLogin));
        dispatch(fetchCurrentUser(value));
        dispatch(fetchGroupsByUserIdRequest());
        dispatch(fetchRolesByUserIdRequest());
      }
    },
    [TableUsersTypes.FROM_GROUPS]: (value: string) => {
      if (currentUserId !== value) {
        dispatch(resetRolesState());
        const userLogin = currentUsers?.find(
          (item) => item.id === value
        )?.login;
        dispatch(setUserId(value));
        dispatch(setUserLogin(userLogin));
        dispatch(fetchRoleInGroupByUserId());
      }
    },
    [TableUsersTypes.ADD_MODAL]: () => null,
    [TableUsersTypes.FROM_DESKTOP]: () => null,
  };

  const handleChangePage = (page: number) => {
    if (isDesktopEmployeesPage) {
      dispatch(setPageNumEmployees(page));
      dispatch(fetchFunctions[tableType]());
    }
    if (!isDesktopEmployeesPage) {
      dispatch(setUsersPage(page));
      dispatch(fetchFunctions[tableType]());
    }
  };

  const handleChangePageSize = (arg: number) => {
    if (isDesktopEmployeesPage) {
      dispatch(setPageSizeEmployees(arg));
    }
    if (!isDesktopEmployeesPage) {
      dispatch(setSizePage(arg));
    }
  };

  const handleSort = (value: string) => {
    if (!isDesktopEmployeesPage) {
      dispatch(setSortUsers(value));
      dispatch(fetchFunctions[tableType]());
    }
    if (isDesktopEmployeesPage) {
      dispatch(setSortUsers(value));

      dispatch(fetchFunctions[tableType]());
    }
  };

  const toggleFilterRow = () => {
    setShowFilterRow((prevState) => !prevState);
  };

  const getCreateFunction = {
    [TableUsersTypes.FULL]: () => push(RouterHref.AdminCreateUser),
    [TableUsersTypes.FROM_GROUPS]: () => toggleModal(),
    [TableUsersTypes.ADD_MODAL]: () => undefined,
    [TableUsersTypes.FROM_DESKTOP]: undefined,
  };

  const getDisabledCreateBtn = {
    [TableUsersTypes.FULL]: !isAccessToCreateUser,
    [TableUsersTypes.FROM_GROUPS]: !currentGroupId,
  };

  const { createTitle, icon } = getChangeButtonProps(currentTotalUsers || 0);

  const createTableTitle =
    tableType === TableUsersTypes.FULL ? 'Создать' : createTitle;
  const createIcon = tableType === TableUsersTypes.FULL ? undefined : icon;

  return {
    methods: {
      toggleDeleteModal,
      handleDelete,
      handleShowModalDelete,
      handleUserClick: onRowClickFunctions[tableType],
      handleChangePage,
      handleChangePageSize,
      handleSort,
      toggleFilterRow,
      toggleModal,
      toggleSearch,
    },
    state: {
      pageNum: currentPageNumber,
      pageSize: currentPageSize,
      totalElements: currentTotalUsers,
      sort,
      groupTitle,
      isAccessToDeleteUser,
      tableTitle,
      showDeleteModal,
      searchDisabled,
      getDisabledCreateBtn,
      createTableTitle,
      createIcon,
      currentGroupId,
      pathname,
      users: currentUsers,
      user,
      showFilterRow,
      isFilterActive,
      currentUserId,
      isModal,
      getCreateFunction,
      usersAdd,
      loadingUsers: currentLoading,
      isSearchOpen,
    },
  };
};
