import { CustomField } from '@entities/custom-fields/model/types';
import {
  Environment,
  EnvironmentForSystem,
} from '@entities/environment/model/types';
import { Ticket, TicketType } from '@entities/ticket/model/types';
import { ResponseWithMeta, UpdateContentType } from '@shared';

import {
  CreateTicket,
  CreateTicketAction,
  FetchCreateTicketsRequestAction,
  FetchCreateTicketTypesRequestAction,
  FetchEnvironmentsRequestAction,
  FetchEnvironmentsSuccessAction,
  FetchRequiredCustomFieldsRequestAction,
  FetchRequiredCustomFieldsSuccessAction,
  FetchTicketsSuccessJoinAction,
  FetchTicketsSuccessUpdateAction,
  FetchTicketTypesSuccessAction,
  HideCreateLoadingAction,
  HideEnvironmentsLoadingAction,
  ResetCreateTicketsStateAction,
  ResetCreateTicketStateAction,
  SetCurrentPageTicketsAction,
  SetFilterTicketsAction,
  ShowCreateLoadingAction,
  ShowEnvironmentsLoadingAction,
  TicketsLoadingHideAction,
  TicketsLoadingShowAction,
} from './actionTypes';
import { CreateTicketPayload, TicketsFilter } from './types';

export const createTicketAction = (
  data: CreateTicketPayload
): CreateTicketAction => ({
  type: CreateTicket.CREATE_TICKET,
  payload: data,
});

export const showEnvironmentsLoading = (): ShowEnvironmentsLoadingAction => ({
  type: CreateTicket.ENVIRONMENTS_LOADING_SHOW,
});

export const hideEnvironmentsLoading = (): HideEnvironmentsLoadingAction => ({
  type: CreateTicket.ENVIRONMENTS_LOADING_HIDE,
});

export const fetchEnvironmentsRequest = (
  data: EnvironmentForSystem
): FetchEnvironmentsRequestAction => ({
  type: CreateTicket.FETCH_ENVIRONMENTS_REQUEST,
  payload: data,
});

export const fetchEnvironmentsSuccess = (
  data: ResponseWithMeta<Environment[]>
): FetchEnvironmentsSuccessAction => ({
  type: CreateTicket.FETCH_ENVIRONMENTS_SUCCESS,
  payload: data,
});

export const showCreateLoading = (): ShowCreateLoadingAction => ({
  type: CreateTicket.CREATE_LOADING_SHOW,
});

export const hideCreateLoading = (): HideCreateLoadingAction => ({
  type: CreateTicket.CREATE_LOADING_HIDE,
});

export const fetchTicketTypesRequest = (
  systemId: string
): FetchCreateTicketTypesRequestAction => ({
  type: CreateTicket.FETCH_CREATE_TICKET_TYPES_REQUEST,
  payload: systemId,
});

export const fetchCreateTicketTypesSuccess = (
  payload: TicketType[]
): FetchTicketTypesSuccessAction => ({
  type: CreateTicket.FETCH_CREATE_TICKET_TYPES_SUCCESS,
  payload,
});

export const resetCreateTicketState = (): ResetCreateTicketStateAction => ({
  type: CreateTicket.RESET_CREATE_TICKET_STATE,
});

export const fetchCreateTicketsRequest = (
  updateType: UpdateContentType
): FetchCreateTicketsRequestAction => ({
  type: CreateTicket.FETCH_TICKETS_REQUEST,
  payload: updateType,
});

export const loadingTicketsShow = (): TicketsLoadingShowAction => ({
  type: CreateTicket.TICKETS_LOADING_SHOW,
});

export const loadingTicketsHide = (): TicketsLoadingHideAction => ({
  type: CreateTicket.TICKETS_LOADING_HIDE,
});

export const fetchTicketsSuccessUpdate = (
  data: ResponseWithMeta<Ticket[]>
): FetchTicketsSuccessUpdateAction => ({
  type: CreateTicket.FETCH_TICKETS_SUCCESS_UPDATE,
  payload: data,
});

export const fetchTicketsSuccessJoin = (
  data: ResponseWithMeta<Ticket[]>
): FetchTicketsSuccessJoinAction => ({
  type: CreateTicket.FETCH_TICKETS_SUCCESS_JOIN,
  payload: data,
});

export const resetCreateTicketsState = (): ResetCreateTicketsStateAction => ({
  type: CreateTicket.RESET_TICKETS_STATE,
});

export const setFilterTickets = (
  filter: TicketsFilter
): SetFilterTicketsAction => ({
  type: CreateTicket.SET_FILTER_TICKETS,
  payload: filter,
});

export const setCurrentPageTickets = (
  page: number
): SetCurrentPageTicketsAction => ({
  type: CreateTicket.SET_CURRENT_PAGE_TICKETS,
  payload: page,
});

export const fetchRequiredCustomFieldsRequest = (
  systemId: string
): FetchRequiredCustomFieldsRequestAction => ({
  type: CreateTicket.FETCH_REQUIRED_CUSTOM_FIELDS_REQUEST,
  payload: systemId,
});

export const fetchRequiredCustomFieldsSuccess = (
  payload: CustomField[]
): FetchRequiredCustomFieldsSuccessAction => ({
  type: CreateTicket.FETCH_REQUIRED_CUSTOM_FIELDS_SUCCESS,
  payload,
});
