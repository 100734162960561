import { CreateUserData } from '@entities/user';

export const DEFAULT_USER_FORM_VALUES: CreateUserData = {
  id: '',
  firstName: '',
  lastName: '',
  middleName: '',
  description: '',
  login: '',
  password: '',
  email: '',
  phoneNumber: '',
  requireUpdatePassword: false,
};

export const USER_EXIST =
  'Такой пользователь уже существует. Для добавления его в группу необходимо обратиться к администратору';
