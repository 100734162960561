import { getUrl } from '@shared';

import { WorkflowParams } from '../model';

export const endpoints = {
  getSystems: () => getUrl('system/filter'),
  getOrganizations: () => getUrl('organization/filter'),
  getWorkflow: (systemId: string, typeId: string | undefined) =>
    getUrl(`workflows/system/${systemId}/type/${typeId}`),
  getTicketTypes: (systemId: string) => getUrl(`system/${systemId}`),
  saveWorkflow: ({ systemId, typeId, version }: WorkflowParams) =>
    getUrl(`workflows/system/${systemId}/type/${typeId}/version/${version}`),
  copyStatusModel: () => getUrl('workflows/copy-status-chain'),
  getTicketTypesBySystems: () => getUrl('type/systems'),
};
