import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchWorkGroupsByContractRequestContractDetail,
  getContractDetail,
  getGroupsByContractBySystemContractDetail,
  getGroupsByContractContractDetail,
  getGroupsFilterByContractContractDetail,
  getPropsGroupsByContractContractDetail,
  resetWorkGroupsByContractContractDetail,
  setFilterWorkGroupsByContractContractDetail,
  setPageWorkGroupsByContractContractDetail,
  setSizePageWorkGroupsByContractContractDetail,
  updateWorkGroupsInContractInSystemContractDetail,
} from '@entities/contract';
import { AddModal } from '@shared';

import { WORK_GROUPS_ADD_ALL_FULL } from '../../config';
import { TableWorkGroupsTypes, WorkGroup, WorkGroupFilter } from '../../model';
import { WorkGroupsFilter } from '../WorkGroupsFilter';

import { getDataTable } from './utils';

interface AddGroupsProps {
  isModal: boolean;
  toggleModal: () => void;
}

export const AddGroups: FC<AddGroupsProps> = ({ isModal, toggleModal }) => {
  const dispatch = useDispatch();

  const groupsAll = useSelector(getGroupsByContractContractDetail) || [];
  const groups = useSelector(getGroupsByContractBySystemContractDetail);

  const contractData = useSelector(getContractDetail);
  const filterValues = useSelector(getGroupsFilterByContractContractDetail);
  const { pageNum, pageSize, totalElements, loading } = useSelector(
    getPropsGroupsByContractContractDetail
  );

  useEffect(
    () => () => {
      dispatch(resetWorkGroupsByContractContractDetail());
    },
    []
  );

  const onSubmit = (data: WorkGroup[]) => {
    if (contractData) {
      dispatch(
        updateWorkGroupsInContractInSystemContractDetail(
          data.map((item) => item.id)
        )
      );
    }
    toggleModal();
  };

  const handleChangePage = (page: number) => {
    dispatch(setPageWorkGroupsByContractContractDetail(page));
    if (contractData?.id) {
      dispatch(
        fetchWorkGroupsByContractRequestContractDetail(contractData?.id)
      );
    }
  };

  const handleChangePageSize = (size: number) => {
    dispatch(setSizePageWorkGroupsByContractContractDetail(size));
  };

  const onChangeFilter = (data: WorkGroupFilter) => {
    if (contractData?.id) {
      dispatch(
        setFilterWorkGroupsByContractContractDetail({
          ...data,
          idsWorkGroupsDelete: filterValues?.idsWorkGroupsDelete,
        })
      );
      dispatch(setPageWorkGroupsByContractContractDetail(0));
      dispatch(
        fetchWorkGroupsByContractRequestContractDetail(contractData?.id)
      );
    }
  };

  const onChangeIdsForDelete = (data: WorkGroup[]) => {
    dispatch(
      setFilterWorkGroupsByContractContractDetail({
        ...filterValues,
        idsWorkGroupsDelete: data.map((item) => item.id),
      })
    );
    if (contractData?.id) {
      dispatch(
        fetchWorkGroupsByContractRequestContractDetail(contractData?.id)
      );
    }
  };

  return (
    <AddModal<WorkGroup>
      leftData={groupsAll}
      rightData={groups || []}
      getDataTable={getDataTable}
      columns={WORK_GROUPS_ADD_ALL_FULL}
      mainTitle="группы"
      subModalText="группы"
      leftTableTitle="Все группы"
      isModal={isModal}
      toggleModal={toggleModal}
      onSubmit={onSubmit}
      loadingData={loading}
      onChangeIdsForDelete={onChangeIdsForDelete}
      paginationLeftTableProps={{
        pageNum,
        pageSize,
        elementsCount: totalElements,
        handleChangePage,
        handleChangePageSize,
      }}
      filterComponent={
        <WorkGroupsFilter
          tableType={TableWorkGroupsTypes.ADD_GROUPS}
          onFilter={onChangeFilter}
        />
      }
    />
  );
};
