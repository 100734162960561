import { FilterData } from '@entities/ticket';

export const getFiltersRadioItems = (data: FilterData[]) => {
  const currentData = data?.map((filter) => ({
    title: filter.title,
    value: filter.id,
  }));

  return [
    {
      title: 'Без фильтра',
      value: 'NONE',
    },
    ...currentData,
  ];
};
