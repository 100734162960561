import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  fetchJiraIntegrationsRequest,
  resetJiraState,
  TableJiraIntegrationsTypes,
} from '@entities/jira-integrations';
import { resetOrganizationState } from '@entities/organization';
import { resetSystemsState } from '@entities/system';
import {
  JiraIntegrationsCurrent,
  JiraIntegrationsTableContainer,
} from '@features/jira-integrations';
import { TablesLayout } from '@shared';

import styles from './JiraIntegrations.module.scss';

const MainTable = () => (
  <JiraIntegrationsTableContainer tableType={TableJiraIntegrationsTypes.FULL} />
);

export const JiraIntegrations = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchJiraIntegrationsRequest());
    return () => {
      dispatch(resetJiraState());
      dispatch(resetSystemsState());
      dispatch(resetOrganizationState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[MainTable]}
      SubTable={[JiraIntegrationsCurrent]}
      classNameRoot={styles.jiraIntegrations}
      classNameWrapperSubTable={styles.jiraIntegrations__subTablesWrapper}
    />
  );
};
