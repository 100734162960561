import { CustomField } from '@entities/custom-fields';
import {
  Environment,
  EnvironmentsFilter,
} from '@entities/environment/model/types';
import { Ticket, TicketType } from '@entities/ticket/model/types';
import { ApiClient, getEnv, ResponseWithMeta } from '@shared';

import {
  CreateTicketDataToRequest,
  FetchCreateTicketsPayload,
} from '../../model/create-ticket';

import { createTicketEndpoints } from './endpoints';

export const createTicketRequests = {
  createTicket: (body: CreateTicketDataToRequest) =>
    ApiClient.post<Ticket[]>({
      url: createTicketEndpoints.createTicket(),
      data: body,
    }),
  fetchEnvironments: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: EnvironmentsFilter
  ) =>
    ApiClient.post<ResponseWithMeta<Environment[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: createTicketEndpoints.getEnvironments(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: filter,
    }),
  fetchTicketTypes: (systemId: string) =>
    ApiClient.get<TicketType[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: createTicketEndpoints.getTicketTypes(systemId),
    }),
  fetchTickets: ({
    pageNum = 0,
    pageSize,
    filter = {},
    workGroupIds,
  }: FetchCreateTicketsPayload) =>
    ApiClient.post<ResponseWithMeta<Ticket[]>>({
      url: createTicketEndpoints.getTickets(),
      params: { pageNum, pageSize, sort: 'DATECREATE_DESC' },
      data: {
        ...filter,
        workGroupIds,
      },
    }),
  fetchRequiredCustomField: (systemId: string) =>
    ApiClient.get<CustomField[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: createTicketEndpoints.getRequiredCustomFieldBySystemId(systemId),
    }),
};
