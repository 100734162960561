import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ActionList,
  getIsAccessedAction,
  getIsClient,
  getIsSpecialist,
} from '@entities/auth';
import {
  fetchTicketTypesTicketDetailRequest,
  getTicket,
  getTicketTypesTicketDetail,
} from '@entities/ticket';

import { checkIsTicketStatusStandart } from '../../utils';

export const useTicketTypes = () => {
  const dispatch = useDispatch();

  const ticket = useSelector(getTicket);
  const ticketTypes = useSelector(getTicketTypesTicketDetail);
  const isClient = useSelector(getIsClient);
  const isSpecialist = useSelector(getIsSpecialist);
  const isAccessChangeTypeTicket = useSelector(
    getIsAccessedAction(ActionList.EditTicketType)
  );

  const hasProcessRoles = isClient || isSpecialist;

  const isStandartStatus = checkIsTicketStatusStandart(ticket);

  const systemId = ticket?.systemId?.key;

  const canProcessTicketType =
    isAccessChangeTypeTicket &&
    hasProcessRoles &&
    ticketTypes.length > 1 &&
    isStandartStatus;

  useEffect(() => {
    if (systemId) {
      dispatch(fetchTicketTypesTicketDetailRequest(systemId));
    }
  }, [dispatch, systemId]);

  return { canProcessTicketType, ticket };
};
