import { Attachment, ParentType } from '@entities/attachment';
import {
  Environment,
  EnvironmentSetting,
  EnvironmentsFilter,
} from '@entities/environment';
import { Organization, OrganizationType } from '@entities/organization';
import { SLAType } from '@entities/sla';
import { System, SystemsFilterToRequest } from '@entities/system';
import { TicketType, TicketTypeSettings } from '@entities/ticket';
import { User, UsersFilterToRequest } from '@entities/user';
import { WorkGroup, WorkGroupFilterToRequest } from '@entities/work-group';
import { ApiClient, getEnv, ResponseWithMeta } from '@shared';

import {
  AttachSystemAndSLAToContractType,
  Contract,
  ContractIdSystemIdArgs,
  ProlongationContractPayload,
  SLAPatternPayload,
  UpdateContractNotificationsPayload,
  UpdateContractPermissionsPayload,
  UpdateEnvironmentSettings,
} from '../../model';

import { contractEndpoints } from './endpoints';

export const contractRequests = {
  fetchSystemsByContractId: (
    id: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: SystemsFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<System[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getSystemsByContractId(id),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: {
        ...filter,
      },
    }),
  fetchCurrentContract: (id: string) =>
    ApiClient.get<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getCurrentContract(id),
      data: {},
    }),

  createContract: (body: Contract) =>
    ApiClient.post<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.createContract(),
      data: body,
    }),
  updateContract: (data: Contract) =>
    ApiClient.put<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.createContract(),
      data,
    }),
  updateGroupsToContractToSystem: (
    contractId: string,
    systemId: string,
    slaId: string,
    data: string[]
  ) =>
    ApiClient.put<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.updateGroupsInSystemInContract(
        contractId,
        systemId,
        slaId
      ),
      data,
    }),
  deleteContract: (id: string) =>
    ApiClient.delete<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.deleteContract(id),
      data: {},
    }),
  fetchSupplementaryAgreements: (
    contractId: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string
  ) =>
    ApiClient.post<ResponseWithMeta<Contract[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getSupplementaryAgreements(contractId),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: {},
    }),
  fetchContractAttachments: (contractId: string) =>
    ApiClient.get<Attachment[]>({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: contractEndpoints.fetchAttachments(),
      params: {
        parent: ParentType.CONTRACT,
        id: contractId,
      },
    }),
  fetchUserByContractId: (
    id: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: UsersFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<User[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getUsersByContractId(id),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchEnvironmentsBySystem: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: EnvironmentsFilter
  ) =>
    ApiClient.post<ResponseWithMeta<Environment[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getEnvironments(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: filter,
    }),
  fetchEnvironmentSettingsBySystem: (data: ContractIdSystemIdArgs) =>
    ApiClient.get<EnvironmentSetting[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getEnvironmentSettings(data),
    }),
  fetchUpdateEnvironmentSettings: ({
    contractId,
    systemId,
    ...data
  }: UpdateEnvironmentSettings) =>
    ApiClient.put<EnvironmentSetting>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getEnvironmentSettings({
        contractId,
        systemId,
      }),
      data,
    }),
  fetchGroupsByContractIdBySystemId: (
    contractId: string,
    systemId: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: WorkGroupFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<WorkGroup[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getGroupsByContractIdBySystemId(
        contractId,
        systemId
      ),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchWorkGroupsByContractId: (
    contractId: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: WorkGroupFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<WorkGroup[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getWorkGroupsByContractId(contractId),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchSystemsForAddingToContract: (contractId: string, filter?: string) =>
    ApiClient.post<System[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getSystemsForAddingToContract(contractId),
      params: { title: filter },
    }),
  signContract: (contractId: string) =>
    ApiClient.get<string>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getSignContractUrl(contractId),
    }),
  fetchSLAPatterns: (title?: string) =>
    ApiClient.get<SLAType[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getSLAPatternsUrl(title ?? ''),
    }),
  isExistPatternTitle: (title: string) =>
    ApiClient.get<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getExistPatternTitleUrl(title),
    }),
  fetchPatternById: (id: string) =>
    ApiClient.get<SLAType>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getPatternByIdUrl(id),
    }),
  createPattern: (sla: SLAPatternPayload) => {
    ApiClient.post<SLAType>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getCreatePatternUrl(),
      data: sla,
    });
  },
  fetchSLAByContractIdBySystemId: (contractId: string, systemId: string) =>
    ApiClient.get<SLAType>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getSLAByContractIdBySystemIdUrl(),
      params: { contract_id: contractId, system_id: systemId },
    }),
  addSLAAndSystemToContract: (
    contractId: string,
    systemId: string,
    sla: SLAType
  ) =>
    ApiClient.put<AttachSystemAndSLAToContractType>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getAttachSLAAndSystemToContractUrl(contractId),
      data: { systemId, sla },
    }),
  updateContractSLA: (sla: SLAType, id: string) =>
    ApiClient.put<SLAType>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getUpdateSLAUrl(id),
      data: sla,
    }),
  updateNotificationsExpireContract: ({
    contractId,
    isEnable,
    notifyBefore,
  }: UpdateContractNotificationsPayload) =>
    ApiClient.put<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getNotificationsExpireContract(contractId),
      params: { isEnable, notifyBefore },
    }),
  updateContractPermission: ({
    contractId,
    isEnable,
  }: UpdateContractPermissionsPayload) =>
    ApiClient.put<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getContractPermission(contractId),
      params: { isEnable },
    }),
  systemTransfer: (data: string[], contractId: string) =>
    ApiClient.put<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getSystemTransfer(contractId),
      data,
    }),
  fetchOrganizations: (organizationTypes: OrganizationType[]) =>
    ApiClient.post<ResponseWithMeta<Organization[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getOrganizations(),
      params: {
        pageNum: 0,
        pageSize: 500,
        sort: 'date_create_DESC'.toUpperCase(),
      },
      data: { organizationTypes },
    }),
  fetchSettingsTicketType: (data: ContractIdSystemIdArgs) =>
    ApiClient.get<TicketTypeSettings[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getSettingsTicketType(data),
    }),
  fetchTicketTypes: (systemId: string) =>
    ApiClient.get<TicketType[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getTicketTypes(systemId),
    }),
  prolongationContact: ({
    contractId,
    contract,
  }: ProlongationContractPayload) =>
    ApiClient.post<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getProlongation(contractId),
      data: contract,
    }),
  fetchProlongationContactStatus: ({
    contractId,
    contract,
  }: ProlongationContractPayload) =>
    ApiClient.post<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractEndpoints.getProlongationStatus(contractId),
      data: contract,
    }),
};
