import {
  all,
  call,
  put,
  select,
  StrictEffect,
  takeEvery,
} from 'redux-saga/effects';

import { getCurrentContractId } from '@entities/contract/model';
import { setFilterOrganizationId } from '@entities/organization/model/actions';
import { getCurrentOrganizationId } from '@entities/organization/model/selectors';
import {
  fetchRespByGroupIdRequest,
  resetResponsibilitiesState,
} from '@entities/responsibilities/model/actions';
import {
  fetchRolesByGroupId,
  resetRolesState,
} from '@entities/roles/model/actions';
import { getSystemId } from '@entities/system/model';
import {
  fetchUsersByGroupId,
  getCurrentUserId,
  resetUsersState,
  setUserId,
} from '@entities/user';
import { getFilterGroupsToRequest } from '@entities/work-group/lib/utils';
import {
  CreateWorkGroupData,
  CreateWorkGroupDataToRequest,
  WorkGroup,
} from '@entities/work-group/model/work-group/types';
import {
  ActionForAlertTypes,
  createError,
  getErrorAlert,
  getSuccessAlert,
  ResponseWithMeta,
  RouterHref,
  setAlert,
  setRedirectPath,
} from '@shared';

import { workGroupRequests } from '../../api';

import {
  createWorkGroup,
  deleteWorkGroupRequest,
  editWorkGroup,
  fetchCurrentWorkGroup,
  fetchCurrentWorkGroupSuccess,
  fetchWorkGroupsAddSuccess,
  fetchWorkGroupsFromMyOrgSuccess,
  fetchWorkGroupsSuccess,
  hideWorkGroupLoading,
  hideWorkGroupsAddLoading,
  hideWorkGroupsLoading,
  resetCurrentWorkGroup,
  showWorkGroupLoading,
  showWorkGroupsAddLoading,
  showWorkGroupsLoading,
} from './actions';
import { WorkGroups } from './actionTypes';
import {
  getPropsGroups,
  getPropsGroupsAdd,
  getWorkGroupFilter,
  getWorkGroupsAddFilter,
} from './selectors';

const ENTITY_GROUP = 'Группа';

const getGroupDataToRequest = (
  data: CreateWorkGroupData
): CreateWorkGroupDataToRequest => {
  const { organizationId, ...otherData } = data;
  return {
    ...otherData,
    organization:
      organizationId && !Array.isArray(organizationId)
        ? {
            id: organizationId.value,
          }
        : undefined,
  };
};

function* workGroupsFetch() {
  try {
    const { pageNum, pageSize, sortGroups }: ReturnType<typeof getPropsGroups> =
      yield select(getPropsGroups);
    const filter: ReturnType<typeof getWorkGroupFilter> = yield select(
      getWorkGroupFilter
    );
    yield put(showWorkGroupsLoading());
    const workGroups: ResponseWithMeta<WorkGroup[]> = yield call(
      workGroupRequests.fetchWorkGroups,
      pageNum,
      pageSize,
      sortGroups,
      getFilterGroupsToRequest(filter)
    );
    yield put(fetchWorkGroupsSuccess(workGroups));
    yield put(hideWorkGroupsLoading());
  } catch (e) {
    createError(e);
    yield put(hideWorkGroupsLoading());
  }
}

function* groupsByOrganizationIdFetch() {
  try {
    const { pageNum, pageSize, sortGroups }: ReturnType<typeof getPropsGroups> =
      yield select(getPropsGroups);
    const filter: ReturnType<typeof getWorkGroupFilter> = yield select(
      getWorkGroupFilter
    );
    const orgId: ReturnType<typeof getCurrentOrganizationId> = yield select(
      getCurrentOrganizationId
    );
    if (orgId) {
      yield put(showWorkGroupsLoading());
      const workGroups: ResponseWithMeta<WorkGroup[]> = yield call(
        workGroupRequests.fetchWorkGroupsByOrganizationId,
        orgId,
        pageNum,
        pageSize,
        sortGroups,
        getFilterGroupsToRequest(filter)
      );
      yield put(fetchWorkGroupsSuccess(workGroups));
    }
    yield put(hideWorkGroupsLoading());
  } catch (e) {
    createError(e);
    yield put(hideWorkGroupsLoading());
  }
}

function* currentWorkGroupFetch({
  payload,
}: ReturnType<typeof fetchCurrentWorkGroup>) {
  try {
    yield put(showWorkGroupLoading());
    if (payload) {
      const workGroup: WorkGroup = yield call(
        workGroupRequests.fetchWorkGroup,
        payload
      );
      yield put(fetchCurrentWorkGroupSuccess(workGroup));
      if (workGroup.organization?.id) {
        yield put(setFilterOrganizationId(workGroup.organization.id));
      }
    }
    yield put(hideWorkGroupLoading());
  } catch (e) {
    yield put(hideWorkGroupLoading());
    createError(e);
  }
}

function* workGroupCreate({ payload }: ReturnType<typeof createWorkGroup>) {
  try {
    yield put(showWorkGroupsLoading());
    const workGroup: WorkGroup = yield call(
      workGroupRequests.createWorkGroup,
      getGroupDataToRequest(payload)
    );
    if (workGroup.id) {
      yield put(setRedirectPath(`/admin/work-groups/${workGroup.id}`));
      yield put(
        setAlert(getSuccessAlert(ENTITY_GROUP, ActionForAlertTypes.CREATE))
      );
    }
    yield put(hideWorkGroupsLoading());
  } catch (e) {
    yield put(
      setAlert(getErrorAlert(ENTITY_GROUP, ActionForAlertTypes.CREATE))
    );
    createError(e);
    yield put(hideWorkGroupsLoading());
  }
}

function* workGroupEdit({ payload }: ReturnType<typeof editWorkGroup>) {
  try {
    yield put(showWorkGroupsLoading());
    const workGroup: WorkGroup = yield call(
      workGroupRequests.editWorkGroup,
      getGroupDataToRequest(payload)
    );
    yield put(fetchCurrentWorkGroupSuccess(workGroup));
    yield put(
      setAlert(getSuccessAlert(ENTITY_GROUP, ActionForAlertTypes.EDIT))
    );
    yield put(hideWorkGroupsLoading());
  } catch (e) {
    yield put(setAlert(getErrorAlert(ENTITY_GROUP, ActionForAlertTypes.EDIT)));
    createError(e);
    yield put(hideWorkGroupsLoading());
  }
}

function* fetchByUserId() {
  try {
    const { pageNum, pageSize, sortGroups }: ReturnType<typeof getPropsGroups> =
      yield select(getPropsGroups);
    const filter: ReturnType<typeof getWorkGroupFilter> = yield select(
      getWorkGroupFilter
    );
    const userId: ReturnType<typeof getCurrentUserId> = yield select(
      getCurrentUserId
    );
    if (userId) {
      yield put(showWorkGroupsLoading());
      const workGroups: ResponseWithMeta<WorkGroup[]> = yield call(
        workGroupRequests.fetchWorkGroupsByUserId,
        userId,
        pageNum,
        pageSize,
        sortGroups,
        getFilterGroupsToRequest(filter)
      );
      yield put(fetchWorkGroupsSuccess(workGroups));
    }

    yield put(hideWorkGroupsLoading());
  } catch (e) {
    createError(e);
    yield put(hideWorkGroupsLoading());
  }
}

function* workGroupUpdate({ payload }: ReturnType<typeof editWorkGroup>) {
  try {
    const { id } = yield call(workGroupRequests.updateWorkGroup, payload);
    yield all([
      put(fetchUsersByGroupId()),
      put(fetchRespByGroupIdRequest()),
      put(fetchRolesByGroupId()),
      put(fetchCurrentWorkGroup(id)),
    ]);
    yield put(setUserId(''));
  } catch (e) {
    createError(e);
  }
}

function* fetchWorkGroupsByOrgIdByContractIdAdd() {
  try {
    const filter: ReturnType<typeof getWorkGroupsAddFilter> = yield select(
      getWorkGroupsAddFilter
    );
    const contractId: ReturnType<typeof getCurrentContractId> = yield select(
      getCurrentContractId
    );
    const { pageNum, pageSize }: ReturnType<typeof getPropsGroupsAdd> =
      yield select(getPropsGroupsAdd);
    if (contractId) {
      yield put(showWorkGroupsAddLoading());
      const workGroups: ResponseWithMeta<WorkGroup[]> = yield call(
        workGroupRequests.fetchWorkGroupsByContractIdByOrgId,
        contractId,
        pageNum,
        pageSize,
        undefined,
        getFilterGroupsToRequest(filter)
      );
      yield put(fetchWorkGroupsAddSuccess(workGroups));
    }
    yield put(hideWorkGroupsAddLoading());
  } catch (e) {
    createError(e);
    yield put(hideWorkGroupsAddLoading());
  }
}

// function* fetchWorkGroupsByContractId() {
//   try {
//     yield put(showWorkGroupsLoading());
//     const { pageNum, pageSize, sortGroups }: ReturnType<typeof getPropsGroups> =
//       yield select(getPropsGroups);
//     const filter: ReturnType<typeof getWorkGroupFilter> = yield select(
//       getWorkGroupFilter
//     );
//     const contractId: ReturnType<typeof getCurrentContractId> = yield select(
//       getCurrentContractId
//     );
//     if (contractId) {
//       const workGroups: ResponseWithMeta<WorkGroup[]> = yield call(
//         workGroupRequests.fetchWorkGroupsByContractId,
//         contractId,
//         pageNum,
//         pageSize,
//         sortGroups,
//         getFilterGroupsToRequest(filter)
//       );
//       yield put(fetchWorkGroupsSuccess(workGroups));
//     }
//     yield put(hideWorkGroupsLoading());
//   } catch (e) {
//     createError(e);
//     yield put(hideWorkGroupsLoading());
//   }
// }

function* fetchWorkGroupsByContractBySystem() {
  try {
    const { pageNum, pageSize, sortGroups }: ReturnType<typeof getPropsGroups> =
      yield select(getPropsGroups);
    const filter: ReturnType<typeof getWorkGroupFilter> = yield select(
      getWorkGroupFilter
    );
    const contractId: ReturnType<typeof getCurrentContractId> = yield select(
      getCurrentContractId
    );
    const systemId: ReturnType<typeof getSystemId> = yield select(getSystemId);
    if (contractId && systemId) {
      yield put(showWorkGroupsLoading());
      const workGroups: ResponseWithMeta<WorkGroup[]> = yield call(
        workGroupRequests.fetchWorkGroupsByContractIdBySystemId,
        contractId,
        systemId,
        pageNum,
        pageSize,
        sortGroups,
        getFilterGroupsToRequest(filter)
      );
      yield put(fetchWorkGroupsSuccess(workGroups));
    }
    yield put(hideWorkGroupsLoading());
  } catch (e) {
    createError(e);
    yield put(hideWorkGroupsLoading());
  }
}

function* workGroupsFromMyOrgFetch() {
  try {
    const workGroups: ResponseWithMeta<WorkGroup[]> = yield call(
      workGroupRequests.fetchWorkGroupsFromMyOrg
    );
    yield put(fetchWorkGroupsFromMyOrgSuccess(workGroups.content));
  } catch (e) {
    createError(e);
  }
}

function* deleteWorkGroup({
  payload,
}: ReturnType<typeof deleteWorkGroupRequest>) {
  try {
    const { id, withRedirect } = payload;
    yield call(workGroupRequests.deleteWorkGroup, id);
    yield put(
      setAlert(getSuccessAlert(ENTITY_GROUP, ActionForAlertTypes.DELETE))
    );
    if (withRedirect) {
      yield put(setRedirectPath(RouterHref.AdminGroups));
      return;
    }
    yield put(resetCurrentWorkGroup());
    yield put(resetUsersState());
    yield put(resetResponsibilitiesState());
    yield put(resetRolesState());
    yield call(workGroupsFetch);
  } catch (e) {
    yield put(
      setAlert(getErrorAlert(ENTITY_GROUP, ActionForAlertTypes.DELETE))
    );
    createError(e);
  }
}

export function* workGroupsSaga(): Generator<StrictEffect> {
  yield takeEvery(WorkGroups.FETCH_WORK_GROUPS_REQUEST, workGroupsFetch);
  yield takeEvery(
    WorkGroups.FETCH_WORK_GROUPS_BY_ORGANIZATION_ID_REQUEST,
    groupsByOrganizationIdFetch
  );
  yield takeEvery(WorkGroups.FETCH_WORK_GROUP_REQUEST, currentWorkGroupFetch);
  yield takeEvery(WorkGroups.CREATE_WORK_GROUP, workGroupCreate);
  yield takeEvery(WorkGroups.EDIT_WORK_GROUP, workGroupEdit);
  yield takeEvery(
    WorkGroups.FETCH_WORK_GROUPS_BY_USERID_REQUEST,
    fetchByUserId
  );
  yield takeEvery(WorkGroups.UPDATE_WORK_GROUP, workGroupUpdate);
  yield takeEvery(
    WorkGroups.FETCH_WORK_GROUPS_BY_ORG_ID_BY_SYSTEM_ID_ADD_REQUEST,
    fetchWorkGroupsByOrgIdByContractIdAdd
  );
  yield takeEvery(
    WorkGroups.FETCH_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM_REQUEST,
    fetchWorkGroupsByContractBySystem
  );
  yield takeEvery(
    WorkGroups.FETCH_WORK_GROUPS_FROM_MY_ORG_REQUEST,
    workGroupsFromMyOrgFetch
  );
  yield takeEvery(WorkGroups.DELETE_WORK_GROUP_REQUEST, deleteWorkGroup);
}
