import { debounce } from 'lodash';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchOrganizationRequest } from '@entities/storage';
import { DEFAULT_DEBOUNCE_DELAY, Select, SelectProps } from '@shared';

interface StorageOrganizationsSelectProps extends SelectProps {}

export const StorageOrganizationsSelect: FC<
  StorageOrganizationsSelectProps
> = ({ className, placeholder, options, ...other }) => {
  const dispatch = useDispatch();

  const onFilterUsers = debounce((valueFilter: string) => {
    dispatch(fetchOrganizationRequest(valueFilter));
  }, DEFAULT_DEBOUNCE_DELAY);

  useEffect(() => {
    dispatch(fetchOrganizationRequest(''));
  }, []);

  return (
    <Select
      {...other}
      placeholder={placeholder}
      onChangeInput={onFilterUsers}
      options={options}
      infiniteScrollable
      className={className}
      isSearchable
      isMulti
      isClearable
    />
  );
};
