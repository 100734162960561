import _ from 'lodash';

import { CustomFieldType } from '@entities/custom-fields';
import { TicketsFilterForRender } from '@entities/ticket';

export const getPreparedCustomFieldsForSubmit = (
  filter: TicketsFilterForRender,
  filterValues: TicketsFilterForRender
) => {
  if (filter?.customFields && filterValues?.customFields) {
    return _.unionBy(
      filter.customFields,
      filterValues.customFields,
      'fieldId'
    ).filter((field) => {
      if (CustomFieldType.FIELD_DATE === field.type) {
        return _.isString(field.date) && !field.date.match(/_/g);
      }
      if (CustomFieldType.FIELD_TEXT === field.type) {
        return _.isString(field.text) && !!field.text.length;
      }
      if (field.type === CustomFieldType.FIELD_LIST) {
        return field;
      }
      return field;
    });
  }

  return [];
};
