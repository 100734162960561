import { FC } from 'react';

import { ModalType, Storage } from '@entities/storage';
import {
  CloseIcon,
  Create,
  ErrorRoundIcon,
  FileInput,
  IconButton,
  MAX_FILE_SIZE,
  Star2Icon,
  TagNew,
  Typography,
  TypographyVariants,
} from '@shared';

import { MODAL_TYPE } from '../../../config';
import { useStorageAddFiles } from '../../../lib';
import { StorageDownloadedFile } from '../StorageDownloadedFile';
import { StorageDownloadingFile } from '../StorageDownloadingFile';

import styles from './StorageAddFiles.module.scss';

interface StorageAddFilesProps {
  isOpenModal: boolean;
  toggleModal: () => void;
  modalType?: ModalType;
  fileCardId?: string;
  selectedStorages?: Storage[];
  onDelete?: (id: string) => void;
}

export const StorageAddFiles: FC<StorageAddFilesProps> = ({
  isOpenModal,
  toggleModal,
  modalType = MODAL_TYPE,
  fileCardId,
  selectedStorages,
  onDelete,
}) => {
  const {
    state: {
      myFiles,
      downloadedFiles,
      mainFileId,
      currentFileId,
      warning,
      error,
      errorFileName,
      uploadingFiles,
      customFields,
    },
    methods: {
      setFileHandler,
      setMyFiles,
      cancelUploadingFile,
      changeMainFile,
      changeCurrentFile,
      removeField,
      register,
      getValues,
      onSubmit,
      getMaxFileName,
      handleCancelError,
      addTextToCustomFields,
      addFlagToCustomFields,
      addDateToCustomFields,
    },
  } = useStorageAddFiles({
    modalType,
    fileCardId,
    selectedStorages,
  });

  const handleSubmitAddFiles = () => {
    toggleModal();
    onSubmit();
  };

  const handleToggleModal = () => {
    toggleModal();

    if (isOpenModal) {
      uploadingFiles.forEach((file) => {
        file.cancelUpload();
      });
    }
  };

  const handleDeleteTag = (id: string) => () => {
    onDelete?.(id);
  };

  const addModalType = modalType.add;

  const currentTitle = addModalType ? 'Добавить файл' : 'Редактировать файлы';
  const currentCreateTitle = addModalType ? 'Опубликовать' : 'Сохранить';
  const currentSubModalText = addModalType
    ? 'добавление файла'
    : 'редактирование файлов';

  return (
    <Create
      isModal={isOpenModal}
      toggleModal={handleToggleModal}
      onSubmit={handleSubmitAddFiles}
      disabledSubmit={!mainFileId}
      title={currentTitle}
      createTitle={currentCreateTitle}
      subModalText={currentSubModalText}
      classNameCancelButton={styles.storageAddFiles__cancelButton}
    >
      {selectedStorages?.length && (
        <div className={styles.storageAddFiles__tags}>
          {selectedStorages.map(({ id, title }) => (
            <TagNew key={id} onDelete={handleDeleteTag(id)}>
              {title}
            </TagNew>
          ))}
        </div>
      )}
      <FileInput
        classNameContent={styles.storageAddFiles}
        classNameIcon={styles.storageAddFiles__icon}
        myFile={myFiles}
        setMyFile={setMyFiles}
        fileHandler={setFileHandler}
        showFiles={false}
        maxFileSize={MAX_FILE_SIZE}
        maxFileSizeWarning="50"
        getMaxFileName={getMaxFileName}
      />
      {warning && !mainFileId && (
        <div className={styles.storageAddFiles__warning}>
          <Star2Icon className={styles.storageAddFiles__warningIcon} />
          <Typography
            className={styles.storageAddFiles__warningText}
            variant={TypographyVariants.h5}
          >
            Выберите основной файл
          </Typography>
        </div>
      )}
      {error && (
        <div className={styles.storageAddFiles__error}>
          <div className={styles.storageAddFiles__errorBlock}>
            <ErrorRoundIcon className={styles.storageAddFiles__errorIcon} />
            <Typography
              className={styles.storageAddFiles__errorText}
              variant={TypographyVariants.h5}
            >
              Невозможно загрузить {errorFileName}, размер больше 50 Мб
            </Typography>
          </div>
          <IconButton
            appearance="outline"
            className={styles.storageAddFiles__errorButton}
            onClick={handleCancelError}
            icon={
              <CloseIcon className={styles.storageAddFiles__errorButtonIcon} />
            }
          />
        </div>
      )}

      {!!downloadedFiles?.length &&
        downloadedFiles.map((file, index) => (
          <StorageDownloadedFile
            key={file.id}
            index={index}
            register={register}
            mainFileId={mainFileId}
            changeMainFile={changeMainFile}
            currentFileId={currentFileId}
            changeCurrentFile={changeCurrentFile}
            removeField={removeField}
            getValues={getValues}
            customFields={customFields}
            addTextToCustomFields={addTextToCustomFields}
            addFlagToCustomFields={addFlagToCustomFields}
            addDateToCustomFields={addDateToCustomFields}
            {...file}
          />
        ))}
      {!!uploadingFiles.length &&
        uploadingFiles.map((file) => (
          <StorageDownloadingFile
            {...file}
            key={file.id}
            cancelUploadingFile={cancelUploadingFile}
          />
        ))}
    </Create>
  );
};
