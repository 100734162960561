import cn from 'clsx';
import { FC } from 'react';

import { TableContractsTypes } from '@entities/contract';
import { TableContainer } from '@shared';

import { PAGINATION_RANGE_MAP } from '../../../config';
import { ContractsDropContent } from '../ContractsDropContent';
import { ContractsFromOrganizationTable } from '../ContractsFromOrganizationTable';
import { ContractsFromSystemsTable } from '../ContractsFromSystemsTable';
import { ContractsFullTable } from '../ContractsFullTable';

import styles from './ContractsTableContainer.module.scss';
import { useContractsTableContainer } from './lib';

interface ContractsTableContainerProps {
  tableType: TableContractsTypes;
  className?: string;
  classNameClose?: string;
}

export const ContractsTableContainer: FC<ContractsTableContainerProps> = ({
  tableType,
  className,
  classNameClose,
}) => {
  const { methods, state } = useContractsTableContainer(tableType);

  const {
    handleSort,
    toggleFilterRow,
    setTableOpened,
    handleShowModalDelete,
    handleDelete,
    toggleDeleteModal,
    handleChangePage,
    handleChangePageSize,
    handleCreate,
    handleRowClick,
  } = methods;

  const {
    pageNum,
    pageSize,
    totalElements,
    sortContracts,
    canCreateContract,
    showFilterRow,
    isFilterActive,
    contract,
    contracts,
    tableOpened,
    searchDisable,
    showDeleteModal,
    currentContractId,
    isAccessToCreateContract,
    isAccessToDeleteContract,
    loadingContracts,
  } = state;

  const getTableComponent: Record<TableContractsTypes, () => JSX.Element> = {
    [TableContractsTypes.FULL]: () => (
      <ContractsFullTable
        contracts={contracts}
        handleSort={handleSort}
        sort={sortContracts}
        handleRowClick={handleRowClick}
        activeRowId={currentContractId}
        showFilterRow={showFilterRow}
        isFilterActive={isFilterActive}
      />
    ),
    [TableContractsTypes.FROM_ORGANIZATIONS]: () => (
      <ContractsFromOrganizationTable
        contracts={contracts}
        handleSort={handleSort}
        sort={sortContracts}
        showFilterRow={showFilterRow}
        activeRowId={contract?.id}
        isFilterActive={isFilterActive}
      />
    ),
    [TableContractsTypes.FROM_SYSTEMS]: () => (
      <ContractsFromSystemsTable
        contracts={contracts}
        handleSort={handleSort}
        sort={sortContracts}
        showFilterRow={showFilterRow}
        handleRowClick={handleRowClick}
        activeRowId={currentContractId}
        isFilterActive={isFilterActive}
      />
    ),
  };

  const getContent = () => getTableComponent[tableType]();

  const dropContent =
    tableType === TableContractsTypes.FULL && isAccessToDeleteContract ? (
      <ContractsDropContent handleDelete={handleShowModalDelete} />
    ) : undefined;

  return (
    <TableContainer
      classNameRoot={cn(
        {
          [styles.contractsTableContainer_full]:
            tableType === TableContractsTypes.FULL,
          [styles.contractsTableContainer_fromOrganizations]:
            tableType === TableContractsTypes.FROM_ORGANIZATIONS,
          [styles.contractsTableContainer_fromSystems]:
            tableType === TableContractsTypes.FROM_SYSTEMS,
        },
        className,
        {
          [cn(styles.contractsTableContainer_close, classNameClose)]:
            !tableOpened,
        }
      )}
      headerProps={{
        title: 'Контракты',
        createHandler: canCreateContract ? handleCreate : undefined,
        createDisabled: canCreateContract && !isAccessToCreateContract,
        createTitle: 'Создать',
        tableOpened,
        handleTableOpen: setTableOpened,
        searchDisabled: searchDisable,
        toggleSearch: toggleFilterRow,
        isSearchOpen: showFilterRow,
        dropContent,
        loading: loadingContracts,
      }}
      contentTable={getContent()}
      countRecordsProps={{
        records: totalElements,
      }}
      paginationProps={{
        pageNum,
        elementsCount: totalElements,
        pageSize,
        marginPagesDisplayed: PAGINATION_RANGE_MAP[tableType],
        handleChangePage,
        handleChangePageSize,
        disabled: loadingContracts,
        entityName: `CONTRACTS_${tableType}`,
      }}
      approveOrCancelProps={{
        onApprove: handleDelete,
        isModal: showDeleteModal,
        toggleModal: toggleDeleteModal,
        approveTitle: 'Удалить',
        approveBtnProps: {
          appearance: 'outline',
          className: styles.contractsTableContainer__iconDelete,
        },
        cancelTitle: 'Отмена',
        text: `Вы уверены, что хотите удалить контракт ${contract?.title}?`,
      }}
    />
  );
};
