import { Organization } from '@entities/organization';
import { ResponseWithMeta, SelectOption } from '@shared';

import {
  Article,
  CategoryArticle,
  FetchArticlesParams,
  KBArticleTableFilterToRequest,
  KBOrganizationsFilter,
} from '../types';

import { ArticleViewing } from './actionTypes';

export const fetchCurrentArticleRequestArticleViewing = (id: string) => ({
  type: ArticleViewing.FETCH_CURRENT_ARTICLE_REQUEST_ARTICLE_VIEWING,
  payload: id,
});

export const fetchCurrentArticleSuccessArticleViewing = (data: Article) => ({
  type: ArticleViewing.FETCH_CURRENT_ARTICLE_SUCCESS_ARTICLE_VIEWING,
  payload: data,
});

export const loadingShowCurrentArticleArticleViewing = () => ({
  type: ArticleViewing.LOADING_SHOW_CURRENT_ARTICLE_ARTICLE_VIEWING,
});

export const loadingHideCurrentArticleArticleViewing = () => ({
  type: ArticleViewing.LOADING_HIDE_CURRENT_ARTICLE_ARTICLE_VIEWING,
});

export const setArticlesFilterArticleViewing = (
  filter: KBArticleTableFilterToRequest
) => ({
  type: ArticleViewing.SET_FILTER_ARTICLES_ARTICLE_VIEWING,
  payload: filter,
});

export const fetchArticlesArticleViewingRequest = (
  params: FetchArticlesParams
) => ({
  type: ArticleViewing.FETCH_REQUEST_ARTICLES_ARTICLE_VIEWING,
  payload: params,
});

export const fetchArticlesSuccessArticleViewing = (
  articles: ResponseWithMeta<Article[]>
) => ({
  type: ArticleViewing.FETCH_SUCCESS_ARTICLES_ARTICLE_VIEWING,
  payload: articles,
});

export const fetchArticlesJoinSuccessArticleViewing = (
  articles: ResponseWithMeta<Article[]>
) => ({
  type: ArticleViewing.FETCH_ARTICLES_JOIN_SUCCESS_ARTICLE_VIEWING,
  payload: articles,
});

export const setArticlesPageArticleViewing = (page: number) => ({
  type: ArticleViewing.SET_ARTICLES_PAGE_ARTICLE_VIEWING,
  payload: page,
});

export const resetArticlesStateArticleViewing = () => ({
  type: ArticleViewing.RESET_ARTICLES_STATE_ARTICLE_VIEWING,
});

export const loadingShowArticleViewing = () => ({
  type: ArticleViewing.LOADING_SHOW_ARTICLES_ARTICLE_VIEWING,
});

export const loadingHideArticleViewing = () => ({
  type: ArticleViewing.LOADING_HIDE_ARTICLES_ARTICLE_VIEWING,
});

export const fetchOrganizationsRequestArticleViewing = (
  filter?: KBOrganizationsFilter
) => ({
  type: ArticleViewing.FETCH_ORGANIZATIONS_REQUEST_ARTICLE_VIEWING,
  payload: filter,
});

export const fetchOrganizationsSuccessArticleViewing = (
  data: Organization[]
) => ({
  type: ArticleViewing.FETCH_ORGANIZATIONS_SUCCESS_ARTICLE_VIEWING,
  payload: data,
});

export const organizationsLoadingShowArticleViewing = () => ({
  type: ArticleViewing.ORGANIZATIONS_LOADING_SHOW_ARTICLE_VIEWING,
});

export const organizationsLoadingHideArticleViewing = () => ({
  type: ArticleViewing.ORGANIZATIONS_LOADING_HIDE_ARTICLE_VIEWING,
});

export const fetchCategoriesRequestArticleViewing = (
  organizationIds?: string[]
) => ({
  type: ArticleViewing.FETCH_CATEGORIES_REQUEST_ARTICLE_VIEWING,
  payload: organizationIds,
});

export const fetchCategoriesSuccessArticleViewing = (
  categories: CategoryArticle[]
) => ({
  type: ArticleViewing.FETCH_CATEGORIES_SUCCESS_ARTICLE_VIEWING,
  payload: categories,
});

export const categoriesLoadingShowArticleViewing = () => ({
  type: ArticleViewing.CATEGORIES_LOADING_SHOW_ARTICLE_VIEWING,
});

export const categoriesLoadingHideArticleViewing = () => ({
  type: ArticleViewing.CATEGORIES_LOADING_HIDE_ARTICLE_VIEWING,
});

export const resetCategoriesStateArticleViewing = () => ({
  type: ArticleViewing.RESET_CATEGORIES_STATE_ARTICLE_VIEWING,
});

export const setSelectedOrganizationArticleViewing = (
  option: SelectOption | null
) => ({
  type: ArticleViewing.SET_ORGANIZATION_SELECTED_ARTICLE_VIEWING,
  payload: option,
});

export const setSearchValueArticleViewing = (value: string) => ({
  type: ArticleViewing.SET_SEARCH_VALUE_ARTICLE_VIEWING,
  payload: value,
});

export const resetStateArticleViewing = () => ({
  type: ArticleViewing.RESET_STATE_ARTICLE_VIEWING,
});

export const createCategoryRequestRightArticleViewing = (
  data: Partial<CategoryArticle>
) => ({
  type: ArticleViewing.CREATE_CATEGORY_REQUEST_RIGHT_ARTICLE_VIEWING,
  payload: data,
});

export const editCategoryRequestRightArticleViewing = (
  data: CategoryArticle
) => ({
  type: ArticleViewing.EDIT_CATEGORY_REQUEST_RIGHT_ARTICLE_VIEWING,
  payload: data,
});

export const deleteCategoryRequestRightArticleViewing = (id: string) => ({
  type: ArticleViewing.DELETE_CATEGORY_REQUEST_RIGHT_ARTICLE_VIEWING,
  payload: id,
});

export const editArticleRequestRightArticleViewing = (
  article: Partial<Article>
) => ({
  type: ArticleViewing.EDIT_ARTICLE_REQUEST_RIGHT_ARTICLE_VIEWING,
  payload: article,
});

export const deleteArticleRequestRightArticleViewing = (id: string) => ({
  type: ArticleViewing.DELETE_ARTICLE_REQUEST_RIGHT_ARTICLE_VIEWING,
  payload: id,
});
