import { Reducer } from 'redux';

import { Action } from '@entities/actions/model/types';
import { AttachmentsFiles } from '@entities/attachment/model/types';
import { Ticket, TicketType } from '@entities/ticket/model/types';
import {
  DEFAULT_PAGINATION_PARAMS,
  PAGE_SIZE_SEARCH_SELECT,
  PaginationType,
  ReducerMap,
} from '@shared';

import {
  CreateTicketsSettingFieldsSuccessAction,
  FetchActionsForCurrentTicketSuccessAction,
  FetchFiltersDataSuccessAction,
  FetchOpenTicketsCountByContactIdSuccessAction,
  FetchTicketsSettingFieldsSuccessAction,
  FetchTicketsSuccessAction,
  FetchTicketStatusSuccessJoinAction,
  FetchTicketStatusSuccessUpdateAction,
  FetchTicketTypesSuccessJoinAction,
  FetchTicketTypesSuccessUpdateAction,
  OpenSettingFieldsAction,
  SetCurrentFilterIdAction,
  SetCurrentTicketId,
  SetCurrentTicketPageAction,
  SetCurrentTicketSuccessAction,
  SetIsUsedFilterAction,
  SetSizePageAction,
  SetSizePageTypeAction,
  SetSortTicketsAction,
  SetTicketsFilterAction,
  SetValueTabItemAction,
  Tickets,
  TicketsActionsTypes,
  UpdateTicketByIdSuccessAction,
} from './actionTypes';
import { FilterData, ISettingFields, TicketsFilterForRender } from './types';

export interface TicketsReducerState {
  tickets?: Ticket[];
  desktopTickets?: Ticket[];
  ticket?: Ticket;
  loading?: boolean;
  ticketLoading?: boolean;
  isSearchOpen?: boolean;
  pagination?: PaginationType;
  paginationType?: PaginationType;
  paginationStatus?: PaginationType;
  totalElements?: number;
  sort?: string;
  sortStatus?: string;
  filter?: TicketsFilterForRender;
  ticketsStatus?: string[];
  openTicketsCount?: number;
  currentTicketId?: string;
  ticketActionsList?: Action[];
  statuses?: string[];
  filtersData?: FilterData[];
  currentFilterId?: string;
  isUsedFilter?: boolean;
  isSettingFieldsOpen?: boolean;
  settingFields?: ISettingFields;
  types?: TicketType[];
  loadingType?: boolean;
  loadingStatus?: boolean;
  imageFiles?: AttachmentsFiles[];
  isModalPopoverInner?: boolean;
  valueTabItem?: string;
}

const initialState: TicketsReducerState = {
  tickets: [],
  desktopTickets: [],
  loading: false,
  loadingType: false,
  loadingStatus: false,
  ticketLoading: false,
  isSearchOpen: false,
  pagination: DEFAULT_PAGINATION_PARAMS,
  paginationType: {
    pageSize: PAGE_SIZE_SEARCH_SELECT,
  },
  paginationStatus: {
    pageSize: PAGE_SIZE_SEARCH_SELECT,
  },
  totalElements: 0,
  sort: 'datecreate_DESC',
  sortStatus: 'NAME_ASC',
  filter: {},
  ticketsStatus: [],
  openTicketsCount: 0,
  ticketActionsList: [],
  statuses: [],
  currentFilterId: 'NONE',
  isUsedFilter: false,
  isSettingFieldsOpen: false,
  types: [],
  imageFiles: [],
  isModalPopoverInner: false,
};

const ticketsReducerMap: ReducerMap<TicketsReducerState, TicketsActionsTypes> =
  {
    [Tickets.TICKETS_LOADING_SHOW]: (state) => ({
      ...state,
      loading: true,
    }),
    [Tickets.TICKETS_LOADING_HIDE]: (state) => ({
      ...state,
      loading: false,
    }),
    [Tickets.TICKET_LOADING_SHOW]: (state) => ({
      ...state,
      ticketLoading: true,
    }),
    [Tickets.TICKET_LOADING_HIDE]: (state) => ({
      ...state,
      ticketLoading: false,
    }),
    [Tickets.FETCH_TICKETS_SUCCESS]: (state, action) => {
      const { payload } = action as FetchTicketsSuccessAction;
      const { content, totalElements } = payload;
      return {
        ...state,
        tickets: content,
        totalElements,
      };
    },
    [Tickets.FETCH_OPEN_TICKETS_COUNT_BY_CONTRACT_ID_SUCCESS]: (
      state,
      action
    ) => {
      const { payload } =
        action as FetchOpenTicketsCountByContactIdSuccessAction;
      return {
        ...state,
        openTicketsCount: payload,
      };
    },
    [Tickets.SET_CURRENT_TICKET_SUCCESS]: (state, action) => {
      const { payload } = action as SetCurrentTicketSuccessAction;
      return {
        ...state,
        ticket: payload,
      };
    },
    [Tickets.TOGGLE_TICKETS_SEARCH]: (state) => ({
      ...state,
      isSearchOpen: !state?.isSearchOpen,
    }),
    [Tickets.RESET_TICKETS_SEARCH]: (state) => ({
      ...state,
      isSearchOpen: false,
    }),
    [Tickets.SET_CURRENT_TICKETS_PAGE]: (state, action) => {
      const { payload } = action as SetCurrentTicketPageAction;
      return {
        ...state,
        pagination: {
          ...state?.pagination,
          pageNum: payload,
        },
      };
    },
    [Tickets.SET_SIZE_PAGE]: (state, action) => {
      const { payload } = action as SetSizePageAction;
      return {
        ...state,
        pagination: {
          ...state?.pagination,
          pageSize: payload,
        },
      };
    },
    [Tickets.SET_SORT_TICKETS]: (state, action) => {
      const { payload } = action as SetSortTicketsAction;
      return {
        ...state,
        sort: payload,
      };
    },
    [Tickets.SET_FILTER_TICKETS]: (state, action) => {
      const { payload } = action as SetTicketsFilterAction;
      return {
        ...state,
        filter: payload,
      };
    },
    [Tickets.UPDATE_TICKET_BY_ID_SUCCESS]: (state, action) => {
      const { payload } = action as UpdateTicketByIdSuccessAction;

      return {
        ...state,
        tickets: state?.tickets?.map((ticket) => {
          if (ticket.id === payload.id) {
            return payload;
          }
          return ticket;
        }),
      };
    },
    [Tickets.SET_CURRENT_TICKET_ID]: (state, action) => {
      const { payload } = action as SetCurrentTicketId;
      return {
        ...state,
        currentTicketId: payload,
      };
    },
    [Tickets.FETCH_ACTIONS_FOR_CURRENT_TICKET_SUCCESS]: (state, action) => {
      const { payload } = action as FetchActionsForCurrentTicketSuccessAction;
      return {
        ...state,
        ticketActionsList: payload,
      };
    },
    [Tickets.RESET_TICKETS_STATE]: () => ({
      ...initialState,
    }),
    [Tickets.RESET_CURRENT_TICKET]: (state) => ({
      ...state,
      ticket: initialState.ticket,
      currentTicketId: initialState.currentTicketId,
      pagination: initialState.pagination,
    }),
    [Tickets.FETCH_FILTERS_DATA_SUCCESS]: (state, action) => {
      const { payload } = action as FetchFiltersDataSuccessAction;
      return {
        ...state,
        filtersData: payload,
      };
    },
    [Tickets.SET_CURRENT_FILTER_ID]: (state, action) => {
      const { payload } = action as SetCurrentFilterIdAction;
      return {
        ...state,
        currentFilterId: payload,
      };
    },
    [Tickets.SET_IS_USED_FILTER]: (state, action) => {
      const { payload } = action as SetIsUsedFilterAction;
      return {
        ...state,
        isUsedFilter: payload,
      };
    },
    [Tickets.RESET_FILTERS_STATE]: (state) => ({
      ...state,
      filtersData: initialState.filtersData,
      currentFilterId: initialState.currentFilterId,
      isUsedFilter: initialState.isUsedFilter,
    }),
    [Tickets.OPEN_SETTING_FIELDS]: (state, action) => {
      const { payload } = action as OpenSettingFieldsAction;
      return {
        ...state,
        isSettingFieldsOpen: payload,
      };
    },
    [Tickets.FETCH_TICKETS_SETTING_FIELD_SUCCESS]: (state, action) => {
      const { payload } = action as FetchTicketsSettingFieldsSuccessAction;
      return {
        ...state,
        settingFields: payload,
      };
    },
    [Tickets.CREATE_TICKETS_SETTING_FIELD_SUCCESS]: (state, action) => {
      const { payload } = action as CreateTicketsSettingFieldsSuccessAction;
      return {
        ...state,
        settingFields: payload,
      };
    },
    [Tickets.FETCH_TICKET_TYPES_SUCCESS_UPDATE]: (state, action) => {
      const { payload } = action as FetchTicketTypesSuccessUpdateAction;
      const { content, page, totalElements } = payload;
      return {
        ...state,
        types: content,
        paginationType: {
          ...state?.paginationType,
          pageNum: page,
          totalElements,
        },
      };
    },
    [Tickets.FETCH_TICKET_TYPES_SUCCESS_JOIN]: (state, action) => {
      const { payload } = action as FetchTicketTypesSuccessJoinAction;
      const { content, page, totalElements } = payload;
      return {
        ...state,
        types: [...(state?.types || []), ...content],
        paginationType: {
          ...state?.paginationType,
          pageNum: page,
          totalElements,
        },
      };
    },
    [Tickets.RESET_TICKET_TYPES]: (state) => ({
      ...state,
      types: initialState.types,
      paginationType: initialState.paginationType,
    }),
    [Tickets.SET_SIZE_TYPE_PAGE]: (state, action) => {
      const { payload } = action as SetSizePageTypeAction;
      return {
        ...state,
        paginationType: {
          ...state?.paginationType,
          pageNum: payload,
        },
      };
    },
    [Tickets.TYPE_LOADING_SHOW]: (state) => ({
      ...state,
      loadingType: true,
    }),
    [Tickets.TYPE_LOADING_HIDE]: (state) => ({
      ...state,
      loadingType: false,
    }),
    [Tickets.FETCH_TICKET_STATUS_SUCCESS_UPDATE]: (state, action) => {
      const { payload } = action as FetchTicketStatusSuccessUpdateAction;
      const { content, page, totalElements } = payload;
      return {
        ...state,
        statuses: content,
        paginationStatus: {
          ...state?.paginationStatus,
          pageNum: page,
          totalElements,
        },
      };
    },
    [Tickets.FETCH_TICKET_STATUS_SUCCESS_JOIN]: (state, action) => {
      const { payload } = action as FetchTicketStatusSuccessJoinAction;
      const { content, page, totalElements } = payload;
      const currentStatuses = Array.isArray(state?.statuses)
        ? state?.statuses
        : [];
      return {
        ...state,
        statuses: [...(currentStatuses || []), ...content],
        paginationStatus: {
          ...state?.paginationStatus,
          pageNum: page,
          totalElements,
        },
      };
    },
    [Tickets.RESET_TICKET_STATUS]: (state) => ({
      ...state,
      statuses: initialState.statuses,
      paginationStatus: initialState.paginationStatus,
    }),
    [Tickets.SET_SIZE_STATUS_PAGE]: (state, action) => {
      const { payload } = action as SetSizePageTypeAction;
      return {
        ...state,
        paginationStatus: {
          ...state?.paginationStatus,
          pageNum: payload,
        },
      };
    },
    [Tickets.SET_IS_MODAL_POPOVER_INNER]: (state) => ({
      ...state,
      isModalPopoverInner: !state?.isModalPopoverInner,
    }),
    [Tickets.SET_VALUE_TAB_ITEM]: (state, action) => {
      const { payload } = action as SetValueTabItemAction;
      return {
        ...state,
        valueTabItem: payload,
      };
    },
  };

export const ticketsSlice: Reducer<TicketsReducerState, TicketsActionsTypes> = (
  state = initialState,
  action
) => {
  const reducer = ticketsReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
