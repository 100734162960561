import cn from 'clsx';
import { FC, HTMLAttributes, memo } from 'react';

import styles from './TableBodyCell.module.scss';

interface TableBodyCellProps extends HTMLAttributes<HTMLDivElement> {
  filterCell?: boolean;
}

export const TableBodyCell: FC<TableBodyCellProps> = memo(
  ({ className, children, style, filterCell }) => (
    <div
      style={style}
      className={cn(
        styles.tableBodyCell,
        {
          [styles.tableBodyCell_filter]: filterCell,
        },
        className
      )}
    >
      {children}
    </div>
  )
);
