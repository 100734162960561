import {
  FilterRulesValue,
  JiraSyncFilterCriteriaDto,
  JiraSyncFilterUnionType,
} from '@entities/jira-integrations';

export const getArraySyncFilterRules = (filterRules: FilterRulesValue) => {
  if (!filterRules) {
    return [];
  }

  const keys = Object.keys(filterRules) as JiraSyncFilterUnionType[];

  return keys.reduce<JiraSyncFilterCriteriaDto[]>((acc, key) => {
    const result = filterRules[key].map(({ value, title }) => ({
      type: key,
      entityId: value,
      value: title,
    }));

    return [...acc, ...result];
  }, []);
};
