import { ChangeEvent, FC } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { Storage } from '@entities/storage';

import { Storage as StorageItem } from '../Storage';

interface StoragesListProps {
  storages?: Storage[];
  handleChangeCheckbox: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedStorages: Storage[];
  hasMore?: boolean;
  handleLoadMore: (page: number) => void;
}

export const StoragesList: FC<StoragesListProps> = ({
  storages,
  selectedStorages,
  handleChangeCheckbox,
  handleLoadMore,
  hasMore,
}) => (
  <InfiniteScroll
    loadMore={handleLoadMore}
    hasMore={hasMore}
    useWindow={false}
    threshold={10}
  >
    {storages?.map((storage) => (
      <StorageItem
        {...storage}
        key={storage.id}
        selectedStorages={selectedStorages}
        handleChangeCheckbox={handleChangeCheckbox}
      />
    ))}
  </InfiniteScroll>
);
