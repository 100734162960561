import { FC } from 'react';

import { Card, TypographyNew, TypographyVariantsNew } from '@shared';

import styles from './NotificationInformationLayout.module.scss';

type NotificationInformationLayoutProps = {
  title?: string;
  description?: string;
};

export const NotificationInformationLayout: FC<
  NotificationInformationLayoutProps
> = ({ title, description, children }) => (
  <div className={styles.notificationInformationLayout}>
    <div className={styles.notificationInformationLayout__header}>
      <TypographyNew
        className={styles.notificationInformationLayout__title}
        variant={TypographyVariantsNew.h2}
      >
        {title}
      </TypographyNew>
    </div>
    <Card className={styles.notificationInformationLayout__content}>
      <div className={styles.notificationInformationLayout__description}>
        {description}
      </div>
      {children}
    </Card>
  </div>
);
