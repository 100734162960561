import { Reducer } from 'redux';

import { Environment } from '@entities/environment';
import { SystemPriority } from '@entities/system/model';
import { TicketType } from '@entities/ticket';
import { DEFAULT_PAGINATION_PARAMS, PaginationType, ReducerMap } from '@shared';

import {
  EditJiraSyncSuccessAction,
  FetchFieldsIntegrationsSuccessAction,
  FetchIntegratedTicketTypesSuccessAction,
  FetchJiraFieldsSuccessAction,
  FetchJiraIntegrationsSuccessAction,
  FetchJiraIntegrationSuccessAction,
  FetchJiraPrioritiesByIdSuccessAction,
  FetchJiraPrioritiesByIntegrationIdSuccessAction,
  FetchJiraPrioritiesSuccessAction,
  FetchJiraSPStatusesForCurrentSuccessAction,
  FetchJiraSPStatusesSuccessAction,
  FetchJiraStatusesByIdSuccessAction,
  FetchJiraStatusesByPropertyIdSuccessAction,
  FetchJiraStatusesSuccessAction,
  FetchJiraSyncFilterSuccessAction,
  FetchJiraTicketTypeByIntegrationIdSuccessAction,
  FetchJiraTicketTypesByIntegrationIdSuccessAction,
  FetchJiraTicketTypesSuccessAction,
  FetchSpFieldsSuccessAction,
  FetchSyncClientsSuccessAction,
  FetchSyncEnvironmentsSuccessAction,
  FetchSyncPrioritiesSuccessAction,
  FetchSyncSpecialistsSuccessAction,
  FetchSyncStatusesSuccessAction,
  FetchSyncTicketTypesSuccessAction,
  FetchSystemPrioritiesForCompareSuccessAction,
  FetchSystemPrioritiesSuccessAction,
  FetchTicketTypesSuccessAction,
  Jira,
  JiraIntegrationsActionsTypes,
  SetCurrentJiraIntegrationIdAction,
  SetCurrentJiraIntegrationsPageAction,
  SetCurrentSystemIdAction,
  SetCurrentTypeIdAction,
  SetJiraIntegrationsFilterAction,
  SetSizeJiraIntegrationsPageAction,
  SetSortJiraIntegrationsAction,
  SetSPStatusesForCompareAction,
  UpdateJiraIntegrationByIdAction,
} from './actionTypes';
import {
  Field,
  FieldsIntegration,
  JiraIntegration,
  JiraIntegrationsFilter,
  JiraPriority,
  JiraStatus,
  JiraSynchronization,
  JiraSynchronizationFilter,
  JiraTicketType,
  SPStatusesForCompare,
  Status,
  SystemPriorityForCompare,
} from './types';

export interface JiraReducerState {
  jiraIntegrations?: JiraIntegration[];
  loadingIntegrations?: boolean;
  pagination?: PaginationType;
  sort?: string;
  filter?: JiraIntegrationsFilter;

  jiraPrioritiesForCreate?: JiraPriority[];
  jiraPrioritiesForEdit?: JiraPriority[];
  jiraPrioritiesForCurrent?: JiraPriority[];

  jiraIntegrationId?: string;
  systemId?: string;
  loadingStatuses?: boolean;
  loadingPriorities?: boolean;
  jiraIntegration?: JiraIntegration;
  spFields?: Field[];
  jiraFields?: Field[];
  loadingJiraIntegrationInfo?: boolean;
  fieldsIntegrations?: FieldsIntegration[];
  systemPriorities?: SystemPriority[];
  systemPrioritiesForCompare?: SystemPriorityForCompare;

  spStatuses?: Status[];
  spStatusesForCompare?: SPStatusesForCompare;
  spStatusesForCurrent?: Status[];

  jiraTicketTypes?: JiraTicketType[];
  jiraTicketTypesForCurrent?: JiraTicketType[];
  jiraTicketTypeByIntegrationId?: JiraTicketType[];

  ticketTypes?: TicketType[];
  typeId?: string;
  integratedTicketTypesIds?: string[];

  jiraStatusesByPropertyId?: JiraStatus[];
  jiraStatusesBySystemId?: JiraStatus[];
  jiraStatusesForCreate?: JiraStatus[];

  synchronization?: {
    ticketTypes?: TicketType[];
    systemPriorities?: SystemPriority[];
    environments?: Environment[];
    statuses?: JiraSynchronizationFilter[];
    specialists?: JiraSynchronizationFilter[];
    clients?: JiraSynchronizationFilter[];
  };
  jiraSyncFilter?: JiraSynchronization;
}

const initialState: JiraReducerState = {
  jiraIntegrations: [],
  loadingIntegrations: false,
  pagination: DEFAULT_PAGINATION_PARAMS,
  sort: 'date_create_DESC',
  filter: {},
  jiraStatusesForCreate: [],
  jiraPrioritiesForCreate: [],
  loadingStatuses: false,
  loadingPriorities: false,
  spFields: [],
  jiraFields: [],
  loadingJiraIntegrationInfo: false,
  fieldsIntegrations: [],
  systemPriorities: [],
  systemPrioritiesForCompare: {},
  spStatuses: [],
  spStatusesForCompare: {},
  spStatusesForCurrent: [],
  jiraTicketTypes: [],
  jiraTicketTypesForCurrent: [],
  ticketTypes: [],
  jiraStatusesByPropertyId: [],
  integratedTicketTypesIds: [],

  synchronization: {
    ticketTypes: [],
    systemPriorities: [],
    environments: [],
    statuses: [],
    specialists: [],
    clients: [],
  },
  jiraSyncFilter: undefined,
};

const accessesReducerMap: ReducerMap<
  JiraReducerState,
  JiraIntegrationsActionsTypes
> = {
  [Jira.JIRA_INTEGRATIONS_LOADING_SHOW]: (state) => ({
    ...state,
    loadingIntegrations: true,
  }),
  [Jira.JIRA_INTEGRATIONS_LOADING_HIDE]: (state) => ({
    ...state,
    loadingIntegrations: false,
  }),
  [Jira.FETCH_JIRA_INTEGRATIONS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraIntegrationsSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      jiraIntegrations: content,
      pagination: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [Jira.SET_CURRENT_JIRA_INTEGRATIONS_PAGE]: (state, action) => {
    const { payload } = action as SetCurrentJiraIntegrationsPageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageNum: payload,
      },
    };
  },
  [Jira.SET_SIZE_JIRA_INTEGRATIONS_PAGE]: (state, action) => {
    const { payload } = action as SetSizeJiraIntegrationsPageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageSize: payload,
      },
    };
  },
  [Jira.SET_SORT_JIRA_INTEGRATIONS]: (state, action) => {
    const { payload } = action as SetSortJiraIntegrationsAction;
    return {
      ...state,
      sort: payload,
    };
  },
  [Jira.SET_FILTER_JIRA_INTEGRATIONS]: (state, action) => {
    const { payload } = action as SetJiraIntegrationsFilterAction;
    return {
      ...state,
      filter: payload,
    };
  },
  [Jira.FETCH_JIRA_STATUSES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraStatusesSuccessAction;
    return {
      ...state,
      jiraStatusesForCreate: payload,
    };
  },
  [Jira.FETCH_JIRA_PRIORITIES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraPrioritiesSuccessAction;
    return {
      ...state,
      jiraPrioritiesForCreate: payload,
    };
  },
  [Jira.FETCH_JIRA_PRIORITIES_BY_INTEGRATION_ID_SUCCESS]: (state, action) => {
    const { payload } =
      action as FetchJiraPrioritiesByIntegrationIdSuccessAction;
    return {
      ...state,
      jiraPrioritiesForEdit: payload,
    };
  },
  [Jira.SET_CURRENT_JIRA_INTEGRATION_ID]: (state, action) => {
    const { payload } = action as SetCurrentJiraIntegrationIdAction;
    return {
      ...state,
      jiraIntegrationId: payload,
    };
  },
  [Jira.SET_CURRENT_SYSTEM_ID]: (state, action) => {
    const { payload } = action as SetCurrentSystemIdAction;
    return {
      ...state,
      systemId: payload,
    };
  },
  [Jira.RESET_JIRA_STATUSES]: (state) => ({
    ...state,
    jiraStatusesForCreate: [],
  }),
  [Jira.RESET_JIRA_PRIORITIES]: (state) => ({
    ...state,
    jiraPrioritiesForCreate: [],
  }),
  [Jira.STATUSES_LOADING_SHOW]: (state) => ({
    ...state,
    loadingStatuses: true,
  }),
  [Jira.STATUSES_LOADING_HIDE]: (state) => ({
    ...state,
    loadingStatuses: false,
  }),
  [Jira.PRIORITIES_LOADING_SHOW]: (state) => ({
    ...state,
    loadingPriorities: true,
  }),
  [Jira.PRIORITIES_LOADING_HIDE]: (state) => ({
    ...state,
    loadingPriorities: false,
  }),
  [Jira.FETCH_JIRA_STATUSES_BY_ID_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraStatusesByIdSuccessAction;
    return {
      ...state,
      jiraStatusesBySystemId: payload,
    };
  },
  [Jira.FETCH_JIRA_PRIORITIES_BY_ID_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraPrioritiesByIdSuccessAction;
    return {
      ...state,
      jiraPrioritiesForCurrent: payload,
    };
  },
  [Jira.FETCH_JIRA_INTEGRATION_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraIntegrationSuccessAction;
    return {
      ...state,
      jiraIntegration: payload,
    };
  },
  [Jira.UPDATE_JIRA_INTEGRATION_BY_ID]: (state, action) => {
    const { payload } = action as UpdateJiraIntegrationByIdAction;

    return {
      ...state,
      jiraIntegrations: state?.jiraIntegrations?.map((jiraIntegration) => {
        if (jiraIntegration.id === payload.id) {
          return payload;
        }
        return jiraIntegration;
      }),
    };
  },
  [Jira.FETCH_SP_FIELDS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchSpFieldsSuccessAction;
    return {
      ...state,
      spFields: payload,
    };
  },
  [Jira.FETCH_JIRA_FIELDS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraFieldsSuccessAction;
    return {
      ...state,
      jiraFields: payload,
    };
  },
  [Jira.JIRA_INTEGRATION_INFO_LOADING_SHOW]: (state) => ({
    ...state,
    loadingJiraIntegrationInfo: true,
  }),
  [Jira.JIRA_INTEGRATION_INFO_LOADING_HIDE]: (state) => ({
    ...state,
    loadingJiraIntegrationInfo: false,
  }),
  [Jira.FETCH_FIELDS_INTEGRATIONS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchFieldsIntegrationsSuccessAction;
    return {
      ...state,
      fieldsIntegrations: payload,
    };
  },
  [Jira.FETCH_SYSTEM_PRIORITIES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchSystemPrioritiesSuccessAction;
    return {
      ...state,
      systemPriorities: payload,
    };
  },
  [Jira.FETCH_SYSTEM_PRIORITIES_FOR_COMPARE_SUCCESS]: (state, action) => {
    const { payload } = action as FetchSystemPrioritiesForCompareSuccessAction;
    return {
      ...state,
      systemPrioritiesForCompare: {
        ...state?.systemPrioritiesForCompare,
        ...payload,
      },
    };
  },
  [Jira.RESET_SYSTEM_PRIORITIES]: (state) => ({
    ...state,
    systemPriorities: initialState.systemPriorities,
  }),
  [Jira.FETCH_JIRA_SP_STATUSES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraSPStatusesSuccessAction;
    return {
      ...state,
      spStatuses: payload,
    };
  },
  [Jira.SET_SP_STATUSES_FOR_COMPARE]: (state, action) => {
    const { payload } = action as SetSPStatusesForCompareAction;
    return {
      ...state,
      spStatusesForCompare: {
        ...state?.spStatusesForCompare,
        ...payload,
      },
    };
  },
  [Jira.RESET_SP_STATUSES_FOR_COMPARE]: (state) => ({
    ...state,
    spStatusesForCompare: initialState.spStatusesForCompare,
  }),
  [Jira.RESET_JIRA_SP_STATUSES]: (state) => ({
    ...state,
    spStatuses: initialState.spStatuses,
  }),
  [Jira.FETCH_JIRA_SP_STATUSES_FOR_CURRENT_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraSPStatusesForCurrentSuccessAction;
    return {
      ...state,
      spStatusesForCurrent: payload,
    };
  },
  [Jira.RESET_JIRA_STATE]: () => ({
    ...initialState,
  }),
  [Jira.FETCH_TICKET_TYPES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchTicketTypesSuccessAction;
    return {
      ...state,
      ticketTypes: payload,
    };
  },
  [Jira.FETCH_JIRA_TICKET_TYPES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraTicketTypesSuccessAction;
    return {
      ...state,
      jiraTicketTypes: payload,
    };
  },
  [Jira.FETCH_JIRA_TICKET_TYPES_BY_INTEGRATION_ID_SUCCESS]: (state, action) => {
    const { payload } =
      action as FetchJiraTicketTypesByIntegrationIdSuccessAction;
    return {
      ...state,
      jiraTicketTypesForCurrent: payload,
    };
  },
  [Jira.FETCH_JIRA_TICKET_TYPE_BY_INTEGRATION_ID_SUCCESS]: (state, action) => {
    const { payload } =
      action as FetchJiraTicketTypeByIntegrationIdSuccessAction;
    return {
      ...state,
      jiraTicketTypeByIntegrationId: payload,
    };
  },
  [Jira.SET_CURRENT_TYPE_ID]: (state, action) => {
    const { payload } = action as SetCurrentTypeIdAction;
    return {
      ...state,
      typeId: payload,
    };
  },
  [Jira.FETCH_SYNC_TICKET_TYPES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchSyncTicketTypesSuccessAction;
    return {
      ...state,
      synchronization: {
        ...state?.synchronization,
        ticketTypes: payload,
      },
    };
  },
  [Jira.FETCH_SYNC_PRIORITIES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchSyncPrioritiesSuccessAction;
    return {
      ...state,
      synchronization: {
        ...state?.synchronization,
        systemPriorities: payload,
      },
    };
  },
  [Jira.FETCH_SYNC_ENVIRONMENTS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchSyncEnvironmentsSuccessAction;
    return {
      ...state,
      synchronization: {
        ...state?.synchronization,
        environments: payload.content,
      },
    };
  },
  [Jira.FETCH_SYNC_STATUSES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchSyncStatusesSuccessAction;
    return {
      ...state,
      synchronization: {
        ...state?.synchronization,
        statuses: payload,
      },
    };
  },
  [Jira.FETCH_SYNC_SPECIALISTS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchSyncSpecialistsSuccessAction;
    return {
      ...state,
      synchronization: {
        ...state?.synchronization,
        specialists: payload,
      },
    };
  },
  [Jira.FETCH_SYNC_CLIENTS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchSyncClientsSuccessAction;
    return {
      ...state,
      synchronization: {
        ...state?.synchronization,
        clients: payload,
      },
    };
  },
  [Jira.FETCH_JIRA_SYNC_FILTER_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraSyncFilterSuccessAction;
    return {
      ...state,
      jiraSyncFilter: payload,
    };
  },
  [Jira.EDIT_JIRA_SYNC_SUCCESS]: (state, action) => {
    const { payload } = action as EditJiraSyncSuccessAction;
    return {
      ...state,
      jiraSyncFilter: payload,
    };
  },
  [Jira.RESET_JIRA_SYNC]: (state) => ({
    ...state,
    synchronization: initialState.synchronization,
    jiraSyncFilter: initialState.jiraSyncFilter,
  }),
  [Jira.FETCH_JIRA_STATUSES_BY_PROPERTY_ID_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraStatusesByPropertyIdSuccessAction;
    return {
      ...state,
      jiraStatusesByPropertyId: payload,
    };
  },
  [Jira.FETCH_INTEGRATED_TICKET_TYPES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchIntegratedTicketTypesSuccessAction;
    return {
      ...state,
      integratedTicketTypesIds: payload,
    };
  },
};

export const jiraSlice: Reducer<
  JiraReducerState,
  JiraIntegrationsActionsTypes
> = (state = initialState, action) => {
  const reducer = accessesReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
