import { createSelector } from 'reselect';

import { NotificationContent, NotificationsState } from './types';

export const getAllNotifications = (state: NotificationsState) =>
  state.notifications.allNotifications;
export const getCurrentNotifications = (state: NotificationsState) =>
  state.notifications.currentNotifications;
export const getNotificationsMyTicketsIds = (
  state: NotificationsState
): string[] | undefined => state.notifications.notificationsMyTicketsIds;
export const getNotificationsNewTicketsIds = (
  state: NotificationsState
): string[] | undefined => state.notifications.notificationsNewTicketsIds;
export const getActiveNotificationId = (state: NotificationsState) =>
  state.notifications.activeNotificationId;
export const getActiveNotification = (state: NotificationsState) =>
  state.notifications.activeNotification;
export const getIsNotCheckedNotifications = createSelector<
  NotificationsState,
  NotificationContent[] | undefined,
  boolean
>([getAllNotifications], (allNotifications): boolean => {
  if (allNotifications && allNotifications.length) {
    return (
      allNotifications.findIndex((notification) => !notification?.checked) >= 0
    );
  }
  return false;
});
