import cn from 'classnames';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { createBemClass } from '../helpers/createBemClass';

import styles from './Tabs.module.scss';
import { type Tab, type TabSize } from './types';
import { TabsItem, type TabsItemProps } from './ui';

export interface TabsProps<T extends string = string>
  extends Omit<TabsItemProps<T>, 'tab' | 'fontSize'> {
  activeKey?: T;
  tabs: Tab[];
  fontSize?: TabSize;
}

const rootClassName = createBemClass('tabs');

export const Tabs = <T extends string>({
  className,
  tabs,
  disabled,
  fontSize = 16,
  activeKey,
  onClick,
  ...other
}: TabsProps<T>) => {
  const tabsRef = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();

  const tabsList = tabs.map((tab) => (
    <TabsItem
      key={tab.key ?? tab.to}
      disabled={disabled}
      fontSize={fontSize}
      isActive={activeKey === tab.key}
      onClick={onClick}
      tab={tab}
    />
  ));

  const { current } = tabsRef;

  useEffect(() => {
    setTimeout(() => {
      if (current) {
        current
          // eslint-disable-next-line quotes
          .querySelector("a[class$='active']")
          ?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
          });
      }
    }, 200);
  }, [pathname, current]);

  return (
    <div
      {...other}
      ref={tabsRef}
      className={cn(styles[rootClassName()], className)}
    >
      {tabsList}
    </div>
  );
};
