import {
  JiraConnectionStatus,
  JiraIntegrationCreateData,
  JiraIntegrationEditData,
  JiraSynchronizationCreateForm,
  TableJiraIntegrationsTypes,
} from '@entities/jira-integrations';
import { Priority } from '@entities/ticket';
import { RenderTypes, TableColumns } from '@shared';
import { Tab } from '@shared/ui-new/Tabs';

import { JiraIntegrationTabType } from '../model';

export const TABLE_HEADER_FULL_FIELDS: TableColumns = [
  { title: 'Название', sortField: 'name', fieldName: 'name' },
  { title: 'Статус', sortField: 'isActive', fieldName: 'status' },
  { title: 'Сервер', sortField: 'jiraUrl', fieldName: 'jiraUrl' },
  {
    title: 'Система',
    sortField: 'systemId',
    fieldName: 'system',
    renderType: RenderTypes.CONDITION,
  },
  {
    title: 'Организация',
    sortField: 'organizationId',
    fieldName: 'organization',
  },
  {
    title: 'Логин',
    sortField: 'login',
    fieldName: 'login',
  },
  {
    title: 'Ключ',
    sortField: 'projectKey',
    fieldName: 'projectKey',
  },
];

export const JIRA_CONNECTION_STATUS_TEXT_MAP = {
  [JiraConnectionStatus.DEFAULT]:
    'Выполните проверку подключения для того что бы продолжить',
  [JiraConnectionStatus.SUCCESS]: 'Успешно',
  [JiraConnectionStatus.ERROR]: 'Невозможно установить соединение',
};

export const INITIAL_JIRA_INTEGRATIONS_FILTER = {
  name: '',
  login: '',
  jiraUrl: '',
  projectKey: '',
  isActive: null,
  systemId: null,
  organizationId: null,
};

export const PAGINATION_RANGE_MAP: Record<
  TableJiraIntegrationsTypes,
  number | undefined
> = {
  [TableJiraIntegrationsTypes.FULL]: 1,
};

export const DEFAULT_JIRA_INTEGRATION_FORM_VALUES: JiraIntegrationCreateData = {
  organizationId: null,
  systemId: null,
  isActive: null,
  ticketTypeId: null,
};

export const DEFAULT_JIRA_SYNCHRONIZATION_FORM_VALUES: JiraSynchronizationCreateForm =
  {
    autoCreateTasks: false,
    considerFilters: false,
    filterRules: {
      TICKET_TYPE: [],
      TICKET_PRIORITY: [],
      TICKET_ENVIRONMENT: [],
      TICKET_STATUS: [],
      TICKET_SPECIALIST: [],
      TICKET_CLIENT: [],
    },
  };

export const DEFAULT_JIRA_CURRENT_FORM_VALUES: JiraIntegrationEditData = {
  ticketTypeId: null,
  jiraTicketType: null,
};

export const PRIORITY_MAP: Record<string, Priority> = {
  Lowest: Priority.NONE,
  Low: Priority.LOW,
  Medium: Priority.MIDDLE,
  High: Priority.HIGH,
  Highest: Priority.VERY_HIGH,
};

export const JIRA_TABS: Tab[] = [
  {
    key: JiraIntegrationTabType.integration,
    label: 'Интеграция',
  },
  {
    key: JiraIntegrationTabType.synchronization,
    label: 'Синхронизация',
  },
];
