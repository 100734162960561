import {
  Organization,
  OrganizationType,
  OrganizationTypeForCustomField,
} from '@entities/organization/model/types';
import { System } from '@entities/system/model';
import { NBoolean, NString, Nullable, ValueType } from '@shared';

import { CustomFieldsReducerState } from './slice';

export type CustomFieldsState = { customFields: CustomFieldsReducerState };

export type CustomField = {
  id: string;
  title?: string;
  customFieldType?: CustomFieldType;
  organization?: Partial<Pick<Organization, 'id' | 'title'>>;
  accessType?: OrganizationTypeForCustomField;
  system?: Partial<Pick<System, 'id' | 'title'>>;
  required?: boolean;
  values?: string[];
};

export type CustomTicketFields = {
  clientCustomFields: Nullable<CustomFieldToRequest[]>;
  specialistCustomFields: Nullable<CustomFieldToRequest[]>;
};

export type CustomFieldToRequest = CustomField &
  Partial<ValueCustomField> & {
    ticketId?: string;
    customFieldId?: string;
  };

export type CreateCustomFieldData = Omit<
  CustomFieldToRequest,
  'organizationId' | 'systemId' | 'id'
> & {
  organizationId?: ValueType<string>;
  systemId?: ValueType<string>;
};

export interface CustomFieldsFilter {
  title?: string;
  customFieldType?: ValueType<CustomFieldType>;
  organizationId?: ValueType<string>;
  systemId?: ValueType<string>;
  idsCustomFieldsDelete?: Array<string>;
  accessType?: ValueType<OrganizationType>;
}

export interface CustomFieldsFilterToRequest
  extends Omit<
    CustomFieldsFilter,
    'organizationId' | 'systemId' | 'customFieldType' | 'accessType'
  > {
  organizationId?: string;
  systemId?: string;
  customFieldType?: CustomFieldType;
  accessType?: OrganizationType;
}

export interface CustomFieldsData
  extends Pick<CustomField, 'title' | 'customFieldType'> {
  organization?: ValueType<string>;
  systemId?: ValueType<string>;
}

export interface DeleteCustomFieldTypeAction {
  id: string;
  systemTitle: string;
}

export enum CustomFieldType {
  FIELD_TEXT = 'TEXT',
  FIELD_DATE = 'DATE',
  FIELD_FLAG = 'FLAG',
  FIELD_LIST = 'LIST',
}

export type ValueCustomField = {
  text: NString;
  date: NString;
  flag: NBoolean;
  listValue?: NString;
  values?: Nullable<string[]>;
};

export type CustomFieldListOption<T> = Omit<T, 'listValue'> & {
  listValue?: ValueType<string>;
};

type ValueCustomFieldFilter = ValueCustomField & {
  listValues?: Nullable<string[]>;
};

export type CustomFieldTypeFilter = {
  fieldId: string;
  type: CustomFieldType;
} & Partial<ValueCustomFieldFilter>;

export type CustomFieldTypeForFilter = CustomFieldTypeFilter & {
  customFields?: CustomFieldTypeFilter[];
};
