import { FC, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';

import {
  contractSignRequestContractDetail,
  ContractStatus,
} from '@entities/contract';
import { Button, Tag } from '@shared';

import styles from './ContractSign.module.scss';

interface ContractSignProps {
  status?: ContractStatus;
  contractId: string;
}

export const ContractSign: FC<ContractSignProps> = ({ status, contractId }) => {
  const dispatch = useDispatch();

  const onSign = (event: MouseEvent) => {
    event.preventDefault();
    dispatch(contractSignRequestContractDetail(contractId));
  };

  return status === ContractStatus.NOT_SIGNED ? (
    <Button
      appearance="outline"
      className={styles.contractSign__btn}
      onClick={onSign}
    >
      Подписать
    </Button>
  ) : (
    <Tag text="Подписан" className={styles.contractSign__block} />
  );
};
