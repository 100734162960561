import { Action } from '@entities/actions/model/types';
import { Organization } from '@entities/organization/model/types';
import { ValueType } from '@shared';

import { ResponsibilityReducerState } from './slice';

export type ResponsibilitiesState = {
  responsibilities: ResponsibilityReducerState;
};

export type Responsibility = {
  id: string;
  title?: string;
  description?: string;
  actionList?: Action[];
  organization?: Organization;
  readOnly?: boolean;
};

export enum TableResponsibilitiesTypes {
  FULL = 'FULL',
  FROM_GROUPS = 'FROM_GROUPS',
  FROM_ROLES = 'FROM_ROLES',
  ADD_MODAL = 'ADD_MODAL',
}

export type ActionList = {
  actionId: string;
  actionStatus: string;
};

export type ResponsibilityFilter = Pick<
  Responsibility,
  'title' | 'description'
> & {
  organizationId?: ValueType<string>;
  idsResponsibilitiesDelete?: Array<string>;
};

export type ResponsibilityFilterToRequest = Omit<
  ResponsibilityFilter,
  'organizationId'
> & {
  organizationId?: string;
};

export type UpdateResponsibilityData = {
  actions: ActionList[];
  responsibilityData?: Omit<Responsibility, 'organization' | 'actionList'> & {
    organizationId?: ValueType<string>;
  };
};

export type CreateResponsibilityType = Omit<
  Responsibility,
  'organization' | 'id' | 'actionList'
> & {
  organizationId?: ValueType<string>;
  actionList?: ActionList[];
};

export type CreateResponsibilityToRequest = Omit<
  CreateResponsibilityType,
  'organizationId'
> & {
  organizationId?: string;
};
