import {
  JiraIntegration,
  JiraIntegrationEditData,
  JiraPriority,
  JiraStatus,
  JiraTicketType,
} from '@entities/jira-integrations';
import { convertValueTypeToValue, getValueFromValueType } from '@shared';

import { getIssueStatusUpdateDtos } from './getIssueStatusUpdateDtos';
import { getIssueTicketPriorityDtos } from './getIssueTicketPriorityDtos';

type Args = {
  data: JiraIntegrationEditData;
  statuses: JiraStatus[];
  priorities: JiraPriority[];
  jiraTicketTypes?: JiraTicketType[];
  jiraIntegration?: JiraIntegration;
  systemId?: string;
  jiraTicketTypeDisabled?: boolean;
};

export const getPreparedDataForEditIntegration = ({
  data,
  statuses,
  priorities,
  jiraTicketTypes,
  jiraIntegration,
  systemId,
  jiraTicketTypeDisabled,
}: Args) => {
  const ticketTypeId = convertValueTypeToValue<string>(data.ticketTypeId);

  const { jiraTicketType } = data;

  const args = {
    systemId,
    ticketTypeId,
  };

  const currentJiraTicketType = jiraTicketTypes?.find(
    (item) => item.idType === getValueFromValueType(jiraTicketType)
  );

  const ticketTypeUpdateDto = {
    ...currentJiraTicketType,
    propertyId: jiraIntegration?.id || '',
    ticketTypeId: ticketTypeId || '',
  };

  const issueStatusUpdateDtos = getIssueStatusUpdateDtos(statuses);
  const issueTicketPriorityDtos = getIssueTicketPriorityDtos(priorities);

  return {
    ...{
      statusMapping: {
        ...args,
        issueStatusUpdateDtos,
      },
    },
    ...{
      priorityMapping: {
        ...args,
        issueTicketPriorityDtos,
      },
    },
    ...(!jiraTicketTypeDisabled && { ticketTypeUpdateDto }),
    ticketTypeId,
  };
};
