import _ from 'lodash';

import {
  IssuePriorityCreateDtos,
  IssueStatusDtos,
  JiraIntegrationCreateData,
  JiraSynchronizationCreateForm,
  JiraTicketType,
  Priorities,
  RecordJiraTicketType,
  RecordValueTypeJiraTicketType,
  SPStatusesForCompare,
  Statuses,
  SystemPriorityForCompare,
} from '@entities/jira-integrations';
import { getSelectedJiraTicketType } from '@entities/jira-integrations/lib';
import { convertValueTypeToValue, getValueFromValueType } from '@shared';

import { getArraySyncFilterRules } from './getArraySyncFilterRules';

type Args = {
  data: JiraIntegrationCreateData;
  statuses: Statuses;
  spStatusesForCompare: SPStatusesForCompare;
  priorities: Priorities;
  systemPrioritiesForCompare: SystemPriorityForCompare;
  jiraTicketType: RecordValueTypeJiraTicketType;
  jiraTicketTypes: JiraTicketType[];
  ticketTypeKeys: string[];
  syncArgs: JiraSynchronizationCreateForm;
};

export const getPreparedDataForCreate = ({
  data,
  statuses,
  spStatusesForCompare,
  priorities,
  systemPrioritiesForCompare,
  jiraTicketType,
  jiraTicketTypes,
  ticketTypeKeys,
  syncArgs,
}: Args) => {
  const { organizationId, isActive } = data;

  const { autoCreateTasks, considerFilters, jiraPropertiesId, filterRules } =
    syncArgs;

  const systemId = convertValueTypeToValue<string>(data.systemId);

  const getValidate = <T extends Record<string, unknown>>(value: T) =>
    ticketTypeKeys.reduce<T>((acc, key) => {
      if (
        statuses[key]?.length === spStatusesForCompare[key]?.length &&
        priorities[key]?.length === systemPrioritiesForCompare[key]?.length
      ) {
        return { ...acc, [key]: value[key] };
      }
      return acc;
    }, {} as T);

  const validatedStatuses = getValidate(statuses);

  const mappingStatuses = _.reduce(
    validatedStatuses,
    (_acc, _value, _key) => {
      const value = _value.reduce<IssueStatusDtos[]>((acc, el) => {
        const result = acc.find((item) => item.idStatus === el.idStatus);
        const { ticketStatusIds } = el;

        if (result) {
          return [
            ...acc.filter((item) => item.idStatus !== el.idStatus),
            {
              ...el,
              ticketStatusIds: [...ticketStatusIds, ...result.ticketStatusIds],
            },
          ];
        }

        return [...acc, { ...el, ticketStatusIds }];
      }, []);

      return { ..._acc, [_key]: value };
    },
    {} as Statuses
  );

  const validatedPriorities = getValidate(priorities);

  const mappingPriorities = _.reduce(
    validatedPriorities,
    (_acc, _value, _key) => {
      const value = _value.reduce<IssuePriorityCreateDtos[]>((acc, el) => {
        const result = acc.find((item) => item.idPriority === el.idPriority);
        const { ticketPriorities } = el;

        if (result && result.ticketPriorities && ticketPriorities) {
          return [
            ...acc.filter((item) => item.idPriority !== el.idPriority),
            {
              ...el,
              ticketPriorities: [
                ...ticketPriorities,
                ...result.ticketPriorities,
              ],
            },
          ];
        }

        return [...acc, { ...el, ticketPriorities }];
      }, []);

      return { ..._acc, [_key]: value };
    },
    {} as Priorities
  );

  const validatedTypes = getValidate(jiraTicketType);

  const mappingTypes = _.reduce(
    validatedTypes,
    (acc, value, key) => {
      const result = getSelectedJiraTicketType(
        getValueFromValueType(value),
        jiraTicketTypes
      );

      if (result) {
        return { ...acc, [key]: { ...result, ticketTypeId: key } };
      }

      return acc;
    },
    {} as RecordJiraTicketType
  );

  const ticketTypeIds = Object.keys(validatedTypes);

  return {
    ...data,
    organizationId: convertValueTypeToValue<string>(organizationId),
    systemId,
    isActive: convertValueTypeToValue<boolean>(isActive),
    ticketTypeIds,
    mappingStatuses,
    mappingPriorities,
    mappingTypes,
    jiraSyncFilter: {
      autoCreateTasks,
      considerFilters,
      jiraPropertiesId,
      filterRules: getArraySyncFilterRules(filterRules),
    },
  };
};
