import cn from 'clsx';
import { FC, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import {
  getNotificationTabs,
  getProfileTabsUnchecked,
} from '@entities/profile';
import { ArrowPaginationIcon, IconButton, Tabs } from '@shared';

import { convertProfileTabToTab } from '../../lib/utils/convertProfileTabToTab';
import { getTabsWithStatusesProfile } from '../../lib/utils/getTabsWithStatuses';
import { NotificationInformation } from '../../ui/NotificationInformation';
import { ProfileDrop } from '../../ui/ProfileDrop';

import styles from './ProfileNotificationsLayout.module.scss';

export const ProfileNotificationsLayout: FC = ({ children }) => {
  const tabs = useSelector(getNotificationTabs);

  const tabsWithStatuses = useSelector(getProfileTabsUnchecked);

  const layoutRef = useRef<HTMLDivElement>(null);
  const tabsRef = useRef<HTMLDivElement>(null);

  const tabsToRender = useMemo(
    () =>
      convertProfileTabToTab(
        getTabsWithStatusesProfile(tabs, tabsWithStatuses)
      ),
    [tabs, tabsWithStatuses]
  );

  const tabsList = tabsToRender && (
    <Tabs
      tabs={tabsToRender}
      tabsRef={tabsRef}
      className={styles.notificationsProfile__tabLine}
      classNameTab={styles.notificationsProfile__tabsItem}
    />
  );

  const handleScrollLeft = () => {
    if (tabsRef?.current) {
      tabsRef?.current.scrollBy({
        left: -150,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollRight = () => {
    if (tabsRef?.current) {
      tabsRef?.current.scrollBy({
        left: 150,
        behavior: 'smooth',
      });
    }
  };

  const notificationsHeader = (
    <div className={styles.notificationsProfile__tabs}>
      {tabsList}
      <div className={styles.notificationsProfile__arrows}>
        <IconButton
          appearance="flat"
          icon={
            <ArrowPaginationIcon
              className={cn(
                styles.notificationsProfile__arrowsItem,
                styles.notificationsProfile__arrowsItem_left
              )}
            />
          }
          onClick={handleScrollLeft}
        />
        <IconButton
          appearance="flat"
          icon={
            <ArrowPaginationIcon
              className={styles.notificationsProfile__arrowsItem}
            />
          }
          onClick={handleScrollRight}
        />
      </div>
      <ProfileDrop withFilters withTabs />
    </div>
  );

  return (
    <div className={styles.notificationsProfile} ref={layoutRef}>
      <div className={styles.notificationsProfile__content}>
        {notificationsHeader}
        {children}
      </div>
      <NotificationInformation />
    </div>
  );
};
