import cn from 'clsx';
import { FC, useEffect, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';

import {
  EntityTypes,
  getActiveNotificationId,
  NotificationComponent,
  NotificationContent,
  NotificationTypeEnum,
  setActiveNotification,
  setActiveNotificationId,
} from '@entities/notifications';
import {
  fetchNotificationsProfileRequest,
  fetchNotificationTabsRequest,
  fetchStorageRequest,
  fetchSystemRequest,
  getNotificationTabs,
  resetNotificationList,
  setCheckedNotificationRequest,
  setNotificationsProfileFilter,
} from '@entities/profile';
import {
  fetchTicketRequestTickets,
  getTicketLoading,
  resetCreateTicketsState,
} from '@entities/ticket';
import {
  BigBell,
  getProfileTitle,
  getScreenWidth,
  Loader,
  ScreenWidth,
  Typography,
  TypographyVariants,
} from '@shared';

import { PageTitleLayout } from '../../layouts';
import { useTabType } from '../../lib';
import { convertPathToTabTitle } from '../../lib/utils/convertTabTitleToPath';
import { formatDateTitle } from '../../lib/utils/fotmatDataTitle';
import { splitByDate } from '../../lib/utils/splitByDate';

import styles from './NotificationsList.module.scss';

interface NotificationsListProps {
  notifications: NotificationContent[];
  onLoadMore: (page: number) => void;
  hasMore: boolean;
  loading?: boolean;
  totalPages: number;
}

export const NotificationsList: FC<NotificationsListProps> = ({
  notifications,
  onLoadMore,
  hasMore,
  loading,
  totalPages,
}) => {
  const notificationsAll = splitByDate(notifications);

  const dispatch = useDispatch();

  const activeId = useSelector(getActiveNotificationId);
  const screenWidth = useSelector(getScreenWidth);
  const tabs = useSelector(getNotificationTabs);
  const ticketLoading = useSelector(getTicketLoading);

  const isMobile = screenWidth === ScreenWidth.MOBILE;
  const isMobileSmall = screenWidth === ScreenWidth.MOBILE_SMALL;
  const isMobileAll = isMobile || isMobileSmall;

  const tabType = useTabType();

  const notificationPathToType = convertPathToTabTitle(tabType);

  const documentTitle = useMemo(
    () =>
      getProfileTitle(
        tabs.find((tab) => tab.titleNotification === notificationPathToType)
          ?.description || 'Все уведомления'
      ),
    [tabType, tabs]
  );

  const setNotification = (value: NotificationContent) => {
    const { entityType, entityId, notificationType } = value;

    const isSystemType = entityType === EntityTypes.SYSTEM;
    const isStorageType = entityType === EntityTypes.STORAGE;
    const isPublishedNews =
      notificationType === NotificationTypeEnum.PUBLISHED_NEWS;

    dispatch(setActiveNotification(value));

    if (entityId) {
      if (isSystemType && !isPublishedNews) {
        dispatch(fetchSystemRequest(entityId));
      }
      if (isStorageType) {
        dispatch(fetchStorageRequest(entityId));
      }
    }
  };

  const handleNotificationClick = (value: NotificationContent) => {
    if (value.entityId && value.entityType === EntityTypes.TICKET) {
      dispatch(fetchTicketRequestTickets(value.entityId));
    }
    if (value.id) {
      dispatch(setActiveNotificationId(value.id));
      dispatch(setCheckedNotificationRequest(value.id));
    }
    setNotification(value);
  };

  const handleLoadMore = (page: number) => {
    if (page < totalPages && !loading) {
      onLoadMore(page);
    }
  };

  useEffect(() => {
    if (tabType) {
      dispatch(resetCreateTicketsState());
      dispatch(resetNotificationList());
      dispatch(fetchNotificationTabsRequest());
      dispatch(setActiveNotificationId(''));
      dispatch(
        setNotificationsProfileFilter({
          notificationType: convertPathToTabTitle(tabType),
          notChecked: false,
        })
      );
      dispatch(
        fetchNotificationsProfileRequest({
          updateType: 'update',
          pageNum: 0,
        })
      );
    }
  }, [tabType]);

  if (!Object.keys(notificationsAll).length) {
    return (
      <div className={styles.notificationsList__noData}>
        {loading && <Loader />}
        {!loading && (
          <>
            <BigBell className={styles.notificationsList__bellIcon} />
            <Typography variant={TypographyVariants.b2}>
              У вас нет уведомлений
            </Typography>
          </>
        )}
      </div>
    );
  }

  const notificationsList = Object.keys(notificationsAll).map((key: string) => (
    <div key={key}>
      {formatDateTitle(key)}
      {notificationsAll[key].map((item) => {
        const isActiveId = item?.id === activeId;

        return (
          <div
            key={item.id}
            className={cn(styles.notificationsList__itemWrapper, {
              [styles.notificationsList__itemWrapper_active]: isActiveId,
            })}
          >
            <NotificationComponent
              notification={item}
              className={cn(styles.notificationsList__item, {
                [styles.notificationsList__item_disabled]:
                  ticketLoading || isActiveId,
              })}
              onClick={handleNotificationClick}
              disabled={ticketLoading || isActiveId}
            />
          </div>
        );
      })}
    </div>
  ));

  return (
    <PageTitleLayout pageTitle={documentTitle}>
      <div className={styles.notificationsList}>
        <InfiniteScroll
          loadMore={handleLoadMore}
          hasMore={!loading && hasMore}
          useWindow={isMobileAll}
          className={styles.notificationsList__content}
          threshold={10}
        >
          {notificationsList}
        </InfiniteScroll>
      </div>
    </PageTitleLayout>
  );
};
