import { isNil } from 'lodash';
import { createSelector } from 'reselect';

import { DEFAULT_CUSTOM_FIELD_VALUES } from '@entities/custom-fields/config';
import { CustomField } from '@entities/custom-fields/model/types';
import { Environment } from '@entities/environment/model/types';
import { Ticket, TicketType } from '@entities/ticket/model/types';
import { getArraySelectOptions, SelectOption } from '@shared';

import { CreateTicketCustomField, CreateTicketState } from './types';

export const getEnvironments = (state: CreateTicketState) =>
  state.createTicket.environments;
export const getLoading = (state: CreateTicketState) =>
  state.createTicket.loading;
export const getLoadingCreate = (state: CreateTicketState) =>
  state.createTicket.loadingCreate;
export const getTicketTypes = (state: CreateTicketState) =>
  state.createTicket.ticketTypes;
export const getRequiredCustomFields = (state: CreateTicketState) =>
  state.createTicket.customFields;
export const getPropsEnvironments = (state: CreateTicketState) => ({
  pageNum: state.createTicket.environmentsPagination?.pageNum,
  pageSize: state.createTicket.environmentsPagination?.pageSize,
  totalElements: state.createTicket.environmentsPagination?.totalElements,
  sort: state.createTicket.environmentsSort,
  loading: state.createTicket.environmentsLoading,
});

export const getEnvironmentsSelectList = createSelector<
  CreateTicketState,
  Environment[] | undefined,
  SelectOption[]
>([getEnvironments], (environments): SelectOption[] =>
  getArraySelectOptions(environments)
);

export const getTicketTypesSelectList = createSelector<
  CreateTicketState,
  TicketType[] | undefined,
  SelectOption[]
>([getTicketTypes], (ticketTypes): SelectOption[] =>
  getArraySelectOptions(ticketTypes)
);

const getTickets = (state: CreateTicketState) => state.createTicket.tickets;

export const getTicketsSelectList = createSelector<
  CreateTicketState,
  Ticket[] | undefined,
  SelectOption[]
>([getTickets], (tickets): SelectOption[] => {
  if (tickets) {
    return tickets.map(
      (ticket: Ticket): SelectOption => ({
        title: ticket.number || '',
        value: ticket.id || '',
        meta: ticket.theme,
      })
    );
  }
  return [];
});
export const getPropsTickets = (state: CreateTicketState) => {
  const totalElements = state.createTicket.paginationTickets?.totalElements;
  const pageSize = state.createTicket.paginationTickets?.pageSize;
  return {
    pageNum: state.createTicket.paginationTickets?.pageNum,
    pageSize,
    totalElements,
    loadingTickets: state.createTicket.loadingTickets,
    totalPagesTickets:
      !isNil(totalElements) && !isNil(pageSize)
        ? Math.ceil(totalElements / pageSize)
        : 0,
  };
};
export const getFilterTickets = (state: CreateTicketState) =>
  state.createTicket.filterTickets;

export const getCustomFields = createSelector<
  CreateTicketState,
  CustomField[] | undefined,
  CreateTicketCustomField[]
>(
  [getRequiredCustomFields],
  (customFields): CreateTicketCustomField[] =>
    customFields?.map(({ id, customFieldType, title, values }) => ({
      customFieldId: id,
      type: customFieldType,
      title,
      values,
      ...DEFAULT_CUSTOM_FIELD_VALUES,
    })) ?? []
);
