import { createSelector } from 'reselect';

import { Storage } from '@entities/storage/model/storage/types';
import { getArraySelectOptions, KeyValueOption, SelectOption } from '@shared';

import { StoragesState } from './types';

export const getOrganizations = (state: StoragesState) =>
  state.storages.organizations;
export const getOrganizationsOptions = createSelector<
  StoragesState,
  KeyValueOption[] | undefined,
  SelectOption[]
>([getOrganizations], (organizations): SelectOption[] => {
  if (organizations) {
    return organizations.map(
      (organization): SelectOption => ({
        title: organization.value,
        value: organization.key,
      })
    );
  }
  return [];
});

export const getWorkGroupsViewers = (state: StoragesState) =>
  state.storages.workGroupsViewers;
export const getWorkGroupsViewersOptions = createSelector<
  StoragesState,
  KeyValueOption[] | undefined,
  SelectOption[]
>([getWorkGroupsViewers], (viewers): SelectOption[] => {
  if (viewers) {
    return viewers.map(
      (viewer): SelectOption => ({
        title: viewer.value,
        value: viewer.key,
      })
    );
  }
  return [];
});

export const getWorkGroupsEditors = (state: StoragesState) =>
  state.storages.workGroupsEditors;
export const getWorkGroupsEditorsOptions = createSelector<
  StoragesState,
  KeyValueOption[] | undefined,
  SelectOption[]
>([getWorkGroupsEditors], (editors): SelectOption[] => {
  if (editors) {
    return editors.map(
      (editor): SelectOption => ({
        title: editor.value,
        value: editor.key,
      })
    );
  }
  return [];
});

export const getOrganizationsForCreate = (state: StoragesState) =>
  state.storages.organizationsForCreate;
export const getOrganizationsForCreateOptions = createSelector<
  StoragesState,
  KeyValueOption[] | undefined,
  SelectOption[]
>([getOrganizationsForCreate], (organizationsForCreate): SelectOption[] => {
  if (organizationsForCreate) {
    return organizationsForCreate.map(
      (organization): SelectOption => ({
        title: organization.value,
        value: organization.key,
      })
    );
  }
  return [];
});

export const getOrganizationIdsViewers = (state: StoragesState) =>
  state.storages.organizationIdsViewers;

export const getOrganizationIdsEditors = (state: StoragesState) =>
  state.storages.organizationIdsEditors;

export const getStorages = (state: StoragesState) => state.storages.storages;
export const getStoragesContent = (state: StoragesState) =>
  state.storages.storages?.content;
export const getStoragesOptions = createSelector<
  StoragesState,
  Storage[] | undefined,
  SelectOption[]
>([getStoragesContent], (storages): SelectOption[] =>
  getArraySelectOptions(storages)
);

export const getAdminOrganizationId = (state: StoragesState) =>
  state.storages.adminOrganizationId;

export const getPaginationParams = (state: StoragesState) => ({
  totalPages: state.storages.storages?.totalPages,
  pageNum: state.storages.storages?.page,
  loading: state.storages.loadingStorages,
});

export const getPropsOrganization = (state: StoragesState) => ({
  pageNum: state?.storages.paginationOrganization?.pageNum,
  pageSize: state?.storages.paginationOrganization?.pageSize,
  totalElements: state.storages.paginationOrganization?.totalElements,
  sort: state?.storages.sortSelect,
  loading: state.storages.loadingOrganization,
});

export const getPropsViewersGroups = (state: StoragesState) => ({
  pageNum: state?.storages.paginationGroupsViewers?.pageNum,
  pageSize: state?.storages.paginationGroupsViewers?.pageSize,
  totalElements: state.storages.paginationGroupsViewers?.totalElements,
  sort: state?.storages.sortSelect,
  loading: state.storages.loadingGroupsViewers,
});

export const getPropsEditorsGroups = (state: StoragesState) => ({
  pageNum: state?.storages.paginationGroupsEditors?.pageNum,
  pageSize: state?.storages.paginationGroupsEditors?.pageSize,
  totalElements: state.storages.paginationGroupsEditors?.totalElements,
  sort: state?.storages.sortSelect,
  loadingEditorsGroups: state.storages.loadingEditorsGroups,
});
