import { Reducer } from 'redux';

import { Organization } from '@entities/organization/model/types';
import { System } from '@entities/system/model';
import { TicketType } from '@entities/ticket/model/types';
import { DEFAULT_PAGINATION_PARAMS, PaginationType, ReducerMap } from '@shared';

import {
  FetchOrganizationsSuccessAction,
  FetchSystemsSuccessAction,
  FetchTicketTypesForSystemsSuccessAction,
  FetchTicketTypesForSystemSuccessAction,
  FetchWorkflowSuccessAction,
  SetCurrentSystemIdAction,
  SetCurrentSystemsPageAction,
  SetPageSizeAction,
  SetSortSystemsAction,
  SetSystemsFilterAction,
  StatusesBuilder,
  StatusesBuilderActionsTypes,
} from './actionTypes';
import { SystemsFilter, SystemsTicketTypes, Workflow } from './types';

export interface StatusesBuilderReducerState {
  systems?: System[];
  loading?: boolean;
  pagination?: PaginationType;
  sort?: string;
  systemId?: string;
  systemsFilter?: SystemsFilter;
  organizations?: Organization[];
  workflow?: Workflow;
  ticketTypesForSystem?: TicketType[];
  ticketTypesForSystems?: SystemsTicketTypes;
}

const initialState: StatusesBuilderReducerState = {
  systems: [],
  loading: false,
  pagination: DEFAULT_PAGINATION_PARAMS,
  sort: 'date_create_DESC',
  systemsFilter: {},
  organizations: [],
  ticketTypesForSystem: [],
  ticketTypesForSystems: [],
};

const statusesBuilderReducerMap: ReducerMap<
  StatusesBuilderReducerState,
  StatusesBuilderActionsTypes
> = {
  [StatusesBuilder.BUILDER_SYSTEMS_LOADING_SHOW]: (state) => ({
    ...state,
    loading: true,
  }),
  [StatusesBuilder.BUILDER_SYSTEMS_LOADING_HIDE]: (state) => ({
    ...state,
    loading: false,
  }),
  [StatusesBuilder.FETCH_BUILDER_SYSTEMS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchSystemsSuccessAction;
    const { content, page, totalElements } = payload;

    return {
      ...state,
      systems: content,
      pagination: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [StatusesBuilder.SET_CURRENT_BUILDER_SYSTEMS_PAGE]: (state, action) => {
    const { payload } = action as SetCurrentSystemsPageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageNum: payload,
      },
    };
  },
  [StatusesBuilder.SET_PAGE_SIZE_BUILDER_SYSTEMS]: (state, action) => {
    const { payload } = action as SetPageSizeAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageSize: payload,
      },
    };
  },
  [StatusesBuilder.SET_SORT_BUILDER_SYSTEMS]: (state, action) => {
    const { payload } = action as SetSortSystemsAction;
    return {
      ...state,
      sort: payload,
    };
  },
  [StatusesBuilder.SET_CURRENT_BUILDER_SYSTEM_ID]: (state, action) => {
    const { payload } = action as SetCurrentSystemIdAction;
    return {
      ...state,
      systemId: payload,
    };
  },
  [StatusesBuilder.SET_BUILDER_SYSTEMS_FILTER]: (state, action) => {
    const { payload } = action as SetSystemsFilterAction;
    return {
      ...state,
      systemsFilter: payload,
    };
  },
  [StatusesBuilder.FETCH_BUILDER_ORGANIZATIONS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchOrganizationsSuccessAction;

    return {
      ...state,
      organizations: payload,
    };
  },
  [StatusesBuilder.FETCH_WORKFLOW_SUCCESS]: (state, action) => {
    const { payload } = action as FetchWorkflowSuccessAction;
    return {
      ...state,
      workflow: payload,
    };
  },
  [StatusesBuilder.FETCH_TICKET_TYPES_FOR_SYSTEM_SUCCESS]: (state, action) => {
    const { payload } = action as FetchTicketTypesForSystemSuccessAction;
    return {
      ...state,
      ticketTypesForSystem: payload,
    };
  },
  [StatusesBuilder.FETCH_TICKET_TYPES_FOR_SYSTEMS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchTicketTypesForSystemsSuccessAction;
    return {
      ...state,
      ticketTypesForSystems: payload,
    };
  },
  [StatusesBuilder.RESET_STATUSES_BUILDER_STATE]: () => ({
    ...initialState,
  }),
};

export const statusesBuilderSlice: Reducer<
  StatusesBuilderReducerState,
  StatusesBuilderActionsTypes
> = (state = initialState, action) => {
  const reducer = statusesBuilderReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
