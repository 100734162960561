import cn from 'clsx';
import { FC, useMemo } from 'react';

import { TableTicketsTypes } from '@entities/ticket';
import {
  PieChartPriority,
  TicketsDashboard,
  TicketsDropContent,
} from '@features/desktop';
import { TicketsTableContainer } from '@features/ticket';
import { ErrorRoundIcon } from '@shared';

import { useDownloadTicketsData } from '../../lib';

import styles from './DesktopHistory.module.scss';

interface DesktopHistoryProps {
  className?: string;
}

export const DesktopHistory: FC<DesktopHistoryProps> = ({ className }) => {
  const {
    state: {
      tickets,
      ticketsStatistic,
      isDownload,
      isReportClients,
      isFilterActive,
    },
    methods: { handleDownload },
  } = useDownloadTicketsData();

  const tableTitle = useMemo(
    () => (
      <div className={styles.desktopHistory__titleWrapper}>
        <span className={styles.desktopHistory__title}>
          Тикеты с нарушением SLA
        </span>
        {Boolean(tickets?.length) && <ErrorRoundIcon />}
      </div>
    ),
    [tickets]
  );

  const downloadTickets = tickets?.length ? (
    <TicketsDropContent
      isDownload={isDownload}
      handleDownload={handleDownload}
    />
  ) : undefined;

  return (
    <div className={cn(styles.desktopHistory, className)}>
      <div className={styles.desktopHistory__leftContainer}>
        <TicketsDashboard
          ticketsStatistic={ticketsStatistic}
          reportClients={isReportClients}
        />
        <PieChartPriority
          title="Тикеты по приоритетам"
          ticketsStatistic={ticketsStatistic}
          className={styles.desktopHistory__chart}
        />
      </div>
      <TicketsTableContainer
        tableType={TableTicketsTypes.FROM_DESKTOP_HISTORY}
        title={tableTitle}
        withOutTabs
        withFilter
        dropContent={downloadTickets}
        className={styles.desktopHistory__table}
        isFilterActive={isFilterActive}
      />
    </div>
  );
};
