import { NotificationsReducerState } from './slice';

export type NotificationsState = { notifications: NotificationsReducerState };

export enum NotificationTypeEnum {
  SPECIALIST_TOOK_TICKET_TO_WORK = 'SPECIALIST_TOOK_TICKET_TO_WORK',
  ASSIGNED_ANOTHER_SPECIALIST = 'ASSIGNED_ANOTHER_SPECIALIST',
  SPECIALIST_SENT_INFORMATION_REQUEST = 'SPECIALIST_SENT_INFORMATION_REQUEST',
  SPECIALIST_SUGGESTED_SOLUTION = 'SPECIALIST_SUGGESTED_SOLUTION',
  YOU_HAVE_BEEN_ASSIGNED_TICKET = 'YOU_HAVE_BEEN_ASSIGNED_TICKET',
  TICKET_WAS_ASSIGNED_ANOTHER_SPECIALIST = 'TICKET_WAS_ASSIGNED_ANOTHER_SPECIALIST',
  CLIENT_PROVIDED_INFORMATION = 'CLIENT_PROVIDED_INFORMATION',
  CLIENT_REJECTED_SOLUTION = 'CLIENT_REJECTED_SOLUTION',
  CLIENT_ACCEPTED_SOLUTION = 'CLIENT_ACCEPTED_SOLUTION',
  SLA_DECISION_EXPIRING = 'SLA_DECISION_EXPIRING',
  SLA_DECISION_EXPIRED = 'SLA_DECISION_EXPIRED',
  SLA_REACTION_EXPIRING = 'SLA_REACTION_EXPIRING',
  SLA_REACTION_EXPIRED = 'SLA_REACTION_EXPIRED',
  SLA_DECISION_EXPIRED_FOR_MANAGER = 'SLA_DECISION_EXPIRED_FOR_MANAGER',
  TICKET_AUTO_CLOSE_NOTIFICATION = 'TICKET_AUTO_CLOSE_NOTIFICATION',
  CLIENT_CREATED_NEW_TICKET = 'CLIENT_CREATED_NEW_TICKET',
  STATUS_CHANGE_FOR_SPECIALIST = 'STATUS_CHANGE_FOR_SPECIALIST',
  STATUS_CHANGE_FOR_CLIENT = 'STATUS_CHANGE_FOR_CLIENT',
  SYSTEM_INDEX_CHANGE = 'SYSTEM_INDEX_CHANGE',
  TICKET_WAS_CLOSED_BY_ADMIN = 'TICKET_WAS_CLOSED_BY_ADMIN',
  NEW_COMMENT = 'NEW_COMMENT',
  NEW_FILE_UPLOAD_IN_STORAGE = 'NEW_FILE_UPLOAD_IN_STORAGE',
  FILE_UPDATED_IN_STORAGE = 'FILE_UPDATED_IN_STORAGE',
  STORAGE_ACCESS_GRANTED = 'STORAGE_ACCESS_GRANTED',
  TICKET_WAS_DELETED = 'TICKET_WAS_DELETED',
  TICKET_PRIORITY_CHANGED = 'TICKET_PRIORITY_CHANGED',
  YOU_HAVE_BEEN_ASSIGNED_TICKET_BY_SYSTEM = 'YOU_HAVE_BEEN_ASSIGNED_TICKET_BY_SYSTEM',
  TICKET_WAS_RESTORED = 'TICKET_WAS_RESTORED',
  TICKET_WAS_REOPENED = 'TICKET_WAS_REOPENED',
  TICKET_TYPE_CHANGED = 'TICKET_TYPE_CHANGED',
  PUBLISHED_NEWS = 'PUBLISHED_NEWS',
}

export enum NotificationType {
  ALL_CHANGES = 'ALL_CHANGES',
  STATUS_CHANGE = 'STATUS_CHANGE',
  MY_TICKETS = 'MY_TICKETS',
  NEW_TICKETS = 'NEW_TICKETS',
}

export enum EntityTypes {
  TICKET = 'TICKET',
  SYSTEM = 'SYSTEM',
  STORAGE = 'STORAGE',
}

export type NotificationContent = {
  id?: string;
  createdWhen?: string;
  entityId?: string;
  entityType?: EntityTypes;
  linkName?: string;
  notificationTitle?: string;
  notificationType: NotificationTypeEnum;
  ticketIds?: string[];
  checked?: boolean;
  notificationMessage?: string;
};

export type Notification = {
  type: NotificationType;
  content: NotificationContent;
};
