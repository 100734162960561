import { MouseEvent } from 'react';

import { AttachmentsFiles } from '@entities/attachment/model/types';
import { CustomFieldType, ValueCustomField } from '@entities/custom-fields';
import { OrganizationType } from '@entities/organization/model/types';
import { User } from '@entities/user/model/types';
import { WorkGroup } from '@entities/work-group/model';
import { KeyValueOption, ValueType } from '@shared';

export interface LocationState {
  organizationId?: string;
  organizationTitle?: string;
  tagName?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AbstractDataType = { [key: string]: any };

export type TicketTab = {
  title: string | JSX.Element;
  onTabClick: () => void;
  className?: string;
  isTabActive?: boolean;
};

export enum TicketStatus {
  CLOSE = 'CLOSE',
  NEW = 'NEW',
  PENDING_CLOSURE = 'PENDING_CLOSURE',
  WORK = 'WORK',
  WAITING_INFO = 'WAITING_INFO',
  REOPEN = 'REOPEN',
}

export enum Priority {
  NONE = 'NONE',
  LOW = 'LOW',
  MIDDLE = 'MIDDLE',
  HIGH = 'HIGH',
  VERY_HIGH = 'VERY_HIGH',
}

export interface TicketFileType {
  e: MouseEvent;
  path?: string;
  fileName: string;
  attachmentId?: string;
  attachmentFileId?: string;
}

export type TicketClient = {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  middleName: string;
  organizationType: OrganizationType;
  phoneNumber: string;
  organizationTitle: string;
  organizationId: string;
};

export type Solution = {
  attachments?: string[];
  solutionId?: string;
  textSolution?: string;
};

export type TicketSLA = {
  decisionDate: string;
  maxDecisionDate: string;
  reactionDate: string;
  maxReactionDate: string;
  onPause: boolean;
  roundTheClock: boolean;
  slaType: TicketSLAType;
  to: string;
  from: string;
  expiredSeconds?: number;
  reactionExpireSeconds?: number;
  maxDecisionSeconds?: number;
  maxReactionSeconds?: number;
  secondsAfterTimerRefresh?: number;
  secondsOnTimer?: number;
  nowIsWorkTime?: boolean;
  expired?: boolean;
};

export type TicketType = {
  id: string;
  title: string;
  value: string;
};

export type TicketTypeSettings = {
  ticketTypeId: string;
  active?: boolean;
  title: string;
};

export type Status = {
  id: string;
  name: string;
  isComment: boolean;
  isNotifications: boolean;
  isSLA: boolean;
  color: string;
  defaultStatus?: TicketStatus;
};

export type TicketStatusesRequest = {
  excludeStatuses?: TicketStatus[];
  systemIds?: string[];
  nextToIds?: string[];
  typeIds?: string[];
};

export type Tag = {
  id: string;
  name?: string;
};

export type TableCustomField = {
  accessType: string;
  customFieldId: string | null;
  customFieldType: CustomFieldType;
  id: string;
  systemId: string;
  title: string;
} & ValueCustomField;

export type Ticket = {
  id?: string;
  customStatus?: Status;
  clientId?: KeyValueOption;
  clientInfo?: TicketClient;
  client: KeyValueOption;
  contractId?: KeyValueOption;
  specialistId?: KeyValueOption;
  workGroups: WorkGroup[];
  priority?: Priority | null;
  environmentId: KeyValueOption;
  systemId?: KeyValueOption;
  versionId?: string;
  sla?: TicketSLA;
  number?: string;
  theme?: string;
  description?: string;
  solution?: Solution;
  attachments?: string[];
  dateCreate?: string;
  dateUpdate?: string;
  dateSolve?: string;
  dateClose?: string;
  expired?: boolean;
  tags?: Tag[];
  slaInfo?: TicketSLA;
  systemName?: string;
  systemVersion?: string;
  ticketType?: KeyValueOption;
  mentionedTickets?: KeyValueOption[];
  customTicketFields: TableCustomField[];
  isDeleted?: boolean;
  source?: SourceTicketType;
};

export enum TicketSLAType {
  DECISION = 'DECISION',
  REACTION = 'REACTION',
}

export enum TableTicketsTypes {
  FULL = 'FULL',
  BY_CONTRACT_IDS = 'BY_CONTRACT_IDS',
  FROM_DESKTOP = 'FROM_DESKTOP',
  FROM_DESKTOP_HISTORY = 'FROM_DESKTOP_HISTORY',
}

export enum SourceTicketType {
  SUP_PORTAL = 'SUP_PORTAL',
  JIRA = 'JIRA',
  TELEGRAM = 'TELEGRAM',
  MAIL = 'MAIL',
  EXTERNAL_INTEGRATION = 'EXTERNAL_INTEGRATION',
}

export enum CommentType {
  CLOSE_REQUEST = 'CLOSE_REQUEST',
  INFO_REQUEST = 'INFO_REQUEST',
  RETURN_TO_WORK = 'RETURN_TO_WORK',
  SOLUTION = 'SOLUTION',
  USUAL = 'USUAL',
  CHANGE_STATUS = 'CHANGE_STATUS',
  REOPEN = 'REOPEN',
}

export type CommentFile = KeyValueOption;

export type CommentSpoiler = {
  header?: string;
  text?: string;
  titleSpoiler?: string;
};

export type Comment = {
  id?: string;
  isClient?: boolean;
  userDto?: User;
  ticketId: string;
  message?: string;
  created?: string;
  updated?: string;
  type: CommentType;
  pathsToFiles?: CommentFile[];
  cancelText?: string;
  solutionAccepted?: boolean;
  spoiler?: CommentSpoiler;
  requestCommentId?: string;
  requestCommentText?: string;
  solutionAcceptedBySystem?: boolean;
  nextStatus?: Status;
  mentionedTickets?: KeyValueOption[];
  files?: AttachmentsFiles[];
};

export interface MessageData {
  commentType: CommentType;
  message?: string;
  ticketsIds?: ValueType<string>;
}
export interface AddSpecAndSetToWorkPayload {
  ticketId: string[];
  specialistId?: string;
  withTicketsUpdate?: boolean;
}

export interface AddSpecAndSetToWorkPayloadTickets
  extends AddSpecAndSetToWorkPayload {
  isTicketNew?: boolean;
}

export enum TicketsFilterDesktop {
  number = 'number',
  sla = 'sla',
  specialistId = 'specialistId',
  clientId = 'clientId',
  theme = 'theme',
}
