import cn from 'classnames';
import { type FormEventHandler, type HTMLAttributes, memo } from 'react';
import { NavLink } from 'react-router-dom';

import { createBemClass } from '../../../helpers/createBemClass';
import { type Tab, type TabSize } from '../../types';

import styles from './TabsItem.module.scss';

const rootClassName = createBemClass('tabs-item');

export interface TabsItemProps<T extends string>
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onClick'> {
  onClick?: (activeKey: T) => void;
  tab: Tab;
  disabled?: boolean;
  fontSize: TabSize;
  isActive?: boolean;
}

const TabsItemDefault = <T extends string>({
  tab,
  className: classNameTab,
  disabled,
  fontSize,
  onClick,
  isActive,
  ...other
}: TabsItemProps<T>) => {
  const { label, className, to, key } = tab;

  const tabIndex = isActive ?? disabled ? -1 : 0;

  const handleClick: FormEventHandler<HTMLDivElement> = (e) => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    if (onClick && key) {
      onClick(key as T);
    }
  };

  if (to) {
    return (
      <NavLink
        className={cn(
          styles[rootClassName()],
          styles[rootClassName({ modName: String(fontSize) })],
          {
            [styles[rootClassName({ modName: 'disabled' })]]: disabled,
          },
          className,
          classNameTab
        )}
        // end={end}
        to={to}
      >
        {label}
      </NavLink>
    );
  }

  return (
    <div
      {...other}
      className={cn(
        styles[rootClassName()],
        styles[rootClassName({ modName: String(fontSize) })],
        {
          active: isActive,
          [styles[rootClassName({ modName: 'disabled' })]]: disabled,
        },
        className,
        classNameTab
      )}
      onClick={handleClick}
      onKeyDown={() => {}}
      role="tab"
      tabIndex={tabIndex}
    >
      {label}
    </div>
  );
};

export const TabsItem = memo(TabsItemDefault) as typeof TabsItemDefault;
