import { Organization } from '@entities/organization';
import { WorkGroup } from '@entities/work-group';

import { Article, ArticleCreateData, CategoryArticle } from '../types';

import {
  ArticleCreate,
  CreateArticleRequestArticleCreateAction,
  DeleteArticleRequestArticleCreateAction,
  EditArticleRequestArticleCreateAction,
} from './actionTypes';
import { FetchWorkGroupPayload } from './types';

export const createArticleRequestArticleCreate = (
  data: ArticleCreateData
): CreateArticleRequestArticleCreateAction => ({
  type: ArticleCreate.CREATE_ARTICLE_REQUEST_ARTICLE_CREATE,
  payload: data,
});

export const editArticleRequestArticleCreate = (
  data: ArticleCreateData
): EditArticleRequestArticleCreateAction => ({
  type: ArticleCreate.EDIT_ARTICLE_REQUEST_ARTICLE_CREATE,
  payload: data,
});

export const deleteArticleRequestArticleCreate = (
  id: string
): DeleteArticleRequestArticleCreateAction => ({
  type: ArticleCreate.DELETE_ARTICLE_REQUEST_ARTICLE_CREATE,
  payload: id,
});

export const createCategoryRequestArticleCreate = (
  data: Partial<CategoryArticle>
) => ({
  type: ArticleCreate.CREATE_CATEGORY_REQUEST_ARTICLE_CREATE,
  payload: data,
});

export const fetchOrganizationsRequestArticleCreate = () => ({
  type: ArticleCreate.FETCH_ORGANIZATIONS_REQUEST_ARTICLE_CREATE,
});

export const fetchOrganizationsSuccessArticleCreate = (
  data: Organization[]
) => ({
  type: ArticleCreate.FETCH_ORGANIZATIONS_SUCCESS_ARTICLE_CREATE,
  payload: data,
});

export const fetchWorkGroupsRequestArticleCreate = (
  payload: FetchWorkGroupPayload
) => ({
  type: ArticleCreate.FETCH_WORK_GROUPS_REQUEST_ARTICLE_CREATE,
  payload,
});

export const fetchWorkGroupsSuccessArticleCreate = (payload: WorkGroup[]) => ({
  type: ArticleCreate.FETCH_WORK_GROUPS_SUCCESS_ARTICLE_CREATE,
  payload,
});

export const fetchAllCategoriesRequestArticleCreate = () => ({
  type: ArticleCreate.FETCH_ALL_CATEGORIES_REQUEST_ARTICLE_CREATE,
});

export const fetchCategoriesRequestArticleCreate = (ids: string[]) => ({
  type: ArticleCreate.FETCH_CATEGORIES_REQUEST_ARTICLE_CREATE,
  payload: ids,
});

export const fetchCategoriesSuccessArticleCreate = (
  data: CategoryArticle[]
) => ({
  type: ArticleCreate.FETCH_CATEGORIES_SUCCESS_ARTICLE_CREATE,
  payload: data,
});

export const fetchSubCategoriesRequestArticleCreate = (id: string) => ({
  type: ArticleCreate.FETCH_SUB_CATEGORIES_REQUEST_ARTICLE_CREATE,
  payload: id,
});

export const fetchSubCategoriesSuccessArticleCreate = (
  data: CategoryArticle[]
) => ({
  type: ArticleCreate.FETCH_SUB_CATEGORIES_SUCCESS_ARTICLE_CREATE,
  payload: data,
});

export const fetchCurrentArticleRequestArticleCreate = (id: string) => ({
  type: ArticleCreate.FETCH_CURRENT_ARTICLE_REQUEST_ARTICLE_CREATE,
  payload: id,
});

export const fetchCurrentArticleSuccessArticleCreate = (data?: Article) => ({
  type: ArticleCreate.FETCH_CURRENT_ARTICLE_SUCCESS_ARTICLE_CREATE,
  payload: data,
});

export const currentArticleLoadingShowArticleCreate = () => ({
  type: ArticleCreate.CURRENT_ARTICLE_LOADING_SHOW_ARTICLE_CREATE,
});

export const currentArticleLoadingHideArticleCreate = () => ({
  type: ArticleCreate.CURRENT_ARTICLE_LOADING_HIDE_ARTICLE_CREATE,
});

export const resetArticleCreateState = () => ({
  type: ArticleCreate.RESET_ARTICLE_CREATE_STATE,
});
