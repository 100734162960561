import {
  FilterRulesValue,
  JiraSynchronization,
} from '@entities/jira-integrations';

export const getSyncFilterRules = (jiraFilterRules?: JiraSynchronization) => {
  const {
    autoCreateTasks = false,
    considerFilters = false,
    filterRules = [],
  } = jiraFilterRules || {};

  const result: FilterRulesValue = {
    TICKET_TYPE: [],
    TICKET_PRIORITY: [],
    TICKET_ENVIRONMENT: [],
    TICKET_STATUS: [],
    TICKET_SPECIALIST: [],
    TICKET_CLIENT: [],
  };

  filterRules.forEach(({ type, value, entityId }) => {
    if (result[type]) {
      result[type].push({
        title: value,
        value: entityId,
      });
    }
  });

  return { autoCreateTasks, considerFilters, filterRules: result };
};
