import { isBefore, isEqual, parseISO } from 'date-fns';

import { Contract, ProgressStatus, SupportType } from '@entities/contract';
import { getReverseDateFromCustomField } from '@entities/ticket/lib';

type Args = {
  contract?: Contract;
  prolongationContractStatus?: ProgressStatus;
  supportType?: SupportType;
  value: string;
};

const checkDate = (value: string, openDate: string) => {
  const newDate = parseISO(
    getReverseDateFromCustomField(value.replaceAll('.', '-'))
  );
  const oldDate = parseISO(openDate);

  return isBefore(newDate, oldDate) || isEqual(newDate, oldDate);
};

export const validateDateForOpenDate = ({
  contract,
  prolongationContractStatus,
  supportType,
  value,
}: Args) => {
  const bothExtended =
    supportType === SupportType.EXTENDED &&
    contract?.supportType === SupportType.EXTENDED;

  const bothLicensed =
    supportType === SupportType.LICENSED &&
    contract?.supportType === SupportType.LICENSED;

  const intoLicensed =
    supportType === SupportType.LICENSED &&
    contract?.supportType === SupportType.EXTENDED;

  if (
    contract &&
    prolongationContractStatus === ProgressStatus.IN_PROGRESS &&
    (bothExtended || bothLicensed || intoLicensed)
  ) {
    const error = checkDate(value, contract.endDate);
    return error ? 'Пересечение дат со старым контрактом' : true;
  }
  return true;
};
