import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

import { Environment } from '@entities/environment';
import { SystemPriority } from '@entities/system';
import { Priority, TicketType } from '@entities/ticket/model/types';
import { getArraySelectOptions, SelectOption } from '@shared';

import { mapJiraSyncFilter } from '../lib/utils';

import {
  Field,
  FieldType,
  JiraPriority,
  JiraState,
  JiraStatus,
  JiraSynchronizationFilter,
  JiraTicketType,
  Status,
} from './types';

export const getJiraIntegrations = (state: JiraState) =>
  state.jira.jiraIntegrations;
export const getLoadingJiraIntegrations = (state: JiraState) =>
  state.jira.loadingIntegrations;
export const getJiraIntegrationsFilter = (state: JiraState) =>
  state.jira.filter || {};
export const getPropsJiraIntegrations = (state: JiraState) => ({
  pageNum: state.jira.pagination?.pageNum,
  pageSize: state.jira.pagination?.pageSize,
  sort: state.jira.sort,
  totalElements: state.jira.pagination?.totalElements,
  loading: state.jira.loadingIntegrations,
});
export const getJiraStatusesForCreate = (state: JiraState) =>
  state.jira.jiraStatusesForCreate || [];
export const getJiraPrioritiesForCreate = (state: JiraState) =>
  state.jira.jiraPrioritiesForCreate;
export const getJiraPrioritiesForEdit = (state: JiraState) =>
  state.jira.jiraPrioritiesForEdit;
export const getCurrentJiraIntegrationId = (state: JiraState) =>
  state.jira.jiraIntegrationId;
export const getCurrentSystemId = (state: JiraState) => state.jira.systemId;
export const getLoadingStatuses = (state: JiraState) =>
  state.jira.loadingStatuses;
export const getLoadingPriorities = (state: JiraState) =>
  state.jira.loadingPriorities;
export const getJiraStatusesBySystemId = (state: JiraState) =>
  state.jira.jiraStatusesBySystemId || [];
export const getJiraPrioritiesForCurrent = (state: JiraState) =>
  state.jira.jiraPrioritiesForCurrent;
export const getJiraIntegration = (state: JiraState) =>
  state.jira.jiraIntegration;
export const getSpFields = (state: JiraState) => state.jira.spFields;
export const getJiraFields = (state: JiraState) => state.jira.jiraFields;
export const getLoadingJiraIntegrationInfo = (state: JiraState) =>
  state.jira.loadingJiraIntegrationInfo;
export const getFieldsIntegrations = (state: JiraState) =>
  state.jira.fieldsIntegrations;
export const getSystemPriorities = (state: JiraState) =>
  state.jira.systemPriorities ?? [];
export const getSystemPrioritiesForCompare = (state: JiraState) =>
  state.jira.systemPrioritiesForCompare || {};
export const getSpStatuses = (state: JiraState) => state.jira.spStatuses;
export const getSpStatusesForCompare = (state: JiraState) =>
  state.jira.spStatusesForCompare || {};
export const getSpStatusesForCurrent = (state: JiraState) =>
  state.jira.spStatusesForCurrent || [];
export const getTicketTypes = (state: JiraState) => state.jira.ticketTypes;
export const getJiraTicketTypes = (state: JiraState) =>
  state.jira.jiraTicketTypes || [];
export const getJiraTicketTypesForCurrent = (state: JiraState) =>
  state.jira.jiraTicketTypesForCurrent;
export const getJiraTicketTypeByIntegrationId = (state: JiraState) =>
  state.jira.jiraTicketTypeByIntegrationId || [];
export const getJiraStatusesByPropertyId = (state: JiraState) =>
  state.jira.jiraStatusesByPropertyId || [];
export const getIntegratedTicketTypesIds = (state: JiraState) =>
  state.jira.integratedTicketTypesIds || [];
export const getTypeId = (state: JiraState) => state.jira.typeId;
export const getSynchronization = (state: JiraState) =>
  state.jira.synchronization;
export const getSyncTicketTypes = (state: JiraState) =>
  state.jira.synchronization?.ticketTypes;
export const getSyncPriorities = (state: JiraState) =>
  state.jira.synchronization?.systemPriorities;
export const getSyncEnvironments = (state: JiraState) =>
  state.jira.synchronization?.environments;
export const getSyncStatuses = (state: JiraState) =>
  state.jira.synchronization?.statuses;
export const getSyncSpecialists = (state: JiraState) =>
  state.jira.synchronization?.specialists;
export const getSyncClients = (state: JiraState) =>
  state.jira.synchronization?.clients;

export const getSpFieldsSelectList = createSelector<
  JiraState,
  Field[] | undefined,
  SelectOption[]
>([getSpFields], (spFields): SelectOption[] => {
  if (spFields) {
    return spFields?.map(
      (spField): SelectOption => ({
        title: spField.title,
        value: spField.value,
      })
    );
  }
  return [];
});

export const getJiraFieldsSelectList = (fieldType?: FieldType) =>
  createSelector<JiraState, Field[] | undefined, SelectOption[]>(
    [getJiraFields],
    (jiraFields): SelectOption[] => {
      if (jiraFields && fieldType) {
        return jiraFields
          ?.filter((jiraField) => jiraField.fieldType === fieldType)
          .map(
            (jiraField): SelectOption => ({
              title: jiraField.title,
              value: jiraField.value,
            })
          );
      }
      return [];
    }
  );

export const getCreateTicketTypesOptions = createSelector<
  JiraState,
  TicketType[] | undefined,
  SelectOption[]
>(
  [getTicketTypes],
  (ticketTypes): SelectOption[] =>
    ticketTypes?.map(({ title, id }) => ({
      title,
      value: id,
    })) ?? []
);

export const getIntegratedTicketTypesOptions = createSelector<
  JiraState,
  SelectOption[],
  string[],
  SelectOption[]
>(
  [getCreateTicketTypesOptions, getIntegratedTicketTypesIds],
  (ticketTypesOptions, jiraTicketType): SelectOption[] =>
    jiraTicketType
      .map((id) => ticketTypesOptions?.filter(({ value }) => value === id))
      .flat()
);

export const getJiraTicketTypesOptions = createSelector<
  JiraState,
  JiraTicketType[] | undefined,
  SelectOption[]
>(
  [getJiraTicketTypes],
  (ticketTypes): SelectOption[] =>
    ticketTypes?.map(({ name, idType }) => ({
      title: name,
      value: idType.toString(),
    })) ?? []
);

export const getJiraPrioritiesOptions = (jiraPriorities?: JiraPriority[]) =>
  sortBy(jiraPriorities, (item) => item.idPriority).map(
    (jiraPriority): SelectOption<number> => ({
      title: jiraPriority.name || '',
      value: jiraPriority.idPriority || 0,
    })
  ) || [];

export const getJiraStatusesOptions = (jiraStatuses?: JiraStatus[]) =>
  jiraStatuses?.map(
    (jiraStatus): SelectOption<number> => ({
      title: jiraStatus.name || '',
      value: jiraStatus.idStatus || 0,
    })
  ) || [];

export const getJiraPrioritiesForCurrentOptions = createSelector<
  JiraState,
  JiraPriority[] | undefined,
  SelectOption<number>[]
>([getJiraPrioritiesForCurrent], (jiraPriorities) =>
  Object.values(Priority).map((priority) => {
    const findJiraPriority = jiraPriorities?.find((item) =>
      item.ticketPriorities?.includes(priority)
    );

    return {
      title: findJiraPriority?.name || '',
      value: findJiraPriority?.idPriority || 0,
    };
  })
);

export const getSyncTicketTypesOptions = createSelector<
  JiraState,
  TicketType[] | undefined,
  SelectOption[]
>([getSyncTicketTypes], (ticketTypes) => getArraySelectOptions(ticketTypes));

export const getSyncPrioritiesOptions = createSelector<
  JiraState,
  SystemPriority[] | undefined,
  SelectOption[]
>([getSyncPriorities], (priorities) => getArraySelectOptions(priorities));

export const getSyncEnvironmentsOptions = createSelector<
  JiraState,
  Environment[] | undefined,
  SelectOption[]
>([getSyncEnvironments], (environments) => getArraySelectOptions(environments));

export const getEditSyncStatusesOptions = createSelector<
  JiraState,
  JiraSynchronizationFilter[] | undefined,
  SelectOption[]
>([getSyncStatuses], (statuses) => mapJiraSyncFilter(statuses));

export const getSyncStatusesOptions = createSelector<
  JiraState,
  Status[] | undefined,
  SelectOption[]
>(
  [getSpStatuses],
  (statuses) =>
    statuses?.map(({ name, id }) => ({
      title: name,
      value: id,
    })) ?? []
);

export const getSyncSpecialistsOptions = createSelector<
  JiraState,
  JiraSynchronizationFilter[] | undefined,
  SelectOption[]
>([getSyncSpecialists], (specialists) => mapJiraSyncFilter(specialists));

export const getSyncClientsOptions = createSelector<
  JiraState,
  JiraSynchronizationFilter[] | undefined,
  SelectOption[]
>([getSyncClients], (clients) => mapJiraSyncFilter(clients));
