import { Organization } from '@entities/organization';
import { WorkGroup } from '@entities/work-group';
import { ApiClient, getEnv } from '@shared';

import { FetchWorkGroupPayload } from '../../model/kb-create-article/types';
import { CategoryArticle } from '../../model/types';

import { endpoints } from './endpoints';

export const requests = {
  organizationsArticleRequest: (filter?: { organizationTitle: string }) =>
    ApiClient.post<Organization[]>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getOrganizationsForArticleCreate(),
      data: filter,
    }),
  fetchWorkGroups: (data: FetchWorkGroupPayload) =>
    ApiClient.post<WorkGroup[]>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getWorkGroupsForArticleCreate(),
      data,
    }),
  categoriesArticleRequest: (data: string[]) =>
    ApiClient.post<CategoryArticle[]>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getCategoriesByOrganizationIds(),
      data,
    }),
  fetchAllCategories: () =>
    ApiClient.get<CategoryArticle[]>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getAllCategories(),
    }),
};
