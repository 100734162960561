import { isNil } from 'lodash';

import { SelectOption, ValueType } from '@shared/ui';

export const getIsValidateFieldValue = {
  validate: (value: ValueType<string>) => !isNil(value),
};

export const getIsValidateMultiArray = {
  validate: (value: SelectOption[]) => Array.isArray(value) && !!value.length,
};
