import { EnvironmentsWithStatus } from '@entities/environment/model/types';
import { OrganizationShort } from '@entities/organization/model/types';
import { SLAType } from '@entities/sla/model/types';
import { System } from '@entities/system/model';
import { User } from '@entities/user/model/types';
import { WorkGroup } from '@entities/work-group/model';
import { KeyValueOption, ValueType } from '@shared';

import { ContractDetailReducerState } from './slice';

export type ContractDetailState = {
  contractDetail: ContractDetailReducerState;
};

export enum SupportType {
  LICENSED = 'LICENSED',
  EXTENDED = 'EXTENDED',
}

export enum ContractType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  SUPPLEMENTARY_AGREEMENT = 'SUPPLEMENTARY_AGREEMENT',
}

export enum ContractStatus {
  ACTIVE = 'ACTIVE',
  CLOSED = 'HIGH',
  PLANNED = 'LOW',
  NOT_SIGNED = 'NOT_SIGNED',
}

export type ContractSystem = {
  contractId: string;
  systemId?: string;
  slaId?: string;
  id?: string;
};

export type ContractNotificationConfig = {
  contractExpireIsEnable?: boolean;
  notifyBeforeExpire?: number;
};

export type ContractPermission = {
  storageAccessible?: boolean;
};

export type Contract = {
  id: string;
  title: string;
  description?: string;
  type: ContractType;
  status: ContractStatus;
  number?: string;
  openDate: string;
  endDate: string;
  organizationId?: string;
  organization?: OrganizationShort;
  organizationTitle?: string;
  partnerOrganization?: KeyValueOption;
  systemList?: System[];
  orgType?: string;
  orgDomain?: string;
  orgDescription?: string;
  orgAddress?: string;
  userList?: User[];
  workGroupList?: WorkGroup[];
  dateCreate?: string;
  systemTitle?: string;
  contractNotificationConfig?: ContractNotificationConfig;
  contractPermission?: ContractPermission;
  mainContractId?: string;
  supportType?: SupportType;
};

export enum TableContractsTypes {
  FULL = 'FULL',
  FROM_ORGANIZATIONS = 'FROM_ORGANIZATIONS',
  FROM_SYSTEMS = 'FROM_SYSTEMS',
}

export type ContractDetailPayload = {
  contractId?: string;
  systemId?: string;
  name?: string;
};

export interface CreateContractData
  extends Pick<
    Contract,
    | 'id'
    | 'title'
    | 'number'
    | 'description'
    | 'openDate'
    | 'endDate'
    | 'status'
    | 'supportType'
  > {
  organizationId?: ValueType<string>;
  type?: ValueType<ContractType>;
  partnerOrganizationId?: ValueType<string>;
  notificationConfig: ContractNotificationConfig;
}

export type ContractIdSystemIdArgs = {
  contractId: string;
  systemId: string;
};

export enum ValidDateType {
  NO_DATA = 'NO_DATA',
  BEYOND_CONTRACT_START_DATE = 'VALID_BEYOND_CONTRACT_START_DATE',
  BEYOND_CONTRACT_END_DATE = 'VALID_BEYOND_CONTRACT_END_DATE',
  END_AFTER_START_DATE = 'END_AFTER_START_DATE',
}

export const validSupplementaryAgreementMap = {
  [ValidDateType.NO_DATA]: '',
  [ValidDateType.BEYOND_CONTRACT_START_DATE]:
    'Выходит за рамки сроков контракта',
  [ValidDateType.BEYOND_CONTRACT_END_DATE]: 'Выходит за рамки сроков контракта',
  [ValidDateType.END_AFTER_START_DATE]:
    'Дата окончания не может быть раньше даты начала',
};

export interface SystemsByContractIdPayload {
  id?: string;
  withDeletedSystems?: boolean;
}

export enum SLAChoiceType {
  CREATE_NEW = 'CREATE_NEW',
  SELECT_FROM_LIST = 'SELECT_FROM_LIST',
}

export type SLASettingsPriority = {
  name: string;
  checked: boolean;
};

export type UpdateEnvironmentSettings = EnvironmentsWithStatus &
  ContractIdSystemIdArgs;

export interface CreateSystemDataAdd
  extends Pick<
    SLAType,
    | 'title'
    | 'workingHoursFrom'
    | 'workingHoursTo'
    | 'autoClosableTickets'
    | 'closeAfterHours'
    | 'expireReactionTimeInDecisionTime'
  > {
  systemId?: string;
  createType?: SLAChoiceType;
}

export type SystemsTransfer = {
  contractId?: string;
  transferStatus?: ProgressStatus;
  systems?: System[];
};

export type ProlongationContractPayload = {
  contractId: string;
  contract: Contract;
};

export enum ProgressStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export type AttachSystemAndSLAToContractType = ContractDetailPayload & {
  sla: SLAType;
};

export type UpdateContractPermissionsPayload = {
  contractId: string;
  isEnable?: boolean;
};

export type UpdateContractNotificationsPayload =
  UpdateContractPermissionsPayload & {
    notifyBefore?: number;
  };

export type SetContractPermissionPayload = Pick<
  UpdateContractPermissionsPayload,
  'isEnable'
>;

export interface CreateContractPayload {
  contract: Contract;
  systemId?: string;
}

export interface CreateSupplementaryAgreementPayload {
  contractId: string;
  systemId: string;
  data?: Contract;
}

export interface EditContractPayload {
  contract: Contract;
  systemId?: string;
}

export type SLAPatternPayload = Pick<
  SLAType,
  | 'title'
  | 'workingHoursFrom'
  | 'workingHoursTo'
  | 'autoClosableTickets'
  | 'closeAfterHours'
  | 'expireReactionTimeInDecisionTime'
  | 'settings'
> & { systemId: string };
