import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  DesktopState,
  EmployeesFormData,
  fetchEmployeesStatisticRequest,
  fetchOrganizationsWorkGroupRequest,
  fetchSlaStatisticRequestEmployees,
  fetchSystemsRequest,
  fetchTicketsStatisticRequestEmployees,
  getTransformedTypeList,
  resetDesktopState,
  resetEmployeesState,
  setDesktopFilters,
} from '@entities/desktop';
import { resetTicketsSearch } from '@entities/ticket';
import { INITIAL_EMPLOYEES_FORM_VALUES } from '@features/desktop/config';
import {
  convertFromDateFormat,
  convertToDateFormat,
  getFormatPartialDate,
  getMultiOptionValues,
  getValueFromValueType,
} from '@shared';

export const useEmployeesForm = () => {
  const dispatch = useDispatch();

  const { control, watch, setValue, reset } = useForm<EmployeesFormData>({
    mode: 'onChange',
    defaultValues: INITIAL_EMPLOYEES_FORM_VALUES,
  });

  const { date, workGroupId, organizationId, systemId, ticketTypesIds } =
    watch();

  const ticketTypesOptions = useSelector((state: DesktopState) =>
    getTransformedTypeList(state, systemId ?? [])
  );

  const isDeafultWorkGroupId =
    workGroupId === INITIAL_EMPLOYEES_FORM_VALUES.workGroupId;

  const isCanFetchOrganizationsWorkId =
    !!workGroupId && !Array.isArray(workGroupId);

  const isCanFetchSystemsOrganizationsId =
    !!organizationId && !Array.isArray(organizationId);

  const isDisabledSystemEmployees = !organizationId;

  const resetEmployeesAllState = () => {
    dispatch(resetEmployeesState());
    dispatch(resetDesktopState());
    dispatch(resetTicketsSearch());
  };

  const allEmployeesFieldReset = () => {
    resetEmployeesAllState();
    reset();
  };

  useEffect(() => {
    if (isCanFetchOrganizationsWorkId) {
      dispatch(fetchOrganizationsWorkGroupRequest(workGroupId.value));
    }
    if (isDeafultWorkGroupId) {
      resetEmployeesAllState();
    }
  }, [isCanFetchOrganizationsWorkId, isDeafultWorkGroupId, workGroupId]);

  useEffect(() => {
    if (isCanFetchSystemsOrganizationsId) {
      dispatch(fetchSystemsRequest(organizationId?.value));
    }
  }, [isCanFetchSystemsOrganizationsId]);

  useEffect(() => {
    if (workGroupId) {
      dispatch(
        setDesktopFilters({
          workgroupId: getValueFromValueType(workGroupId),
          to: getFormatPartialDate(convertToDateFormat(date)),
          from: getFormatPartialDate(convertFromDateFormat(date)),
          onlyActualTickets: false,
          organizationId: getValueFromValueType(organizationId),
          systemId: getValueFromValueType(systemId),
          ticketTypeIds: getMultiOptionValues(ticketTypesIds),
        })
      );
      dispatch(fetchEmployeesStatisticRequest());
      dispatch(fetchSlaStatisticRequestEmployees());
      dispatch(fetchTicketsStatisticRequestEmployees());
    }
  }, [workGroupId, date, organizationId, systemId, ticketTypesIds]);

  return {
    state: {
      ticketTypesEmployeesOptions: ticketTypesOptions,
      isDisabledSystemEmployees,
    },
    methods: {
      employeesControl: control,
      setEmpoyeesValue: setValue,
      employeesReset: allEmployeesFieldReset,
    },
  };
};
