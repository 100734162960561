import { CustomConditions, CustomField, Trigger } from '@entities/triggers';

export const getCustomConditions = (
  trigger: Trigger,
  customFields: CustomField[]
): CustomConditions[] =>
  trigger.customConditions.map((condition) => {
    const { customField } = condition;
    const { key } = customField;

    const currentCustomField = customFields.find((field) => field.id === key);

    if (currentCustomField) {
      const { values } = currentCustomField;

      return {
        ...condition,
        customField: {
          ...condition.customField,
          values,
        },
      };
    }

    return condition;
  });
