import { FC } from 'react';

import {
  CloseIcon,
  IconButtonWrapper,
  Typography,
  TypographyVariants,
} from '@shared';

import styles from './StatusSideMenu.module.scss';

export interface StatusSideMenuProps {
  title: string;
  onCloseModal: () => void;
}

export const StatusSideMenu: FC<StatusSideMenuProps> = ({
  title,
  children,
  onCloseModal,
}) => (
  <div className={styles.statusSideMenu}>
    <div className={styles.statusSideMenu__header}>
      <Typography variant={TypographyVariants.h3}>{title}</Typography>
      <IconButtonWrapper
        icon={<CloseIcon className={styles.statusSideMenu__headerButtonIcon} />}
        onClick={onCloseModal}
        className={styles.statusSideMenu__headerButton}
      />
    </div>
    {children}
  </div>
);
