import cn from 'clsx';
import { FC, memo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  getIsClient,
  getUserEmail,
  getUserFirstName,
  getUserFullName,
  getUserLastName,
  getUserMiddleName,
  getUserOrganizationTitle,
  getUserPhoneNumber,
} from '@entities/auth';
import {
  Avatar,
  AvatarColor,
  InfoBlock,
  MailIcon,
  PhoneIcon,
  Size,
  Tag,
  Typography,
  TypographyVariants,
} from '@shared';

import { ProfileUserTabs } from '../ProfileUserTabs';

import styles from './ProfileUser.module.scss';

interface ProfileUserProps {
  className?: string;
}

export const ProfileUser: FC<ProfileUserProps> = memo(({ className }) => {
  const isClient = useSelector(getIsClient);
  const userFullName = useSelector(getUserFullName);
  const userFirstName = useSelector(getUserFirstName);
  const userLastName = useSelector(getUserLastName);
  const userMiddleName = useSelector(getUserMiddleName);
  const userEmail = useSelector(getUserEmail);
  const userPhoneNumber = useSelector(getUserPhoneNumber);
  const userOrganizationTitle = useSelector(getUserOrganizationTitle);

  const [openInfo, setOpenInfo] = useState(false);

  const handleOpenInfo = () => {
    setOpenInfo((prev) => !prev);
  };

  return (
    <div className={styles.profileUser__helper}>
      <div className={cn(styles.profileUser, className)}>
        <div className={styles.profileUser__topWrapper}>
          <Avatar
            size={Size.l}
            color={isClient ? AvatarColor.purple : AvatarColor.cyan}
            initialsUser={userFullName}
            className={styles.profileUser__initials}
          />
          <div className={styles.profileUser__fullName}>
            <Typography
              variant={TypographyVariants.h3}
              className={styles.profileUser__fullNameItem}
            >
              {`${userLastName} ${userFirstName} ${userMiddleName || ''}`}
            </Typography>
            <button
              className={cn(styles.profileUser__row, {
                [styles.profileUser__rowUp]: openInfo,
              })}
              onClick={handleOpenInfo}
            />
          </div>
          <Tag
            text={userOrganizationTitle}
            className={cn(styles.profileUser__organization, {
              [styles.profileUser__organization_client]: isClient,
            })}
          />
          <div
            className={cn(styles.profileUser__systemInfo, {
              [styles.profileUser__systemInfo_open]: openInfo,
            })}
          >
            <div className={styles.profileUser__systemInfoItem}>
              <InfoBlock
                icon={<MailIcon className={styles.profileUser__iconInner} />}
                mainTitle="Почта"
                info={userEmail}
                classNameInfo={styles.profileUser__infoTitle}
                classNameIconWrapper={styles.profileUser__iconWrapper}
              />
            </div>
            <div className={styles.profileUser__systemInfoItem}>
              <InfoBlock
                icon={<PhoneIcon className={styles.profileUser__iconInner} />}
                mainTitle="Телефон"
                info={userPhoneNumber}
                classNameInfo={styles.profileUser__infoTitle}
                classNameIconWrapper={styles.profileUser__iconWrapper}
              />
            </div>
          </div>
        </div>
        <ProfileUserTabs />
      </div>
    </div>
  );
});
