import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  copyStatusModel,
  fetchTicketTypesForSystemRequest,
  fetchTicketTypesForSystemsRequest,
  getTicketTypesTo,
  StatusCopyModelForm,
} from '@entities/statuses-builder';
import { getMultiOptionValues } from '@shared';

import { DEFAULT_VALUES_COPY_STATUS_MODEL_FORM } from '../../config';
import { getPreparedDataCopyStatusPayload } from '../utils';

interface UseCopyStatusModelProps {
  onClose(): void;
}

export const useCopyStatusModel = ({ onClose }: UseCopyStatusModelProps) => {
  const dispatch = useDispatch();
  const ticketTypesTo = useSelector(getTicketTypesTo);

  const {
    control,
    watch,
    handleSubmit,
    resetField,
    reset,
    formState: { isDirty, isValid },
  } = useForm<StatusCopyModelForm>({
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES_COPY_STATUS_MODEL_FORM,
  });

  const { systemId, typeId, systemIds, typeIds } = watch();

  const onCloseModalHandler = useCallback(() => {
    onClose();
  }, [onClose]);

  const formSubmitHandler = handleSubmit((data) => {
    if (ticketTypesTo) {
      dispatch(
        copyStatusModel(getPreparedDataCopyStatusPayload(data, ticketTypesTo))
      );
    }
    reset();
    onClose();
  });

  const isSubmitDisabled = !isDirty || !isValid;

  const isDisabledTypeId = !systemId;
  const isDisabledSystemIds = !typeId;
  const isDisabledTypeIds = !systemIds.length;

  const isResetCopyTo =
    systemIds.length === 1 &&
    systemIds[0].value === systemId?.value &&
    typeIds.length === 1 &&
    typeIds[0].value === typeId?.value;

  useEffect(() => {
    if (systemId) {
      dispatch(fetchTicketTypesForSystemRequest(systemId.value));
    }
  }, [systemId]);

  useEffect(() => {
    if (systemIds.length) {
      dispatch(
        fetchTicketTypesForSystemsRequest(getMultiOptionValues(systemIds) ?? [])
      );
    }
  }, [systemIds]);

  useEffect(() => {
    if (isResetCopyTo) {
      resetField('systemIds');
      resetField('typeIds');
    }
  }, [isResetCopyTo]);

  return {
    methods: {
      control,
      formSubmitHandler,
      onCloseModalHandler,
      resetField,
    },
    state: {
      isSubmitDisabled,
      isDisabledTypeId,
      isDisabledTypeIds,
      isDisabledSystemIds,
    },
  };
};
