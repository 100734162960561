import { Selector } from 'react-redux';
import { createSelector } from 'reselect';

import { Action } from '@entities/actions/model/types';
import { ActionList } from '@entities/auth/model/types';
import { CustomFieldToRequest, CustomFieldType } from '@entities/custom-fields';
import { getReverseDateFromCustomField } from '@entities/ticket/lib/utils';
import { createSelectOption } from '@shared';

import { CustomFieldTicket, TicketState } from './types';

export const getLoadingTicketDetail = (state: TicketState) =>
  state?.ticket.loading;
export const getTicketHistoryLoading = (state: TicketState) =>
  state?.ticket.historyLoading;
export const getTicket = (state: TicketState) => state?.ticket.ticket;
export const getTicketSystem = (state: TicketState) =>
  state?.ticket.ticketSystem;
export const getTicketWorkGroups = (state: TicketState) =>
  state.ticket.ticket?.workGroups;
export const getSystemId = (state: TicketState) =>
  state.ticket.ticket?.systemId;
export const getHistory = (state: TicketState) => state?.ticket.history;
export const getIsError = (state: TicketState) => state.ticket.isError;
export const getIsSuccess = (state: TicketState) => state.ticket.isSuccess;
export const getTicketActionsListTicketDetail = (state: TicketState) =>
  state.ticket.ticketActionsList;
export const getIsSystemIntegratedWithJira = (state: TicketState) =>
  state.ticket.isSystemIntegratedWithJira;
export const getJiraUrl = (state: TicketState) => state.ticket.jiraUrl;
export const getSpecialistTicketCustomFields = (state: TicketState) =>
  state.ticket.specialistCustomFields || [];
export const getClientTicketCustomFields = (state: TicketState) =>
  state.ticket.clientCustomFields || [];
export const getNextStatuses = (state: TicketState) =>
  state.ticket.nextStatuses;
export const getTicketDeleted = (state: TicketState) =>
  state.ticket.ticket?.isDeleted;
export const getClientInfoOrgTypeFromTicket = (state: TicketState) =>
  state.ticket.ticket?.clientInfo?.organizationType;
export const getClientInfo = (state: TicketState) =>
  state.ticket.ticket?.clientInfo;
export const getTicketTypesTicketDetail = (state: TicketState) =>
  state.ticket.ticketTypes || [];
export const getStatusesTicketDetail = (state: TicketState) =>
  state.ticket.statuses;
export const getToastMessage = (state: TicketState) =>
  state.ticket.toastMessage;
export const getCustomFieldsTicket = (state: TicketState) =>
  state.ticket.customFields ?? [];

export const getIsAccessedTicketDetailAction = (action: ActionList) =>
  createSelector<TicketState, Action[] | undefined, boolean>(
    [getTicketActionsListTicketDetail],
    (ticketActions): boolean =>
      !!ticketActions?.find((ticketAction) => ticketAction.title === action)
  );

export const getClientCustomFields = createSelector<
  TicketState,
  CustomFieldToRequest[],
  CustomFieldToRequest[]
>([getClientTicketCustomFields], (customFields) =>
  customFields.map((customField) => {
    if (
      customField.customFieldType === CustomFieldType.FIELD_DATE &&
      customField.date
    ) {
      getReverseDateFromCustomField(customField.date);
    }
    return customField;
  })
);

export const createCustomTicketInfoFieldsSelector = (
  getFieldsSelector: Selector<TicketState, CustomFieldToRequest[]>
) =>
  createSelector<
    TicketState,
    CustomFieldToRequest[],
    CustomFieldToRequest[],
    CustomFieldTicket[]
  >(
    [getFieldsSelector, getCustomFieldsTicket],
    (customFieldsBySystem, customFields) =>
      customFieldsBySystem.map((customField) => {
        const { customFieldId, listValue, customFieldType } = customField;
        const customFieldBySystem = customFields.find(
          (field) => field.id === customFieldId
        );

        const listValueOption =
          customFieldType === CustomFieldType.FIELD_LIST
            ? createSelectOption(listValue)
            : null;

        return {
          ...customField,
          listValue: listValueOption,
          values: customFieldBySystem?.values,
        };
      })
  );

export const getClientTicketInfoCustomFields =
  createCustomTicketInfoFieldsSelector(getClientTicketCustomFields);

export const getSpecialistTicketInfoCustomFields =
  createCustomTicketInfoFieldsSelector(getSpecialistTicketCustomFields);
