import cn from 'clsx';
import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { KeyValueOption } from '@shared/model';

import { Tag } from '../Tag';
import { Tooltip } from '../Tooltip';
import { Typography } from '../Typography';

import styles from './MoreTag.module.scss';

interface MoreTagProps {
  data: KeyValueOption[];
  classNameTitle?: string;
}

export const MoreTag: FC<MoreTagProps> = ({ data, classNameTitle }) => {
  const id = uuidv4();

  const dataTip = data.length >= 2 && (
    <Tag text={`+${data ? data?.length - 1 : 0}`} />
  );

  const tooltip = data
    ?.slice(1)
    // eslint-disable-next-line react/no-array-index-key
    .map((item, index) => <Typography key={index}>{item.value}</Typography>);

  return (
    <div className={styles.moreTag__container}>
      <Typography className={cn(styles.moreTag__data, classNameTitle)}>
        {data[0]?.value}
      </Typography>
      <div data-tip data-for={id}>
        {dataTip}
        <Tooltip id={id} place="right">
          {tooltip}
        </Tooltip>
      </div>
    </div>
  );
};
