import { isEmpty } from 'lodash';
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  createJiraIntegration,
  fetchJiraPrioritiesRequest,
  fetchJiraStatusesRequest,
  fetchJiraTicketTypeRequest,
  fetchSPStatusesRequest,
  fetchSyncClientsRequest,
  fetchSyncEnvironmentsRequest,
  fetchSyncPrioritiesRequest,
  fetchSyncSpecialistsRequest,
  fetchSyncStatusesRequest,
  fetchSyncTicketTypesRequest,
  fetchSystemPrioritiesForCompareRequest,
  fetchSystemPrioritiesRequest,
  fetchTicketTypeRequest,
  getCreateTicketTypesOptions,
  getJiraPrioritiesForCreate,
  getJiraPrioritiesOptions,
  getJiraStatusesForCreate,
  getJiraStatusesOptions,
  getJiraTicketTypes,
  getJiraTicketTypesOptions,
  getSpStatuses,
  getSpStatusesForCompare,
  getSyncClientsOptions,
  getSyncEnvironmentsOptions,
  getSyncPrioritiesOptions,
  getSyncSpecialistsOptions,
  getSyncStatusesOptions,
  getSyncTicketTypesOptions,
  getSystemPriorities,
  getSystemPrioritiesForCompare,
  IssuePriorityCreateDtos,
  IssueStatusDtos,
  JiraConnectionStatus,
  JiraIntegrationCreateData,
  JiraSynchronizationCreateForm,
  Priorities,
  RecordValueTypeJiraTicketType,
  requests,
  resetJiraPriorities,
  resetJiraStatuses,
  resetJiraSync,
  resetSPStatuses,
  resetSPStatusesForCompare,
  resetSystemPriorities,
  Statuses,
} from '@entities/jira-integrations';
import {
  fetchOrganizationsRequest,
  getOrganizationsSelectList,
  OrganizationType,
  resetOrganizationState,
  setOrganizationsFilter,
  setSizePage,
} from '@entities/organization';
import {
  fetchSystemsOrganizationForJiraRequest,
  fetchSystemsRequest,
  getSystemsOrganizationSelectList,
  resetSystemsState,
} from '@entities/system';
import { Priority } from '@entities/ticket';
import {
  getTitleFromValueType,
  getValueFromValueType,
  ValueType,
} from '@shared';

import {
  DEFAULT_JIRA_INTEGRATION_FORM_VALUES,
  DEFAULT_JIRA_SYNCHRONIZATION_FORM_VALUES,
} from '../../config/constants';
import { getPreparedDataForCreate } from '../utils';

const setTicketTypes = <
  T extends Record<string, Array<IssueStatusDtos | IssuePriorityCreateDtos>>
>(
  id: string,
  setState: (value: SetStateAction<T>) => void
) => {
  setState((prev) => {
    if (!prev[id]) {
      return { ...prev, [id]: [] };
    }
    return prev;
  });
};

interface UseJiraIntegrationCreateProps {
  toggleModal?(): void;
}

export const useJiraIntegrationCreate = ({
  toggleModal,
}: UseJiraIntegrationCreateProps) => {
  const dispatch = useDispatch();

  const spStatuses = useSelector(getSpStatuses);
  const spStatusesForCompare = useSelector(getSpStatusesForCompare);
  const jiraStatuses = useSelector(getJiraStatusesForCreate);
  const jiraPriorities = useSelector(getJiraPrioritiesForCreate);
  const jiraTicketTypes = useSelector(getJiraTicketTypes);
  const organizationsOptions = useSelector(getOrganizationsSelectList);
  const systemsOptions = useSelector(getSystemsOrganizationSelectList);
  const systemPriorities = useSelector(getSystemPriorities);
  const systemPrioritiesForCompare = useSelector(getSystemPrioritiesForCompare);
  const jiraPrioritiesOptions = useSelector(() =>
    getJiraPrioritiesOptions(jiraPriorities)
  );
  const jiraStatusesOptions = useSelector(() =>
    getJiraStatusesOptions(jiraStatuses)
  );
  const ticketTypesOptions = useSelector(getCreateTicketTypesOptions);
  const jiraTicketTypesOptions = useSelector(getJiraTicketTypesOptions);
  const syncTicketTypesOptions = useSelector(getSyncTicketTypesOptions);
  const syncPrioritiesOptions = useSelector(getSyncPrioritiesOptions);
  const syncEnvironmentsOptions = useSelector(getSyncEnvironmentsOptions);
  const syncStatusesOptions = useSelector(getSyncStatusesOptions);
  const syncSpecialistsOptions = useSelector(getSyncSpecialistsOptions);
  const syncClientsOptions = useSelector(getSyncClientsOptions);

  const [jiraConnectionStatus, setJiraConnectionStatus] =
    useState<JiraConnectionStatus>(JiraConnectionStatus.DEFAULT);

  const isSuccessConnect =
    jiraConnectionStatus === JiraConnectionStatus.SUCCESS;
  const isErrorConnect = jiraConnectionStatus === JiraConnectionStatus.ERROR;

  const [statuses, setStatuses] = useState<Statuses>({});
  const [priorities, setPriorities] = useState<Priorities>({});
  const [jiraTicketType, setJiraTicketType] =
    useState<RecordValueTypeJiraTicketType>({});

  const {
    control,
    register,
    formState: { isDirty, isValid, errors },
    watch,
    resetField,
    handleSubmit,
    setValue,
    trigger,
    getValues,
  } = useForm<JiraIntegrationCreateData>({
    mode: 'onChange',
    // TODO: jira test dev
    // login: 'svgri@list.ru',
    // password: 'FTHMeRBtqpyNepnZv1G2EDCD',
    // projectKey: 'XRFH',
    // jiraUrl: 'https://svgri.atlassian.net',
    defaultValues: DEFAULT_JIRA_INTEGRATION_FORM_VALUES,
  });

  const {
    control: syncControl,
    watch: syncWatch,
    formState: { dirtyFields: syncDirtyFields },
    resetField: syncResetField,
    getValues: syncGetValues,
  } = useForm<JiraSynchronizationCreateForm>({
    mode: 'onChange',
    defaultValues: DEFAULT_JIRA_SYNCHRONIZATION_FORM_VALUES,
  });

  const {
    login,
    password,
    jiraUrl,
    projectKey,
    organizationId,
    systemId,
    ticketTypeId,
  } = watch();

  const { considerFilters } = syncWatch();

  const systemIdValue = getValueFromValueType(systemId);
  const typeId = getValueFromValueType(ticketTypeId) || '';

  const isTicketTypeDisabled = !systemIdValue;
  const isJiraTicketTypeDisabled = !ticketTypeId;
  const statusesDisabled = isEmpty(jiraTicketType[typeId]);
  const syncFieldsDisabled = !systemIdValue;
  const syncDisabledSubmit =
    syncDirtyFields.considerFilters && !syncDirtyFields.filterRules;

  const validateLessOneJiraTicketType = () => {
    const keys = Object.keys(jiraTicketType);

    if (keys.length === 1 || keys.length === 0) {
      const key = keys[0];

      if (
        keys.length &&
        statuses[key]?.length === spStatusesForCompare[key]?.length &&
        priorities[key]?.length === systemPrioritiesForCompare[key]?.length
      ) {
        return false;
      }
      return true;
    }

    return false;
  };

  const lessOneJiraTicketType = validateLessOneJiraTicketType();

  const disabledSubmit =
    !isDirty || !isValid || lessOneJiraTicketType || syncDisabledSubmit;

  const nameInputOptions = register('name', {
    required: true,
  });

  const loginInputOptions = register('login', {
    required: true,
  });

  const passwordInputOptions = register('password', {
    required: true,
  });

  const jiraUrlInputOptions = register('jiraUrl', {
    required: true,
  });

  const projectKeyInputOptions = register('projectKey', {
    required: true,
  });

  const getIsPriorityAvailable = useCallback(
    (priority: Priority) =>
      !!systemPriorities?.find((el) => el.value === priority),
    [systemPriorities]
  );

  const spPriorities = Object.values(Priority).map((priority) => ({
    priority,
  }));

  const isCheckConnectionDisabled = useMemo(
    () => !login || !password || !jiraUrl || !projectKey || isSuccessConnect,
    [login, password, jiraUrl, projectKey, isSuccessConnect]
  );

  const checkConnectionData = useMemo(
    () => ({
      login,
      password,
      jiraUrl,
      projectKey,
    }),
    [login, password, jiraUrl, projectKey]
  );

  const checkConnectionHandler = useCallback(async () => {
    try {
      const isConnect = await requests.checkConnectionToJira(
        checkConnectionData
      );
      if (isConnect) {
        setJiraConnectionStatus(JiraConnectionStatus.SUCCESS);
        dispatch(fetchJiraTicketTypeRequest(checkConnectionData));
        dispatch(fetchJiraPrioritiesRequest(checkConnectionData));
        dispatch(resetJiraSync());
        return;
      }
      setJiraConnectionStatus(JiraConnectionStatus.ERROR);
    } catch {
      setJiraConnectionStatus(JiraConnectionStatus.ERROR);
    }
  }, [login, password, jiraUrl, projectKey, setJiraConnectionStatus]);

  const onChangeStatuses = useCallback(
    (id: string) => (value: ValueType<number>) => {
      setStatuses((prevState) => {
        const statusByKey = prevState[typeId];

        const result = jiraStatuses?.find(
          (item) => item.idStatus === getValueFromValueType(value)
        );
        const existingTicketStatusId = !!statusByKey?.find((item) =>
          item.ticketStatusIds.includes(id)
        );

        if (!existingTicketStatusId) {
          return {
            ...prevState,
            [typeId]: [...statusByKey, { ...result, ticketStatusIds: [id] }],
          };
        }

        return {
          ...prevState,
          [typeId]: statusByKey.map((status) => {
            if (status.ticketStatusIds.includes(id)) {
              return { ...result, ticketStatusIds: [id] };
            }
            return status;
          }),
        };
      });
    },
    [setStatuses, jiraStatuses, typeId]
  );

  const setTicketTypeIdInKey = (id: string) => {
    setTicketTypes(id, setStatuses);
    setTicketTypes(id, setPriorities);
  };

  const resetSPStatusesCompare = () => {
    dispatch(resetSPStatusesForCompare());
  };

  const onChangePriorities = useCallback(
    (priority: Priority) => (value: ValueType) => {
      setPriorities((prevState) => {
        const priorityByKey = prevState[typeId];

        const title = getTitleFromValueType(value) || '';
        const result = jiraPriorities?.find((item) => item.name === title);
        const existingPriority = !!priorityByKey?.find((item) =>
          item?.ticketPriorities?.includes(priority)
        );

        if (!existingPriority) {
          return {
            ...prevState,
            [typeId]: [
              ...priorityByKey,
              { ...result, ticketPriorities: [priority] },
            ],
          };
        }

        return {
          ...prevState,
          [typeId]: priorityByKey.map((item) => {
            if (item?.ticketPriorities?.includes(priority)) {
              return { ...result, ticketPriorities: [priority] };
            }
            return item;
          }),
        };
      });
    },
    [setPriorities, jiraPriorities, typeId]
  );

  const onChangeJiraTicketType = (value: ValueType<string>) => {
    setJiraTicketType((prevState) => ({
      ...prevState,
      [typeId]: value,
    }));
  };

  useEffect(() => {
    dispatch(
      setOrganizationsFilter({
        organizationTypes: {
          title: OrganizationType.CUSTOMER,
          value: OrganizationType.CUSTOMER,
        },
      })
    );
    dispatch(setSizePage(1000));
    dispatch(fetchOrganizationsRequest());
    return () => {
      dispatch(resetOrganizationState());
      dispatch(resetSystemsState());
      dispatch(fetchSystemsRequest());
      dispatch(resetJiraStatuses());
      dispatch(resetJiraPriorities());
      dispatch(resetSystemPriorities());
      dispatch(resetSPStatuses());
    };
  }, []);

  useEffect(() => {
    if (organizationId && !Array.isArray(organizationId)) {
      dispatch(resetSystemsState());
      dispatch(resetSystemPriorities());
      dispatch(fetchSystemsOrganizationForJiraRequest(organizationId.value));
      dispatch(resetSPStatuses());
      resetField('systemId');
      resetField('ticketTypeId');
      setJiraTicketType({});
      syncResetField('filterRules');
    }
  }, [organizationId]);

  useEffect(() => {
    if (systemIdValue) {
      dispatch(fetchSystemPrioritiesRequest(systemIdValue));
      dispatch(fetchTicketTypeRequest(systemIdValue));
      dispatch(fetchSyncTicketTypesRequest(systemIdValue));
      dispatch(fetchSyncPrioritiesRequest(systemIdValue));
      dispatch(fetchSyncEnvironmentsRequest({ systemId: systemIdValue }));
      dispatch(fetchSyncStatusesRequest(systemIdValue));
      dispatch(fetchSyncSpecialistsRequest(systemIdValue));
      dispatch(fetchSyncClientsRequest(systemIdValue));
      dispatch(resetSPStatuses());
      resetField('ticketTypeId');
      setJiraTicketType({});
      syncResetField('filterRules');
    }
  }, [systemIdValue]);

  useEffect(() => {
    if (systemIdValue && typeId) {
      dispatch(
        fetchSystemPrioritiesForCompareRequest({
          systemId: systemIdValue,
          typeId,
        })
      );
    }
  }, [systemIdValue, typeId]);

  useEffect(() => {
    setStatuses({});
    setPriorities({});
  }, [systemId]);

  const fetchSPStatuses = (id: string) => {
    if (systemIdValue) {
      dispatch(fetchSPStatusesRequest({ systemId: systemIdValue, typeId: id }));
    }
  };

  const fetchJiraStatuses = (idType: string) => {
    dispatch(fetchJiraStatusesRequest({ ...checkConnectionData, idType }));
  };

  const createJiraIntegrationHandler = handleSubmit((data) => {
    const prepareData = getPreparedDataForCreate({
      data,
      statuses,
      spStatusesForCompare,
      priorities,
      systemPrioritiesForCompare,
      jiraTicketType,
      jiraTicketTypes,
      ticketTypeKeys: Object.keys(spStatusesForCompare),
      syncArgs: syncGetValues(),
    });

    dispatch(createJiraIntegration(prepareData));
    toggleModal?.();
  });

  return {
    state: {
      organizationsOptions,
      systemsOptions,
      jiraPrioritiesOptions,
      jiraStatusesOptions,
      jiraConnectionStatus,
      isSuccessConnect,
      isErrorConnect,
      control,
      errors,
      nameInputOptions,
      loginInputOptions,
      passwordInputOptions,
      jiraUrlInputOptions,
      projectKeyInputOptions,
      isCheckConnectionDisabled,
      spPriorities,
      spStatuses,
      ticketTypeId,
      isTicketTypeDisabled,
      isJiraTicketTypeDisabled,
      jiraTicketTypesOptions,
      ticketTypesOptions,
      statuses: statuses[typeId] || [],
      priorities: priorities[typeId] || [],
      jiraTicketType: jiraTicketType[typeId] || [],
      disabledSubmit,
      statusesDisabled,
      synchronizationProps: {
        considerFilters,
        syncTicketTypesOptions,
        syncPrioritiesOptions,
        syncEnvironmentsOptions,
        syncStatusesOptions,
        syncSpecialistsOptions,
        syncClientsOptions,
        syncFieldsDisabled,
        control: syncControl,
        getValues: syncGetValues,
      },
    },
    methods: {
      checkConnectionHandler,
      createJiraIntegrationHandler,
      getIsPriorityAvailable,
      fetchSPStatuses,
      fetchJiraStatuses,
      setValue,
      resetField,
      trigger,
      onChangeStatuses,
      onChangePriorities,
      getValues,
      setTicketTypeIdInKey,
      onChangeJiraTicketType,
      resetSPStatusesCompare,
    },
  };
};
