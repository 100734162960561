import { FC } from 'react';

import { Button, Size } from '@shared';

import styles from './ResetFilterButton.module.scss';

interface ResetFilterButtonProps {
  reset: () => void;
}

export const ResetFilterButton: FC<ResetFilterButtonProps> = ({ reset }) => (
  <Button
    type="button"
    appearance="flat"
    size={Size.xs}
    onClick={reset}
    className={styles.resetFilterButton}
  >
    Сбросить фильтр
  </Button>
);
