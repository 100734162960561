import { DefaulDataTable } from '../../model';

export const getDefaultDataTable = ({
  columns,
  defaultFilterMap,
  idsForTable,
}: DefaulDataTable) =>
  columns
    .map((column) => {
      if (idsForTable) {
        const preparedId = idsForTable(column);
        return defaultFilterMap[preparedId];
      }
      return defaultFilterMap[column];
    })
    .filter(Boolean);
