import { User } from '@entities/user/model/types';
import { WorkGroup } from '@entities/work-group/model';
import { ApiClient, getEnv, ResponseWithMeta } from '@shared';

import { AccessedRoute } from '../model';

import { endpoints } from './endpoints';

export const requests = {
  fetchAccessedRoutes: () =>
    ApiClient.get<AccessedRoute[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getAccessedRoutes(),
    }),
  fetchActionList: () =>
    ApiClient.get<string[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getActionList(),
    }),
  fetchUsersWorkGroups: (userId: string) =>
    ApiClient.post<ResponseWithMeta<WorkGroup[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getUsersWorkGroups(userId),
      data: {},
    }),
  changePassword: (password: string) =>
    ApiClient.put<User>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getChangePasswordPath(),
      data: password,
      headers: {
        'Content-Type': 'text/plain',
      },
    }),
  fetchUser: () =>
    ApiClient.get<User>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getUser(),
    }),
  fetchManagerWorkGroups: (userId: string) =>
    ApiClient.get<string[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getUserManagerWorkGroup(userId),
    }),
  fetchSpecialistWorkGroups: (userId: string) =>
    ApiClient.get<string[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getUserSpecialistWorkGroup(userId),
    }),
};
