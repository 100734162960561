import {
  IssuePriorityEditDtos,
  JiraPriority,
} from '@entities/jira-integrations';

export const getIssueTicketPriorityDtos = (priorities: JiraPriority[]) =>
  priorities.reduce((acc, el) => {
    const result = acc.find((item) => item.idPriority === el.idPriority);
    const { ticketPriorities } = el;

    if (result && result.ticketPriorities && ticketPriorities) {
      return [
        ...acc.filter((item) => item.idPriority !== el.idPriority),
        {
          ...el,
          ticketPriorities: [...ticketPriorities, ...result.ticketPriorities],
        },
      ];
    }

    return [...acc, { ...el, ticketPriorities }];
  }, [] as IssuePriorityEditDtos[]);
