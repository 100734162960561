import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getIsReportClients } from '@entities/auth';
import {
  downloadTicketsRequest,
  getCurrentStateTickets,
  getCurrentStateTicketsStatistic,
  getHistoryTickets,
  getHistoryTicketsStatistic,
  getIsDesktopTicketsLoading,
} from '@entities/desktop';
import { getFilterValues } from '@entities/ticket';
import { DEFAULT_TICKETS_FILTER_VALUES } from '@features/ticket/config';
import {
  checkObjectIdentity,
  checkPathnameWithTrailingSlash,
  RouterHref,
} from '@shared';

export const useDownloadTicketsData = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const ticketsHistory = useSelector(getHistoryTickets);
  const ticketsStatisticHistory = useSelector(getHistoryTicketsStatistic);
  const ticketsCurrentState = useSelector(getCurrentStateTickets);
  const ticketsStatisticCurrentState = useSelector(
    getCurrentStateTicketsStatistic
  );
  const filterValues = useSelector(getFilterValues);

  const isFilterActive = !checkObjectIdentity(
    filterValues,
    DEFAULT_TICKETS_FILTER_VALUES
  );

  const isHistoryPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.DesktopHistory
  );

  const tickets = isHistoryPage ? ticketsHistory : ticketsCurrentState;
  const ticketsStatistic = isHistoryPage
    ? ticketsStatisticHistory
    : ticketsStatisticCurrentState;

  const isDownload = useSelector(getIsDesktopTicketsLoading);
  const isReportClients = useSelector(getIsReportClients);

  const downloadTicketsData = ticketsStatistic?.downloadInfo;

  const handleDownload = useCallback(() => {
    if (downloadTicketsData) {
      dispatch(
        downloadTicketsRequest({
          path: downloadTicketsData?.path,
          fileName: downloadTicketsData?.fileName,
        })
      );
    }
  }, [tickets, downloadTicketsData]);

  return {
    state: {
      tickets,
      ticketsStatistic,
      isDownload,
      isReportClients,
      isFilterActive,
    },
    methods: {
      handleDownload,
    },
  };
};
