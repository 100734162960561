import { Control, Controller, UseFormGetValues } from 'react-hook-form';

import { JiraSynchronizationCreateForm } from '@entities/jira-integrations';
import { Select, SelectOption, ToggleSwitch, TypographyNew } from '@shared';

import styles from './JiraSynchronization.module.scss';

type JiraSynchronizationProps = {
  jiraSyncProps: {
    state: {
      control: Control<JiraSynchronizationCreateForm>;
      synchronizationProps: {
        autoCreateTasks?: boolean;
        considerFilters?: boolean;
        syncTicketTypesOptions: SelectOption[];
        syncPrioritiesOptions: SelectOption[];
        syncEnvironmentsOptions: SelectOption[];
        syncStatusesOptions: SelectOption[];
        syncSpecialistsOptions: SelectOption[];
        syncClientsOptions: SelectOption[];
        syncFieldsDisabled?: boolean;
      };
    };
    methods: {
      getValues: UseFormGetValues<JiraSynchronizationCreateForm>;
    };
  };
  preview?: boolean;
};

export const JiraSynchronization = ({
  jiraSyncProps,
  preview,
}: JiraSynchronizationProps) => {
  const {
    state: { control, synchronizationProps },
  } = jiraSyncProps;

  const {
    considerFilters,
    syncTicketTypesOptions,
    syncPrioritiesOptions,
    syncEnvironmentsOptions,
    syncStatusesOptions,
    syncSpecialistsOptions,
    syncClientsOptions,
    syncFieldsDisabled,
  } = synchronizationProps;

  return (
    <div className={styles.jiraSynchronization}>
      <div className={styles.jiraSynchronization__switches}>
        <div className={styles.jiraSynchronization__switchBlock}>
          <div className={styles.jiraSynchronization__switch}>
            <Controller
              name="autoCreateTasks"
              key="autoCreateTasks"
              control={control}
              render={({ field }) => (
                <ToggleSwitch
                  label="Автоматически создавать аналогичный тикет в Джира"
                  disabled={preview}
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </div>

          <TypographyNew
            variant="s2"
            className={styles.jiraSynchronization__switchBlockDescription}
          >
            Новые тикеты на портале будут автоматически отправляться в Jira. При
            одновременном включении параметра «Учитывать фильтр для
            синхронизации» копироваться в Jira будут только те тикеты, которые
            попадают под настройки фильтров
          </TypographyNew>
        </div>
        <div className={styles.jiraSynchronization__switchBlock}>
          <div className={styles.jiraSynchronization__switch}>
            <Controller
              name="considerFilters"
              key="considerFilters"
              control={control}
              render={({ field }) => (
                <ToggleSwitch
                  label="Учитывать фильтр при синхронизации"
                  disabled={preview}
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          <TypographyNew
            variant="s2"
            className={styles.jiraSynchronization__switchBlockDescription}
          >
            При включённом параметре в уже связанных с Jira тикетах
            синхронизация изменений будет происходить только для тех тикетов,
            которые попадают под настройки фильтров
          </TypographyNew>
        </div>
      </div>
      {considerFilters && (
        <div className={styles.jiraSynchronization__fields}>
          <Controller
            name="filterRules.TICKET_TYPE"
            key="filterRules.TICKET_TYPE"
            control={control}
            render={({ field }) => (
              <Select
                isMulti
                disabled={preview || syncFieldsDisabled}
                value={field.value}
                label="Тип тикета"
                options={syncTicketTypesOptions}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="filterRules.TICKET_PRIORITY"
            key="filterRules.TICKET_PRIORITY"
            control={control}
            render={({ field }) => (
              <Select
                isMulti
                disabled={preview || syncFieldsDisabled}
                value={field.value}
                label="Приоритет"
                options={syncPrioritiesOptions}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="filterRules.TICKET_ENVIRONMENT"
            key="filterRules.TICKET_ENVIRONMENT"
            control={control}
            render={({ field }) => (
              <Select
                isMulti
                disabled={preview || syncFieldsDisabled}
                value={field.value}
                label="Среда"
                options={syncEnvironmentsOptions}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="filterRules.TICKET_STATUS"
            key="filterRules.TICKET_STATUS"
            control={control}
            render={({ field }) => (
              <Select
                isMulti
                disabled={preview || syncFieldsDisabled}
                value={field.value}
                label="Статус"
                options={syncStatusesOptions}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="filterRules.TICKET_SPECIALIST"
            key="filterRules.TICKET_SPECIALIST"
            control={control}
            render={({ field }) => (
              <Select
                isMulti
                disabled={preview || syncFieldsDisabled}
                value={field.value}
                label="Исполнитель"
                options={syncSpecialistsOptions}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="filterRules.TICKET_CLIENT"
            key="filterRules.TICKET_CLIENT"
            control={control}
            render={({ field }) => (
              <Select
                isMulti
                disabled={preview || syncFieldsDisabled}
                value={field.value}
                label="Создатель тикета"
                options={syncClientsOptions}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};
