import { call, put, select, takeEvery } from 'redux-saga/effects';

import {
  createError,
  createSuccessAlert,
  getKeysFromKeyValue,
  KeyValueOption,
  ResponseWithMeta,
  setAlert,
} from '@shared';

import { requests } from '../api';

import { getCurrentRuleId, getFilterValues, getPropsRules } from './selectors';
import {
  createRule,
  deleteRuleRequest,
  fetchAttributesFieldsRequest,
  fetchAttributesFieldsSuccess,
  fetchCurrentRule,
  fetchFiltersFieldsRequest,
  fetchFiltersFieldsSuccess,
  fetchRulesRequest,
  fetchRulesSuccess,
  fetchSpecialistsRequest,
  fetchSpecialistsSuccess,
  fetchWorkGroupsRequest,
  fetchWorkGroupsSuccess,
  hideRulesLoading,
  resetCurrentRule,
  setCurrentRuleId,
  setCurrentRuleSuccess,
  showRulesLoading,
  updateRule,
} from './slice';
import { Attributes, Rule, RulesFilter, RulesFilterToRequest } from './types';

export const getFilterRulesRequest = (
  filter: RulesFilter
): RulesFilterToRequest => filter;

function* filterFieldsFetch() {
  try {
    const rulesFields: Attributes = yield call(requests.fetchFiltersFields);
    yield put(fetchFiltersFieldsSuccess(rulesFields));
  } catch (error) {
    createError(error);
  }
}

function* attributesFieldsFetch({
  payload,
}: ReturnType<typeof fetchAttributesFieldsRequest>) {
  try {
    const rulesFields: Attributes = yield call(
      requests.fetchRulesFields,
      payload
    );
    yield put(fetchAttributesFieldsSuccess(rulesFields));
  } catch (error) {
    createError(error);
  }
}

function* specialistsFetch({
  payload,
}: ReturnType<typeof fetchSpecialistsRequest>) {
  try {
    const specialists: KeyValueOption[] = yield call(
      requests.fetchSpecialists,
      payload
    );
    yield put(fetchSpecialistsSuccess(specialists));
  } catch (error) {
    createError(error);
  }
}

function* workGroupsFetch({
  payload,
}: ReturnType<typeof fetchWorkGroupsRequest>) {
  try {
    const workGroups: KeyValueOption[] = yield call(
      requests.fetchWorkGroups,
      payload
    );
    yield put(fetchWorkGroupsSuccess(workGroups));
  } catch (error) {
    createError(error);
  }
}

function* rulesFetch() {
  try {
    const { pageNum, pageSize, sortRules }: ReturnType<typeof getPropsRules> =
      yield select(getPropsRules);
    const filter: ReturnType<typeof getFilterValues> = yield select(
      getFilterValues
    );
    yield put(showRulesLoading());

    const rules: ResponseWithMeta<Rule[]> = yield call(
      requests.fetchRules,
      pageNum,
      pageSize,
      sortRules,
      filter
    );
    yield put(fetchRulesSuccess(rules));
    yield put(hideRulesLoading());
  } catch (e) {
    yield put(hideRulesLoading());
    createError(e);
  }
}

function* currentRuleFetch({ payload }: ReturnType<typeof fetchCurrentRule>) {
  try {
    const rule: Rule = yield call(requests.fetchCurrentRule, payload);

    const attributes = {
      organizations: getKeysFromKeyValue(rule.organizations),
      systems: getKeysFromKeyValue(rule.systems),
      ticketTypes: getKeysFromKeyValue(rule.ticketTypes),
      ticketPriorities: getKeysFromKeyValue(rule.ticketPriorities),
      environments: getKeysFromKeyValue(rule.environments),
      clients: getKeysFromKeyValue(rule.clients),
    };

    yield call(attributesFieldsFetch, {
      type: fetchAttributesFieldsRequest.type,
      payload: attributes,
    });

    yield put(setCurrentRuleSuccess(rule));
  } catch (e) {
    createError(e);
  }
}

function* ruleCreate({ payload }: ReturnType<typeof createRule>) {
  try {
    const { pageSize, sortRules }: ReturnType<typeof getPropsRules> =
      yield select(getPropsRules);
    const filter: ReturnType<typeof getFilterValues> = yield select(
      getFilterValues
    );
    const rule: Rule = yield call(requests.createRule, payload);
    yield put(
      setAlert(
        createSuccessAlert(`Успешно выполнено создание правила ${rule.title}.`)
      )
    );
    yield put(showRulesLoading());

    const rules: ResponseWithMeta<Rule[]> = yield call(
      requests.fetchRules,
      0,
      pageSize,
      sortRules,
      filter
    );
    yield put(fetchRulesSuccess(rules));
    yield put(hideRulesLoading());
  } catch (e) {
    yield put(hideRulesLoading());
    createError(e);
  }
}

function* ruleUpdate({ payload }: ReturnType<typeof createRule>) {
  try {
    const id: ReturnType<typeof getCurrentRuleId> = yield select(
      getCurrentRuleId
    );
    if (id) {
      const rule: Rule = yield call(requests.updateRule, payload, id);
      yield put(setCurrentRuleId(rule.id));
      yield put(setCurrentRuleSuccess(rule));
      yield put(
        setAlert(
          createSuccessAlert(`Правило ${rule.title} успешно отредактировано`)
        )
      );
    }

    yield call(rulesFetch);
  } catch (e) {
    createError(e);
  }
}

function* deleteRule({ payload }: ReturnType<typeof deleteRuleRequest>) {
  try {
    yield call(requests.deleteRule, payload);
    yield put(setAlert(createSuccessAlert(`Правило успешно удалено`)));
    yield put(resetCurrentRule());
    yield call(rulesFetch);
  } catch (e) {
    createError(e);
  }
}

export function* rulesSaga() {
  yield takeEvery(fetchFiltersFieldsRequest.type, filterFieldsFetch);
  yield takeEvery(fetchAttributesFieldsRequest.type, attributesFieldsFetch);
  yield takeEvery(fetchSpecialistsRequest.type, specialistsFetch);
  yield takeEvery(fetchWorkGroupsRequest.type, workGroupsFetch);
  yield takeEvery(fetchRulesRequest.type, rulesFetch);
  yield takeEvery(fetchCurrentRule.type, currentRuleFetch);
  yield takeEvery(createRule.type, ruleCreate);
  yield takeEvery(deleteRuleRequest.type, deleteRule);
  yield takeEvery(updateRule.type, ruleUpdate);
}
