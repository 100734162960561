import { createSelector } from 'reselect';

import { Action } from '@entities/actions/model/types';
import {
  getActionList,
  getUserId,
  getUserOrganization,
} from '@entities/auth/model/selectors';
import { ActionList, AuthState } from '@entities/auth/model/types';
import {
  Organization,
  OrganizationType,
} from '@entities/organization/model/types';
import { Ticket, TicketType } from '@entities/ticket/model/types';
import { SelectOption } from '@shared';

import {
  EnabledFieldsList,
  ISettingFields,
  TicketsSettingFieldType,
  TicketsState,
} from './types';

export const getTickets = (state: TicketsState) => state?.tickets.tickets;
export const getDesktopTickets = (state: TicketsState) =>
  state?.tickets.desktopTickets;
export const getLoadingTickets = (state: TicketsState) =>
  state?.tickets.loading;
export const getTicketLoading = (state: TicketsState) =>
  state?.tickets.ticketLoading;
export const getCurrentTicket = (state: TicketsState) => state?.tickets.ticket;
export const getIsSearchOpen = (state: TicketsState) =>
  state?.tickets.isSearchOpen;
export const getPageNum = (state: TicketsState) =>
  state.tickets.pagination?.pageNum;
export const getPageSize = (state: TicketsState) =>
  state.tickets.pagination?.pageSize;
export const getTotalElements = (state: TicketsState) =>
  state.tickets.totalElements;
export const getSort = (state: TicketsState) => state?.tickets.sort;
export const getFilterValues = (state: TicketsState) =>
  state?.tickets.filter || {};
export const getOpenTicketsCount = (state: TicketsState) =>
  state.tickets.openTicketsCount;
export const getCurrentTicketId = (state: TicketsState) =>
  state.tickets.currentTicketId;
export const getTicketActionsListTickets = (state: TicketsState) =>
  state.tickets.ticketActionsList;
export const getStatusesTickets = (state: TicketsState) =>
  state.tickets.statuses;
export const getFiltersData = (state: TicketsState) =>
  state.tickets.filtersData;
export const getCurrentFilterId = (state: TicketsState) =>
  state.tickets.currentFilterId;
export const getIsUsedFilter = (state: TicketsState) =>
  state.tickets.isUsedFilter;
export const getIsSettingFieldsOpen = (state: TicketsState) =>
  state.tickets.isSettingFieldsOpen;
export const getSettingFields = (state: TicketsState) =>
  state.tickets.settingFields;
export const getTicketTypesTickets = (state: TicketsState) =>
  state.tickets.types;
export const getClientInfoOrgTypeFromTickets = (state: TicketsState) =>
  state.tickets.ticket?.clientInfo?.organizationType;
export const getIsModalPopoverInner = (state: TicketsState) =>
  state.tickets.isModalPopoverInner;
export const getValueTabItem = (state: TicketsState) =>
  state.tickets.valueTabItem;
export const getPropsType = (state: TicketsState) => ({
  pageNum: state.tickets.paginationType?.pageNum,
  pageSize: state.tickets.paginationType?.pageSize,
  totalElements: state.tickets.paginationType?.totalElements,
  loading: state.tickets.loadingType,
});
export const getPropsStatus = (state: TicketsState) => ({
  pageNum: state.tickets.paginationStatus?.pageNum,
  pageSize: state.tickets.paginationStatus?.pageSize,
  totalElements: state.tickets.paginationStatus?.totalElements,
  sort: state?.tickets.sortStatus,
  loading: state.tickets.loadingStatus,
});

export const getIsAccessedTicketAction = (action: ActionList) =>
  createSelector<TicketsState, Action[] | undefined, boolean>(
    [getTicketActionsListTickets],
    (ticketActions): boolean =>
      !!ticketActions?.find((ticketAction) => ticketAction.title === action)
  );

export const getStatusesSelectList = createSelector<
  TicketsState,
  string[] | undefined,
  SelectOption[]
>([getStatusesTickets], (statuses): SelectOption[] => {
  if (Array.isArray(statuses)) {
    return statuses.map(
      (status: string): SelectOption => ({
        title: status,
        value: status,
      })
    );
  }
  return [];
});

export const enabledFieldsList = createSelector<
  TicketsState,
  ISettingFields | undefined,
  EnabledFieldsList
>([getSettingFields], (field) => {
  const getKeys = (columns: TicketsSettingFieldType[]): string[] =>
    columns
      .filter((column) => !column.hidden)
      .map((column) => column.naturalKey);

  const defaultColumns = field?.defaultColumns || [];
  const customColumns = field?.customColumns || [];

  return {
    defaultColumns: getKeys(defaultColumns),
    customColumns: getKeys(customColumns),
  };
});

export const getTicketTypesOptions = createSelector<
  TicketsState,
  TicketType[] | undefined,
  SelectOption[]
>([getTicketTypesTickets], (types): SelectOption[] => {
  if (Array.isArray(types)) {
    return types.map(
      ({ id, title }): SelectOption => ({
        title,
        value: id,
      })
    );
  }
  return [];
});

export const getIsHideSLA = (orgType?: OrganizationType, ticket?: Ticket) =>
  createSelector<
    AuthState,
    string[] | undefined,
    Organization | undefined,
    string | undefined,
    boolean
  >(
    [getActionList, getUserOrganization, getUserId],
    (actionList, organization, userId): boolean => {
      const isAccessToTakeTicketToWork = actionList?.includes(
        ActionList.TakeTicketToWork
      );
      const isAccessToCreateTicket = actionList?.includes(
        ActionList.CreateTicket
      );

      const isClientPartner =
        orgType === OrganizationType.PARTNER &&
        organization?.type === OrganizationType.PARTNER;

      const isMyTicket = userId === ticket?.clientInfo?.id;

      return !!(
        (isClientPartner &&
          isAccessToCreateTicket &&
          !isAccessToTakeTicketToWork) ||
        (isAccessToTakeTicketToWork && isMyTicket)
      );
    }
  );
