import { useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import { CreateTicket as CreateTicketContent } from '@features/ticket';
import { MainLayout } from '@shared';

import styles from './CreateTicketPage.module.scss';

const CreateTicket = () => {
  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );

  return (
    <MainLayout
      headerType="default"
      title="Создать тикет"
      classNameHeader={styles.createTicketPage__header}
      withGoBack
      accessToCreateTicket={isAccessToCreateTicket}
    >
      <CreateTicketContent />
    </MainLayout>
  );
};

export default CreateTicket;
