import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  getIsReportAccess,
  getIsReportClients,
  getUserOrganization,
} from '@entities/auth';
import {
  DesktopState,
  fetchOrganizationsRequest,
  fetchSystemsRequest,
  fetchTicketsRequestHistory,
  fetchTicketsStatisticRequestHistory,
  getTransformedTypeList,
  HistoryFormData,
  resetDesktopState,
  resetHistoryState,
  setDesktopFilters,
} from '@entities/desktop';
import { INITIAL_HISTORY_FORM_VALUES } from '@features/desktop/config';
import {
  checkPathnameWithTrailingSlash,
  convertFromDateFormat,
  convertToDateFormat,
  getFormatPartialDate,
  getMultiOptionValues,
  getSelectOption,
  getValueFromValueType,
  RouterHref,
} from '@shared';

export const useHistoryForm = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { control, watch, setValue, reset } = useForm<HistoryFormData>({
    mode: 'onChange',
    defaultValues: INITIAL_HISTORY_FORM_VALUES,
  });

  const { date, organizationId, systemId, ticketTypesIds } = watch();

  const userOrganization = useSelector(getUserOrganization);
  const isReportClients = useSelector(getIsReportClients);
  const isReportAccess = useSelector(getIsReportAccess);

  const ticketTypesOptions = useSelector((state: DesktopState) =>
    getTransformedTypeList(state, systemId ?? [])
  );

  const isFetchDesktopFilters = !!organizationId && isReportAccess;

  const isCanFetchSystemsOrganizationsId =
    !!organizationId && !Array.isArray(organizationId);

  const isHistoryPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.DesktopHistory
  );

  const shouldSetOrganizationId =
    !organizationId && isReportClients && userOrganization;

  const shouldResetStatesAndFetch = isHistoryPage && !organizationId;

  const isDisabledSystemHistory = !organizationId;

  const allHistoryFieldReset = () => {
    dispatch(resetHistoryState());
    dispatch(resetDesktopState());
    reset();
    dispatch(fetchOrganizationsRequest());
  };

  useEffect(() => {
    if (isCanFetchSystemsOrganizationsId) {
      dispatch(fetchSystemsRequest(organizationId?.value));
    }
  }, [isCanFetchSystemsOrganizationsId]);

  useEffect(() => {
    if (shouldResetStatesAndFetch) {
      dispatch(resetHistoryState());
      dispatch(resetDesktopState());
      dispatch(fetchOrganizationsRequest());
    }
  }, [shouldResetStatesAndFetch]);

  useEffect(() => {
    if (shouldSetOrganizationId) {
      setValue('organizationId', getSelectOption(userOrganization));
    }
  }, [shouldSetOrganizationId]);

  useEffect(() => {
    if (isFetchDesktopFilters) {
      dispatch(
        setDesktopFilters({
          organizationId: getValueFromValueType(organizationId),
          systemId: getValueFromValueType(systemId),
          to: getFormatPartialDate(convertToDateFormat(date)),
          from: getFormatPartialDate(convertFromDateFormat(date)),
          onlyActualTickets: false,
          ticketTypeIds: getMultiOptionValues(ticketTypesIds),
        })
      );
      dispatch(fetchTicketsRequestHistory());
      dispatch(fetchTicketsStatisticRequestHistory());
    }
  }, [isFetchDesktopFilters, date, organizationId, systemId, ticketTypesIds]);

  return {
    state: {
      ticketTypesHistoryOptions: ticketTypesOptions,
      isDisabledSystemHistory,
    },
    methods: {
      historyControl: control,
      setHistoryValue: setValue,
      historyReset: allHistoryFieldReset,
    },
  };
};
