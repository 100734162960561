import { Ticket } from '@entities/ticket/model/types';
import { ApiClient, getEnv, ResponseWithMeta } from '@shared';

import {
  DesktopFilterRequest,
  DesktopFilters,
  TicketsStatistic,
} from '../../model';

import { endpointsCurrentState } from './endpoints';

export const requestsCurrentState = {
  fetchCurrentStateTickets: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filterValues?: DesktopFilterRequest
  ) =>
    ApiClient.post<ResponseWithMeta<Ticket[]>>({
      baseURL: getEnv('REACT_APP_SLA_URL'),
      url: endpointsCurrentState.getCurrentStateTickets(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: filterValues,
    }),
  fetchCurrentStateTicketsStatistic: (filterValues?: DesktopFilters) =>
    ApiClient.post<TicketsStatistic>({
      baseURL: getEnv('REACT_APP_TICKET_REPORT_URL'),
      url: endpointsCurrentState.getCurrentStateTicketsStatistic(),
      data: filterValues,
    }),
};
