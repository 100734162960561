import { all, AllEffect, StrictEffect } from 'redux-saga/effects';

import { accessesSaga } from '@entities/accesses';
import { actionsSaga } from '@entities/actions';
import { currentUserSaga } from '@entities/auth';
import { contractDetailSaga, contractsSaga } from '@entities/contract';
import { customFieldsSaga } from '@entities/custom-fields';
import {
  desktopCurrentStateSaga,
  desktopEmployeesSaga,
  desktopHistorySaga,
  desktopKpiSaga,
  desktopSaga,
} from '@entities/desktop';
import { jiraSaga } from '@entities/jira-integrations';
import {
  articleCreateSaga,
  articlesMainSaga,
  articlesTableSaga,
  articleViewingSaga,
} from '@entities/knowledge-base';
import { organizationsSaga } from '@entities/organization';
import { notificationsProfileSaga } from '@entities/profile';
import { responsibilitiesSaga } from '@entities/responsibilities';
import { rolesSaga } from '@entities/roles';
import { rulesSaga } from '@entities/rules';
import { similarSolutionsSaga } from '@entities/similar-solutions';
import { statusesBuilderSaga } from '@entities/statuses-builder';
import { storageSaga, storagesSaga } from '@entities/storage';
import { supplementaryAgreementDetailSaga } from '@entities/supplementary-agreement';
import { systemSaga, systemsSaga } from '@entities/system';
import {
  assessmentInteractionSaga,
  attachmentsSaga,
  createTicketSaga,
  notesSaga,
  tagsSaga,
  ticketSaga,
  ticketsExportSaga,
  ticketsSaga,
} from '@entities/ticket';
import { triggersSaga } from '@entities/triggers';
import { usersSaga } from '@entities/user';
import { userAssistanceSaga } from '@entities/user-assistance';
import { workGroupsSaga } from '@entities/work-group';

export function* rootSaga(): Generator<AllEffect<Generator<StrictEffect>>> {
  yield all([
    currentUserSaga(),
    ticketsSaga(),
    usersSaga(),
    attachmentsSaga(),
    systemsSaga(),
    systemSaga(),
    createTicketSaga(),
    organizationsSaga(),
    contractsSaga(),
    workGroupsSaga(),
    responsibilitiesSaga(),
    rolesSaga(),
    accessesSaga(),
    actionsSaga(),
    notificationsProfileSaga(),
    tagsSaga(),
    notesSaga(),
    customFieldsSaga(),
    ticketsExportSaga(),
    jiraSaga(),
    articleCreateSaga(),
    articlesTableSaga(),
    articleViewingSaga(),
    articlesMainSaga(),
    similarSolutionsSaga(),
    assessmentInteractionSaga(),
    ticketSaga(),
    contractDetailSaga(),
    supplementaryAgreementDetailSaga(),
    statusesBuilderSaga(),
    desktopSaga(),
    desktopKpiSaga(),
    desktopCurrentStateSaga(),
    desktopHistorySaga(),
    desktopEmployeesSaga(),
    userAssistanceSaga(),
    rulesSaga(),
    triggersSaga(),
    storagesSaga(),
    storageSaga(),
  ]);
}
