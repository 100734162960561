import { MapFields } from '@shared';

export const mapFields = <T>({
  fieldsList,
  field,
  currentField,
}: MapFields<T>) =>
  fieldsList.map((f) => {
    if (currentField && f.naturalKey === field.naturalKey) {
      return { ...f, ordinal: currentField.ordinal };
    }
    if (f.naturalKey === currentField?.naturalKey) {
      return { ...f, ordinal: field.ordinal };
    }
    return f;
  });
