import { ChangeEvent, FC, memo, useCallback } from 'react';

import { SLASettingsPriority } from '@entities/contract/model';
import { Priority, TicketTypeSettings } from '@entities/ticket/model/types';
import { PriorityBlock } from '@entities/ticket/ui';
import { Checkbox } from '@shared';

import { SLARowType, SLASettings } from '../../../../model/types';
import { Cell } from '../Cell';
import { Row } from '../Row';
import { SlaCell } from '../SlaCell';

interface SlaRowProps {
  slaSettings: SLARowType;
  priority?: SLASettingsPriority;
  canEdit?: boolean;
  onRowDataChange?: (settings: SLARowType, priority: Priority) => void;
  rowActive?: boolean;
  isDefaultRow?: boolean;
  slaTicketTypes?: TicketTypeSettings[];
  changeRowActive?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SlaRow: FC<SlaRowProps> = memo(
  ({
    slaSettings = {},
    priority,
    canEdit = false,
    onRowDataChange,
    rowActive = false,
    changeRowActive,
    slaTicketTypes = [],
    isDefaultRow = false,
  }) => {
    const getIsRowDisabled = () => {
      if (canEdit) {
        return !rowActive;
      }
      return isDefaultRow;
    };
    const onCellDataChange = useCallback(
      (setting: SLASettings, cellType: string) => {
        if (onRowDataChange && priority?.name) {
          onRowDataChange(
            { ...slaSettings, [cellType]: setting },
            priority.name as Priority
          );
        }
      },
      [onRowDataChange, slaSettings]
    );

    return (
      <Row disabled={getIsRowDisabled()}>
        {canEdit && (
          <Cell>
            <Checkbox checked={rowActive} onChange={changeRowActive} />
          </Cell>
        )}
        <Cell>
          <PriorityBlock priority={priority?.name as Priority} />
        </Cell>
        {slaTicketTypes.map(({ title, ticketTypeId }) => (
          <SlaCell
            setting={slaSettings[title]}
            key={ticketTypeId}
            cellType={title}
            disabled={!rowActive}
            canEdit={canEdit}
            onCellDataChange={onCellDataChange}
          />
        ))}
      </Row>
    );
  }
);
