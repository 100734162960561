import {
  all,
  call,
  put,
  select,
  StrictEffect,
  takeEvery,
} from 'redux-saga/effects';

import { resetAccessesState } from '@entities/accesses/model/actions';
import { resetActionsState } from '@entities/actions/model/actions';
import { fetchPermissionsRequest } from '@entities/auth/model/actions';
import { getFilterOrganizationId } from '@entities/organization/model/selectors';
import { getCurrentRoleId } from '@entities/roles/model/selectors';
import { getCurrentGroupId } from '@entities/work-group/model';
import {
  ActionForAlertTypes,
  createError,
  getErrorAlert,
  getSuccessAlert,
  ResponseWithMeta,
  setAlert,
} from '@shared';

import { requests } from '../api';

import {
  fetchResponsibilitiesAddSuccess,
  fetchResponsibilitiesRequest,
  fetchResponsibilitiesSuccess,
  fetchResponsibilityByIdRequest,
  fetchResponsibilityByIdSuccess,
  hideResponsibilitiesAddLoading,
  hideResponsibilitiesLoading,
  resetCurrentResponsibility,
  resetResponsibilitiesState,
  setCurrentResponsibilityId,
  showResponsibilitiesAddLoading,
  showResponsibilitiesLoading,
} from './actions';
import {
  CreateResponsibilityAction,
  DeleteResponsibilityRequest,
  FetchResponsibilityByIdRequestAction,
  Responsibilities,
  UpdateResponsibilityAction,
} from './actionTypes';
import {
  getCurrentResponsibilityId,
  getPropsResponsibilities,
  getPropsResponsibilitiesAdd,
  getResponsibilitiesAddFilter,
  getResponsibilitiesFilter,
} from './selectors';
import {
  CreateResponsibilityToRequest,
  CreateResponsibilityType,
  Responsibility,
  ResponsibilityFilter,
  ResponsibilityFilterToRequest,
} from './types';

const ENTITY_RESPONSIBILITY = 'Полномочие';

export const getFilterResponsibilitiesToRequest = (
  filter: ResponsibilityFilter
): ResponsibilityFilterToRequest => {
  const { organizationId } = filter;
  return {
    ...filter,
    organizationId:
      organizationId && !Array.isArray(organizationId)
        ? organizationId.value
        : undefined,
  };
};

export const getResponsibilitiesDataToRequest = (
  data: CreateResponsibilityType
): CreateResponsibilityToRequest => {
  const { organizationId } = data;
  return {
    ...data,
    organizationId:
      organizationId && !Array.isArray(organizationId)
        ? organizationId.value
        : undefined,
  };
};

function* responsibilitiesFetch() {
  try {
    const {
      pageNum,
      pageSize,
      sortResponsibilities,
    }: ReturnType<typeof getPropsResponsibilities> = yield select(
      getPropsResponsibilities
    );
    const filter: ReturnType<typeof getResponsibilitiesFilter> = yield select(
      getResponsibilitiesFilter
    );
    yield put(showResponsibilitiesLoading());
    const responsibilities: ResponseWithMeta<Responsibility[]> = yield call(
      requests.fetchResponsibilities,
      pageNum,
      pageSize,
      sortResponsibilities,
      getFilterResponsibilitiesToRequest(filter)
    );
    yield put(fetchResponsibilitiesSuccess(responsibilities));
    yield put(hideResponsibilitiesLoading());
  } catch (e) {
    createError(e);
    yield put(hideResponsibilitiesLoading());
  }
}

function* fetchByGroupId() {
  try {
    const {
      pageNum,
      pageSize,
      sortResponsibilities,
    }: ReturnType<typeof getPropsResponsibilities> = yield select(
      getPropsResponsibilities
    );
    const filter: ReturnType<typeof getResponsibilitiesFilter> = yield select(
      getResponsibilitiesFilter
    );
    const groupId: ReturnType<typeof getCurrentGroupId> = yield select(
      getCurrentGroupId
    );
    if (groupId) {
      yield put(showResponsibilitiesLoading());
      const responsibilities: ResponseWithMeta<Responsibility[]> = yield call(
        requests.fetchResponsibilityByGroupId,
        groupId,
        pageNum,
        pageSize,
        sortResponsibilities,
        getFilterResponsibilitiesToRequest(filter)
      );
      yield put(fetchResponsibilitiesSuccess(responsibilities));
    }

    yield put(hideResponsibilitiesLoading());
  } catch (e) {
    createError(e);
    yield put(hideResponsibilitiesLoading());
  }
}

function* fetchByRoleId() {
  try {
    const {
      pageNum,
      pageSize,
      sortResponsibilities,
    }: ReturnType<typeof getPropsResponsibilities> = yield select(
      getPropsResponsibilities
    );
    const filter: ReturnType<typeof getResponsibilitiesFilter> = yield select(
      getResponsibilitiesFilter
    );
    const roleId: ReturnType<typeof getCurrentRoleId> = yield select(
      getCurrentRoleId
    );
    if (roleId) {
      yield put(showResponsibilitiesLoading());
      const responsibilities: ResponseWithMeta<Responsibility[]> = yield call(
        requests.fetchResponsibilityByRoleId,
        roleId,
        pageNum,
        pageSize,
        sortResponsibilities,
        getFilterResponsibilitiesToRequest(filter)
      );
      yield put(fetchResponsibilitiesSuccess(responsibilities));
    }
    yield put(hideResponsibilitiesLoading());
  } catch (e) {
    createError(e);
    yield put(hideResponsibilitiesLoading());
  }
}

function* createResponsibility({ payload }: CreateResponsibilityAction) {
  try {
    const { actionList, ...other } = payload;
    const { id }: Responsibility = yield call(
      requests.createResponsibility,
      getResponsibilitiesDataToRequest(other)
    );
    yield call(requests.addAllActionsResponsibility, id);
    yield put(
      setAlert(
        getSuccessAlert(ENTITY_RESPONSIBILITY, ActionForAlertTypes.CREATE)
      )
    );
    if (actionList) {
      yield call(requests.updateResponsibilityAction, id, actionList);
    }
    yield all([
      put(fetchResponsibilitiesRequest()),
      put(setCurrentResponsibilityId(id)),
      put(fetchResponsibilityByIdRequest(id)),
    ]);
  } catch (e) {
    yield put(
      setAlert(getErrorAlert(ENTITY_RESPONSIBILITY, ActionForAlertTypes.CREATE))
    );
    createError(e);
  }
}

function* respAddFetch() {
  try {
    const filter: ReturnType<typeof getResponsibilitiesAddFilter> =
      yield select(getResponsibilitiesAddFilter);
    const orgId: ReturnType<typeof getFilterOrganizationId> = yield select(
      getFilterOrganizationId
    );
    const {
      pageNum,
      pageSize,
    }: ReturnType<typeof getPropsResponsibilitiesAdd> = yield select(
      getPropsResponsibilitiesAdd
    );
    if (orgId) {
      yield put(showResponsibilitiesAddLoading());
      const responsibilities: ResponseWithMeta<Responsibility[]> = yield call(
        requests.fetchResponsibilities,
        pageNum,
        pageSize,
        undefined,
        {
          ...filter,
          organizationId: orgId,
        }
      );
      yield put(fetchResponsibilitiesAddSuccess(responsibilities));
    }
    yield put(hideResponsibilitiesAddLoading());
  } catch (e) {
    createError(e);
    yield put(hideResponsibilitiesAddLoading());
  }
}

function* updateResponsibility({ payload }: UpdateResponsibilityAction) {
  try {
    const { actions, responsibilityData } = payload;
    const respId: ReturnType<typeof getCurrentResponsibilityId> = yield select(
      getCurrentResponsibilityId
    );
    if (responsibilityData) {
      yield call(
        requests.updateResponsibilityData,
        getResponsibilitiesDataToRequest(responsibilityData)
      );
    }
    if (respId) {
      yield call(requests.updateResponsibilityAction, respId, actions);
      yield put(
        setAlert(
          getSuccessAlert(ENTITY_RESPONSIBILITY, ActionForAlertTypes.EDIT)
        )
      );
      yield put(resetResponsibilitiesState());
      yield put(resetAccessesState());
      yield put(resetActionsState());
      yield call(responsibilitiesFetch);
      yield put(fetchPermissionsRequest());
    }
  } catch (e) {
    yield put(
      setAlert(getErrorAlert(ENTITY_RESPONSIBILITY, ActionForAlertTypes.EDIT))
    );
    createError(e);
  }
}

function* fetchCurrentResponsibility({
  payload,
}: FetchResponsibilityByIdRequestAction) {
  try {
    if (payload) {
      const responsibility: Responsibility = yield call(
        requests.fetchResponsibilityById,
        payload
      );
      if (responsibility) {
        yield put(fetchResponsibilityByIdSuccess(responsibility));
      }
    }
  } catch (e) {
    createError(e);
  }
}

function* deleteResponsibility({ payload }: DeleteResponsibilityRequest) {
  try {
    yield call(requests.deleteResponsibility, payload);
    yield put(
      setAlert(
        getSuccessAlert(ENTITY_RESPONSIBILITY, ActionForAlertTypes.DELETE)
      )
    );
    yield put(resetCurrentResponsibility());
    yield put(resetAccessesState());
    yield put(resetActionsState());
    yield call(responsibilitiesFetch);
  } catch (e) {
    yield put(
      setAlert(getErrorAlert(ENTITY_RESPONSIBILITY, ActionForAlertTypes.DELETE))
    );
    createError(e);
  }
}

export function* responsibilitiesSaga(): Generator<StrictEffect> {
  yield takeEvery(
    Responsibilities.FETCH_RESPONSIBILITIES_REQUEST,
    responsibilitiesFetch
  );
  yield takeEvery(
    Responsibilities.FETCH_RESPONSIBILITY_BY_GROUP_ID_REQUEST,
    fetchByGroupId
  );
  yield takeEvery(
    Responsibilities.FETCH_RESPONSIBILITY_BY_ROLE_ID_REQUEST,
    fetchByRoleId
  );
  yield takeEvery(Responsibilities.CREATE_RESPONSIBILITY, createResponsibility);
  yield takeEvery(
    Responsibilities.FETCH_RESPONSIBILITIES_ADD_REQUEST,
    respAddFetch
  );
  yield takeEvery(Responsibilities.UPDATE_RESPONSIBILITY, updateResponsibility);
  yield takeEvery(
    Responsibilities.FETCH_RESPONSIBILITY_BY_ID_REQUEST,
    fetchCurrentResponsibility
  );
  yield takeEvery(
    Responsibilities.DELETE_RESPONSIBILITY_BY_ID_REQUEST,
    deleteResponsibility
  );
}
