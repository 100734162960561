import { format } from 'date-fns';

import styles from '../../ui/NotificationsList/NotificationsList.module.scss';

export const formatDateTitle = (date: string) => {
  const today = format(new Date(), 'yyyy/MM/dd');
  const yesterday = format(+new Date() - 1000 * 3600 * 24, 'yyyy/MM/dd');
  const returnDate = `${format(new Date(date), 'dd')} ${format(
    new Date(date),
    'MMMM'
  )}`;
  if (date === today) {
    return (
      <p className={styles.notificationsList__date}>
        <span className={styles.notificationsList__date_black}>Сегодня </span>
        {returnDate}
      </p>
    );
  }
  if (date === yesterday) {
    return (
      <p className={styles.notificationsList__date}>
        <span className={styles.notificationsList__date_black}>Вчера </span>
        {returnDate}
      </p>
    );
  }
  return <p className={styles.notificationsList__date}>{returnDate}</p>;
};
