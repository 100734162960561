import cn from 'clsx';
import { FC, ReactNode } from 'react';
// import { useTranslation } from 'react-i18next';

// import { Languages } from '../../lib';

// import { getTicketTypeTextMap } from './lib';
// import { ProcedureType } from './model';
import styles from './Tag.module.scss';

export interface TagProps {
  text?: string | ReactNode;
  // ticketType?: ProcedureType;
  isSolution?: boolean;
  className?: string;
}

// TODO feature/tsp-2629 заменить на Tag из ui-kit
export const Tag: FC<TagProps> = ({
  text,
  // ticketType,
  isSolution,
  className,
}) => (
  // const { i18n } = useTranslation();

  // const title = ticketType
  //   ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     // @ts-ignore
  //     getTicketTypeTextMap(i18n.language as Languages)[ticketType]
  //   : text;

  <div
    className={cn(
      styles.tag,
      {
        [styles.tag_solution]: isSolution,
      },
      className
    )}
  >
    {text}
  </div>
);
