import { System } from '@entities/system/model';

export const getDataTable = (systems?: System[]) =>
  systems?.map((system) => {
    const { title, description, id, deleted } = system;
    return {
      id,
      title: { text: title },
      description,
      deleted,
      tooltipDeletedRow: 'Система удалена',
    };
  }) || [];
