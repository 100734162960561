import cn from 'clsx';
import { nanoid } from 'nanoid';
import { BaseSyntheticEvent } from 'react';
import { Controller } from 'react-hook-form';

import {
  AlertItem,
  AlertTypes,
  ArrowHorizontalIcon,
  Button,
  Card,
  DataEmpty,
  Input,
  JiraLogoIcon,
  PenIcon,
  PopoverButton,
  PopoverContainer,
  Select,
  Size,
  T1Icon,
  Typography,
  TypographyNew,
  TypographyVariants,
} from '@shared';

import { JIRA_TABS } from '../../../../config';
import { useJiraIntegrationsCurrent } from '../../../../lib';
import { JiraIntegrationTabType } from '../../../../model';
import { JiraSynchronization } from '../../../JiraSynchronization';
import { JiraTabs } from '../../../JiraTabs';
import { JiraIntegrationsFieldsIntegrations } from '../JiraIntegrationsFieldsIntegrations';
import { JiraIntegrationsPriorities } from '../JiraIntegrationsPriorities';
import { JiraIntegrationsStatuses } from '../JiraIntegrationsStatuses';
import { JiraIntegrationsTicketTypes } from '../JiraIntegrationsTicketTypes';

import styles from './JiraIntegrationsCurrent.module.scss';

type PropsMapType = {
  title: string;
  onCancel: () => void;
  onSubmit: (e?: BaseSyntheticEvent) => void;
  disabled: boolean;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const JiraIntegrationsCurrent = () => {
  const form = useJiraIntegrationsCurrent();
  const { state, methods } = form;

  const {
    jiraIntegrationId,
    isAddDisabled,
    systemId,
    isEditMode,
    loadingInfo,
    alertIsOpen,
    control,
    ticketTypeId,
    jiraPrioritiesOptions,
    priorities,
    jiraTicketTypesForCurrentOptions,
    jiraStatusesOptions,
    statusMapOptions,
    statuses,
    integrationDisabledSubmit,
    syncDisabledSubmit,
    fieldsIntegrations,
    ticketTypesOptions,
    tab,
    synchronizationProps,
    isPopoverOpen,
    jiraTicketType,
    addNewTicketType,
  } = state;

  const {
    setIsAddDisabled,
    onCloseAlert,
    submitHandler,
    getIsPriorityAvailable,
    onChangePriorities,
    onChangeStatuses,
    toggleIntegrationEditMode,
    toggleSyncEditMode,
    editJiraSynchronizationHandler,
    togglePopover,
    toggleTab,
    dropContentHandler,
    fetchJiraStatuses,
    fetchJiraStatusesByPropertyId,
    resetStatuses,
    onChangeTicketTypeId,
  } = methods;

  const propsMap: Record<JiraIntegrationTabType, PropsMapType> = {
    [JiraIntegrationTabType.integration]: {
      title: 'интеграцию',
      onCancel: toggleIntegrationEditMode,
      onSubmit: submitHandler,
      disabled: integrationDisabledSubmit,
    },
    [JiraIntegrationTabType.synchronization]: {
      title: 'синхронизацию',
      onCancel: toggleSyncEditMode,
      onSubmit: editJiraSynchronizationHandler,
      disabled: syncDisabledSubmit,
    },
  };

  const jiraSyncProps = {
    state: {
      control: synchronizationProps.control,
      synchronizationProps,
    },
    methods: {
      getValues: synchronizationProps.getValues,
    },
  };

  const warningAlert = alertIsOpen && (
    <AlertItem
      alert={{
        id: nanoid(),
        type: AlertTypes.WARNING,
        message: (
          <TypographyNew variant="h6">
            Проверьте совпадение статусной модели с Jira
          </TypographyNew>
        ),
      }}
      className={styles.jiraIntegrationsCurrent__alert}
      onClose={onCloseAlert}
    />
  );

  const dropContent = (
    <PopoverContainer>
      <PopoverButton
        onClick={dropContentHandler}
        icon={<PenIcon className={styles.jiraIntegrationsCurrent__editIcon} />}
        disabled={!jiraIntegrationId}
      >
        Редактировать
      </PopoverButton>
    </PopoverContainer>
  );

  const dataEmpty = (
    <DataEmpty className={styles.jiraIntegrationsCurrent__emptyData} />
  );

  const content = (
    <div className={styles.jiraIntegrationsCurrent__content}>
      {!systemId && !loadingInfo && dataEmpty}
      {systemId && (
        <div className={styles.jiraIntegrationsCurrent__wrapper}>
          {warningAlert}
          <div className={styles.jiraIntegrationsCurrent__inputs}>
            <div className={styles.jiraIntegrationsCurrent__header}>
              <div className={styles.jiraIntegrationsCurrent__headerElement}>
                <T1Icon
                  className={styles.jiraIntegrationsCurrent__headerIcon}
                />
                <Typography
                  variant={TypographyVariants.h4}
                  className={styles.jiraIntegrationsCurrent__headerTitle}
                >
                  SupPortal
                </Typography>
              </div>
              <div className={styles.jiraIntegrationsCurrent__headerElement}>
                <JiraLogoIcon
                  className={styles.jiraIntegrationsCurrent__headerIcon}
                />
                <Typography
                  variant={TypographyVariants.h4}
                  className={styles.jiraIntegrationsCurrent__headerTitle}
                >
                  Jira
                </Typography>
              </div>
            </div>
            <div className={styles.jiraIntegrationsCurrent__infoLineWrapper}>
              <Input
                size={Size.s}
                value="Исполнитель"
                className={styles.jiraIntegrationsCurrent__input}
                disabled
              />
              <ArrowHorizontalIcon
                className={styles.jiraIntegrationsCurrent__arrowIcon}
              />
              <Input
                size={Size.s}
                value="Исполнитель"
                className={styles.jiraIntegrationsCurrent__input}
                disabled
              />
            </div>

            <Controller
              control={control}
              rules={{ required: true }}
              name="ticketTypeId"
              render={({ field }) => (
                <Select<string>
                  label="Тип тикета"
                  mobileModalTitle="тип тикета"
                  options={ticketTypesOptions}
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                    onChangeTicketTypeId(value);
                  }}
                />
              )}
            />

            {ticketTypeId && (
              <>
                <JiraIntegrationsTicketTypes
                  ticketTypeId={ticketTypeId}
                  jiraTicketTypesOptions={jiraTicketTypesForCurrentOptions}
                  control={control}
                  isEditMode={isEditMode}
                  fetchJiraStatuses={fetchJiraStatuses}
                  fetchJiraStatusesByPropertyId={fetchJiraStatusesByPropertyId}
                  resetStatuses={resetStatuses}
                />
                <JiraIntegrationsStatuses
                  jiraStatusesOptions={jiraStatusesOptions}
                  isEditMode={isEditMode}
                  onChangeStatuses={onChangeStatuses}
                  statusMapOptions={statusMapOptions}
                  statuses={statuses}
                  jiraTicketType={jiraTicketType}
                />
                <JiraIntegrationsPriorities
                  isEditMode={isEditMode}
                  getIsPriorityAvailable={getIsPriorityAvailable}
                  jiraPrioritiesOptions={jiraPrioritiesOptions}
                  priorities={priorities}
                  onChangePriorities={onChangePriorities}
                  addNewTicketType={addNewTicketType}
                />
                <JiraIntegrationsFieldsIntegrations
                  isAddDisabled={isAddDisabled}
                  closeEditHandler={() => setIsAddDisabled(false)}
                  fieldsIntegrations={fieldsIntegrations}
                  className={styles.jiraIntegrationsCurrent__fieldsListWrapper}
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );

  const getComponent = () => {
    const componentList: Record<JiraIntegrationTabType, JSX.Element> = {
      [JiraIntegrationTabType.integration]: content,
      [JiraIntegrationTabType.synchronization]: (
        <JiraSynchronization
          jiraSyncProps={jiraSyncProps}
          preview={!isEditMode}
        />
      ),
    };

    return componentList[tab];
  };

  const editComponent = (
    <div className={styles.jiraIntegrationsCurrent__editBlock}>
      <div className={styles.jiraIntegrationsCurrent__editBlockHeader}>
        <Typography variant={TypographyVariants.h3}>
          {`Редактировать ${propsMap[tab].title}`}
        </Typography>
      </div>
      <div className={styles.jiraIntegrationsCurrent__editBlockContainer}>
        {getComponent()}
      </div>
      <div className={styles.jiraIntegrationsCurrent__editBlockFooter}>
        <div className={cn(styles.jiraIntegrationsCurrent__editBlockButtons)}>
          <Button onClick={propsMap[tab].onCancel} appearance="flat">
            Отмена
          </Button>
          <Button
            onClick={propsMap[tab].onSubmit}
            disabled={propsMap[tab].disabled}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </div>
  );

  const previewComponent = (
    <Card className={styles.jiraIntegrationsCurrent__card}>
      {!jiraIntegrationId && dataEmpty}
      {jiraIntegrationId && (
        <JiraTabs
          tabs={JIRA_TABS}
          dropContentProps={{ dropContent, togglePopover, isPopoverOpen }}
          createHandler={() => setIsAddDisabled(true)}
          createDisabled={isAddDisabled || !systemId || !ticketTypeId}
          tabType={tab}
          onClick={toggleTab}
          activeKey={tab}
        >
          {getComponent()}
        </JiraTabs>
      )}
    </Card>
  );

  return isEditMode ? editComponent : previewComponent;
};
