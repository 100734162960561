import { FC } from 'react';

import { CustomFieldType } from '@entities/custom-fields';
import {
  Create,
  SettingFieldsList,
  Typography,
  TypographyVariants,
} from '@shared';

import { useTicketsSettingFieldsList } from '../../lib/hooks';

import styles from './TicketsSettingFields.module.scss';

export const TicketsSettingFields: FC = () => {
  const {
    defaultFieldsList,
    setDefaultFieldsList,
    dragDefaultFieldsStartHandler,
    dropDefaultFieldsHandler,
    checkedAllDefaultFields,
    onChangeDefaultFieldsList,
    customFieldsList,
    setCustomFieldsList,
    dragCustomFieldsStartHandler,
    dropCustomFieldsHandler,
    checkedAllCustomFields,
    onChangeCustomFieldsList,
    formSubmitHandler,
    isSettingFieldsOpen,
    toggleSettingFieldsModal,
  } = useTicketsSettingFieldsList();

  return (
    <Create
      toggleModal={toggleSettingFieldsModal}
      isModal={!!isSettingFieldsOpen}
      title="Настройка столбцов таблицы"
      subModalText="настройку столбцов таблицы"
      createTitle="Сохранить"
      onSubmit={formSubmitHandler}
    >
      <>
        <SettingFieldsList<CustomFieldType>
          fieldsList={defaultFieldsList}
          handleChange={onChangeDefaultFieldsList}
          setFields={setDefaultFieldsList}
          checkedAllFields={checkedAllDefaultFields}
          dragStartHandler={dragDefaultFieldsStartHandler}
          dropHandler={dropDefaultFieldsHandler}
        />
        <Typography
          className={styles.ticketsSettingFields__title}
          variant={TypographyVariants.h5}
        >
          Кастомные поля
        </Typography>
        <SettingFieldsList<CustomFieldType>
          fieldsList={customFieldsList}
          handleChange={onChangeCustomFieldsList}
          setFields={setCustomFieldsList}
          checkedAllFields={checkedAllCustomFields}
          dragStartHandler={dragCustomFieldsStartHandler}
          dropHandler={dropCustomFieldsHandler}
        />
      </>
    </Create>
  );
};
