import cn from 'clsx';
import DOMPurify from 'dompurify';
import { forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { AttachmentDeleteType } from '@entities/attachment';
import {
  ActionList,
  getCustomerAttribute,
  getIsClient,
  getIsMyTicket,
  getIsOrgTypePartner,
  getSpecialistAttribute,
} from '@entities/auth';
import { SimilarSolutions } from '@entities/similar-solutions';
import {
  getIsAccessedTicketDetailAction,
  getJiraUrl,
  getTicket,
  getTicketSystem,
  PreviewImageModal,
  SYSTEM_TICKET_INFO,
  TicketAttachments,
  TicketChip,
  TicketCustomFieldsInfo,
  TicketInfoDropContent,
  TicketStatus,
  TicketTabsBlock,
  TicketTags,
  useTicket,
  useTicketAttachments,
  useTicketInfoTabs,
} from '@entities/ticket';
import {
  ApproveOrCancel,
  Button,
  Card,
  InfoBlock,
  JiraLogoIcon,
  Loader,
  PlusIcon,
  SearchIcon,
  Size,
  Solution,
  System,
  Tooltip,
  Typography,
  TypographyVariants,
  UsersGroupIcon,
} from '@shared';

import { SpecialistForm } from '../SpecialistForm';
import { TicketType } from '../TicketType';

import styles from './TicketInfo.module.scss';

interface TicketInfoProps {
  className?: string;
  isLoading?: boolean;
}

export const TicketInfo = forwardRef<HTMLDivElement, TicketInfoProps>(
  // eslint-disable-next-line sonarjs/cognitive-complexity
  ({ className, isLoading }, ref) => {
    const ticket = useSelector(getTicket);
    const isClient = useSelector(getIsClient);

    const isAccessToTakeTicketToWork = useSelector(
      getIsAccessedTicketDetailAction(ActionList.TakeTicketToWork)
    );
    const isAccessToAssigningTicketToSpecialist = useSelector(
      getIsAccessedTicketDetailAction(ActionList.AssigningTicketToSpecialist)
    );
    const jiraUrl = useSelector(getJiraUrl);
    const ticketSystem = useSelector(getTicketSystem);
    const customerAttribute = useSelector(getCustomerAttribute);
    const specialistAttribute = useSelector(getSpecialistAttribute);
    const isPartner = useSelector(getIsOrgTypePartner);
    const isMyTicket = useSelector(getIsMyTicket(ticket));

    const {
      isImageModal,
      initialImageIndex,
      attachment,
      imageFiles,
      documentFiles,
      toggleIsImageModal,
      handleInitialImageIndex,
    } = useTicketAttachments();

    const isTicketDeleted = ticket?.isDeleted;

    const [isModal, setIsModal] = useState<boolean>(false);

    const hasAccessToSimilarSolutions = !isClient && isAccessToTakeTicketToWork;

    const showBtnToWork = isPartner
      ? customerAttribute && specialistAttribute && !isMyTicket
      : !isClient;

    const toggleIsModal = () => {
      setIsModal((prevState) => !prevState);
    };

    const {
      isTakeToWorkDisabled,
      isJiraButtonDisabled,
      setTicketToWorkHandler,
      restoreTicketHandler,
      setTicketToWorkAndCreateTaskInJiraHandler,
      toggleRestoreModal,
      isSeeTags,
      isEditTags,
      isRestoreModal,
      isAccessToRestore,
    } = useTicket();

    const { mode, ticketInfoTabs } = useTicketInfoTabs();

    const onApproveRestoreModal = () => {
      restoreTicketHandler?.();
    };

    const solution = ticket?.customStatus?.defaultStatus ===
      TicketStatus.CLOSE &&
      ticket.solution?.textSolution && (
        <Solution
          text={ticket.solution?.textSolution}
          className={styles.ticketInfo__solution}
        />
      );

    const setSpecAndGoToWork = showBtnToWork && !isTicketDeleted && (
      <div className={styles.ticketInfo__buttonsWrapper}>
        {isAccessToTakeTicketToWork && (
          <Button
            onClick={setTicketToWorkHandler}
            disabled={isTakeToWorkDisabled}
            icon={<PlusIcon width={20} />}
            type="button"
            className={styles.ticketInfo__takeInProcess}
            extraActionContent={
              !isJiraButtonDisabled ? (
                <TicketInfoDropContent
                  onClick={setTicketToWorkAndCreateTaskInJiraHandler}
                  disabled={isJiraButtonDisabled}
                />
              ) : undefined
            }
          >
            Взять в работу
          </Button>
        )}
        {isAccessToAssigningTicketToSpecialist && <SpecialistForm />}
      </div>
    );

    const restoreButton = isTicketDeleted && (
      <Button
        onClick={toggleRestoreModal}
        type="button"
        disabled={!isAccessToRestore}
        className={styles.ticketInfo__buttonRestore}
      >
        Восстановить
      </Button>
    );

    const systemInfo = (
      <div className={styles.ticketInfo__systemInfo}>
        <div className={styles.ticketInfo__systemInfoItem}>
          <InfoBlock
            mainTitle="Система"
            info={ticket?.systemId?.value}
            icon={<System className={styles.ticketInfo__iconInner} />}
            classNameInfo={styles.ticketInfo__blockInfo}
            dataInfoIcon={SYSTEM_TICKET_INFO}
          />
          <Tooltip
            id={SYSTEM_TICKET_INFO}
            className={styles.ticketInfo__tooltipSystem}
          >
            <Typography className={styles.ticketInfo__tooltipSystemVersion}>
              {ticketSystem?.versionTitle}
            </Typography>
            <Typography>{ticketSystem?.description}</Typography>
          </Tooltip>
        </div>
        <div className={styles.ticketInfo__systemInfoItem}>
          <InfoBlock
            mainTitle="Среда"
            info={ticket?.environmentId?.value}
            icon={<UsersGroupIcon className={styles.ticketInfo__iconInner} />}
            classNameInfo={styles.ticketInfo__blockInfo}
          />
        </div>
        {jiraUrl && (
          <div className={styles.ticketInfo__systemInfoItem}>
            <InfoBlock
              mainTitle="Jira"
              info={
                <a
                  href={jiraUrl}
                  target="_blank"
                  className={styles.ticketInfo__jiraLink}
                  rel="noreferrer"
                >
                  {jiraUrl}
                </a>
              }
              icon={<JiraLogoIcon className={styles.ticketInfo__iconInner} />}
              classNameInfo={styles.ticketInfo__blockInfo}
            />
          </div>
        )}
      </div>
    );

    const tags = isSeeTags && <TicketTags canEditTags={isEditTags} />;

    const mentionedTickets = !!ticket?.mentionedTickets?.length && (
      <div className={styles.ticketInfo__tickets}>
        {ticket.mentionedTickets.map(({ key, value }) => (
          <TicketChip key={key} id={key} number={value} />
        ))}
      </div>
    );

    const attachments = (
      <div className={styles.ticketInfo__ticketAttachments}>
        <TicketAttachments
          toggleIsModal={toggleIsImageModal}
          handleInitialImageIndex={handleInitialImageIndex}
          attachment={attachment}
          imageFiles={imageFiles}
          documentFiles={documentFiles}
          size={Size.s}
          attachmentDeleteType={AttachmentDeleteType.INFO}
        />
      </div>
    );

    const imageModal = isImageModal && (
      <PreviewImageModal
        toggleModal={toggleIsImageModal}
        initialImageIndex={initialImageIndex}
        imageFiles={imageFiles}
      />
    );

    const ticketMainInfo = (
      <>
        <div className={styles.ticketInfo__inner}>
          <TicketType />
          <div className={styles.ticketInfo__titleWrapper}>
            <Typography
              variant={TypographyVariants.h3}
              className={styles.ticketInfo__title}
            >
              {ticket?.theme}
            </Typography>
            {hasAccessToSimilarSolutions && (
              <button
                onClick={toggleIsModal}
                className={styles.ticketInfo__similarSolutions}
              >
                <SearchIcon
                  className={styles.ticketInfo__similarSolutionsIcon}
                />
                <Typography
                  variant={TypographyVariants.h5}
                  className={styles.ticketInfo__similarSolutionsTitle}
                >
                  Искать похожие решения
                </Typography>
              </button>
            )}
          </div>
          <p
            className={cn('ck-content', styles.ticketInfo__text)}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(ticket?.description || ''),
            }}
          />
          {mentionedTickets}
          {imageModal}
          {attachments}
          {solution}
          {systemInfo}
          {setSpecAndGoToWork}
          {restoreButton}
        </div>
        {tags}
      </>
    );

    const content = !isLoading && (
      <>
        <TicketTabsBlock tabs={ticketInfoTabs} />
        {mode === 'info' ? ticketMainInfo : <TicketCustomFieldsInfo />}
        <SimilarSolutions isOpen={isModal} toggleIsOpen={toggleIsModal} />
      </>
    );

    const loader = isLoading && (
      <div className={styles.ticketInfo__loaderWrapper}>
        <Loader />
      </div>
    );

    return (
      <Card ref={ref} className={cn(styles.ticketInfo, className)}>
        {loader}
        {content}
        <ApproveOrCancel
          onApprove={onApproveRestoreModal}
          isModal={isRestoreModal}
          toggleModal={toggleRestoreModal}
          text={`Вы уверены, что хотите восстановить тикет ${ticket?.number}?`}
        />
      </Card>
    );
  }
);
