import { Edge, Node } from 'reactflow';

import { TicketStatus, TicketType } from '@entities/ticket/model/types';
import { Nullable, SelectOption, ValueType } from '@shared';

import { StatusesBuilderReducerState } from './slice';

export type StatusesBuilderState = {
  statusesBuilder: StatusesBuilderReducerState;
};

export type Workflow = {
  systemId: string;
  systemTitle?: string;
  version?: number;
  nodes: Node<NodeType>[];
  edges: Edge<EdgeType>[];
  typeId?: string;
};

export type NodeType = {
  label: string;
  jumpCondition?: string[];
  color: string;
  accessibility: Accessibility;
  isNotifications: boolean;
  isSLA: boolean;
  isComment: boolean;
  commentTypes?: string[];
  deletable?: boolean;
  modified?: boolean;
  hideTopHandle?: boolean;
  hideBottomHandle?: boolean;
  hideLeftHandle?: boolean;
  hideRightHandle?: boolean;
  defaultStatus?: TicketStatus;
};

export enum Accessibility {
  CLIENT = 'CLIENT',
  SPECIALIST = 'SPECIALIST',
  ALL = 'ALL',
}

export type EdgeType = {
  protected?: boolean;
};

export interface WorkflowParams
  extends Pick<Workflow, 'typeId' | 'systemId' | 'version'> {}

export type SystemsFilterToRequest = {
  title?: string;
  organizationId?: string;
};

export type SystemsFilter = Omit<SystemsFilterToRequest, 'organizationId'> & {
  organizationId?: ValueType<string>;
};

export interface StatusForm extends NodeType {}

interface CopyStatusFrom {
  systemId?: string;
  typeId?: string;
}

export interface CopyStatusTo {
  systemId?: string;
  typeIds?: string[];
}

export interface StatusCopyModelForm {
  systemId: Nullable<SelectOption>;
  typeId: Nullable<SelectOption>;
  systemIds: Array<SelectOption>;
  typeIds: Array<SelectOption>;
}

export interface StatusCopyModelPayload {
  copyFrom: CopyStatusFrom;
  copyTo: CopyStatusTo[];
}

export type SystemsTicketTypes = Array<{
  systemId: string;
  typeList: TicketType[];
}>;
