import { createSelector } from 'reselect';

import {
  JiraState,
  JiraSynchronization,
  JiraSynchronizationCreateForm,
} from '@entities/jira-integrations';

import { getSyncFilterRules } from '../lib/utils';

export const getJiraSyncFilter = (state: JiraState) =>
  state.jira.jiraSyncFilter;

export const getJiraSyncFilterForCurrentOptions = createSelector<
  JiraState,
  JiraSynchronization | undefined,
  JiraSynchronizationCreateForm
>([getJiraSyncFilter], (filter) => getSyncFilterRules(filter));
