import { call, debounce, put, select, takeEvery } from 'redux-saga/effects';

import {
  getFilterValues,
  getPrepareFilterTicketsToRequest,
  TicketsFilterForRender,
} from '@entities/ticket';
import { Ticket } from '@entities/ticket/model/types';
import { createError, DEFAULT_DEBOUNCE_DELAY, ResponseWithMeta } from '@shared';

import { requestsHistory } from '../../api/desktop-history';
import { mergeDesktopFilters } from '../../lib';
import { getDesktopFilters } from '../selectors';
import {
  DesktopFilterRequest,
  DesktopFilters,
  TicketsStatistic,
} from '../types';

import {
  getHistoryPageNumber,
  getHistoryPageSize,
  getHistorySort,
} from './selectors';
import {
  fetchTicketsRequestHistory,
  fetchTicketsStatisticRequestHistory,
  fetchTicketsStatisticSuccessHistory,
  fetchTicketsSuccessHistory,
  setIsTicketsLoadingHistory,
} from './slice';

function* ticketsFetch() {
  try {
    yield put(setIsTicketsLoadingHistory(true));
    const currentPage: ReturnType<typeof getHistoryPageNumber> = yield select(
      getHistoryPageNumber
    );
    const pageSize: ReturnType<typeof getHistoryPageSize> = yield select(
      getHistoryPageSize
    );
    const sort: ReturnType<typeof getHistorySort> = yield select(
      getHistorySort
    );
    const desktopFilter: DesktopFilters = yield select(getDesktopFilters);
    const ticketsReportFilter: TicketsFilterForRender = yield select(
      getFilterValues
    );
    const filter: DesktopFilterRequest = mergeDesktopFilters(
      desktopFilter,
      getPrepareFilterTicketsToRequest({
        filter: ticketsReportFilter,
      })
    );
    const data: ResponseWithMeta<Ticket[]> = yield call(
      requestsHistory.fetchHistoryTickets,
      currentPage,
      pageSize,
      sort,
      filter
    );
    yield put(fetchTicketsSuccessHistory(data));
    yield put(setIsTicketsLoadingHistory(false));
  } catch (error) {
    createError(error);
  }
}

function* ticketsStatisticHistoryFetch() {
  try {
    const filter: DesktopFilters = yield select(getDesktopFilters);
    const data: TicketsStatistic = yield call(
      requestsHistory.fetchHistoryTicketsStatistic,
      filter
    );
    yield put(fetchTicketsStatisticSuccessHistory(data));
  } catch (error) {
    createError(error);
  }
}

export function* desktopHistorySaga() {
  yield debounce(
    DEFAULT_DEBOUNCE_DELAY,
    fetchTicketsRequestHistory.type,
    ticketsFetch
  );

  yield takeEvery(
    fetchTicketsStatisticRequestHistory.type,
    ticketsStatisticHistoryFetch
  );
}
