import { debounce } from 'lodash';
import { FC, memo, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  fetchJiraIntegrationsRequest,
  getJiraIntegrationsFilterToRequest,
  JiraIntegrationsFormData,
  setCurrentJiraIntegrationsPage,
  setJiraIntegrationsFilter,
  TableJiraIntegrationsTypes,
} from '@entities/jira-integrations';
import {
  fetchOrganizationsAddRequest,
  OrganizationSelect,
  setCurrentOrganizationsAddPage,
  setOrganizationsAddFilterAction,
} from '@entities/organization';
import {
  fetchSystemsRequest,
  resetSystemsState,
  SystemSelect,
} from '@entities/system';
import {
  ACTIVE_SELECT_OPTIONS,
  checkObjectIdentity,
  DEFAULT_DEBOUNCE_DELAY,
  Input,
  Select,
  Size,
  TableBodyCell,
  TableFilter,
  ValueType,
} from '@shared';

import { INITIAL_JIRA_INTEGRATIONS_FILTER } from '../../config';

interface JiraIntegrationsFilterProps {
  tableType: TableJiraIntegrationsTypes;
}

export const JiraIntegrationsFilter: FC<JiraIntegrationsFilterProps> = memo(
  ({ tableType }) => {
    const dispatch = useDispatch();

    const { register, handleSubmit, reset, watch, control } =
      useForm<JiraIntegrationsFormData>({
        mode: 'onChange',
        defaultValues: INITIAL_JIRA_INTEGRATIONS_FILTER,
      });

    const filterValues = watch();

    const resetFilter = () => {
      dispatch(setCurrentJiraIntegrationsPage(0));
      dispatch(setJiraIntegrationsFilter({}));
      dispatch(fetchJiraIntegrationsRequest());
      if (tableType === TableJiraIntegrationsTypes.FULL) {
        dispatch(setCurrentOrganizationsAddPage(0));
        dispatch(setOrganizationsAddFilterAction({}));
        dispatch(fetchOrganizationsAddRequest({ updateType: 'update' }));
      }
      reset();
    };

    const formSubmitHandler = handleSubmit((data) => {
      dispatch(setCurrentJiraIntegrationsPage(0));
      dispatch(
        setJiraIntegrationsFilter(getJiraIntegrationsFilterToRequest(data))
      );
      dispatch(fetchJiraIntegrationsRequest());
    });

    useEffect(() => {
      dispatch(fetchSystemsRequest());
      return () => {
        dispatch(resetSystemsState());
      };
    }, []);

    const formSubmitHandlerDebounced = useMemo(
      () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
      []
    );

    const nameInputOptions = register('name', {
      onChange: formSubmitHandlerDebounced,
    });
    const projectKeyInputOptions = register('projectKey', {
      onChange: formSubmitHandlerDebounced,
    });
    const loginInputOptions = register('login', {
      onChange: formSubmitHandlerDebounced,
    });
    const jiraUrlInputOptions = register('jiraUrl', {
      onChange: formSubmitHandlerDebounced,
    });

    const tableBodyWrapper = (elements: JSX.Element[]) =>
      elements.map((item) => (
        <TableBodyCell filterCell key={item.key}>
          {item}
        </TableBodyCell>
      ));

    const filterElements = {
      [TableJiraIntegrationsTypes.FULL]: () => [
        <Input size={Size.xs} type="name" key="name" {...nameInputOptions} />,
        <Controller
          control={control}
          name="isActive"
          key="isActive"
          render={({ field }) => (
            <Select<boolean>
              size={Size.xs}
              mobileModalTitle="статус"
              onChange={(value) => {
                field.onChange(value);
                formSubmitHandler();
              }}
              options={ACTIVE_SELECT_OPTIONS}
              value={field.value}
              isTooltip={false}
            />
          )}
        />,
        <Input
          size={Size.xs}
          type="jiraUrl"
          key="jiraUrl"
          {...jiraUrlInputOptions}
        />,
        <Controller
          control={control}
          name="systemId"
          key="systemId"
          render={({ field }) => (
            <SystemSelect
              mobileModalTitle="систему"
              value={field.value}
              onChange={(value: ValueType<string>) => {
                field.onChange(value);
                formSubmitHandler();
              }}
              isMulti={false}
            />
          )}
        />,
        <Controller
          control={control}
          name="organizationId"
          key="organizationId"
          render={({ field }) => (
            <OrganizationSelect
              size={Size.xs}
              onChange={(value) => {
                field.onChange(value);
                formSubmitHandler();
              }}
              value={field.value}
            />
          )}
        />,
        <Input
          size={Size.xs}
          type="login"
          key="login"
          {...loginInputOptions}
        />,
        <Input
          size={Size.xs}
          type="projectKey"
          key="projectKey"
          {...projectKeyInputOptions}
        />,
      ],
    };

    const filter = (
      <>
        {tableBodyWrapper(
          filterElements[tableType as keyof typeof filterElements]()
        )}
      </>
    );

    const disableReset = checkObjectIdentity(filterValues, {});

    return (
      <TableFilter
        filterComponent={filter}
        onReset={resetFilter}
        disableReset={disableReset}
      />
    );
  }
);
