import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import { Tickets } from '@features/ticket';
import { MainLayout } from '@shared';

import styles from './TicketsPage.module.scss';

const TicketsPage = () => {
  const { t } = useTranslation('tickets');

  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );

  return (
    <MainLayout
      title={t('headerTitle')}
      withCreateTicketButton
      headerType="default"
      classNameRoot={styles.ticketsPage}
      accessToCreateTicket={isAccessToCreateTicket}
    >
      <Tickets />
    </MainLayout>
  );
};

export default TicketsPage;
