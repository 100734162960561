import { CustomField } from '@entities/custom-fields/model/types';
import { System } from '@entities/system/model';
import { ResponseWithMeta } from '@shared';

import {
  CloseExportPanelAction,
  CustomFieldsExportLoadingHideAction,
  CustomFieldsExportLoadingShowAction,
  DownloadExcelRequestAction,
  DownloadingExcel,
  FetchCustomFieldsExportRequestAction,
  FetchCustomFieldsExportSuccessAction,
  FetchSystemsExportRequestAction,
  FetchSystemsExportSuccessAction,
  GetExportFieldsRequestAction,
  GetTicketsCountRequestAction,
  IGetExportFieldsAction,
  OpenExportPanelAction,
  ResetCustomFieldsExportAction,
  ResetSelectedCustomFieldsAction,
  ResetTicketsExportStateAction,
  SelectAllCustomFieldsAction,
  SelectCustomFieldAction,
  SetExtraExportFields,
  SetTicketsCountAction,
  SystemsExportLoadingHideAction,
  SystemsExportLoadingShowAction,
  TicketsExportType,
} from './actionTypes';
import { ExportFields, TicketsRequestData } from './types';

export const openExportPanel = (): OpenExportPanelAction => ({
  type: TicketsExportType.OPEN_EXPORT_PANEL,
});

export const closeExportPanel = (): CloseExportPanelAction => ({
  type: TicketsExportType.CLOSE_EXPORT_PANEL,
});

export const getExportFieldsAction = (
  payload: ExportFields
): IGetExportFieldsAction => ({
  type: TicketsExportType.GET_EXPORT_FIELDS,
  payload,
});

export const getExportFieldsRequest = (): GetExportFieldsRequestAction => ({
  type: TicketsExportType.GET_EXPORT_FIELDS_REQUEST,
});

export const setTicketsCount = (payload?: number): SetTicketsCountAction => ({
  type: TicketsExportType.SET_TICKETS_COUNT,
  payload,
});

export const getTicketsCountRequest = (
  payload: TicketsRequestData
): GetTicketsCountRequestAction => ({
  type: TicketsExportType.GET_TICKETS_COUNT_REQUEST,
  payload,
});

export const downloadExcelRequest = (): DownloadExcelRequestAction => ({
  type: TicketsExportType.DOWNLOAD_EXCEL_REQUEST,
});

export const getIsDownloadingExcelAction = (
  payload: boolean
): DownloadingExcel => ({
  type: TicketsExportType.DOWNLOADING_EXCEL,
  payload,
});

export const fetchSystemsExportRequest = (
  title?: string
): FetchSystemsExportRequestAction => ({
  type: TicketsExportType.FETCH_SYSTEMS_EXPORT_REQUEST,
  payload: title,
});

export const fetchSystemsExportSuccess = (
  payload: ResponseWithMeta<System[]>
): FetchSystemsExportSuccessAction => ({
  type: TicketsExportType.FETCH_SYSTEMS_EXPORT_SUCCESS,
  payload,
});

export const systemsExportLoadingShow = (): SystemsExportLoadingShowAction => ({
  type: TicketsExportType.SYSTEMS_EXPORT_LOADING_SHOW,
});

export const systemsExportLoadingHide = (): SystemsExportLoadingHideAction => ({
  type: TicketsExportType.SYSTEMS_EXPORT_LOADING_HIDE,
});

export const resetTicketsExportState = (): ResetTicketsExportStateAction => ({
  type: TicketsExportType.RESET_TICKETS_EXPORT_STATE,
});

export const fetchCustomFieldsExportRequest = (
  systemId: string
): FetchCustomFieldsExportRequestAction => ({
  type: TicketsExportType.FETCH_CUSTOM_FIELDS_EXPORT_REQUEST,
  payload: systemId,
});

export const fetchCustomFieldsExportSuccess = (
  payload: ResponseWithMeta<CustomField[]>
): FetchCustomFieldsExportSuccessAction => ({
  type: TicketsExportType.FETCH_CUSTOM_FIELDS_EXPORT_SUCCESS,
  payload,
});

export const customFieldsExportLoadingShow =
  (): CustomFieldsExportLoadingShowAction => ({
    type: TicketsExportType.CUSTOM_FIELDS_EXPORT_LOADING_SHOW,
  });

export const customFieldsExportLoadingHide =
  (): CustomFieldsExportLoadingHideAction => ({
    type: TicketsExportType.CUSTOM_FIELDS_EXPORT_LOADING_HIDE,
  });

export const resetCustomFieldsExport = (): ResetCustomFieldsExportAction => ({
  type: TicketsExportType.RESET_CUSTOM_FIELDS_EXPORT,
});

export const selectCustomField = (
  payload: CustomField
): SelectCustomFieldAction => ({
  type: TicketsExportType.SELECT_CUSTOM_FIELD,
  payload,
});

export const selectAllCustomFields = (): SelectAllCustomFieldsAction => ({
  type: TicketsExportType.SELECT_ALL_CUSTOM_FIELDS,
});

export const resetSelectedCustomFields =
  (): ResetSelectedCustomFieldsAction => ({
    type: TicketsExportType.RESET_SELECTED_CUSTOM_FIELDS,
  });

export const setExtraExportFields = (
  payload?: CustomField[]
): SetExtraExportFields => ({
  type: TicketsExportType.SET_EXTRA_EXPORT_FIELDS,
  payload,
});
