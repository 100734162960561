import { Organization } from '@entities/organization/model/types';
import { System } from '@entities/system/model';
import { ApiClient, getEnv, ResponseWithMeta } from '@shared';

import { endpoints } from './endpoints';

export const requests = {
  fetchOrganizations: () =>
    ApiClient.get<Organization[]>({
      baseURL: getEnv('REACT_APP_TICKET_REPORT_URL'),
      url: endpoints.getOrganizations(),
    }),
  fetchSystems: (id: string) =>
    ApiClient.get<System[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSystems(id),
    }),
  fetchOrganizationsWorkGroup: (workgroupId: string) =>
    ApiClient.get<ResponseWithMeta<Organization[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getOrganizationsWorkGroup(workgroupId),
    }),
};
