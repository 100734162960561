import { FC } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';

import { HistoryFormData } from '@entities/desktop';
import { InputDatePicker, Select, SelectOption } from '@shared';

import { ResetFilterButton } from '../ResetFilterButton';

import styles from './HistoryForm.module.scss';

interface HistoryFormProps {
  control: Control<HistoryFormData>;
  setValue: UseFormSetValue<HistoryFormData>;
  organizationsOptions: SelectOption[];
  systemsOptions: SelectOption[];
}

interface HistoryFormProps {
  control: Control<HistoryFormData>;
  setValue: UseFormSetValue<HistoryFormData>;
  organizationsOptions: SelectOption[];
  systemsOptions: SelectOption[];
  ticketTypesOptions: SelectOption[];
  isDisabledSystem?: boolean;
  reset: () => void;
}

export const HistoryForm: FC<HistoryFormProps> = ({
  control,
  setValue,
  organizationsOptions,
  systemsOptions,
  ticketTypesOptions,
  isDisabledSystem,
  reset,
}) => (
  <>
    <Controller
      control={control}
      name="date"
      render={({ field }) => (
        <InputDatePicker
          type="datePicker"
          placeholder="Период"
          value={field.value}
          onChange={field.onChange}
          className={styles.historyForm__select}
          showRange
        />
      )}
    />
    <Controller
      control={control}
      name="organizationId"
      render={({ field }) => (
        <Select<string>
          allowClear
          label="Организация"
          mobileModalTitle="организацию"
          value={field.value}
          options={organizationsOptions}
          className={styles.historyForm__select}
          onChange={(value) => {
            setValue('systemId', null);
            setValue('ticketTypesIds', []);
            field.onChange(value);
          }}
        />
      )}
    />
    <Controller
      control={control}
      name="systemId"
      render={({ field }) => (
        <Select<string>
          allowClear
          label="Система"
          mobileModalTitle="систему"
          value={field.value}
          className={styles.historyForm__select}
          options={systemsOptions}
          onChange={(value) => {
            setValue('ticketTypesIds', []);
            field.onChange(value);
          }}
          disabled={isDisabledSystem}
        />
      )}
    />
    <Controller
      control={control}
      name="ticketTypesIds"
      render={({ field }) => (
        <Select<string>
          allowClear
          label="Тип тикета"
          mobileModalTitle="тип тикета"
          value={field.value}
          options={ticketTypesOptions}
          className={styles.historyForm__select}
          onChange={(event) => {
            field.onChange(event);
          }}
          isMulti
        />
      )}
    />
    <ResetFilterButton reset={reset} />
  </>
);
