import { CustomFieldType } from '@entities/custom-fields';
import {
  CustomCondition,
  CustomField,
  TriggerAttribute,
  TriggerCondition,
} from '@entities/triggers';
import { getValueFromValueType, SelectOption } from '@shared';

import {
  SELECT_ATTRIBUTES_LIST_TRIGGERS,
  SELECT_CONDITION_LIST,
} from '../../config';

const isAttribute = (
  list: CustomCondition[] | TriggerCondition[] | TriggerAttribute[]
): list is TriggerAttribute[] => 'attribute' in list[0];

const getOptions = (
  items: TriggerCondition[] | TriggerAttribute[],
  list: SelectOption[]
) => {
  if (!items.length) {
    return [];
  }

  let itemsValue: string[];

  if (isAttribute(items)) {
    itemsValue = items.map(
      ({ attribute }) => getValueFromValueType(attribute) || ''
    );
  } else {
    itemsValue = items.map(
      ({ condition }) => getValueFromValueType(condition) || ''
    );
  }

  return list.filter(({ value }) => !itemsValue.includes(value));
};

export const getAttributesOptions = (attributes: TriggerAttribute[]) =>
  getOptions(attributes, SELECT_ATTRIBUTES_LIST_TRIGGERS);

export const getTriggerConditionOptions = (conditions?: TriggerCondition[]) => {
  if (!conditions) {
    return [];
  }

  return getOptions(conditions, SELECT_CONDITION_LIST);
};

export const getCustomFieldsOptions = (
  customConditions?: CustomCondition[],
  customFields?: CustomField[]
) => {
  const preparedCustomFields =
    customFields?.map(({ id, title, values, customFieldType }) => ({
      title,
      value: id,
      ...(customFieldType === CustomFieldType.FIELD_LIST && { values }),
    })) ?? [];

  if (!customConditions) {
    return preparedCustomFields;
  }

  const customConditionValue = customConditions.map(
    ({ customField }) => getValueFromValueType(customField) || ''
  );

  return preparedCustomFields.filter(
    ({ value }) => !customConditionValue.includes(value)
  );
};
