import { createSelector } from 'reselect';

import {
  Organization,
  OrganizationType,
} from '@entities/organization/model/types';
import { Ticket } from '@entities/ticket/model/types';
import { WorkGroup } from '@entities/work-group/model';
import { getArraySelectOptions, RouterHref, SelectOption } from '@shared';

import { AccessedRoute, ActionList, AuthState } from './types';

export const getUserId = (state: AuthState): string | undefined =>
  state.currentUser.user?.userId;
export const getUser = (state: AuthState) => state.currentUser.user;
export const getUserFirstName = (state: AuthState): string | undefined =>
  state.currentUser.user?.firstName;
export const getUserLastName = (state: AuthState): string | undefined =>
  state.currentUser.user?.lastName;
export const getUserMiddleName = (state: AuthState): string | undefined =>
  state.currentUser.user?.middleName;
export const getUserFullName = (state: AuthState): string | undefined =>
  state.currentUser.user?.userFullName;
export const getUserEmail = (state: AuthState): string | undefined =>
  state.currentUser.user?.email;
export const getUserPhoneNumber = (state: AuthState): string | undefined =>
  state.currentUser.user?.phoneNumber;
export const getUserOrganizationTitle = (
  state: AuthState
): string | undefined => state.currentUser.user?.organization?.title;
export const getIsAuthorized = (state: AuthState): boolean | undefined =>
  state.currentUser.isAuthorized;
export const getAccessedRoutes = (state: AuthState) =>
  state.currentUser.accessedRoutes;
export const getActionList = (state: AuthState): string[] | undefined =>
  state.currentUser.actionList;
export const getWorkGroupsIds = (state: AuthState): string[] | undefined =>
  state.currentUser.workGroupsIds;
export const getManagerWorkGroupsIds = (
  state: AuthState
): string[] | undefined => state.currentUser.managerWorkGroupsIds;
export const getWorkGroups = (state: AuthState) => state.currentUser.workGroups;
export const getSpecialistWorkGroupsIds = (
  state: AuthState
): string[] | undefined => state.currentUser.specialistWorkGroupsIds;

export const getManagerWorkGroups = (state: AuthState) =>
  state.currentUser.managerWorkGroups;
export const getSpecialistWorkGroups = (
  state: AuthState
): WorkGroup[] | undefined => state.currentUser.specialistWorkGroups;
export const getUserOrganization = (state: AuthState) =>
  state.currentUser.user?.organization;
export const getUserOrganizationId = (state: AuthState) =>
  state.currentUser.user?.organization?.id;
export const getCustomerAttribute = (state: AuthState) =>
  state.currentUser.user?.hasCustomerAttribute;
export const getSpecialistAttribute = (state: AuthState) =>
  state.currentUser.user?.hasSpecialistAttribute;
const getUserIsServiceAdmin = (state: AuthState) =>
  state.currentUser.user?.admin ?? false;

export const getIsAccessedAction = (action: ActionList) =>
  createSelector<AuthState, string[] | undefined, boolean>(
    [getActionList],
    (actionList): boolean => {
      if (actionList) {
        return actionList?.includes(action);
      }
      return false;
    }
  );
// TODO: tsp-1519 переписать логику позже
export const getIsAccessedByRoute = (routerHref: RouterHref) =>
  createSelector<AuthState, AccessedRoute[] | undefined, boolean>(
    [getAccessedRoutes],
    (routeList): boolean =>
      !!routeList?.filter(({ route }) => route === routerHref)?.length
  );

export const getIsOrgTypeCustomer = createSelector<
  AuthState,
  Organization | undefined,
  boolean
>(
  [getUserOrganization],
  (organization): boolean => organization?.type === OrganizationType.CUSTOMER
);

export const getIsOrgTypeService = createSelector<
  AuthState,
  Organization | undefined,
  boolean
>(
  [getUserOrganization],
  (organization): boolean => organization?.type === OrganizationType.SERVICE
);

export const getIsOrgTypePartner = createSelector<
  AuthState,
  Organization | undefined,
  boolean
>(
  [getUserOrganization],
  (organization): boolean => organization?.type === OrganizationType.PARTNER
);

export const getIsClient = createSelector<
  AuthState,
  boolean,
  boolean | undefined,
  boolean
>(
  [getIsOrgTypeCustomer, getCustomerAttribute],
  (isOrgTypeCustomer, customerAttribute): boolean =>
    !!(isOrgTypeCustomer && customerAttribute)
);

export const getIsClientPartner = createSelector<
  AuthState,
  boolean,
  boolean | undefined,
  boolean
>(
  [getIsOrgTypePartner, getCustomerAttribute],
  (isOrgTypePartner, customerAttribute): boolean =>
    !!(isOrgTypePartner && customerAttribute)
);

export const getIsClients = createSelector<
  AuthState,
  boolean,
  boolean,
  boolean
>(
  [getIsClient, getIsClientPartner],
  (isClient, isClientPartner) => isClient || isClientPartner
);

export const getIsMyTicket = (ticket?: Ticket) =>
  createSelector<AuthState, string | undefined, boolean>(
    getUserId,
    (userId): boolean => userId === ticket?.clientInfo?.id
  );

export const getIsSpecialist = createSelector<
  AuthState,
  boolean,
  boolean | undefined,
  boolean
>(
  [getIsOrgTypeService, getSpecialistAttribute],
  (isOrgTypeService, specialistAttribute): boolean =>
    !!(isOrgTypeService && specialistAttribute)
);

export const getIsSpecialistPartner = createSelector<
  AuthState,
  boolean,
  boolean | undefined,
  boolean
>(
  [getIsOrgTypePartner, getSpecialistAttribute],
  (isOrgTypePartner, specialistAttribute): boolean =>
    !!(isOrgTypePartner && specialistAttribute)
);

export const getManagerWorkGroup = createSelector<
  AuthState,
  string[] | undefined,
  boolean
>(
  [getManagerWorkGroupsIds],
  (userManagerWorkGroupsIds): boolean => !!userManagerWorkGroupsIds?.length
);

export const getSpecialistWorkGroup = createSelector<
  AuthState,
  boolean,
  boolean,
  boolean
>(
  [getIsSpecialist, getIsSpecialistPartner],
  (isSpecialist, isSpecialistPartner): boolean =>
    isSpecialist || isSpecialistPartner
);

export const getOnlySpecialistWorkGroup = createSelector<
  AuthState,
  boolean,
  boolean,
  boolean
>(
  [getSpecialistWorkGroup, getManagerWorkGroup],
  (isSpecialistWorkGroup, isWorkGroupManager): boolean =>
    isSpecialistWorkGroup && !isWorkGroupManager
);

export const getManagerSpecialistWorkGroup = createSelector<
  AuthState,
  boolean,
  boolean,
  boolean
>(
  [getSpecialistWorkGroup, getManagerWorkGroup],
  (isUserSpecialist, isWorkGroupManager): boolean =>
    isUserSpecialist && isWorkGroupManager
);

export const getAcessChangeGroupSpecialist = createSelector<
  AuthState,
  boolean,
  boolean,
  boolean
>(
  [getManagerWorkGroup, getSpecialistWorkGroup],
  (isManagerWorkGroup, isSpecialistWorkGroup): boolean =>
    isManagerWorkGroup || isSpecialistWorkGroup
);

export const getManagerWorkGroupsList = createSelector<
  AuthState,
  WorkGroup[] | undefined,
  SelectOption[]
>([getManagerWorkGroups], (managerWorkGroups): SelectOption[] =>
  getArraySelectOptions(managerWorkGroups)
);

export const getWorkGroupsOptionsList = createSelector<
  AuthState,
  WorkGroup[] | undefined,
  WorkGroup[] | undefined,
  SelectOption[]
>(
  [getManagerWorkGroups, getSpecialistWorkGroups],
  (managerWorkGroups, specialistWorkGroups): SelectOption[] => {
    const managerWorkGroup = getArraySelectOptions(managerWorkGroups);
    const specWorkGroup = getArraySelectOptions(specialistWorkGroups);

    const uniqueWorkGroupsMap = new Map<string, SelectOption>();

    [...managerWorkGroup, ...specWorkGroup].forEach((workGroup) => {
      uniqueWorkGroupsMap.set(workGroup.value, workGroup);
    });

    return Array.from(uniqueWorkGroupsMap.values());
  }
);

export const getIsAccessServiceAdmin = (action: ActionList) =>
  createSelector<AuthState, boolean, string[] | undefined, boolean>(
    [getUserIsServiceAdmin, getActionList],
    (isServiceAdmin, actionList): boolean => {
      const hasAccessToAction = actionList
        ? actionList.includes(action)
        : false;
      return isServiceAdmin && hasAccessToAction;
    }
  );

export const getIsServiceAdmin = getIsAccessServiceAdmin(
  ActionList.ViewingAllTickets
);
export const getIsAdmin = getIsAccessedAction(ActionList.ViewingAllTickets);

export const getIsManagerCurrentWorkGroups = (workGroups?: WorkGroup[]) =>
  createSelector<AuthState, string[] | undefined, boolean>(
    getManagerWorkGroupsIds,
    (managerWorkGroupsIds): boolean =>
      !!workGroups?.find((workGroup) =>
        managerWorkGroupsIds?.includes(workGroup.id)
      )
  );

export const getUserOrganizationList = createSelector<
  AuthState,
  Organization | undefined,
  SelectOption[]
>([getUserOrganization], (organization): SelectOption[] => [
  {
    title: organization?.title || '',
    value: organization?.id || '',
  },
]);

const getRoleManagerWorkGroup = (
  isRoleSelector: (state: AuthState) => boolean,
  workGroupsIdsSelector: (state: AuthState) => string[] | undefined
) =>
  createSelector(
    [isRoleSelector, workGroupsIdsSelector],
    (isRole, workGroupsIds) => isRole && !!workGroupsIds?.length
  );

export const getIsManagerClients = getRoleManagerWorkGroup(
  getIsClient,
  getManagerWorkGroupsIds
);

export const getIsManagerSpecialist = getRoleManagerWorkGroup(
  getIsSpecialist,
  getSpecialistWorkGroupsIds
);

export const getIsManagerSpecialistPartner = getRoleManagerWorkGroup(
  getIsSpecialistPartner,
  getSpecialistWorkGroupsIds
);

export const getIsManagerWorkGroup = createSelector<
  AuthState,
  boolean,
  boolean
>(
  [getIsManagerSpecialist, getIsManagerSpecialistPartner],
  (isManagerSpecialists, isManagerpartner) =>
    isManagerSpecialists || isManagerpartner
);

export const getIsReportClients = createSelector<AuthState, boolean, boolean>(
  [getIsAccessedAction(ActionList.CreateReportByMyOrg), getIsManagerClients],
  (isAccessed, isManager) => isAccessed && isManager
);

export const getIsReportSpecialists = createSelector<
  AuthState,
  boolean,
  boolean
>(
  [
    getIsAccessedAction(ActionList.CreateReportByOrganization),
    getIsManagerSpecialist,
  ],
  (isAccessed, isManager) => isAccessed && isManager
);

export const getIsReportPartner = createSelector<AuthState, boolean, boolean>(
  [
    getIsAccessedAction(ActionList.CreateReportByOrganization),
    getIsManagerSpecialistPartner,
  ],
  (isAccessed, isManager) => isAccessed && isManager
);

export const getIsReportAccess = createSelector<AuthState, boolean, boolean>(
  [getIsReportClients, getIsReportSpecialists, getIsReportPartner],
  (isReportClients, isReportSpecialists, isReportPartner) =>
    isReportClients || isReportSpecialists || isReportPartner
);
