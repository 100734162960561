import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  FilterData,
  getIsModalPopoverInner,
  getLoadingTickets,
  getValueTabItem,
  SetCurrentFilterTitleRequest,
  toggleModalPopoverInner,
} from '@entities/ticket';
import {
  DeleteApproveOrCancel,
  PlusIcon,
  Popover,
  PopoverButton,
  PopoverTitle,
  Radio,
} from '@shared';

import { getFiltersRadioItems } from '../../../lib';
import { TicketsPopoverInner } from '../TicketsPopoverInner';
import { TicketsPopovers } from '../TicketsPopovers';

import styles from './TicketsDropContentFilters.module.scss';

interface TicketsDropContentFiltersProps {
  filtersData: FilterData[];
  currentFilterId?: string;
  isUsedFilter?: boolean;
  setCurrentFilters: (id: string) => void;
  handleSubmitFilter: () => void;
  handleSubmitFilterTitle?: (data: SetCurrentFilterTitleRequest) => void;
  handleDeleteFilter: (id: string) => void;
  handleChangeFilterTitle: (title: string) => void;
}

export const TicketsDropContentFilters: FC<TicketsDropContentFiltersProps> = (
  props
) => {
  const {
    filtersData,
    currentFilterId,
    isUsedFilter,
    setCurrentFilters,
    handleSubmitFilter,
    handleSubmitFilterTitle,
    handleDeleteFilter,
    handleChangeFilterTitle,
  } = props;

  const dispatch = useDispatch();

  const isModalPopoverInner = useSelector(getIsModalPopoverInner);
  const loading = useSelector(getLoadingTickets);
  const valueTabItem = useSelector(getValueTabItem);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleTogglePopover = () => {
    setIsPopoverOpen((prev) => !prev);
  };

  const toggleModal = () => {
    dispatch(toggleModalPopoverInner());
  };

  const handleDelete = () => {
    setIsPopoverOpen(false);

    if (valueTabItem) {
      handleDeleteFilter(valueTabItem);
    }
  };

  return (
    <div className={styles.filters}>
      <PopoverTitle title="Фильтры" />
      <div className={styles.filtersBlocks}>
        <Radio
          items={getFiltersRadioItems(filtersData)}
          value={currentFilterId ?? ''}
          onChange={(event) => {
            setCurrentFilters(event.target.value);
          }}
          disabled={loading}
          className={styles.filtersRadio}
        />
        <TicketsPopovers
          items={getFiltersRadioItems(filtersData)}
          onChangePopover={handleChangeFilterTitle}
          handleSubmitPopover={handleSubmitFilterTitle}
        />
      </div>
      {isUsedFilter && (
        <Popover
          togglePopover={handleTogglePopover}
          isOpen={isPopoverOpen}
          positions={['left']}
          align="start"
          content={
            <TicketsPopoverInner
              formSubmitHandler={handleSubmitFilter}
              onChange={handleChangeFilterTitle}
              resetTitle="Отмена"
              closePopoverWithReset={setIsPopoverOpen}
            />
          }
          popoverClassName={styles.filters__popoverInner}
        >
          <PopoverButton
            onClick={handleTogglePopover}
            icon={<PlusIcon className={styles.filters__popoverInnerIcon} />}
            className={styles.filters__popoverInnerBtn}
          >
            Сохранить фильтр
          </PopoverButton>
        </Popover>
      )}
      {isModalPopoverInner && (
        <DeleteApproveOrCancel
          isModal={isModalPopoverInner}
          toggleModal={toggleModal}
          entityTitle="фильтр"
          onApprove={handleDelete}
          withHideBodyScroll={false}
        />
      )}
    </div>
  );
};
