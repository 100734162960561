import {
  ApproveOrCancel,
  Button,
  DataEmpty,
  DeleteButton,
  EmptyDataType,
  entityNames,
  PenIcon,
  PlusIcon,
  PopoverButton,
  PopoverContainer,
  TableContainer,
} from '@shared';

import { useStorageTable } from '../../../lib';
import { StorageAddFiles } from '../StorageAddFiles';
import { StorageInfoBlock } from '../StorageInfoBlock';
import { StorageTableContent } from '../StorageTableContent';

import styles from './StorageTableContainer.module.scss';

export const StorageTableContainer = () => {
  const {
    state: {
      isOpenAddModal,
      storageFiles,
      modalType,
      fileCardId,
      loading,
      isCancelModal,
      mainFileName,
      pageSize,
      pageNum,
      elementsCount,
      currentStorage,
      customFields,
    },
    methods: {
      toggleAddModal,
      toggleEditModal,
      downloadFile,
      handleClickFileCard,
      toggleCancelModal,
      onApproveCancelModal,
      handleChangePage,
      handleChangePageSize,
    },
  } = useStorageTable();

  const addFileButton = currentStorage?.currentUserCanEditFiles ? (
    <Button
      appearance="outline"
      onClick={toggleAddModal}
      className={styles.storageTableContainer__button}
      icon={<PlusIcon />}
    >
      Добавить файл
    </Button>
  ) : undefined;

  const addFilesModal = isOpenAddModal && (
    <StorageAddFiles
      modalType={modalType}
      isOpenModal={isOpenAddModal}
      toggleModal={toggleAddModal}
      fileCardId={fileCardId}
    />
  );

  const dropContent = currentStorage?.currentUserCanEditFiles ? (
    <PopoverContainer>
      <PopoverButton
        onClick={toggleEditModal}
        icon={<PenIcon className={styles.storageTableContainer__icon} />}
        disabled={!fileCardId}
      >
        Редактировать
      </PopoverButton>

      <DeleteButton disabled={!fileCardId} onClick={toggleCancelModal} />
    </PopoverContainer>
  ) : undefined;

  const headerProps = {
    title: 'Файлы',
    createTitle: 'Добавить',
    createHandler: currentStorage?.currentUserCanEditFiles
      ? toggleAddModal
      : undefined,
    dropContent,
    loading,
  };

  const paginationProps = {
    pageNum,
    pageSize,
    elementsCount,
    handleChangePage,
    handleChangePageSize,
    entityName: entityNames.STORAGE,
  };

  const getContent = () => {
    if (!storageFiles?.content.length) {
      return (
        <>
          <DataEmpty
            type={EmptyDataType.NO_DATA_FILES}
            buttonComponent={addFileButton}
          />
          <StorageInfoBlock />
        </>
      );
    }
    return (
      <>
        <StorageTableContent
          storageFiles={storageFiles?.content}
          downloadFile={downloadFile}
          handleClickFileCard={handleClickFileCard}
          activeFileCardId={fileCardId}
          customFields={customFields}
        />
        <StorageInfoBlock />
      </>
    );
  };

  return (
    <>
      <TableContainer
        classNameRoot={styles.storageTableContainer}
        headerProps={headerProps}
        countRecordsProps={{
          records: storageFiles?.content.length,
        }}
        paginationProps={paginationProps}
        contentTable={getContent()}
      />
      {addFilesModal}
      <ApproveOrCancel
        onApprove={onApproveCancelModal}
        isModal={isCancelModal}
        toggleModal={toggleCancelModal}
        text={`Вы уверены, что хотите удалить файл ${mainFileName}?`}
      />
    </>
  );
};
