import cn from 'clsx';
import { FC } from 'react';
import ReactFlow, { Background } from 'reactflow';

import { TicketType } from '@entities/ticket';
import {
  Button,
  Copy2Icon,
  EllipseIconButton,
  EyeIcon,
  IconButton,
  LinkCrossedIcon,
  Pen3Icon,
  PlusIcon,
  Select,
  Size,
} from '@shared';

import { nodeTypes } from '../../config/constants';
import { useBuilder } from '../../lib/hooks';
import { getCustomEdges, getCustomNodes } from '../../lib/utils';
import { StatusCopyModel } from '../StatusCopyModel';
import { StatusDetail } from '../StatusDetail';

import styles from './Builder.module.scss';

import './styles.scss';

interface BuilderProps {
  isSmallWindowWidth: boolean | null;
}

export const Builder: FC<BuilderProps> = ({ isSmallWindowWidth }) => {
  const { state, methods } = useBuilder({ isSmallWindowWidth });

  const {
    theme,
    isDetailStatusOpen,
    selectedNode,
    selectedEdge,
    nodes,
    edges,
    isChanges,
    systemsOptions,
    systemOption,
    systemId,
    ticketTypeOption,
    isCreateDisabled,
    disabledSave,
    ticketTypes,
    isCopyStatusModelOpen,
    ticketTypesTo,
  } = state;

  const {
    onNodesChange,
    onEdgesChange,
    onConnect,
    onEdgeUpdate,
    onEdgeDelete,
    onCreateNode,
    onCreateEdge,
    toggleIsDetailStatus,
    onResetSelectedNode,
    onCloseDetailStatus,
    onEditNodeHandler,
    onDeleteNodeHandler,
    onSetBuilderValues,
    setSystemOption,
    setTicketTypeOption,
    onSaveBuilderValues,
    toggleIsCopyStatus,
    onCloseCopyStatusModel,
  } = methods;

  const statusDetail = isDetailStatusOpen && (
    <StatusDetail
      selectedNode={selectedNode}
      nodes={nodes}
      onCreateNodeHandler={onCreateNode}
      onCreateEdgeHandler={onCreateEdge}
      onClose={onCloseDetailStatus}
      onResetSelectedNode={onResetSelectedNode}
      onEditNodeHandler={onEditNodeHandler}
      onDeleteNodeHandler={onDeleteNodeHandler}
    />
  );

  const copyStatusModel = isCopyStatusModelOpen && (
    <StatusCopyModel
      systemsOptions={systemsOptions}
      ticketTypesFrom={ticketTypes}
      ticketTypesTo={ticketTypesTo}
      onClose={onCloseCopyStatusModel}
    />
  );

  const ticketTypeOptions = (
    <Select
      size={Size.xs}
      label="Выберите тип тикета"
      onChange={(type: TicketType) => setTicketTypeOption(type)}
      options={ticketTypes}
      value={ticketTypeOption}
      loading={false}
      className={cn(styles.builder__select, {
        [styles.builder__select_notActive]: !systemId,
      })}
      disabled={!systemId}
    />
  );

  return (
    <div className={styles.builder}>
      <div
        className={cn(
          styles.builder__buttonsWrapper,
          styles.builder__buttonsWrapper_left,
          { [styles.builder__selectWrapper_hidden]: isDetailStatusOpen }
        )}
      >
        <Select<string>
          size={Size.xs}
          label="Выберите систему"
          options={systemsOptions}
          value={systemOption}
          onChange={(value) => setSystemOption(value)}
          className={cn(styles.builder__select, {
            [styles.builder__select_notActive]: !systemsOptions.length,
          })}
        />
        <div
          className={cn(styles.builder__selectWrapper_left, {
            [styles.builder__selectWrapper_hidden]: isDetailStatusOpen,
          })}
        >
          {ticketTypeOptions}
        </div>
      </div>
      <ReactFlow
        nodes={getCustomNodes(nodes)}
        edges={getCustomEdges({ edges, theme })}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onEdgeUpdate={onEdgeUpdate}
        nodeTypes={nodeTypes}
        deleteKeyCode={null}
      >
        <Background className={styles.builder__background} />
        <div className={styles.builder__selectWrapper}>{ticketTypeOptions}</div>
        <div className={styles.builder__buttonsWrapper}>
          <IconButton
            appearance="flat"
            icon={
              <LinkCrossedIcon className={styles.builder__deleteButtonIcon} />
            }
            onClick={onEdgeDelete}
            disabled={!selectedEdge}
            className={cn(styles.builder__deleteButton, {
              [styles.builder__deleteButton_visible]:
                !!selectedEdge && !selectedEdge.data?.protected,
            })}
          />
          <IconButton
            appearance="flat"
            type="button"
            icon={<Copy2Icon className={styles.builder__copyButtonIcon} />}
            disabled={isCreateDisabled}
            onClick={toggleIsCopyStatus}
            className={styles.builder__copyButton}
          />
          <Button
            appearance="flat"
            icon={<PlusIcon className={styles.builder__createButtonIcon} />}
            onClick={toggleIsDetailStatus}
            disabled={isCreateDisabled}
            className={styles.builder__createButton}
          >
            Создать статус
          </Button>
          <IconButton
            appearance="flat"
            icon={<PlusIcon className={styles.builder__createButtonIcon} />}
            onClick={toggleIsDetailStatus}
            disabled={isCreateDisabled}
            className={cn(
              styles.builder__createButton,
              styles.builder__createButton_mobile
            )}
          />
        </div>
        <div className={styles.builder__footer}>
          <div
            className={cn(styles.builder__wrapperSaveButtons, {
              [styles.builder__wrapperSaveButtons_hidden]:
                !isChanges || isDetailStatusOpen,
            })}
          >
            <Button
              disabled={disabledSave}
              className={styles.builder__wrapperSaveButtonsItem}
              onClick={onSaveBuilderValues}
            >
              Сохранить
            </Button>
            <Button
              onClick={onSetBuilderValues}
              disabled={!isChanges}
              className={styles.builder__wrapperSaveButtonsItem}
              appearance="outline"
            >
              Отмена
            </Button>
          </div>
          <EllipseIconButton
            onClick={toggleIsDetailStatus}
            className={cn(styles.builder__editButton, {
              [styles.builder__editButton_visible]:
                selectedNode && !isDetailStatusOpen,
            })}
            icon={
              selectedNode?.data.modified || selectedNode?.data.deletable ? (
                <Pen3Icon />
              ) : (
                <EyeIcon />
              )
            }
          />
        </div>
      </ReactFlow>
      {statusDetail}
      {copyStatusModel}
    </div>
  );
};
