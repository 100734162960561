import cn from 'clsx';
import { FC } from 'react';
import { Controller } from 'react-hook-form';

import { JiraConnectionStatus } from '@entities/jira-integrations';
import { JiraIntegrationCreateHookType } from '@features/jira-integrations/model';
import {
  Button,
  CheckMarkIcon,
  CloseIcon,
  getValueFromValueType,
  Input,
  JiraLogoIcon,
  RefreshIcon,
  Select,
  Typography,
  TypographyVariants,
} from '@shared';

import { JIRA_CONNECTION_STATUS_TEXT_MAP } from '../../../../config';

import styles from './JiraIntegrationCreateLeftForm.module.scss';

type JiraIntegrationCreateLeftFormProps = {
  form: JiraIntegrationCreateHookType;
};

const jiraConnectionStatusIconMap = {
  [JiraConnectionStatus.DEFAULT]: (
    <RefreshIcon
      className={styles.jiraIntegrationCreateLeftForm__checkConnectionIcon}
    />
  ),
  [JiraConnectionStatus.SUCCESS]: (
    <CheckMarkIcon
      className={
        styles.jiraIntegrationCreateLeftForm__checkConnectionIcon_success
      }
    />
  ),
  [JiraConnectionStatus.ERROR]: (
    <CloseIcon
      className={styles.jiraIntegrationCreateLeftForm__checkConnectionIcon}
    />
  ),
};

export const JiraIntegrationCreateLeftForm: FC<
  JiraIntegrationCreateLeftFormProps
> = ({ form }) => {
  const { state, methods } = form;

  const {
    organizationsOptions,
    systemsOptions,
    jiraConnectionStatus,
    isSuccessConnect,
    isErrorConnect,
    control,
    errors,
    nameInputOptions,
    loginInputOptions,
    passwordInputOptions,
    jiraUrlInputOptions,
    projectKeyInputOptions,
    isCheckConnectionDisabled,
    ticketTypesOptions,
    isTicketTypeDisabled,
  } = state;

  const {
    checkConnectionHandler,
    fetchSPStatuses,
    setTicketTypeIdInKey,
    resetSPStatusesCompare,
  } = methods;

  return (
    <div className={styles.jiraIntegrationCreateLeftForm__leftContainer}>
      <JiraLogoIcon />
      <div className={styles.jiraIntegrationCreateLeftForm__leftWrapper}>
        <div className={styles.jiraIntegrationCreateLeftForm__connectionForm}>
          <Input
            label="Название"
            disabled={!isSuccessConnect}
            {...nameInputOptions}
            className={cn(styles.jiraIntegrationCreateLeftForm__element_mb)}
          />
          <Input
            label="Сервер"
            disabled={isSuccessConnect}
            error={isErrorConnect || !!errors.jiraUrl}
            {...jiraUrlInputOptions}
            className={cn(styles.jiraIntegrationCreateLeftForm__element_mb)}
          />
          <div className={styles.jiraIntegrationCreateLeftForm__loginBlock}>
            <Input
              label="Логин"
              disabled={isSuccessConnect}
              error={isErrorConnect || !!errors.login}
              {...loginInputOptions}
              className={cn(
                styles.jiraIntegrationCreateLeftForm__element_mb,
                styles.jiraIntegrationCreateLeftForm__element_oneOfTwo
              )}
            />
            <Input
              label="Токен"
              disabled={isSuccessConnect}
              error={isErrorConnect || !!errors.password}
              {...passwordInputOptions}
              type="password"
              isPassword
              className={cn(
                styles.jiraIntegrationCreateLeftForm__element_mb,
                styles.jiraIntegrationCreateLeftForm__element_oneOfTwo
              )}
            />
          </div>
          <Input
            label="Ключ"
            disabled={isSuccessConnect}
            error={isErrorConnect || !!errors.projectKey}
            {...projectKeyInputOptions}
            className={cn(styles.jiraIntegrationCreateLeftForm__element_mb)}
          />
          <div
            className={
              styles.jiraIntegrationCreateLeftForm__checkConnectionBlock
            }
          >
            <Button
              appearance="outline"
              type="button"
              disabled={isCheckConnectionDisabled}
              className={
                styles.jiraIntegrationCreateLeftForm__checkConnectionButton
              }
              onClick={checkConnectionHandler}
            >
              Проверить подключение
            </Button>
            <div
              className={
                styles.jiraIntegrationCreateLeftForm__checkConnectionTitleBlock
              }
            >
              {jiraConnectionStatusIconMap[jiraConnectionStatus]}
              <Typography
                variant={TypographyVariants.b3}
                className={
                  styles.jiraIntegrationCreateLeftForm__checkConnectionText
                }
              >
                {JIRA_CONNECTION_STATUS_TEXT_MAP[jiraConnectionStatus]}
              </Typography>
            </div>
          </div>
        </div>
        <div
          className={
            styles.jiraIntegrationCreateLeftForm__selectConnectionBlock
          }
        >
          <Typography
            variant={TypographyVariants.h4}
            className={styles.jiraIntegrationCreateLeftForm__element_mb}
          >
            Связь с организацией
          </Typography>
          <Controller
            control={control}
            rules={{ required: true }}
            name="organizationId"
            key="organizationId"
            render={({ field }) => (
              <Select<string>
                label="Организация"
                mobileModalTitle="организацию"
                onChange={field.onChange}
                value={field.value}
                options={organizationsOptions}
                disabled={!isSuccessConnect}
                className={styles.jiraIntegrationCreateLeftForm__element_mb}
              />
            )}
          />
          <Controller
            control={control}
            rules={{ required: true }}
            name="systemId"
            key="systemId"
            render={({ field }) => (
              <Select<string>
                label="Система"
                mobileModalTitle="cистему"
                onChange={(value) => {
                  field.onChange(value);
                  resetSPStatusesCompare();
                }}
                value={field.value}
                options={systemsOptions}
                disabled={!isSuccessConnect}
              />
            )}
          />
        </div>
        <div
          className={
            styles.jiraIntegrationCreateLeftForm__selectConnectionBlock
          }
        >
          <Typography
            variant={TypographyVariants.h4}
            className={styles.jiraIntegrationCreateLeftForm__element_mb}
          >
            Выберите тип тикета для настройки маппинга
          </Typography>
          <Controller
            control={control}
            rules={{ required: true }}
            name="ticketTypeId"
            render={({ field }) => (
              <Select<string>
                label="Тип тикета"
                mobileModalTitle="Тип тикета"
                onChange={(value) => {
                  const typeId = getValueFromValueType(value) ?? '';
                  fetchSPStatuses(typeId);
                  field.onChange(value);
                  setTicketTypeIdInKey(typeId);
                }}
                value={field.value}
                options={ticketTypesOptions}
                disabled={isTicketTypeDisabled}
                className={styles.jiraIntegrationCreateLeftForm__element_mb}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};
