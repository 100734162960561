import { Environment, EnvironmentsFilter } from '@entities/environment';
import { SystemPriority } from '@entities/system/model';
import { TicketType } from '@entities/ticket';
import { ResponseWithMeta } from '@shared';

import {
  CheckConnectionToJiraData,
  CreateMapping,
  EditJiraIntegrationsActivePayload,
  FetchArgs,
  FetchInfoForJiraIntegrationPayload,
  Field,
  FieldsIntegration,
  JiraIntegration,
  JiraIntegrationAddNewTicketTypeForm,
  JiraIntegrationCreatePayload,
  JiraIntegrationEditDataToRequest,
  JiraIntegrationsFilter,
  JiraPriority,
  JiraStatus,
  JiraStatusesPayload,
  JiraStatusesRequest,
  JiraSynchronization,
  JiraSynchronizationCreateDataToRequest,
  JiraSynchronizationFilter,
  JiraTicketType,
  SPStatusesForCompare,
  Status,
  SystemPriorityForCompare,
} from './types';

export enum Jira {
  FETCH_JIRA_INTEGRATIONS_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_JIRA_INTEGRATIONS_SUCCESS',
  FETCH_JIRA_INTEGRATIONS_REQUEST = 'JIRA_INTEGRATIONS/FETCH_JIRA_INTEGRATIONS_REQUEST',
  JIRA_INTEGRATIONS_LOADING_SHOW = 'JIRA_INTEGRATIONS/JIRA_INTEGRATIONS_LOADING_SHOW',
  JIRA_INTEGRATIONS_LOADING_HIDE = 'JIRA_INTEGRATIONS/JIRA_INTEGRATIONS_LOADING_HIDE',
  SET_CURRENT_JIRA_INTEGRATIONS_PAGE = 'JIRA_INTEGRATIONS/SET_CURRENT_JIRA_INTEGRATIONS_PAGE',
  SET_SORT_JIRA_INTEGRATIONS = 'JIRA_INTEGRATIONS/SET_SORT_JIRA_INTEGRATIONS',
  SET_FILTER_JIRA_INTEGRATIONS = 'JIRA_INTEGRATIONS/SET_FILTER_JIRA_INTEGRATIONS',
  SET_SIZE_JIRA_INTEGRATIONS_PAGE = 'JIRA_INTEGRATIONS/SET_SIZE_JIRA_INTEGRATIONS_PAGE',
  RESET_JIRA_STATE = 'JIRA_INTEGRATIONS/RESET_JIRA_STATE',
  FETCH_JIRA_STATUSES_REQUEST = 'JIRA_INTEGRATIONS/FETCH_JIRA_STATUSES_REQUEST',
  FETCH_JIRA_STATUSES_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_JIRA_STATUSES_SUCCESS',
  FETCH_JIRA_PRIORITIES_REQUEST = 'JIRA_INTEGRATIONS/FETCH_JIRA_PRIORITIES_REQUEST',
  FETCH_JIRA_PRIORITIES_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_JIRA_PRIORITIES_SUCCESS',
  FETCH_JIRA_PRIORITIES_BY_INTEGRATION_ID_REQUEST = 'JIRA_INTEGRATIONS/FETCH_JIRA_PRIORITIES_BY_INTEGRATION_ID_REQUEST',
  FETCH_JIRA_PRIORITIES_BY_INTEGRATION_ID_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_JIRA_PRIORITIES_BY_INTEGRATION_ID_SUCCESS',
  SET_CURRENT_JIRA_INTEGRATION_ID = 'JIRA_INTEGRATIONS/SET_CURRENT_JIRA_INTEGRATION_ID',
  SET_CURRENT_SYSTEM_ID = 'JIRA_INTEGRATIONS/SET_CURRENT_SYSTEM_ID',
  CREATE_JIRA_INTEGRATION = 'JIRA_INTEGRATIONS/CREATE_JIRA_INTEGRATION',
  EDIT_JIRA_INTEGRATION = 'JIRA_INTEGRATIONS/EDIT_JIRA_INTEGRATION',
  RESET_JIRA_STATUSES = 'JIRA_INTEGRATIONS/RESET_JIRA_STATUSES',
  RESET_JIRA_PRIORITIES = 'JIRA_INTEGRATIONS/RESET_JIRA_PRIORITIES',
  FETCH_JIRA_STATUSES_BY_ID_REQUEST = 'JIRA_INTEGRATIONS/FETCH_JIRA_STATUSES_BY_ID_REQUEST',
  FETCH_JIRA_STATUSES_BY_ID_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_JIRA_STATUSES_BY_ID_SUCCESS',
  FETCH_JIRA_PRIORITIES_BY_ID_REQUEST = 'JIRA_INTEGRATIONS/FETCH_JIRA_PRIORITIES_BY_ID_REQUEST',
  FETCH_JIRA_PRIORITIES_BY_ID_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_JIRA_PRIORITIES_BY_ID_SUCCESS',
  STATUSES_LOADING_SHOW = 'JIRA_INTEGRATIONS/STATUSES_LOADING_SHOW',
  STATUSES_LOADING_HIDE = 'JIRA_INTEGRATIONS/STATUSES_LOADING_HIDE',
  PRIORITIES_LOADING_SHOW = 'JIRA_INTEGRATIONS/PRIORITIES_LOADING_SHOW',
  PRIORITIES_LOADING_HIDE = 'JIRA_INTEGRATIONS/PRIORITIES_LOADING_HIDE',
  DELETE_JIRA_INTEGRATION = 'JIRA_INTEGRATIONS/DELETE_JIRA_INTEGRATION',
  FETCH_JIRA_INTEGRATION_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_JIRA_INTEGRATION_SUCCESS',
  FETCH_JIRA_INTEGRATION_REQUEST = 'JIRA_INTEGRATIONS/FETCH_JIRA_INTEGRATION_REQUEST',
  EDIT_JIRA_INTEGRATION_ACTIVE = 'JIRA_INTEGRATIONS/EDIT_JIRA_INTEGRATION_ACTIVE',
  UPDATE_JIRA_INTEGRATION_BY_ID = 'JIRA_INTEGRATIONS/UPDATE_JIRA_INTEGRATION_BY_ID',
  FETCH_SP_FIELDS_REQUEST = 'JIRA_INTEGRATIONS/FETCH_SP_FIELDS_REQUEST',
  FETCH_SP_FIELDS_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_SP_FIELDS_SUCCESS',
  FETCH_JIRA_FIELDS_REQUEST = 'JIRA_INTEGRATIONS/FETCH_JIRA_FIELDS_REQUEST',
  FETCH_JIRA_FIELDS_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_JIRA_FIELDS_SUCCESS',
  FETCH_INFO_FOR_JIRA_INTEGRATION = 'JIRA_INTEGRATIONS/FETCH_INFO_FOR_JIRA_INTEGRATION',
  JIRA_INTEGRATION_INFO_LOADING_SHOW = 'JIRA_INTEGRATIONS/JIRA_INTEGRATION_INFO_LOADING_SHOW',
  JIRA_INTEGRATION_INFO_LOADING_HIDE = 'JIRA_INTEGRATIONS/JIRA_INTEGRATION_INFO_LOADING_HIDE',
  FETCH_FIELDS_INTEGRATIONS_REQUEST = 'JIRA_INTEGRATIONS/FETCH_FIELDS_INTEGRATIONS_REQUEST',
  FETCH_FIELDS_INTEGRATIONS_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_FIELDS_INTEGRATIONS_SUCCESS',
  CREATE_FIELDS_INTEGRATION = 'JIRA_INTEGRATIONS/CREATE_FIELDS_INTEGRATION',
  DELETE_FIELDS_INTEGRATION = 'JIRA_INTEGRATIONS/DELETE_FIELDS_INTEGRATION',
  EDIT_FIELDS_INTEGRATION = 'JIRA_INTEGRATIONS/EDIT_FIELDS_INTEGRATION',
  FETCH_SYSTEM_PRIORITIES_REQUEST = 'JIRA_INTEGRATIONS/FETCH_SYSTEM_PRIORITIES_REQUEST_JIRA',
  FETCH_SYSTEM_PRIORITIES_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_SYSTEM_PRIORITIES_SUCCESS_JIRA',
  FETCH_SYSTEM_PRIORITIES_FOR_COMPARE_REQUEST = 'JIRA_INTEGRATIONS/FETCH_SYSTEM_PRIORITIES_FOR_COMPARE_REQUEST',
  FETCH_SYSTEM_PRIORITIES_FOR_COMPARE_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_SYSTEM_PRIORITIES_FOR_COMPARE_SUCCESS',
  RESET_SYSTEM_PRIORITIES = 'JIRA_INTEGRATIONS/RESET_SYSTEM_PRIORITIES',
  FETCH_JIRA_SP_STATUSES_REQUEST = 'JIRA_INTEGRATIONS/FETCH_JIRA_SP_STATUSES_REQUEST',
  FETCH_JIRA_SP_STATUSES_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_JIRA_SP_STATUSES_SUCCESS',
  SET_SP_STATUSES_FOR_COMPARE = 'JIRA_INTEGRATIONS/SET_SP_STATUSES_FOR_COMPARE',
  RESET_SP_STATUSES_FOR_COMPARE = 'JIRA_INTEGRATIONS/RESET_SP_STATUSES_FOR_COMPARE',
  FETCH_JIRA_SP_STATUSES_FOR_CURRENT_REQUEST = 'JIRA_INTEGRATIONS/FETCH_JIRA_SP_STATUSES_FOR_CURRENT_REQUEST',
  FETCH_JIRA_SP_STATUSES_FOR_CURRENT_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_JIRA_SP_STATUSES_FOR_CURRENT_SUCCESS',
  RESET_JIRA_SP_STATUSES = 'JIRA_INTEGRATIONS/RESET_JIRA_SP_STATUSES',
  FETCH_TICKET_TYPES_REQUEST = 'JIRA_INTEGRATIONS/FETCH_TICKET_TYPES_REQUEST',
  FETCH_TICKET_TYPES_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_TICKET_TYPES_SUCCESS',
  FETCH_JIRA_TICKET_TYPES_REQUEST = 'JIRA_INTEGRATIONS/FETCH_JIRA_TICKET_TYPES_REQUEST',
  FETCH_JIRA_TICKET_TYPES_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_JIRA_TICKET_TYPES_SUCCESS',
  FETCH_JIRA_TICKET_TYPES_BY_INTEGRATION_ID_REQUEST = 'JIRA_INTEGRATIONS/FETCH_JIRA_TICKET_TYPES_BY_INTEGRATION_ID_REQUEST',
  FETCH_JIRA_TICKET_TYPES_BY_INTEGRATION_ID_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_JIRA_TICKET_TYPES_BY_INTEGRATION_ID_SUCCESS',
  FETCH_JIRA_TICKET_TYPE_BY_INTEGRATION_ID_REQUEST = 'JIRA_INTEGRATIONS/FETCH_JIRA_TICKET_TYPE_BY_INTEGRATION_ID_REQUEST',
  FETCH_JIRA_TICKET_TYPE_BY_INTEGRATION_ID_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_JIRA_TICKET_TYPE_BY_INTEGRATION_ID_SUCCESS',
  SET_CURRENT_TYPE_ID = 'JIRA_INTEGRATIONS/SET_CURRENT_TYPE_ID',
  FETCH_SYNC_TICKET_TYPES_REQUEST = 'JIRA_INTEGRATIONS/FETCH_SYNC_TICKET_TYPES_REQUEST',
  FETCH_SYNC_TICKET_TYPES_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_SYNC_TICKET_TYPES_SUCCESS',
  FETCH_SYNC_PRIORITIES_REQUEST = 'JIRA_INTEGRATIONS/FETCH_SYNC_PRIORITIES_REQUEST',
  FETCH_SYNC_PRIORITIES_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_SYNC_PRIORITIES_SUCCESS',
  FETCH_SYNC_ENVIRONMENTS_REQUEST = 'JIRA_INTEGRATIONS/FETCH_SYNC_ENVIRONMENTS_REQUEST',
  FETCH_SYNC_ENVIRONMENTS_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_SYNC_ENVIRONMENTS_SUCCESS',
  FETCH_SYNC_STATUSES_REQUEST = 'JIRA_INTEGRATIONS/FETCH_SYNC_STATUSES_REQUEST',
  FETCH_SYNC_STATUSES_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_SYNC_STATUSES_SUCCESS',
  FETCH_SYNC_SPECIALISTS_REQUEST = 'JIRA_INTEGRATIONS/FETCH_SYNC_SPECIALISTS_REQUEST',
  FETCH_SYNC_SPECIALISTS_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_SYNC_SPECIALISTS_SUCCESS',
  FETCH_SYNC_CLIENTS_REQUEST = 'JIRA_INTEGRATIONS/FETCH_SYNC_CLIENTS_REQUEST',
  FETCH_SYNC_CLIENTS_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_SYNC_CLIENTS_SUCCESS',
  FETCH_JIRA_SYNC_FILTER_REQUEST = 'JIRA_INTEGRATIONS/FETCH_JIRA_SYNC_FILTER_REQUEST',
  FETCH_JIRA_SYNC_FILTER_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_JIRA_SYNC_FILTER_SUCCESS',
  EDIT_JIRA_SYNC_REQUEST = 'JIRA_INTEGRATIONS/EDIT_JIRA_SYNC_REQUEST',
  EDIT_JIRA_SYNC_SUCCESS = 'JIRA_INTEGRATIONS/EDIT_JIRA_SYNC_SUCCESS',
  RESET_JIRA_SYNC = 'JIRA_INTEGRATIONS/RESET_JIRA_SYNC',
  FETCH_JIRA_STATUSES_BY_PROPERTY_ID_REQUEST = 'JIRA_INTEGRATIONS/FETCH_JIRA_STATUSES_BY_PROPERTY_ID_REQUEST',
  FETCH_JIRA_STATUSES_BY_PROPERTY_ID_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_JIRA_STATUSES_BY_PROPERTY_ID_SUCCESS',
  FETCH_INTEGRATED_TICKET_TYPES_REQUEST = 'JIRA_INTEGRATIONS/FETCH_INTEGRATED_TICKET_TYPES_REQUEST',
  FETCH_INTEGRATED_TICKET_TYPES_SUCCESS = 'JIRA_INTEGRATIONS/FETCH_INTEGRATED_TICKET_TYPES_SUCCESS',
  ADD_NEW_TICKET_TYPE_REQUEST = 'JIRA_INTEGRATIONS/ADD_NEW_TICKET_TYPE_REQUEST',
  ADD_NEW_TICKET_TYPE_SUCCESS = 'JIRA_INTEGRATIONS/ADD_NEW_TICKET_TYPE_SUCCESS',
}

export interface ShowJiraIntegrationsLoadingAction {
  type: Jira.JIRA_INTEGRATIONS_LOADING_SHOW;
}

export interface HideJiraIntegrationsLoadingAction {
  type: Jira.JIRA_INTEGRATIONS_LOADING_HIDE;
}

export interface FetchJiraIntegrationsRequestAction {
  type: Jira.FETCH_JIRA_INTEGRATIONS_REQUEST;
}

export interface FetchJiraIntegrationsSuccessAction {
  type: Jira.FETCH_JIRA_INTEGRATIONS_SUCCESS;
  payload: ResponseWithMeta<JiraIntegration[]>;
}

export interface SetCurrentJiraIntegrationsPageAction {
  type: Jira.SET_CURRENT_JIRA_INTEGRATIONS_PAGE;
  payload: number;
}

export interface SetSizeJiraIntegrationsPageAction {
  type: Jira.SET_SIZE_JIRA_INTEGRATIONS_PAGE;
  payload: number;
}

export interface SetSortJiraIntegrationsAction {
  type: Jira.SET_SORT_JIRA_INTEGRATIONS;
  payload: string;
}

export interface SetJiraIntegrationsFilterAction {
  type: Jira.SET_FILTER_JIRA_INTEGRATIONS;
  payload?: JiraIntegrationsFilter;
}

export interface ResetJiraStateAction {
  type: Jira.RESET_JIRA_STATE;
}

export interface FetchJiraStatusesRequestAction {
  type: Jira.FETCH_JIRA_STATUSES_REQUEST;
  payload: JiraStatusesRequest;
}

export interface FetchJiraStatusesSuccessAction {
  type: Jira.FETCH_JIRA_STATUSES_SUCCESS;
  payload: JiraStatus[];
}

export interface FetchJiraPrioritiesRequestAction {
  type: Jira.FETCH_JIRA_PRIORITIES_REQUEST;
  payload: CheckConnectionToJiraData;
}

export interface FetchJiraPrioritiesSuccessAction {
  type: Jira.FETCH_JIRA_PRIORITIES_SUCCESS;
  payload: JiraPriority[];
}

export interface FetchJiraPrioritiesByIntegrationIdRequestAction {
  type: Jira.FETCH_JIRA_PRIORITIES_BY_INTEGRATION_ID_REQUEST;
  payload: FetchArgs;
}

export interface FetchJiraPrioritiesByIntegrationIdSuccessAction {
  type: Jira.FETCH_JIRA_PRIORITIES_BY_INTEGRATION_ID_SUCCESS;
  payload: JiraPriority[];
}

export interface SetCurrentJiraIntegrationIdAction {
  type: Jira.SET_CURRENT_JIRA_INTEGRATION_ID;
  payload?: string;
}

export interface SetCurrentSystemIdAction {
  type: Jira.SET_CURRENT_SYSTEM_ID;
  payload?: string;
}

export interface CreateJiraIntegrationAction {
  type: Jira.CREATE_JIRA_INTEGRATION;
  payload: JiraIntegrationCreatePayload;
}

export interface CreateMappingPayload {
  payload: CreateMapping;
}

export interface EditJiraIntegrationAction {
  type: Jira.EDIT_JIRA_INTEGRATION;
  payload: JiraIntegrationEditDataToRequest;
}

export interface ResetJiraStatusesAction {
  type: Jira.RESET_JIRA_STATUSES;
}

export interface ResetJiraPrioritiesAction {
  type: Jira.RESET_JIRA_PRIORITIES;
}

export interface FetchJiraPrioritiesByIdAction {
  type: Jira.FETCH_JIRA_PRIORITIES_BY_ID_REQUEST;
  payload: FetchArgs;
}

export interface FetchJiraStatusesByIdAction {
  type: Jira.FETCH_JIRA_STATUSES_BY_ID_REQUEST;
  payload: FetchArgs;
}

export interface ShowStatusesLoadingAction {
  type: Jira.STATUSES_LOADING_SHOW;
}

export interface HideStatusesLoadingAction {
  type: Jira.STATUSES_LOADING_HIDE;
}

export interface ShowPrioritiesLoadingAction {
  type: Jira.PRIORITIES_LOADING_SHOW;
}

export interface HidePrioritiesLoadingAction {
  type: Jira.PRIORITIES_LOADING_HIDE;
}

export interface FetchJiraStatusesByIdSuccessAction {
  type: Jira.FETCH_JIRA_STATUSES_BY_ID_SUCCESS;
  payload: JiraStatus[];
}

export interface FetchJiraPrioritiesByIdSuccessAction {
  type: Jira.FETCH_JIRA_PRIORITIES_BY_ID_SUCCESS;
  payload: JiraPriority[];
}

export interface DeleteJiraIntegrationAction {
  type: Jira.DELETE_JIRA_INTEGRATION;
  payload: string;
}

export interface FetchJiraIntegrationRequestAction {
  type: Jira.FETCH_JIRA_INTEGRATION_REQUEST;
  payload: string;
}

export interface FetchJiraIntegrationSuccessAction {
  type: Jira.FETCH_JIRA_INTEGRATION_SUCCESS;
  payload?: JiraIntegration;
}

export interface EditJiraIntegrationsActiveAction {
  type: Jira.EDIT_JIRA_INTEGRATION_ACTIVE;
  payload: EditJiraIntegrationsActivePayload;
}

export interface UpdateJiraIntegrationByIdAction {
  type: Jira.UPDATE_JIRA_INTEGRATION_BY_ID;
  payload: JiraIntegration;
}

export interface FetchSpFieldsRequestAction {
  type: Jira.FETCH_SP_FIELDS_REQUEST;
  payload: FetchArgs;
}

export interface FetchSpFieldsSuccessAction {
  type: Jira.FETCH_SP_FIELDS_SUCCESS;
  payload: Field[];
}

export interface FetchJiraFieldsRequestAction {
  type: Jira.FETCH_JIRA_FIELDS_REQUEST;
  payload: FetchArgs;
}

export interface FetchJiraFieldsSuccessAction {
  type: Jira.FETCH_JIRA_FIELDS_SUCCESS;
  payload: Field[];
}

export interface FetchInfoForJiraIntegrationAction {
  type: Jira.FETCH_INFO_FOR_JIRA_INTEGRATION;
  payload: FetchInfoForJiraIntegrationPayload;
}

export interface ShowJiraIntegrationInfoLoadingAction {
  type: Jira.JIRA_INTEGRATION_INFO_LOADING_SHOW;
}

export interface HideJiraIntegrationInfoLoadingAction {
  type: Jira.JIRA_INTEGRATION_INFO_LOADING_HIDE;
}

export interface FetchFieldsIntegrationsRequestAction {
  type: Jira.FETCH_FIELDS_INTEGRATIONS_REQUEST;
  payload: FetchArgs;
}

export interface FetchFieldsIntegrationsSuccessAction {
  type: Jira.FETCH_FIELDS_INTEGRATIONS_SUCCESS;
  payload: FieldsIntegration[];
}

export interface CreateFieldsIntegrationAction {
  type: Jira.CREATE_FIELDS_INTEGRATION;
  payload: FieldsIntegration;
}

export interface DeleteFieldsIntegrationAction {
  type: Jira.DELETE_FIELDS_INTEGRATION;
  payload: string;
}

export interface EditFieldsIntegrationAction {
  type: Jira.EDIT_FIELDS_INTEGRATION;
  payload: FieldsIntegration;
}

export interface FetchSystemPrioritiesRequestAction {
  type: Jira.FETCH_SYSTEM_PRIORITIES_REQUEST;
  payload: string;
}

export interface FetchSystemPrioritiesSuccessAction {
  type: Jira.FETCH_SYSTEM_PRIORITIES_SUCCESS;
  payload: SystemPriority[];
}
export interface FetchSystemPrioritiesForCompareRequestAction {
  type: Jira.FETCH_SYSTEM_PRIORITIES_FOR_COMPARE_REQUEST;
  payload: FetchArgs;
}

export interface FetchSystemPrioritiesForCompareSuccessAction {
  type: Jira.FETCH_SYSTEM_PRIORITIES_FOR_COMPARE_SUCCESS;
  payload: SystemPriorityForCompare;
}

export interface FetchJiraSPStatusesRequestAction {
  type: Jira.FETCH_JIRA_SP_STATUSES_REQUEST;
  payload: FetchArgs;
}

export interface FetchJiraSPStatusesSuccessAction {
  type: Jira.FETCH_JIRA_SP_STATUSES_SUCCESS;
  payload: Status[];
}
export interface SetSPStatusesForCompareAction {
  type: Jira.SET_SP_STATUSES_FOR_COMPARE;
  payload: SPStatusesForCompare;
}
export interface ResetSPStatusesForCompareAction {
  type: Jira.RESET_SP_STATUSES_FOR_COMPARE;
}

export interface ResetSPStatusesSuccessAction {
  type: Jira.RESET_JIRA_SP_STATUSES;
}

export interface FetchJiraSPStatusesForCurrentRequestAction {
  type: Jira.FETCH_JIRA_SP_STATUSES_FOR_CURRENT_REQUEST;
  payload: FetchArgs;
}

export interface FetchJiraSPStatusesForCurrentSuccessAction {
  type: Jira.FETCH_JIRA_SP_STATUSES_FOR_CURRENT_SUCCESS;
  payload: Status[];
}

export interface ResetSystemPrioritiesAction {
  type: Jira.RESET_SYSTEM_PRIORITIES;
}

export interface FetchTicketTypesRequestAction {
  type: Jira.FETCH_TICKET_TYPES_REQUEST;
  payload: string;
}

export interface FetchTicketTypesSuccessAction {
  type: Jira.FETCH_TICKET_TYPES_SUCCESS;
  payload: TicketType[];
}

export interface FetchJiraTicketTypesRequestAction {
  type: Jira.FETCH_JIRA_TICKET_TYPES_REQUEST;
  payload: CheckConnectionToJiraData;
}

export interface FetchJiraTicketTypesSuccessAction {
  type: Jira.FETCH_JIRA_TICKET_TYPES_SUCCESS;
  payload: JiraTicketType[];
}

export interface FetchJiraTicketTypesByIntegrationIdRequestAction {
  type: Jira.FETCH_JIRA_TICKET_TYPES_BY_INTEGRATION_ID_REQUEST;
  payload: string;
}

export interface FetchJiraTicketTypesByIntegrationIdSuccessAction {
  type: Jira.FETCH_JIRA_TICKET_TYPES_BY_INTEGRATION_ID_SUCCESS;
  payload: JiraTicketType[];
}

export interface FetchJiraTicketTypeByIntegrationIdRequestAction {
  type: Jira.FETCH_JIRA_TICKET_TYPE_BY_INTEGRATION_ID_REQUEST;
  payload: string;
}

export interface FetchJiraTicketTypeByIntegrationIdSuccessAction {
  type: Jira.FETCH_JIRA_TICKET_TYPE_BY_INTEGRATION_ID_SUCCESS;
  payload: JiraTicketType[];
}

export interface SetCurrentTypeIdAction {
  type: Jira.SET_CURRENT_TYPE_ID;
  payload?: string;
}

export interface FetchSyncTicketTypesRequestAction {
  type: Jira.FETCH_SYNC_TICKET_TYPES_REQUEST;
  payload: string;
}

export interface FetchSyncTicketTypesSuccessAction {
  type: Jira.FETCH_SYNC_TICKET_TYPES_SUCCESS;
  payload: TicketType[];
}

export interface FetchSyncPrioritiesRequestAction {
  type: Jira.FETCH_SYNC_PRIORITIES_REQUEST;
  payload: string;
}

export interface FetchSyncPrioritiesSuccessAction {
  type: Jira.FETCH_SYNC_PRIORITIES_SUCCESS;
  payload: SystemPriority[];
}

export interface FetchSyncEnvironmentsRequestAction {
  type: Jira.FETCH_SYNC_ENVIRONMENTS_REQUEST;
  payload?: EnvironmentsFilter;
}

export interface FetchSyncEnvironmentsSuccessAction {
  type: Jira.FETCH_SYNC_ENVIRONMENTS_SUCCESS;
  payload: ResponseWithMeta<Environment[]>;
}

export interface FetchSyncStatusesRequestAction {
  type: Jira.FETCH_SYNC_STATUSES_REQUEST;
  payload: string;
}

export interface FetchSyncStatusesSuccessAction {
  type: Jira.FETCH_SYNC_STATUSES_SUCCESS;
  payload: JiraSynchronizationFilter[];
}

export interface FetchSyncSpecialistsRequestAction {
  type: Jira.FETCH_SYNC_SPECIALISTS_REQUEST;
  payload: string;
}

export interface FetchSyncSpecialistsSuccessAction {
  type: Jira.FETCH_SYNC_SPECIALISTS_SUCCESS;
  payload: JiraSynchronizationFilter[];
}

export interface FetchSyncClientsRequestAction {
  type: Jira.FETCH_SYNC_CLIENTS_REQUEST;
  payload: string;
}

export interface FetchSyncClientsSuccessAction {
  type: Jira.FETCH_SYNC_CLIENTS_SUCCESS;
  payload: JiraSynchronizationFilter[];
}

export interface FetchJiraSyncFilterRequestAction {
  type: Jira.FETCH_JIRA_SYNC_FILTER_REQUEST;
  payload: string;
}

export interface FetchJiraSyncFilterSuccessAction {
  type: Jira.FETCH_JIRA_SYNC_FILTER_SUCCESS;
  payload: JiraSynchronization;
}

export interface EditJiraSyncRequestAction {
  type: Jira.EDIT_JIRA_SYNC_REQUEST;
  payload: JiraSynchronizationCreateDataToRequest;
}

export interface EditJiraSyncSuccessAction {
  type: Jira.EDIT_JIRA_SYNC_SUCCESS;
  payload: JiraSynchronization;
}

export interface ResetJiraSyncAction {
  type: Jira.RESET_JIRA_SYNC;
}

export interface FetchJiraStatusesByPropertyIdRequestAction {
  type: Jira.FETCH_JIRA_STATUSES_BY_PROPERTY_ID_REQUEST;
  payload: JiraStatusesPayload;
}

export interface FetchJiraStatusesByPropertyIdSuccessAction {
  type: Jira.FETCH_JIRA_STATUSES_BY_PROPERTY_ID_SUCCESS;
  payload: JiraStatus[];
}

export interface FetchIntegratedTicketTypesRequestAction {
  type: Jira.FETCH_INTEGRATED_TICKET_TYPES_REQUEST;
  payload: string;
}

export interface FetchIntegratedTicketTypesSuccessAction {
  type: Jira.FETCH_INTEGRATED_TICKET_TYPES_SUCCESS;
  payload: string[];
}

export interface AddNewTicketTypeRequestAction {
  type: Jira.ADD_NEW_TICKET_TYPE_REQUEST;
  payload: JiraIntegrationAddNewTicketTypeForm;
}

export interface AddNewTicketTypeSuccessAction {
  type: Jira.ADD_NEW_TICKET_TYPE_SUCCESS;
  payload: JiraIntegration;
}

export type JiraIntegrationsActionsTypes =
  | FetchJiraIntegrationsSuccessAction
  | SetCurrentJiraIntegrationsPageAction
  | SetSizeJiraIntegrationsPageAction
  | SetSortJiraIntegrationsAction
  | SetJiraIntegrationsFilterAction
  | FetchJiraStatusesSuccessAction
  | FetchJiraPrioritiesSuccessAction
  | FetchJiraPrioritiesByIntegrationIdSuccessAction
  | SetCurrentJiraIntegrationIdAction
  | SetCurrentSystemIdAction
  | FetchJiraStatusesByIdSuccessAction
  | FetchJiraPrioritiesByIdSuccessAction
  | FetchJiraIntegrationSuccessAction
  | UpdateJiraIntegrationByIdAction
  | FetchSpFieldsSuccessAction
  | FetchJiraFieldsSuccessAction
  | FetchFieldsIntegrationsSuccessAction
  | FetchSystemPrioritiesSuccessAction
  | ResetSystemPrioritiesAction
  | FetchJiraSPStatusesSuccessAction
  | FetchJiraSPStatusesForCurrentSuccessAction
  | FetchTicketTypesSuccessAction
  | FetchJiraTicketTypesSuccessAction
  | FetchJiraTicketTypesByIntegrationIdSuccessAction
  | FetchJiraTicketTypeByIntegrationIdSuccessAction
  | SetCurrentTypeIdAction
  | FetchSyncTicketTypesSuccessAction
  | FetchSyncPrioritiesSuccessAction
  | FetchSyncEnvironmentsSuccessAction
  | FetchSyncStatusesSuccessAction
  | FetchSyncSpecialistsSuccessAction
  | FetchSyncClientsSuccessAction
  | FetchJiraSyncFilterSuccessAction
  | EditJiraSyncSuccessAction
  | FetchJiraStatusesByPropertyIdSuccessAction
  | FetchIntegratedTicketTypesSuccessAction
  | AddNewTicketTypeSuccessAction
  | SetSPStatusesForCompareAction
  | ResetSPStatusesForCompareAction
  | FetchSystemPrioritiesForCompareSuccessAction;
