import { Environment, EnvironmentsFilter } from '@entities/environment';
import { SystemPriority } from '@entities/system/model';
import { TicketType } from '@entities/ticket';
import { ResponseWithMeta } from '@shared';

import {
  AddNewTicketTypeRequestAction,
  AddNewTicketTypeSuccessAction,
  CreateFieldsIntegrationAction,
  CreateJiraIntegrationAction,
  DeleteFieldsIntegrationAction,
  DeleteJiraIntegrationAction,
  EditFieldsIntegrationAction,
  EditJiraIntegrationAction,
  EditJiraIntegrationsActiveAction,
  EditJiraSyncRequestAction,
  EditJiraSyncSuccessAction,
  FetchFieldsIntegrationsRequestAction,
  FetchFieldsIntegrationsSuccessAction,
  FetchInfoForJiraIntegrationAction,
  FetchIntegratedTicketTypesRequestAction,
  FetchIntegratedTicketTypesSuccessAction,
  FetchJiraFieldsRequestAction,
  FetchJiraFieldsSuccessAction,
  FetchJiraIntegrationRequestAction,
  FetchJiraIntegrationsRequestAction,
  FetchJiraIntegrationsSuccessAction,
  FetchJiraIntegrationSuccessAction,
  FetchJiraPrioritiesByIdAction,
  FetchJiraPrioritiesByIdSuccessAction,
  FetchJiraPrioritiesByIntegrationIdRequestAction,
  FetchJiraPrioritiesByIntegrationIdSuccessAction,
  FetchJiraPrioritiesRequestAction,
  FetchJiraPrioritiesSuccessAction,
  FetchJiraSPStatusesForCurrentRequestAction,
  FetchJiraSPStatusesForCurrentSuccessAction,
  FetchJiraSPStatusesRequestAction,
  FetchJiraSPStatusesSuccessAction,
  FetchJiraStatusesByIdAction,
  FetchJiraStatusesByIdSuccessAction,
  FetchJiraStatusesByPropertyIdRequestAction,
  FetchJiraStatusesByPropertyIdSuccessAction,
  FetchJiraStatusesRequestAction,
  FetchJiraStatusesSuccessAction,
  FetchJiraSyncFilterRequestAction,
  FetchJiraSyncFilterSuccessAction,
  FetchJiraTicketTypeByIntegrationIdRequestAction,
  FetchJiraTicketTypeByIntegrationIdSuccessAction,
  FetchJiraTicketTypesByIntegrationIdRequestAction,
  FetchJiraTicketTypesByIntegrationIdSuccessAction,
  FetchJiraTicketTypesRequestAction,
  FetchJiraTicketTypesSuccessAction,
  FetchSpFieldsRequestAction,
  FetchSpFieldsSuccessAction,
  FetchSyncClientsRequestAction,
  FetchSyncClientsSuccessAction,
  FetchSyncEnvironmentsRequestAction,
  FetchSyncEnvironmentsSuccessAction,
  FetchSyncPrioritiesRequestAction,
  FetchSyncPrioritiesSuccessAction,
  FetchSyncSpecialistsRequestAction,
  FetchSyncSpecialistsSuccessAction,
  FetchSyncStatusesRequestAction,
  FetchSyncStatusesSuccessAction,
  FetchSyncTicketTypesRequestAction,
  FetchSyncTicketTypesSuccessAction,
  FetchSystemPrioritiesForCompareRequestAction,
  FetchSystemPrioritiesForCompareSuccessAction,
  FetchSystemPrioritiesRequestAction,
  FetchSystemPrioritiesSuccessAction,
  FetchTicketTypesRequestAction,
  FetchTicketTypesSuccessAction,
  HideJiraIntegrationInfoLoadingAction,
  HideJiraIntegrationsLoadingAction,
  HidePrioritiesLoadingAction,
  HideStatusesLoadingAction,
  Jira,
  ResetJiraPrioritiesAction,
  ResetJiraStateAction,
  ResetJiraStatusesAction,
  ResetJiraSyncAction,
  ResetSPStatusesForCompareAction,
  ResetSPStatusesSuccessAction,
  ResetSystemPrioritiesAction,
  SetCurrentJiraIntegrationIdAction,
  SetCurrentJiraIntegrationsPageAction,
  SetCurrentSystemIdAction,
  SetCurrentTypeIdAction,
  SetJiraIntegrationsFilterAction,
  SetSizeJiraIntegrationsPageAction,
  SetSortJiraIntegrationsAction,
  SetSPStatusesForCompareAction,
  ShowJiraIntegrationInfoLoadingAction,
  ShowJiraIntegrationsLoadingAction,
  ShowPrioritiesLoadingAction,
  ShowStatusesLoadingAction,
  UpdateJiraIntegrationByIdAction,
} from './actionTypes';
import {
  CheckConnectionToJiraData,
  EditJiraIntegrationsActivePayload,
  FetchArgs,
  FetchInfoForJiraIntegrationPayload,
  Field,
  FieldsIntegration,
  JiraIntegration,
  JiraIntegrationAddNewTicketTypeForm,
  JiraIntegrationCreatePayload,
  JiraIntegrationEditDataToRequest,
  JiraIntegrationsFilter,
  JiraPriority,
  JiraStatus,
  JiraStatusesPayload,
  JiraStatusesRequest,
  JiraSynchronization,
  JiraSynchronizationCreateDataToRequest,
  JiraSynchronizationFilter,
  JiraTicketType,
  SPStatusesForCompare,
  Status,
  SystemPriorityForCompare,
} from './types';

export const showJiraIntegrationsLoading =
  (): ShowJiraIntegrationsLoadingAction => ({
    type: Jira.JIRA_INTEGRATIONS_LOADING_SHOW,
  });

export const hideJiraIntegrationsLoading =
  (): HideJiraIntegrationsLoadingAction => ({
    type: Jira.JIRA_INTEGRATIONS_LOADING_HIDE,
  });

export const fetchJiraIntegrationsRequest =
  (): FetchJiraIntegrationsRequestAction => ({
    type: Jira.FETCH_JIRA_INTEGRATIONS_REQUEST,
  });

export const fetchJiraIntegrationsSuccess = (
  data: ResponseWithMeta<JiraIntegration[]>
): FetchJiraIntegrationsSuccessAction => ({
  type: Jira.FETCH_JIRA_INTEGRATIONS_SUCCESS,
  payload: data,
});

export const setCurrentJiraIntegrationsPage = (
  page: number
): SetCurrentJiraIntegrationsPageAction => ({
  type: Jira.SET_CURRENT_JIRA_INTEGRATIONS_PAGE,
  payload: page,
});

export const setSizeJiraIntegrationsPage = (
  size: number
): SetSizeJiraIntegrationsPageAction => ({
  type: Jira.SET_SIZE_JIRA_INTEGRATIONS_PAGE,
  payload: size,
});

export const setSortJiraIntegrations = (
  sort: string
): SetSortJiraIntegrationsAction => ({
  type: Jira.SET_SORT_JIRA_INTEGRATIONS,
  payload: sort,
});

export const setJiraIntegrationsFilter = (
  filter?: JiraIntegrationsFilter
): SetJiraIntegrationsFilterAction => ({
  type: Jira.SET_FILTER_JIRA_INTEGRATIONS,
  payload: filter,
});

export const resetJiraState = (): ResetJiraStateAction => ({
  type: Jira.RESET_JIRA_STATE,
});

export const fetchJiraStatusesRequest = (
  payload: JiraStatusesRequest
): FetchJiraStatusesRequestAction => ({
  type: Jira.FETCH_JIRA_STATUSES_REQUEST,
  payload,
});

export const fetchJiraStatusesSuccess = (
  payload: JiraStatus[]
): FetchJiraStatusesSuccessAction => ({
  type: Jira.FETCH_JIRA_STATUSES_SUCCESS,
  payload,
});

export const fetchJiraPrioritiesRequest = (
  payload: CheckConnectionToJiraData
): FetchJiraPrioritiesRequestAction => ({
  type: Jira.FETCH_JIRA_PRIORITIES_REQUEST,
  payload,
});

export const fetchJiraPrioritiesSuccess = (
  payload: JiraPriority[]
): FetchJiraPrioritiesSuccessAction => ({
  type: Jira.FETCH_JIRA_PRIORITIES_SUCCESS,
  payload,
});

export const fetchJiraPrioritiesByIntegrationIdRequest = (
  payload: FetchArgs
): FetchJiraPrioritiesByIntegrationIdRequestAction => ({
  type: Jira.FETCH_JIRA_PRIORITIES_BY_INTEGRATION_ID_REQUEST,
  payload,
});

export const fetchJiraPrioritiesByIntegrationIdSuccess = (
  payload: JiraPriority[]
): FetchJiraPrioritiesByIntegrationIdSuccessAction => ({
  type: Jira.FETCH_JIRA_PRIORITIES_BY_INTEGRATION_ID_SUCCESS,
  payload,
});

export const setCurrentJiraIntegrationId = (
  payload?: string
): SetCurrentJiraIntegrationIdAction => ({
  type: Jira.SET_CURRENT_JIRA_INTEGRATION_ID,
  payload,
});

export const setCurrentSystemId = (
  payload?: string
): SetCurrentSystemIdAction => ({
  type: Jira.SET_CURRENT_SYSTEM_ID,
  payload,
});

export const createJiraIntegration = (
  payload: JiraIntegrationCreatePayload
): CreateJiraIntegrationAction => ({
  type: Jira.CREATE_JIRA_INTEGRATION,
  payload,
});

export const editJiraIntegration = (
  payload: JiraIntegrationEditDataToRequest
): EditJiraIntegrationAction => ({
  type: Jira.EDIT_JIRA_INTEGRATION,
  payload,
});

export const resetJiraStatuses = (): ResetJiraStatusesAction => ({
  type: Jira.RESET_JIRA_STATUSES,
});

export const resetJiraPriorities = (): ResetJiraPrioritiesAction => ({
  type: Jira.RESET_JIRA_PRIORITIES,
});

export const fetchJiraStatusesById = (
  payload: FetchArgs
): FetchJiraStatusesByIdAction => ({
  type: Jira.FETCH_JIRA_STATUSES_BY_ID_REQUEST,
  payload,
});

export const fetchJiraPrioritiesById = (
  payload: FetchArgs
): FetchJiraPrioritiesByIdAction => ({
  type: Jira.FETCH_JIRA_PRIORITIES_BY_ID_REQUEST,
  payload,
});

export const showStatusesLoading = (): ShowStatusesLoadingAction => ({
  type: Jira.STATUSES_LOADING_SHOW,
});

export const hideStatusesLoading = (): HideStatusesLoadingAction => ({
  type: Jira.STATUSES_LOADING_HIDE,
});

export const showPrioritiesLoading = (): ShowPrioritiesLoadingAction => ({
  type: Jira.PRIORITIES_LOADING_SHOW,
});

export const hidePrioritiesLoading = (): HidePrioritiesLoadingAction => ({
  type: Jira.PRIORITIES_LOADING_HIDE,
});

export const fetchJiraPrioritiesByIdSuccess = (
  payload: JiraPriority[]
): FetchJiraPrioritiesByIdSuccessAction => ({
  type: Jira.FETCH_JIRA_PRIORITIES_BY_ID_SUCCESS,
  payload,
});

export const fetchJiraStatusesByIdSuccess = (
  payload: JiraStatus[]
): FetchJiraStatusesByIdSuccessAction => ({
  type: Jira.FETCH_JIRA_STATUSES_BY_ID_SUCCESS,
  payload,
});

export const deleteJiraIntegration = (
  id: string
): DeleteJiraIntegrationAction => ({
  type: Jira.DELETE_JIRA_INTEGRATION,
  payload: id,
});

export const fetchJiraIntegrationRequest = (
  id: string
): FetchJiraIntegrationRequestAction => ({
  type: Jira.FETCH_JIRA_INTEGRATION_REQUEST,
  payload: id,
});

export const fetchJiraIntegrationSuccess = (
  data?: JiraIntegration
): FetchJiraIntegrationSuccessAction => ({
  type: Jira.FETCH_JIRA_INTEGRATION_SUCCESS,
  payload: data,
});

export const editJiraIntegrationsActive = (
  payload: EditJiraIntegrationsActivePayload
): EditJiraIntegrationsActiveAction => ({
  type: Jira.EDIT_JIRA_INTEGRATION_ACTIVE,
  payload,
});

export const updateJiraIntegrationById = (
  payload: JiraIntegration
): UpdateJiraIntegrationByIdAction => ({
  type: Jira.UPDATE_JIRA_INTEGRATION_BY_ID,
  payload,
});

export const fetchSpFieldsRequest = (
  payload: FetchArgs
): FetchSpFieldsRequestAction => ({
  type: Jira.FETCH_SP_FIELDS_REQUEST,
  payload,
});

export const fetchSpFieldsSuccess = (
  payload: Field[]
): FetchSpFieldsSuccessAction => ({
  type: Jira.FETCH_SP_FIELDS_SUCCESS,
  payload,
});

export const fetchJiraFieldsRequest = (
  payload: FetchArgs
): FetchJiraFieldsRequestAction => ({
  type: Jira.FETCH_JIRA_FIELDS_REQUEST,
  payload,
});

export const fetchJiraFieldsSuccess = (
  payload: Field[]
): FetchJiraFieldsSuccessAction => ({
  type: Jira.FETCH_JIRA_FIELDS_SUCCESS,
  payload,
});

export const fetchInfoForJiraIntegration = (
  payload: FetchInfoForJiraIntegrationPayload
): FetchInfoForJiraIntegrationAction => ({
  type: Jira.FETCH_INFO_FOR_JIRA_INTEGRATION,
  payload,
});

export const showJiraIntegrationInfoLoading =
  (): ShowJiraIntegrationInfoLoadingAction => ({
    type: Jira.JIRA_INTEGRATION_INFO_LOADING_SHOW,
  });

export const hideJiraIntegrationInfoLoading =
  (): HideJiraIntegrationInfoLoadingAction => ({
    type: Jira.JIRA_INTEGRATION_INFO_LOADING_HIDE,
  });

export const fetchFieldsIntegrationsRequest = (
  payload: FetchArgs
): FetchFieldsIntegrationsRequestAction => ({
  type: Jira.FETCH_FIELDS_INTEGRATIONS_REQUEST,
  payload,
});

export const fetchFieldsIntegrationsSuccess = (
  payload: FieldsIntegration[]
): FetchFieldsIntegrationsSuccessAction => ({
  type: Jira.FETCH_FIELDS_INTEGRATIONS_SUCCESS,
  payload,
});

export const createFieldsIntegration = (
  data: FieldsIntegration
): CreateFieldsIntegrationAction => ({
  type: Jira.CREATE_FIELDS_INTEGRATION,
  payload: data,
});

export const deleteFieldsIntegration = (
  id: string
): DeleteFieldsIntegrationAction => ({
  type: Jira.DELETE_FIELDS_INTEGRATION,
  payload: id,
});

export const editFieldsIntegration = (
  data: FieldsIntegration
): EditFieldsIntegrationAction => ({
  type: Jira.EDIT_FIELDS_INTEGRATION,
  payload: data,
});

export const fetchSystemPrioritiesRequest = (
  systemId: string
): FetchSystemPrioritiesRequestAction => ({
  type: Jira.FETCH_SYSTEM_PRIORITIES_REQUEST,
  payload: systemId,
});

export const fetchSystemPrioritiesSuccess = (
  priorities: SystemPriority[]
): FetchSystemPrioritiesSuccessAction => ({
  type: Jira.FETCH_SYSTEM_PRIORITIES_SUCCESS,
  payload: priorities,
});

export const fetchSystemPrioritiesForCompareRequest = (
  payload: FetchArgs
): FetchSystemPrioritiesForCompareRequestAction => ({
  type: Jira.FETCH_SYSTEM_PRIORITIES_FOR_COMPARE_REQUEST,
  payload,
});

export const fetchSystemPrioritiesForCompareSuccess = (
  priorities: SystemPriorityForCompare
): FetchSystemPrioritiesForCompareSuccessAction => ({
  type: Jira.FETCH_SYSTEM_PRIORITIES_FOR_COMPARE_SUCCESS,
  payload: priorities,
});

export const fetchSPStatusesRequest = (
  payload: FetchArgs
): FetchJiraSPStatusesRequestAction => ({
  type: Jira.FETCH_JIRA_SP_STATUSES_REQUEST,
  payload,
});

export const fetchSPStatusesSuccess = (
  payload: Status[]
): FetchJiraSPStatusesSuccessAction => ({
  type: Jira.FETCH_JIRA_SP_STATUSES_SUCCESS,
  payload,
});

export const resetSPStatuses = (): ResetSPStatusesSuccessAction => ({
  type: Jira.RESET_JIRA_SP_STATUSES,
});

export const fetchSPStatusesForCurrentRequest = (
  payload: FetchArgs
): FetchJiraSPStatusesForCurrentRequestAction => ({
  type: Jira.FETCH_JIRA_SP_STATUSES_FOR_CURRENT_REQUEST,
  payload,
});

export const fetchSPStatusesForCurrentSuccess = (
  payload: Status[]
): FetchJiraSPStatusesForCurrentSuccessAction => ({
  type: Jira.FETCH_JIRA_SP_STATUSES_FOR_CURRENT_SUCCESS,
  payload,
});

export const resetSystemPriorities = (): ResetSystemPrioritiesAction => ({
  type: Jira.RESET_SYSTEM_PRIORITIES,
});

export const fetchTicketTypeRequest = (
  payload: string
): FetchTicketTypesRequestAction => ({
  type: Jira.FETCH_TICKET_TYPES_REQUEST,
  payload,
});

export const fetchTicketTypeSuccess = (
  payload: TicketType[]
): FetchTicketTypesSuccessAction => ({
  type: Jira.FETCH_TICKET_TYPES_SUCCESS,
  payload,
});

export const fetchJiraTicketTypeRequest = (
  payload: CheckConnectionToJiraData
): FetchJiraTicketTypesRequestAction => ({
  type: Jira.FETCH_JIRA_TICKET_TYPES_REQUEST,
  payload,
});

export const fetchJiraTicketTypeSuccess = (
  payload: JiraTicketType[]
): FetchJiraTicketTypesSuccessAction => ({
  type: Jira.FETCH_JIRA_TICKET_TYPES_SUCCESS,
  payload,
});

export const fetchJiraTicketTypesByIntegrationIdRequest = (
  payload: string
): FetchJiraTicketTypesByIntegrationIdRequestAction => ({
  type: Jira.FETCH_JIRA_TICKET_TYPES_BY_INTEGRATION_ID_REQUEST,
  payload,
});

export const fetchJiraTicketTypesByIntegrationIdSuccess = (
  payload: JiraTicketType[]
): FetchJiraTicketTypesByIntegrationIdSuccessAction => ({
  type: Jira.FETCH_JIRA_TICKET_TYPES_BY_INTEGRATION_ID_SUCCESS,
  payload,
});

export const fetchJiraTicketTypeByIntegrationIdRequest = (
  payload: string
): FetchJiraTicketTypeByIntegrationIdRequestAction => ({
  type: Jira.FETCH_JIRA_TICKET_TYPE_BY_INTEGRATION_ID_REQUEST,
  payload,
});

export const fetchJiraTicketTypeByIntegrationIdSuccess = (
  payload: JiraTicketType[]
): FetchJiraTicketTypeByIntegrationIdSuccessAction => ({
  type: Jira.FETCH_JIRA_TICKET_TYPE_BY_INTEGRATION_ID_SUCCESS,
  payload,
});

export const setCurrentTypeId = (payload?: string): SetCurrentTypeIdAction => ({
  type: Jira.SET_CURRENT_TYPE_ID,
  payload,
});

export const fetchSyncTicketTypesRequest = (
  payload: string
): FetchSyncTicketTypesRequestAction => ({
  type: Jira.FETCH_SYNC_TICKET_TYPES_REQUEST,
  payload,
});

export const fetchSyncTicketTypesSuccess = (
  payload: TicketType[]
): FetchSyncTicketTypesSuccessAction => ({
  type: Jira.FETCH_SYNC_TICKET_TYPES_SUCCESS,
  payload,
});

export const fetchSyncPrioritiesRequest = (
  payload: string
): FetchSyncPrioritiesRequestAction => ({
  type: Jira.FETCH_SYNC_PRIORITIES_REQUEST,
  payload,
});

export const fetchSyncPrioritiesSuccess = (
  payload: SystemPriority[]
): FetchSyncPrioritiesSuccessAction => ({
  type: Jira.FETCH_SYNC_PRIORITIES_SUCCESS,
  payload,
});

export const fetchSyncEnvironmentsRequest = (
  payload: EnvironmentsFilter
): FetchSyncEnvironmentsRequestAction => ({
  type: Jira.FETCH_SYNC_ENVIRONMENTS_REQUEST,
  payload,
});

export const fetchSyncEnvironmentsSuccess = (
  payload: ResponseWithMeta<Environment[]>
): FetchSyncEnvironmentsSuccessAction => ({
  type: Jira.FETCH_SYNC_ENVIRONMENTS_SUCCESS,
  payload,
});

export const fetchSyncStatusesRequest = (
  payload: string
): FetchSyncStatusesRequestAction => ({
  type: Jira.FETCH_SYNC_STATUSES_REQUEST,
  payload,
});

export const fetchSyncStatusesSuccess = (
  payload: JiraSynchronizationFilter[]
): FetchSyncStatusesSuccessAction => ({
  type: Jira.FETCH_SYNC_STATUSES_SUCCESS,
  payload,
});

export const fetchSyncSpecialistsRequest = (
  payload: string
): FetchSyncSpecialistsRequestAction => ({
  type: Jira.FETCH_SYNC_SPECIALISTS_REQUEST,
  payload,
});

export const fetchSyncSpecialistsSuccess = (
  payload: JiraSynchronizationFilter[]
): FetchSyncSpecialistsSuccessAction => ({
  type: Jira.FETCH_SYNC_SPECIALISTS_SUCCESS,
  payload,
});

export const fetchSyncClientsRequest = (
  payload: string
): FetchSyncClientsRequestAction => ({
  type: Jira.FETCH_SYNC_CLIENTS_REQUEST,
  payload,
});

export const fetchSyncClientsSuccess = (
  payload: JiraSynchronizationFilter[]
): FetchSyncClientsSuccessAction => ({
  type: Jira.FETCH_SYNC_CLIENTS_SUCCESS,
  payload,
});

export const fetchJiraSyncFilterRequest = (
  payload: string
): FetchJiraSyncFilterRequestAction => ({
  type: Jira.FETCH_JIRA_SYNC_FILTER_REQUEST,
  payload,
});

export const fetchJiraSyncFilterSuccess = (
  payload: JiraSynchronization
): FetchJiraSyncFilterSuccessAction => ({
  type: Jira.FETCH_JIRA_SYNC_FILTER_SUCCESS,
  payload,
});

export const editJiraSync = (
  payload: JiraSynchronizationCreateDataToRequest
): EditJiraSyncRequestAction => ({
  type: Jira.EDIT_JIRA_SYNC_REQUEST,
  payload,
});

export const editJiraSyncSuccess = (
  payload: JiraSynchronization
): EditJiraSyncSuccessAction => ({
  type: Jira.EDIT_JIRA_SYNC_SUCCESS,
  payload,
});

export const resetJiraSync = (): ResetJiraSyncAction => ({
  type: Jira.RESET_JIRA_SYNC,
});

export const fetchJiraStatusesByPropertyIdRequest = (
  payload: JiraStatusesPayload
): FetchJiraStatusesByPropertyIdRequestAction => ({
  type: Jira.FETCH_JIRA_STATUSES_BY_PROPERTY_ID_REQUEST,
  payload,
});

export const fetchJiraStatusesByPropertyIdSuccess = (
  payload: JiraStatus[]
): FetchJiraStatusesByPropertyIdSuccessAction => ({
  type: Jira.FETCH_JIRA_STATUSES_BY_PROPERTY_ID_SUCCESS,
  payload,
});

export const fetchIntegratedTicketTypesRequest = (
  payload: string
): FetchIntegratedTicketTypesRequestAction => ({
  type: Jira.FETCH_INTEGRATED_TICKET_TYPES_REQUEST,
  payload,
});

export const fetchIntegratedTicketTypesSuccess = (
  payload: string[]
): FetchIntegratedTicketTypesSuccessAction => ({
  type: Jira.FETCH_INTEGRATED_TICKET_TYPES_SUCCESS,
  payload,
});

export const addNewTicketTypeRequest = (
  payload: JiraIntegrationAddNewTicketTypeForm
): AddNewTicketTypeRequestAction => ({
  type: Jira.ADD_NEW_TICKET_TYPE_REQUEST,
  payload,
});

export const addNewTicketTypeSuccess = (
  payload: JiraIntegration
): AddNewTicketTypeSuccessAction => ({
  type: Jira.ADD_NEW_TICKET_TYPE_SUCCESS,
  payload,
});

export const setSPStatusesForCompare = (
  payload: SPStatusesForCompare
): SetSPStatusesForCompareAction => ({
  type: Jira.SET_SP_STATUSES_FOR_COMPARE,
  payload,
});

export const resetSPStatusesForCompare =
  (): ResetSPStatusesForCompareAction => ({
    type: Jira.RESET_SP_STATUSES_FOR_COMPARE,
  });
