import { debounce } from 'lodash';
import { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  fetchOrganizationsAddRequest,
  OrganizationSelect,
  setCurrentOrganizationsAddPage,
  setOrganizationsAddFilterAction,
} from '@entities/organization';
import {
  ResponsibilityFilter,
  TableResponsibilitiesTypes,
} from '@entities/responsibilities';
import {
  checkObjectIdentity,
  DEFAULT_DEBOUNCE_DELAY,
  Input,
  Size,
  TableBodyCell,
  TableFilter,
} from '@shared';

import { INITIAL_RESPONSIBILITIES_FILTER } from '../../config';

interface ResponsibilitiesFilterProps {
  tableType: TableResponsibilitiesTypes;
  onFilter: (data: ResponsibilityFilter) => void;
}

export const ResponsibilitiesFilter: FC<ResponsibilitiesFilterProps> = ({
  tableType,
  onFilter,
}) => {
  const { register, handleSubmit, reset, watch, control } =
    useForm<ResponsibilityFilter>({
      mode: 'onChange',
      defaultValues: INITIAL_RESPONSIBILITIES_FILTER,
    });
  const filterValues = watch();
  const dispatch = useDispatch();

  const resetFilter = () => {
    onFilter({});
    if (tableType === TableResponsibilitiesTypes.FULL) {
      dispatch(setCurrentOrganizationsAddPage(0));
      dispatch(setOrganizationsAddFilterAction({}));
      dispatch(fetchOrganizationsAddRequest({ updateType: 'update' }));
    }
    reset();
  };

  const formSubmitHandler = handleSubmit((data) => {
    const { title, description, organizationId } = data;
    const preparedData = {
      title: title || undefined,
      description: description || undefined,
      organizationId: organizationId || undefined,
    };
    onFilter(preparedData);
  });

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    [onFilter]
  );

  const titleToInput = register('title', {
    onChange: formSubmitHandlerDebounced,
  });
  const descriptionToInput = register('description', {
    onChange: formSubmitHandlerDebounced,
  });

  const tableBodyWrapper = (elements: JSX.Element[]) =>
    elements.map((item) => (
      <TableBodyCell filterCell key={item.key}>
        {item}
      </TableBodyCell>
    ));

  const filterElements = {
    [TableResponsibilitiesTypes.FULL]: () => [
      <Input size={Size.xs} type="title" key="title" {...titleToInput} />,
      <Input
        size={Size.xs}
        type="description"
        key="description"
        {...descriptionToInput}
      />,
      <Controller
        control={control}
        name="organizationId"
        key="organizationId"
        render={({ field }) => (
          <OrganizationSelect
            size={Size.xs}
            onChange={(value) => {
              field.onChange(value);
              formSubmitHandler();
            }}
            value={field.value}
          />
        )}
      />,
    ],
    [TableResponsibilitiesTypes.ADD_MODAL]: () => [
      <div />,
      <Input size={Size.xs} type="title" key="title" {...titleToInput} />,
      <Input
        size={Size.xs}
        type="description"
        key="descriptions"
        {...descriptionToInput}
      />,
    ],
  };

  const filter = (
    <>
      {tableBodyWrapper(
        filterElements[tableType as keyof typeof filterElements]()
      )}
    </>
  );

  const disableReset = checkObjectIdentity(filterValues, {});

  return (
    <TableFilter
      filterComponent={filter}
      onReset={resetFilter}
      disableReset={disableReset}
    />
  );
};
