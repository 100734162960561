import cn from 'clsx';
import { useMemo, useRef, useState } from 'react';

import {
  Popover,
  RouterHref,
  Tab,
  Tabs,
  Typography,
  TypographyVariants,
} from '@shared';

import {
  useCurrentStateForm,
  useEmployeesForm,
  useHeaderForm,
  useHistoryForm,
  useKpiForm,
} from '../../lib';
import { CurrentStateForm } from '../CurrentStateForm';
import { EmployeesForm } from '../EmployeesForm';
import { HistoryForm } from '../HistoryForm';
import { KpiForm } from '../KpiForm';

import styles from './DesktopHeader.module.scss';

export const DesktopHeader = () => {
  const tabsRef = useRef<HTMLDivElement>(null);

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const {
    state: {
      isDesktopKpiPage,
      isDesktopPage,
      isDesktopHistoryPage,
      isMobileAll,
      isManagerSpecialistsWorkGroup,
      managerWorkGroupsList,
      organizationsOptions,
      systemsOptions,
      hideDesktopKpi,
      hideCurrentState,
      hideHistory,
      hideEmployees,
    },
  } = useHeaderForm();

  const {
    state: {
      kpiWorkGroupsIds,
      kpiOrganizationsIds,
      kpiSystemsIds,
      kpiWorkGroupsOptions,
      kpiOrganizationsOptions,
      kpiSystemsOptions,
      kpiTicketTypesOptions,
    },
    methods: { kpiControl, fetchKpiHandler, setValue, kpiReset },
  } = useKpiForm();

  const {
    state: { ticketTypesEmployeesOptions, isDisabledSystemEmployees },
    methods: { employeesControl, setEmpoyeesValue, employeesReset },
  } = useEmployeesForm();

  const {
    state: { ticketTypesHistoryOptions, isDisabledSystemHistory },
    methods: { historyControl, setHistoryValue, historyReset },
  } = useHistoryForm();

  const {
    state: { ticketTypesCurrentStateOptions, isDisabledSystemCurrentsState },
    methods: { currentStateControl, setCurrentStateValue, currentStateReset },
  } = useCurrentStateForm();

  const togglePopover = () => {
    setIsPopoverOpen((prevState) => !prevState);
  };

  const tabsConfig: Tab[] = useMemo(
    () => [
      {
        to: RouterHref.DesktopKpi,
        exact: true,
        title: 'Моя эффективность',
        className: cn(styles.desktopHeader__tab, {
          [styles.desktopHeader__tab_hidden]: hideDesktopKpi,
        }),
      },
      {
        to: RouterHref.DesktopCurrentState,
        exact: false,
        title: 'Текущее состояние',
        className: cn(styles.desktopHeader__tab, {
          [styles.desktopHeader__tab_hidden]: hideCurrentState,
        }),
      },
      {
        to: RouterHref.DesktopHistory,
        exact: false,
        title: 'История',
        className: cn(styles.desktopHeader__tab, {
          [styles.desktopHeader__tab_hidden]: hideHistory,
        }),
      },
      {
        to: RouterHref.DesktopEmployees,
        exact: false,
        title: 'Сотрудники',
        className: cn(styles.desktopHeader__tab, {
          [styles.desktopHeader__tab_hidden]: hideEmployees,
        }),
      },
    ],
    [hideDesktopKpi, hideCurrentState, hideHistory, hideEmployees]
  );

  const getForm = () => {
    if (isDesktopKpiPage) {
      return (
        <KpiForm
          control={kpiControl}
          fetchKpiHandler={fetchKpiHandler}
          setValue={setValue}
          workGroupsIds={kpiWorkGroupsIds}
          organizationsIds={kpiOrganizationsIds}
          systemsIds={kpiSystemsIds}
          workGroupsOptions={kpiWorkGroupsOptions}
          organizationsOptions={kpiOrganizationsOptions}
          systemsOptions={kpiSystemsOptions}
          ticketTypesOptions={kpiTicketTypesOptions}
          reset={kpiReset}
        />
      );
    }
    if (isDesktopPage) {
      return (
        <CurrentStateForm
          control={currentStateControl}
          setValue={setCurrentStateValue}
          managersOptions={managerWorkGroupsList}
          systemsOptions={systemsOptions}
          organizationsOptions={organizationsOptions}
          isManagerSpecialistsWorkGroup={isManagerSpecialistsWorkGroup}
          ticketTypesOptions={ticketTypesCurrentStateOptions}
          isDisabledSystem={isDisabledSystemCurrentsState}
          reset={currentStateReset}
        />
      );
    }
    if (isDesktopHistoryPage) {
      return (
        <HistoryForm
          control={historyControl}
          setValue={setHistoryValue}
          organizationsOptions={organizationsOptions}
          systemsOptions={systemsOptions}
          ticketTypesOptions={ticketTypesHistoryOptions}
          isDisabledSystem={isDisabledSystemHistory}
          reset={historyReset}
        />
      );
    }
    return (
      <EmployeesForm
        control={employeesControl}
        managerWorkGroupsList={managerWorkGroupsList}
        organizationsOptions={organizationsOptions}
        systemsOptions={systemsOptions}
        ticketTypesOptions={ticketTypesEmployeesOptions}
        setValue={setEmpoyeesValue}
        isDisabledSystem={isDisabledSystemEmployees}
        reset={employeesReset}
      />
    );
  };

  const rightContainer = !isMobileAll && (
    <div className={styles.desktopHeader__rightContainer}>{getForm()}</div>
  );

  const title = isMobileAll && (
    <Typography
      variant={TypographyVariants.h3}
      className={styles.desktopHeader__title}
    >
      Рабочий стол
      <Popover
        className={styles.desktopHeader__drop}
        content={
          <div className={styles.desktopHeader__dropContentWrapper}>
            {getForm()}
          </div>
        }
        togglePopover={togglePopover}
        isOpen={isPopoverOpen}
      />
    </Typography>
  );

  return (
    <div className={styles.desktopHeader}>
      <div className={styles.desktopHeader__leftContainer}>
        {title}
        <div className={styles.desktopHeader__tabsWrapper}>
          <Tabs
            className={styles.desktopHeader__tabs}
            tabs={tabsConfig}
            tabsRef={tabsRef}
            fontSize={14}
          />
        </div>
      </div>
      {rightContainer}
    </div>
  );
};
