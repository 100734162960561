import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { Action } from '@entities/actions/model/types';
import {
  CustomField,
  CustomTicketFields,
} from '@entities/custom-fields/model/types';
import { System } from '@entities/system/model';
import {
  createError,
  createErrorAlert,
  createSuccessAlert,
  createWarningAlert,
  KeyValueOption,
  RouterHref,
  setAlert,
  setRedirectPath,
} from '@shared';

import { ticketRequests as requests } from '../../api/ticket';
import { TEXT_TICKET_TOAST } from '../../config';
import { Status, Ticket, TicketStatus, TicketType } from '../types';

import {
  fetchActionsForTicketDetailSuccess,
  fetchCurrentTicketSystemRequest,
  fetchCustomFieldsByTicketIdSuccess,
  fetchHistorySuccess,
  fetchIsSystemIntegratedWithJiraSuccess,
  fetchJiraUrlSuccess,
  fetchNextStatusesSuccess,
  fetchStatusesSuccess,
  fetchTicketCustomFieldsSuccess,
  fetchTicketTypesSuccess,
  hideTicketDetailLoading,
  hideTicketHistoryLoading,
  setCurrentTicketSystemSuccess,
  setCurrentTicketTicketDetail,
  setIsError,
  setIsSuccess,
  setToastMessage,
  showTicketDetailLoading,
  showTicketHistoryLoading,
} from './actions';
import {
  AddSpecAndSetToWorkTicketDetailAction,
  ChangeStatusAction,
  CloseTicketRequestAction,
  CreateTicketInJiraAction,
  DeleteTicketRequestTicketDetailAction,
  EditTicketPriorityAction,
  FetchActionsForTicketDetailRequestAction,
  FetchCustomFieldsByTicketIdRequestAction,
  FetchHistoryRequestAction,
  FetchIsSystemIntegratedWithJiraRequestAction,
  FetchJiraUrlRequestAction,
  FetchNextStatusesRequestAction,
  FetchStatusesRequestTicketDetailAction,
  FetchTicketCustomFieldsRequestAction,
  FetchTicketRequestTicketDetailAction,
  FetchTicketTypesActions,
  ReopenTicketRequestAction,
  RestoreTicketRequestAction,
  TicketDetail,
  UpdateCustomFieldsByTicketIdRequestAction,
  UpdateDataTicketRequestAction,
  UpdateTicketTypeActions,
} from './actionTypes';
import { getTicket } from './selectors';
import { TicketHistory } from './types';

function* fetchCurrentTicketActions({
  payload,
}: FetchActionsForTicketDetailRequestAction) {
  try {
    const ticketActionsList: Action[] = yield call(
      requests.fetchCurrentTicketActions,
      payload
    );
    yield put(fetchActionsForTicketDetailSuccess(ticketActionsList));
  } catch (e) {
    createError(e);
  }
}

function* fetchIsSystemIntegratedWithJira({
  payload,
}: FetchIsSystemIntegratedWithJiraRequestAction) {
  try {
    const isSystemIntegratedWithJira: boolean = yield call(
      requests.fetchIsSystemIntegratedWithJira,
      payload
    );
    yield put(
      fetchIsSystemIntegratedWithJiraSuccess(isSystemIntegratedWithJira)
    );
  } catch (e) {
    createError(e);
  }
}

function* fetchJiraUrl({ payload }: FetchJiraUrlRequestAction) {
  try {
    const jiraUrl: string = yield call(requests.fetchJiraUrl, payload);
    yield put(fetchJiraUrlSuccess(jiraUrl));
  } catch (e) {
    createError(e);
  }
}

function* historyFetch({ payload }: FetchHistoryRequestAction) {
  try {
    yield put(showTicketHistoryLoading());
    const history: TicketHistory[] = yield call(requests.fetchHistory, payload);
    yield put(fetchHistorySuccess(history));
    yield put(hideTicketHistoryLoading());
  } catch (e) {
    yield put(hideTicketHistoryLoading());
    createError(e);
  }
}

function* fetchNextStatuses({ payload }: FetchNextStatusesRequestAction) {
  try {
    const nextStatuses: Status[] = yield call(
      requests.fetchNextStatuses,
      payload
    );
    yield put(fetchNextStatusesSuccess(nextStatuses));
  } catch (e) {
    createError(e);
  }
}

function* compareWithJiraStatuses(ticketId: string) {
  try {
    yield call(requests.compareWithJiraStatuses, ticketId);

    yield put(
      setAlert(
        createWarningAlert('Проверьте совпадение статусной модели с Jira')
      )
    );
  } catch (e) {
    createError(e);
  }
}

function* fetchFilterStatuses({
  payload,
}: FetchStatusesRequestTicketDetailAction) {
  try {
    const statuses: KeyValueOption[] = yield call(
      requests.fetchTicketStatuses,
      payload
    );
    yield put(fetchStatusesSuccess(statuses));
  } catch (e) {
    createError(e);
  }
}

function* ticketFetch({ payload }: FetchTicketRequestTicketDetailAction) {
  const { ticketId, withJira, withHistory, withNextStatuses } = payload;

  try {
    yield put(showTicketDetailLoading());
    const ticket: Ticket = yield call(requests.fetchTicket, ticketId);
    const ticketWorkGroups = ticket?.workGroups.map(
      (workGroup) => workGroup.id
    );
    yield call(fetchCurrentTicketActions, {
      payload: ticketWorkGroups,
      type: TicketDetail.FETCH_ACTIONS_FOR_TICKET_DETAIL_REQUEST,
    });
    if (withJira) {
      yield call(fetchJiraUrl, {
        payload: ticketId,
        type: TicketDetail.FETCH_JIRA_URL_REQUEST,
      });
    }
    if (withHistory) {
      yield call(historyFetch, {
        payload: ticketId,
        type: TicketDetail.FETCH_HISTORY_REQUEST,
      });
    }
    if (withNextStatuses && ticket?.customStatus?.id && ticket.clientId) {
      yield call(fetchNextStatuses, {
        payload: {
          currentStatusId: ticket.customStatus?.id,
          ticketCreatorId: ticket.clientId.key,
        },
        type: TicketDetail.FETCH_NEXT_STATUSES_REQUEST,
      });
    }
    if (ticket.systemId && ticket.ticketType && ticket.customStatus) {
      yield call(fetchFilterStatuses, {
        payload: {
          systemIds: [ticket.systemId?.key],
          typeIds: [ticket.ticketType?.key],
          nextToIds: [ticket.customStatus?.id],
        },
        type: TicketDetail.FETCH_STATUSES_REQUEST,
      });
    }

    yield put(setCurrentTicketTicketDetail(ticket));
    yield put(hideTicketDetailLoading());
  } catch (e) {
    yield put(hideTicketDetailLoading());
    yield put(setRedirectPath(RouterHref.NotFound));
    createError(e);
  }
}

function* ticketSystemFetch({
  payload,
}: ReturnType<typeof fetchCurrentTicketSystemRequest>) {
  try {
    const system: System = yield call(requests.fetchTicketSystem, payload);
    yield put(setCurrentTicketSystemSuccess(system));
  } catch (e) {
    createError(e);
  }
}

function* addSpecAndSetToWork({
  payload: { ticketId, specialistId },
}: AddSpecAndSetToWorkTicketDetailAction) {
  try {
    const ticket: ReturnType<typeof getTicket> = yield select(getTicket);
    const isTicketNew =
      ticket?.customStatus?.defaultStatus === TicketStatus.NEW;
    const isReopenTicket =
      ticket?.customStatus?.defaultStatus === TicketStatus.REOPEN;

    if (specialistId) {
      yield call(requests.addSpecialist, ticketId, specialistId);
    }
    if (isTicketNew || isReopenTicket) {
      yield call(requests.setTicketToWork, ticketId);
    }
    yield call(ticketFetch, {
      payload: { ticketId: ticketId[0] },
      type: TicketDetail.FETCH_TICKET_DETAIL_REQUEST,
    });
  } catch (e) {
    createError(e);
  }
}

function* editTicketPriority({ payload }: EditTicketPriorityAction) {
  const { ticketId, priority } = payload;
  try {
    yield call(requests.fetchEditTicketPriority, ticketId, priority);
    yield call(ticketFetch, {
      payload: { ticketId },
      type: TicketDetail.FETCH_TICKET_DETAIL_REQUEST,
    });
    yield put(setIsSuccess(true));
    yield put(setToastMessage(TEXT_TICKET_TOAST.changeTicketPriority));
  } catch (e) {
    yield put(setIsError(true));
    createError(e);
  }
}

function* deleteTicket({ payload }: DeleteTicketRequestTicketDetailAction) {
  try {
    if (payload?.id) {
      yield call(requests.deleteTicket, payload.id);
      yield put(
        setAlert(
          createSuccessAlert(
            `Тикет по системе "${payload.systemId?.value}"  успешно удален`
          )
        )
      );
      yield put(setRedirectPath(RouterHref.Tickets));
    }
  } catch (e) {
    yield put(
      setAlert(
        createErrorAlert(
          `Произошла ошибка при удалении тикета по системе "${payload.systemId?.value}"`
        )
      )
    );
    createError(e);
  }
}

function* updateDataTicket({ payload }: UpdateDataTicketRequestAction) {
  try {
    yield put(showTicketDetailLoading());
    yield call(requests.updateDataTicket, payload);
    if (payload.id) {
      yield call(ticketFetch, {
        payload: { ticketId: payload.id },
        type: TicketDetail.FETCH_TICKET_DETAIL_REQUEST,
      });
    }
    yield put(hideTicketDetailLoading());
  } catch (e) {
    createError(e);
    yield put(hideTicketDetailLoading());
  }
}

function* createTicketInJira({ payload }: CreateTicketInJiraAction) {
  try {
    yield call(addSpecAndSetToWork, {
      payload: { ticketId: [payload.ticketId] },
      type: TicketDetail.ADD_SPEC_AND_SET_TO_WORK_TICKET_DETAIL,
    });

    yield call(requests.createTicketInJira, payload);
    yield call(fetchJiraUrl, {
      payload: payload.ticketId,
      type: TicketDetail.FETCH_JIRA_URL_REQUEST,
    });
    yield put(
      setAlert(
        createSuccessAlert(`Успешно создана связанная с тикетом задача в Jira`)
      )
    );

    yield call(compareWithJiraStatuses, payload.ticketId);
  } catch (e) {
    yield put(
      setAlert(
        createErrorAlert(
          'Произошла ошибка при попытке создания связанной с тикетом задачи в Jira'
        )
      )
    );
    createError(e);
  }
}

function* fetchCustomFieldsByTicketId({
  payload,
}: FetchCustomFieldsByTicketIdRequestAction) {
  try {
    const customFields: CustomTicketFields = yield call(
      requests.fetchCustomFields,
      payload
    );
    if (
      customFields.clientCustomFields?.length ||
      customFields.specialistCustomFields?.length
    ) {
      yield put(fetchCustomFieldsByTicketIdSuccess(customFields));
    }
  } catch (e) {
    createError(e);
  }
}

function* updateCustomFieldsByTicketId({
  payload,
}: UpdateCustomFieldsByTicketIdRequestAction) {
  try {
    yield put(showTicketDetailLoading());
    const ticket: ReturnType<typeof getTicket> = yield select(getTicket);
    if (ticket?.id) {
      const customFields: CustomTicketFields = yield call(
        requests.updateCustomFields,
        ticket.id,
        payload
      );
      yield put(fetchCustomFieldsByTicketIdSuccess(customFields));
    }
    yield put(hideTicketDetailLoading());
  } catch (e) {
    createError(e);
    yield put(hideTicketDetailLoading());
  }
}

function* changeStatus({ payload }: ChangeStatusAction) {
  try {
    yield call(requests.changeStatus, payload);
  } catch (e) {
    createError(e);
  }
}

function* closeTicket({ payload }: CloseTicketRequestAction) {
  try {
    yield call(requests.closeTicket, payload);
  } catch (e) {
    createError(e);
  }
}

function* restoreTicket({ payload }: RestoreTicketRequestAction) {
  try {
    yield call(requests.restoreTicket, payload);

    if (payload) {
      yield call(ticketFetch, {
        type: TicketDetail.FETCH_TICKET_DETAIL_REQUEST,
        payload: { ticketId: payload },
      });
    }
  } catch (e) {
    createError(e);
  }
}

function* fetchTicketTypes({ payload }: FetchTicketTypesActions) {
  try {
    const ticketTypes: TicketType[] = yield call(
      requests.fetchTicketTypes,
      payload
    );
    yield put(fetchTicketTypesSuccess(ticketTypes));
  } catch (e) {
    createError(e);
  }
}

function* updateTicketType({ payload }: UpdateTicketTypeActions) {
  try {
    const ticket: ReturnType<typeof getTicket> = yield select(getTicket);
    if (ticket?.id) {
      yield call(requests.updateTicketType, ticket.id, payload);
      yield call(ticketFetch, {
        payload: { ticketId: ticket.id },
        type: TicketDetail.FETCH_TICKET_DETAIL_REQUEST,
      });
      yield put(setIsSuccess(true));
      yield put(setToastMessage(TEXT_TICKET_TOAST.changeTicketType));
    }
  } catch (e) {
    yield put(setIsError(true));
    createError(e);
  }
}

function* reopenTicket({ payload }: ReopenTicketRequestAction) {
  try {
    yield call(requests.reopenTicket, payload);

    if (payload) {
      yield call(ticketFetch, {
        type: TicketDetail.FETCH_TICKET_DETAIL_REQUEST,
        payload: { ticketId: payload.ticketId },
      });
    }
  } catch (e) {
    createError(e);
  }
}

function* customFieldFetch({ payload }: FetchTicketCustomFieldsRequestAction) {
  try {
    const fields: CustomField[] = yield call(
      requests.fetchCustomField,
      payload
    );
    yield put(fetchTicketCustomFieldsSuccess(fields));
  } catch (e) {
    createError(e);
  }
}

export function* ticketSaga(): Generator<StrictEffect> {
  yield takeEvery(TicketDetail.FETCH_TICKET_DETAIL_REQUEST, ticketFetch);
  yield takeEvery(
    TicketDetail.FETCH_TICKET_SYSTEM_DETAIL_REQUEST,
    ticketSystemFetch
  );
  yield takeEvery(
    TicketDetail.ADD_SPEC_AND_SET_TO_WORK_TICKET_DETAIL,
    addSpecAndSetToWork
  );
  yield takeEvery(TicketDetail.FETCH_HISTORY_REQUEST, historyFetch);
  yield takeEvery(TicketDetail.EDIT_TICKET_PRIORITY, editTicketPriority);
  yield takeEvery(
    TicketDetail.FETCH_ACTIONS_FOR_TICKET_DETAIL_REQUEST,
    fetchCurrentTicketActions
  );
  yield takeEvery(TicketDetail.DELETE_TICKET_DETAIL_REQUEST, deleteTicket);
  yield takeEvery(TicketDetail.UPDATE_DATA_TICKET_DETAIL, updateDataTicket);
  yield takeEvery(TicketDetail.CREATE_TICKET_IN_JIRA, createTicketInJira);
  yield takeEvery(
    TicketDetail.FETCH_IS_SYSTEM_INTEGRATED_WITH_JIRA_REQUEST,
    fetchIsSystemIntegratedWithJira
  );
  yield takeEvery(TicketDetail.FETCH_JIRA_URL_REQUEST, fetchJiraUrl);
  yield takeEvery(
    TicketDetail.FETCH_CUSTOM_FIELDS_BY_TICKET_ID_REQUEST,
    fetchCustomFieldsByTicketId
  );
  yield takeEvery(
    TicketDetail.UPDATE_CUSTOM_FIELDS_BY_TICKET_ID_REQUEST,
    updateCustomFieldsByTicketId
  );
  yield takeEvery(TicketDetail.FETCH_NEXT_STATUSES_REQUEST, fetchNextStatuses);
  yield takeEvery(TicketDetail.CHANGE_STATUS, changeStatus);
  yield takeEvery(TicketDetail.CLOSE_TICKET_REQUEST, closeTicket);
  yield takeEvery(TicketDetail.RESTORE_TICKET_REQUEST, restoreTicket);
  yield takeEvery(TicketDetail.FETCH_TICKET_TYPES_REQUEST, fetchTicketTypes);
  yield takeEvery(
    TicketDetail.UPDATE_TICKET_TYPES_BY_TYPE_ID_REQUEST,
    updateTicketType
  );
  yield takeEvery(TicketDetail.REOPEN_TICKET_REQUEST, reopenTicket);
  yield takeEvery(TicketDetail.FETCH_STATUSES_REQUEST, fetchFilterStatuses);
  yield takeEvery(
    TicketDetail.FETCH_TICKET_CUSTOM_FIELDS_BY_SYSTEM_ID_REQUEST,
    customFieldFetch
  );
  yield takeEvery(TicketDetail.FETCH_STATUSES_REQUEST, fetchFilterStatuses);
}
