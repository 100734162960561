import { Action } from '@entities/actions/model/types';
import {
  AddSpecAndSetToWorkPayloadTickets,
  Ticket,
  TicketType,
} from '@entities/ticket/model/types';
import { FetchRequestArgs, ResponseWithMeta, TicketsTabType } from '@shared';

import {
  AddSpecAndSetToWorkTicketsAction,
  CreateFilterRequestAction,
  CreateTicketsSettingFieldsRequestAction,
  CreateTicketsSettingFieldsSuccessAction,
  DeleteFilterRequestAction,
  DeleteTicketRequestTicketsAction,
  FetchActionsForCurrentTicketSuccessAction,
  FetchActualTicketsSettingFieldsRequestAction,
  FetchFiltersDataRequestAction,
  FetchFiltersDataSuccessAction,
  FetchOpenTicketsCountByContactIdRequestAction,
  FetchOpenTicketsCountByContactIdSuccessAction,
  FetchTicketRequestTicketsAction,
  FetchTicketsRequestAction,
  FetchTicketsSettingFieldsRequestAction,
  FetchTicketsSettingFieldsSuccessAction,
  FetchTicketsSuccessAction,
  FetchTicketStatusRequestAction,
  FetchTicketStatusSuccessJoinAction,
  FetchTicketStatusSuccessUpdateAction,
  FetchTicketTypesRequestAction,
  FetchTicketTypesSuccessJoinAction,
  FetchTicketTypesSuccessUpdateAction,
  HideTicketLoadingTicketsAction,
  HideTicketsLoadingAction,
  HideTypesLoadingAction,
  OpenSettingFieldsAction,
  ResetCurrentTicketAction,
  ResetFiltersStateAction,
  ResetTicketsSearchAction,
  ResetTicketsStateAction,
  ResetTicketStatusStateAction,
  ResetTicketTypeStateAction,
  SetCurrentFilterIdAction,
  SetCurrentFilterTitleRequestAction,
  SetCurrentTicketId,
  SetCurrentTicketPageAction,
  SetCurrentTicketSuccessAction,
  SetIsModalPopoverInnerAction,
  SetIsUsedFilterAction,
  SetSizePageAction,
  SetSizePageStatusAction,
  SetSizePageTypeAction,
  SetSortTicketsAction,
  SetTicketsFilterAction,
  SetValueTabItemAction,
  ShowTicketLoadingTicketsAction,
  ShowTicketsLoadingAction,
  ShowTypesLoadingAction,
  Tickets,
  ToggleTicketsSearchAction,
  UpdateTicketByIdRequestAction,
  UpdateTicketByIdSuccessAction,
} from './actionTypes';
import {
  CreateFilterRequest,
  CreateSettingFields,
  DeleteFilterRequest,
  FetchTickets,
  FilterData,
  ISettingFields,
  SetCurrentFilterTitleRequest,
  StatusTicketPayload,
  TicketsFilterForRender,
  TicketsTypesPayload,
} from './types';

export const showTicketsLoading = (): ShowTicketsLoadingAction => ({
  type: Tickets.TICKETS_LOADING_SHOW,
});

export const hideTicketsLoading = (): HideTicketsLoadingAction => ({
  type: Tickets.TICKETS_LOADING_HIDE,
});

export const showTicketLoading = (): ShowTicketLoadingTicketsAction => ({
  type: Tickets.TICKET_LOADING_SHOW,
});

export const hideTicketLoading = (): HideTicketLoadingTicketsAction => ({
  type: Tickets.TICKET_LOADING_HIDE,
});

export const toggleTicketsSearch = (): ToggleTicketsSearchAction => ({
  type: Tickets.TOGGLE_TICKETS_SEARCH,
});

export const resetTicketsSearch = (): ResetTicketsSearchAction => ({
  type: Tickets.RESET_TICKETS_SEARCH,
});

export const fetchTicketsRequest = ({
  ticketTab = TicketsTabType.ALL_TICKETS,
  withFirstTicketFetch = false,
}: FetchTickets): FetchTicketsRequestAction => ({
  type: Tickets.FETCH_TICKETS_REQUEST,
  payload: {
    ticketTab,
    withFirstTicketFetch,
  },
});

export const fetchTicketsSuccess = (
  data: ResponseWithMeta<Ticket[]>
): FetchTicketsSuccessAction => ({
  type: Tickets.FETCH_TICKETS_SUCCESS,
  payload: data,
});

export const fetchTicketsCountByContractIdRequest = (
  contractIds: string[]
): FetchOpenTicketsCountByContactIdRequestAction => ({
  type: Tickets.FETCH_OPEN_TICKETS_COUNT_BY_CONTRACT_ID_REQUEST,
  payload: contractIds,
});

export const fetchTicketsCountByContractIdSuccess = (
  count: number
): FetchOpenTicketsCountByContactIdSuccessAction => ({
  type: Tickets.FETCH_OPEN_TICKETS_COUNT_BY_CONTRACT_ID_SUCCESS,
  payload: count,
});

export const fetchTicketRequestTickets = (
  payload: string
): FetchTicketRequestTicketsAction => ({
  type: Tickets.FETCH_TICKET_REQUEST,
  payload,
});

export const setCurrentTicket = (
  data?: Ticket
): SetCurrentTicketSuccessAction => ({
  type: Tickets.SET_CURRENT_TICKET_SUCCESS,
  payload: data,
});

export const setCurrentTicketPage = (
  page: number
): SetCurrentTicketPageAction => ({
  type: Tickets.SET_CURRENT_TICKETS_PAGE,
  payload: page,
});

export const setSizePage = (size: number): SetSizePageAction => ({
  type: Tickets.SET_SIZE_PAGE,
  payload: size,
});

export const setSortTickets = (sort: string): SetSortTicketsAction => ({
  type: Tickets.SET_SORT_TICKETS,
  payload: sort,
});

export const setTicketsFilter = (
  filter?: TicketsFilterForRender
): SetTicketsFilterAction => ({
  type: Tickets.SET_FILTER_TICKETS,
  payload: filter,
});

export const addSpecAndSetToWorkTickets = (
  payload: AddSpecAndSetToWorkPayloadTickets
): AddSpecAndSetToWorkTicketsAction => ({
  type: Tickets.ADD_SPEC_AND_SET_TO_WORK,
  payload,
});

export const resetTicketsState = (): ResetTicketsStateAction => ({
  type: Tickets.RESET_TICKETS_STATE,
});

export const updateTicketByIdSuccess = (
  payload: Ticket
): UpdateTicketByIdSuccessAction => ({
  type: Tickets.UPDATE_TICKET_BY_ID_SUCCESS,
  payload,
});

export const updateTicketByIdRequest = (
  ticketId: string
): UpdateTicketByIdRequestAction => ({
  payload: ticketId,
  type: Tickets.UPDATE_TICKET_BY_ID_REQUEST,
});

export const setCurrentTicketId = (ticketId?: string): SetCurrentTicketId => ({
  payload: ticketId,
  type: Tickets.SET_CURRENT_TICKET_ID,
});

export const fetchActionsForCurrentTicketSuccess = (
  payload: Action[]
): FetchActionsForCurrentTicketSuccessAction => ({
  payload,
  type: Tickets.FETCH_ACTIONS_FOR_CURRENT_TICKET_SUCCESS,
});

export const deleteTicketRequestTickets = (
  ticket: Ticket
): DeleteTicketRequestTicketsAction => ({
  type: Tickets.DELETE_TICKET_REQUEST,
  payload: ticket,
});

export const resetCurrentTicket = (): ResetCurrentTicketAction => ({
  type: Tickets.RESET_CURRENT_TICKET,
});

export const fetchFiltersDataRequest = (
  payload: TicketsTabType
): FetchFiltersDataRequestAction => ({
  type: Tickets.FETCH_FILTERS_DATA_REQUEST,
  payload,
});

export const fetchFiltersDataSuccess = (
  payload: FilterData[]
): FetchFiltersDataSuccessAction => ({
  type: Tickets.FETCH_FILTERS_DATA_SUCCESS,
  payload,
});

export const createFilterRequest = (
  payload: CreateFilterRequest
): CreateFilterRequestAction => ({
  type: Tickets.CREATE_FILTER_REQUEST,
  payload,
});

export const deleteFilterRequest = (
  payload: DeleteFilterRequest
): DeleteFilterRequestAction => ({
  type: Tickets.DELETE_FILTER_REQUEST,
  payload,
});

export const setCurrentFilterTitleRequest = (
  payload: SetCurrentFilterTitleRequest
): SetCurrentFilterTitleRequestAction => ({
  type: Tickets.SET_CURRENT_FILTER_TITLE_REQUEST,
  payload,
});

export const setCurrentFilterId = (
  payload: string
): SetCurrentFilterIdAction => ({
  type: Tickets.SET_CURRENT_FILTER_ID,
  payload,
});

export const setIsUsedFilter = (payload: boolean): SetIsUsedFilterAction => ({
  type: Tickets.SET_IS_USED_FILTER,
  payload,
});

export const resetFiltersState = (): ResetFiltersStateAction => ({
  type: Tickets.RESET_FILTERS_STATE,
});

export const openSettingFields = (
  payload: boolean
): OpenSettingFieldsAction => ({
  type: Tickets.OPEN_SETTING_FIELDS,
  payload,
});

export const fetchTicketsSettingFieldsRequest =
  (): FetchTicketsSettingFieldsRequestAction => ({
    type: Tickets.FETCH_TICKETS_SETTING_FIELDS_REQUEST,
  });

export const fetchTicketsSettingFieldSuccess = (
  payload: ISettingFields
): FetchTicketsSettingFieldsSuccessAction => ({
  type: Tickets.FETCH_TICKETS_SETTING_FIELD_SUCCESS,
  payload,
});

export const fetchActualTicketsSettingFieldsRequest =
  (): FetchActualTicketsSettingFieldsRequestAction => ({
    type: Tickets.FETCH_ACTUAL_TICKETS_SETTING_FIELDS_REQUEST,
  });

export const createTicketsSettingFieldsRequest = (
  payload: CreateSettingFields
): CreateTicketsSettingFieldsRequestAction => ({
  type: Tickets.CREATE_TICKETS_SETTING_FIELDS_REQUEST,
  payload,
});

export const createTicketsSettingFieldSuccess = (
  payload: ISettingFields
): CreateTicketsSettingFieldsSuccessAction => ({
  type: Tickets.CREATE_TICKETS_SETTING_FIELD_SUCCESS,
  payload,
});

export const fetchTicketTypesTicketsRequest = (
  payload: FetchRequestArgs<TicketsTypesPayload>
): FetchTicketTypesRequestAction => ({
  type: Tickets.FETCH_TICKET_TYPES_REQUEST,
  payload,
});

export const fetchTicketTypesUpdateSuccess = (
  payload: ResponseWithMeta<TicketType[]>
): FetchTicketTypesSuccessUpdateAction => ({
  type: Tickets.FETCH_TICKET_TYPES_SUCCESS_UPDATE,
  payload,
});

export const fetchTicketTypesJoinSuccess = (
  payload: ResponseWithMeta<TicketType[]>
): FetchTicketTypesSuccessJoinAction => ({
  type: Tickets.FETCH_TICKET_TYPES_SUCCESS_JOIN,
  payload,
});

export const showTypesLoading = (): ShowTypesLoadingAction => ({
  type: Tickets.TYPE_LOADING_SHOW,
});

export const hideTypesLoading = (): HideTypesLoadingAction => ({
  type: Tickets.TYPE_LOADING_HIDE,
});

export const resetTicketTypeState = (): ResetTicketTypeStateAction => ({
  type: Tickets.RESET_TICKET_TYPES,
});

export const setSizeTypePage = (size: number): SetSizePageTypeAction => ({
  type: Tickets.SET_SIZE_TYPE_PAGE,
  payload: size,
});

export const fetchTicketStatusesRequest = (
  payload: FetchRequestArgs<StatusTicketPayload>
): FetchTicketStatusRequestAction => ({
  type: Tickets.FETCH_TICKET_STATUS_REQUEST,
  payload,
});

export const fetchTicketStatusUpdateSuccess = (
  payload: ResponseWithMeta<string[]>
): FetchTicketStatusSuccessUpdateAction => ({
  type: Tickets.FETCH_TICKET_STATUS_SUCCESS_UPDATE,
  payload,
});

export const fetchTicketStatusJoinSuccess = (
  payload: ResponseWithMeta<string[]>
): FetchTicketStatusSuccessJoinAction => ({
  type: Tickets.FETCH_TICKET_STATUS_SUCCESS_JOIN,
  payload,
});

export const resetTicketStatusState = (): ResetTicketStatusStateAction => ({
  type: Tickets.RESET_TICKET_STATUS,
});

export const setSizeStatusPage = (size: number): SetSizePageStatusAction => ({
  type: Tickets.SET_SIZE_STATUS_PAGE,
  payload: size,
});

export const toggleModalPopoverInner = (): SetIsModalPopoverInnerAction => ({
  type: Tickets.SET_IS_MODAL_POPOVER_INNER,
});

export const setValueTabItem = (payload?: string): SetValueTabItemAction => ({
  type: Tickets.SET_VALUE_TAB_ITEM,
  payload,
});
