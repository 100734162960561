import { SettingFieldType } from '../../model';

export const sortFields = <T>(
  first: SettingFieldType<T>,
  second: SettingFieldType<T>
) => {
  if (!!first.ordinal && !!second.ordinal) {
    if (first.ordinal > second.ordinal) {
      return 1;
    }
    return -1;
  }
  return 0;
};
