import {
  all,
  call,
  fork,
  put,
  select,
  StrictEffect,
  takeEvery,
} from 'redux-saga/effects';

import { Environment } from '@entities/environment';
import { System, SystemPriority } from '@entities/system/model';
import { TicketType } from '@entities/ticket';
import {
  createError,
  createErrorAlert,
  createSuccessAlert,
  getValueFromValueType,
  ResponseWithMeta,
  setAlert,
} from '@shared';

import { requests } from '../api';
import { JIRA_ERROR_ALERT_TITLE, JIRA_SUCCESS_ALERT_TITLE } from '../config';
import { getSelectedJiraTicketType } from '../lib';

import {
  addNewTicketTypeSuccess,
  editJiraSyncSuccess,
  fetchFieldsIntegrationsSuccess,
  fetchIntegratedTicketTypesSuccess,
  fetchJiraFieldsSuccess,
  fetchJiraIntegrationsSuccess,
  fetchJiraIntegrationSuccess,
  fetchJiraPrioritiesByIdSuccess,
  fetchJiraPrioritiesByIntegrationIdSuccess,
  fetchJiraPrioritiesSuccess,
  fetchJiraStatusesByIdSuccess,
  fetchJiraStatusesByPropertyIdSuccess,
  fetchJiraStatusesSuccess,
  fetchJiraSyncFilterSuccess,
  fetchJiraTicketTypeByIntegrationIdSuccess,
  fetchJiraTicketTypesByIntegrationIdSuccess,
  fetchJiraTicketTypeSuccess,
  fetchSpFieldsSuccess,
  fetchSPStatusesForCurrentSuccess,
  fetchSPStatusesSuccess,
  fetchSyncClientsSuccess,
  fetchSyncEnvironmentsSuccess,
  fetchSyncPrioritiesSuccess,
  fetchSyncSpecialistsSuccess,
  fetchSyncStatusesSuccess,
  fetchSyncTicketTypesSuccess,
  fetchSystemPrioritiesForCompareSuccess,
  fetchSystemPrioritiesSuccess,
  fetchTicketTypeSuccess,
  hideJiraIntegrationInfoLoading,
  hideJiraIntegrationsLoading,
  hidePrioritiesLoading,
  hideStatusesLoading,
  setCurrentJiraIntegrationId,
  setCurrentSystemId,
  setSPStatusesForCompare,
  showJiraIntegrationInfoLoading,
  showJiraIntegrationsLoading,
  showPrioritiesLoading,
  showStatusesLoading,
  updateJiraIntegrationById,
} from './actions';
import {
  AddNewTicketTypeRequestAction,
  CreateFieldsIntegrationAction,
  CreateJiraIntegrationAction,
  CreateMappingPayload,
  DeleteFieldsIntegrationAction,
  DeleteJiraIntegrationAction,
  EditFieldsIntegrationAction,
  EditJiraIntegrationAction,
  EditJiraIntegrationsActiveAction,
  EditJiraSyncRequestAction,
  FetchFieldsIntegrationsRequestAction,
  FetchInfoForJiraIntegrationAction,
  FetchIntegratedTicketTypesRequestAction,
  FetchJiraFieldsRequestAction,
  FetchJiraIntegrationRequestAction,
  FetchJiraPrioritiesByIdAction,
  FetchJiraPrioritiesByIntegrationIdRequestAction,
  FetchJiraPrioritiesRequestAction,
  FetchJiraSPStatusesForCurrentRequestAction,
  FetchJiraSPStatusesRequestAction,
  FetchJiraStatusesByIdAction,
  FetchJiraStatusesByPropertyIdRequestAction,
  FetchJiraStatusesRequestAction,
  FetchJiraSyncFilterRequestAction,
  FetchJiraTicketTypeByIntegrationIdRequestAction,
  FetchJiraTicketTypesByIntegrationIdRequestAction,
  FetchJiraTicketTypesRequestAction,
  FetchSpFieldsRequestAction,
  FetchSyncClientsRequestAction,
  FetchSyncEnvironmentsRequestAction,
  FetchSyncPrioritiesRequestAction,
  FetchSyncSpecialistsRequestAction,
  FetchSyncStatusesRequestAction,
  FetchSyncTicketTypesRequestAction,
  FetchSystemPrioritiesForCompareRequestAction,
  FetchSystemPrioritiesRequestAction,
  FetchTicketTypesRequestAction,
  Jira,
} from './actionTypes';
import {
  getCurrentJiraIntegrationId,
  getCurrentSystemId,
  getJiraIntegrationsFilter,
  getJiraTicketTypesForCurrent,
  getPropsJiraIntegrations,
  getTypeId,
} from './selectors';
import {
  Field,
  FieldsIntegration,
  JiraIntegration,
  JiraIntegrationResponse,
  JiraIntegrationsFilter,
  JiraIntegrationsFormData,
  JiraPriority,
  JiraStatus,
  JiraSynchronization,
  JiraSynchronizationFilter,
  JiraTicketType,
  Status,
} from './types';

export const getJiraIntegrationsFilterToRequest = (
  filter: JiraIntegrationsFormData
): JiraIntegrationsFilter => {
  const { systemId, isActive, organizationId } = filter;
  return {
    ...filter,
    systemId: getValueFromValueType(systemId),
    isActive: getValueFromValueType<boolean>(isActive),
    organizationId: getValueFromValueType(organizationId),
  };
};

function* jiraIntegrationsFetch() {
  try {
    const {
      pageNum,
      pageSize,
      sort,
    }: ReturnType<typeof getPropsJiraIntegrations> = yield select(
      getPropsJiraIntegrations
    );
    const filter: ReturnType<typeof getJiraIntegrationsFilter> = yield select(
      getJiraIntegrationsFilter
    );

    yield put(showJiraIntegrationsLoading());
    const jiraIntegrations: ResponseWithMeta<JiraIntegration[]> = yield call(
      requests.fetchJiraIntegrations,
      pageNum,
      pageSize,
      sort,
      filter
    );
    yield put(fetchJiraIntegrationsSuccess(jiraIntegrations));

    yield put(hideJiraIntegrationsLoading());
  } catch (e) {
    yield put(hideJiraIntegrationsLoading());
    createError(e);
  }
}

function* jiraStatusesFetch({ payload }: FetchJiraStatusesRequestAction) {
  try {
    const jiraStatuses: JiraStatus[] = yield call(
      requests.fetchJiraStatuses,
      payload
    );
    yield put(fetchJiraStatusesSuccess(jiraStatuses));
  } catch (e) {
    createError(e);
  }
}

function* jiraPrioritiesFetch({ payload }: FetchJiraPrioritiesRequestAction) {
  try {
    const jiraPriorities: JiraPriority[] = yield call(
      requests.fetchJiraPriorities,
      payload
    );
    yield put(fetchJiraPrioritiesSuccess(jiraPriorities));
  } catch (e) {
    createError(e);
  }
}

function* fetchJiraPrioritiesByIntegrationId({
  payload,
}: FetchJiraPrioritiesByIntegrationIdRequestAction) {
  try {
    const jiraPriorities: JiraPriority[] = yield call(
      requests.fetchJiraPrioritiesByIntegrationId,
      payload
    );
    yield put(fetchJiraPrioritiesByIntegrationIdSuccess(jiraPriorities));
  } catch (e) {
    createError(e);
  }
}

function* fetchTicketTypes({ payload }: FetchTicketTypesRequestAction) {
  try {
    const { typeList }: System = yield call(requests.fetchTicketTypes, payload);
    if (typeList) {
      yield put(fetchTicketTypeSuccess(typeList));
    }
  } catch (e) {
    createError(e);
  }
}

function* fetchJiraTicketType({ payload }: FetchJiraTicketTypesRequestAction) {
  try {
    const jiraTicketTypes: JiraTicketType[] = yield call(
      requests.fetchJiraTicketType,
      payload
    );
    yield put(fetchJiraTicketTypeSuccess(jiraTicketTypes));
  } catch (e) {
    createError(e);
  }
}

function* fetchJiraTicketTypesByIntegrationId({
  payload,
}: FetchJiraTicketTypesByIntegrationIdRequestAction) {
  try {
    const jiraTicketTypes: JiraTicketType[] = yield call(
      requests.fetchJiraTicketTypesByIntegrationId,
      payload
    );
    yield put(fetchJiraTicketTypesByIntegrationIdSuccess(jiraTicketTypes));
  } catch (e) {
    createError(e);
  }
}

function* fetchJiraTicketTypeByIntegrationId({
  payload,
}: FetchJiraTicketTypeByIntegrationIdRequestAction) {
  try {
    const jiraTicketType: JiraTicketType[] = yield call(
      requests.fetchJiraTicketTypeByIntegrationId,
      payload
    );
    yield put(fetchJiraTicketTypeByIntegrationIdSuccess(jiraTicketType));
  } catch (e) {
    createError(e);
  }
}

function* fetchIntegratedTicketTypes({
  payload,
}: FetchIntegratedTicketTypesRequestAction) {
  try {
    const ticketTypesIds: string[] = yield call(
      requests.fetchIntegratedTicketTypes,
      payload
    );

    yield put(fetchIntegratedTicketTypesSuccess(ticketTypesIds));
  } catch (e) {
    createError(e);
  }
}

function* createMapping({ payload }: CreateMappingPayload) {
  try {
    yield call(requests.mapping, payload);

    yield put(setAlert(createSuccessAlert('Маппинг с Jira успешно создан')));
  } catch (e) {
    yield put(
      setAlert(createErrorAlert('Произошла ошибка при маппинге с Jira'))
    );
    createError(e);
  }
}

function* createJiraIntegration({ payload }: CreateJiraIntegrationAction) {
  try {
    const {
      name,
      login,
      password,
      jiraUrl,
      projectKey,
      organizationId,
      systemId,
      ticketTypeIds,
      jiraSyncFilter,
    } = payload;

    const jiraIntegration: JiraIntegrationResponse = yield call(
      requests.createJiraIntegration,
      {
        name,
        login,
        password,
        jiraUrl,
        projectKey,
        organizationId,
        systemId,
        ticketTypeIds,
      }
    );

    if (jiraIntegration.jiraPropertiesResponse?.id) {
      yield all(
        ticketTypeIds.map((key) => {
          const mappingStatuses = payload.mappingStatuses[key];
          const mappingPriorities = payload.mappingPriorities[key];
          const mappingTypes = payload.mappingTypes[key];

          return call(createMapping, {
            payload: {
              propertyId: jiraIntegration.jiraPropertiesResponse?.id ?? '',
              mappingStatuses,
              mappingPriorities,
              mappingTypes,
            },
          });
        })
      );
    }

    if (jiraSyncFilter && jiraIntegration.jiraPropertiesResponse?.id) {
      const filter: JiraSynchronization = yield call(
        requests.createJiraSynchronization,
        {
          ...jiraSyncFilter,
          jiraPropertiesId: jiraIntegration.jiraPropertiesResponse?.id,
        }
      );

      yield put(fetchJiraSyncFilterSuccess(filter));
    }

    yield put(
      setAlert(createSuccessAlert('Интеграция с Jira успешно создана'))
    );

    if (jiraIntegration.jiraPropertiesResponse?.id) {
      yield call(fetchIntegratedTicketTypes, {
        type: Jira.FETCH_INTEGRATED_TICKET_TYPES_REQUEST,
        payload: jiraIntegration.jiraPropertiesResponse?.id,
      });
    }

    yield call(jiraIntegrationsFetch);
    yield put(
      setCurrentJiraIntegrationId(jiraIntegration.jiraPropertiesResponse?.id)
    );
    yield put(
      setCurrentSystemId(jiraIntegration.jiraPropertiesResponse.systemId)
    );
    yield put(
      fetchJiraIntegrationSuccess(jiraIntegration.jiraPropertiesResponse)
    );
  } catch (e) {
    yield put(
      setAlert(createErrorAlert('Не удалось создать интеграцию с Jira'))
    );
    createError(e);
  }
}

function* editJiraIntegration({ payload }: EditJiraIntegrationAction) {
  try {
    const { ticketTypeUpdateDto, statusMapping, priorityMapping } =
      payload || {};

    if (ticketTypeUpdateDto) {
      yield call(requests.editMappingJiraTicketType, ticketTypeUpdateDto);
    }
    if (statusMapping) {
      yield call(requests.editMappingStatuses, statusMapping);
    }
    if (priorityMapping) {
      yield call(requests.editMappingPriorities, priorityMapping);
    }

    yield put(
      setAlert(createSuccessAlert('Интеграция с Jira успешно отредактирована'))
    );
  } catch (e) {
    yield put(setAlert(createErrorAlert(JIRA_ERROR_ALERT_TITLE)));
    createError(e);
  }
}

function* jiraStatusesByIdFetch({ payload }: FetchJiraStatusesByIdAction) {
  try {
    yield put(showStatusesLoading());
    const jiraStatuses: JiraStatus[] = yield call(
      requests.fetchJiraStatusesById,
      payload
    );
    yield put(fetchJiraStatusesByIdSuccess(jiraStatuses));
  } catch (e) {
    createError(e);
  } finally {
    yield put(hideStatusesLoading());
    yield put(hideJiraIntegrationInfoLoading());
  }
}

function* jiraPrioritiesByIdFetch({ payload }: FetchJiraPrioritiesByIdAction) {
  try {
    yield put(showPrioritiesLoading());
    const jiraPriorities: JiraPriority[] = yield call(
      requests.fetchJiraPrioritiesById,
      payload
    );
    yield put(fetchJiraPrioritiesByIdSuccess(jiraPriorities));
  } catch (e) {
    createError(e);
  } finally {
    yield put(hidePrioritiesLoading());
    yield put(hideJiraIntegrationInfoLoading());
  }
}

function* deleteJiraIntegration({ payload }: DeleteJiraIntegrationAction) {
  try {
    yield call(requests.deleteJiraIntegration, payload);
    yield put(
      setAlert(createSuccessAlert('Интеграция с Jira успешно удалена'))
    );
    yield call(jiraIntegrationsFetch);
    yield put(setCurrentJiraIntegrationId(undefined));
    yield put(setCurrentSystemId(undefined));
    yield put(fetchJiraIntegrationSuccess(undefined));
  } catch (e) {
    yield put(
      setAlert(createErrorAlert('Не удалось удалить интеграцию с Jira'))
    );
    createError(e);
  }
}

function* jiraIntegrationFetch({ payload }: FetchJiraIntegrationRequestAction) {
  try {
    const jiraIntegration: JiraIntegration = yield call(
      requests.fetchJiraIntegration,
      payload
    );
    yield put(fetchJiraIntegrationSuccess(jiraIntegration));

    const systemId = jiraIntegration?.systemId;

    if (systemId) {
      yield call(fetchTicketTypes, {
        payload: systemId,
        type: Jira.FETCH_TICKET_TYPES_REQUEST,
      });
    }
  } catch (e) {
    createError(e);
  }
}

function* editJiraIntegrationsActive({
  payload,
}: EditJiraIntegrationsActiveAction) {
  try {
    const jiraIntegration: JiraIntegration = yield call(
      requests.editJiraIntegrationActive,
      payload
    );
    yield put(fetchJiraIntegrationSuccess(jiraIntegration));
    yield put(updateJiraIntegrationById(jiraIntegration));
  } catch (e) {
    createError(e);
  }
}

function* fetchSpFields({ payload }: FetchSpFieldsRequestAction) {
  try {
    const spFields: Field[] = yield call(requests.fetchSpFields, payload);
    yield put(fetchSpFieldsSuccess(spFields));
  } catch (e) {
    createError(e);
  }
}

function* fetchJiraFields({ payload }: FetchJiraFieldsRequestAction) {
  try {
    const jiraFields: Field[] = yield call(requests.fetchJiraFields, payload);
    yield put(fetchJiraFieldsSuccess(jiraFields));
  } catch (e) {
    createError(e);
  }
}

function* fieldsIntegrationsFetch({
  payload,
}: FetchFieldsIntegrationsRequestAction) {
  try {
    const fieldsIntegrations: FieldsIntegration[] = yield call(
      requests.fetchFieldsIntegrations,
      payload
    );
    yield put(fetchFieldsIntegrationsSuccess(fieldsIntegrations));
  } catch (e) {
    yield put(hideJiraIntegrationInfoLoading());
    createError(e);
  }
}

function* jiraSPStatusesFetch({ payload }: FetchJiraSPStatusesRequestAction) {
  try {
    const statuses: Status[] = yield call(requests.fetchSPStatuses, payload);
    yield put(fetchSPStatusesSuccess(statuses));
    yield put(setSPStatusesForCompare({ [payload.typeId]: statuses }));
  } catch (e) {
    createError(e);
  }
}

function* jiraSPStatusesForCurrentFetch({
  payload,
}: FetchJiraSPStatusesForCurrentRequestAction) {
  try {
    const statuses: Status[] = yield call(requests.fetchSPStatuses, payload);
    yield put(fetchSPStatusesForCurrentSuccess(statuses));
  } catch (e) {
    createError(e);
  }
}

function* fetchInfoForJiraIntegration({
  payload,
}: FetchInfoForJiraIntegrationAction) {
  const { systemId, typeId } = payload;
  const args = { systemId, typeId };

  try {
    yield put(showJiraIntegrationInfoLoading());
    yield call(jiraSPStatusesForCurrentFetch, {
      payload: args,
      type: Jira.FETCH_JIRA_SP_STATUSES_FOR_CURRENT_REQUEST,
    });
    yield call(jiraStatusesByIdFetch, {
      payload: args,
      type: Jira.FETCH_JIRA_STATUSES_BY_ID_REQUEST,
    });
    yield call(jiraPrioritiesByIdFetch, {
      payload: args,
      type: Jira.FETCH_JIRA_PRIORITIES_BY_ID_REQUEST,
    });
    yield call(fieldsIntegrationsFetch, {
      payload: args,
      type: Jira.FETCH_FIELDS_INTEGRATIONS_REQUEST,
    });
  } catch (e) {
    createError(e);
  } finally {
    yield put(hideJiraIntegrationInfoLoading());
  }
}

function* createFieldsIntegration({ payload }: CreateFieldsIntegrationAction) {
  try {
    const systemId: ReturnType<typeof getCurrentSystemId> = yield select(
      getCurrentSystemId
    );
    const jiraIntegrationId: ReturnType<typeof getCurrentJiraIntegrationId> =
      yield select(getCurrentJiraIntegrationId);

    const { typeId } = payload || {};

    if (systemId && jiraIntegrationId && typeId) {
      yield call(requests.createFieldsIntegration, payload, {
        systemId,
        typeId,
      });
      yield call(fieldsIntegrationsFetch, {
        payload: { systemId, typeId },
        type: Jira.FETCH_FIELDS_INTEGRATIONS_REQUEST,
      });
      yield call(fetchSpFields, {
        payload: { systemId, typeId },
        type: Jira.FETCH_SP_FIELDS_REQUEST,
      });
      yield call(fetchJiraFields, {
        payload: { systemId, typeId },
        type: Jira.FETCH_JIRA_FIELDS_REQUEST,
      });
    }
  } catch (e) {
    createError(e);
  }
}

function* deleteFieldsIntegration({ payload }: DeleteFieldsIntegrationAction) {
  try {
    const systemId: ReturnType<typeof getCurrentSystemId> = yield select(
      getCurrentSystemId
    );
    const jiraIntegrationId: ReturnType<typeof getCurrentJiraIntegrationId> =
      yield select(getCurrentJiraIntegrationId);
    const typeId: ReturnType<typeof getTypeId> = yield select(getTypeId);

    yield call(requests.deleteFieldsIntegration, payload);

    if (systemId && jiraIntegrationId && typeId) {
      yield call(fieldsIntegrationsFetch, {
        payload: { systemId, typeId },
        type: Jira.FETCH_FIELDS_INTEGRATIONS_REQUEST,
      });
      yield call(fetchSpFields, {
        payload: { systemId, typeId },
        type: Jira.FETCH_SP_FIELDS_REQUEST,
      });
      yield call(fetchJiraFields, {
        payload: { systemId, typeId },
        type: Jira.FETCH_JIRA_FIELDS_REQUEST,
      });
    }
  } catch (e) {
    createError(e);
  }
}

function* editFieldsIntegration({ payload }: EditFieldsIntegrationAction) {
  try {
    const systemId: ReturnType<typeof getCurrentSystemId> = yield select(
      getCurrentSystemId
    );
    const jiraIntegrationId: ReturnType<typeof getCurrentJiraIntegrationId> =
      yield select(getCurrentJiraIntegrationId);
    const typeId: ReturnType<typeof getTypeId> = yield select(getTypeId);

    if (systemId && jiraIntegrationId && typeId) {
      yield call(requests.editFieldsIntegration, payload);
      yield call(fieldsIntegrationsFetch, {
        payload: { systemId, typeId },
        type: Jira.FETCH_FIELDS_INTEGRATIONS_REQUEST,
      });
      yield call(fetchSpFields, {
        payload: { systemId, typeId },
        type: Jira.FETCH_SP_FIELDS_REQUEST,
      });
      yield call(fetchJiraFields, {
        payload: { systemId, typeId },
        type: Jira.FETCH_JIRA_FIELDS_REQUEST,
      });
    }
  } catch (e) {
    createError(e);
  }
}

function* systemPrioritiesFetch({
  payload,
}: FetchSystemPrioritiesRequestAction) {
  try {
    const priorities: SystemPriority[] = yield call(
      requests.fetchSystemPriorities,
      payload
    );
    yield put(fetchSystemPrioritiesSuccess(priorities));
  } catch (e) {
    yield put(fetchSystemPrioritiesSuccess([]));
    createError(e);
  }
}

function* fetchSystemPrioritiesForCompare({
  payload,
}: FetchSystemPrioritiesForCompareRequestAction) {
  try {
    const priorities: SystemPriority[] = yield call(
      requests.fetchSystemPriorities,
      payload.systemId
    );

    yield put(
      fetchSystemPrioritiesForCompareSuccess({ [payload.typeId]: priorities })
    );
  } catch (e) {
    createError(e);
  }
}

function* fetchSyncTicketTypes({ payload }: FetchSyncTicketTypesRequestAction) {
  try {
    const ticketTypes: TicketType[] = yield call(
      requests.fetchSyncTicketTypes,
      payload
    );
    yield put(fetchSyncTicketTypesSuccess(ticketTypes));
  } catch (e) {
    createError(e);
  }
}

function* fetchSyncPriorities({ payload }: FetchSyncPrioritiesRequestAction) {
  try {
    const priorities: SystemPriority[] = yield call(
      requests.fetchSyncPriorities,
      payload
    );
    yield put(fetchSyncPrioritiesSuccess(priorities));
  } catch (e) {
    createError(e);
  }
}

function* fetchSyncEnvironments({
  payload,
}: FetchSyncEnvironmentsRequestAction) {
  try {
    const environments: ResponseWithMeta<Environment[]> = yield call(
      requests.fetchSyncEnvironments,
      payload
    );
    yield put(fetchSyncEnvironmentsSuccess(environments));
  } catch (e) {
    createError(e);
  }
}

function* fetchSyncStatuses({ payload }: FetchSyncStatusesRequestAction) {
  try {
    const statuses: JiraSynchronizationFilter[] = yield call(
      requests.fetchSyncStatuses,
      payload
    );
    yield put(fetchSyncStatusesSuccess(statuses));
  } catch (e) {
    createError(e);
  }
}

function* fetchSyncSpecialists({ payload }: FetchSyncSpecialistsRequestAction) {
  try {
    const specialists: JiraSynchronizationFilter[] = yield call(
      requests.fetchSyncSpecialists,
      payload
    );
    yield put(fetchSyncSpecialistsSuccess(specialists));
  } catch (e) {
    createError(e);
  }
}

function* fetchSyncClients({ payload }: FetchSyncClientsRequestAction) {
  try {
    const clients: JiraSynchronizationFilter[] = yield call(
      requests.fetchSyncClients,
      payload
    );
    yield put(fetchSyncClientsSuccess(clients));
  } catch (e) {
    createError(e);
  }
}

function* fetchJiraSyncFilter({ payload }: FetchJiraSyncFilterRequestAction) {
  try {
    const filter: JiraSynchronization = yield call(
      requests.fetchJiraSyncFilter,
      payload
    );
    yield put(fetchJiraSyncFilterSuccess(filter));
  } catch (e) {
    createError(e);
  }
}

function* editJiraSynchronization({ payload }: EditJiraSyncRequestAction) {
  try {
    const jiraSync: JiraSynchronization = yield call(
      requests.editJiraSynchronization,
      payload
    );

    yield put(editJiraSyncSuccess(jiraSync));

    yield put(setAlert(createSuccessAlert(JIRA_SUCCESS_ALERT_TITLE)));
  } catch (e) {
    yield put(setAlert(createErrorAlert(JIRA_ERROR_ALERT_TITLE)));
    createError(e);
  }
}

function* fetchJiraStatusesByPropertyId({
  payload,
}: FetchJiraStatusesByPropertyIdRequestAction) {
  try {
    const jiraStatuses: JiraStatus[] = yield call(
      requests.fetchJiraStatusesByPropertyId,
      payload
    );
    yield put(fetchJiraStatusesByPropertyIdSuccess(jiraStatuses));
  } catch (e) {
    createError(e);
  }
}

function* addNewTicketType({ payload }: AddNewTicketTypeRequestAction) {
  try {
    const {
      systemId,
      ticketTypeId,
      jiraIntegrationId,
      issueStatusCreateDtos,
      issuePriorityCreateDtos,
      jiraTicketTypeId,
    } = payload;

    const jiraTicketTypes: JiraTicketType[] | undefined = yield select(
      getJiraTicketTypesForCurrent
    );
    const jiraTicketType = getSelectedJiraTicketType(
      jiraTicketTypeId,
      jiraTicketTypes
    );

    const jiraIntegration: JiraIntegration = yield call(
      requests.addNewTicketType,
      { systemId, ticketTypeId, jiraIntegrationId }
    );

    if (jiraTicketType && jiraIntegration.id) {
      yield call(requests.mappingTicketTypes, {
        idType: jiraTicketType.idType,
        description: jiraTicketType.description,
        name: jiraTicketType.name,
        subtask: jiraTicketType.subtask,
        propertyId: jiraIntegration.id,
        ticketTypeId: ticketTypeId ?? '',
      });
    }

    yield call(requests.mappingStatuses, {
      systemId,
      ticketTypeId,
      issueStatusCreateDtos,
    });
    yield call(requests.mappingPriorities, {
      systemId,
      ticketTypeId,
      issuePriorityCreateDtos,
    });

    yield put(addNewTicketTypeSuccess(jiraIntegration));
    yield put(setAlert(createSuccessAlert(JIRA_SUCCESS_ALERT_TITLE)));

    if (jiraIntegration.id) {
      yield fork(fetchIntegratedTicketTypes, {
        type: Jira.FETCH_INTEGRATED_TICKET_TYPES_REQUEST,
        payload: jiraIntegration.id,
      });
      yield fork(fetchJiraTicketTypeByIntegrationId, {
        type: Jira.FETCH_JIRA_TICKET_TYPE_BY_INTEGRATION_ID_REQUEST,
        payload: jiraIntegrationId,
      });
      yield fork(jiraPrioritiesByIdFetch, {
        type: Jira.FETCH_JIRA_PRIORITIES_BY_ID_REQUEST,
        payload: { systemId, typeId: ticketTypeId ?? '' },
      });
    }
  } catch (e) {
    yield put(setAlert(createErrorAlert(JIRA_ERROR_ALERT_TITLE)));
    createError(e);
  }
}

export function* jiraSaga(): Generator<StrictEffect> {
  yield takeEvery(Jira.FETCH_JIRA_INTEGRATIONS_REQUEST, jiraIntegrationsFetch);
  yield takeEvery(Jira.FETCH_JIRA_STATUSES_REQUEST, jiraStatusesFetch);
  yield takeEvery(Jira.FETCH_JIRA_PRIORITIES_REQUEST, jiraPrioritiesFetch);
  yield takeEvery(
    Jira.FETCH_JIRA_PRIORITIES_BY_INTEGRATION_ID_REQUEST,
    fetchJiraPrioritiesByIntegrationId
  );
  yield takeEvery(Jira.CREATE_JIRA_INTEGRATION, createJiraIntegration);
  yield takeEvery(Jira.EDIT_JIRA_INTEGRATION, editJiraIntegration);
  yield takeEvery(
    Jira.FETCH_JIRA_STATUSES_BY_ID_REQUEST,
    jiraStatusesByIdFetch
  );
  yield takeEvery(
    Jira.FETCH_JIRA_PRIORITIES_BY_ID_REQUEST,
    jiraPrioritiesByIdFetch
  );
  yield takeEvery(Jira.DELETE_JIRA_INTEGRATION, deleteJiraIntegration);
  yield takeEvery(Jira.FETCH_JIRA_INTEGRATION_REQUEST, jiraIntegrationFetch);
  yield takeEvery(
    Jira.EDIT_JIRA_INTEGRATION_ACTIVE,
    editJiraIntegrationsActive
  );
  yield takeEvery(Jira.FETCH_SP_FIELDS_REQUEST, fetchSpFields);
  yield takeEvery(Jira.FETCH_JIRA_FIELDS_REQUEST, fetchJiraFields);
  yield takeEvery(
    Jira.FETCH_FIELDS_INTEGRATIONS_REQUEST,
    fieldsIntegrationsFetch
  );
  yield takeEvery(
    Jira.FETCH_INFO_FOR_JIRA_INTEGRATION,
    fetchInfoForJiraIntegration
  );
  yield takeEvery(Jira.CREATE_FIELDS_INTEGRATION, createFieldsIntegration);
  yield takeEvery(Jira.DELETE_FIELDS_INTEGRATION, deleteFieldsIntegration);
  yield takeEvery(Jira.EDIT_FIELDS_INTEGRATION, editFieldsIntegration);
  yield takeEvery(Jira.FETCH_SYSTEM_PRIORITIES_REQUEST, systemPrioritiesFetch);
  yield takeEvery(
    Jira.FETCH_SYSTEM_PRIORITIES_FOR_COMPARE_REQUEST,
    fetchSystemPrioritiesForCompare
  );
  yield takeEvery(Jira.FETCH_JIRA_SP_STATUSES_REQUEST, jiraSPStatusesFetch);
  yield takeEvery(
    Jira.FETCH_JIRA_SP_STATUSES_FOR_CURRENT_REQUEST,
    jiraSPStatusesForCurrentFetch
  );
  yield takeEvery(Jira.FETCH_TICKET_TYPES_REQUEST, fetchTicketTypes);
  yield takeEvery(Jira.FETCH_JIRA_TICKET_TYPES_REQUEST, fetchJiraTicketType);
  yield takeEvery(
    Jira.FETCH_JIRA_TICKET_TYPES_BY_INTEGRATION_ID_REQUEST,
    fetchJiraTicketTypesByIntegrationId
  );
  yield takeEvery(
    Jira.FETCH_JIRA_TICKET_TYPE_BY_INTEGRATION_ID_REQUEST,
    fetchJiraTicketTypeByIntegrationId
  );
  yield takeEvery(Jira.FETCH_SYNC_TICKET_TYPES_REQUEST, fetchSyncTicketTypes);
  yield takeEvery(Jira.FETCH_SYNC_PRIORITIES_REQUEST, fetchSyncPriorities);
  yield takeEvery(Jira.FETCH_SYNC_ENVIRONMENTS_REQUEST, fetchSyncEnvironments);
  yield takeEvery(Jira.FETCH_SYNC_STATUSES_REQUEST, fetchSyncStatuses);
  yield takeEvery(Jira.FETCH_SYNC_SPECIALISTS_REQUEST, fetchSyncSpecialists);
  yield takeEvery(Jira.FETCH_SYNC_CLIENTS_REQUEST, fetchSyncClients);
  yield takeEvery(Jira.FETCH_JIRA_SYNC_FILTER_REQUEST, fetchJiraSyncFilter);
  yield takeEvery(Jira.EDIT_JIRA_SYNC_REQUEST, editJiraSynchronization);
  yield takeEvery(
    Jira.FETCH_JIRA_STATUSES_BY_PROPERTY_ID_REQUEST,
    fetchJiraStatusesByPropertyId
  );
  yield takeEvery(
    Jira.FETCH_INTEGRATED_TICKET_TYPES_REQUEST,
    fetchIntegratedTicketTypes
  );
  yield takeEvery(Jira.ADD_NEW_TICKET_TYPE_REQUEST, addNewTicketType);
}
