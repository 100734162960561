import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchContractsRequest,
  getSLAContracts,
  resetContractsState,
  TableContractsTypes,
} from '@entities/contract';
import { resetOrganizationState } from '@entities/organization';
import { resetSystemsState, TableSystemsTypes } from '@entities/system';
import { ContractsTableContainer } from '@features/contract/ui';
import { SLATableShort } from '@features/sla';
import { SystemsTableContainer } from '@features/system';
import { TablesLayout } from '@shared';

import styles from './Contracts.module.scss';

const MainTable = () => (
  <ContractsTableContainer tableType={TableContractsTypes.FULL} />
);

const SubTable = () => (
  <SystemsTableContainer tableType={TableSystemsTypes.FROM_CONTRACTS} />
);

const SLA = () => {
  const sla = useSelector(getSLAContracts);
  return <SLATableShort slaSettings={sla?.settings} />;
};

export const Contracts: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchContractsRequest());
    return () => {
      dispatch(resetContractsState());
      dispatch(resetSystemsState());
      dispatch(resetOrganizationState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[MainTable]}
      classNameRoot={styles.contracts}
      SubTable={[SubTable, SLA]}
    />
  );
};
