import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ActionList, getIsAccessedAction, getIsClient } from '@entities/auth';
import {
  addSpecAndSetToWorkTickets,
  getCurrentTicket,
  getIsAccessedTicketAction,
  openExportPanel,
  openSettingFields,
  TicketStatus,
} from '@entities/ticket';
import {
  fetchSubordinatesRequest,
  getSubordinatesWithOutCurrentSelectList,
} from '@entities/user';
import {
  Button,
  checkPathnameWithTrailingSlash,
  DeleteButton,
  ExportIcon,
  PlusIcon,
  PopoverButton,
  PopoverContainer,
  PopoverItemWrapper,
  PopoverLine,
  PopoverTitle,
  RouterHref,
  Select,
  SettingIcon,
  Size,
  ValueType,
} from '@shared';

import { useTicketsFilter } from '../../../lib';
import { TicketsDropContentFilters } from '../TicketsDropContentFilters';

import styles from './TicketsDropContent.module.scss';

interface TicketsDropContentProps {
  showDeleteModal: () => void;
}

export const TicketsDropContent: FC<TicketsDropContentProps> = ({
  showDeleteModal,
}) => {
  const { pathname } = useLocation();

  const { t } = useTranslation('tickets');

  const dispatch = useDispatch();

  const ticket = useSelector(getCurrentTicket);
  const executorOptions =
    useSelector(
      getSubordinatesWithOutCurrentSelectList(ticket?.specialistId?.key)
    ) || [];
  const isClient = useSelector(getIsClient);
  const isAccessToTakeTicketToWork = useSelector(
    getIsAccessedTicketAction(ActionList.TakeTicketToWork)
  );
  const isAccessToAssigningTicketToSpecialist = useSelector(
    getIsAccessedTicketAction(ActionList.AssigningTicketToSpecialist)
  );
  const isAccessToDeleteTicket = useSelector(
    getIsAccessedTicketAction(ActionList.DeleteTicket)
  );
  const isAccessToExportToExcel = useSelector(
    getIsAccessedAction(ActionList.ExportToExcel)
  );

  const {
    state: { filtersData, currentFilterId, isUsedFilter },
    methods: {
      setCurrentFilters,
      handleChangeFilterTitle,
      handleSubmitFilter,
      handleSubmitFilterTitle,
      handleDeleteFilter,
    },
  } = useTicketsFilter();

  const isMyTicketsPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.Tickets
  );
  const isNewTicket = ticket?.customStatus?.defaultStatus === TicketStatus.NEW;
  const showAppointSpec =
    isAccessToAssigningTicketToSpecialist &&
    !isMyTicketsPage &&
    ticket?.customStatus?.defaultStatus !== TicketStatus.CLOSE;

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
  } = useForm<{ executor: ValueType<string> }>({
    mode: 'onChange',
    defaultValues: {
      executor: null,
    },
  });

  useEffect(() => {
    if (isAccessToAssigningTicketToSpecialist && ticket) {
      dispatch(fetchSubordinatesRequest(ticket));
    }
  }, [ticket]);

  const formSubmitHandler = handleSubmit(({ executor }) => {
    if (ticket?.id) {
      dispatch(
        addSpecAndSetToWorkTickets({
          ticketId: [ticket.id],
          specialistId:
            executor && !Array.isArray(executor) ? executor.value : '',
          withTicketsUpdate: true,
          isTicketNew: false,
        })
      );
    }
    reset();
  });

  const setTicketToWorkHandler = () => {
    if (ticket?.id) {
      dispatch(
        addSpecAndSetToWorkTickets({
          ticketId: [ticket.id],
          withTicketsUpdate: true,
        })
      );
    }
  };

  const handleOpenSettingFields = () => {
    dispatch(openSettingFields(true));
  };

  const handleOpenExportExcel = () => {
    dispatch(openExportPanel());
  };

  const settingFields = (
    <PopoverButton
      icon={<SettingIcon className={styles.ticketsDropContent__icon} />}
      onClick={handleOpenSettingFields}
    >
      Настройка столбцов
    </PopoverButton>
  );

  const exportPanel = isAccessToExportToExcel && (
    <>
      <PopoverButton
        onClick={handleOpenExportExcel}
        icon={<ExportIcon className={styles.ticketsDropContent__icon} />}
        className={styles.ticketsDropContent__exportTitle}
      >
        Экспорт данных Excel
      </PopoverButton>
      {showAppointSpec && <PopoverLine />}
    </>
  );

  const header = !isClient &&
    isAccessToTakeTicketToWork &&
    !isMyTicketsPage &&
    isNewTicket && (
      <PopoverButton
        disabled={!ticket}
        onClick={setTicketToWorkHandler}
        icon={<PlusIcon className={styles.ticketsDropContent__icon} />}
      >
        {t('takeToWork')}
      </PopoverButton>
    );

  const body = showAppointSpec && (
    <>
      <PopoverTitle title={t('appointSpecialist')} />
      <PopoverItemWrapper>
        <form
          onSubmit={formSubmitHandler}
          className={styles.ticketsDropContent__body}
        >
          <Controller
            control={control}
            name="executor"
            render={({ field }) => (
              <Select<string>
                size={Size.s}
                mobileModalTitle="исполнителя"
                label="Исполнитель"
                options={executorOptions}
                className={styles.ticketsDropContent__bodySelect}
                dropdownContentClassName={styles.ticketsDropContent__menuSelect}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
          <Button disabled={!isDirty || !isValid || !ticket}>
            {t('appoint')}
          </Button>
        </form>
      </PopoverItemWrapper>
      {isAccessToDeleteTicket && (
        <PopoverLine className={styles.ticketsDropContent__line} />
      )}
    </>
  );

  const filters = filtersData && (
    <TicketsDropContentFilters
      filtersData={filtersData}
      currentFilterId={currentFilterId}
      isUsedFilter={isUsedFilter}
      setCurrentFilters={setCurrentFilters}
      handleChangeFilterTitle={handleChangeFilterTitle}
      handleSubmitFilter={handleSubmitFilter}
      handleSubmitFilterTitle={handleSubmitFilterTitle}
      handleDeleteFilter={handleDeleteFilter}
    />
  );

  return (
    <PopoverContainer>
      {header}
      {settingFields}
      {exportPanel}
      {body}
      {!isClient && isAccessToDeleteTicket && (
        <DeleteButton
          onClick={showDeleteModal}
          disabled={!ticket}
          className={styles.ticketsDropContent__deleteTitle}
        />
      )}
      {filters}
    </PopoverContainer>
  );
};
