import {
  ChangeEvent,
  Dispatch,
  DragEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import { SettingFieldHandleChange, SettingFieldType } from '../../model';
import { mapFields } from '../utils';

type UseSettingFieldListProps<T> = {
  settingFields?: SettingFieldType<T>[];
  disabledField?: string;
};

export const useSettingFieldList = <T,>({
  settingFields,
  disabledField,
}: UseSettingFieldListProps<T>) => {
  const [fieldsList, setFieldsList] = useState<SettingFieldType<T>[]>();
  const [currentField, setCurrentField] = useState<SettingFieldType<T>>();

  useEffect(() => {
    if (settingFields) {
      setFieldsList(settingFields);
    }
  }, [settingFields]);

  const dragFieldsStartHandler = (
    _: DragEvent<HTMLDivElement>,
    field: SettingFieldType<T>
  ) => {
    setCurrentField(field);
  };

  const dropFieldsHandler = (
    event: DragEvent<HTMLDivElement>,
    field: SettingFieldType<T>
  ) => {
    event.preventDefault();

    if (currentField) {
      setFieldsList(
        mapFields({
          fieldsList: fieldsList || [],
          field,
          currentField,
        })
      );
    }
  };

  const checkedAllFields = (
    event: ChangeEvent<HTMLInputElement>,
    setFields: Dispatch<SetStateAction<SettingFieldType<T>[] | undefined>>
  ) => {
    setFields((prev) =>
      prev?.map((field) => {
        if (field.name === disabledField) {
          return { ...field, hidden: false };
        }
        return { ...field, hidden: !event.target.checked };
      })
    );
  };

  const onChangeFieldsList = ({
    event,
    setFields,
    ordinal,
  }: SettingFieldHandleChange<T>) => {
    setFields((prev) =>
      prev?.map((field) => {
        if (ordinal === field.ordinal) {
          return { ...field, hidden: !event.target.checked };
        }
        return field;
      })
    );
  };

  return {
    dragFieldsStartHandler,
    dropFieldsHandler,
    checkedAllFields,
    onChangeFieldsList,
    fieldsList,
    setFieldsList,
  };
};
