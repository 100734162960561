import cn from 'clsx';
import { FC, HTMLAttributes } from 'react';

import { Spacing } from '@shared/model';

import styles from './ButtonGroup.module.scss';

export interface ButtonGroupProps extends HTMLAttributes<HTMLDivElement> {
  spacing?: Spacing;
}

export const ButtonGroup: FC<ButtonGroupProps> = ({
  spacing = Spacing.sm,
  children,
  className,
  ...other
}) => {
  const gapClassName = `buttonGroup_gap${spacing}`;
  const classNames = cn(styles.buttonGroup, gapClassName, className);

  return (
    <div {...other} className={classNames}>
      {children}
    </div>
  );
};
