import cn from 'clsx';
import { debounce } from 'lodash';
import { nanoid } from 'nanoid';
import { FC, KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  CheckMarkIcon,
  CloseIcon,
  DEFAULT_DEBOUNCE_DELAY,
  Loader,
  PenIcon,
  PlusIcon,
  RoundButton,
  Select,
  SelectOption,
  ValueType,
} from '@shared';

import {
  checkIsExistTagRequest,
  createTagRequest,
  fetchTagsRequest,
  resetTagsState,
  setCurrentPageTags,
  setFilterTags,
} from '../../model/tags/actions';
import {
  getIsExistTag,
  getPropsTags,
  getTagsSelectList,
} from '../../model/tags/selectors';
import { updateDataTicketRequest } from '../../model/ticket/actions';
import {
  getLoadingTicketDetail,
  getTicket,
} from '../../model/ticket/selectors';
import { TicketUpdateType } from '../../model/ticket/types';
import { Tag } from '../../model/types';

import styles from './TicketTags.module.scss';
import { TicketTag } from './ui';

interface TicketTagsProps {
  canEditTags?: boolean;
}

const RenderTicketTag = (option: SelectOption) => (
  <div>
    {option.title}
    <CloseIcon />
  </div>
);

export const TicketTags: FC<TicketTagsProps> = ({ canEditTags = false }) => {
  const dispatch = useDispatch();

  const tagsOptions = useSelector(getTagsSelectList);
  const tagsProps = useSelector(getPropsTags);
  const { pageNum, totalPagesTags, loadingTags } = tagsProps;
  const ticket = useSelector(getTicket);
  const loadingTicket = useSelector(getLoadingTicketDetail);
  const isExistTag = useSelector(getIsExistTag);

  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const systemId = ticket?.systemId?.key;

  const getTicketTagsInitial = () => {
    if (ticket?.tags?.length) {
      return ticket.tags.map((tag: Tag) => ({
        title: tag.name || '',
        value: tag.id || '',
      }));
    }
    return [];
  };

  const showInputRow = () => {
    setShowInput(true);
  };

  useEffect(() => {
    if (showInput) {
      dispatch(setCurrentPageTags(0));
      dispatch(fetchTagsRequest('update'));
    }
  }, [showInput]);

  const onInputFilter = debounce((value: string) => {
    dispatch(setCurrentPageTags(0));
    dispatch(setFilterTags({ name: value }));
    dispatch(fetchTagsRequest('update'));

    if (systemId && value) {
      dispatch(
        checkIsExistTagRequest({
          systemId,
          name: value,
        })
      );
    }
  }, DEFAULT_DEBOUNCE_DELAY);

  const handleInput = (value: string) => {
    onInputFilter(value);
    setInputValue(value);
  };

  const handleAddTag = (value: string) => {
    dispatch(createTagRequest({ name: value, systemId: systemId || '' }));
  };

  const handleStopPropagation = (
    event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>
  ) => {
    event.stopPropagation();
  };

  const { control, handleSubmit, reset, setValue } = useForm<{
    tags: ValueType<string>;
  }>({
    mode: 'onChange',
    defaultValues: {
      tags: [],
    },
  });

  const hideInputRow = () => {
    setShowInput(false);
    setInputValue('');
    reset();
    dispatch(resetTagsState());
  };

  useEffect(() => {
    if (ticket && showInput) {
      setValue('tags', getTicketTagsInitial());
    }
  }, [ticket, showInput]);

  const formSubmitHandler = handleSubmit((data) => {
    const { tags } = data;
    if (ticket) {
      const { id, priority, environmentId, theme, description, ticketType } =
        ticket;
      const prepareCreateContractData: TicketUpdateType = {
        id,
        priority,
        systemId: systemId || '',
        environmentId: environmentId.key,
        theme,
        description,
        ticketType,
        tags:
          tags && tags instanceof Array
            ? tags?.map((item: SelectOption) => item.value)
            : [],
      };
      dispatch(updateDataTicketRequest(prepareCreateContractData));
      hideInputRow();
    }
  });

  const handleDeleteTag = (tagId: string) => {
    if (ticket) {
      const { id, priority, environmentId, theme, description, ticketType } =
        ticket;
      const prepareCreateContractData: TicketUpdateType = {
        id,
        priority,
        systemId: systemId || '',
        environmentId: environmentId.key,
        theme,
        description,
        ticketType,
        tags:
          ticket?.tags
            ?.map((elem) => elem.id)
            ?.filter((item) => item !== tagId) || [],
      };
      dispatch(updateDataTicketRequest(prepareCreateContractData));
    }
  };

  const setNextPage = (page: number) => {
    dispatch(setCurrentPageTags(page));
    dispatch(fetchTagsRequest('join'));
  };

  const goToEditModeComponent = (
    <button className={styles.ticketTags__addBtn} onClick={showInputRow}>
      <div className={styles.ticketTags__addIconWrapper}>
        {!ticket?.tags?.length ? (
          <PlusIcon className={styles.ticketTags__addIcon} />
        ) : (
          <PenIcon className={styles.ticketTags__addIcon} />
        )}
      </div>
      {!ticket?.tags?.length ? 'Добавить тег' : null}
    </button>
  );

  const ticketTagsInner = (
    <>
      {!showInput && canEditTags && goToEditModeComponent}
      {!showInput &&
        ticket?.tags?.map((item) => (
          <TicketTag
            key={nanoid()}
            tag={item}
            onDeleteTag={canEditTags ? handleDeleteTag : undefined}
          />
        ))}
      {showInput && (
        <>
          <form className={styles.ticketTags__edit}>
            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <Select<string>
                  options={tagsOptions}
                  onChangeInput={handleInput}
                  currentPage={pageNum}
                  totalPage={totalPagesTags}
                  setNextPage={setNextPage}
                  showAddComponent={!isExistTag}
                  onClickAddComponent={handleAddTag}
                  inputValue={inputValue}
                  value={field.value}
                  onChange={field.onChange}
                  mobileModalTitle="тег"
                  loading={loadingTags}
                  isSearchable
                  renderValue={RenderTicketTag}
                  infiniteScrollable
                  isMulti
                  isChip
                />
              )}
            />
            <div className={styles.ticketTags__editButtons}>
              <RoundButton
                icon={<CloseIcon />}
                onClick={hideInputRow}
                type="button"
                className={styles.ticketTags__editClose}
              />
              <RoundButton
                icon={<CheckMarkIcon />}
                type="submit"
                onClick={formSubmitHandler}
              />
            </div>
          </form>
        </>
      )}
    </>
  );
  return (
    <div
      className={cn(styles.ticketTags, {
        [styles.ticketTags_hidden]: !ticket?.tags?.length && !canEditTags,
      })}
      onClick={handleStopPropagation}
      onKeyDown={handleStopPropagation}
      role="button"
      tabIndex={0}
    >
      {loadingTicket && <Loader classNameRoot={styles.ticketTags__loader} />}
      {!loadingTicket && ticketTagsInner}
    </div>
  );
};
