import { FC } from 'react';

import { Contract } from '@entities/contract';
import { Table } from '@shared';

import { TABLE_HEADER_FROM_SUPPLEMENTARY_AGREEMENT } from '../../../config';

import { getDataTable } from './lib';

interface SupplementaryAgreementTableProps {
  supplementaryAgreements?: Contract[];
  loading?: boolean;
  onSort?: (sort: string) => void;
  sort?: string;
}

export const SupplementaryAgreementTable: FC<
  SupplementaryAgreementTableProps
> = ({ supplementaryAgreements, loading, onSort, sort }) => {
  const dataTable = getDataTable(supplementaryAgreements);

  return (
    <Table
      data={dataTable}
      onSort={onSort}
      sort={sort}
      columns={TABLE_HEADER_FROM_SUPPLEMENTARY_AGREEMENT}
      dataLoading={loading}
      valueFieldName="id"
    />
  );
};
