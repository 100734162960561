import {
  CurrentStateFormData,
  EmployeesFormData,
  HistoryFormData,
  KpiFormData,
  OrganizationsType,
} from '@entities/desktop';

export const NO_DATA_GRAPH = [
  {
    name: 'Нет данных',
  },
];

export const radioTabs = [
  {
    title: 'Закрыты',
    value: OrganizationsType.IN_STATUS_CLOSE,
  },
  {
    title: 'Новые',
    value: OrganizationsType.IN_STATUS_NEW,
  },
  {
    title: 'В работе',
    value: OrganizationsType.IN_ACTIVE_STATUS,
  },
];

export const KPI_PERIOD_OPTIONS = [
  {
    title: 'Неделя',
    value: 'WEEK',
  },
  {
    title: 'Месяц',
    value: 'MONTH',
  },
];

export const INITIAL_KPI_FORM_VALUES: KpiFormData = {
  period: KPI_PERIOD_OPTIONS[0],
  workGroupsIds: [],
  organizationsIds: [],
  systemsIds: [],
  ticketTypesIds: [],
};

export const INITIAL_CURRENT_STATE_FORM_VALUES: CurrentStateFormData = {
  workGroupId: null,
  organizationId: null,
  systemId: null,
  ticketTypesIds: [],
};

export const INITIAL_HISTORY_FORM_VALUES: HistoryFormData = {
  organizationId: null,
  systemId: null,
  date: '',
  dateFrom: '',
  dateTo: '',
  onlyActualTickets: false,
  ticketTypesIds: [],
};

export const INITIAL_EMPLOYEES_FORM_VALUES: EmployeesFormData = {
  workGroupId: null,
  date: '',
  dateFrom: '',
  dateTo: '',
  onlyActualTickets: false,
  organizationId: null,
  systemId: null,
  ticketTypesIds: [],
};
