import {
  StatusCopyModelForm,
  StatusCopyModelPayload,
  SystemsTicketTypes,
} from '@entities/statuses-builder';
import { getMultiOptionValues, getValueFromValueType } from '@shared';

export const getPreparedDataCopyStatusPayload = (
  payload: StatusCopyModelForm,
  ticketTypesTo: SystemsTicketTypes
): StatusCopyModelPayload => {
  const { systemId: selectSystemIdFrom, typeId, systemIds, typeIds } = payload;

  const copyFrom = {
    systemId: getValueFromValueType(selectSystemIdFrom),
    typeId: getValueFromValueType(typeId),
  };

  const copyTo = systemIds.map((option) => {
    const selectSystemId = getValueFromValueType(option);
    const matchingSystem = ticketTypesTo.find(
      ({ systemId }) => systemId === selectSystemId
    );

    const filteredTypeIds = typeIds.filter((typeOption) =>
      matchingSystem?.typeList.some(
        ({ id }) => id === getValueFromValueType(typeOption)
      )
    );

    return {
      systemId: selectSystemId,
      typeIds: getMultiOptionValues(filteredTypeIds),
    };
  });

  return {
    copyFrom,
    copyTo,
  };
};
