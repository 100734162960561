import { Organization } from '@entities/organization';
import { ResponseWithMeta, SelectOption } from '@shared';

import {
  Article,
  CategoryArticle,
  FetchArticlesParams,
  KBArticleTableFilterToRequest,
  KBOrganizationsFilter,
} from '../types';

export enum ArticleViewing {
  FETCH_CURRENT_ARTICLE_REQUEST_ARTICLE_VIEWING = 'KB_ARTICLE/FETCH_CURRENT_ARTICLE_REQUEST_ARTICLE_VIEWING',
  FETCH_CURRENT_ARTICLE_SUCCESS_ARTICLE_VIEWING = 'KB_ARTICLE/FETCH_CURRENT_ARTICLE_SUCCESS_ARTICLE_VIEWING',
  LOADING_SHOW_CURRENT_ARTICLE_ARTICLE_VIEWING = 'KB_ARTICLE/LOADING_SHOW_CURRENT_ARTICLE_ARTICLE_VIEWING',
  LOADING_HIDE_CURRENT_ARTICLE_ARTICLE_VIEWING = 'KB_ARTICLE/LOADING_HIDE_CURRENT_ARTICLE_ARTICLE_VIEWING',

  SET_FILTER_ARTICLES_ARTICLE_VIEWING = 'KB_ARTICLE/SET_FILTER_ARTICLES_ARTICLE_VIEWING',

  FETCH_REQUEST_ARTICLES_ARTICLE_VIEWING = 'KB_ARTICLE/FETCH_REQUEST_ARTICLES_ARTICLE_VIEWING',
  FETCH_SUCCESS_ARTICLES_ARTICLE_VIEWING = 'KB_ARTICLE/FETCH_SUCCESS_ARTICLES_ARTICLE_VIEWING',
  FETCH_ARTICLES_JOIN_SUCCESS_ARTICLE_VIEWING = 'KB_ARTICLE/FETCH_ARTICLES_JOIN_SUCCESS_ARTICLE_VIEWING',
  LOADING_SHOW_ARTICLES_ARTICLE_VIEWING = 'KB_ARTICLE/LOADING_SHOW_ARTICLES_ARTICLE_VIEWING',
  LOADING_HIDE_ARTICLES_ARTICLE_VIEWING = 'KB_ARTICLE/LOADING_HIDE_ARTICLES_ARTICLE_VIEWING',
  SET_ARTICLES_PAGE_ARTICLE_VIEWING = 'KB_ARTICLE/SET_ARTICLES_PAGE_ARTICLE_VIEWING',
  RESET_ARTICLES_STATE_ARTICLE_VIEWING = 'KB_ARTICLE/RESET_ARTICLES_STATE_ARTICLE_VIEWING',

  FETCH_ORGANIZATIONS_REQUEST_ARTICLE_VIEWING = 'KB_ARTICLE/FETCH_ORGANIZATIONS_REQUEST_ARTICLE_VIEWING',
  FETCH_ORGANIZATIONS_SUCCESS_ARTICLE_VIEWING = 'KB_ARTICLE/FETCH_ORGANIZATIONS_SUCCESS_ARTICLE_VIEWING',
  ORGANIZATIONS_LOADING_SHOW_ARTICLE_VIEWING = 'KB_ARTICLE/ORGANIZATIONS_LOADING_SHOW_ARTICLE_VIEWING',
  ORGANIZATIONS_LOADING_HIDE_ARTICLE_VIEWING = 'KB_ARTICLE/ORGANIZATIONS_LOADING_HIDE_ARTICLE_VIEWING',

  FETCH_CATEGORIES_REQUEST_ARTICLE_VIEWING = 'KB_ARTICLE/FETCH_CATEGORIES_REQUEST_ARTICLE_VIEWING',
  FETCH_CATEGORIES_SUCCESS_ARTICLE_VIEWING = 'KB_ARTICLE/FETCH_CATEGORIES_SUCCESS_ARTICLE_VIEWING',
  CATEGORIES_LOADING_SHOW_ARTICLE_VIEWING = 'KB_ARTICLE/CATEGORIES_LOADING_SHOW_ARTICLE_VIEWING',
  CATEGORIES_LOADING_HIDE_ARTICLE_VIEWING = 'KB_ARTICLE/CATEGORIES_LOADING_HIDE_ARTICLE_VIEWING',
  RESET_CATEGORIES_STATE_ARTICLE_VIEWING = 'KB_ARTICLE/RESET_CATEGORIES_STATE_ARTICLE_VIEWING',

  SET_ORGANIZATION_SELECTED_ARTICLE_VIEWING = 'KB_ARTICLE/SET_ORGANIZATION_SELECTED_ARTICLE_VIEWING',
  SET_SEARCH_VALUE_ARTICLE_VIEWING = 'KB_ARTICLE/SET_SEARCH_VALUE_ARTICLE_VIEWING',

  RESET_STATE_ARTICLE_VIEWING = 'KB_ARTICLE/RESET_STATE_ARTICLE_VIEWING',

  CREATE_CATEGORY_REQUEST_RIGHT_ARTICLE_VIEWING = 'KB_ARTICLE/CREATE_CATEGORY_REQUEST_RIGHT_ARTICLE_VIEWING',
  EDIT_CATEGORY_REQUEST_RIGHT_ARTICLE_VIEWING = 'KB_ARTICLE/EDIT_CATEGORY_REQUEST_RIGHT_ARTICLE_VIEWING',
  DELETE_CATEGORY_REQUEST_RIGHT_ARTICLE_VIEWING = 'KB_ARTICLE/DELETE_CATEGORY_REQUEST_RIGHT_ARTICLE_VIEWING',

  EDIT_ARTICLE_REQUEST_RIGHT_ARTICLE_VIEWING = 'KB_ARTICLE/EDIT_ARTICLE_REQUEST_RIGHT_ARTICLE_VIEWING',
  DELETE_ARTICLE_REQUEST_RIGHT_ARTICLE_VIEWING = 'KB_ARTICLE/DELETE_ARTICLE_REQUEST_RIGHT_ARTICLE_VIEWING',
}

export interface FetchCurrentArticleRequestArticleViewingAction {
  type: ArticleViewing.FETCH_CURRENT_ARTICLE_REQUEST_ARTICLE_VIEWING;
  payload: string;
}

export interface FetchCurrentArticleSuccessArticleViewingAction {
  type: ArticleViewing.FETCH_CURRENT_ARTICLE_SUCCESS_ARTICLE_VIEWING;
  payload: Article;
}

export interface LoadingShowCurrentArticleArticleViewingAction {
  type: ArticleViewing.LOADING_SHOW_CURRENT_ARTICLE_ARTICLE_VIEWING;
}

export interface LoadingHideCurrentArticleArticleViewingAction {
  type: ArticleViewing.LOADING_HIDE_CURRENT_ARTICLE_ARTICLE_VIEWING;
}

export interface SetArticlesFilterArticleViewingAction {
  type: ArticleViewing.SET_FILTER_ARTICLES_ARTICLE_VIEWING;
  payload: KBArticleTableFilterToRequest;
}

export interface FetchArticlesRightArticlesTableRequestAction {
  type: ArticleViewing.FETCH_REQUEST_ARTICLES_ARTICLE_VIEWING;
  payload: FetchArticlesParams;
}

export interface FetchArticlesSuccessArticleViewingAction {
  type: ArticleViewing.FETCH_SUCCESS_ARTICLES_ARTICLE_VIEWING;
  payload: ResponseWithMeta<Article[]>;
}

export interface FetchArticlesJoinSuccessArticleViewingAction {
  type: ArticleViewing.FETCH_ARTICLES_JOIN_SUCCESS_ARTICLE_VIEWING;
  payload: ResponseWithMeta<Article[]>;
}

export interface SetArticlesPageArticleViewingAction {
  type: ArticleViewing.SET_ARTICLES_PAGE_ARTICLE_VIEWING;
  payload: number;
}

export interface ResetArticlesStateArticleViewingAction {
  type: ArticleViewing.RESET_ARTICLES_STATE_ARTICLE_VIEWING;
}

export interface LoadingShowArticleViewingAction {
  type: ArticleViewing.LOADING_SHOW_ARTICLES_ARTICLE_VIEWING;
}

export interface LoadingHideArticleViewingAction {
  type: ArticleViewing.LOADING_HIDE_ARTICLES_ARTICLE_VIEWING;
}

export interface FetchOrganizationsRequestArticleViewingAction {
  type: ArticleViewing.FETCH_ORGANIZATIONS_REQUEST_ARTICLE_VIEWING;
  payload?: KBOrganizationsFilter;
}

export interface FetchOrganizationsSuccessArticleViewingAction {
  type: ArticleViewing.FETCH_ORGANIZATIONS_SUCCESS_ARTICLE_VIEWING;
  payload: Organization[];
}

export interface OrganizationsLoadingShowArticleViewingAction {
  type: ArticleViewing.ORGANIZATIONS_LOADING_SHOW_ARTICLE_VIEWING;
}

export interface OrganizationsLoadingHideArticleViewingAction {
  type: ArticleViewing.ORGANIZATIONS_LOADING_HIDE_ARTICLE_VIEWING;
}

export interface FetchCategoriesRequestArticleViewingAction {
  type: ArticleViewing.FETCH_CATEGORIES_REQUEST_ARTICLE_VIEWING;
  payload?: string;
}

export interface FetchCategoriesSuccessArticleViewingAction {
  type: ArticleViewing.FETCH_CATEGORIES_SUCCESS_ARTICLE_VIEWING;
  payload: CategoryArticle[];
}

export interface CategoriesLoadingShowArticleViewingAction {
  type: ArticleViewing.CATEGORIES_LOADING_SHOW_ARTICLE_VIEWING;
}

export interface CategoriesLoadingHideArticleViewingAction {
  type: ArticleViewing.CATEGORIES_LOADING_HIDE_ARTICLE_VIEWING;
}

export interface ResetCategoriesStateArticleViewingAction {
  type: ArticleViewing.RESET_CATEGORIES_STATE_ARTICLE_VIEWING;
}

export interface SetSelectedOrganizationArticleViewingAction {
  type: ArticleViewing.SET_ORGANIZATION_SELECTED_ARTICLE_VIEWING;
  payload: SelectOption | null;
}

export interface SetSearchValueArticleViewingAction {
  type: ArticleViewing.SET_SEARCH_VALUE_ARTICLE_VIEWING;
  payload: string;
}

export interface ResetStateArticleViewingAction {
  type: ArticleViewing.RESET_STATE_ARTICLE_VIEWING;
}

export interface CreateCategoryRequestRightArticleViewingAction {
  type: ArticleViewing.CREATE_CATEGORY_REQUEST_RIGHT_ARTICLE_VIEWING;
  payload: CategoryArticle;
}

export interface EditCategoryRequestRightArticleViewingAction {
  type: ArticleViewing.EDIT_CATEGORY_REQUEST_RIGHT_ARTICLE_VIEWING;
  payload: CategoryArticle;
}

export interface DeleteCategoryRequestRightArticleViewingAction {
  type: ArticleViewing.DELETE_CATEGORY_REQUEST_RIGHT_ARTICLE_VIEWING;
  payload: string;
}

export interface EditArticleRequestRightArticleViewingAction {
  type: ArticleViewing.EDIT_ARTICLE_REQUEST_RIGHT_ARTICLE_VIEWING;
  payload: Partial<Article>;
}

export interface DeleteArticleRequestRightArticleViewingAction {
  type: ArticleViewing.DELETE_ARTICLE_REQUEST_RIGHT_ARTICLE_VIEWING;
  payload: string;
}

export type ArticleViewingActionsTypes =
  | FetchCurrentArticleRequestArticleViewingAction
  | FetchCurrentArticleSuccessArticleViewingAction
  | LoadingShowCurrentArticleArticleViewingAction
  | LoadingHideCurrentArticleArticleViewingAction
  | FetchArticlesRightArticlesTableRequestAction
  | FetchArticlesJoinSuccessArticleViewingAction
  | FetchArticlesSuccessArticleViewingAction
  | SetArticlesPageArticleViewingAction
  | ResetArticlesStateArticleViewingAction
  | LoadingShowArticleViewingAction
  | LoadingHideArticleViewingAction
  | FetchOrganizationsRequestArticleViewingAction
  | FetchOrganizationsSuccessArticleViewingAction
  | OrganizationsLoadingShowArticleViewingAction
  | OrganizationsLoadingHideArticleViewingAction
  | FetchCategoriesRequestArticleViewingAction
  | FetchCategoriesSuccessArticleViewingAction
  | CategoriesLoadingShowArticleViewingAction
  | CategoriesLoadingHideArticleViewingAction
  | ResetCategoriesStateArticleViewingAction
  | SetArticlesFilterArticleViewingAction
  | SetSelectedOrganizationArticleViewingAction
  | SetSearchValueArticleViewingAction
  | ResetStateArticleViewingAction
  | CreateCategoryRequestRightArticleViewingAction
  | EditCategoryRequestRightArticleViewingAction
  | DeleteCategoryRequestRightArticleViewingAction
  | EditArticleRequestRightArticleViewingAction
  | DeleteArticleRequestRightArticleViewingAction;
