import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getSlaTicketTypesFromSettings } from '@entities/sla';
import {
  fetchSlaBySAIdBySystemIdRequestSADetail,
  getSLASupAgDetail,
  getSystemsLoadingSupAgDetail,
  getSystemsSupAgDetail,
  getTicketTypesSupAgDetail,
} from '@entities/supplementary-agreement';
import { TicketTypeSettings } from '@entities/ticket';
import { ParamsProps } from '@widgets/supplementary-agreement';

export const useSystemsTableContainer = () => {
  const dispatch = useDispatch();

  const { supplementaryAgreementId, contractId } = useParams<ParamsProps>();
  const idContractOrSupAg = contractId || supplementaryAgreementId;
  const systems = useSelector(getSystemsSupAgDetail);
  const loading = useSelector(getSystemsLoadingSupAgDetail);
  const currentSystemId = systems?.length ? systems[0]?.id : undefined;
  const sla = useSelector(getSLASupAgDetail);
  const ticketTypes = useSelector(getTicketTypesSupAgDetail);
  const isCreateMode = !supplementaryAgreementId;

  const [tableOpened, setTableOpened] = useState(true);

  const [slaTicketTypes, setSlaTicketTypes] = useState<TicketTypeSettings[]>();

  const prepareDataTag = () => {
    if (sla?.workingHoursFrom?.length && sla?.workingHoursTo?.length) {
      return `${sla?.workingHoursFrom.slice(
        0,
        5
      )} - ${sla?.workingHoursTo.slice(0, 5)}`;
    }
    return undefined;
  };

  const autoClosingTime =
    sla && sla.autoClosableTickets && sla?.closeAfterHours
      ? sla.closeAfterHours.toString()
      : undefined;

  useEffect(() => {
    if (sla?.id) {
      setSlaTicketTypes(getSlaTicketTypesFromSettings(sla?.settings));
      return;
    }
    setSlaTicketTypes(ticketTypes);
  }, [sla]);

  useEffect(() => {
    if (idContractOrSupAg && currentSystemId) {
      dispatch(
        fetchSlaBySAIdBySystemIdRequestSADetail({
          contractId: idContractOrSupAg,
          systemId: currentSystemId,
        })
      );
    }
  }, [currentSystemId, idContractOrSupAg]);

  return {
    methods: {
      setTableOpened,
      prepareDataTag,
    },
    state: {
      systems,
      tableOpened,
      sla,
      autoClosingTime,
      slaTicketTypes,
      currentSystemId,
      loading,
      isCreateMode,
      ticketTypes,
    },
  };
};
