import { SystemPriority } from '@entities/system';
import {
  Priority,
  Status as TicketStatus,
  TicketType,
} from '@entities/ticket/model/types';
import { SelectOption, ValueType } from '@shared';

import { JiraReducerState } from './slice';

export type JiraState = { jira: JiraReducerState };

export interface FormData {
  spField?: ValueType<string>;
  jiraField?: ValueType<string>;
}

export type JiraIntegration = {
  id?: string;
  name?: string;
  isActive?: boolean;
  jiraUrl?: string;
  systemId?: string;
  systemTitle?: string;
  organizationId?: string;
  organizationTitle?: string;
  login?: string;
  projectKey?: string;
  ticketTypeId?: string;
};

export type JiraIntegrationResponse = {
  jiraPropertiesResponse: JiraIntegration;
  ticketTypeIds: string[];
};

export type JiraTicketType = {
  id?: string;
  idType: number;
  name: string;
  subtask: boolean;
  description: string;
  ticketType?: Omit<TicketType, 'value'>;
};

export type JiraStatus = {
  id?: string;
  idStatus?: number;
  description?: string;
  name?: string;
  systemId?: string;
  issueTypeId?: string;
  propertyId?: string;
  ticketStatusIds?: Status[];
};

export type JiraPriority = {
  id?: string;
  idPriority?: number;
  self?: string;
  statusColor?: string;
  description?: string;
  name?: string;
  systemId?: string;
  ticketPriorities?: Priority[];
};

export type JiraStatusesPayload = {
  propertyId: string;
  issueTypeId: string;
};

export interface JiraIntegrationsFilter extends JiraIntegration {}
export interface JiraIntegrationsFormData
  extends Omit<
    JiraIntegrationsFilter,
    'isActive' | 'systemId' | 'organizationId' | 'ticketTypeId'
  > {
  isActive?: ValueType<boolean>;
  systemId?: ValueType<string>;
  organizationId?: ValueType<string>;
}

export type JiraSyncFilterUnionType = keyof typeof JiraSyncFilterType;

export type FilterRulesValue = Record<
  Partial<JiraSyncFilterUnionType>,
  SelectOption[]
>;

export type JiraSynchronizationFilter = {
  type: JiraSyncFilterType;
  value: string;
  entityId: string;
};

export type JiraSyncFilterCriteriaDto = {
  type: Partial<JiraSyncFilterUnionType>;
  value: string;
  entityId: string;
};

export interface JiraSynchronization {
  id: string;
  autoCreateTasks: boolean;
  considerFilters: boolean;
  jiraPropertiesId?: string;
  filterRules: JiraSyncFilterCriteriaDto[];
}

export interface JiraSynchronizationCreateForm {
  autoCreateTasks: boolean;
  considerFilters: boolean;
  jiraPropertiesId?: string;
  filterRules: FilterRulesValue;
}

export enum JiraSyncFilterType {
  TICKET_TYPE = 'TICKET_TYPE',
  TICKET_PRIORITY = 'TICKET_PRIORITY',
  TICKET_ENVIRONMENT = 'TICKET_ENVIRONMENT',
  TICKET_STATUS = 'TICKET_STATUS',
  TICKET_SPECIALIST = 'TICKET_SPECIALIST',
  TICKET_CLIENT = 'TICKET_CLIENT',
}

export type JiraSynchronizationCreateDataToRequest = {
  filterRules?: JiraSyncFilterCriteriaDto[];
} & Omit<JiraSynchronization, 'filterRules' | 'id'>;

type JiraTicketTypeCreate = {
  ticketTypeId?: string;
} & JiraTicketType;

export type Statuses = Record<string, IssueStatusDtos[]>;
export type Priorities = Record<string, IssuePriorityCreateDtos[]>;
export type RecordValueTypeJiraTicketType = Record<string, ValueType<string>>;
export type RecordJiraTicketType = Record<string, JiraTicketTypeCreate>;

export type JiraIntegrationCreateData = {
  password?: string;
  ticketTypeId?: ValueType<string>;
} & JiraIntegrationsFormData;

export interface JiraIntegrationEditData {
  ticketTypeId?: ValueType<string>;
  jiraTicketType?: ValueType<number>;
}

export type IssueStatusDtos = {
  ticketStatusIds: string[];
} & Pick<JiraStatus, 'idStatus' | 'description' | 'name'>;

export type CreateStatusMapping = {
  systemId?: string;
  ticketTypeId?: string;
  issueStatusCreateDtos: IssueStatusDtos[];
};

export type EditStatusMapping = {
  issueStatusUpdateDtos: IssueStatusDtos[];
} & Pick<CreateStatusMapping, 'systemId' | 'ticketTypeId'>;

export type IssuePriorityCreateDtos = Omit<JiraPriority, 'id' | 'systemId'>;

export type IssuePriorityEditDtos = Omit<JiraPriority, 'systemId'>;

export type CreatePriorityMapping = {
  systemId?: string;
  ticketTypeId?: string;
  issuePriorityCreateDtos: IssuePriorityCreateDtos[];
};

export type CreateMapping = {
  propertyId: string;
  mappingStatuses: IssueStatusDtos[];
  mappingPriorities: IssuePriorityCreateDtos[];
  mappingTypes: JiraTicketTypeCreate;
};

export type CreateMappingResponse = CreateMapping;

export type EditPriorityMapping = {
  issueTicketPriorityDtos: IssuePriorityEditDtos[];
} & Pick<CreatePriorityMapping, 'systemId' | 'ticketTypeId'>;

export interface JiraIntegrationCreatePayload
  extends Pick<
    JiraIntegrationCreateData,
    'id' | 'name' | 'login' | 'password' | 'jiraUrl' | 'projectKey'
  > {
  ticketTypeIds: string[];
  systemId?: string;
  organizationId?: string;
  idType?: string;
  mappingPriorities: Priorities;
  mappingStatuses: Statuses;
  mappingTypes: RecordJiraTicketType;
  jiraSyncFilter?: JiraSynchronizationCreateDataToRequest;
}

export interface JiraIntegrationEditDataToRequest {
  statusMapping?: EditStatusMapping;
  priorityMapping?: EditPriorityMapping;
  ticketTypeUpdateDto?: JiraTicketTypeMapping;
}

export interface JiraIntegrationCreate
  extends Pick<
    JiraIntegrationCreateData,
    'name' | 'login' | 'password' | 'jiraUrl' | 'projectKey'
  > {
  ticketTypeIds?: string[];
  systemId?: string;
  organizationId?: string;
}

export interface JiraIntegrationEdit extends JiraIntegrationCreate {
  issueKey: string;
}

export interface CheckConnectionToJiraData
  extends Pick<
    JiraIntegrationCreateData,
    'login' | 'password' | 'jiraUrl' | 'projectKey'
  > {}

export enum JiraConnectionStatus {
  DEFAULT = 'DEFAULT',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type Status = Pick<
  TicketStatus,
  'id' | 'name' | 'defaultStatus' | 'color'
>;

export type JiraTicketTypeMapping = {
  propertyId: string;
  ticketTypeId: string;
} & Partial<Omit<JiraTicketType, 'ticketType'>>;

export enum FieldType {
  DATE = 'DATE',
  STRING = 'STRING',
  LIST = 'LIST',
}

export type Field = {
  id: string;
  title: string;
  value: string;
  fieldType: FieldType;
};

export type FieldsIntegration = {
  id?: string;
  ticketColumn?: string;
  ticketColumnTitle?: string;
  jiraFieldKey?: string;
  jiraFieldTitle?: string;
  fieldType?: FieldType;
  systemId?: string;
  typeId?: string;
  ticketColumnId: string;
};

export enum TableJiraIntegrationsTypes {
  FULL = 'FULL',
}

export type JiraStatusesRequest = {
  idType: string;
} & CheckConnectionToJiraData;

export type FetchArgs = {
  systemId: string;
  typeId: string;
};

export type FetchInfoForJiraIntegrationPayload = {
  systemId: string;
  jiraIntegrationId: string;
  typeId: string;
};

export interface EditJiraIntegrationsActivePayload
  extends Pick<JiraIntegration, 'id' | 'isActive'> {}

export type JiraIntegrationAddNewTicketTypeForm = {
  ticketTypeId?: string;
  systemId: string;
  jiraTicketTypeId: string;
  jiraIntegrationId: string;
  issueStatusCreateDtos: IssueStatusDtos[];
  issuePriorityCreateDtos: IssuePriorityCreateDtos[];
};

export type JiraIntegrationAddNewTicketTypePayload = Pick<
  JiraIntegrationAddNewTicketTypeForm,
  'ticketTypeId' | 'systemId' | 'jiraIntegrationId'
>;

export type SPStatusesForCompare = Record<string, Status[]>;
export type SystemPriorityForCompare = Record<string, SystemPriority[]>;
