import cn from 'clsx';
import { debounce } from 'lodash';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  fetchSystemsForAddingSupplementaryAgreementRequestContractDetail,
  getSystemsForAddingSAContractDetail,
  getSystemsForAddingSALoadingContractDetail,
} from '@entities/contract';
import {
  Create,
  DEFAULT_DEBOUNCE_DELAY,
  Input,
  Loader,
  Size,
  Typography,
  TypographyVariants,
} from '@shared';

import { useContractId } from '../../../lib';

import styles from './SupplementaryAgreementCreate.module.scss';

interface SupplementaryAgreementCreateProps {
  isModal: boolean;
  toggleModal(): void;
}

export const SupplementaryAgreementCreate: FC<
  SupplementaryAgreementCreateProps
> = ({ isModal, toggleModal }) => {
  const { push } = useHistory();

  const dispatch = useDispatch();

  const systemsForContract = useSelector(getSystemsForAddingSAContractDetail);
  const loading = useSelector(getSystemsForAddingSALoadingContractDetail);
  const contractId = useContractId();

  const { register, reset, watch } = useForm<{ title?: string }>({
    mode: 'onChange',
  });
  const { title } = watch();

  const [systemSelected, setSystemSelected] = useState<string | undefined>();

  useEffect(() => {
    dispatch(
      fetchSystemsForAddingSupplementaryAgreementRequestContractDetail()
    );
  }, []);

  const formSubmitHandler = () => {
    if (contractId && systemSelected) {
      push(
        `/admin/supplementary-agreement/create/${contractId}/${systemSelected}`
      );
    }
    reset();
  };

  const handleChangeTitleFilter = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      fetchSystemsForAddingSupplementaryAgreementRequestContractDetail(
        e.target.value
      )
    );
  };

  const systemFilterSubmitHandlerDebounced = useMemo(
    () => debounce(handleChangeTitleFilter, DEFAULT_DEBOUNCE_DELAY),
    []
  );

  const inputSearchSystems = register('title', {
    onChange: (e) => {
      systemFilterSubmitHandlerDebounced(e);
    },
  });

  const systemOrganizationList = (
    <>
      {systemsForContract?.map((item) => {
        const onSelectSystem = () => {
          setSystemSelected(item.id);
        };
        return (
          <li
            key={item.id}
            aria-hidden
            className={cn(styles.supplementaryAgreementCreate__systemItem, {
              [styles.supplementaryAgreementCreate__systemItem_active]:
                systemSelected === item?.id,
            })}
            onClick={onSelectSystem}
          >
            {item.title}
          </li>
        );
      })}
      {!systemsForContract?.length && title && (
        <span className={styles.supplementaryAgreementCreate__noResult}>
          Нет результатов
        </span>
      )}
    </>
  );

  return (
    <Create
      className={styles.supplementaryAgreementCreate}
      classNameBlockAdd={styles.supplementaryAgreementCreate__buttonsBlockAdd}
      toggleModal={toggleModal}
      isModal={isModal}
      createTitle="Продолжить"
      title="Создать допсоглашение"
      onSubmit={formSubmitHandler}
      disabledSubmit={!systemSelected}
      subModalText="создание дополнительного соглашения"
    >
      <div className={styles.supplementaryAgreementCreate__form}>
        <Typography
          variant={TypographyVariants.h4}
          className={styles.supplementaryAgreementCreate__systemsHeader}
        >
          Выберите систему
        </Typography>
        <Input
          size={Size.s}
          label="Поиск"
          className={styles.supplementaryAgreementCreate__systemsSearch}
          {...inputSearchSystems}
        />
        <ul className={styles.supplementaryAgreementCreate__systemsList}>
          {loading && (
            <Loader
              classNameRoot={styles.supplementaryAgreementCreate__loader}
            />
          )}
          {!loading && systemOrganizationList}
        </ul>
      </div>
    </Create>
  );
};
