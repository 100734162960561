import cn from 'clsx';
import {
  FC,
  HTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsClient } from '@entities/auth';
import {
  fetchHistoryRequest,
  fetchNotesByTicketIdRequest,
  getHistory,
  getTicket,
  getTicketHistoryLoading,
  HistoryType,
  MessageType,
  TicketTab,
  TicketTabsBlock,
  useTicketId,
} from '@entities/ticket';
import {
  ArrowInBoxIcon,
  Button,
  Card,
  ChangeIcon,
  CheckMarkIcon,
  getFormatDateWithYear,
  Loader,
  PlusIcon,
  useComponentDidMount,
} from '@shared';

import { ModeTab } from '../../../model';
import { AddNoteModal } from '../AddNoteModal';
import { TicketHistoryTab } from '../TicketHistoryTab';
import { TicketNotes } from '../TicketNotes';

import styles from './TicketHistory.module.scss';

interface TicketHistoryProps extends HTMLAttributes<HTMLDivElement> {}

const getTabIcon = (message: MessageType, type: HistoryType) => {
  if (type === HistoryType.APPOINT || message === MessageType.REOPEN) {
    return <ChangeIcon />;
  }
  if (message === MessageType.CREATE) {
    return <PlusIcon className={styles.ticketHistory__plusIcon} />;
  }
  if (message === MessageType.CLOSED) {
    return <CheckMarkIcon />;
  }
  return <ArrowInBoxIcon />;
};

export const TicketHistory: FC<TicketHistoryProps> = ({ className, style }) => {
  const ticketId = useTicketId();

  const dispatch = useDispatch();

  const ticket = useSelector(getTicket);
  const history = useSelector(getHistory);
  const isClient = useSelector(getIsClient);
  const loading = useSelector(getTicketHistoryLoading);

  const isComponentMounted = useComponentDidMount();

  const canCreateNote = !isClient;

  const ref = useRef<HTMLDivElement | null>(null);

  const [mode, changeMode] = useState<ModeTab>('history');
  const [showCreateDrop, setShowCreateDrop] = useState(false);

  const isHistory = mode === 'history';

  const setHistoryTab = () => {
    changeMode('history');
  };

  const setNotesTab = () => {
    changeMode('notes');
    if (ticketId) {
      dispatch(fetchNotesByTicketIdRequest(ticketId));
    }
  };

  const onCreateNote = () => {
    setShowCreateDrop(true);
  };
  const hideCreateDrop = () => {
    setShowCreateDrop(false);
  };

  const globalTabs: TicketTab[] = useMemo(
    () => [
      {
        title: 'История изменений',
        onTabClick: setHistoryTab,
        isTabActive: mode === 'history',
        className: styles.ticketHistory__tab,
      },
    ],
    [mode, setHistoryTab]
  );

  const specialistTabs: TicketTab[] = useMemo(
    () => [
      {
        title: 'Заметки',
        onTabClick: setNotesTab,
        isTabActive: mode === 'notes',
        className: styles.ticketHistory__tab,
      },
    ],
    [mode, setNotesTab]
  );

  const addNoteTab = {
    title: (
      <Button
        appearance="flat"
        icon={<PlusIcon />}
        disabled={ticket?.isDeleted}
      >
        Добавить
      </Button>
    ),
    className: styles.ticketHistory__tab_noHover,
    onTabClick: onCreateNote,
  };

  const tabs: TicketTab[] = canCreateNote
    ? [...globalTabs, ...specialistTabs]
    : globalTabs;

  useEffect(() => {
    if (ticketId && ticket?.customStatus && isComponentMounted) {
      dispatch(fetchHistoryRequest(ticketId));
    }
  }, [ticketId, ticket?.customStatus, ticket?.specialistId]);

  useEffect(() => {
    setTimeout(() => {
      ref.current?.scrollTo({
        top: ref.current?.scrollHeight,
      });
    }, 200);
  }, [history, ref.current, isHistory]);

  const historyList = !loading ? (
    history?.map((historyTab) => {
      const { date, type, message, statusInfo, personTriggeredName } =
        historyTab;
      const isStatusChange = type === HistoryType.CHANGE_STATUS;

      return (
        <TicketHistoryTab
          key={date}
          icon={getTabIcon(message, type)}
          date={getFormatDateWithYear(date)}
          personTriggeredName={personTriggeredName}
          type={type}
          message={message}
          statusInfo={statusInfo}
          className={cn(styles.ticketHistory__historyTab, {
            [styles.ticketHistory__historyTab_withSubStatus]: isStatusChange,
          })}
        />
      );
    })
  ) : (
    <Loader />
  );

  return (
    <div className={cn(styles.ticketHistory, className)} style={style}>
      <Card className={styles.ticketHistory__title}>
        <TicketTabsBlock
          tabs={tabs}
          rightTab={canCreateNote ? addNoteTab : undefined}
          className={styles.ticketHistory__tabs}
        />
        <AddNoteModal
          isModal={showCreateDrop}
          onHideModal={hideCreateDrop}
          onCreateNote={setNotesTab}
        />
      </Card>
      <div
        ref={ref}
        className={cn(styles.ticketHistory__wrapper, {
          [styles.ticketHistory__wrapper_notes]: mode === 'notes',
          [styles.ticketHistory__wrapper_loading]: loading,
        })}
      >
        {isHistory ? (
          historyList
        ) : (
          <TicketNotes
            onCreateNote={onCreateNote}
            disabled={ticket?.isDeleted}
          />
        )}
      </div>
    </div>
  );
};
