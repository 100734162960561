import { isNil } from 'lodash';

import {
  CustomFieldType,
  CustomFieldTypeFilter,
} from '@entities/custom-fields';

import { TableCustomFieldValue } from '../../model';

const getPropsCustomFieldType = (field: CustomFieldTypeFilter) => {
  if (!field.type) {
    return null;
  }
  const { date, flag, text, type, listValues } = field;

  const fieldTypeMap = {
    [CustomFieldType.FIELD_DATE]: date,
    [CustomFieldType.FIELD_FLAG]: flag,
    [CustomFieldType.FIELD_TEXT]: text,
    [CustomFieldType.FIELD_LIST]: listValues,
  };

  return fieldTypeMap[type];
};

export const getValueCustomFieldsForFilter = (
  fieldId: string,
  fields?: CustomFieldTypeFilter[]
): {
  value: TableCustomFieldValue;
  selectListValues: string[];
} => {
  const customFieldsForTable: Record<string, TableCustomFieldValue> = {};

  fields?.forEach((field) => {
    const value = getPropsCustomFieldType(field);

    if (!isNil(value)) {
      customFieldsForTable[field.fieldId] = value;
    }
  });

  const listFilter = fields?.find(
    ({ type, fieldId: id }) =>
      type === CustomFieldType.FIELD_LIST && id === fieldId
  );

  return {
    value: customFieldsForTable[fieldId],
    selectListValues: listFilter?.listValues ?? [],
  };
};
