import { Attachment, ParentType } from '@entities/attachment/model/types';
import { Contract } from '@entities/contract/model';
import { SLAType } from '@entities/sla/model/types';
import { System } from '@entities/system/model';
import {
  WorkGroup,
  WorkGroupFilterToRequest,
} from '@entities/work-group/model';
import { ApiClient, getEnv, ResponseWithMeta } from '@shared';

import { CreateSupplementaryAgreementBodyType } from '../model';

import { endpoints } from './endpoints';

export const requests = {
  fetchCurrentSupplementaryAgreement: (id: string) =>
    ApiClient.get<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getCurrentContract(id),
      data: {},
    }),
  deleteSupplementaryAgreement: (id: string) =>
    ApiClient.delete<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.deleteContract(id),
      data: {},
    }),
  fetchSystemById: (id: string) =>
    ApiClient.get<System>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSystemByIdUrl(id),
    }),
  fetchSystemBySupAgId: (id: string) =>
    ApiClient.get<System[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSystemBySupAgIdUrl(id),
    }),
  fetchSupAgAttachments: (contractId: string) =>
    ApiClient.get<Attachment[]>({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.fetchAttachments(),
      params: {
        parent: ParentType.CONTRACT,
        id: contractId,
      },
    }),

  fetchGroupsByContractIdBySystemId: (
    contractId: string,
    systemId: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: WorkGroupFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<WorkGroup[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getGroupsByContractIdBySystemId(contractId, systemId),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchSLAByContractIdBySystemId: (contractId: string, systemId: string) =>
    ApiClient.get<SLAType>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSLAByContractIdBySystemIdUrl(),
      params: { contract_id: contractId, system_id: systemId },
    }),
  getCreateSupAgNumber: (contractId: string) =>
    ApiClient.get<string>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getNumberSupAgUrl(contractId),
    }),
  createSupAg: (
    contractId: string,
    systemId: string,
    data: CreateSupplementaryAgreementBodyType
  ) =>
    ApiClient.post<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getCreateSupAgUrl(contractId, systemId),
      data,
    }),
};
