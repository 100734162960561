import { ContractDetailPayload } from '@entities/contract/model';
import { Environment } from '@entities/environment/model/types';
import { SLAType } from '@entities/sla/model/types';
import { FetchRequestArgs, ResponseWithMeta, UpdateContentType } from '@shared';

import { System, SystemPriority, SystemsFilter } from '../system/types';

import {
  DeleteSystemRequestAction,
  FetchCurrentSystemRequestAction,
  FetchFiltersSystemsRequestAction,
  FetchFiltersSystemsSuccessJoinAction,
  FetchFiltersSystemsSuccessUpdateAction,
  FetchMySystemsRequestAction,
  FetchSystemPrioritiesRequestAction,
  FetchSystemPrioritiesSuccessAction,
  FetchSystemsByContractIdRequestAction,
  FetchSystemsOrganizationForJiraRequestAction,
  FetchSystemsOrganizationRequestAction,
  FetchSystemsOrganizationSuccessAction,
  FetchSystemsRequestAction,
  FetchSystemsSuccessJoinAction,
  FetchSystemsSuccessUpdateAction,
  HideSystemsLoadingAction,
  ResetCurrentSystemAction,
  ResetSystemIdStateAction,
  ResetSystemPrioritiesAction,
  ResetSystemsStateAction,
  SetCurrentOrganizationSystemIdAction,
  SetCurrentSystemIdAction,
  SetCurrentSystemsPageAction,
  SetCurrentSystemSuccessAction,
  SetIsErrorNoDataAction,
  SetSelectedSystemSuccessAction,
  SetSizePageAction,
  SetSortSystemsAction,
  SetSystemsByContractAction,
  SetSystemsFilterAction,
  ShowSystemsLoadingAction,
  Systems,
} from './actionTypes';
import { SystemTicketPayload } from './types';

export const showSystemsLoading = (): ShowSystemsLoadingAction => ({
  type: Systems.SYSTEMS_LOADING_SHOW,
});

export const hideSystemsLoading = (): HideSystemsLoadingAction => ({
  type: Systems.SYSTEMS_LOADING_HIDE,
});

export const fetchSystemsRequest = (): FetchSystemsRequestAction => ({
  type: Systems.FETCH_SYSTEMS_REQUEST,
});

export const fetchSystemsOrganizationRequest = (
  id: string
): FetchSystemsOrganizationRequestAction => ({
  type: Systems.FETCH_SYSTEMS_ORGANIZATION_REQUEST,
  payload: id,
});

export const fetchSystemsSuccessUpdate = (
  data: ResponseWithMeta<System[]>
): FetchSystemsSuccessUpdateAction => ({
  type: Systems.FETCH_SYSTEMS_SUCCESS_UPDATE,
  payload: data,
});

export const fetchSystemsSuccessJoin = (
  data: ResponseWithMeta<System[]>
): FetchSystemsSuccessJoinAction => ({
  type: Systems.FETCH_SYSTEMS_SUCCESS_JOIN,
  payload: data,
});

export const fetchFiltersSystemsSuccessUpdate = (
  data: ResponseWithMeta<System[]>
): FetchFiltersSystemsSuccessUpdateAction => ({
  type: Systems.FETCH_FILTERS_SYSTEMS_SUCCESS_UPDATE,
  payload: data,
});

export const fetchFiltersSystemsSuccessJoin = (
  data: ResponseWithMeta<System[]>
): FetchFiltersSystemsSuccessJoinAction => ({
  type: Systems.FETCH_FILTERS_SYSTEMS_SUCCESS_JOIN,
  payload: data,
});

export const fetchSystemsOrganizationSuccess = (
  data: System[]
): FetchSystemsOrganizationSuccessAction => ({
  type: Systems.FETCH_SYSTEMS_ORGANIZATION_SUCCESS,
  payload: data,
});

export const fetchMySystemsRequest = (
  updateType: UpdateContentType
): FetchMySystemsRequestAction => ({
  type: Systems.FETCH_MY_SYSTEMS_REQUEST,
  payload: updateType,
});

export const fetchFiltersSystemsRequest = (
  payload: FetchRequestArgs<SystemTicketPayload>
): FetchFiltersSystemsRequestAction => ({
  type: Systems.FETCH_FILTERS_SYSTEMS_REQUEST,
  payload,
});

export const fetchCurrentSystemRequest = (
  id: string
): FetchCurrentSystemRequestAction => ({
  type: Systems.FETCH_CURRENT_SYSTEM_REQUEST,
  payload: id,
});

export const setCurrentSystemsPage = (
  page: number
): SetCurrentSystemsPageAction => ({
  type: Systems.SET_CURRENT_SYSTEMS_PAGE,
  payload: page,
});

export const setSizePage = (size: number): SetSizePageAction => ({
  type: Systems.SET_SIZE_PAGE_SYSTEMS,
  payload: size,
});

export const setSortSystems = (sort: string): SetSortSystemsAction => ({
  type: Systems.SET_SORT_SYSTEMS,
  payload: sort,
});

export const setCurrentSystemSuccess = (
  data: System
): SetCurrentSystemSuccessAction => ({
  type: Systems.SET_CURRENT_SYSTEM_SUCCESS,
  payload: data,
});

export const resetSystemsState = (): ResetSystemsStateAction => ({
  type: Systems.RESET_SYSTEMS_STATE,
});

export const resetSystemIdState = (): ResetSystemIdStateAction => ({
  type: Systems.RESET_SYSTEM_ID_STATE,
});

export const setCurrentSystemId = (id: string): SetCurrentSystemIdAction => ({
  type: Systems.SET_CURRENT_SYSTEM_ID,
  payload: id,
});

export const setCurrentOrganizationSystemId = (
  id?: string
): SetCurrentOrganizationSystemIdAction => ({
  type: Systems.SET_CURRENT_ORGANIZATION_SYSTEM_ID,
  payload: id,
});

export const fetchSystemsByContractId =
  (): FetchSystemsByContractIdRequestAction => ({
    type: Systems.FETCH_SYSTEMS_BY_CONTRACT_ID_REQUEST,
  });

export const setSystemsByContract = (
  system: System
): SetSystemsByContractAction => ({
  type: Systems.SET_SYSTEMS_BY_CONTRACT,
  payload: system,
});

export const setSystemsFilter = (
  data: SystemsFilter
): SetSystemsFilterAction => ({
  type: Systems.SET_SYSTEMS_FILTER,
  payload: data,
});

export const setSelectedSystemSuccess = (
  data: System
): SetSelectedSystemSuccessAction => ({
  type: Systems.SET_SELECTED_SYSTEM_SUCCESS,
  payload: data,
});

export const fetchSystemPrioritiesRequest = (
  systemId: string
): FetchSystemPrioritiesRequestAction => ({
  type: Systems.FETCH_SYSTEM_PRIORITIES_REQUEST,
  payload: systemId,
});

export const fetchSystemPrioritiesSuccess = (
  priorities: SystemPriority[]
): FetchSystemPrioritiesSuccessAction => ({
  type: Systems.FETCH_SYSTEM_PRIORITIES_SUCCESS,
  payload: priorities,
});

export const resetSystemPriorities = (): ResetSystemPrioritiesAction => ({
  type: Systems.RESET_SYSTEM_PRIORITIES,
});

export const setIsErrorNoData = (data: boolean): SetIsErrorNoDataAction => ({
  type: Systems.SET_IS_ERROR_NO_DATA,
  payload: data,
});

export const deleteSystemRequest = (id: string): DeleteSystemRequestAction => ({
  type: Systems.DELETE_SYSTEM_BY_ID_REQUEST,
  payload: id,
});

export const fetchSystemsOrganizationForJiraRequest = (
  id: string
): FetchSystemsOrganizationForJiraRequestAction => ({
  type: Systems.FETCH_SYSTEMS_ORGANIZATION_FOR_JIRA_REQUEST,
  payload: id,
});

export const resetCurrentSystem = (): ResetCurrentSystemAction => ({
  type: Systems.RESET_CURRENT_SYSTEM,
});

export const fetchSLAByContractIdBySystemIdRequestSystems = (
  payload: ContractDetailPayload
) => ({
  type: Systems.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST,
  payload,
});

export const fetchSLAByContractIdBySystemIdSuccessSystems = (sla: SLAType) => ({
  type: Systems.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS,
  payload: sla,
});

export const resetSLAByContractIdBySystemIdSystems = () => ({
  type: Systems.RESET_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID,
});

export const fetchEnvironmentsRequest = () => ({
  type: Systems.FETCH_ENVIRONMENTS_REQUEST,
});

export const fetchEnvironmentsSuccess = (
  payload: ResponseWithMeta<Environment[]>
) => ({
  type: Systems.FETCH_ENVIRONMENTS_SUCCESS,
  payload,
});
