import { createSelector } from 'reselect';

import { Organization } from '@entities/organization/model/types';
import { System } from '@entities/system/model';
import { TicketType } from '@entities/ticket/model/types';
import { getArraySelectOptions, SelectOption } from '@shared';

import { StatusesBuilderState, SystemsTicketTypes } from './types';

export const getSystems = (state: StatusesBuilderState): System[] | undefined =>
  state.statusesBuilder.systems;
export const getLoading = (state: StatusesBuilderState): boolean | undefined =>
  state.statusesBuilder.loading;
export const getPropsSystems = (state: StatusesBuilderState) => ({
  pageNum: state.statusesBuilder.pagination?.pageNum,
  pageSize: state.statusesBuilder.pagination?.pageSize,
  sortSystems: state.statusesBuilder.sort,
  totalElements: state.statusesBuilder.pagination?.totalElements,
  loadingSystem: getLoading(state),
});
export const getSort = (state: StatusesBuilderState) =>
  state.statusesBuilder.sort;
export const getSystemId = (state: StatusesBuilderState) =>
  state.statusesBuilder.systemId;
export const getOrganizations = (
  state: StatusesBuilderState
): Organization[] | undefined => state.statusesBuilder.organizations;
export const getWorkflow = (state: StatusesBuilderState) =>
  state.statusesBuilder.workflow;
export const getTicketTypes = (state: StatusesBuilderState) =>
  state.statusesBuilder.ticketTypesForSystem;
export const getTicketTypesTo = (state: StatusesBuilderState) =>
  state.statusesBuilder.ticketTypesForSystems;
export const getSystemsFilter = (state: StatusesBuilderState) =>
  state.statusesBuilder.systemsFilter ?? {};

export const getTicketTypesSelectList = createSelector<
  StatusesBuilderState,
  TicketType[] | undefined,
  SelectOption[]
>([getTicketTypes], (types): SelectOption[] => getArraySelectOptions(types));

export const getOrganizationsSelectList = createSelector<
  StatusesBuilderState,
  Organization[] | undefined,
  SelectOption[]
>([getOrganizations], (organizations): SelectOption[] =>
  getArraySelectOptions(organizations)
);

export const getSystemsSelectList = createSelector<
  StatusesBuilderState,
  System[] | undefined,
  SelectOption[]
>([getSystems], (systems): SelectOption[] => getArraySelectOptions(systems));

export const getTicketTypesSelectListCopyTo = createSelector<
  StatusesBuilderState,
  SystemsTicketTypes | undefined,
  SelectOption[]
>([getTicketTypesTo], (types): SelectOption[] => {
  const options = (types ?? []).flatMap(({ typeList }) =>
    getArraySelectOptions(typeList)
  );

  return Array.from(
    new Map(options.map((item) => [item.value, item])).values()
  );
});
