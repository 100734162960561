import { createSelector } from 'reselect';

import { WorkGroup } from '@entities/work-group/model';
import { getArraySelectOptions, SelectOption } from '@shared';

import { WorkGroupsState } from './types';

export const getWorkGroups = (
  state: WorkGroupsState
): WorkGroup[] | undefined => state.workGroups.workGroups;
export const getWorkGroup = (state: WorkGroupsState): WorkGroup | undefined =>
  state.workGroups.workGroup;
export const getLoading = (state: WorkGroupsState): boolean | undefined =>
  state.workGroups.loading;
export const getLoadingWorkGroup = (
  state: WorkGroupsState
): boolean | undefined => state.workGroups.loadingWorkGroup;
export const getLoadingAdd = (state: WorkGroupsState): boolean | undefined =>
  state.workGroups.loadingAdd;
export const getCurrentGroupId = (state: WorkGroupsState) =>
  state.workGroups.workGroupId;
export const getPropsGroups = (state: WorkGroupsState) => ({
  pageNum: state.workGroups.pagination?.pageNum,
  pageSize: state.workGroups.pagination?.pageSize,
  sortGroups: state.workGroups.sort,
  loadingGroups: state.workGroups.loading,
  totalElements: state.workGroups.pagination?.totalElements,
});
export const getPropsGroupsAdd = (state: WorkGroupsState) => ({
  pageNum: state.workGroups.paginationAdd?.pageNum,
  pageSize: state.workGroups.paginationAdd?.pageSize,
  loadingGroupsAdd: state.workGroups.loadingAdd,
  totalElements: state.workGroups.paginationAdd?.totalElements,
});
export const getSort = (state: WorkGroupsState) => state.workGroups.sort;
export const getGroupTitle = (state: WorkGroupsState) =>
  state.workGroups.workGroupTitle;
export const getWorkGroupFilter = (state: WorkGroupsState) =>
  state.workGroups.workGroupFilter || {};
export const getWorkGroupsAdd = (state: WorkGroupsState) =>
  state.workGroups.workGroupsAdd;
export const getWorkGroupsAddFilter = (state: WorkGroupsState) =>
  state.workGroups.workGroupsAddFilter || {};
export const getWorkGroupsFromMyOrg = (state: WorkGroupsState) =>
  state.workGroups.workGroupsFromMyOrg;

export const getWorkGroupsFromMyOrgSelectList = createSelector<
  WorkGroupsState,
  WorkGroup[] | undefined,
  SelectOption[]
>([getWorkGroupsFromMyOrg], (workGroups): SelectOption[] =>
  getArraySelectOptions(workGroups)
);
