import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { resetContractsState, TableContractsTypes } from '@entities/contract';
import { resetOrganizationState } from '@entities/organization';
import {
  fetchEnvironmentsRequest,
  fetchSystemsRequest,
  getSLASystems,
  resetSystemsState,
  TableSystemsTypes,
} from '@entities/system';
import { ContractsTableContainer } from '@features/contract';
import { SLATableShort } from '@features/sla';
import { SystemsTableContainer } from '@features/system';
import { TablesLayout } from '@shared';

import styles from './Systems.module.scss';

const SubContractsTable = () => (
  <ContractsTableContainer tableType={TableContractsTypes.FROM_SYSTEMS} />
);

const MainTable = () => (
  <SystemsTableContainer tableType={TableSystemsTypes.FULL} />
);

const SLA = () => {
  const sla = useSelector(getSLASystems);
  return <SLATableShort slaSettings={sla?.settings} />;
};

export const Systems = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSystemsRequest());
    dispatch(fetchEnvironmentsRequest());
    return () => {
      dispatch(resetContractsState());
      dispatch(resetSystemsState());
      dispatch(resetOrganizationState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[MainTable]}
      SubTable={[SubContractsTable, SLA]}
      classNameRoot={styles.systems}
    />
  );
};
