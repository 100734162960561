import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  fetchKpiRequest,
  getKpiOrganizationsOptions,
  getKpiSystemsOptions,
  getKpiTicketTypesOptions,
  getKpiWorkGroupsOptions,
  KpiFormData,
  resetDesktopState,
  resetKpiState,
} from '@entities/desktop';
import {
  checkPathnameWithTrailingSlash,
  getMultiOptionValues,
  getValueFromValueType,
  RouterHref,
} from '@shared';

import { INITIAL_KPI_FORM_VALUES } from '../../config';

export const useKpiForm = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const { control, handleSubmit, setValue, watch, reset } =
    useForm<KpiFormData>({
      mode: 'onChange',
      defaultValues: INITIAL_KPI_FORM_VALUES,
    });

  const { workGroupsIds, organizationsIds, systemsIds } = watch();

  const workGroupsOptions = useSelector(getKpiWorkGroupsOptions);
  const organizationsOptions = useSelector(getKpiOrganizationsOptions);
  const systemsOptions = useSelector(getKpiSystemsOptions);
  const ticketTypesOptions = useSelector(getKpiTicketTypesOptions);

  const isDesktopKpiPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.DesktopKpi
  );

  useEffect(
    () => () => {
      dispatch(resetKpiState());
      dispatch(resetDesktopState());
      reset();
    },
    []
  );

  const fetchKpiHandler = handleSubmit((data) => {
    dispatch(
      fetchKpiRequest({
        workgroupList: getMultiOptionValues(data.workGroupsIds),
        organizationList: getMultiOptionValues(data.organizationsIds),
        systemList: getMultiOptionValues(data.systemsIds),
        ticketTypeList: getMultiOptionValues(data.ticketTypesIds),
        period: getValueFromValueType(data.period),
      })
    );
  });

  const allKpiFieldReset = () => {
    reset();
    fetchKpiHandler();
  };

  useEffect(() => {
    if (isDesktopKpiPage) {
      fetchKpiHandler();
    }
  }, [isDesktopKpiPage]);

  return {
    state: {
      kpiWorkGroupsIds: workGroupsIds,
      kpiOrganizationsIds: organizationsIds,
      kpiSystemsIds: systemsIds,
      kpiWorkGroupsOptions: workGroupsOptions,
      kpiOrganizationsOptions: organizationsOptions,
      kpiSystemsOptions: systemsOptions,
      kpiTicketTypesOptions: ticketTypesOptions,
    },
    methods: {
      kpiControl: control,
      fetchKpiHandler,
      setValue,
      kpiReset: allKpiFieldReset,
    },
  };
};
