import _ from 'lodash';

import {
  CustomFieldType,
  CustomFieldTypeFilter,
  CustomFieldTypeForFilter,
} from '@entities/custom-fields';
import { getValueFromValueType, ValueType } from '@shared/ui/Select';

import { getReverseDateForRequest } from './getReverseDateForRequest';

export const getPreparedCustomFieldsForSelect = <T>(
  valueName: CustomFieldTypeForFilter,
  value: ValueType<T> | string
): CustomFieldTypeFilter => {
  const isString = _.isString(value);

  const baseField = {
    ...valueName,
  };

  if (valueName.type === CustomFieldType.FIELD_DATE && isString) {
    return {
      ...baseField,
      date: getReverseDateForRequest(value),
    };
  }
  if (valueName.type === CustomFieldType.FIELD_TEXT && isString) {
    return { ...baseField, text: value };
  }
  if (valueName.type === CustomFieldType.FIELD_FLAG && !isString) {
    return { ...baseField, flag: !!getValueFromValueType(value) };
  }
  if (valueName.type === CustomFieldType.FIELD_LIST && !isString) {
    if (Array.isArray(value)) {
      const listValues = value.map((selectValue) => {
        const listOption = getValueFromValueType(selectValue);
        return listOption ? String(listOption) : '';
      });
      return { ...baseField, listValues };
    }
    return baseField;
  }
  return baseField;
};
