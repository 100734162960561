import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  Attribute,
  createRule,
  CreateRuleData,
  DEFAULT_VALUES_RULE_FORM,
  DestinationType,
  fetchAttributesFieldsRequest,
  fetchSpecialistsRequest,
  fetchWorkGroupsRequest,
  getAttributesFields,
  getConditionTypeOptions,
  getCurrentRule,
  getExecutor,
  getMinutes,
  getSpecialistsOptions,
  getWorkGroupsOptions,
  INITIAL_ATTRIBUTES_THEN_VALUES,
  INITIAL_ATTRIBUTES_VALUES,
  resetCurrentRule,
  SELECT_CONDITION_LIST,
  updateRule,
} from '@entities/rules';
import {
  convertedTimeToMinutes,
  getConvertedTime,
  getValueFromValueType,
  SelectOption,
  ValueType,
} from '@shared';

import { INITIAL_ATTRIBUTES, TARGET_MAP } from '../../config';
import {
  getAttributesOptions,
  getNormalizedAttributes,
  prepareAttributeForRequest,
} from '../utils';

interface UseRuleFormProps {
  isModal: boolean;
  isEditMode: boolean;
  toggleIsModal(): void;
}

export const useRuleForm = ({
  isModal,
  isEditMode,
  toggleIsModal,
}: UseRuleFormProps) => {
  const dispatch = useDispatch();

  const rule = useSelector(getCurrentRule);
  const attributesFields = useSelector(getAttributesFields);
  const specialistsOptions = useSelector(getSpecialistsOptions);
  const workGroupsOptions = useSelector(getWorkGroupsOptions);
  const executor = useSelector(getExecutor);
  const conditionTypeOptions = useSelector(getConditionTypeOptions);
  const minutes = useSelector(getMinutes);

  const [attributes, setAttributes] = useState<Attribute[]>(INITIAL_ATTRIBUTES);
  const [attributeIsChanged, setAttributeIsChanged] = useState(false);

  const attributesOptions = getAttributesOptions(attributes);
  const conditionsOptions = SELECT_CONDITION_LIST.filter((type) => type.value);

  const EXECUTOR_MAP = {
    [DestinationType.SPECIALIST]: specialistsOptions,
    [DestinationType.WORK_GROUP]: workGroupsOptions,
  };

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    register,
    formState: { errors, isValid },
  } = useForm<CreateRuleData>({
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES_RULE_FORM,
  });

  const { executorType } = watch();

  const executorOptions = EXECUTOR_MAP[executorType];

  const numberInputOptions = register('minutes', {
    required: true,
  });

  const titleInputOptions = register('title', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Название правила не может быть длиннее 100 символов.',
    },
  });

  const isValidAttributes = useMemo(() => {
    let valid = true;
    attributes.forEach((attribute) => {
      if (
        !attribute.attribute ||
        (Array.isArray(attribute.value) && !attribute.value.length)
      ) {
        valid = false;
      }
    });
    return valid;
  }, [attributes]);

  const getSubmitDisabled = () => {
    if (isEditMode) {
      return (
        (!attributeIsChanged && !isValid) ||
        !isValidAttributes ||
        !attributes.length
      );
    }
    return (
      !attributeIsChanged ||
      !isValid ||
      !isValidAttributes ||
      !attributes.length
    );
  };

  const isDisabledSubmit = getSubmitDisabled();

  const resetRule = () => {
    reset();
    setAttributes(INITIAL_ATTRIBUTES);
    setAttributeIsChanged(false);
  };

  const closeModal = () => {
    toggleIsModal();
    resetRule();
  };

  const formSubmitHandler = handleSubmit((data) => {
    const preparedAttributes = prepareAttributeForRequest(attributes);

    const preparedData = _.omit(
      {
        [TARGET_MAP[data.executorType]]: getValueFromValueType(data.executor),
        ...data,
        ...preparedAttributes,
        conditionType: getValueFromValueType(data.conditionType),
        minutes: convertedTimeToMinutes(data.minutes),
      },
      ['executorType', 'executor']
    );

    if (isEditMode && rule) {
      dispatch(updateRule({ ...preparedData, isEnable: rule.isEnable }));
      closeModal();
      return;
    }

    dispatch(createRule(preparedData));
    closeModal();
  });

  const fetchRulesFieldsHandler = (data: Attribute[]) => {
    const preparedAttributes = prepareAttributeForRequest(data);

    const { organizations, systems } = preparedAttributes;
    const dataThen = { organizations, systems };

    dispatch(fetchAttributesFieldsRequest(preparedAttributes));
    if (!!organizations?.length || !!systems?.length) {
      dispatch(fetchSpecialistsRequest(dataThen));
      dispatch(fetchWorkGroupsRequest(dataThen));
    }
  };

  const onAddAttributeHandler = useCallback(() => {
    setAttributes((prevState) => [
      ...prevState,
      {
        id: uuidv4(),
        isActive: true,
        attribute: { title: '', value: '' },
        value: [],
      },
    ]);
  }, [setAttributes]);

  const onDeleteAttributeHandler = useCallback(
    (id: string) => () => {
      setAttributes((prevState) => {
        const newState = prevState.filter((attribute) => attribute.id !== id);
        fetchRulesFieldsHandler(newState);
        setAttributeIsChanged(true);
        return newState;
      });
    },
    [setAttributes]
  );

  const onChangeValue = useCallback(
    (id: string) => (value: ValueType<string>) => {
      setAttributes((prevState) => {
        const newState = prevState.map((attribute) => {
          if (attribute.id === id) {
            return { ...attribute, value };
          }
          return attribute;
        });
        fetchRulesFieldsHandler(newState);
        setAttributeIsChanged(true);
        return newState;
      });
    },
    [setAttributes]
  );

  const onChangeAttribute = useCallback(
    (id: string) => (value: SelectOption) => {
      setAttributes((prevState) => {
        fetchRulesFieldsHandler(prevState);
        return prevState.map((attribute) => {
          if (!prevState.length || attribute.id === id) {
            return { ...attribute, isActive: true, attribute: value };
          }
          return attribute;
        });
      });
      onChangeValue(id)([]);
    },
    [setAttributes]
  );

  useEffect(() => {
    if (isModal) {
      resetRule();
    }

    if (isEditMode && rule) {
      const normalizedAttributes = getNormalizedAttributes(rule);
      const time = getConvertedTime(minutes) || '00:00';

      setValue('title', rule.title);
      setValue('conditionType', conditionTypeOptions);
      setValue('minutes', time);
      setAttributes(normalizedAttributes);

      if (executor) {
        setValue('executor', executor.executor[0]);
        setValue('executorType', executor.executorType);
      }
    }
  }, [isEditMode, isModal, rule]);

  useEffect(() => {
    if (!isModal) {
      dispatch(resetCurrentRule());
    }
  }, [isModal]);

  useEffect(() => {
    if (!isEditMode) {
      dispatch(resetCurrentRule());
      dispatch(fetchAttributesFieldsRequest(INITIAL_ATTRIBUTES_VALUES));
      dispatch(fetchSpecialistsRequest(INITIAL_ATTRIBUTES_THEN_VALUES));
      dispatch(fetchWorkGroupsRequest(INITIAL_ATTRIBUTES_THEN_VALUES));
    }
  }, [isEditMode]);

  return {
    methods: {
      control,
      closeModal,
      resetRule,
      reset,
      setValue,
      handleSubmit,
      onAddAttributeHandler,
      onDeleteAttributeHandler,
      onChangeAttribute,
      onChangeValue,
      setAttributes,
      setAttributeIsChanged,
      formSubmitHandler,
    },
    state: {
      attributes,
      errors,
      isDisabledSubmit,
      isValidAttributes,
      attributesFields,
      attributesOptions,
      conditionsOptions,
      executorOptions,
      titleInputOptions,
      numberInputOptions,
    },
  };
};
