import { IssueStatusDtos, JiraStatus } from '@entities/jira-integrations';

export const getIssueStatusUpdateDtos = (statuses: JiraStatus[]) =>
  statuses.reduce((acc, el) => {
    const result = acc.find((item) => item.idStatus === el.idStatus);
    const { ticketStatusIds } = el;
    const ids = ticketStatusIds?.map((item) => item.id) || [];

    if (result) {
      return [
        ...acc.filter((item) => item.idStatus !== el.idStatus),
        {
          ...el,
          ticketStatusIds: [...ids, ...result.ticketStatusIds],
        },
      ];
    }

    return [...acc, { ...el, ticketStatusIds: ids }];
  }, [] as IssueStatusDtos[]);
