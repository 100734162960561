import { Reducer } from 'redux';

import { WorkGroup } from '@entities/work-group/model';
import { ReducerMap } from '@shared';

import {
  CurrentUserActionsTypes,
  CurrentUserTypes,
  FetchPermissionsSuccessAction,
  SetAuthorizedAction,
  SetCurrentUserAction,
} from './actionTypes';
import { AccessedRoute, CurrentUser } from './types';

export interface CurrentUserReducerState {
  user?: CurrentUser;
  accessedRoutes?: AccessedRoute[];
  workGroupsIds?: string[];
  workGroups?: WorkGroup[];
  managerWorkGroupsIds?: string[];
  managerWorkGroups?: WorkGroup[];
  actionList?: string[];
  isAuthorized?: boolean;
  specialistWorkGroupsIds?: string[];
  specialistWorkGroups?: WorkGroup[];
}

const initialState: CurrentUserReducerState = {
  accessedRoutes: [],
  workGroupsIds: [],
  managerWorkGroupsIds: [],
  actionList: [],
  isAuthorized: false,
  specialistWorkGroupsIds: [],
  specialistWorkGroups: [],
};

const currentUserReducerMap: ReducerMap<
  CurrentUserReducerState,
  CurrentUserActionsTypes
> = {
  [CurrentUserTypes.SET_CURRENT_USER]: (state, action) => {
    const { payload } = action as SetCurrentUserAction;
    return {
      ...state,
      user: { ...state?.user, ...payload },
    };
  },
  [CurrentUserTypes.SET_AUTHORIZED]: (state, action) => {
    const { payload } = action as SetAuthorizedAction;
    return {
      ...state,
      isAuthorized: payload,
    };
  },
  [CurrentUserTypes.FETCH_USER_PERMISSIONS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchPermissionsSuccessAction;
    const {
      accessedRoutes,
      actionList,
      workGroups,
      managerWorkGroupsIds,
      specialistWorkGroupsIds,
    } = payload;
    const workGroupsIds = workGroups?.map((workGroup) => workGroup.id);
    const managerWorkGroups = workGroups?.filter((workGroup) =>
      managerWorkGroupsIds.includes(workGroup.id)
    );
    const specialistWorkGroups = workGroups?.filter((workGroup) =>
      specialistWorkGroupsIds.includes(workGroup.id)
    );

    return {
      ...state,
      accessedRoutes,
      actionList,
      workGroupsIds,
      managerWorkGroupsIds,
      workGroups,
      managerWorkGroups,
      specialistWorkGroups,
      specialistWorkGroupsIds,
    };
  },
};

export const currentUserSlice: Reducer<
  CurrentUserReducerState,
  CurrentUserActionsTypes
> = (state = initialState, action) => {
  const reducer = currentUserReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
