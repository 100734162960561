import { FC } from 'react';
import { useSelector } from 'react-redux';

import {
  getLoading,
  System,
  TABLE_FIELDS_FROM_CONTRACTS,
  TableSystemsTypes,
} from '@entities/system';
import { Table } from '@shared';

import { SystemsFilter } from '../SystemsFilter';

import { getDataTable } from './utils';

interface SystemsFromContractsTableProps {
  systems?: System[];
  showFilterRow?: boolean;
  sort?: string;
  handleSort?: (value: string) => void;
  handleClickSystem?: (value: string) => void;
  activeRowId?: string;
  isFilterActive?: boolean;
}

export const SystemsFromContractsTable: FC<SystemsFromContractsTableProps> = ({
  systems,
  showFilterRow,
  sort,
  handleSort,
  handleClickSystem,
  activeRowId,
  isFilterActive,
}) => {
  const data = getDataTable(systems);

  const loading = useSelector(getLoading);

  return (
    <Table
      data={data}
      sort={sort}
      onSort={handleSort}
      onRowClick={handleClickSystem}
      columns={TABLE_FIELDS_FROM_CONTRACTS}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={
        showFilterRow && (
          <SystemsFilter tableType={TableSystemsTypes.FROM_CONTRACTS} />
        )
      }
      disabledSort={loading}
    />
  );
};
