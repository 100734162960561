import {
  Contract,
  ContractsFilterToRequest,
  ContractSystem,
  SystemContractId,
} from '@entities/contract/model';
import { SLAType } from '@entities/sla/model/types';
import { System } from '@entities/system/model';
import {
  ApiClient,
  convertFromDateFormat,
  getEnv,
  ResponseWithMeta,
} from '@shared';

import { contractsEndpoints } from './endpoints';

export const contractsRequests = {
  fetchContracts: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: ContractsFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<Contract[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractsEndpoints.getContracts(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: {
        ...filter,
        endDate: convertFromDateFormat(filter?.endDate),
      },
    }),
  fetchCurrentContract: (id: string) =>
    ApiClient.get<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractsEndpoints.getCurrentContract(id),
      data: {},
    }),
  fetchContractsByOrganizationId: (
    organizationId: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: ContractsFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<Contract[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractsEndpoints.getContracts(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: {
        ...filter,
        organizationId,
        endDate: convertFromDateFormat(filter?.endDate),
      },
    }),
  fetchContractsBySystemId: (
    systemId: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: ContractsFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<Contract[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractsEndpoints.getContractsBySystemId(systemId),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: filter,
    }),
  createContract: (body: Contract) =>
    ApiClient.post<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractsEndpoints.createContract(),
      data: body,
    }),
  addSystem: (body: ContractSystem) =>
    ApiClient.put<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractsEndpoints.addSystem(),
      data: body,
    }),
  addSLA: (body: ContractSystem) =>
    ApiClient.put<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractsEndpoints.addSLA(),
      data: body,
    }),
  editContract: (body: Contract) =>
    ApiClient.put<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractsEndpoints.editContract(),
      data: body,
    }),
  updateContract: (data: Contract) =>
    ApiClient.put<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractsEndpoints.createContract(),
      data,
    }),
  fetchSystemListForContract: (id: string) =>
    ApiClient.get<System[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractsEndpoints.getSystemsForContract(id),
      data: {},
    }),
  fetchSLAForSystem: ({ contractId, systemId }: SystemContractId) =>
    ApiClient.get<SLAType>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractsEndpoints.getSLAForSystem(),
      params: { contract_id: contractId, system_id: systemId },
      data: {},
    }),
  updateGroupsToContractToSystem: (
    contractId: string,
    systemId: string,
    slaId: string,
    data: string[]
  ) =>
    ApiClient.put<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractsEndpoints.updateGroupsInSystemInContract(
        contractId,
        systemId,
        slaId
      ),
      data,
    }),
  deleteContract: (id: string) =>
    ApiClient.delete<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractsEndpoints.deleteContract(id),
      data: {},
    }),
  fetchSLA: (contractId: string, systemId: string) =>
    ApiClient.get<SLAType>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: contractsEndpoints.getSLA(),
      params: { contract_id: contractId, system_id: systemId },
    }),
};
