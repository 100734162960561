import { FC } from 'react';
import { useSelector } from 'react-redux';

import { getLoadingTickets, PriorityBlock, Ticket } from '@entities/ticket';
import { Table } from '@shared';

import { TICKETS_BY_CONTRACT_IDS_TABLE_FIELDS } from '../../../config';
import { TicketsFilter } from '../TicketsFilter';

const getDataTable = (tickets?: Ticket[]) =>
  tickets?.map((ticket) => {
    const { number, id, priority, theme } = ticket;
    return {
      id,
      number: String(number),
      priority: <PriorityBlock priority={priority} />,
      theme,
    };
  }) || [];

interface TicketsByContractIdsTableProps {
  tickets?: Ticket[];
  sort?: string;
  handleSort?: (value: string) => void;
  isSearchOpen?: boolean;
  isFilterActive?: boolean;
}

export const TicketsByContractIdsTable: FC<TicketsByContractIdsTableProps> = ({
  isSearchOpen,
  tickets,
  sort,
  handleSort,
  isFilterActive,
}) => {
  const data = getDataTable(tickets);

  const loading = useSelector(getLoadingTickets);

  const bodySearch = isSearchOpen && <TicketsFilter />;

  return (
    <Table
      columns={TICKETS_BY_CONTRACT_IDS_TABLE_FIELDS}
      data={data}
      sort={sort}
      onSort={handleSort}
      filterComponent={bodySearch}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      disabledSort={loading}
    />
  );
};
