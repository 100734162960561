import cn from 'clsx';
import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  getIsClient,
  getUserOrganization,
} from '@entities/auth/model/selectors';
import { useCanCreateArticleOrCategory } from '@entities/knowledge-base/lib';
import { getOrgByUserOrgId } from '@entities/knowledge-base/lib/utils';
import { OrganizationType } from '@entities/organization/model/types';
import { TICKET_STATUS_TITLE_MAP } from '@entities/ticket/config/constants';
import { TicketStatus } from '@entities/ticket/model/types';
import { WorkGroupModal } from '@entities/ticket/ui/WorkGroupModal';
import {
  BackArrowIcon,
  CalendarIcon,
  CheckMarkIcon,
  getFormatDateWithYear,
  IconButtonWrapper,
  InfoBlock,
  InfoBlockProps,
  LabelIcon,
  RouterHref,
  SendToIcon,
  Typography,
  TypographyVariants,
  UserIcon,
  UsersGroupIcon,
} from '@shared';

import { getArticle, getTicket } from '../../model/selectors';
import { SolutionType } from '../../model/types';

import styles from './SimilarSolutionHeader.module.scss';

const TICKET_STATUSES_STYLES_MAP = {
  [TicketStatus.NEW]: styles.similarSolutionHeader__ticketInfoItem_new,
  [TicketStatus.WORK]: styles.similarSolutionHeader__ticketInfoItem_inProcess,
  [TicketStatus.CLOSE]: styles.similarSolutionHeader__ticketInfoItem_closed,
  [TicketStatus.PENDING_CLOSURE]:
    styles.similarSolutionHeader__ticketInfoItem_waitingToClose,
  [TicketStatus.WAITING_INFO]:
    styles.similarSolutionHeader__ticketInfoItem_waitingInfo,
  [TicketStatus.REOPEN]: styles.similarSolutionHeader__ticketInfoItem_reopen,
};

interface SimilarSolutionHeaderProps {
  type: SolutionType;
  toggleIsOpen(): void;
}

export const SimilarSolutionHeader: FC<SimilarSolutionHeaderProps> = ({
  toggleIsOpen,
  type,
}) => {
  const ticket = useSelector(getTicket);
  const article = useSelector(getArticle);
  const isClient = useSelector(getIsClient);
  const userOrganization = useSelector(getUserOrganization);
  const canCreateArticle = useCanCreateArticleOrCategory();

  const [isShowModal, setIsShowModal] = useState<boolean>(false);

  const ticketStatus = ticket?.customStatus?.defaultStatus;
  const isTickedClosed =
    ticket?.customStatus?.defaultStatus === TicketStatus.CLOSE;

  const isTicketTab = type === SolutionType.TICKET;

  const groupsWork =
    ticket?.workGroups.filter((item) =>
      !isClient
        ? item.organizationType === OrganizationType.SERVICE
        : item.organizationType === OrganizationType.CUSTOMER
    ) || [];

  const toggleShowModal = () => {
    setIsShowModal((item) => !item);
  };

  const workGroupButton =
    groupsWork.length > 2 ? (
      <div className={styles.similarSolutionHeader__groupsInfoWrapper}>
        <button onClick={toggleShowModal}>
          {`${groupsWork[0]?.title}; ${groupsWork[1]?.title}`}
        </button>
        <div className={styles.similarSolutionHeader__groupsInfo}>
          +{groupsWork.length - 2}
        </div>
      </div>
    ) : (
      <div>{groupsWork.map((e) => e.title).join('; ') || '-'}</div>
    );

  const infoBlocksTicketConfig = useMemo(
    () => [
      {
        title: 'Статус',
        info: ticketStatus
          ? TICKET_STATUS_TITLE_MAP[ticketStatus]
          : ticket?.customStatus?.name,
        icon: !isTickedClosed ? (
          <LabelIcon
            className={styles.similarSolutionHeader__ticketStatusIcon}
          />
        ) : (
          <CheckMarkIcon
            className={styles.similarSolutionHeader__ticketStatusIcon}
          />
        ),
        classNameIconWrapper: ticketStatus
          ? TICKET_STATUSES_STYLES_MAP[ticketStatus]
          : '',
        backgroundColor: !ticketStatus ? ticket?.customStatus?.color : '',
      },
      {
        title: 'Создан',
        info: ticket?.dateCreate
          ? getFormatDateWithYear(ticket.dateCreate)
          : '-',
        icon: (
          <CalendarIcon
            className={styles.similarSolutionHeader__ticketInfoIcon}
          />
        ),
      },
      {
        title: 'Исполнитель',
        info: ticket?.specialistId?.value || '-',
        icon: (
          <UserIcon
            className={cn(
              styles.similarSolutionHeader__ticketInfoIcon,
              styles.similarSolutionHeader__userIcon
            )}
          />
        ),
      },
      {
        title: 'Дата исполнения',
        info: ticket?.dateSolve ? getFormatDateWithYear(ticket.dateSolve) : '-',
        icon: (
          <CalendarIcon
            className={styles.similarSolutionHeader__ticketInfoIcon}
          />
        ),
      },
      {
        title: 'Группа исполнителей',
        info: workGroupButton,
        icon: (
          <UsersGroupIcon
            className={styles.similarSolutionHeader__ticketInfoIcon}
          />
        ),
      },
    ],
    [
      ticketStatus,
      isTickedClosed,
      ticket?.dateCreate,
      ticket?.specialistId?.value,
      ticket?.dateSolve,
      workGroupButton,
      ticket?.customStatus,
    ]
  );

  const infoBlocksArticleConfig = useMemo(
    () => [
      {
        title: 'Организация',
        info: getOrgByUserOrgId({
          canCreateArticle,
          organizations: article?.organizations,
          userOrganization,
        }),
        icon: (
          <UserIcon
            className={cn(
              styles.similarSolutionHeader__ticketInfoIcon,
              styles.similarSolutionHeader__userIcon
            )}
          />
        ),
      },
      {
        title: 'Создатель',
        info: article?.userFullName || '-',
        icon: (
          <UserIcon
            className={cn(
              styles.similarSolutionHeader__ticketInfoIcon,
              styles.similarSolutionHeader__userIcon
            )}
          />
        ),
      },
      {
        title: 'Редактирован',
        info: article?.updated ? getFormatDateWithYear(article?.updated) : '-',
        icon: (
          <CalendarIcon
            className={styles.similarSolutionHeader__ticketInfoIcon}
          />
        ),
      },
    ],
    [article?.organizations, article?.userFullName, article?.updated]
  );

  const workGroupsModal = isShowModal && (
    <WorkGroupModal isModal={isShowModal} toggleModal={toggleShowModal} />
  );

  const getInfoBlocks = (config: InfoBlockProps[]) =>
    config?.map((infoItem) => (
      <InfoBlock
        key={infoItem.title?.toString()}
        mainTitle={infoItem.title}
        info={infoItem.info}
        icon={infoItem.icon}
        className={styles.similarSolutionHeader__infoBlocksItem}
        classNameIconWrapper={infoItem.classNameIconWrapper}
        classNameInfo={styles.similarSolutionHeader__infoBlocksItemText}
      />
    ));

  return (
    <div className={styles.similarSolutionHeader}>
      <div className={styles.similarSolutionHeader__titleWrapper}>
        <IconButtonWrapper
          icon={
            <BackArrowIcon className={styles.similarSolutionHeader__icon} />
          }
          onClick={toggleIsOpen}
        />
        <Typography
          variant={TypographyVariants.h2}
          className={styles.similarSolutionHeader__title}
        >
          {isTicketTab ? `Тикет #${ticket?.number}` : article?.title}
        </Typography>
        {isTicketTab && (
          <a
            href={`${RouterHref.Tickets}/${ticket?.id}`}
            target="_blank"
            rel="noreferrer"
            className={styles.similarSolutionHeader__titleLink}
          >
            <SendToIcon style={{ color: 'var(--blue120)' }} />
          </a>
        )}
      </div>
      <div className={styles.similarSolutionHeader__infoBlocks}>
        {getInfoBlocks(
          isTicketTab ? infoBlocksTicketConfig : infoBlocksArticleConfig
        )}
      </div>
      {workGroupsModal}
    </div>
  );
};
