import {
  CustomFieldType,
  CustomFieldTypeForFilter,
} from '@entities/custom-fields';
import { TicketsSettingFieldType } from '@entities/ticket';

export const getCustomFieldType = (
  customColumns?: TicketsSettingFieldType[]
) => {
  const result: Record<string, CustomFieldTypeForFilter> = {};

  if (!customColumns) {
    return {};
  }

  customColumns?.forEach(({ customFieldType, naturalKey, values }) => {
    if (customFieldType) {
      result[naturalKey] = {
        fieldId: naturalKey,
        type: customFieldType,
        ...(customFieldType === CustomFieldType.FIELD_LIST && {
          values,
        }),
      };
    }
  });

  return result;
};
