import cn from 'clsx';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchContractsByOrganizationsRequest,
  resetContractsState,
  TableContractsTypes,
} from '@entities/contract';
import {
  fetchCurrentOrganization,
  getCurrentOrganization,
  getLoadingOrganization,
  OrganizationContext,
  resetOrganizationState,
  setCurrentOrganizationId,
} from '@entities/organization';
import { resetSystemsState } from '@entities/system';
import {
  fetchTicketsCountByContractIdRequest,
  fetchTicketsRequest,
  getFilterValues,
  resetCreateTicketsState,
  setTicketsFilter,
  TableTicketsTypes,
  TicketsFilterForRender,
} from '@entities/ticket';
import {
  fetchGroupsByOrganizationId,
  resetWorkGroupsState,
  TableWorkGroupsTypes,
} from '@entities/work-group';
import { ContractsTableContainer } from '@features/contract';
import { OrganizationForm, useOrganizationId } from '@features/organization';
import {
  getDefaultFilterValues,
  TicketsTableContainer,
} from '@features/ticket';
import { WorkGroupsTableContainer } from '@features/work-groups';
import { checkObjectIdentity, LazyLoader } from '@shared';

import styles from './Organization.module.scss';

interface OrganizationProps {
  className?: string;
}

export const Organization: FC<OrganizationProps> = ({ className }) => {
  const organizationId = useOrganizationId();

  const organization = useSelector(getCurrentOrganization);
  const filterValues = useSelector(getFilterValues);
  const loading = useSelector(getLoadingOrganization);

  const [contractIds, setContractIds] = useState<string[]>([]);

  const defaultFilterValues = getDefaultFilterValues({ contractIds });

  const isFilterActive = !checkObjectIdentity(
    filterValues as Record<string, TicketsFilterForRender>,
    defaultFilterValues as Record<string, TicketsFilterForRender>
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (organization) {
      const organizationContractIds = organization.contractList.map(
        (contract) => contract.id
      );
      setContractIds(organizationContractIds);
    }
  }, [organization]);

  useEffect(() => {
    dispatch(setTicketsFilter(defaultFilterValues));
    if (organizationId && contractIds.length) {
      dispatch(fetchTicketsCountByContractIdRequest(contractIds));
      dispatch(fetchTicketsRequest({}));
    }
  }, [organizationId, contractIds]);

  useEffect(() => {
    if (organizationId) {
      dispatch(setCurrentOrganizationId(organizationId));
      dispatch(fetchCurrentOrganization(organizationId));
    }
  }, [organizationId]);

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchContractsByOrganizationsRequest());
      dispatch(fetchGroupsByOrganizationId());
    }
    return () => {
      if (organizationId) {
        dispatch(resetContractsState());
        dispatch(resetWorkGroupsState());
        dispatch(resetOrganizationState());
        dispatch(resetCreateTicketsState());
      }
      dispatch(resetSystemsState());
    };
  }, []);

  if (loading && !organization) {
    return <LazyLoader className={styles.organization__loader} />;
  }

  return (
    <OrganizationContext.Provider value={{ contractIds }}>
      <div className={cn(styles.organization, className)}>
        <div className={styles.organization__leftContainer}>
          <OrganizationForm />
          <ContractsTableContainer
            tableType={TableContractsTypes.FROM_ORGANIZATIONS}
            className={styles.organization__table}
            classNameClose={styles.organization__table_close}
          />
          <WorkGroupsTableContainer
            tableType={TableWorkGroupsTypes.FROM_ORGANIZATIONS}
            className={styles.organization__table}
          />
        </div>
        <div className={styles.organization__rightContainer}>
          <TicketsTableContainer
            tableType={TableTicketsTypes.BY_CONTRACT_IDS}
            title="Тикеты"
            withOutTabs
            isFilterActive={isFilterActive}
            className={styles.organization__ticketsTable}
          />
        </div>
      </div>
    </OrganizationContext.Provider>
  );
};
