import { FC, memo } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { resetSystemsState } from '@entities/system';
import {
  fetchUsersClientsRequest,
  fetchUsersSpecialistsRequest,
  getPropsUsersClients,
  getPropsUsersSpecialists,
  getUsersClientsSelectList,
  getUsersSpecialistsSelectList,
  resetUsersState,
} from '@entities/user';
import {
  getPreparedIdsForTable,
  useTicketsFilterDesktop,
} from '@features/ticket/lib';
import {
  getDefaultDataTable,
  Input,
  SearchSelect,
  Size,
  TableBodyCell,
  TableFilter,
} from '@shared';

export const TicketsFilterDesktop: FC = memo(() => {
  const { state, methods } = useTicketsFilterDesktop();
  const { currentTicketReportFilters, historyReportFilter } = state;
  const { control, formSubmitHandler, resetFilter } = methods;

  const defaultFilterMap: Record<string, JSX.Element> = {
    number: (
      <TableBodyCell filterCell key="number">
        <Controller
          name="number"
          control={control}
          render={({ field }) => (
            <Input
              title="Номер тикета"
              size={Size.xs}
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                formSubmitHandler();
              }}
            />
          )}
        />
      </TableBodyCell>
    ),
    sla: <TableBodyCell filterCell key="sla" />,
    theme: historyReportFilter && (
      <TableBodyCell filterCell key="theme">
        <Controller
          name="theme"
          control={control}
          render={({ field }) => (
            <Input
              title="Тема"
              size={Size.xs}
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                formSubmitHandler();
              }}
            />
          )}
        />
      </TableBodyCell>
    ),
    clientIds: (
      <TableBodyCell filterCell key="clientIds">
        <Controller
          name="clientIds"
          control={control}
          render={({ field }) => (
            <SearchSelect
              {...field}
              mobileModalTitle="клиента"
              options={useSelector(getUsersClientsSelectList)}
              dataPagination={useSelector(getPropsUsersClients)}
              resetState={resetUsersState}
              fetchRequest={fetchUsersClientsRequest}
              onChange={(value) => {
                field.onChange(value);
                formSubmitHandler();
              }}
            />
          )}
        />
      </TableBodyCell>
    ),
    specialistIds: (
      <TableBodyCell filterCell key="specialistIds">
        <Controller
          name="specialistIds"
          control={control}
          render={({ field }) => (
            <SearchSelect
              {...field}
              mobileModalTitle="исполнителя"
              options={useSelector(getUsersSpecialistsSelectList)}
              dataPagination={useSelector(getPropsUsersSpecialists)}
              resetState={resetSystemsState}
              fetchRequest={fetchUsersSpecialistsRequest}
              onChange={(value) => {
                field.onChange(value);
                formSubmitHandler();
              }}
            />
          )}
        />
      </TableBodyCell>
    ),
  };

  const defaultFilter = getDefaultDataTable({
    columns: [...currentTicketReportFilters, ...historyReportFilter],
    defaultFilterMap,
    idsForTable: getPreparedIdsForTable,
  });

  const filter = <>{defaultFilter}</>;

  return <TableFilter filterComponent={filter} onReset={resetFilter} />;
});
