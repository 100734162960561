import { Organization } from '@entities/organization/model/types';
import { System } from '@entities/system/model';
import { TicketType } from '@entities/ticket/model/types';
import { ResponseWithMeta } from '@shared';

import {
  StatusCopyModelPayload,
  SystemsFilter,
  SystemsTicketTypes,
  Workflow,
  WorkflowParams,
} from './types';

export enum StatusesBuilder {
  FETCH_BUILDER_SYSTEMS_REQUEST = 'STATUSES_BUILDER/FETCH_BUILDER_SYSTEMS_REQUEST',
  FETCH_BUILDER_SYSTEMS_SUCCESS = 'STATUSES_BUILDER/FETCH_BUILDER_SYSTEMS_SUCCESS',
  BUILDER_SYSTEMS_LOADING_SHOW = 'STATUSES_BUILDER/BUILDER_SYSTEMS_LOADING_SHOW',
  BUILDER_SYSTEMS_LOADING_HIDE = 'STATUSES_BUILDER/BUILDER_SYSTEMS_LOADING_HIDE',
  SET_CURRENT_BUILDER_SYSTEMS_PAGE = 'STATUSES_BUILDER/SET_CURRENT_BUILDER_SYSTEMS_PAGE',
  SET_SORT_BUILDER_SYSTEMS = 'STATUSES_BUILDER/SET_SORT_BUILDER_SYSTEMS',
  SET_PAGE_SIZE_BUILDER_SYSTEMS = 'STATUSES_BUILDER/SET_PAGE_SIZE_BUILDER_SYSTEMS',
  SET_CURRENT_BUILDER_SYSTEM_ID = 'STATUSES_BUILDER/SET_CURRENT_BUILDER_SYSTEM_ID',
  SET_BUILDER_SYSTEMS_FILTER = 'STATUSES_BUILDER/SET_BUILDER_SYSTEMS_FILTER',
  RESET_STATUSES_BUILDER_STATE = 'STATUSES_BUILDER/RESET_STATUSES_BUILDER_STATE',
  FETCH_BUILDER_ORGANIZATIONS_REQUEST = 'STATUSES_BUILDER/FETCH_BUILDER_ORGANIZATIONS_REQUEST',
  FETCH_BUILDER_ORGANIZATIONS_SUCCESS = 'STATUSES_BUILDER/FETCH_BUILDER_ORGANIZATIONS_SUCCESS',
  FETCH_TICKET_TYPES_FOR_SYSTEM_REQUEST = 'STATUSES_BUILDER/FETCH_TICKET_TYPES_FOR_SYSTEM_REQUEST',
  FETCH_TICKET_TYPES_FOR_SYSTEM_SUCCESS = 'STATUSES_BUILDER/FETCH_TICKET_TYPES_FOR_SYSTEM_SUCCESS',
  FETCH_WORKFLOW_REQUEST = 'STATUSES_BUILDER/FETCH_WORKFLOW_REQUEST',
  FETCH_WORKFLOW_SUCCESS = 'STATUSES_BUILDER/FETCH_WORKFLOW_SUCCESS',
  SAVE_WORKFLOW = 'STATUSES_BUILDER/SAVE_WORKFLOW',
  COPY_MODEL = 'STATUSES_BUILDER/COPY_MODEL',
  FETCH_TICKET_TYPES_FOR_SYSTEMS_REQUEST = 'STATUSES_BUILDER/FETCH_TICKET_TYPES_FOR_SYSTEMS_REQUEST',
  FETCH_TICKET_TYPES_FOR_SYSTEMS_SUCCESS = 'STATUSES_BUILDER/FETCH_TICKET_TYPES_FOR_SYSTEMS_SUCCESS',
}

export interface ShowSystemsLoadingAction {
  type: StatusesBuilder.BUILDER_SYSTEMS_LOADING_SHOW;
}

export interface HideSystemsLoadingAction {
  type: StatusesBuilder.BUILDER_SYSTEMS_LOADING_HIDE;
}

export interface FetchSystemsRequestAction {
  type: StatusesBuilder.FETCH_BUILDER_SYSTEMS_REQUEST;
}

export interface FetchSystemsSuccessAction {
  type: StatusesBuilder.FETCH_BUILDER_SYSTEMS_SUCCESS;
  payload: ResponseWithMeta<System[]>;
}

export interface SetCurrentSystemsPageAction {
  type: StatusesBuilder.SET_CURRENT_BUILDER_SYSTEMS_PAGE;
  payload: number;
}

export interface SetPageSizeAction {
  type: StatusesBuilder.SET_PAGE_SIZE_BUILDER_SYSTEMS;
  payload: number;
}

export interface SetSortSystemsAction {
  type: StatusesBuilder.SET_SORT_BUILDER_SYSTEMS;
  payload: string;
}

export interface ResetStatusesBuilderStateAction {
  type: StatusesBuilder.RESET_STATUSES_BUILDER_STATE;
}

export interface SetCurrentSystemIdAction {
  type: StatusesBuilder.SET_CURRENT_BUILDER_SYSTEM_ID;
  payload?: string;
}

export interface SetSystemsFilterAction {
  type: StatusesBuilder.SET_BUILDER_SYSTEMS_FILTER;
  payload: SystemsFilter;
}

export interface FetchOrganizationsRequestAction {
  type: StatusesBuilder.FETCH_BUILDER_ORGANIZATIONS_REQUEST;
}

export interface FetchOrganizationsSuccessAction {
  type: StatusesBuilder.FETCH_BUILDER_ORGANIZATIONS_SUCCESS;
  payload: Organization[];
}

export interface FetchWorkflowRequestAction {
  type: StatusesBuilder.FETCH_WORKFLOW_REQUEST;
  payload: WorkflowParams;
}

export interface FetchWorkflowSuccessAction {
  type: StatusesBuilder.FETCH_WORKFLOW_SUCCESS;
  payload?: Workflow;
}

export interface FetchTicketTypesForSystemRequestAction {
  type: StatusesBuilder.FETCH_TICKET_TYPES_FOR_SYSTEM_REQUEST;
  payload: string;
}

export interface FetchTicketTypesForSystemSuccessAction {
  type: StatusesBuilder.FETCH_TICKET_TYPES_FOR_SYSTEM_SUCCESS;
  payload?: TicketType[];
}

export interface SaveWorkflowAction {
  type: StatusesBuilder.SAVE_WORKFLOW;
  payload: Workflow;
}

export interface CopyStatusModelAction {
  type: StatusesBuilder.COPY_MODEL;
  payload: StatusCopyModelPayload;
}
export interface FetchTicketTypesForSystemsRequestAction {
  type: StatusesBuilder.FETCH_TICKET_TYPES_FOR_SYSTEMS_REQUEST;
  payload: string[];
}

export interface FetchTicketTypesForSystemsSuccessAction {
  type: StatusesBuilder.FETCH_TICKET_TYPES_FOR_SYSTEMS_SUCCESS;
  payload: SystemsTicketTypes;
}

export type StatusesBuilderActionsTypes =
  | SetCurrentSystemIdAction
  | SetSortSystemsAction
  | SetCurrentSystemsPageAction
  | SetPageSizeAction
  | SetSystemsFilterAction
  | FetchSystemsSuccessAction
  | FetchOrganizationsSuccessAction
  | FetchWorkflowSuccessAction
  | FetchTicketTypesForSystemSuccessAction
  | FetchTicketTypesForSystemsSuccessAction;
