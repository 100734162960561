import { isNil } from 'lodash';

import { JiraPriority } from '@entities/jira-integrations';
import { Priority } from '@entities/ticket';

export const getPreparedPriorities = (jiraPriority?: JiraPriority[]) =>
  Object.values(Priority)
    .map((priority) => {
      const existingTicketPriority = jiraPriority?.find((item) =>
        item?.ticketPriorities?.find((el) => el === priority)
      );

      if (!existingTicketPriority) {
        return undefined;
      }

      return {
        ...existingTicketPriority,
        ticketPriorities: [priority],
      } as JiraPriority;
    })
    .filter((value): value is JiraPriority => !isNil(value));
