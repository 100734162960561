import {
  FilterRulesValue,
  JiraSyncFilterCriteriaDto,
  JiraSynchronization,
  JiraSynchronizationCreateForm,
} from '@entities/jira-integrations';

import { getArraySyncFilterRules } from './getArraySyncFilterRules';

type Args = {
  jiraSyncFilter?: JiraSynchronization;
  data: JiraSynchronizationCreateForm;
  filterRulesMap: FilterRulesValue;
};
export const getPreparedDataForEditSync = ({
  jiraSyncFilter,
  data,
  filterRulesMap,
}: Args) => {
  const { jiraPropertiesId, id } = jiraSyncFilter || {};
  const { filterRules: dataFilterRules, ...other } = data;

  const filterRules = getArraySyncFilterRules(filterRulesMap);

  const result: JiraSyncFilterCriteriaDto[] = [];

  filterRules.forEach((rule) => {
    const arr = dataFilterRules[rule.type];

    if (arr.length) {
      arr.forEach((item) => {
        if (item.value === rule.entityId) {
          result.push(rule);
        }
      });
    }
  });

  return { ...other, jiraPropertiesId, id, filterRules: result };
};
