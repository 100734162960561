import { Contract } from '@entities/contract';
import { CalendarIcon, getFormatDateWithoutTime, Tag } from '@shared';

import styles from './getDataTable.module.scss';

export const getDataTable = (supplementaryAgreements?: Contract[]) =>
  supplementaryAgreements?.map((supplementaryAgreement) => {
    const { title, systemTitle, id, openDate, endDate } =
      supplementaryAgreement;
    return {
      id,
      title: {
        title,
        to: `/admin/supplementary-agreement/${id}`,
      },

      systemTitle: <Tag text={systemTitle} />,
      supportPeriod: (
        <div className={styles.dateContent}>
          <CalendarIcon className={styles.dateContent__icon} />
          {getFormatDateWithoutTime(openDate)}
          {' - '}
          {getFormatDateWithoutTime(endDate)}
        </div>
      ),
    };
  }) || [];
