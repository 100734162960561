import { SelectOption } from '@shared';

import { OrganizationType } from '../model/types';

export const TYPE_OPTIONS: SelectOption<OrganizationType>[] = [
  { title: 'Клиентская', value: OrganizationType.CUSTOMER },
  { title: 'Партнерская', value: OrganizationType.PARTNER },
];

export const TYPE_OPTIONS_FOR_FILTER: SelectOption<OrganizationType>[] = [
  ...TYPE_OPTIONS,
  { title: 'Обслуживающая', value: OrganizationType.SERVICE },
];

export const META_ORGANIZATION: Record<OrganizationType, string> = {
  [OrganizationType.SERVICE]: 'Админская',
  [OrganizationType.CUSTOMER]: 'Клиентская',
  [OrganizationType.PARTNER]: 'Партнёрская',
};
