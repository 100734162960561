import { JiraTicketType } from '@entities/jira-integrations/model';

export const getSelectedJiraTicketType = (
  id?: string,
  jiraTicketTypes?: JiraTicketType[]
) => {
  if (id) {
    return jiraTicketTypes?.find(({ idType }) => idType === +id);
  }
  return undefined;
};
