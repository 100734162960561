import {
  CustomFieldType,
  CustomFieldTypeFilter,
} from '@entities/custom-fields';
import { Priority } from '@entities/ticket/model/types';
import {
  FetchRequestArgs,
  KeyValueOption,
  Nullable,
  ObjectType,
  SelectOption,
  SettingFieldType,
  TicketsTabType,
} from '@shared';

import { TicketsReducerState } from './slice';

export type TicketsState = {
  tickets: TicketsReducerState;
};

export type TicketsFilterForRequest = {
  theme?: string;
  number?: string;
  statuses?: string[];
  priorities?: Priority[];
  specialistIds?: string[];
  dateSolveFrom?: Date;
  dateSolveTo?: Date;
  systemIds?: string[];
  clientIds?: string[];
  memberIds?: string[];
  workGroupIds?: string[];
  contractIds?: string[];
  tagName?: string;
  onlyActive?: boolean;
  dateCreateFrom?: Date;
  dateCreateTo?: Date;
  typeIds?: string[];
  customFields?: CustomFieldTypeFilter[];
  name?: string;
};

export interface TicketsFilterForRender
  extends Pick<
    TicketsFilterForRequest,
    'theme' | 'number' | 'onlyActive' | 'contractIds' | 'customFields'
  > {
  statuses?: Array<SelectOption>;
  priorities?: Array<SelectOption>;
  specialistIds?: Array<SelectOption>;
  memberIds?: Array<SelectOption | string>;
  workGroupIds?: string[];
  systemIds?: Array<SelectOption>;
  clientIds?: Array<SelectOption>;
  tagName?: string;
  dateSolve?: string;
  dateCreate?: string;
  typeIds?: Array<SelectOption>;
  name?: string;
  sources?: Array<SelectOption>;
}

export interface Filter {
  theme?: string;
  number?: string;
  onlyActive?: boolean;
  contractIds?: string[];
  statuses?: string[];
  priorities?: KeyValueOption[];
  specialists?: KeyValueOption[];
  workGroupIds?: string[];
  systems?: KeyValueOption[];
  clients?: KeyValueOption[];
  tagName?: string;
  dateSolveFrom: Date;
  dateSolveTo: Date;
  dateCreateFrom: Date;
  dateCreateTo: Date;
  types?: KeyValueOption[];
}

export interface FilterData {
  id: string;
  userId: string;
  title: string;
  filter: Filter;
}

export interface DefaultFilterValuesProps {
  userId?: string;
  userWorkGroupsIds?: string[];
  isClient?: boolean;
  contractIds?: string[];
  userManagerWorkGroupsIds?: string[];
  isMyTicketsPage?: boolean;
  isAllTicketsPage?: boolean;
  isGroupsTicketsPage?: boolean;
}

export interface CreateFilterRequest {
  title: string;
  ticketTab: TicketsTabType;
  filter: TicketsFilterForRequest;
}

export interface DeleteFilterRequest {
  id: string;
  ticketTab: TicketsTabType;
}

export interface SetCurrentFilterTitleRequest {
  title: string;
  id: string;
  ticketTab?: TicketsTabType;
}

export interface FetchTickets {
  withFirstTicketFetch?: boolean;
  ticketTab?: TicketsTabType;
}

export interface FetchStatuses {
  filter: TicketsFilterForRender;
}

export enum TicketsTableFields {
  TICKET_NUMBER = 'Номер тикета',
  SYSTEM = 'Система',
  SOURCE = 'Источник',
}

export type FetchRequestArgsWithTab<T extends ObjectType> =
  FetchRequestArgs<T> & {
    ticketTab?: TicketsTabType;
  };

export type TypesFilterToRequest = {
  title?: string;
  ticketTab?: TicketsTabType;
};

export type StatusToRequest = {
  name?: string;
  ticketTab?: TicketsTabType;
};

export interface StatusTicketPayload
  extends FetchRequestArgsWithTab<StatusToRequest> {}

export interface CreateTicketInJiraPayload {
  ticketId: string;
  systemId: string;
  userId: string;
  ticketTypeId: string;
}

export interface FetchTicketsPayload
  extends FetchRequestArgs<TicketsFilterForRequest> {
  filterValues?: TicketsFilterForRequest;
  ticketTab?: TicketsTabType;
}

export interface TicketsTypesPayload
  extends FetchRequestArgsWithTab<TypesFilterToRequest> {}

export type TicketsSettingFieldType = SettingFieldType<CustomFieldType>;

export interface ISettingFields {
  userId: string;
  defaultColumns: TicketsSettingFieldType[];
  customColumns: TicketsSettingFieldType[];
}

export type TableCustomFieldValue = Nullable<string | boolean | string[]>;

export interface EnabledFieldsList {
  defaultColumns: string[];
  customColumns: string[];
}

export interface CreateSettingFields extends Omit<ISettingFields, 'userId'> {}
