import { FC } from 'react';

import { Storage } from '@entities/storage';
import { MoreTag, UsersGroupIcon } from '@shared';

import { NotificationInfoBlock } from '../NotificationInfoBlock';
import { NotificationInformationLayout } from '../NotificationInformationLayout';

import styles from './NotificationStorageInformation.module.scss';

type NotificationStorageInformationProps = {
  storage?: Storage;
};

export const NotificationStorageInformation: FC<
  NotificationStorageInformationProps
> = ({ storage }) => (
  <NotificationInformationLayout
    title={`Хранилище ${storage?.title ?? ''}`}
    description={storage?.description ?? ''}
  >
    <NotificationInfoBlock
      mainTitle="Организация"
      info={
        <MoreTag
          classNameTitle={
            styles.notificationStorageInformation__organizationTitle
          }
          data={storage?.organizations || []}
        />
      }
      icon={
        <UsersGroupIcon
          className={styles.notificationStorageInformation__icon}
        />
      }
      classNameInfoWrapper={styles.notificationStorageInformation__wrapper}
    />
  </NotificationInformationLayout>
);
