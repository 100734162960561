import { Reducer } from 'redux';

import { Action } from '@entities/actions/model/types';
import {
  CustomField,
  CustomFieldToRequest,
} from '@entities/custom-fields/model/types';
import { System } from '@entities/system/model';
import { KeyValueOption, Nullable, ReducerMap } from '@shared';

import { Status, Ticket, TicketType } from '../types';

import {
  FetchActionsForTicketDetailSuccessAction,
  FetchCustomFieldsByTicketIdSuccessAction,
  FetchHistorySuccessAction,
  FetchIsSystemIntegratedWithJiraSuccessAction,
  FetchJiraUrlSuccessAction,
  FetchNextStatusesSuccessAction,
  FetchStatusesSuccessTicketDetailAction,
  FetchTicketCustomFieldsSuccessAction,
  SetIsErrorAction,
  SetIsSuccessAction,
  SetTicketDetailSuccessAction,
  SetTicketSuccessTypesActions,
  SetTicketSystemDetailSuccessAction,
  SetToastMessageAction,
  TicketActionsTypes,
  TicketDetail,
} from './actionTypes';
import { TicketHistory } from './types';

export interface TicketReducerState {
  ticket?: Ticket;
  ticketSystem?: System;
  loading?: boolean;
  history?: TicketHistory[];
  historyLoading?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  ticketActionsList?: Action[];
  isSystemIntegratedWithJira?: boolean;
  jiraUrl?: string;
  specialistCustomFields?: Nullable<CustomFieldToRequest[]>;
  clientCustomFields?: Nullable<CustomFieldToRequest[]>;
  nextStatuses?: Status[];
  ticketTypes?: TicketType[];
  statuses?: KeyValueOption[];
  toastMessage?: string;
  customFields?: CustomField[];
}

const initialState: TicketReducerState = {
  loading: false,
  historyLoading: false,
  history: [],
  isError: false,
  isSuccess: false,
  ticketActionsList: [],
  isSystemIntegratedWithJira: false,
  specialistCustomFields: [],
  clientCustomFields: [],
  nextStatuses: [],
  ticketTypes: [],
  statuses: [],
};

const ticketReducerMap: ReducerMap<TicketReducerState, TicketActionsTypes> = {
  [TicketDetail.TICKET_DETAIL_LOADING_SHOW]: (state) => ({
    ...state,
    loading: true,
  }),
  [TicketDetail.TICKET_DETAIL_LOADING_HIDE]: (state) => ({
    ...state,
    loading: false,
  }),
  [TicketDetail.TICKET_HISTORY_LOADING_SHOW]: (state) => ({
    ...state,
    historyLoading: true,
  }),
  [TicketDetail.TICKET_HISTORY_LOADING_HIDE]: (state) => ({
    ...state,
    historyLoading: false,
  }),
  [TicketDetail.SET_TICKET_DETAIL_SUCCESS]: (state, action) => {
    const { payload } = action as SetTicketDetailSuccessAction;
    return {
      ...state,
      ticket: payload,
    };
  },
  [TicketDetail.FETCH_HISTORY_SUCCESS]: (state, action) => {
    const { payload } = action as FetchHistorySuccessAction;
    return {
      ...state,
      history: payload,
    };
  },
  [TicketDetail.SET_IS_ERROR]: (state, action) => {
    const { payload } = action as SetIsErrorAction;
    return {
      ...state,
      isError: payload,
    };
  },
  [TicketDetail.SET_IS_SUCCESS]: (state, action) => {
    const { payload } = action as SetIsSuccessAction;
    return {
      ...state,
      isSuccess: payload,
    };
  },
  [TicketDetail.SET_TOAST_MESSAGE]: (state, action) => {
    const { payload } = action as SetToastMessageAction;
    return {
      ...state,
      toastMessage: payload,
    };
  },
  [TicketDetail.FETCH_ACTIONS_FOR_TICKET_DETAIL_SUCCESS]: (state, action) => {
    const { payload } = action as FetchActionsForTicketDetailSuccessAction;
    return {
      ...state,
      ticketActionsList: payload,
    };
  },
  [TicketDetail.FETCH_IS_SYSTEM_INTEGRATED_WITH_JIRA_SUCCESS]: (
    state,
    action
  ) => {
    const { payload } = action as FetchIsSystemIntegratedWithJiraSuccessAction;
    return {
      ...state,
      isSystemIntegratedWithJira: payload,
    };
  },
  [TicketDetail.FETCH_JIRA_URL_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraUrlSuccessAction;
    return {
      ...state,
      jiraUrl: payload,
    };
  },
  [TicketDetail.FETCH_CUSTOM_FIELDS_BY_TICKET_ID_SUCCESS]: (state, action) => {
    const { payload } = action as FetchCustomFieldsByTicketIdSuccessAction;
    const { clientCustomFields, specialistCustomFields } = payload;
    return {
      ...state,
      clientCustomFields,
      specialistCustomFields,
    };
  },
  [TicketDetail.FETCH_NEXT_STATUSES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchNextStatusesSuccessAction;
    return {
      ...state,
      nextStatuses: payload,
    };
  },
  [TicketDetail.RESET_TICKET_DETAIL_STATE]: () => ({
    ...initialState,
  }),
  [TicketDetail.SET_TICKET_SYSTEM_DETAIL_SUCCESS]: (state, action) => {
    const { payload } = action as SetTicketSystemDetailSuccessAction;
    return {
      ...state,
      ticketSystem: payload,
    };
  },
  [TicketDetail.FETCH_TICKET_TYPES_SUCCESS]: (state, action) => {
    const { payload } = action as SetTicketSuccessTypesActions;
    return {
      ...state,
      ticketTypes: payload,
    };
  },
  [TicketDetail.FETCH_STATUSES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchStatusesSuccessTicketDetailAction;
    return {
      ...state,
      statuses: payload,
    };
  },
  [TicketDetail.FETCH_TICKET_CUSTOM_FIELDS_BY_SYSTEM_ID_SUCCESS]: (
    state,
    action
  ) => {
    const { payload } = action as FetchTicketCustomFieldsSuccessAction;
    return {
      ...state,
      customFields: payload,
    };
  },
};

export const ticketSlice: Reducer<TicketReducerState, TicketActionsTypes> = (
  state = initialState,
  action
) => {
  const reducer = ticketReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
