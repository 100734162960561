import { createSelector } from 'reselect';

import { KeyValueOption, SelectOption } from '@shared';

import { ProfileState } from './types';

export const getNotificationsProfile = (state: ProfileState) =>
  state.notificationsProfile.notificationsProfile || [];
export const getLoading = (state: ProfileState): boolean | undefined =>
  state.notificationsProfile.loading;
export const getLoadingOptions = (state: ProfileState): boolean | undefined =>
  state.notificationsProfile.loadingOptions;
export const getFilterValues = (state: ProfileState) =>
  state.notificationsProfile.filter;
export const getPropsNotificationsProfile = (state: ProfileState) => ({
  pageNum: state.notificationsProfile.pagination?.pageNum,
  pageSize: state.notificationsProfile.pagination?.pageSize,
  loading: state.notificationsProfile.loading,
  sort: state.notificationsProfile.sort,
  totalElements: state.notificationsProfile.pagination?.totalElements,
});
export const getProfileTabsUnchecked = (state: ProfileState) =>
  state.notificationsProfile.tabsUnchecked || [];
export const getNotificationTabs = (state: ProfileState) =>
  state.notificationsProfile.tabs || [];
export const getNotificationSettingsLocale = (state: ProfileState) =>
  state.notificationsProfile.tabsSettings;
export const getNews = (state: ProfileState) =>
  state.notificationsProfile.news || [];
export const getLoadingNews = (state: ProfileState): boolean | undefined =>
  state.notificationsProfile.loadingNews;
export const getPropsNews = (state: ProfileState) => ({
  pageNum: state.notificationsProfile.paginationNews?.pageNum,
  pageSize: state.notificationsProfile.paginationNews?.pageSize,
  loading: state.notificationsProfile.loadingNews,
  sort: state.notificationsProfile.sortNews,
  totalElements: state.notificationsProfile.paginationNews?.totalElements,
});
export const getCurrentNews = (state: ProfileState) =>
  state.notificationsProfile.currentNews;
export const getPublicationDate = (state: ProfileState) =>
  state.notificationsProfile.currentNews?.publicationDate;
export const getLoadingCurrentNews = (
  state: ProfileState
): boolean | undefined => state.notificationsProfile.loadingCurrentNews;
export const getOrganizationsCurrentNews = (state: ProfileState) =>
  state.notificationsProfile.currentNews?.organizations || [];
export const getWorkGroupsCurrentNews = (state: ProfileState) =>
  state.notificationsProfile.currentNews?.workgroups;
export const getIsEditNewsMode = (state: ProfileState): boolean | undefined =>
  state.notificationsProfile.isEditNewsMode;
export const getIsNewsModal = (state: ProfileState): boolean | undefined =>
  state.notificationsProfile.isNewsModal;
export const getIsUncheckedNews = (state: ProfileState): boolean | undefined =>
  state.notificationsProfile.isUncheckedNews;
export const getOrganizations = (state: ProfileState) =>
  state.notificationsProfile.organizations;
export const getWorkGroups = (state: ProfileState) =>
  state.notificationsProfile.workGroups;
export const getHasUnpublishedNews = (state: ProfileState) =>
  state.notificationsProfile.hasUnpublishedNews;
export const getSystem = (state: ProfileState) =>
  state.notificationsProfile.system;
export const getStorage = (state: ProfileState) =>
  state.notificationsProfile.storage;

export const getOrganizationsOptions = createSelector<
  ProfileState,
  KeyValueOption[] | undefined,
  SelectOption[]
>([getOrganizations], (organizations): SelectOption[] => {
  if (organizations) {
    return organizations.map(
      (organization): SelectOption => ({
        title: organization.value,
        value: organization.key,
      })
    );
  }
  return [];
});

export const getWorkGroupsOptions = createSelector<
  ProfileState,
  KeyValueOption[] | undefined,
  SelectOption[]
>([getWorkGroups], (workGroups): SelectOption[] => {
  if (workGroups) {
    return workGroups.map(
      (workGroup): SelectOption => ({
        title: workGroup.value,
        value: workGroup.key,
      })
    );
  }
  return [];
});
