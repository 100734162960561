import { CustomField } from '@entities/custom-fields/model/types';
import { System } from '@entities/system/model';
import { ApiClient, getEnv, ResponseWithMeta } from '@shared';

import { ExportFields, TicketsRequestData } from '../../model/tickets-export';

import { ticketsExportEndpoints } from './endpoints';

export const ticketsExportRequests = {
  getExportFields: () =>
    ApiClient.get<ExportFields>({
      baseURL: getEnv('REACT_APP_EXPORT_EXCEL_URL'),
      url: ticketsExportEndpoints.getExportFields(),
    }),
  getTicketsCount: (data: TicketsRequestData) =>
    ApiClient.post<ExportFields>({
      baseURL: getEnv('REACT_APP_EXPORT_EXCEL_URL'),
      url: ticketsExportEndpoints.getTicketsCount(),
      data,
    }),
  getDownloadExcelUrl: (data: TicketsRequestData) =>
    ApiClient.post<ExportFields>({
      baseURL: getEnv('REACT_APP_EXPORT_EXCEL_URL'),
      url: ticketsExportEndpoints.getDownloadExcelData(),
      data,
    }),
  fetchSystems: (title?: string) =>
    ApiClient.post<ResponseWithMeta<System[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: ticketsExportEndpoints.getSystems(),
      params: {
        pageNum: 0,
        pageSize: 1000,
        sort: 'date_create_DESC'.toUpperCase(),
      },
      data: title ? { title } : {},
    }),
  fetchCustomFields: (systemId: string) =>
    ApiClient.post<ResponseWithMeta<CustomField[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: ticketsExportEndpoints.getCustomFields(),
      params: {
        pageNum: 0,
        pageSize: 1000,
        sort: 'created_DESC'.toUpperCase(),
      },
      data: { systemId },
    }),
};
