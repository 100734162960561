import { ContractIdSystemIdArgs } from '@entities/contract';
import { getUrl } from '@shared';

export const contractEndpoints = {
  getCurrentContract: (id: string) => getUrl(`contract/${id}`),
  getSystemsByContractId: (id: string) =>
    getUrl(`system/filter/contract/${id}`),
  createContract: () => getUrl('contract'),
  updateGroupsInSystemInContract: (
    contractId: string,
    systemId: string,
    slaId: string
  ) => getUrl(`contract/work-group/${contractId}/${systemId}/${slaId}`),
  deleteContract: (id: string) => getUrl(`contract/${id}`),
  getSupplementaryAgreements: (contractId: string) =>
    getUrl(`contract/supplementary-agreement-contract/${contractId}`),
  fetchAttachments: () => getUrl('attachment'),
  getUsersByContractId: (id: string) => getUrl(`user/contract/${id}`),
  getGroupsByContractIdBySystemId: (contractId: string, systemId: string) =>
    getUrl(`work-group/contract-system/${contractId}/${systemId}`),
  getEnvironments: () => getUrl('environment/filter'),
  getEnvironmentSettings: ({ contractId, systemId }: ContractIdSystemIdArgs) =>
    getUrl(`environment/settings/contracts/${contractId}/systems/${systemId}`),
  getWorkGroupsByContractId: (contractId: string) =>
    getUrl(`work-group/contract-groups/${contractId}`),
  getSystemsForAddingToContract: (contractId: string) =>
    getUrl(`system/organization-contract/${contractId}`),
  getSignContractUrl: (contractId: string) =>
    getUrl(`contract/status/${contractId}`),
  getSLAPatternsUrl: (title: string) =>
    getUrl(`sla-pattern/map/simple/${title}`),
  getExistPatternTitleUrl: (title: string) =>
    getUrl(`sla-pattern/exist/${title}`),
  getPatternByIdUrl: (id: string) => getUrl(`sla-pattern/${id}`),
  getCreatePatternUrl: () => getUrl(`sla-pattern/map`),
  getSLAByContractIdBySystemIdUrl: () => getUrl(`sla/map/by-contract-system`),
  getAttachSLAAndSystemToContractUrl: (contractId: string) =>
    getUrl(`contract/add-system-with-sla/${contractId}`),
  getUpdateSLAUrl: (id: string) => getUrl(`sla/${id}`),
  getNotificationsExpireContract: (contractId: string) =>
    getUrl(`contract/notifications/expire/${contractId}`),
  getContractPermission: (contractId: string) =>
    getUrl(`contract/permissions/${contractId}`),
  getSystemTransfer: (contractId: string) =>
    getUrl(`contract/transfer-systems/${contractId}`),
  getOrganizations: () => getUrl('organization/filter'),
  getSettingsTicketType: ({ contractId, systemId }: ContractIdSystemIdArgs) =>
    getUrl(`type/settings/contracts/${contractId}/systems/${systemId}`),
  getTicketTypes: (systemId: string) => getUrl(`type/system/${systemId}`),
  getProlongation: (contractId: string) =>
    getUrl(`contract/${contractId}/prolongation`),
  getProlongationStatus: (contractId: string) =>
    getUrl(`contract/${contractId}/prolongation/status`),
};
