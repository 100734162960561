import cn from 'clsx';
import { FC, useMemo } from 'react';

import { OrganizationType } from '@entities/organization';
import {
  Priority,
  PRIORITY_TITLE_MAP,
  SOURCE_TICKET_TYPE_MAP,
  SourceTicketType,
  TICKET_STATUS_TITLE_MAP,
  TicketPriorityEdit,
  TicketStatus,
  WorkGroupModal,
} from '@entities/ticket';
import {
  ApproveOrCancel,
  Button,
  CalendarIcon,
  ChangeIcon,
  CheckMarkIcon,
  Create,
  DesktopIcon,
  getFormatDateWithYear,
  InfoBlockConfig,
  LabelIcon,
  MainLayout,
  Typography,
  TypographyVariants,
  UserIcon,
  UsersGroupIcon,
} from '@shared';

import { TicketToast } from '../../ui';

import { useTicketLayout } from './lib';
import styles from './TicketLayout.module.scss';
import {
  EditStatusModalContent,
  StatusDropContent,
  TicketCloseModal,
  TicketLayoutDropContent,
} from './ui';

const PRIORITY_STYLES_MAP = {
  [Priority.NONE]: styles.ticketLayout__headerPriority_none,
  [Priority.LOW]: styles.ticketLayout__headerPriority_low,
  [Priority.MIDDLE]: styles.ticketLayout__headerPriority_middle,
  [Priority.HIGH]: styles.ticketLayout__headerPriority_high,
  [Priority.VERY_HIGH]: styles.ticketLayout__headerPriority_veryHigh,
};

const TICKET_STATUSES_STYLES_MAP = {
  [TicketStatus.NEW]: styles.ticketLayout__ticketInfoItem_new,
  [TicketStatus.WORK]: styles.ticketLayout__ticketInfoItem_inProcess,
  [TicketStatus.CLOSE]: styles.ticketLayout__ticketInfoItem_closed,
  [TicketStatus.PENDING_CLOSURE]:
    styles.ticketLayout__ticketInfoItem_waitingToClose,
  [TicketStatus.WAITING_INFO]: styles.ticketLayout__ticketInfoItem_waitingInfo,
  [TicketStatus.REOPEN]: styles.ticketLayout__ticketInfoItem_reopen,
};

const getIcon = (status?: TicketStatus) => {
  if (status === TicketStatus.CLOSE) {
    return <CheckMarkIcon className={styles.ticketLayout__ticketStatusIcon} />;
  }
  if (status === TicketStatus.REOPEN) {
    return <ChangeIcon className={styles.ticketLayout__ticketStatusIcon} />;
  }
  return <LabelIcon className={styles.ticketLayout__ticketStatusIcon} />;
};

export const TicketLayout: FC = ({ children }) => {
  const { state, methods } = useTicketLayout();

  const {
    ticket,
    isClient,
    ticketStatus,
    isTickedClosed,
    isPopoverOpen,
    isShowModal,
    isJiraButtonDisabled,
    ticketPriority,
    isMobileAll,
    isAccessToEditPriority,
    isModal,
    showDeleteModal,
    isEditStatusModalOpen,
    showDrop,
    nextStatuses,
    nextStatus,
    errors,
    disabledStatusChange,
    messageTextAreaOptions,
    isAccessToChangeStatus,
    isShowCloseModal,
    loading,
    isTicketDeleted,
    isReopenTicket,
    isAccessToCreateTicket,
  } = state;

  const {
    toggleShowModal,
    toggleIsPopoverOpen,
    handleShowModalDelete,
    toggleModal,
    handleDelete,
    toggleDeleteModal,
    toggleIsEditStatusModalOpen,
    onChangeNextStatusHandler,
    formSubmitHandler,
    toggleCloseModal,
    toggleReopenStatusModalOpen,
  } = methods;

  const workGroups =
    ticket?.workGroups.filter((item) =>
      !isClient
        ? item.organizationType === OrganizationType.SERVICE
        : item.organizationType === OrganizationType.CUSTOMER
    ) || [];

  const isSourceJira = ticket?.source === SourceTicketType.JIRA;
  const isSourceMail = ticket?.source === SourceTicketType.MAIL;
  const isSourceTelegram = ticket?.source === SourceTicketType.TELEGRAM;
  const isSourceExternal =
    ticket?.source === SourceTicketType.EXTERNAL_INTEGRATION;

  const isAllowedSource =
    isSourceJira || isSourceMail || isSourceTelegram || isSourceExternal;

  const workGroupsButton =
    workGroups.length > 2 ? (
      <div className={styles.ticketLayout__info}>
        <span>{`${workGroups[0]?.title}; ${workGroups[1]?.title}`}</span>
        <button
          onClick={toggleShowModal}
          className={styles.ticketLayout__infoGroup}
        >{`+${workGroups.length - 2}`}</button>
      </div>
    ) : (
      <div>
        {workGroups.map((workGroup) => workGroup.title).join('; ') || '-'}
      </div>
    );

  const infoBlocksConfig = useMemo(
    (): InfoBlockConfig[] => [
      {
        ...(isReopenTicket && {
          customButton: (
            <Button
              appearance="outline"
              onClick={toggleReopenStatusModalOpen}
              type="button"
            >
              Переоткрыть
            </Button>
          ),
        }),
        title: 'Статус',
        info: ticketStatus
          ? TICKET_STATUS_TITLE_MAP[ticketStatus]
          : ticket?.customStatus?.name,
        icon: getIcon(ticketStatus),
        classNameIconWrapper: ticketStatus
          ? TICKET_STATUSES_STYLES_MAP[ticketStatus]
          : '',
        popoverContent:
          nextStatuses?.length && !isTicketDeleted ? (
            <StatusDropContent
              onChangeNextStatusHandler={onChangeNextStatusHandler}
            />
          ) : undefined,
        isPopoverOpen,
        toggleIsPopoverOpen:
          nextStatuses?.length && isAccessToChangeStatus
            ? toggleIsPopoverOpen
            : undefined,
        backgroundColor: !ticketStatus ? ticket?.customStatus?.color : '',
      },
      {
        title: 'Создан',
        info: ticket?.dateCreate
          ? getFormatDateWithYear(ticket.dateCreate)
          : '-',
        icon: <CalendarIcon className={styles.ticketLayout__ticketInfoIcon} />,
      },
      {
        title: 'Исполнитель',
        info: ticket?.specialistId?.value || '-',
        icon: (
          <UserIcon
            className={cn(
              styles.ticketLayout__ticketInfoIcon,
              styles.ticketLayout__userIcon
            )}
          />
        ),
      },
      {
        title: 'Дата исполнения',
        info: ticket?.dateSolve ? getFormatDateWithYear(ticket.dateSolve) : '-',
        icon: <CalendarIcon className={styles.ticketLayout__ticketInfoIcon} />,
      },
      {
        title: 'Группа исполнителей',
        info: workGroupsButton,
        icon: (
          <UsersGroupIcon className={styles.ticketLayout__ticketInfoIcon} />
        ),
      },
      ...(isAllowedSource
        ? [
            {
              title: 'Источник',
              info: ticket?.source
                ? SOURCE_TICKET_TYPE_MAP[ticket?.source]
                : '-',
              icon: (
                <DesktopIcon className={styles.ticketLayout__ticketInfoIcon} />
              ),
            },
          ]
        : []),
    ],
    [
      isReopenTicket,
      ticketStatus,
      isTickedClosed,
      ticket?.dateCreate,
      ticket?.specialistId?.value,
      ticket?.dateSolve,
      ticket?.source,
      workGroupsButton,
      isPopoverOpen,
      toggleIsPopoverOpen,
      nextStatuses?.length,
      onChangeNextStatusHandler,
      isAccessToChangeStatus,
      isAllowedSource,
    ]
  );

  const workGroupsModal = isShowModal && (
    <WorkGroupModal isModal={isShowModal} toggleModal={toggleShowModal} />
  );

  const dropContent =
    showDrop && !isClient ? (
      <TicketLayoutDropContent
        isJiraButtonDisabled={isJiraButtonDisabled}
        handleShowModalDelete={handleShowModalDelete}
        handleShowModalClose={toggleCloseModal}
      />
    ) : undefined;
  // TODO заменить на Tag из нового ui kit
  const labelDeleted = isTicketDeleted ? (
    <div className={styles.ticketLayout__label}>
      <Typography variant={TypographyVariants.o}>Удален</Typography>
    </div>
  ) : undefined;

  return (
    <>
      <MainLayout
        headerType="withExtraInfo"
        title={`Тикет #${ticket?.number}`}
        subTitle={PRIORITY_TITLE_MAP[ticketPriority]}
        withGoBack={isMobileAll}
        infoBlocksConfig={infoBlocksConfig}
        loaderProps={{
          withLoader: true,
          isLoading: loading,
        }}
        onClickSubTitle={isAccessToEditPriority ? toggleModal : undefined}
        classNameHeaderTitleBlock={
          PRIORITY_STYLES_MAP[ticket?.priority || Priority.NONE]
        }
        dropContent={dropContent}
        dropDisabled={isTicketDeleted}
        labelComponent={labelDeleted}
        accessToCreateTicket={isAccessToCreateTicket}
      >
        {children}
      </MainLayout>
      {workGroupsModal}
      <TicketCloseModal
        id={ticket?.id}
        isModal={isShowCloseModal}
        toggleModal={toggleCloseModal}
      />
      <TicketPriorityEdit isModal={isModal} toggleModal={toggleModal} />
      <ApproveOrCancel
        onApprove={handleDelete}
        isModal={showDeleteModal}
        toggleModal={toggleDeleteModal}
        approveTitle="Удалить"
        approveBtnProps={{
          appearance: 'outline',
          className: styles.ticketLayout__iconDelete,
        }}
        cancelTitle="Отмена"
        text={`Вы уверены, что хотите удалить тикет ${ticket?.number}?`}
      />
      <Create
        onSubmit={formSubmitHandler}
        toggleModal={toggleIsEditStatusModalOpen}
        isModal={isEditStatusModalOpen}
        title="Сменить статус"
        subModalText="смену статуса"
        createTitle="Отправить"
        disabledSubmit={disabledStatusChange}
      >
        <EditStatusModalContent
          currentStatus={ticket?.customStatus}
          nextStatus={nextStatus}
          error={errors.message}
          errorMessage={errors.message?.message}
          additionalFormData={messageTextAreaOptions}
        />
      </Create>
      <TicketToast />
    </>
  );
};
