import {
  JiraIntegrationEditData,
  JiraPriority,
  JiraStatus,
} from '@entities/jira-integrations';
import { getValueFromValueType } from '@shared';

import { getIssueStatusUpdateDtos } from './getIssueStatusUpdateDtos';
import { getIssueTicketPriorityDtos } from './getIssueTicketPriorityDtos';

type Args = {
  data: JiraIntegrationEditData;
  statuses: JiraStatus[];
  priorities: JiraPriority[];
  systemId?: string;
};

export const getPreparedDataForAddNewTicketType = ({
  data,
  statuses,
  priorities,
}: Args) => {
  const { jiraTicketType } = data;

  const jiraTicketTypeId =
    getValueFromValueType(jiraTicketType)?.toString() ?? '';
  const issueStatusCreateDtos = getIssueStatusUpdateDtos(statuses);
  const issuePriorityCreateDtos = getIssueTicketPriorityDtos(priorities);

  return {
    issueStatusCreateDtos,
    issuePriorityCreateDtos,
    jiraTicketTypeId,
  };
};
