import { FC } from 'react';
import { Controller } from 'react-hook-form';

import { useCopyStatusModel } from '@features/statuses-builder/lib';
import {
  Button,
  ButtonGroup,
  getIsValidateFieldValue,
  getIsValidateMultiArray,
  Select,
  SelectOption,
  Typography,
  TypographyVariants,
} from '@shared';

import { StatusSideMenu } from '../StatusSideMenu';

import styles from './StatusCopyModel.module.scss';

export interface StatusCopyModelProps {
  systemsOptions: SelectOption[];
  ticketTypesFrom: SelectOption[];
  ticketTypesTo: SelectOption[];
  onClose(): void;
}

export const StatusCopyModel: FC<StatusCopyModelProps> = ({
  onClose,
  systemsOptions,
  ticketTypesFrom,
  ticketTypesTo,
}) => {
  const { methods, state } = useCopyStatusModel({
    onClose,
  });

  const { control, formSubmitHandler, onCloseModalHandler, resetField } =
    methods;

  const {
    isSubmitDisabled,
    isDisabledTypeId,
    isDisabledTypeIds,
    isDisabledSystemIds,
  } = state;

  const submitButtons = (
    <ButtonGroup>
      <Button
        disabled={isSubmitDisabled}
        className={styles.statusCopyModel__button}
      >
        Скопировать
      </Button>
      <Button
        appearance="outline"
        type="button"
        className={styles.statusCopyModel__button}
        onClick={onCloseModalHandler}
      >
        Отмена
      </Button>
    </ButtonGroup>
  );

  return (
    <StatusSideMenu
      title="Скопировать цепочку статусов"
      onCloseModal={onCloseModalHandler}
    >
      <form
        className={styles.statusCopyModel__form}
        onSubmit={formSubmitHandler}
      >
        <Typography variant={TypographyVariants.h5}>
          Откуда скопировать
        </Typography>
        <Controller
          name="systemId"
          control={control}
          rules={getIsValidateFieldValue}
          render={({ field }) => (
            <Select
              label="Система"
              mobileModalTitle="Систему"
              options={systemsOptions}
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                resetField('typeId');
                resetField('systemIds');
                resetField('typeIds');
              }}
            />
          )}
        />
        <Controller
          name="typeId"
          control={control}
          rules={getIsValidateFieldValue}
          render={({ field }) => (
            <Select
              label="Тип тикета"
              mobileModalTitle="Тип тикета"
              options={ticketTypesFrom}
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
              }}
              disabled={isDisabledTypeId}
            />
          )}
        />
        <Typography variant={TypographyVariants.h5}>
          Куда скопировать
        </Typography>
        <Controller
          name="systemIds"
          control={control}
          rules={getIsValidateMultiArray}
          render={({ field }) => (
            <Select
              label="Системы"
              mobileModalTitle="Системы"
              options={systemsOptions}
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                resetField('typeIds');
              }}
              isMulti
              disabled={isDisabledSystemIds}
            />
          )}
        />
        <Controller
          name="typeIds"
          control={control}
          rules={getIsValidateMultiArray}
          render={({ field }) => (
            <Select
              label="Типы тикета"
              mobileModalTitle="Типы тикета"
              options={ticketTypesTo}
              value={field.value}
              onChange={field.onChange}
              isMulti
              disabled={isDisabledTypeIds}
            />
          )}
        />
        <div className={styles.statusCopyModel__footer}>{submitButtons}</div>
      </form>
    </StatusSideMenu>
  );
};
