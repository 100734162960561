import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { Organization } from '@entities/organization/model/types';
import { getFilterSystemToRequest } from '@entities/system/lib/utils';
import { System } from '@entities/system/model';
import {
  createError,
  createSuccessAlert,
  ResponseWithMeta,
  setAlert,
} from '@shared';

import { requests } from '../api';

import {
  fetchOrganizationsSuccess,
  fetchSystemsSuccess,
  fetchTicketTypesForSystemsSuccess,
  fetchTicketTypesForSystemSuccess,
  fetchWorkflowSuccess,
  hideSystemsLoading,
  showSystemsLoading,
} from './actions';
import {
  CopyStatusModelAction,
  FetchTicketTypesForSystemRequestAction,
  FetchTicketTypesForSystemsRequestAction,
  FetchWorkflowRequestAction,
  SaveWorkflowAction,
  StatusesBuilder,
} from './actionTypes';
import { getPropsSystems, getSystemsFilter } from './selectors';
import { SystemsTicketTypes, Workflow } from './types';

function* systemsFetch() {
  try {
    const {
      pageNum,
      pageSize,
      sortSystems,
    }: ReturnType<typeof getPropsSystems> = yield select(getPropsSystems);
    const filter: ReturnType<typeof getSystemsFilter> = yield select(
      getSystemsFilter
    );

    yield put(showSystemsLoading());
    const systems: ResponseWithMeta<System[]> = yield call(
      requests.fetchSystems,
      pageNum,
      pageSize,
      sortSystems,
      getFilterSystemToRequest(filter)
    );
    yield put(fetchSystemsSuccess(systems));
    yield put(hideSystemsLoading());
  } catch (e) {
    createError(e);
    yield put(hideSystemsLoading());
  }
}

function* organizationsFetch() {
  try {
    const organizations: ResponseWithMeta<Organization[]> = yield call(
      requests.fetchOrganizations
    );
    yield put(fetchOrganizationsSuccess(organizations.content));
  } catch (e) {
    createError(e);
  }
}

function* workflowFetch({ payload }: FetchWorkflowRequestAction) {
  try {
    const workflow: Workflow = yield call(requests.fetchWorkflow, payload);
    yield put(fetchWorkflowSuccess(workflow));
  } catch (e) {
    yield put(fetchWorkflowSuccess(undefined));
    createError(e);
  }
}

function* ticketTypesFetch({
  payload,
}: FetchTicketTypesForSystemRequestAction) {
  try {
    const { typeList }: System = yield call(requests.fetchTicketTypes, payload);
    if (typeList) {
      yield put(fetchTicketTypesForSystemSuccess(typeList));
    }
  } catch (e) {
    createError(e);
  }
}

function* workflowSave({ payload }: SaveWorkflowAction) {
  try {
    const { systemTitle, ...saveParams } = payload;
    const { systemId, typeId } = saveParams;
    yield call(requests.saveWorkflow, saveParams);
    yield put(
      setAlert(
        createSuccessAlert(
          `Внесены изменения в настройку статусов системы "${systemTitle}"`
        )
      )
    );
    yield call(workflowFetch, {
      type: StatusesBuilder.FETCH_WORKFLOW_REQUEST,
      payload: { systemId, typeId },
    });
  } catch (e) {
    createError(e);
  }
}

function* copyStatusModel({ payload }: CopyStatusModelAction) {
  try {
    yield call(requests.copyStatusModel, payload);
    yield call(systemsFetch);
  } catch (e) {
    createError(e);
  }
}

function* ticketTypesFetchBySystems({
  payload,
}: FetchTicketTypesForSystemsRequestAction) {
  try {
    const data: SystemsTicketTypes = yield call(
      requests.fetchTicketTypesBySystems,
      payload
    );
    yield put(fetchTicketTypesForSystemsSuccess(data));
  } catch (e) {
    createError(e);
  }
}

export function* statusesBuilderSaga(): Generator<StrictEffect> {
  yield takeEvery(StatusesBuilder.FETCH_BUILDER_SYSTEMS_REQUEST, systemsFetch);
  yield takeEvery(
    StatusesBuilder.FETCH_BUILDER_ORGANIZATIONS_REQUEST,
    organizationsFetch
  );
  yield takeEvery(StatusesBuilder.FETCH_WORKFLOW_REQUEST, workflowFetch);
  yield takeEvery(
    StatusesBuilder.FETCH_TICKET_TYPES_FOR_SYSTEM_REQUEST,
    ticketTypesFetch
  );
  yield takeEvery(StatusesBuilder.SAVE_WORKFLOW, workflowSave);
  yield takeEvery(StatusesBuilder.COPY_MODEL, copyStatusModel);
  yield takeEvery(
    StatusesBuilder.FETCH_TICKET_TYPES_FOR_SYSTEMS_REQUEST,
    ticketTypesFetchBySystems
  );
}
