import { useSelector } from 'react-redux';

import {
  EntityTypes,
  getActiveNotification,
  getActiveNotificationId,
} from '@entities/notifications';
import { getStorage, getSystem } from '@entities/profile';
import { CurrentTicket, getCurrentTicket } from '@entities/ticket';
import {
  Card,
  getIsDesktop,
  getIsDesktopSmall,
  ListRoundIcon,
  Typography,
  TypographyVariants,
} from '@shared';

import { NotificationStorageInformation } from '../NotificationStorageInformation';
import { NotificationSystemInformation } from '../NotificationSystemInformation';

import styles from './NotificationInformation.module.scss';

export const NotificationInformation = () => {
  const isDesktop = useSelector(getIsDesktop);
  const isDesktopSmall = useSelector(getIsDesktopSmall);
  const ticket = useSelector(getCurrentTicket);
  const activeNotificationId = useSelector(getActiveNotificationId);
  const activeNotification = useSelector(getActiveNotification);
  const system = useSelector(getSystem);
  const storage = useSelector(getStorage);

  const currentTicket =
    isDesktop || isDesktopSmall ? (
      <CurrentTicket
        className={styles.notificationInformation__ticket}
        ticket={activeNotificationId ? ticket : undefined}
      />
    ) : undefined;

  const componentMap: Record<EntityTypes, JSX.Element | undefined> = {
    [EntityTypes.TICKET]: currentTicket,
    [EntityTypes.SYSTEM]: (
      <NotificationSystemInformation
        activeNotification={activeNotification}
        system={system}
      />
    ),
    [EntityTypes.STORAGE]: <NotificationStorageInformation storage={storage} />,
    // TODO feature/tsp-2107 прикрутить когда добавят EntityTypes.CONTRACT
    // [EntityTypes.CONTRACT]: (
    //   <NotificationContractInformation contract={contract} />
    // ),
  };

  if (!activeNotification?.entityType) {
    return (
      <Card className={styles.notificationInformation_empty}>
        <ListRoundIcon className={styles.notificationInformation__emptyIcon} />
        <Typography
          variant={TypographyVariants.b2}
          className={styles.notificationInformation_empty__message}
        >
          Нет данных
        </Typography>
      </Card>
    );
  }

  return <>{componentMap[activeNotification.entityType]}</>;
};
