import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import {
  fetchNewsRequest,
  getHasUnpublishedNews,
  getNews,
  getPropsNews,
  resetNewsState,
} from '@entities/profile';
import { RouterHref, useInfiniteScroll } from '@shared';

import { PUBLISHED_NEWS_PATH, UNPUBLISHED_NEWS_PATH } from '../../config';
import { ProfileNewsLayout } from '../../layouts';
import { NewsList } from '../NewsList';

export const ProfileNews = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { push } = useHistory();

  const news = useSelector(getNews);
  const {
    totalElements,
    pageNum = 0,
    pageSize,
    loading,
  } = useSelector(getPropsNews);
  const isAccessToEditNews = useSelector(
    getIsAccessedAction(ActionList.EditNews)
  );
  const hasUnpublishedNews = useSelector(getHasUnpublishedNews);

  const isAccessUnpublishedNews = isAccessToEditNews && hasUnpublishedNews;
  const isEmptyUnpublishedNews =
    pathname === UNPUBLISHED_NEWS_PATH && !isAccessUnpublishedNews;

  const published = pathname === PUBLISHED_NEWS_PATH;

  const { hasMore, totalPage } = useInfiniteScroll({
    pageNum,
    pageSize,
    totalElements,
  });

  const onLoadMore = (page: number) => {
    dispatch(
      fetchNewsRequest({ updateType: 'join', pageNum: page, published })
    );
  };

  useEffect(() => {
    if (isEmptyUnpublishedNews) {
      push(PUBLISHED_NEWS_PATH);
    }
  }, [push, isEmptyUnpublishedNews]);

  useEffect(() => {
    if (pathname === RouterHref.ProfileNews) {
      push(PUBLISHED_NEWS_PATH);
    }
    return () => {
      dispatch(resetNewsState());
    };
  }, [push, pathname]);

  return (
    <ProfileNewsLayout>
      <Switch>
        <Route
          path="/profile/news/:tabType"
          render={() => (
            <NewsList
              news={news}
              onLoadMore={onLoadMore}
              hasMore={!loading && hasMore}
              loading={loading}
              totalPages={totalPage}
              published={published}
            />
          )}
        />
      </Switch>
    </ProfileNewsLayout>
  );
};
