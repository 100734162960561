import cn from 'clsx';
import { DragEvent, InputHTMLAttributes } from 'react';

import { DragIcon } from '@shared/assets';
import { Checkbox } from '@shared/ui/Checkbox';

import { SettingFieldType as Field } from '../../model';

import styles from './SettingField.module.scss';

export interface SettingFieldProps<T>
  extends InputHTMLAttributes<HTMLInputElement> {
  field: Field<T>;
  dragStartHandler: (event: DragEvent<HTMLDivElement>, card: Field<T>) => void;
  dropHandler: (event: DragEvent<HTMLDivElement>, field: Field<T>) => void;
  disabledField?: string;
}

export const SettingField = <T,>(props: SettingFieldProps<T>) => {
  const { field, dragStartHandler, dropHandler, onChange, disabledField } =
    props;

  const isDisabled = field.name === disabledField;

  return (
    <div
      className={cn(styles.settingField, {
        [styles.settingField_disabled]: isDisabled,
      })}
      draggable
      onDragStart={(event) => dragStartHandler(event, field)}
      onDragOver={(event) => event.preventDefault()}
      onDrop={(event) => dropHandler(event, field)}
    >
      {!isDisabled && <DragIcon className={styles.settingField__drag} />}
      <Checkbox
        onChange={onChange}
        label={field.name}
        checked={!field.hidden}
        disabled={isDisabled}
      />
    </div>
  );
};
