import { FC } from 'react';

import { SetCurrentFilterTitleRequest } from '@entities/ticket';
import { TabItem } from '@shared';

import { TicketsPopover } from '../TicketsPopover';

import styles from './TicketsPopovers.module.scss';

interface TicketsPopoversProps {
  items: TabItem[];
  onChangePopover?: (title: string) => void;
  handleSubmitPopover?: (data: SetCurrentFilterTitleRequest) => void;
}

export const TicketsPopovers: FC<TicketsPopoversProps> = (props) => {
  const { items, onChangePopover, handleSubmitPopover } = props;

  const itemsEls = items
    .slice(1)
    .map(({ title, value }) => (
      <TicketsPopover
        key={value}
        title={title}
        value={value}
        onChangePopover={onChangePopover}
        handleSubmitPopover={handleSubmitPopover}
        className={styles.popovers__item}
      />
    ));

  return <div className={styles.popovers}>{itemsEls}</div>;
};
