import { setDefaultOptions } from 'date-fns';
import { ru } from 'date-fns/locale';
import { ComponentType, ReactPropTypes, useEffect } from 'react';

export const withLocale =
  (Component: ComponentType<ReactPropTypes>) => (props: ReactPropTypes) => {
    useEffect(() => {
      setDefaultOptions({ locale: ru });
    }, []);

    return <Component {...props} />;
  };
