import { StorageCustomField } from '@entities/storage';

import { STORAGE_TABLE_FIELDS } from '../../config';

export const getColumns = (customFields?: StorageCustomField[]) => {
  const column =
    customFields?.map(({ id, title }) => ({
      title,
      sortField: '',
      fieldName: id || '',
    })) ?? [];

  return [...STORAGE_TABLE_FIELDS, ...column];
};
