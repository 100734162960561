import cn from 'clsx';
import { FC } from 'react';

import { SLA_TYPE_TITLES_MAP, SLADuration } from '@entities/sla';
import { TicketSLAType } from '@entities/ticket';
import {
  Clock12,
  getFormatDisplayedTime,
  InfoBlock,
  Tag,
  Typography,
  TypographyVariants,
} from '@shared';

import styles from './EmployeesSlaTimer.module.scss';

const slaTypeStylesMap = {
  [TicketSLAType.DECISION]: styles.employeesSlaTimer__tag_decision,
  [TicketSLAType.REACTION]: styles.employeesSlaTimer__tag_reaction,
};

interface EmployeesSLATimerProps {
  type: TicketSLAType;
  avgTime: SLADuration;
  avgExpireTime: SLADuration;
  className?: string;
}

export const EmployeesSLATimer: FC<EmployeesSLATimerProps> = ({
  type,
  className,
  avgTime,
  avgExpireTime,
}) => {
  const isDecision = type === TicketSLAType.DECISION;

  return (
    <div className={cn(styles.employeesSlaTimer, className)}>
      <div className={styles.employeesSlaTimer__leftBlock}>
        <InfoBlock
          icon={
            <Clock12
              className={cn(styles.employeesSlaTimer__clock, {
                [styles.employeesSlaTimer__clock_decision]: isDecision,
              })}
            />
          }
          classNameIconWrapper={cn(styles.employeesSlaTimer__iconWrapper, {
            [styles.employeesSlaTimer__iconWrapper_decision]: isDecision,
          })}
        />
        <Tag
          text={SLA_TYPE_TITLES_MAP[type || TicketSLAType.REACTION]}
          className={cn(
            styles.employeesSlaTimer__tag,
            slaTypeStylesMap[type || TicketSLAType.REACTION]
          )}
        />
      </div>
      <div className={styles.employeesSlaTimer__rightBlock}>
        <Typography
          variant={TypographyVariants.b3}
          className={styles.employeesSlaTimer__title}
        >
          Выполнено
        </Typography>
        <div className={styles.employeesSlaTimer__timeWrapper}>
          <Typography variant={TypographyVariants.h1}>
            {getFormatDisplayedTime(avgTime.hours)}
          </Typography>
          <Typography
            variant={TypographyVariants.h1}
            className={styles.employeesSlaTimer__separator}
          >
            {' '}
            :{' '}
          </Typography>
          <Typography variant={TypographyVariants.h1}>
            {getFormatDisplayedTime(avgTime.minutes)}
          </Typography>
          <Typography
            variant={TypographyVariants.h1}
            className={styles.employeesSlaTimer__separator}
          >
            {' '}
            :{' '}
          </Typography>
          <Typography variant={TypographyVariants.h1}>
            {getFormatDisplayedTime(avgTime.seconds)}
          </Typography>
        </div>
        <hr className={styles.employeesSlaTimer__hr} />
        <Typography
          variant={TypographyVariants.b3}
          className={styles.employeesSlaTimer__title}
        >
          Просрочено
        </Typography>
        <div
          className={cn(
            styles.employeesSlaTimer__timeWrapper,
            styles.employeesSlaTimer__timeWrapper_expired
          )}
        >
          <Typography variant={TypographyVariants.h3}>
            {getFormatDisplayedTime(avgExpireTime.hours)}
          </Typography>
          <Typography
            variant={TypographyVariants.h3}
            className={cn(
              styles.employeesSlaTimer__separator,
              styles.employeesSlaTimer__separator_expired
            )}
          >
            {' '}
            :{' '}
          </Typography>
          <Typography variant={TypographyVariants.h3}>
            {getFormatDisplayedTime(avgExpireTime.minutes)}
          </Typography>
          <Typography
            variant={TypographyVariants.h3}
            className={cn(
              styles.employeesSlaTimer__separator,
              styles.employeesSlaTimer__separator_expired
            )}
          >
            {' '}
            :{' '}
          </Typography>
          <Typography variant={TypographyVariants.h3}>
            {getFormatDisplayedTime(avgExpireTime.seconds)}
          </Typography>
        </div>
      </div>
    </div>
  );
};
