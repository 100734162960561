import { JiraStatus, Status } from '@entities/jira-integrations';

import { getJiraStatus } from './getJiraStatus';

type Args = {
  statusMapOptions: Status[];
  jiraStatuses: JiraStatus[];
};

export const getStatusesByOrder = ({
  statusMapOptions,
  jiraStatuses,
}: Args) => {
  const statusesByOrder = statusMapOptions?.map((status) => ({
    ...jiraStatuses.find((item) =>
      item.ticketStatusIds?.find((element) => element.id === status.id)
    ),
  }));

  return statusMapOptions?.map((status) => ({
    ...getJiraStatus(statusesByOrder, status),
    ticketStatusIds: [status],
  }));
};
