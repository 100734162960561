import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  fetchFiltersFieldsRequest,
  fetchRulesRequest,
  resetRulesState,
} from '@entities/rules';
import { TablesLayout } from '@shared';

import { RulesTableContainer } from '../RulesTableContainer';

import styles from './Rules.module.scss';

const MainTable = () => <RulesTableContainer />;

const Rules = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRulesRequest());
    dispatch(fetchFiltersFieldsRequest());
    return () => {
      dispatch(resetRulesState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[MainTable]}
      classNameRoot={styles.rules}
      classNameSubTables={styles.rules__subTable}
      classNameMainTables={styles.rules__mainTable}
      SubTable={[]}
    />
  );
};

export default Rules;
