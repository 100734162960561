import { Organization } from '@entities/organization';
import { ResponseWithMeta, SelectOption } from '@shared';

import {
  Article,
  CategoryArticle,
  FetchArticlesParams,
  KBArticleTableFilterToRequest,
  KBOrganizationsFilter,
} from '../types';

import {
  ArticlesMain,
  FetchArticlesMainRequestAction,
  SetArticlesMainFilterAction,
} from './actionTypes';

export const setArticlesMainFilter = (
  data: KBArticleTableFilterToRequest
): SetArticlesMainFilterAction => ({
  type: ArticlesMain.SET_FILTER_ARTICLES_MAIN,
  payload: data,
});

export const fetchArticlesMainRequest = (
  data: FetchArticlesParams
): FetchArticlesMainRequestAction => ({
  type: ArticlesMain.FETCH_REQUEST_ARTICLES_MAIN,
  payload: data,
});

export const fetchArticlesMainSuccess = (
  data: ResponseWithMeta<Article[]>
) => ({
  type: ArticlesMain.FETCH_SUCCESS_ARTICLES_MAIN,
  payload: data,
});

export const fetchJoinSuccessArticlesMain = (
  data: ResponseWithMeta<Article[]>
) => ({
  type: ArticlesMain.FETCH_ARTICLES_MAIN_JOIN_SUCCESS,
  payload: data,
});

export const resetArticlesStateArticlesMain = () => ({
  type: ArticlesMain.RESET_ARTICLES_STATE_ARTICLES_MAIN,
});

export const loadingShowArticlesMain = () => ({
  type: ArticlesMain.LOADING_SHOW_ARTICLES_MAIN,
});

export const loadingHideArticlesMain = () => ({
  type: ArticlesMain.LOADING_HIDE_ARTICLES_MAIN,
});

export const fetchDefaultArticlesMainRequest = () => ({
  type: ArticlesMain.FETCH_DEFAULT_ARTICLES_MAIN_REQUEST,
});

export const fetchDefaultArticlesMainSuccess = (data: Article[]) => ({
  type: ArticlesMain.FETCH_DEFAULT_ARTICLES_MAIN_SUCCESS,
  payload: data,
});

export const fetchOrganizationsArticlesMainRequest = (
  filter: KBOrganizationsFilter
) => ({
  type: ArticlesMain.FETCH_ORGANIZATIONS_REQUEST_ARTICLES_MAIN,
  payload: filter,
});

export const fetchOrganizationsArticlesMainSuccess = (
  data: Organization[]
) => ({
  type: ArticlesMain.FETCH_ORGANIZATIONS_SUCCESS_ARTICLES_MAIN,
  payload: data,
});

export const setArticlesMainPage = (page: number) => ({
  type: ArticlesMain.SET_ARTICLES_MAIN_PAGE,
  payload: page,
});

export const organizationsLoadingShowArticlesMain = () => ({
  type: ArticlesMain.ORGANIZATIONS_LOADING_SHOW_ARTICLES_MAIN,
});

export const organizationsLoadingHideArticlesMain = () => ({
  type: ArticlesMain.ORGANIZATIONS_LOADING_HIDE_ARTICLES_MAIN,
});

export const fetchCategoriesRequestArticlesMain = (
  organizationIds?: string[]
) => ({
  type: ArticlesMain.FETCH_CATEGORIES_REQUEST_ARTICLES_MAIN,
  payload: organizationIds,
});

export const fetchCategoriesSuccessArticlesMain = (
  categories: CategoryArticle[]
) => ({
  type: ArticlesMain.FETCH_CATEGORIES_SUCCESS_ARTICLES_MAIN,
  payload: categories,
});

export const categoriesLoadingShowArticlesMain = () => ({
  type: ArticlesMain.CATEGORIES_LOADING_SHOW_ARTICLES_MAIN,
});

export const categoriesLoadingHideArticlesMain = () => ({
  type: ArticlesMain.CATEGORIES_LOADING_HIDE_ARTICLES_MAIN,
});

export const resetArticlesMainState = () => ({
  type: ArticlesMain.RESET_ARTICLES_MAIN_STATE,
});

export const createCategoryRequestArticlesMain = (
  data: Partial<CategoryArticle>
) => ({
  type: ArticlesMain.CREATE_CATEGORY_REQUEST_ARTICLES_MAIN,
  payload: data,
});

export const editCategoryRequestArticlesMain = (data: CategoryArticle) => ({
  type: ArticlesMain.EDIT_CATEGORY_REQUEST_ARTICLES_MAIN,
  payload: data,
});

export const deleteCategoryRequestArticlesMain = (id: string) => ({
  type: ArticlesMain.DELETE_CATEGORY_REQUEST_ARTICLES_MAIN,
  payload: id,
});

export const setSelectedOrganizationArticlesMain = (
  option: SelectOption | null
) => ({
  type: ArticlesMain.SET_ORGANIZATION_SELECTED_ARTICLES_MAIN,
  payload: option,
});

export const editArticleRequestArticlesMain = (article: Partial<Article>) => ({
  type: ArticlesMain.EDIT_ARTICLE_REQUEST_ARTICLES_MAIN,
  payload: article,
});

export const deleteArticleRequestArticlesMain = (id: string) => ({
  type: ArticlesMain.DELETE_ARTICLE_REQUEST_ARTICLES_MAIN,
  payload: id,
});

export const resetCategoriesStateMain = () => ({
  type: ArticlesMain.RESET_CATEGORIES_STATE_ARTICLES_MAIN,
});
