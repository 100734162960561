import { useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import { StorageHeader } from '@features/storage';
import { MainLayout } from '@shared';
import { Storage } from '@widgets/storage';

const StoragePage = () => {
  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );

  return (
    <MainLayout
      customHeader={<StorageHeader />}
      accessToCreateTicket={isAccessToCreateTicket}
    >
      <Storage />
    </MainLayout>
  );
};

export default StoragePage;
