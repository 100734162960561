import {
  Priority,
  SOURCE_TICKET_TYPE_MAP,
  SourceTicketType,
  TableTicketsTypes,
  TicketsFilterDesktop,
  TicketsFilterForRender,
} from '@entities/ticket';
import { entityNames, RenderTypes, SelectOption, TableColumns } from '@shared';

export const TICKETS_BY_CONTRACT_IDS_TABLE_FIELDS: TableColumns = [
  {
    title: 'ID',
    sortField: 'number',
    fieldName: 'number',
  },
  {
    title: 'Приоритет',
    sortField: 'priority',
    fieldName: 'priority',
  },
  { title: 'Тема', fieldName: 'theme' },
];

export const TICKETS_FROM_DESKTOP_TABLE_FIELDS: TableColumns = [
  {
    title: 'Тикет',
    sortField: 'number',
    fieldName: 'number',
    renderType: RenderTypes.NOTIFICATION_LINK,
  },
  {
    title: 'SLA',
    fieldName: 'sla',
  },
  { title: 'Исполнитель', fieldName: 'specialistId', sortField: 'specialist' },
  { title: 'Клиент', fieldName: 'client', sortField: 'client' },
];

export const TICKETS_FROM_DESKTOP_HISTORY_TABLE_FIELDS: TableColumns = [
  {
    title: 'Тикет',
    sortField: 'number',
    fieldName: 'number',
    renderType: RenderTypes.NOTIFICATION_LINK,
  },
  { title: 'SLA', fieldName: 'sla' },
  { title: 'Тема', fieldName: 'theme' },
  {
    title: 'Исполнитель',
    fieldName: 'specialistId',
    sortField: 'specialist',
  },
  { title: 'Клиент', fieldName: 'client', sortField: 'client' },
  {
    title: 'Статус',
    fieldName: 'status',
    sortField: 'status',
  },
];

export const DEFAULT_TICKETS_FILTER_VALUES = {
  theme: '',
  number: '',
  tagName: '',
  statuses: [],
  priorities: [],
  systemIds: [],
  typeIds: [],
  clientIds: [],
  specialistIds: [],
  dateSolve: '',
  dateCreate: '',
  customFields: [],
  sources: [],
};

export const PRIORITY_OPTIONS = [
  { title: 'Без приоритета', value: Priority.NONE },
  { title: 'Низкий', value: Priority.LOW },
  { title: 'Средний', value: Priority.MIDDLE },
  { title: 'Высокий', value: Priority.HIGH },
  { title: 'Критический', value: Priority.VERY_HIGH },
];

export const PAGINATION_RANGE_MAP: Record<
  TableTicketsTypes,
  number | undefined
> = {
  [TableTicketsTypes.FULL]: 1,
  [TableTicketsTypes.BY_CONTRACT_IDS]: 0,
  [TableTicketsTypes.FROM_DESKTOP]: 0,
  [TableTicketsTypes.FROM_DESKTOP_HISTORY]: 0,
};

export const ticketTabEntityMap = {
  MY_TICKETS: entityNames.MY_TICKET,
  ALL_TICKETS: entityNames.ALL_TICKET,
  MY_GROUPS_TICKETS: entityNames.GROUPS_TICKETS,
};

export const SOURCE_TICKET_TYPE_OPTIONS: SelectOption<SourceTicketType>[] = [
  {
    title: SOURCE_TICKET_TYPE_MAP.SUP_PORTAL,
    value: SourceTicketType.SUP_PORTAL,
  },
  { title: SOURCE_TICKET_TYPE_MAP.JIRA, value: SourceTicketType.JIRA },
  { title: SOURCE_TICKET_TYPE_MAP.TELEGRAM, value: SourceTicketType.TELEGRAM },
  { title: SOURCE_TICKET_TYPE_MAP.MAIL, value: SourceTicketType.MAIL },
  {
    title: SOURCE_TICKET_TYPE_MAP.EXTERNAL_INTEGRATION,
    value: SourceTicketType.EXTERNAL_INTEGRATION,
  },
];
export const DEFAULT_SORT_FILTER_TYPES = 'TITLE_ASC';
export const DEFAULT_SORT_FILTER_STATUSES = 'NAME_ASC';

export const CURRENT_TICKETS_REPORT_FILTER_FIELD: TicketsFilterDesktop[] = [
  TicketsFilterDesktop.number,
  TicketsFilterDesktop.sla,
  TicketsFilterDesktop.specialistId,
  TicketsFilterDesktop.clientId,
];

export const HISTORY_TICKETS_REPORT_FILTER_FIELD: string[] = [
  TicketsFilterDesktop.number,
  TicketsFilterDesktop.sla,
  TicketsFilterDesktop.theme,
  TicketsFilterDesktop.specialistId,
  TicketsFilterDesktop.clientId,
];

export const INITIAL_TICKETS_REPORT_VALUES: TicketsFilterForRender = {
  clientIds: [],
  specialistIds: [],
  number: '',
  theme: '',
};
