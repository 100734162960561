import { FC, MouseEvent, useEffect, useState } from 'react';

import { UploadingFile } from '@entities/storage';
import {
  BackArrowIcon,
  Button,
  DocumentIcon,
  Typography,
  TypographyVariants,
} from '@shared';

import { circumference, radius, UPLOADING_STATUS_TEXT } from '../../../config';

import styles from './StorageDownloadingFile.module.scss';

interface StorageDownloadingFileProps extends UploadingFile {
  cancelUploadingFile: (id: string) => void;
}

export const StorageDownloadingFile: FC<StorageDownloadingFileProps> = ({
  id,
  title,
  cancelUploadingFile,
  status,
  progress,
  cancelUpload,
}) => {
  const [offset, setOffset] = useState(circumference);

  const setProgress = (percent: number) => {
    const currentOffset = circumference - (percent / 100) * circumference;

    setOffset(currentOffset);
  };

  const handleCancelUpload = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    cancelUploadingFile(id);
    cancelUpload();
  };

  useEffect(() => {
    setProgress(progress);
  }, [progress]);

  return (
    <div className={styles.storageDownloadingFile}>
      <div>
        <div className={styles.storageDownloadingFile__progressContainer}>
          <BackArrowIcon className={styles.storageDownloadingFile__arrowIcon} />
          <svg className={styles.storageDownloadingFile__progress}>
            <circle
              className={styles.storageDownloadingFile__progressCircle}
              cx="50%"
              cy="50%"
              r={radius}
              style={{
                strokeDashoffset: `${offset}`,
                strokeDasharray: `${circumference} ${circumference}`,
              }}
            />
          </svg>
        </div>
      </div>
      <Typography variant={TypographyVariants.h6}>
        {UPLOADING_STATUS_TEXT[status]}
      </Typography>
      <Typography
        className={styles.storageDownloadingFile__title}
        variant={TypographyVariants.b3}
      >
        <DocumentIcon className={styles.storageDownloadingFile__icon} />
        {title}
      </Typography>
      <Button appearance="flat" onClick={handleCancelUpload}>
        Отмена
      </Button>
    </div>
  );
};
