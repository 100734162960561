import cn from 'clsx';
import { FC } from 'react';

import { SLADataType, SlaTable, SlaTableHeader } from '@entities/sla';
import { SystemsFromContractTable } from '@entities/system';
import { TicketTypeSettings } from '@entities/ticket';
import { CreateSLA } from '@features/contract';
import { Card, CardTableHeader } from '@shared';

import { useSystemsTableContainer } from '../../lib';

import styles from './SystemsTableSupAg.module.scss';

interface SystemsTableSupAgProps {
  onChange?: (data: SLADataType) => void;
  setIsValidSlaTicketTypes?: (data: boolean) => void;
  ticketTypes?: TicketTypeSettings[];
}

export const SystemsTableSupAg: FC<SystemsTableSupAgProps> = ({
  onChange,
  setIsValidSlaTicketTypes,
  ticketTypes: slaTicketTypes,
}) => {
  const { methods, state } = useSystemsTableContainer();

  const { setTableOpened, prepareDataTag } = methods;

  const {
    systems,
    tableOpened,
    sla,
    autoClosingTime,
    ticketTypes,
    currentSystemId,
    loading,
    isCreateMode,
  } = state;

  const getContent = () => (
    <SystemsFromContractTable
      systems={systems}
      activeRowId={currentSystemId}
      customLoading={loading}
    />
  );

  const slaContent = isCreateMode ? (
    <CreateSLA
      sla={sla}
      createMode
      canChooseSystem={false}
      showContentInModal={false}
      onChange={onChange}
      slaTicketTypes={slaTicketTypes}
      autoClosingProps={{
        classNameToggleContainer: styles.systemsTableSupAg__autoClosingToggle,
        className: styles.systemsTableSupAg__autoClosing,
      }}
      setIsValidSlaTicketTypes={setIsValidSlaTicketTypes}
    />
  ) : (
    <>
      <SlaTableHeader
        workTime={prepareDataTag()}
        autoClosing={autoClosingTime}
        className={cn({
          [styles.systemsTableSupAg__slaHeader_close]: !tableOpened,
        })}
      />
      <SlaTable slaTicketTypes={ticketTypes} slaSettings={sla?.settings} />
    </>
  );

  return (
    <div
      className={cn(styles.systemsTableSupAg, {
        [styles.systemsTableSupAg_short]: !isCreateMode,
        [styles.systemsTableSupAg_close]: !tableOpened,
      })}
    >
      <div className={styles.systemsTableSupAg__systems}>
        <CardTableHeader
          title="Системы"
          tableOpened={tableOpened}
          handleTableOpen={setTableOpened}
          className={cn(styles.systemsTableSupAg__header, {
            [styles.systemsTableSupAg__header_close]: !tableOpened,
          })}
        />
        <div className={styles.systemsTableSupAg__systemsContent}>
          {getContent()}
        </div>
      </div>
      <Card
        className={cn(styles.systemsTableSupAg__sla, {
          [styles.systemsTableSupAg__sla_close]: !tableOpened,
        })}
      >
        {slaContent}
      </Card>
    </div>
  );
};
