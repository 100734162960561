import { MouseEvent } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  EmployeesFilters,
  fetchEmployeesStatisticRequest,
  fetchSlaStatisticRequestEmployees,
  fetchTicketsStatisticRequestEmployees,
  setEmployeesFilters,
} from '@entities/desktop';
import { INITIAL_EMPLOYEES_FIO_FILTER } from '@features/user/config';

import { EmployeesFieldName } from '../../model';

export const useUsersDesktopEmployeesFilter = () => {
  const dispatch = useDispatch();
  const { handleSubmit, control, reset } = useForm<EmployeesFilters>({
    defaultValues: INITIAL_EMPLOYEES_FIO_FILTER,
  });

  const fieldNames = Object.values(EmployeesFieldName);

  const formSubmitHandler = handleSubmit((data: EmployeesFilters) => {
    dispatch(setEmployeesFilters(data));
    dispatch(fetchEmployeesStatisticRequest());
    dispatch(fetchSlaStatisticRequestEmployees());
    dispatch(fetchTicketsStatisticRequestEmployees());
  });

  const resetFilter = (event?: MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
    }
    reset();
    formSubmitHandler();
  };

  return {
    state: { fieldNames },
    methods: { control, formSubmitHandler, resetFilter },
  };
};
