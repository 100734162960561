import {
  Environment,
  EnvironmentsFilter,
} from '@entities/environment/model/types';
import { SLAType } from '@entities/sla/model/types';
import { ApiClient, getEnv, ResponseWithMeta } from '@shared';

import { DEFAULT_SORT_FILTER_SYSTEMS } from '../../config';
import {
  System,
  SystemFormData,
  SystemPriority,
  SystemsFilterToRequest,
  SystemTicketPayload,
} from '../../model';

import { systemsEndpoints } from './endpoints';

export const systemsRequests = {
  fetchSystems: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: SystemsFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<System[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: systemsEndpoints.getSystems(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchCurrentSystem: (id: string) =>
    ApiClient.get<System>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: systemsEndpoints.getCurrentSystem(id),
      data: {},
    }),
  fetchSystemsByContractId: (
    id: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: SystemsFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<System[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: systemsEndpoints.getSystemsByContractId(id),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchMySystems: (
    pageNum = 0,
    _pageSize?: number,
    sort?: string,
    filter?: SystemsFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<System[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: systemsEndpoints.getMySystems(),
      params: { pageNum, pageSize: 999, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchFilterSystems: ({
    pageNum = 0,
    pageSize,
    sort = DEFAULT_SORT_FILTER_SYSTEMS,
    ticketTab,
    filter,
  }: SystemTicketPayload) =>
    ApiClient.post<ResponseWithMeta<System[]>>({
      url: systemsEndpoints.getFilterSystems(),
      params: {
        pageNum,
        pageSize,
        sort: sort?.toUpperCase(),
        ticketTab,
      },
      data: filter,
    }),
  fetchSystemsOrganization: (id: string) =>
    ApiClient.get<System[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: systemsEndpoints.getSystemsOrganization(id),
    }),
  fetchSystemPriorities: (systemId: string) =>
    ApiClient.get<SystemPriority[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: systemsEndpoints.getSystemPriorities(systemId),
    }),
  deleteSystem: (id: string) =>
    ApiClient.delete<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: systemsEndpoints.deleteSystem(id),
      data: {},
    }),
  updateSystem: (data: System | SystemFormData) =>
    ApiClient.put<System>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: systemsEndpoints.updateSystem(),
      data,
    }),
  fetchSystemsOrganizationForJira: (id: string) =>
    ApiClient.get<System[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: systemsEndpoints.getSystemsOrganizationFirJira(id),
    }),

  fetchSLA: (contractId: string, systemId: string) =>
    ApiClient.get<SLAType>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: systemsEndpoints.getSLA(),
      params: { contract_id: contractId, system_id: systemId },
    }),
  fetchEnvironments: (filter?: EnvironmentsFilter) =>
    ApiClient.post<ResponseWithMeta<Environment[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: systemsEndpoints.getEnvironments(),
      params: { pageNum: 0, pageSize: 1000 },
      data: filter,
    }),
};
