import cn from 'clsx';
import { PropsWithChildren } from 'react';

import { Button, PlusIcon, Popover, Size, TabsNew } from '@shared';
import { Tab, TabsProps } from '@shared/ui-new/Tabs';

import { JiraIntegrationTabType } from '../../model';

import styles from './JiraTabs.module.scss';

type DropContentProps = {
  dropContent?: JSX.Element;
  togglePopover: () => void;
  isPopoverOpen: boolean;
};

type JiraTabsProps = {
  tabs: Tab[];
  create?: boolean;
  classNameHeader?: string;
  dropContentProps?: DropContentProps;
  createHandler?: () => void;
  createDisabled?: boolean;
  tabType?: JiraIntegrationTabType;
};

export const JiraTabs = <T extends string>({
  tabs,
  children,
  create,
  classNameHeader,
  dropContentProps,
  createHandler,
  createDisabled,
  tabType,
  onClick,
  activeKey,
}: PropsWithChildren<JiraTabsProps> & TabsProps<T>) => {
  const { dropContent, togglePopover, isPopoverOpen } = dropContentProps || {};

  const isIntegration = tabType === JiraIntegrationTabType.integration;

  const popover = dropContent && togglePopover && (
    <Popover
      isOpen={isPopoverOpen}
      togglePopover={togglePopover}
      content={dropContent}
      positions={['left', 'bottom']}
      align="start"
    />
  );

  const createButton = isIntegration && createHandler && (
    <Button
      appearance="flat"
      size={Size.xs}
      onClick={createHandler}
      icon={<PlusIcon />}
      disabled={createDisabled}
      className={styles.cardTableHeader__createButton}
    >
      Добавить
    </Button>
  );

  return (
    <div
      className={cn(styles.jiraTabs__container, {
        [styles.jiraTabs__container_create]: create,
      })}
    >
      <div className={cn(styles.jiraTabs__header, classNameHeader)}>
        <div className={styles.jiraTabs__headerTabs}>
          <TabsNew tabs={tabs} onClick={onClick} activeKey={activeKey} />
        </div>
        <div className={styles.jiraTabs__buttons}>
          {createButton}
          {popover}
        </div>
      </div>
      {children}
    </div>
  );
};
