import cn from 'clsx';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import { getLoading as getRolesLoading } from '@entities/roles';
import {
  fetchUsersRequest,
  getLoading,
  setUsersFilter,
  setUsersPage,
  TableUsersTypes,
  User,
  UsersFilter,
  UsersFilterData,
} from '@entities/user';
import { getLoading as getWorkGroupsLoading } from '@entities/work-group';
import {
  MailIcon,
  NotificationLink,
  PhoneIcon,
  Table,
  UserIcon,
} from '@shared';

import { TABLE_HEADER_FULL_FIELDS } from '../../config';

import styles from './UsersFullTable.module.scss';

interface DataTableConfig {
  users?: User[];
  isAccessToViewingCompany: boolean;
}

const getDataTable = ({ users, isAccessToViewingCompany }: DataTableConfig) =>
  users?.map((user) => {
    const {
      login,
      lastName,
      firstName,
      id,
      middleName,
      email,
      phoneNumber,
      organization,
    } = user;
    return {
      id,
      login: {
        icon: (
          <UserIcon
            className={cn(styles.users__icon, styles.users__icon_blue)}
          />
        ),
        children: <NotificationLink to={`/admin/users/${id}`} title={login} />,
        to: `/admin/users/${id}`,
      },
      organizationId: isAccessToViewingCompany ? (
        <NotificationLink
          to={`/admin/organizations/${organization?.id}`}
          title={organization?.title}
        />
      ) : (
        organization?.title
      ),
      firstName,
      lastName,
      middleName,
      email: {
        text: email,
        icon: <MailIcon className={styles.users__icon} />,
      },
      phoneNumber: {
        text: phoneNumber,
        icon: <PhoneIcon className={styles.users__icon} />,
      },
      organizationIdAddl: organization?.id,
    };
  }) || [];

interface UsersFullTableProps {
  users?: User[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: string) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
}

export const UsersFullTable: FC<UsersFullTableProps> = ({
  users,
  handleRowClick,
  sort,
  handleSort,
  activeRowId,
  showFilterRow,
  isFilterActive,
}) => {
  const dispatch = useDispatch();

  const isAccessToViewingCompany = useSelector(
    getIsAccessedAction(ActionList.ViewingCompanyAdministration)
  );

  const data = getDataTable({ users, isAccessToViewingCompany });

  const loading = useSelector(getLoading);
  const workGroupsLoading = useSelector(getWorkGroupsLoading);
  const rolesLoading = useSelector(getRolesLoading);

  const handleFilter = (values: UsersFilterData) => {
    dispatch(setUsersFilter(values));
    dispatch(setUsersPage(0));
    dispatch(fetchUsersRequest());
  };

  return (
    <Table
      data={data}
      columns={TABLE_HEADER_FULL_FIELDS}
      onRowClick={handleRowClick}
      onSort={handleSort}
      sort={sort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={
        showFilterRow && (
          <UsersFilter
            tableType={TableUsersTypes.FULL}
            onFilter={handleFilter}
          />
        )
      }
      disabledSort={loading}
      disabledRows={rolesLoading || workGroupsLoading}
    />
  );
};
