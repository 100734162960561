import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getIsClient, getManagerWorkGroups } from '@entities/auth';
import {
  getNotificationsMyTicketsIds,
  getNotificationsNewTicketsIds,
} from '@entities/notifications';
import {
  CurrentTicketAdaptive,
  enabledFieldsList,
  getCurrentTicketId,
  getLoadingTicketDetail,
  getSettingFields,
  getTicketLoading,
  PriorityBlock,
  SOURCE_TICKET_TYPE_MAP,
  StatusBlock,
  Ticket,
} from '@entities/ticket';
import {
  Avatar,
  AvatarColor,
  CalendarDangerIcon,
  CalendarIcon,
  checkPathnameWithTrailingSlash,
  getFormatDateWithYear,
  IconBlock,
  Loader,
  RouterHref,
  Size,
  Table,
  TableColumn,
  TableColumns,
  TableRow,
} from '@shared';

import { getColumns } from '../../../lib';
import { TicketsFilter } from '../TicketsFilter';
import { getCustomFieldsForTable } from '../TicketsSettingFields';

import styles from './TicketsFullTable.module.scss';

interface DataTableProps {
  isClient: boolean;
  tickets?: Ticket[];
  linkTicketTitle?: string;
  notificationsTicketsIds?: string[];
  isMyTicketPage?: boolean;
  checkedTickets?: string[];
  ticketLoading?: boolean;
}

const getDataTable = ({
  isClient,
  tickets,
  linkTicketTitle,
  notificationsTicketsIds,
  isMyTicketPage,
  ticketLoading,
}: DataTableProps) =>
  tickets?.map((ticket) => {
    const {
      number,
      customStatus,
      id,
      priority,
      theme,
      clientId,
      specialistId,
      dateSolve,
      systemId,
      ticketType,
      expired,
      tags,
      dateCreate,
      customTicketFields,
      source,
    } = ticket;

    return {
      id,
      number: {
        title: `${linkTicketTitle} #${number}`,
        to: `${RouterHref.Tickets}/${id}`,
        withNotification: id ? notificationsTicketsIds?.includes(id) : false,
        newTicketNotification: !isMyTicketPage,
      },
      status: (
        <StatusBlock
          status={customStatus?.defaultStatus}
          label={customStatus?.name}
          color={customStatus?.color}
        />
      ),
      priority: <PriorityBlock priority={priority} />,
      theme,
      clientId: (
        <div className={styles.ticketsFullTable__cell}>
          {clientId?.value && (
            <Avatar
              size={Size.xxs}
              initialsUser={clientId?.value}
              color={AvatarColor.purple}
              className={styles.ticketsFullTable__cellItem}
            />
          )}
          {clientId?.value || '-'}
        </div>
      ),
      specialistId: (
        <div className={styles.ticketsFullTable__cell}>
          {specialistId?.value && (
            <Avatar
              size={Size.xxs}
              initialsUser={specialistId?.value}
              className={styles.ticketsFullTable__cellItem}
            />
          )}
          {specialistId?.value || '-'}
        </div>
      ),
      dateSolve: (
        <IconBlock
          icon={
            !expired || isClient ? (
              <CalendarIcon className={styles.ticketsFullTable__icon_grey} />
            ) : (
              <CalendarDangerIcon className={styles.ticketsFullTable__icon} />
            )
          }
          text={dateSolve ? getFormatDateWithYear(dateSolve) : '-'}
        />
      ),
      systemId: { text: systemId?.value },
      ticketType: ticketType?.value || '-',
      adaptiveRow: (
        <TableRow className={styles.ticketsFullTable__currentTicket}>
          <td
            colSpan={100}
            className={styles.ticketsFullTable__currentTicketCell}
          >
            {ticketLoading ? <Loader /> : <CurrentTicketAdaptive />}
          </td>
        </TableRow>
      ),
      tags: tags?.map((item) => item.name).join('; ') || '-',
      dateCreate: (
        <IconBlock
          icon={<CalendarIcon className={styles.ticketsFullTable__icon_grey} />}
          text={dateCreate ? getFormatDateWithYear(dateCreate) : '-'}
        />
      ),
      source: { text: source ? SOURCE_TICKET_TYPE_MAP[source] : '-' },
      ...getCustomFieldsForTable(customTicketFields),
    };
  }) || [];

interface TicketsFullTableProps {
  tickets?: Ticket[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: string) => void;
  isSearchOpen?: boolean;
  isFilterActive?: boolean;
}

export const TicketsFullTable: FC<TicketsFullTableProps> = ({
  isSearchOpen,
  tickets,
  sort,
  handleSort,
  handleRowClick,
  isFilterActive,
}) => {
  const { t } = useTranslation('notifications');
  const { pathname } = useLocation();

  const currentTicketId = useSelector(getCurrentTicketId);
  const isClient = useSelector(getIsClient);
  const isWorkGroupManager = useSelector(getManagerWorkGroups);
  const notificationsMyTicketsIds = useSelector(getNotificationsMyTicketsIds);
  const notificationsNewTicketsIds = useSelector(getNotificationsNewTicketsIds);
  const loading = useSelector(getLoadingTicketDetail);
  const ticketLoading = useSelector(getTicketLoading);
  const settingFields = useSelector(getSettingFields);
  const columns = getColumns(settingFields);
  const { defaultColumns, customColumns } = useSelector(enabledFieldsList);
  const activeFieldsList = [...defaultColumns, ...customColumns];

  const isMyTicketPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.Tickets
  );
  const isAllTicketsPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.TicketsAll
  );
  const isGroupTicketsPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.TicketsGroup
  );

  const isSpecialistAllTickets =
    !isClient && (isAllTicketsPage || isGroupTicketsPage);
  const isSpecialistColumn = isSpecialistAllTickets || isClient;

  const getNotificationsTicketsIds = () => {
    if (isMyTicketPage) {
      return notificationsMyTicketsIds;
    }
    if (isAllTicketsPage) {
      return notificationsNewTicketsIds;
    }
    return [];
  };

  const notificationsTicketsIds = getNotificationsTicketsIds();

  const data = getDataTable({
    isClient,
    tickets,
    linkTicketTitle: t('notification.ticketLinkTitle'),
    notificationsTicketsIds,
    isMyTicketPage,
    ticketLoading,
  });

  const bodySearch = isSearchOpen && <TicketsFilter />;

  const fields: TableColumns = columns.filter(
    (elem: TableColumn) =>
      activeFieldsList.includes(elem.fieldName.toString()) &&
      !(isClient && isMyTicketPage && elem.fieldName === 'clientId') &&
      !(!isSpecialistColumn && elem.fieldName === 'specialistId') &&
      !(isClient && !isWorkGroupManager && elem.fieldName === 'tags')
  );

  return (
    <Table
      columns={fields}
      data={data}
      sort={sort}
      onSort={handleSort}
      onRowClick={handleRowClick}
      activeRowId={currentTicketId}
      filterComponent={bodySearch}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      disabledSort={loading || ticketLoading}
    />
  );
};
