import { DeleteDataType, ResponseWithMeta } from '@shared';

import {
  CreateOrganizationData,
  FetchOrganizations,
  Organization,
  OrganizationsFilter,
} from './types';

export enum Organizations {
  FETCH_ORGANIZATIONS_SUCCESS = 'ORGANIZATIONS/FETCH_ORGANIZATIONS_SUCCESS',
  FETCH_ORGANIZATIONS_ADD_JOIN_SUCCESS = 'ORGANIZATIONS/FETCH_ORGANIZATIONS_ADD_JOIN_SUCCESS',
  FETCH_ORGANIZATIONS_REQUEST = 'ORGANIZATIONS/FETCH_ORGANIZATIONS_REQUEST',
  ORGANIZATIONS_LOADING_SHOW = 'ORGANIZATIONS/ORGANIZATIONS_LOADING_SHOW',
  ORGANIZATIONS_LOADING_HIDE = 'ORGANIZATIONS/ORGANIZATIONS_LOADING_HIDE',
  ORGANIZATIONS_ADD_LOADING_SHOW = 'ORGANIZATIONS/ORGANIZATIONS_ADD_LOADING_SHOW',
  ORGANIZATIONS_ADD_LOADING_HIDE = 'ORGANIZATIONS/ORGANIZATIONS_ADD_LOADING_HIDE',
  ORGANIZATION_LOADING_SHOW = 'ORGANIZATIONS/ORGANIZATION_LOADING_SHOW',
  ORGANIZATION_LOADING_HIDE = 'ORGANIZATIONS/ORGANIZATION_LOADING_HIDE',
  SET_CURRENT_ORGANIZATIONS_PAGE = 'ORGANIZATIONS/SET_CURRENT_ORGANIZATIONS_PAGE',
  SET_SORT_ORGANIZATIONS = 'ORGANIZATIONS/SET_SORT_ORGANIZATIONS',
  SET_SIZE_PAGE_ORGANIZATIONS = 'ORGANIZATIONS/SET_SIZE_PAGE_ORGANIZATIONS',
  CREATE_ORGANIZATION = 'ORGANIZATIONS/CREATE_ORGANIZATION',
  EDIT_ORGANIZATION = 'ORGANIZATIONS/EDIT_ORGANIZATION',
  FETCH_ORGANIZATION_REQUEST = 'ORGANIZATIONS/FETCH_ORGANIZATION_REQUEST',
  SET_CURRENT_ORGANIZATION_SUCCESS = 'ORGANIZATIONS/SET_CURRENT_ORGANIZATION_SUCCESS',
  RESET_ORGANIZATION_STATE = 'ORGANIZATIONS/RESET_ORGANIZATION_STATE',
  SET_CURRENT_ORGANIZATION_ID = 'ORGANIZATIONS/SET_CURRENT_ORGANIZATION_ID',
  SET_ORGANIZATIONS_FILTER = 'ORGANIZATIONS/SET_ORGANIZATIONS_FILTER',
  FETCH_ORGANIZATIONS_ADD_SUCCESS = 'ORGANIZATIONS/FETCH_ORGANIZATIONS_ADD_SUCCESS',
  FETCH_ORGANIZATIONS_ADD_REQUEST = 'ORGANIZATIONS/FETCH_ORGANIZATIONS_ADD_REQUEST',
  SET_CURRENT_ORGANIZATIONS_ADD_PAGE = 'ORGANIZATIONS/SET_CURRENT_ORGANIZATIONS_ADD_PAGE',
  SET_SORT_ORGANIZATIONS_ADD = 'ORGANIZATIONS/SET_SORT_ORGANIZATIONS_ADD',
  SET_SIZE_PAGE_ORGANIZATIONS_ADD = 'ORGANIZATIONS/SET_SIZE_PAGE_ORGANIZATIONS_ADD',
  SET_ORGANIZATIONS_ADD_FILTER = 'ORGANIZATIONS/SET_ORGANIZATIONS_ADD_FILTER',
  RESET_ORGANIZATION_ADD_STATE = 'ORGANIZATIONS/RESET_ORGANIZATION_ADD_STATE',
  SET_FILTER_ORGANIZATION_ID = 'ORGANIZATIONS/SET_FILTER_ORGANIZATION_ID',
  RESET_FILTER_ORGANIZATION_ID = 'ORGANIZATIONS/RESET_FILTER_ORGANIZATION_ID',
  FETCH_ORGANIZATIONS_JOIN_SUCCESS = 'ORGANIZATIONS/FETCH_ORGANIZATIONS_JOIN_SUCCESS',
  RESET_ORGANIZATION_ONLY_STATE = 'ORGANIZATIONS/RESET_ORGANIZATION_ONLY_STATE',
  DELETE_ORGANIZATION_REQUEST = 'ORGANIZATIONS/DELETE_ORGANIZATION_REQUEST',
  FETCH_ORGANIZATIONS_FOR_REPORT_REQUEST = 'ORGANIZATIONS/FETCH_ORGANIZATIONS_FOR_REPORT_REQUEST',
  FETCH_ORGANIZATIONS_FOR_REPORT_SUCCESS = 'ORGANIZATIONS/FETCH_ORGANIZATIONS_FOR_REPORT_SUCCESS',
  RESET_CURRENT_ORGANIZATION = 'ORGANIZATIONS/RESET_CURRENT_ORGANIZATION',
  CHECK_IS_EXIST_TITLE_CONTAINING_REQUEST = 'USERS/CHECK_IS_EXIST_TITLE_CONTAINING_REQUEST',
  CHECK_IS_EXIST_TITLE_CONTAINING_SUCCESS = 'USERS/CHECK_IS_EXIST_TITLE_CONTAINING_SUCCESS',
}

export interface ShowOrganizationsLoadingAction {
  type: Organizations.ORGANIZATIONS_LOADING_SHOW;
}

export interface HideOrganizationsLoadingAction {
  type: Organizations.ORGANIZATIONS_LOADING_HIDE;
}

export interface ShowOrganizationsAddLoadingAction {
  type: Organizations.ORGANIZATIONS_ADD_LOADING_SHOW;
}

export interface HideOrganizationsAddLoadingAction {
  type: Organizations.ORGANIZATIONS_ADD_LOADING_HIDE;
}

export interface ShowOrganizationLoadingAction {
  type: Organizations.ORGANIZATION_LOADING_SHOW;
}

export interface HideOrganizationLoadingAction {
  type: Organizations.ORGANIZATION_LOADING_HIDE;
}

export interface FetchOrganizationsSuccessAction {
  type: Organizations.FETCH_ORGANIZATIONS_SUCCESS;
  payload: ResponseWithMeta<Organization[]>;
}

export interface FetchOrganizationsRequestAction {
  type: Organizations.FETCH_ORGANIZATIONS_REQUEST;
  payload?: FetchOrganizations;
}

export interface SetCurrentOrganizationsPageAction {
  type: Organizations.SET_CURRENT_ORGANIZATIONS_PAGE;
  payload: number;
}

export interface SetSizePageAction {
  type: Organizations.SET_SIZE_PAGE_ORGANIZATIONS;
  payload: number;
}

export interface SetSortOrganizationsAction {
  type: Organizations.SET_SORT_ORGANIZATIONS;
  payload: string;
}

export interface FetchOrganizationsAddRequestAction {
  type: Organizations.FETCH_ORGANIZATIONS_ADD_REQUEST;
  payload?: FetchOrganizations;
}

export interface FetchOrganizationsAddJoinSuccessAction {
  type: Organizations.FETCH_ORGANIZATIONS_ADD_JOIN_SUCCESS;
  payload: ResponseWithMeta<Organization[]>;
}

export interface FetchOrganizationsJoinSuccessAction {
  type: Organizations.FETCH_ORGANIZATIONS_JOIN_SUCCESS;
  payload: ResponseWithMeta<Organization[]>;
}

export interface FetchOrganizationsAddSuccessAction {
  type: Organizations.FETCH_ORGANIZATIONS_ADD_SUCCESS;
  payload: ResponseWithMeta<Organization[]>;
}

export interface SetCurrentOrganizationsAddPageAction {
  type: Organizations.SET_CURRENT_ORGANIZATIONS_ADD_PAGE;
  payload: number;
}

export interface SetSizePageAddAction {
  type: Organizations.SET_SIZE_PAGE_ORGANIZATIONS_ADD;
  payload: number;
}

export interface SetSortOrganizationsAddAction {
  type: Organizations.SET_SORT_ORGANIZATIONS_ADD;
  payload: string;
}

export interface FetchOrganizationRequestAction {
  type: Organizations.FETCH_ORGANIZATION_REQUEST;
  payload: string;
}

export interface CreateOrganizationAction {
  type: Organizations.CREATE_ORGANIZATION;
  payload: CreateOrganizationData;
}

export interface EditOrganizationAction {
  type: Organizations.EDIT_ORGANIZATION;
  payload: CreateOrganizationData;
}

export interface SetCurrentOrganizationSuccessAction {
  type: Organizations.SET_CURRENT_ORGANIZATION_SUCCESS;
  payload?: Organization;
}

export interface ResetOrganizationStateAction {
  type: Organizations.RESET_ORGANIZATION_STATE;
}

export interface ResetOrganizationAddStateAction {
  type: Organizations.RESET_ORGANIZATION_ADD_STATE;
}

export interface ResetOrganizationOnlyStateAction {
  type: Organizations.RESET_ORGANIZATION_ONLY_STATE;
}

export interface SetCurrentOrganizationIdAction {
  type: Organizations.SET_CURRENT_ORGANIZATION_ID;
  payload?: string;
}

export interface SetFilterOrganizationIdAction {
  type: Organizations.SET_FILTER_ORGANIZATION_ID;
  payload: string;
}

export interface SetOrganizationsFilterAction {
  type: Organizations.SET_ORGANIZATIONS_FILTER;
  payload: OrganizationsFilter;
}

export interface SetOrganizationsAddFilterAction {
  type: Organizations.SET_ORGANIZATIONS_ADD_FILTER;
  payload: OrganizationsFilter;
}

export interface ResetFilterOrganizationIdAction {
  type: Organizations.RESET_FILTER_ORGANIZATION_ID;
}

export interface DeleteOrganizationRequestAction {
  type: Organizations.DELETE_ORGANIZATION_REQUEST;
  payload: DeleteDataType;
}

export interface FetchOrganizationsForReportRequestAction {
  type: Organizations.FETCH_ORGANIZATIONS_FOR_REPORT_REQUEST;
}

export interface FetchOrganizationsForReportSuccessAction {
  type: Organizations.FETCH_ORGANIZATIONS_FOR_REPORT_SUCCESS;
  payload: Organization[];
}

export interface ResetCurrentOrganizationAction {
  type: Organizations.RESET_CURRENT_ORGANIZATION;
}

export interface CheckIsExistTitleContainingRequestAction {
  type: Organizations.CHECK_IS_EXIST_TITLE_CONTAINING_REQUEST;
  payload: string;
}

export interface CheckIsExistTitleContainingSuccessAction {
  type: Organizations.CHECK_IS_EXIST_TITLE_CONTAINING_SUCCESS;
  payload: boolean;
}

export type OrganizationsActionsTypes =
  | FetchOrganizationsSuccessAction
  | SetCurrentOrganizationsPageAction
  | SetSizePageAction
  | SetSortOrganizationsAction
  | FetchOrganizationRequestAction
  | SetCurrentOrganizationSuccessAction
  | ResetOrganizationStateAction
  | SetCurrentOrganizationIdAction
  | SetOrganizationsFilterAction
  | FetchOrganizationsAddSuccessAction
  | SetCurrentOrganizationsAddPageAction
  | SetSizePageAddAction
  | SetSortOrganizationsAddAction
  | ResetOrganizationAddStateAction
  | SetOrganizationsAddFilterAction
  | SetFilterOrganizationIdAction
  | ResetFilterOrganizationIdAction
  | FetchOrganizationsAddJoinSuccessAction
  | FetchOrganizationsJoinSuccessAction
  | ResetOrganizationOnlyStateAction
  | FetchOrganizationsForReportSuccessAction
  | ShowOrganizationsAddLoadingAction
  | HideOrganizationsAddLoadingAction
  | ResetCurrentOrganizationAction
  | CheckIsExistTitleContainingSuccessAction;
