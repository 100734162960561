import { Organization } from '@entities/organization/model/types';
import { System } from '@entities/system/model';
import { TicketType } from '@entities/ticket/model/types';
import { ResponseWithMeta } from '@shared';

import {
  CopyStatusModelAction,
  FetchOrganizationsRequestAction,
  FetchOrganizationsSuccessAction,
  FetchSystemsRequestAction,
  FetchSystemsSuccessAction,
  FetchTicketTypesForSystemRequestAction,
  FetchTicketTypesForSystemsRequestAction,
  FetchTicketTypesForSystemsSuccessAction,
  FetchTicketTypesForSystemSuccessAction,
  FetchWorkflowRequestAction,
  FetchWorkflowSuccessAction,
  HideSystemsLoadingAction,
  ResetStatusesBuilderStateAction,
  SaveWorkflowAction,
  SetCurrentSystemIdAction,
  SetCurrentSystemsPageAction,
  SetPageSizeAction,
  SetSortSystemsAction,
  SetSystemsFilterAction,
  ShowSystemsLoadingAction,
  StatusesBuilder,
} from './actionTypes';
import {
  StatusCopyModelPayload,
  SystemsFilter,
  SystemsTicketTypes,
  Workflow,
  WorkflowParams,
} from './types';

export const showSystemsLoading = (): ShowSystemsLoadingAction => ({
  type: StatusesBuilder.BUILDER_SYSTEMS_LOADING_SHOW,
});

export const hideSystemsLoading = (): HideSystemsLoadingAction => ({
  type: StatusesBuilder.BUILDER_SYSTEMS_LOADING_HIDE,
});

export const fetchSystemsRequest = (): FetchSystemsRequestAction => ({
  type: StatusesBuilder.FETCH_BUILDER_SYSTEMS_REQUEST,
});

export const fetchSystemsSuccess = (
  payload: ResponseWithMeta<System[]>
): FetchSystemsSuccessAction => ({
  type: StatusesBuilder.FETCH_BUILDER_SYSTEMS_SUCCESS,
  payload,
});

export const setCurrentSystemsPage = (
  page: number
): SetCurrentSystemsPageAction => ({
  type: StatusesBuilder.SET_CURRENT_BUILDER_SYSTEMS_PAGE,
  payload: page,
});

export const setPageSize = (size: number): SetPageSizeAction => ({
  type: StatusesBuilder.SET_PAGE_SIZE_BUILDER_SYSTEMS,
  payload: size,
});

export const setSortSystems = (sort: string): SetSortSystemsAction => ({
  type: StatusesBuilder.SET_SORT_BUILDER_SYSTEMS,
  payload: sort,
});

export const resetStatusesBuilderState =
  (): ResetStatusesBuilderStateAction => ({
    type: StatusesBuilder.RESET_STATUSES_BUILDER_STATE,
  });

export const setCurrentSystemId = (id?: string): SetCurrentSystemIdAction => ({
  type: StatusesBuilder.SET_CURRENT_BUILDER_SYSTEM_ID,
  payload: id,
});

export const setSystemsFilter = (
  data: SystemsFilter
): SetSystemsFilterAction => ({
  type: StatusesBuilder.SET_BUILDER_SYSTEMS_FILTER,
  payload: data,
});

export const fetchOrganizationsRequest =
  (): FetchOrganizationsRequestAction => ({
    type: StatusesBuilder.FETCH_BUILDER_ORGANIZATIONS_REQUEST,
  });

export const fetchOrganizationsSuccess = (
  payload: Organization[]
): FetchOrganizationsSuccessAction => ({
  type: StatusesBuilder.FETCH_BUILDER_ORGANIZATIONS_SUCCESS,
  payload,
});

export const fetchWorkflowRequest = (
  payload: WorkflowParams
): FetchWorkflowRequestAction => ({
  type: StatusesBuilder.FETCH_WORKFLOW_REQUEST,
  payload,
});

export const fetchWorkflowSuccess = (
  payload?: Workflow
): FetchWorkflowSuccessAction => ({
  type: StatusesBuilder.FETCH_WORKFLOW_SUCCESS,
  payload,
});

export const fetchTicketTypesForSystemRequest = (
  payload: string
): FetchTicketTypesForSystemRequestAction => ({
  type: StatusesBuilder.FETCH_TICKET_TYPES_FOR_SYSTEM_REQUEST,
  payload,
});

export const fetchTicketTypesForSystemSuccess = (
  payload?: TicketType[]
): FetchTicketTypesForSystemSuccessAction => ({
  type: StatusesBuilder.FETCH_TICKET_TYPES_FOR_SYSTEM_SUCCESS,
  payload,
});

export const saveWorkflow = (payload: Workflow): SaveWorkflowAction => ({
  type: StatusesBuilder.SAVE_WORKFLOW,
  payload,
});

export const copyStatusModel = (
  data: StatusCopyModelPayload
): CopyStatusModelAction => ({
  type: StatusesBuilder.COPY_MODEL,
  payload: data,
});

export const fetchTicketTypesForSystemsRequest = (
  payload: string[]
): FetchTicketTypesForSystemsRequestAction => ({
  type: StatusesBuilder.FETCH_TICKET_TYPES_FOR_SYSTEMS_REQUEST,
  payload,
});

export const fetchTicketTypesForSystemsSuccess = (
  payload: SystemsTicketTypes
): FetchTicketTypesForSystemsSuccessAction => ({
  type: StatusesBuilder.FETCH_TICKET_TYPES_FOR_SYSTEMS_SUCCESS,
  payload,
});
