import { CustomFieldType } from '@entities/custom-fields';

import {
  fetchTicketStatusJoinSuccess,
  fetchTicketStatusUpdateSuccess,
  fetchTicketTypesJoinSuccess,
  fetchTicketTypesUpdateSuccess,
} from '../model/tickets/actions';
import { EnableFields } from '../model/tickets-export';
import { Priority, SourceTicketType, TicketStatus } from '../model/types';

import styles from './radioTab.module.scss';

export const DEFAULT_RADIO_TABS = [
  {
    title: 'Без приоритета',
    value: Priority.NONE,
    classNameTab: styles.radioTab_none,
  },
  {
    title: 'Низкий',
    value: Priority.LOW,
    classNameTab: styles.radioTab_low,
  },
  {
    title: 'Средний',
    value: Priority.MIDDLE,
    classNameTab: styles.radioTab_medium,
  },
  {
    title: 'Высокий',
    value: Priority.HIGH,
    classNameTab: styles.radioTab_high,
  },
  {
    title: 'Критический',
    value: Priority.VERY_HIGH,
    classNameTab: styles.radioTab_critical,
  },
];

export const TICKET_STATUS_TITLE_MAP = {
  [TicketStatus.NEW]: 'Новый',
  [TicketStatus.WORK]: 'В работе',
  [TicketStatus.CLOSE]: 'Закрыт',
  [TicketStatus.PENDING_CLOSURE]: 'В ожидании закрытия',
  [TicketStatus.WAITING_INFO]: 'В ожидании информации',
  [TicketStatus.REOPEN]: 'Переоткрыт',
};

export const TICKET_STATUS_TITLE_MAP_EN = {
  [TicketStatus.NEW]: 'New',
  [TicketStatus.WORK]: 'In work',
  [TicketStatus.CLOSE]: 'Closed',
  [TicketStatus.PENDING_CLOSURE]: 'Pending close',
  [TicketStatus.WAITING_INFO]: 'Pending info',
  [TicketStatus.REOPEN]: 'Reopen',
};

export const SOURCE_TICKET_TYPE_MAP: Record<SourceTicketType, string> = {
  [SourceTicketType.SUP_PORTAL]: 'SupPortal',
  [SourceTicketType.JIRA]: 'Jira',
  [SourceTicketType.TELEGRAM]: 'Telegram',
  [SourceTicketType.MAIL]: 'Почта',
  [SourceTicketType.EXTERNAL_INTEGRATION]: 'External integration',
};

export const SYSTEM_TICKET_INFO = 'SYSTEM_TICKET_INFO';

export const FIELD_NAMES_BY_CUSTOM_FIELD_TYPE = {
  [CustomFieldType.FIELD_DATE]: 'date',
  [CustomFieldType.FIELD_FLAG]: 'flag',
  [CustomFieldType.FIELD_TEXT]: 'text',
  [CustomFieldType.FIELD_LIST]: 'listValue',
};

export const STEP_ZOOM = 0.45;

export const CUSTOM_WRAPPER_STYLE = {
  height: '828px',
  width: '1386px',
};

export const CUSTOM_CONTENT_STYLE = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
};

export const TEXT_TICKET_TOAST: Record<string, string> = {
  changeTicketType: 'Тип тикета изменен',
  changeTicketPriority: 'Приоритет тикета изменен',
};

export const fetchTicketTypeSuccessMap = {
  update: fetchTicketTypesUpdateSuccess,
  join: fetchTicketTypesJoinSuccess,
};

export const fetchTicketStatusSuccessMap = {
  update: fetchTicketStatusUpdateSuccess,
  join: fetchTicketStatusJoinSuccess,
};

export const PRIORITY_TITLE_MAP = {
  [Priority.NONE]: 'Без приоритета',
  [Priority.LOW]: 'Низкий приоритет',
  [Priority.MIDDLE]: 'Средний приоритет',
  [Priority.HIGH]: 'Высокий приоритет',
  [Priority.VERY_HIGH]: 'Критический приоритет',
};

export const PRIORITY_TITLE_MAP_EN = {
  [Priority.NONE]: 'Without priority',
  [Priority.LOW]: 'Low priority',
  [Priority.MIDDLE]: 'Middle priority',
  [Priority.HIGH]: 'High priority',
  [Priority.VERY_HIGH]: 'Very high priority',
};

export const DEFAULT_SORT_FILTER_TYPES = 'TITLE_ASC';
export const DEFAULT_SORT_FILTER_STATUSES = 'NAME_ASC';

export const ENABLE_FIELDS: EnableFields = {
  all: true,
  status: true,
  priority: true,
  description: true,
  solutions: true,
  client: true,
  performer: true,
  system: true,
  ticketType: true,
  environment: true,
  organization: true,
  workgroup: true,
  dateCreation: true,
  dateSolve: true,
  sla: true,
  sources: true,
  customFields: false,
};

export const MAX_FILE_SIZE_TEXT = 'Размер файла не должен превышать 50 MB';
