import { Environment, EnvironmentsFilter } from '@entities/environment';
import { System, SystemPriority } from '@entities/system';
import { TicketType } from '@entities/ticket';
import { ApiClient, getEnv, ResponseWithMeta } from '@shared';

import {
  CheckConnectionToJiraData,
  CreateMapping,
  CreateMappingResponse,
  CreatePriorityMapping,
  CreateStatusMapping,
  EditJiraIntegrationsActivePayload,
  EditPriorityMapping,
  EditStatusMapping,
  FetchArgs,
  Field,
  FieldsIntegration,
  JiraIntegration,
  JiraIntegrationAddNewTicketTypePayload,
  JiraIntegrationCreate,
  JiraIntegrationEdit,
  JiraIntegrationResponse,
  JiraIntegrationsFilter,
  JiraPriority,
  JiraStatus,
  JiraStatusesPayload,
  JiraStatusesRequest,
  JiraSynchronization,
  JiraSynchronizationCreateDataToRequest,
  JiraSynchronizationFilter,
  JiraTicketType,
  JiraTicketTypeMapping,
  Status,
} from '../model';

import { endpoints } from './endpoints';

export const requests = {
  fetchJiraIntegrations: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filterValues?: JiraIntegrationsFilter
  ) =>
    ApiClient.post<ResponseWithMeta<JiraIntegration[]>>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getJiraIntegrations(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: filterValues,
    }),
  checkConnectionToJira: (data: CheckConnectionToJiraData) =>
    ApiClient.post<boolean>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getCheckConnectionToJira(),
      data,
    }),
  fetchJiraStatuses: ({ idType, ...data }: JiraStatusesRequest) =>
    ApiClient.post<JiraStatus[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getJiraStatuses(idType),
      data,
    }),
  fetchJiraPriorities: (data: CheckConnectionToJiraData) =>
    ApiClient.post<JiraPriority[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getJiraPriorities(),
      data,
    }),
  fetchJiraPrioritiesByIntegrationId: (ids: FetchArgs) =>
    ApiClient.get<JiraPriority[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getJiraPrioritiesByIntegrationId(ids),
    }),
  createJiraIntegration: (data: JiraIntegrationCreate) =>
    ApiClient.post<JiraIntegrationResponse>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getCreateJiraIntegration(),
      data,
    }),
  editJiraIntegration: ({ issueKey, ...data }: JiraIntegrationEdit) =>
    ApiClient.post<JiraIntegration>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getEditJiraIntegration(issueKey),
      data,
    }),
  mappingTicketTypes: (data: JiraTicketTypeMapping) =>
    ApiClient.post<JiraIntegration>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getMappingTicketTypes(),
      data,
    }),
  mappingStatuses: (data: CreateStatusMapping) =>
    ApiClient.post<JiraStatus[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getMappingStatuses(),
      data,
    }),
  editMappingStatuses: (data: EditStatusMapping) =>
    ApiClient.put<JiraStatus>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getEditMappingStatuses(),
      data,
    }),
  mappingPriorities: (data: CreatePriorityMapping) =>
    ApiClient.post<JiraPriority[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getMappingPriorities(),
      data,
    }),
  editMappingPriorities: (data: EditPriorityMapping) =>
    ApiClient.put<JiraPriority>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getEditMappingPriorities(),
      data,
    }),
  mapping: (data: CreateMapping) =>
    ApiClient.post<CreateMappingResponse>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getMapping(),
      data,
    }),
  fetchJiraStatusesById: (ids: FetchArgs) =>
    ApiClient.get<JiraStatus[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getJiraStatusesById(ids),
    }),
  fetchJiraPrioritiesById: (ids: FetchArgs) =>
    ApiClient.get<JiraPriority[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getJiraPrioritiesBySystemId(ids),
    }),
  deleteJiraIntegration: (id: string) =>
    ApiClient.delete({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getDeleteJiraIntegration(id),
    }),
  fetchJiraIntegration: (id: string) =>
    ApiClient.get({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getFetchJiraIntegration(id),
    }),
  editJiraIntegrationActive: (data: EditJiraIntegrationsActivePayload) =>
    ApiClient.patch({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getEditJiraIntegrationActive(),
      data,
    }),
  fetchSpFields: (ids: FetchArgs) =>
    ApiClient.get<Field[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getSpFields(ids),
    }),
  fetchJiraFields: (ids: FetchArgs) =>
    ApiClient.get<Field[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getJiraFields(ids),
    }),
  fetchFieldsIntegrations: (ids: FetchArgs) =>
    ApiClient.get<FieldsIntegration[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getFieldsIntegrations(ids),
    }),
  createFieldsIntegration: (data: FieldsIntegration, ids: FetchArgs) =>
    ApiClient.post<FieldsIntegration>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getCreateFieldsIntegration(ids),
      data,
    }),
  deleteFieldsIntegration: (id: string) =>
    ApiClient.delete<boolean>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getDeleteFieldsIntegration(id),
    }),
  editFieldsIntegration: (data: FieldsIntegration) =>
    ApiClient.put<FieldsIntegration>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getEditFieldsIntegration(),
      data,
    }),
  fetchSystemPriorities: (systemId: string) =>
    ApiClient.get<SystemPriority[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSystemPriorities(systemId),
    }),
  fetchSPStatuses: (ids: FetchArgs) =>
    ApiClient.get<Status[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getSPStatuses(ids),
    }),
  fetchTicketTypes: (systemId: string) =>
    ApiClient.get<System>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getTicketTypes(systemId),
    }),
  fetchJiraTicketType: (data: CheckConnectionToJiraData) =>
    ApiClient.post<JiraTicketType[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getJiraTicketType(),
      data,
    }),
  editMappingJiraTicketType: (data: JiraTicketTypeMapping) =>
    ApiClient.put<JiraTicketType[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getEditMappingJiraTicketType(),
      data,
    }),
  fetchJiraTicketTypesByIntegrationId: (propertyId: string) =>
    ApiClient.get<JiraTicketType[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getJiraTicketTypesByIntegrationId(propertyId),
    }),
  fetchJiraTicketTypeByIntegrationId: (propertyId: string) =>
    ApiClient.get<JiraTicketType[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getJiraTicketTypeByIntegrationId(propertyId),
    }),
  createJiraSynchronization: (data: JiraSynchronizationCreateDataToRequest) =>
    ApiClient.post<JiraSynchronization[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getCreateJiraSynchronization(),
      data,
    }),
  editJiraSynchronization: (data: JiraSynchronizationCreateDataToRequest) =>
    ApiClient.patch<JiraSynchronization>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getCreateJiraSynchronization(),
      data,
    }),
  fetchSyncTicketTypes: (systemId: string) =>
    ApiClient.get<TicketType[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSyncTicketTypes(systemId),
    }),
  fetchSyncPriorities: (systemId: string) =>
    ApiClient.get<SystemPriority[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSyncPriorities(systemId),
    }),
  fetchSyncEnvironments: (data?: EnvironmentsFilter) =>
    ApiClient.post<ResponseWithMeta<Environment[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSyncEnvironments(),
      params: { pageNum: 0, pageSize: 1000 },
      data,
    }),
  fetchSyncStatuses: (systemId: string) =>
    ApiClient.get<JiraSynchronizationFilter[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getSyncStatuses(systemId),
    }),
  fetchSyncSpecialists: (systemId: string) =>
    ApiClient.get<JiraSynchronizationFilter[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getSyncSpecialists(systemId),
    }),
  fetchSyncClients: (systemId: string) =>
    ApiClient.get<JiraSynchronizationFilter[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getSyncClients(systemId),
    }),
  fetchJiraSyncFilter: (propertyId: string) =>
    ApiClient.get<JiraSynchronization>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getJiraSyncFilter(propertyId),
    }),
  fetchJiraStatusesByPropertyId: (payload: JiraStatusesPayload) =>
    ApiClient.get<JiraStatus[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.fetchJiraStatusesByPropertyId(payload),
    }),
  addNewTicketType: ({
    jiraIntegrationId,
    ...data
  }: JiraIntegrationAddNewTicketTypePayload) =>
    ApiClient.post<JiraIntegration>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getAddNewTicketType(jiraIntegrationId),
      data,
    }),
  fetchIntegratedTicketTypes: (propertyId: string) =>
    ApiClient.get<string[]>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getIntegratedTicketTypes(propertyId),
    }),
};
