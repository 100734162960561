import { FC, memo } from 'react';
import { Controller } from 'react-hook-form';

import {
  getDefaultDataTable,
  Input,
  Size,
  TableBodyCell,
  TableFilter,
} from '@shared';

import { useUsersDesktopEmployeesFilter } from '../../lib';
import { EmployeesFieldName } from '../../model';

export const UserDesktopEmployeesFilter: FC = memo(() => {
  const { state, methods } = useUsersDesktopEmployeesFilter();
  const { fieldNames } = state;
  const { control, formSubmitHandler, resetFilter } = methods;

  const createEmptyFilterElement = (key: EmployeesFieldName) => (
    <TableBodyCell filterCell key={key} />
  );

  const filterFioElement = (
    <TableBodyCell filterCell key={EmployeesFieldName.fio}>
      <Controller
        control={control}
        name={EmployeesFieldName.fio}
        render={({ field }) => (
          <Input
            size={Size.xs}
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              formSubmitHandler();
            }}
          />
        )}
      />
    </TableBodyCell>
  );

  const defaultFilterMap: Record<EmployeesFieldName, JSX.Element> = {
    [EmployeesFieldName.fio]: filterFioElement,
    [EmployeesFieldName.inWork]: createEmptyFilterElement(
      EmployeesFieldName.inWork
    ),
    [EmployeesFieldName.closed]: createEmptyFilterElement(
      EmployeesFieldName.closed
    ),
    [EmployeesFieldName.expired]: createEmptyFilterElement(
      EmployeesFieldName.expired
    ),
    [EmployeesFieldName.timeOfSolve]: createEmptyFilterElement(
      EmployeesFieldName.timeOfSolve
    ),
  };

  const defaultFilter = getDefaultDataTable({
    columns: fieldNames,
    defaultFilterMap,
  });

  const filter = <>{defaultFilter}</>;

  return (
    <TableFilter
      filterComponent={filter}
      onReset={resetFilter}
      disableReset={false}
    />
  );
});
