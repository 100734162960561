import { CancelToken } from 'axios';

import { CustomFieldType, ValueCustomField } from '@entities/custom-fields';
import { KeyValueOption } from '@shared';

import { StorageReducerState } from './slice';

export type StorageState = { storage: StorageReducerState };

export interface ModalType {
  add: boolean;
  edit: boolean;
}

export enum UploadingStatus {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  QUEUE = 'QUEUE',
}

export enum CustomFieldTypeStorage {
  FIELD_TEXT = CustomFieldType.FIELD_TEXT,
  FIELD_DATE = CustomFieldType.FIELD_DATE,
  FIELD_FLAG = CustomFieldType.FIELD_FLAG,
}

export interface AddFilesForm {
  files?: StorageFile[];
}

export interface StorageFileCardsRequest {
  id?: string;
  pageNum?: number;
  pageSize?: number;
}

export type StorageCustomField = {
  id?: string;
  title: string;
  description: string;
  type: CustomFieldTypeStorage;
  storageId?: string;
  required?: boolean;
} & Partial<ValueCustomField>;

export interface Storage {
  id: string;
  title: string;
  description: string;
  organizations: KeyValueOption[];
  viewersGroups?: KeyValueOption[];
  editorsGroups?: KeyValueOption[];
  size: number;
  usedSpace: number;
  percentUsedSpace?: number;
  currentUserCanEditStorage?: boolean;
  currentUserCanEditFiles?: boolean;
  empty?: boolean;
  customFields?: StorageCustomField[];
}

export interface StorageFileCards {
  totalElements: number;
  elements: number;
  totalPages: number;
  page: number;
  size: number;
  content: StorageFileCard[];
}

export interface StorageFile {
  id?: string;
  url?: string;
  fileName?: string;
  description?: string;
  hashSum?: string;
  version?: string;
  updateAt?: string;
  uploadAt?: string;
  updatedBy?: {
    key?: string;
    value?: string;
  };
  size?: string;
  fileExtension?: string;
}

export interface UploadingFile {
  id: string;
  title?: string;
  progress: number;
  status: UploadingStatus;
  type: string;
  cancelUpload: () => void;
}

export interface StorageFileCard {
  id: string;
  mainFile: StorageFile;
  additionalFiles: StorageFile[];
  fileCardCustomFields: FileCardCustomFields[];
}

export type FileCardCustomFields = {
  customFieldId?: string | null;
} & ValueCustomField;

export interface StorageFileCardRequest {
  storageIds: string[];
  mainFile: StorageFile;
  additionalFiles: StorageFile[];
  viewerGroupIds?: string[];
  fileCardCustomFields?: FileCardCustomFields[];
}

interface FileCard {
  mainFile: StorageFile;
  additionalFiles: StorageFile[];
  fileCardCustomFields?: FileCardCustomFields[];
}

export interface CurrentStorageFileCardRequest {
  storageId?: string;
  fileCardId?: string;
  fileCard?: FileCard;
}

export interface DownloadStorageFileRequest {
  storageId?: string;
  fileId?: string;
}

export interface StorageFileRequest {
  fileName: string;
  url: string;
}

interface ProgressEvent {
  lengthComputable: number;
  total: number;
  target: XMLHttpRequest;
  loaded: number;
}

export interface UploadStorageFile {
  data: FormData;
  onUploadProgress: (progressEvent: ProgressEvent) => void;
  cancelToken: CancelToken;
}
