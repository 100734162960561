import { FC, memo } from 'react';
import { Controller } from 'react-hook-form';

import {
  ACTIVE_SELECT_OPTIONS,
  checkObjectIdentity,
  Input,
  Select,
  Size,
  TableBodyCell,
  TableFilter,
} from '@shared';

import { useRulesFilter } from '../../lib';

export const RulesFilter: FC = memo(() => {
  const {
    state: {
      filterValues,
      titleToInput,
      organizationOptions,
      systemsOptions,
      ticketTypesOptions,
      ticketPrioritiesOptions,
      environmentsOptions,
      clientsOptions,
    },
    methods: { control, resetFilter, formSubmitHandler },
  } = useRulesFilter();

  const tableBodyWrapper = (elements: JSX.Element[]) =>
    elements.map((item) => (
      <TableBodyCell filterCell key={item.key}>
        {item}
      </TableBodyCell>
    ));

  const filterElements = [
    <Input size={Size.xs} type="title" key="title" {...titleToInput} />,
    <Controller
      control={control}
      name="isActive"
      key="isActive"
      render={({ field }) => (
        <Select<boolean>
          size={Size.xs}
          mobileModalTitle="активность"
          options={ACTIVE_SELECT_OPTIONS}
          value={field.value}
          onChange={(value) => {
            field.onChange(value);
            formSubmitHandler();
          }}
          isTooltip={false}
        />
      )}
    />,
    <Controller
      control={control}
      name="organizations"
      key="organizations"
      render={({ field }) => (
        <Select
          size={Size.xs}
          mobileModalTitle="организация"
          options={organizationOptions}
          value={field.value}
          onChange={(value) => {
            field.onChange(value);
            formSubmitHandler();
          }}
          isMulti
        />
      )}
    />,
    <Controller
      control={control}
      name="systems"
      key="systems"
      render={({ field }) => (
        <Select
          size={Size.xs}
          mobileModalTitle="система"
          options={systemsOptions}
          value={field.value}
          onChange={(value) => {
            field.onChange(value);
            formSubmitHandler();
          }}
          isMulti
        />
      )}
    />,
    <Controller
      control={control}
      name="ticketTypes"
      key="ticketTypes"
      render={({ field }) => (
        <Select
          size={Size.xs}
          mobileModalTitle="тип тикета"
          options={ticketTypesOptions}
          value={field.value}
          onChange={(value) => {
            field.onChange(value);
            formSubmitHandler();
          }}
          isMulti
        />
      )}
    />,
    <Controller
      control={control}
      name="ticketPriorities"
      key="ticketPriorities"
      render={({ field }) => (
        <Select
          size={Size.xs}
          mobileModalTitle="приоритет"
          options={ticketPrioritiesOptions}
          value={field.value}
          onChange={(value) => {
            field.onChange(value);
            formSubmitHandler();
          }}
          isMulti
        />
      )}
    />,
    <Controller
      control={control}
      name="environments"
      key="environments"
      render={({ field }) => (
        <Select
          size={Size.xs}
          mobileModalTitle="среда"
          options={environmentsOptions}
          value={field.value}
          onChange={(value) => {
            field.onChange(value);
            formSubmitHandler();
          }}
          isMulti
        />
      )}
    />,
    <Controller
      control={control}
      name="clients"
      key="clients"
      render={({ field }) => (
        <Select
          size={Size.xs}
          mobileModalTitle="клиент"
          options={clientsOptions}
          value={field.value}
          onChange={(value) => {
            field.onChange(value);
            formSubmitHandler();
          }}
          isMulti
        />
      )}
    />,
  ];

  const filter = <>{tableBodyWrapper(filterElements)}</>;

  const disableReset = checkObjectIdentity(filterValues, {});

  return (
    <TableFilter
      filterComponent={filter}
      onReset={resetFilter}
      disableReset={disableReset}
    />
  );
});
