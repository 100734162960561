import {
  AddCurrentNotificationAction,
  DeleteAllCurrentNotificationsAction,
  DeleteCurrentNotificationAction,
  DeleteNotificationByIdAction,
  Notifications,
  SetActiveNotificationAction,
  SetActiveNotificationIdAction,
  SetAllNotificationsAction,
  SetNotificationsMyTicketsIdsAction,
  SetNotificationsNewTicketsIdsAction,
} from './actionTypes';
import { NotificationContent } from './types';

export const setAllNotifications = (
  notifications: NotificationContent[]
): SetAllNotificationsAction => ({
  type: Notifications.SET_ALL_NOTIFICATIONS,
  payload: notifications,
});

export const addCurrentNotification = (
  notification: NotificationContent
): AddCurrentNotificationAction => ({
  type: Notifications.ADD_CURRENT_NOTIFICATION,
  payload: notification,
});

export const deleteCurrentNotification = (
  entityId: string
): DeleteCurrentNotificationAction => ({
  type: Notifications.DELETE_CURRENT_NOTIFICATION,
  payload: entityId,
});

export const deleteNotificationById = (
  entityId: string
): DeleteNotificationByIdAction => ({
  type: Notifications.DELETE_NOTIFICATION_BY_ID,
  payload: entityId,
});

export const deleteAllCurrentNotifications =
  (): DeleteAllCurrentNotificationsAction => ({
    type: Notifications.DELETE_ALL_CURRENT_NOTIFICATIONS,
  });

export const setNotificationsMyTicketsIds = (
  ticketsIds: string[]
): SetNotificationsMyTicketsIdsAction => ({
  type: Notifications.SET_NOTIFICATIONS_MY_TICKERS_IDS,
  payload: ticketsIds,
});

export const setNotificationsNewTicketsIds = (
  ticketsIds: string[]
): SetNotificationsNewTicketsIdsAction => ({
  type: Notifications.SET_NOTIFICATIONS_NEW_TICKERS_IDS,
  payload: ticketsIds,
});

export const setActiveNotificationId = (
  id: string
): SetActiveNotificationIdAction => ({
  type: Notifications.SET_ACTIVE_NOTIFICATION_ID,
  payload: id,
});

export const setActiveNotification = (
  payload: NotificationContent
): SetActiveNotificationAction => ({
  type: Notifications.SET_ACTIVE_NOTIFICATION,
  payload,
});
