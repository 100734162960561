import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { resetContractsState, TableContractsTypes } from '@entities/contract';
import {
  fetchOrganizationsRequest,
  resetOrganizationState,
  setCurrentOrganizationId,
} from '@entities/organization';
import {
  resetWorkGroupsState,
  TableWorkGroupsTypes,
} from '@entities/work-group';
import { ContractsTableContainer } from '@features/contract';
import { OrganizationsTableContainer } from '@features/organization';
import { WorkGroupsTableContainer } from '@features/work-groups';
import { TablesLayout } from '@shared';

import styles from './Organizations.module.scss';

const SubTableFirst = () => (
  <ContractsTableContainer tableType={TableContractsTypes.FROM_ORGANIZATIONS} />
);

const SubTableSecond = () => (
  <WorkGroupsTableContainer
    tableType={TableWorkGroupsTypes.FROM_ORGANIZATIONS}
  />
);

export const Organizations = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrganizationsRequest());
    return () => {
      dispatch(setCurrentOrganizationId(''));
      dispatch(resetContractsState());
      dispatch(resetWorkGroupsState());
      dispatch(resetOrganizationState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[OrganizationsTableContainer]}
      SubTable={[SubTableFirst, SubTableSecond]}
      classNameRoot={styles.organizations}
    />
  );
};
