import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  getIsReportAccess,
  getIsReportClients,
  getUserOrganization,
} from '@entities/auth';
import {
  CurrentStateFormData,
  DesktopState,
  fetchOrganizationsRequest,
  fetchOrganizationsWorkGroupRequest,
  fetchSystemsRequest,
  fetchTicketsRequestCurrentState,
  fetchTicketsStatisticRequestCurrentState,
  getTransformedTypeList,
  resetCurrentState,
  resetDesktopState,
  setDesktopFilters,
} from '@entities/desktop';
import { INITIAL_CURRENT_STATE_FORM_VALUES } from '@features/desktop/config';
import {
  checkPathnameWithTrailingSlash,
  getMultiOptionValues,
  getSelectOption,
  getValueFromValueType,
  RouterHref,
} from '@shared';

export const useCurrentStateForm = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { watch, control, setValue, reset } = useForm<CurrentStateFormData>({
    mode: 'onChange',
    defaultValues: INITIAL_CURRENT_STATE_FORM_VALUES,
  });

  const { organizationId, systemId, workGroupId, ticketTypesIds } = watch();

  const userOrganization = useSelector(getUserOrganization);
  const isReportClients = useSelector(getIsReportClients);
  const isReportAccess = useSelector(getIsReportAccess);
  const ticketTypesOptions = useSelector((state: DesktopState) =>
    getTransformedTypeList(state, systemId ?? [])
  );

  const isDesktopCurrentPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.DesktopCurrentState
  );

  const isFetchDesktopFilters =
    (!!workGroupId || !!organizationId) && isReportAccess;

  const isDeafultWorkGroupId =
    workGroupId === INITIAL_CURRENT_STATE_FORM_VALUES.workGroupId;

  const isDeafultOrganizationId =
    organizationId === INITIAL_CURRENT_STATE_FORM_VALUES.organizationId;

  const isCanFetchOrganizations =
    isDesktopCurrentPage && isDeafultWorkGroupId && isDeafultOrganizationId;

  const isCanFetchSystemsOrganizationsId =
    !!organizationId && !Array.isArray(organizationId);

  const isDisabledSystemCurrentsState = !organizationId;

  const resetFilterAndDesktopState = () => {
    dispatch(resetCurrentState());
    dispatch(resetDesktopState());
  };

  const allCurrentStateFieldReset = () => {
    resetFilterAndDesktopState();
    reset();
  };

  useEffect(() => {
    if (isCanFetchOrganizations) {
      dispatch(resetCurrentState());
      dispatch(resetDesktopState());
      dispatch(fetchOrganizationsRequest());
    }
  }, [isCanFetchOrganizations]);

  useEffect(() => {
    if (isCanFetchSystemsOrganizationsId) {
      dispatch(fetchSystemsRequest(organizationId?.value));
    }
  }, [isCanFetchSystemsOrganizationsId]);

  useEffect(() => {
    if (isReportAccess && !!workGroupId && !Array.isArray(workGroupId)) {
      dispatch(fetchOrganizationsWorkGroupRequest(workGroupId.value));
    }
  }, [isReportAccess, workGroupId]);

  useEffect(() => {
    if (!organizationId && isReportClients && userOrganization) {
      setValue('organizationId', getSelectOption(userOrganization));
    }
  }, [organizationId, isReportClients, userOrganization]);

  useEffect(() => {
    if (isFetchDesktopFilters) {
      dispatch(
        setDesktopFilters({
          workgroupId: getValueFromValueType(workGroupId),
          organizationId: getValueFromValueType(organizationId),
          systemId: getValueFromValueType(systemId),
          onlyActualTickets: true,
          ticketTypeIds: getMultiOptionValues(ticketTypesIds),
        })
      );
      dispatch(fetchTicketsStatisticRequestCurrentState());
      dispatch(fetchTicketsRequestCurrentState());
    }
  }, [
    isFetchDesktopFilters,
    workGroupId,
    organizationId,
    systemId,
    ticketTypesIds,
  ]);

  return {
    state: {
      ticketTypesCurrentStateOptions: ticketTypesOptions,
      isDisabledSystemCurrentsState,
    },
    methods: {
      currentStateControl: control,
      setCurrentStateValue: setValue,
      currentStateReset: allCurrentStateFieldReset,
    },
  };
};
