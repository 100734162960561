import { SelectOption } from '@shared';

import { META_ORGANIZATION } from '../../config/constants';
import { Organization, OrganizationType } from '../../model/types';

const mapEntitiesToSelectOptions = <
  T extends { title: string; id?: string; type: OrganizationType }
>(
  entities: T[]
): SelectOption[] =>
  entities.map(
    ({ title, id, type }: T): SelectOption => ({
      title,
      value: id || '',
      meta: META_ORGANIZATION[type],
    })
  );

export const mapOrganizationsToSelectOptions = (
  organizations: Organization[]
): SelectOption[] => mapEntitiesToSelectOptions(organizations);
