import { debounce } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  CreateSystemDataAdd,
  fetchSLAPatternsRequestContractDetail,
  getPatternActive,
  getTicketTypes,
  resetSLAPatternsContractDetail,
} from '@entities/contract';
import {
  getSlaTicketTypesFromSettings,
  SlaTable,
  SLAType,
} from '@entities/sla';
import { TicketTypeSettings } from '@entities/ticket';
import { DEFAULT_DEBOUNCE_DELAY, Input, Size } from '@shared';

import { getPreparedTicketTypes } from '../../../../../lib/utils';
import { SlaAutoClosing, SlaAutoClosingProps } from '../SlaAutoClosing';
import { SLAPatternsList } from '../SLAPatternsList/SLAPatternsList';

import styles from './SavedPatternsSla.module.scss';

interface SavedPatternsSlaProps {
  copyPatternToCreateMode?: (data: SLAType) => void;
  systemId?: string;
}

export const SavedPatternsSla: FC<SavedPatternsSlaProps> = ({
  copyPatternToCreateMode,
  systemId,
}) => {
  const dispatch = useDispatch();

  const activeSLA = useSelector(getPatternActive);
  const ticketTypes = useSelector(getTicketTypes);

  const [slaTicketTypes, setSlaTicketTypes] = useState<TicketTypeSettings[]>();

  useEffect(() => {
    if (activeSLA?.id) {
      setSlaTicketTypes(getSlaTicketTypesFromSettings(activeSLA?.settings));
      return;
    }
    setSlaTicketTypes(getPreparedTicketTypes(ticketTypes));
  }, [activeSLA]);

  const { register, handleSubmit, reset, watch } = useForm<{ title?: string }>({
    mode: 'onChange',
  });

  const {
    control,
    setValue,
    watch: watchAutoClosing,
  } = useForm<CreateSystemDataAdd>({
    mode: 'onChange',
    defaultValues: {
      expireReactionTimeInDecisionTime: false,
      autoClosableTickets: false,
    },
  });

  const { closeAfterHours, autoClosableTickets } = watchAutoClosing();

  const autoClothingProps: SlaAutoClosingProps = {
    control,
    setValue,
    isEditMode: false,
    closeAfterHours,
    autoClosableTickets,
    className: styles.savedPatternsSla__autoClosing,
  };

  useEffect(() => {
    if (activeSLA) {
      setValue('autoClosableTickets', activeSLA.autoClosableTickets);
      setValue('closeAfterHours', activeSLA.closeAfterHours);
      setValue(
        'expireReactionTimeInDecisionTime',
        activeSLA.expireReactionTimeInDecisionTime
      );
    }
  }, [activeSLA]);

  const { title: searchValue } = watch();

  const formSubmitHandler = handleSubmit((data) => {
    const { title } = data;
    if (title) {
      dispatch(fetchSLAPatternsRequestContractDetail(title));
    }
  });

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    [systemId]
  );

  const titleInputOptions = register('title', {
    onChange: formSubmitHandlerDebounced,
  });

  useEffect(() => {
    dispatch(fetchSLAPatternsRequestContractDetail());

    return () => {
      reset();
      dispatch(resetSLAPatternsContractDetail());
    };
  }, [systemId]);

  return (
    <div>
      <Input
        {...titleInputOptions}
        size={Size.s}
        label="Поиск"
        className={styles.savedPatternsSla__input}
        disabled={!systemId}
      />
      <div className={styles.savedPatternsSla__patterns}>
        <SLAPatternsList
          hasSearchValue={Boolean(searchValue)}
          copyPatternToCreateMode={copyPatternToCreateMode}
        />
      </div>
      <SlaTable
        slaSettings={activeSLA?.settings}
        slaTicketTypes={slaTicketTypes}
      />
      <SlaAutoClosing {...autoClothingProps} />
    </div>
  );
};
