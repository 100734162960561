import { FC } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';

import { CurrentStateFormData } from '@entities/desktop';
import { Select, SelectOption } from '@shared';

import { ResetFilterButton } from '../ResetFilterButton';

import styles from './CurrentStateForm.module.scss';

interface CurrentStateFormProps {
  control: Control<CurrentStateFormData>;
  setValue: UseFormSetValue<CurrentStateFormData>;
  managersOptions: SelectOption[];
  organizationsOptions: SelectOption[];
  systemsOptions: SelectOption[];
  isManagerSpecialistsWorkGroup: boolean;
  ticketTypesOptions: SelectOption[];
  isDisabledSystem?: boolean;
  reset: () => void;
}

export const CurrentStateForm: FC<CurrentStateFormProps> = (props) => {
  const {
    control,
    setValue,
    systemsOptions,
    organizationsOptions,
    managersOptions,
    isManagerSpecialistsWorkGroup,
    ticketTypesOptions,
    isDisabledSystem,
    reset,
  } = props;

  return (
    <>
      {isManagerSpecialistsWorkGroup && (
        <Controller
          control={control}
          name="workGroupId"
          render={({ field }) => (
            <Select<string>
              allowClear
              label="Рабочая группа"
              mobileModalTitle="рабочую группу"
              value={field.value}
              className={styles.currentStateForm__select}
              options={managersOptions}
              onChange={(value) => {
                setValue('organizationId', null);
                setValue('systemId', null);
                setValue('ticketTypesIds', []);
                field.onChange(value);
              }}
            />
          )}
        />
      )}

      <Controller
        control={control}
        name="organizationId"
        render={({ field }) => (
          <Select<string>
            allowClear
            label="Организация"
            mobileModalTitle="организацию"
            value={field.value}
            options={organizationsOptions}
            className={styles.currentStateForm__select}
            onChange={(value) => {
              setValue('systemId', null);
              setValue('ticketTypesIds', []);
              field.onChange(value);
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="systemId"
        render={({ field }) => (
          <Select<string>
            allowClear
            label="Система"
            mobileModalTitle="систему"
            value={field.value}
            className={styles.currentStateForm__select}
            options={systemsOptions}
            onChange={(value) => {
              setValue('ticketTypesIds', []);
              field.onChange(value);
            }}
            disabled={isDisabledSystem}
          />
        )}
      />
      <Controller
        control={control}
        name="ticketTypesIds"
        render={({ field }) => (
          <Select<string>
            allowClear
            label="Тип тикета"
            mobileModalTitle="тип тикета"
            value={field.value}
            className={styles.currentStateForm__select}
            options={ticketTypesOptions}
            onChange={(event) => {
              field.onChange(event);
            }}
            isMulti
          />
        )}
      />
      <ResetFilterButton reset={reset} />
    </>
  );
};
