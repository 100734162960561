import { Filter, TicketsFilterForRender } from '@entities/ticket';

import { getCurrentDate } from './getCurrentDate';

export const getPrepareFilterTicketsToRender = (
  filter: Filter
): TicketsFilterForRender => {
  const {
    statuses,
    specialists,
    systems,
    clients,
    priorities,
    dateSolveFrom,
    dateSolveTo,
    dateCreateFrom,
    dateCreateTo,
    types,
  } = filter;
  return {
    ...filter,
    statuses: statuses?.map((data) => ({
      title: data,
      value: data,
    })),
    specialistIds: specialists?.map((data) => ({
      title: data.value,
      value: data.key,
    })),
    systemIds: systems?.map((data) => ({
      title: data.value,
      value: data.key,
    })),
    clientIds: clients?.map((data) => ({
      title: data.value,
      value: data.key,
    })),
    priorities: priorities?.map((data) => ({
      title: data.value,
      value: data.key,
    })),
    dateSolve: getCurrentDate(dateSolveFrom, dateSolveTo),
    dateCreate: getCurrentDate(dateCreateFrom, dateCreateTo),
    typeIds: types?.map((data) => ({
      title: data.value,
      value: data.key,
    })),
  };
};
