import { FC } from 'react';

import { InfoBlock, InfoBlockProps } from '@shared';

import styles from './NotificationInfoBlock.module.scss';

type NotificationInfoBlockProps = InfoBlockProps;

export const NotificationInfoBlock: FC<NotificationInfoBlockProps> = ({
  ...props
}) => (
  <InfoBlock
    {...props}
    classNameIconWrapper={styles.notificationInfoBlock__icon}
    className={styles.notificationInfoBlock__block}
  />
);
