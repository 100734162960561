import {
  all,
  call,
  put,
  select,
  StrictEffect,
  takeEvery,
} from 'redux-saga/effects';

import { NotificationContent } from '@entities/notifications/model/types';
import { Storage } from '@entities/storage';
import { System } from '@entities/system';
import {
  ActionForAlertTypes,
  createError,
  createSuccessAlert,
  getErrorAlert,
  getIsMobile,
  getIsMobileSmall,
  getSuccessAlert,
  KeyValueOption,
  ResponseWithMeta,
  setAlert,
} from '@shared';

import { requests } from '../api';

import {
  checkHasUnpublishedNewsSuccess,
  checkNewsRequest,
  checkNewsSuccess,
  fetchCurrentNewsRequest,
  fetchCurrentNewsSuccess,
  fetchIsUncheckedNewsSuccess,
  fetchNewsJoinSuccess,
  fetchNewsRequest,
  fetchNewsSuccess,
  fetchNotificationSettingsSuccess,
  fetchNotificationsProfileJoinSuccess,
  fetchNotificationsProfileRequest,
  fetchNotificationsProfileSuccess,
  fetchNotificationTabsSuccess,
  fetchOrganizationsSuccess,
  fetchStorageRequest,
  fetchStorageSuccess,
  fetchSystemRequest,
  fetchSystemSuccess,
  fetchWorkGroupsRequest,
  fetchWorkGroupsSuccess,
  getIsUncheckedNotifications,
  hideCurrentNewsLoading,
  hideNewsLoading,
  hideNotificationsProfileLoading,
  newsCreate,
  newsDelete,
  newsEdit,
  resetNewsState,
  setCheckedNotificationRequest,
  setCheckedNotificationSuccess,
  setIsUncheckedNotificationsSuccess,
  showCurrentNewsLoading,
  showNewsLoading,
  showNotificationsProfileLoading,
  updateNotificationSettingsRequest,
} from './actions';
import { NotificationsProfile } from './actionTypes';
import {
  getFilterValues,
  getPropsNews,
  getPropsNotificationsProfile,
} from './selectors';
import {
  HasUncheckedNotificationsType,
  News,
  ProfileSettings,
  ProfileTabSettings,
} from './types';

const ENTITY_NEWS = 'Новость';

function* notificationsProfileFetch({
  payload,
}: ReturnType<typeof fetchNotificationsProfileRequest>) {
  try {
    yield put(showNotificationsProfileLoading());
    const { pageNum, updateType } = payload;
    const { pageSize, sort }: ReturnType<typeof getPropsNotificationsProfile> =
      yield select(getPropsNotificationsProfile);
    const filter: ReturnType<typeof getFilterValues> = yield select(
      getFilterValues
    );
    const content: ResponseWithMeta<NotificationContent[]> = yield call(
      requests.fetchNotificationProfile,
      pageNum,
      pageSize,
      sort,
      filter
    );
    yield put(
      updateType === 'update'
        ? fetchNotificationsProfileSuccess(content)
        : fetchNotificationsProfileJoinSuccess(content)
    );
    yield put(hideNotificationsProfileLoading());
  } catch (e) {
    createError(e);
    yield put(hideNotificationsProfileLoading());
  }
}

function* getIsUncheckedNotificationsProfile({
  payload,
}: ReturnType<typeof getIsUncheckedNotifications>) {
  try {
    const data: HasUncheckedNotificationsType[] = yield call(
      requests.getIsUncheckedNotificationProfile,
      payload
    );
    yield put(setIsUncheckedNotificationsSuccess(data));
  } catch (e) {
    createError(e);
  }
}

function* fetchNotificationTabs() {
  try {
    const notificationTabs: ProfileTabSettings[] = yield call(
      requests.fetchNotificationTabs
    );
    const allNotificationTabs = [
      {
        titleNotification: 'ALL',
        tabGroup: ' ',
        description: 'Все уведомления',
      },
      ...notificationTabs,
    ];
    yield all([
      put(fetchNotificationTabsSuccess(allNotificationTabs)),
      put(
        getIsUncheckedNotifications(
          allNotificationTabs?.map((item) => ({
            notificationType:
              item?.titleNotification?.toLocaleLowerCase() === 'all'
                ? null
                : item?.titleNotification,
          }))
        )
      ),
    ]);
  } catch (e) {
    createError(e);
  }
}

function* setAllNotificationsChecked() {
  try {
    yield call(requests.setAllCheckedNotificationProfile);
    yield all([
      call(notificationsProfileFetch, {
        type: NotificationsProfile.FETCH_NOTIFICATIONS_PROFILE_REQUEST,
        payload: { updateType: 'update', pageNum: 0 },
      }),
      call(fetchNotificationTabs),
    ]);
  } catch (e) {
    createError(e);
  }
}

function* setCheckedNotification({
  payload,
}: ReturnType<typeof setCheckedNotificationRequest>) {
  try {
    yield call(requests.setCheckedNotification, payload);
    yield put(setCheckedNotificationSuccess(payload));
    yield call(fetchNotificationTabs);
  } catch (e) {
    createError(e);
  }
}
function* fetchNotificationSettings() {
  try {
    const notificationSettings: ProfileSettings = yield call(
      requests.fetchNotificationSettings
    );
    yield put(fetchNotificationSettingsSuccess(notificationSettings));
  } catch (e) {
    createError(e);
  }
}

function* updateNotificationSettings({
  payload,
}: ReturnType<typeof updateNotificationSettingsRequest>) {
  try {
    yield put(fetchNotificationSettingsSuccess(payload));
    yield call(requests.updateNotificationSettings, payload);
  } catch (e) {
    createError(e);
  }
}

function* currentNewsFetch({
  payload,
}: ReturnType<typeof fetchCurrentNewsRequest>) {
  try {
    yield put(showCurrentNewsLoading());
    const currentNews: News = yield call(requests.fetchCurrentNews, payload);
    yield put(fetchCurrentNewsSuccess(currentNews));
    yield put(hideCurrentNewsLoading());
  } catch (e) {
    yield put(hideCurrentNewsLoading());
    createError(e);
  }
}

function* fetchIsUncheckedNews() {
  try {
    const isUncheckedNews: boolean = yield call(requests.fetchIsUnchecked);
    yield put(fetchIsUncheckedNewsSuccess(isUncheckedNews));
  } catch (e) {
    createError(e);
  }
}

function* checkHasUnpublishedNews() {
  try {
    const hasUnpublishedNews: boolean = yield call(
      requests.checkHasUnpublishedNews
    );
    yield put(checkHasUnpublishedNewsSuccess(hasUnpublishedNews));
  } catch (e) {
    createError(e);
  }
}

function* checkNews({ payload }: ReturnType<typeof checkNewsRequest>) {
  try {
    yield call(requests.checkNews, payload);
    yield put(checkNewsSuccess(payload));
    yield call(fetchIsUncheckedNews);
  } catch (e) {
    createError(e);
  }
}

function* newsFetch({ payload }: ReturnType<typeof fetchNewsRequest>) {
  try {
    const isMobile: ReturnType<typeof getIsMobile> = yield select(getIsMobile);
    const isMobileSmall: ReturnType<typeof getIsMobileSmall> = yield select(
      getIsMobileSmall
    );
    const isMobileAll = isMobile || isMobileSmall;

    yield put(showNewsLoading());
    const { pageNum, updateType, published } = payload;
    const { pageSize, sort }: ReturnType<typeof getPropsNews> = yield select(
      getPropsNews
    );
    const news: ResponseWithMeta<News[]> = yield call(requests.fetchNews, {
      pageNum,
      pageSize,
      sort,
      published,
    });

    const firstNews = news.content[0];
    const firstNewsId = firstNews.id;
    const isFirstPage = pageNum === 0;

    if (isFirstPage && firstNewsId && !isMobileAll) {
      yield call(currentNewsFetch, {
        type: NotificationsProfile.CURRENT_NEWS_FETCH_REQUEST,
        payload: firstNewsId,
      });
      if (!firstNews.checked) {
        yield call(checkNews, {
          type: NotificationsProfile.CHECK_NEWS_REQUEST,
          payload: firstNewsId,
        });
      }
    }

    yield put(
      updateType === 'update'
        ? fetchNewsSuccess(news)
        : fetchNewsJoinSuccess(news)
    );
    yield put(hideNewsLoading());
  } catch (e) {
    createError(e);
    yield put(hideNewsLoading());
  }
}

function* newsCreateFunction({ payload }: ReturnType<typeof newsCreate>) {
  try {
    const { published, ...data } = payload;

    const news: News = yield call(requests.createNews, data);
    yield put(
      setAlert(
        createSuccessAlert(`Успешно выполнено создание новости "${news.title}"`)
      )
    );
    yield put(resetNewsState());
    yield call(newsFetch, {
      type: NotificationsProfile.FETCH_NEWS_REQUEST,
      payload: { updateType: 'update', pageNum: 0, published },
    });
    yield call(checkHasUnpublishedNews);
  } catch (e) {
    yield put(setAlert(getErrorAlert(ENTITY_NEWS, ActionForAlertTypes.CREATE)));
    createError(e);
  }
}

function* newsEditFunction({ payload }: ReturnType<typeof newsEdit>) {
  try {
    const { published, ...data } = payload;

    const news: News = yield call(requests.editNews, data);
    yield put(
      setAlert(
        createSuccessAlert(
          `Успешно выполнено редактирование новости "${news.title}"`
        )
      )
    );
    yield put(resetNewsState());
    yield call(newsFetch, {
      type: NotificationsProfile.FETCH_NEWS_REQUEST,
      payload: { updateType: 'update', pageNum: 0, published },
    });
    yield call(checkHasUnpublishedNews);
  } catch (e) {
    yield put(setAlert(getErrorAlert(ENTITY_NEWS, ActionForAlertTypes.EDIT)));
    createError(e);
  }
}

function* newsDeleteFunction({ payload }: ReturnType<typeof newsDelete>) {
  try {
    const { published, id } = payload;

    yield call(requests.deleteNews, id);
    yield put(
      setAlert(getSuccessAlert(ENTITY_NEWS, ActionForAlertTypes.DELETE))
    );
    yield put(resetNewsState());
    yield call(newsFetch, {
      type: NotificationsProfile.FETCH_NEWS_REQUEST,
      payload: { updateType: 'update', pageNum: 0, published },
    });
    yield call(checkHasUnpublishedNews);
  } catch (e) {
    yield put(setAlert(getErrorAlert(ENTITY_NEWS, ActionForAlertTypes.DELETE)));
    createError(e);
  }
}

function* fetchOrganizations() {
  try {
    const organizations: KeyValueOption[] = yield call(
      requests.fetchOrganizations
    );
    yield put(fetchOrganizationsSuccess(organizations));
  } catch (e) {
    createError(e);
  }
}

function* fetchWorkGroups({
  payload,
}: ReturnType<typeof fetchWorkGroupsRequest>) {
  try {
    const workGroups: KeyValueOption[] = yield call(
      requests.fetchWorkGroups,
      payload
    );
    yield put(fetchWorkGroupsSuccess(workGroups));
  } catch (e) {
    createError(e);
  }
}

function* fetchSystem({ payload }: ReturnType<typeof fetchSystemRequest>) {
  try {
    const system: System = yield call(requests.fetchSystemById, payload);
    yield put(fetchSystemSuccess(system));
  } catch (e) {
    createError(e);
  }
}

function* fetchStorage({ payload }: ReturnType<typeof fetchStorageRequest>) {
  try {
    const storage: Storage = yield call(requests.fetchStorageById, payload);
    yield put(fetchStorageSuccess(storage));
  } catch (e) {
    createError(e);
  }
}

export function* notificationsProfileSaga(): Generator<StrictEffect> {
  yield takeEvery(
    NotificationsProfile.FETCH_NOTIFICATIONS_PROFILE_REQUEST,
    notificationsProfileFetch
  );
  yield takeEvery(
    NotificationsProfile.SET_ALL_CHECKED_NOTIFICATIONS_REQUEST,
    setAllNotificationsChecked
  );
  yield takeEvery(
    NotificationsProfile.GET_IS_UNCHECKED_STATUSES_REQUEST,
    getIsUncheckedNotificationsProfile
  );
  yield takeEvery(
    NotificationsProfile.SET_CHECKED_NOTIFICATION_REQUEST,
    setCheckedNotification
  );
  yield takeEvery(
    NotificationsProfile.FETCH_NOTIFICATION_TABS_REQUEST,
    fetchNotificationTabs
  );
  yield takeEvery(
    NotificationsProfile.FETCH_NOTIFICATION_SETTINGS_REQUEST,
    fetchNotificationSettings
  );
  yield takeEvery(
    NotificationsProfile.UPDATE_NOTIFICATION_SETTINGS_REQUEST,
    updateNotificationSettings
  );
  yield takeEvery(NotificationsProfile.FETCH_NEWS_REQUEST, newsFetch);
  yield takeEvery(NotificationsProfile.NEWS_CREATE, newsCreateFunction);
  yield takeEvery(NotificationsProfile.NEWS_EDIT, newsEditFunction);
  yield takeEvery(NotificationsProfile.NEWS_DELETE, newsDeleteFunction);
  yield takeEvery(
    NotificationsProfile.CURRENT_NEWS_FETCH_REQUEST,
    currentNewsFetch
  );
  yield takeEvery(
    NotificationsProfile.FETCH_IS_UNCHECKED_NEWS_REQUEST,
    fetchIsUncheckedNews
  );
  yield takeEvery(NotificationsProfile.CHECK_NEWS_REQUEST, checkNews);
  yield takeEvery(NotificationsProfile.FETCH_ORGANIZATIONS, fetchOrganizations);
  yield takeEvery(
    NotificationsProfile.FETCH_WORK_GROUPS_REQUEST,
    fetchWorkGroups
  );
  yield takeEvery(
    NotificationsProfile.CHECK_HAS_UNPUBLISHED_NEWS_REQUEST,
    checkHasUnpublishedNews
  );
  yield takeEvery(NotificationsProfile.FETCH_SYSTEM_REQUEST, fetchSystem);
  yield takeEvery(NotificationsProfile.FETCH_STORAGE_REQUEST, fetchStorage);
}
