import {
  CreateWorkGroupData,
  WorkGroup,
  WorkGroupFilter,
} from '@entities/work-group/model';
import { DeleteDataType, ResponseWithMeta } from '@shared';

import {
  CreateWorkGroupAction,
  DeleteWorkGroupRequestAction,
  EditWorkGroupAction,
  FetchCurrentWorkGroupSuccessAction,
  FetchGroupsByOrganizationRequestAction,
  FetchGroupsByUserIdRequestAction,
  FetchWorkGroupRequestAction,
  FetchWorkGroupsFromMyOrgRequestAction,
  FetchWorkGroupsFromMyOrgSuccessAction,
  FetchWorkGroupsRequestAction,
  FetchWorkGroupsSuccessAction,
  HideWorkGroupLoadingAction,
  HideWorkGroupsAddLoadingAction,
  HideWorkGroupsLoadingAction,
  ResetCurrentWorkGroupAction,
  ResetWorkGroupsAddStateAction,
  ResetWorkGroupsStateAction,
  SetCurrentGroupsPageAction,
  SetGroupsAddPageAction,
  SetGroupTitleAction,
  SetSizePageAction,
  SetSizePageGroupsAddAction,
  SetSortGroupsAction,
  SetWorkGroupFilterAction,
  SetWorkGroupIdAction,
  ShowWorkGroupLoadingAction,
  ShowWorkGroupsAddLoadingAction,
  ShowWorkGroupsLoadingAction,
  UpdateWorkGroupAction,
  WorkGroups,
} from './actionTypes';

export const showWorkGroupsLoading = (): ShowWorkGroupsLoadingAction => ({
  type: WorkGroups.WORK_GROUPS_LOADING_SHOW,
});

export const hideWorkGroupsLoading = (): HideWorkGroupsLoadingAction => ({
  type: WorkGroups.WORK_GROUPS_LOADING_HIDE,
});

export const showWorkGroupLoading = (): ShowWorkGroupLoadingAction => ({
  type: WorkGroups.WORK_GROUP_LOADING_SHOW,
});

export const hideWorkGroupLoading = (): HideWorkGroupLoadingAction => ({
  type: WorkGroups.WORK_GROUP_LOADING_HIDE,
});

export const showWorkGroupsAddLoading = (): ShowWorkGroupsAddLoadingAction => ({
  type: WorkGroups.WORK_GROUPS_ADD_LOADING_SHOW,
});

export const hideWorkGroupsAddLoading = (): HideWorkGroupsAddLoadingAction => ({
  type: WorkGroups.WORK_GROUPS_ADD_LOADING_HIDE,
});

export const fetchWorkGroupsRequest = (): FetchWorkGroupsRequestAction => ({
  type: WorkGroups.FETCH_WORK_GROUPS_REQUEST,
});

export const fetchWorkGroupsSuccess = (
  data: ResponseWithMeta<WorkGroup[]>
): FetchWorkGroupsSuccessAction => ({
  type: WorkGroups.FETCH_WORK_GROUPS_SUCCESS,
  payload: data,
});

export const setCurrentWorkGroupsPage = (
  page: number
): SetCurrentGroupsPageAction => ({
  type: WorkGroups.SET_CURRENT_GROUPS_PAGE,
  payload: page,
});

export const setSizePage = (size: number): SetSizePageAction => ({
  type: WorkGroups.SET_SIZE_PAGE_WORK_GROUPS,
  payload: size,
});

export const setGroupsAddPage = (page: number): SetGroupsAddPageAction => ({
  type: WorkGroups.SET_GROUPS_ADD_PAGE,
  payload: page,
});

export const setSizePageGroupsAdd = (
  size: number
): SetSizePageGroupsAddAction => ({
  type: WorkGroups.SET_SIZE_PAGE_GROUPS_ADD,
  payload: size,
});

export const setSortWorkGroups = (sort: string): SetSortGroupsAction => ({
  type: WorkGroups.SET_SORT_GROUPS,
  payload: sort,
});

export const resetWorkGroupsState = (): ResetWorkGroupsStateAction => ({
  type: WorkGroups.RESET_WORK_GROUPS_STATE,
});

export const fetchGroupsByOrganizationId =
  (): FetchGroupsByOrganizationRequestAction => ({
    type: WorkGroups.FETCH_WORK_GROUPS_BY_ORGANIZATION_ID_REQUEST,
  });

export const fetchCurrentWorkGroup = (
  id: string
): FetchWorkGroupRequestAction => ({
  type: WorkGroups.FETCH_WORK_GROUP_REQUEST,
  payload: id,
});

export const fetchCurrentWorkGroupSuccess = (
  data?: WorkGroup
): FetchCurrentWorkGroupSuccessAction => ({
  type: WorkGroups.FETCH_WORK_GROUP_SUCCESS,
  payload: data,
});

export const createWorkGroup = (
  data: CreateWorkGroupData
): CreateWorkGroupAction => ({
  type: WorkGroups.CREATE_WORK_GROUP,
  payload: data,
});

export const editWorkGroup = (
  data: CreateWorkGroupData
): EditWorkGroupAction => ({
  type: WorkGroups.EDIT_WORK_GROUP,
  payload: data,
});

export const fetchGroupsByUserIdRequest =
  (): FetchGroupsByUserIdRequestAction => ({
    type: WorkGroups.FETCH_WORK_GROUPS_BY_USERID_REQUEST,
  });

export const setWorkGroupId = (id: string): SetWorkGroupIdAction => ({
  type: WorkGroups.SET_WORK_GROUP_ID,
  payload: id,
});

export const setGroupTitle = (title?: string): SetGroupTitleAction => ({
  type: WorkGroups.SET_WORK_GROUP_TITLE,
  payload: title,
});

export const updateWorkGroup = (data: WorkGroup): UpdateWorkGroupAction => ({
  type: WorkGroups.UPDATE_WORK_GROUP,
  payload: data,
});

export const setWorkGroupsFilter = (
  data: WorkGroupFilter
): SetWorkGroupFilterAction => ({
  type: WorkGroups.SET_WORK_GROUP_FILTER,
  payload: data,
});

export const setWorkGroupsAddFilter = (data: WorkGroupFilter) => ({
  type: WorkGroups.SET_WORK_GROUP_ADD_FILTER,
  payload: data,
});

export const fetchWorkGroupsAddRequest = () => ({
  type: WorkGroups.FETCH_WORK_GROUPS_BY_ORG_ID_BY_SYSTEM_ID_ADD_REQUEST,
});

export const fetchWorkGroupsAddSuccess = (
  data: ResponseWithMeta<WorkGroup[]>
) => ({
  type: WorkGroups.FETCH_WORK_GROUPS_BY_ORG_ID_BY_SYSTEM_ID_ADD_SUCCESS,
  payload: data,
});

export const fetchWorkGroupsByContractBySystemRequest = () => ({
  type: WorkGroups.FETCH_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM_REQUEST,
});

export const resetWorkGroupsAddState = (): ResetWorkGroupsAddStateAction => ({
  type: WorkGroups.RESET_WORK_GROUPS_ADD_STATE,
});

export const fetchWorkGroupsFromMyOrgRequest =
  (): FetchWorkGroupsFromMyOrgRequestAction => ({
    type: WorkGroups.FETCH_WORK_GROUPS_FROM_MY_ORG_REQUEST,
  });

export const fetchWorkGroupsFromMyOrgSuccess = (
  workGroups: WorkGroup[]
): FetchWorkGroupsFromMyOrgSuccessAction => ({
  type: WorkGroups.FETCH_WORK_GROUPS_FROM_MY_ORG_SUCCESS,
  payload: workGroups,
});

export const deleteWorkGroupRequest = (
  data: DeleteDataType
): DeleteWorkGroupRequestAction => ({
  type: WorkGroups.DELETE_WORK_GROUP_REQUEST,
  payload: data,
});

export const resetCurrentWorkGroup = (): ResetCurrentWorkGroupAction => ({
  type: WorkGroups.RESET_CURRENT_WORK_GROUP,
});
