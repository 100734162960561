import { createSelector } from 'reselect';

import { Organization } from '@entities/organization/model/types';
import { System } from '@entities/system/model';
import { getArraySelectOptions, SelectOption } from '@shared';

import { ContractDetailState } from './types';

export const getLoadingContractDetail = (
  state: ContractDetailState
): boolean | undefined => state.contractDetail.contractLoading;
export const getLoadingSupplementaryAgreements = (
  state: ContractDetailState
): boolean | undefined => state.contractDetail.supplementaryAgreementsLoading;
export const getContractDetail = (state: ContractDetailState) =>
  state.contractDetail.contract;
export const getProlongationContract = (state: ContractDetailState) =>
  state.contractDetail.prolongationContract;
export const getSystemSelected = (state: ContractDetailState) =>
  state.contractDetail.selectedSystem;
export const getSystemsTransfer = (state: ContractDetailState) =>
  state.contractDetail.systemsTransfer || {};
export const getSystemsForTransfer = (state: ContractDetailState) =>
  state.contractDetail.systemsTransfer?.systems;
export const getSupportType = (state: ContractDetailState) =>
  state.contractDetail.supportType;

export const getTransferSystemIds = createSelector<
  ContractDetailState,
  System[] | undefined,
  string[]
>(
  [getSystemsForTransfer],
  (systems) => systems?.map((system) => system.id) || []
);

export const getPropsSupplementaryAgreements = (
  state: ContractDetailState
) => ({
  pageNum: state?.contractDetail.supplementaryAgreementsPagination?.pageNum,
  pageSize: state.contractDetail.supplementaryAgreementsPagination?.pageSize,
  loadingSA: getLoadingSupplementaryAgreements(state),
  sortSA: state.contractDetail.supplementaryAgreementsSort,
  totalElements:
    state.contractDetail.supplementaryAgreementsPagination?.totalElements,
});

export const getSystemsForAddingContractDetail = (state: ContractDetailState) =>
  state.contractDetail.systems;
export const getSystemsForAddingToContractLoadingContractDetail = (
  state: ContractDetailState
) => state.contractDetail.systemsForAddingToContractLoading;

export const getSystemsForAddingSAContractDetail = (
  state: ContractDetailState
) => state.contractDetail.systemsForSA;
export const getSystemsForAddingSALoadingContractDetail = (
  state: ContractDetailState
) => state.contractDetail.systemsFofSALoading;

export const getSystemsByContractIdSaved = (state: ContractDetailState) =>
  state.contractDetail.systemsByContractIdSaved;
export const getSystemsLoadingContractDetail = (state: ContractDetailState) =>
  state.contractDetail.systemsByContractIdSavedLoading;
export const getPropsSystemsContractDetail = (state: ContractDetailState) => ({
  pageNum: state.contractDetail.systemsByContractIdSavedPagination?.pageNum,
  sort: state.contractDetail.systemsByContractIdSavedSort,
  pageSize: state.contractDetail.systemsByContractIdSavedPagination?.pageSize,
  totalElements:
    state.contractDetail.systemsByContractIdSavedPagination?.totalElements,
  loading: getSystemsLoadingContractDetail(state),
});
export const getSystemsFilterContractDetail = (state: ContractDetailState) =>
  state.contractDetail.systemsByContractIdSavedFilter || {};
export const getActiveSystemIdContractDetail = (state: ContractDetailState) =>
  state.contractDetail.systemIdActive;

export const getSupplementaryAgreementsContractDetail = (
  state: ContractDetailState
) => state.contractDetail.supplementaryAgreements;
export const getSystemIdContractDetail = (state: ContractDetailState) =>
  state.contractDetail.systemId;

export const getOrganizationsContractDetail = (
  state: ContractDetailState
): Organization[] | undefined => state.contractDetail.organizations;

export const getLoadingOrganizationsContractDetail = (
  state: ContractDetailState
): boolean | undefined => state.contractDetail.organizationsLoading;

export const getSelectedOrganizationContractDetail = (
  state: ContractDetailState
) => state?.contractDetail.organizationSelected;
export const getFilterValuesOrganizationsContractDetail = (
  state: ContractDetailState
) => state.contractDetail.organizationsFilter;

export const getOrganizationsSelectListContractDetail = createSelector<
  ContractDetailState,
  Organization[] | undefined,
  SelectOption[]
>([getOrganizationsContractDetail], (organizations): SelectOption[] =>
  getArraySelectOptions(organizations)
);

export const getPropsOrganizationsContractDetail = (
  state: ContractDetailState
) => ({
  pageNum: state.contractDetail.organizationsPagination?.pageNum,
  pageSize: state.contractDetail.organizationsPagination?.pageSize,
  loadingOrganization: getLoadingOrganizationsContractDetail(state),
  sortOrganizations: state?.contractDetail.organizationsSort,
  totalElements: state.contractDetail.organizationsPagination?.totalElements,
});

export const getPartnerOrganizationsContractDetail = (
  state: ContractDetailState
) => state.contractDetail.partnerOrganizations;
export const getLoadingPartnerOrganizationsContractDetail = (
  state: ContractDetailState
) => state.contractDetail.partnerOrganizationsLoading;

export const getPartnerOrganizationsOptionsContractDetail = createSelector<
  ContractDetailState,
  Organization[] | undefined,
  SelectOption[]
>([getPartnerOrganizationsContractDetail], (organizations) =>
  getArraySelectOptions(organizations)
);

export const getUsersContractDetail = (state: ContractDetailState) =>
  state.contractDetail.users;
export const getUsersLoadingContractDetail = (state: ContractDetailState) =>
  state.contractDetail.usersLoading;
export const getPropsUsersContractDetail = (state: ContractDetailState) => ({
  pageNum: state.contractDetail.usersPagination?.pageNum,
  pageSize: state.contractDetail.usersPagination?.pageSize,
  totalElements: state.contractDetail.usersPagination?.totalElements,
  loadingUsers: getUsersLoadingContractDetail(state),
});
export const getUsersFilterContractDetail = (state: ContractDetailState) =>
  state.contractDetail.usersFilter || {};

export const getAttachmentsContractDetail = (state: ContractDetailState) =>
  state.contractDetail.attachments;
export const getLoadingAttachmentsContractDetail = (
  state: ContractDetailState
) => state.contractDetail.attachmentsLoading;

export const getGroupsByContractBySystemContractDetail = (
  state: ContractDetailState
) => state.contractDetail.groups;
export const getEnvironmentsBySystem = (state: ContractDetailState) =>
  state.contractDetail.environments;
export const getEnvironmentsFilterBySystem = (state: ContractDetailState) =>
  state.contractDetail.environmentsFilter || {};
export const getPropsEnvironmentsBySystem = (state: ContractDetailState) => ({
  pageNum: state.contractDetail.environmentsPagination?.pageNum,
  pageSize: state.contractDetail.environmentsPagination?.pageSize,
  totalElements: state.contractDetail.environmentsPagination?.totalElements,
  sort: state.contractDetail.environmentsSort,
  loading: state.contractDetail.environmentsLoading,
});
export const getEnvironmentsSettingsBySystem = (state: ContractDetailState) =>
  state.contractDetail.environmentSettings;
export const getGroupsLoadingByContractBySystemContractDetail = (
  state: ContractDetailState
) => state.contractDetail.groupsLoading;
export const getGroupsFilterByContractBySystemContractDetail = (
  state: ContractDetailState
) => state.contractDetail.groupsFilter || {};
export const getPropsGroupsContractDetail = (state: ContractDetailState) => ({
  pageNum: state.contractDetail.groupsPagination?.pageNum,
  pageSize: state.contractDetail.groupsPagination?.pageSize,
  totalElements: state.contractDetail.groupsPagination?.totalElements,
  sort: state.contractDetail.groupsSort,
  loading: getGroupsLoadingByContractBySystemContractDetail(state),
});

export const getGroupsByContractContractDetail = (state: ContractDetailState) =>
  state.contractDetail.groupsByContract;
export const getGroupsLoadingByContractContractDetail = (
  state: ContractDetailState
) => state.contractDetail.groupsByContractLoading;
export const getGroupsFilterByContractContractDetail = (
  state: ContractDetailState
) => state.contractDetail.groupsByContractFilter;
export const getPropsGroupsByContractContractDetail = (
  state: ContractDetailState
) => ({
  pageNum: state.contractDetail.groupsByContractPagination?.pageNum,
  pageSize: state.contractDetail.groupsByContractPagination?.pageSize,
  totalElements: state.contractDetail.groupsByContractPagination?.totalElements,
  sort: state.contractDetail.groupsByContractSort,
  loading: getGroupsLoadingByContractContractDetail(state),
});

export const getSLAPatterns = (state: ContractDetailState) =>
  state.contractDetail.slaPatterns;
export const getSLAPatternsLoading = (state: ContractDetailState) =>
  state.contractDetail.slaPatternsLoading;
export const getIsPatternTitleExist = (state: ContractDetailState) =>
  state.contractDetail.isPatternTitleExist;
export const getPatternActive = (state: ContractDetailState) =>
  state.contractDetail.slaPatternActive;

export const getSLAByContractSystemContractDetail = (
  state: ContractDetailState
) => state.contractDetail.slaByContractBySystem;
export const getSLAByContractSystemLoadingContractDetail = (
  state: ContractDetailState
) => state.contractDetail.slaByContractBySystemLoading;

export const getTicketTypesSettings = (state: ContractDetailState) =>
  state.contractDetail.ticketTypesSettings || [];
export const getTicketTypesSettingsLoading = (state: ContractDetailState) =>
  state.contractDetail.ticketTypesSettingsLoading;
export const getTicketTypes = (state: ContractDetailState) =>
  state.contractDetail.ticketTypes || [];
export const getSelectedSystemSla = (state: ContractDetailState) =>
  state.contractDetail.selectedSystemSla;

export const getContractPermission = (state: ContractDetailState) =>
  state.contractDetail.contractPermission;
