import {
  CreateSystemDataAdd,
  SLAChoiceType,
  SupportType,
} from '@entities/contract';
import { RenderTypes, TableColumns } from '@shared';

export const TABLE_HEADER_FROM_SUPPLEMENTARY_AGREEMENT: TableColumns = [
  {
    title: 'Название',
    fieldName: 'title',
    sortField: 'title',
    renderType: RenderTypes.NOTIFICATION_LINK,
  },
  {
    title: 'Система',
    fieldName: 'systemTitle',
  },
  {
    title: 'Срок поддержки',
    fieldName: 'supportPeriod',
  },
];

export const DEFAULT_CONTRACT_FORM_VALUES = {
  openDate: '',
  endDate: '',
  supportType: SupportType.EXTENDED,
  notificationConfig: {
    contractExpireIsEnable: false,
    notifyBeforeExpire: 0,
  },
};

export const ENVIRONMENT_MAINS = ['TEST', 'DEV', 'PROD'];

export const DEFAULT_CREATE_SLA_FORM_VALUES: CreateSystemDataAdd = {
  workingHoursFrom: '',
  workingHoursTo: '',
  autoClosableTickets: false,
  closeAfterHours: undefined,
  expireReactionTimeInDecisionTime: false,
  systemId: undefined,
  createType: SLAChoiceType.CREATE_NEW,
};
