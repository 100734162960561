import {
  DefaultFilterValuesProps,
  TicketsFilterForRender,
} from '@entities/ticket';

import { DEFAULT_TICKETS_FILTER_VALUES } from '../../../config/tickets';

export const getDefaultFilterValues = ({
  userId,
  userWorkGroupsIds,
  isMyTicketsPage,
  isAllTicketsPage,
  contractIds,
  userManagerWorkGroupsIds,
  isGroupsTicketsPage,
}: DefaultFilterValuesProps): TicketsFilterForRender => {
  const currentUserIdArray = userId ? [userId] : [];

  if (isMyTicketsPage) {
    return {
      ...DEFAULT_TICKETS_FILTER_VALUES,
      memberIds: currentUserIdArray,
    };
  }
  if (isAllTicketsPage) {
    return {
      ...DEFAULT_TICKETS_FILTER_VALUES,
      workGroupIds: userWorkGroupsIds,
    };
  }
  if (isGroupsTicketsPage) {
    return {
      ...DEFAULT_TICKETS_FILTER_VALUES,
      workGroupIds: userManagerWorkGroupsIds,
      onlyActive: true,
    };
  }
  return {
    ...DEFAULT_TICKETS_FILTER_VALUES,
    contractIds,
  };
};
