import { FC } from 'react';

import {
  NotificationContent,
  NotificationTypeEnum,
} from '@entities/notifications';
import { System } from '@entities/system';
import {
  DocumentProductIcon,
  HashtagIcon,
  InfoBlock,
  UsersGroupIcon,
} from '@shared';

import { NotificationInfoBlock } from '../NotificationInfoBlock';
import { NotificationInformationLayout } from '../NotificationInformationLayout';

import styles from './NotificationSystemInformation.module.scss';

type NotificationSystemInformationProps = {
  activeNotification?: NotificationContent;
  system?: System;
};

export const NotificationSystemInformation: FC<
  NotificationSystemInformationProps
> = ({ activeNotification, system }) => {
  const { notificationMessage, linkName, notificationType } =
    activeNotification || {};

  const isPublishedNews =
    notificationType === NotificationTypeEnum.PUBLISHED_NEWS;
  const isSystemIndexChange =
    notificationType === NotificationTypeEnum.SYSTEM_INDEX_CHANGE;

  return (
    <NotificationInformationLayout
      title={
        isPublishedNews
          ? activeNotification?.notificationTitle ?? ''
          : `Система ${linkName}`
      }
      description={notificationMessage}
    >
      {isSystemIndexChange && (
        <div className={styles.notificationSystemInformation__content}>
          <InfoBlock
            mainTitle="Версия"
            info={system?.versionTitle}
            icon={
              <DocumentProductIcon
                className={styles.notificationSystemInformation__iconInner}
              />
            }
            classNameIconWrapper={styles.notificationSystemInformation__icon}
          />
          <div className={styles.notificationSystemInformation__infoBlocks}>
            <NotificationInfoBlock
              mainTitle="Организация"
              info={system?.organization?.title}
              icon={
                <UsersGroupIcon
                  className={styles.notificationSystemInformation__icon}
                />
              }
            />
            <NotificationInfoBlock
              mainTitle="Индекс"
              info={system?.index}
              icon={
                <HashtagIcon
                  className={styles.notificationSystemInformation__icon}
                />
              }
            />
          </div>
        </div>
      )}
    </NotificationInformationLayout>
  );
};
