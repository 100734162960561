import { isBoolean, isString } from 'lodash';

import { ACTIVE_SELECT_OPTIONS } from '@shared/ui/Table';

import { TableCustomFieldValue } from '../../model';

import { getReverseDateFromCustomField } from './getReverseDateFromCustomField';

export const getValueFromFieldFlag = (value: TableCustomFieldValue) => {
  if (isBoolean(value)) {
    return value ? ACTIVE_SELECT_OPTIONS[0] : ACTIVE_SELECT_OPTIONS[1];
  }
  return null;
};

export const getValueFromFieldText = (value?: TableCustomFieldValue) =>
  isString(value) ? value : '';

export const getValueFromFieldDate = (value?: TableCustomFieldValue) =>
  getReverseDateFromCustomField(getValueFromFieldText(value));
