import { Attachment } from '@entities/attachment';
import {
  Environment,
  EnvironmentSetting,
  EnvironmentsFilter,
} from '@entities/environment';
import {
  FetchOrganizations,
  Organization,
  OrganizationsFilter,
  OrganizationType,
} from '@entities/organization';
import { SLAType } from '@entities/sla';
import { System, SystemsFilter } from '@entities/system';
import { TicketType, TicketTypeSettings } from '@entities/ticket';
import { User, UsersFilterData } from '@entities/user';
import { WorkGroup, WorkGroupFilter } from '@entities/work-group';
import { ResponseWithMeta } from '@shared';

import {
  ContractDetail,
  CreateContractDetailAction,
  DeleteContractDetailRequestAction,
  EditContractDetailAction,
  FetchContractDetailRequestAction,
  FetchContractDetailSuccessAction,
  FetchEnvironmentsBySystemRequestAction,
  FetchEnvironmentsBySystemSuccessAction,
  FetchEnvironmentSettingsBySystemRequestAction,
  FetchEnvironmentSettingsBySystemSuccessAction,
  FetchOrganizationsJoinSuccessContractDetailAction,
  FetchOrganizationsRequestContractDetailAction,
  FetchOrganizationsSuccessUpdateContractDetailAction,
  FetchPartnerOrganizationsRequestContractDetailAction,
  FetchPartnerOrganizationsSuccessContractDetailAction,
  FetchSupplementaryAgreementsSuccessAction,
  FetchSystemsForAddingToContractRequestContractDetailAction,
  FetchSystemsForAddingToContractSuccessContractDetailAction,
  FetchUpdateEnvironmentSettingsRequestAction,
  HideOrganizationsLoadingContractDetailAction,
  HidePartnerOrganizationsLoadingContractDetailAction,
  LoadingHideSystemsForAddingToContractContractDetailAction,
  LoadingShowSystemsForAddingToContractContractDetailAction,
  ResetOrganizationOnlyStateContractDetailAction,
  SetContractPermissionAction,
  SetCurrentOrganizationIdContractDetailAction,
  SetFilterEnvironmentsBySystemAction,
  SetOrganizationsPageContractDetailAction,
  SetOrganizationsSortContractDetailAction,
  SetPageEnvironmentsBySystemAction,
  SetPageSizeSupplementaryAgreementsContractDetailAction,
  SetPageSupplementaryAgreementsContractDetailAction,
  SetSelectedSystemIdContractDetailAction,
  SetSelectSystemByContractAction,
  SetSizeEnvironmentsBySystemAction,
  SetSortEnvironmentsBySystemAction,
  SetSortSupplementaryAgreementsContractDetailAction,
  ShowOrganizationsLoadingContractDetailAction,
  ShowPartnerOrganizationsLoadingContractDetailAction,
  SystemTransferAction,
  UpdateContractDetailAction,
  UpdateContractNotificationsAction,
  UpdateContractNotificationsSuccessAction,
  UpdateContractPermissionsAction,
  UpdateContractPermissionsSuccessAction,
} from './actionTypes';
import {
  AttachSystemAndSLAToContractType,
  Contract,
  ContractDetailPayload,
  ContractIdSystemIdArgs,
  EditContractPayload,
  ProgressStatus,
  ProlongationContractPayload,
  SetContractPermissionPayload,
  SLAPatternPayload,
  SystemsByContractIdPayload,
  UpdateContractNotificationsPayload,
  UpdateContractPermissionsPayload,
  UpdateEnvironmentSettings,
} from './types';

export const showContractDetailLoading = () => ({
  type: ContractDetail.CONTRACT_LOADING_SHOW,
});

export const hideContractDetailLoading = () => ({
  type: ContractDetail.CONTRACT_LOADING_HIDE,
});

export const fetchContractDetailRequest = (
  id: string
): FetchContractDetailRequestAction => ({
  type: ContractDetail.FETCH_CONTRACT_REQUEST,
  payload: id,
});

export const fetchContractDetailSuccess = (
  contract: Contract
): FetchContractDetailSuccessAction => ({
  type: ContractDetail.FETCH_CONTRACT_SUCCESS,
  payload: contract,
});

export const contractSignRequestContractDetail = (id: string) => ({
  type: ContractDetail.CONTRACT_SIGN_REQUEST,
  payload: id,
});

export const showSupplementaryAgreementsLoading = () => ({
  type: ContractDetail.SUPPLEMENTARY_AGREEMENTS_LOADING_SHOW,
});

export const hideSupplementaryAgreementsLoading = () => ({
  type: ContractDetail.SUPPLEMENTARY_AGREEMENTS_LOADING_HIDE,
});

export const fetchSupplementaryAgreementsSuccess = (
  data: ResponseWithMeta<Contract[]>
): FetchSupplementaryAgreementsSuccessAction => ({
  type: ContractDetail.FETCH_SUPPLEMENTARY_AGREEMENTS_SUCCESS,
  payload: data,
});

export const fetchSupplementaryAgreementsRequest = (id: string) => ({
  type: ContractDetail.FETCH_SUPPLEMENTARY_AGREEMENTS_REQUEST,
  payload: id,
});

export const setPageSupplementaryAgreementsContractDetail = (
  page: number
): SetPageSupplementaryAgreementsContractDetailAction => ({
  type: ContractDetail.SET_SUPPLEMENTARY_AGREEMENTS_PAGE,
  payload: page,
});

export const setPageSizeSupplementaryAgreementsContractDetail = (
  size: number
): SetPageSizeSupplementaryAgreementsContractDetailAction => ({
  type: ContractDetail.SET_SIZE_PAGE_SUPPLEMENTARY_AGREEMENTS,
  payload: size,
});

export const setSortSupplementaryAgreementsContractDetail = (
  sort: string
): SetSortSupplementaryAgreementsContractDetailAction => ({
  type: ContractDetail.SET_SORT_SUPPLEMENTARY_AGREEMENTS,
  payload: sort,
});

export const createContractDetail = (
  data: Contract
): CreateContractDetailAction => ({
  type: ContractDetail.CREATE_CONTRACT,
  payload: data,
});

export const setContractPermission = (
  data: SetContractPermissionPayload
): SetContractPermissionAction => ({
  type: ContractDetail.SET_CONTRACT_PERMISSION,
  payload: data,
});

export const updateNotificationsExpireContract = (
  data: UpdateContractNotificationsPayload
): UpdateContractNotificationsAction => ({
  type: ContractDetail.UPDATE_CONTRACT_NOTIFICATIONS,
  payload: data,
});

export const updateNotificationsExpireContractSuccess = (
  data: Contract
): UpdateContractNotificationsSuccessAction => ({
  type: ContractDetail.UPDATE_CONTRACT_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const setProlongationContract = (payload?: ProgressStatus) => ({
  type: ContractDetail.SET_PROLONGATION_CONTRACT,
  payload,
});

export const prolongationContract = (payload: ProlongationContractPayload) => ({
  type: ContractDetail.PROLONGATION_CONTRACT,
  payload,
});

export const resetPartlyContractDetailStateForProlongation = () => ({
  type: ContractDetail.RESET_PARTLY_CONTRACT_DETAIL_STATE_FOR_PROLONGATION,
});

export const updateContractPermission = (
  data: UpdateContractPermissionsPayload
): UpdateContractPermissionsAction => ({
  type: ContractDetail.UPDATE_CONTRACT_PERMISSION,
  payload: data,
});

export const updateContractPermissionSuccess = (
  data: Contract
): UpdateContractPermissionsSuccessAction => ({
  type: ContractDetail.UPDATE_CONTRACT_PERMISSION_SUCCESS,
  payload: data,
});

export const setSelectedSystemIdContractDetail = (
  id?: string
): SetSelectedSystemIdContractDetailAction => ({
  type: ContractDetail.SET_SELECTED_SYSTEM_ID,
  payload: id,
});

export const editContractDetail = (
  data: EditContractPayload
): EditContractDetailAction => ({
  type: ContractDetail.EDIT_CONTRACT,
  payload: data,
});

export const updateContractDetail = (
  contract: Contract
): UpdateContractDetailAction => ({
  type: ContractDetail.UPDATE_CONTRACT,
  payload: contract,
});

export const deleteContractDetailRequest = (
  id: string
): DeleteContractDetailRequestAction => ({
  type: ContractDetail.DELETE_CONTRACT_REQUEST,
  payload: id,
});

export const fetchSystemsByContractIdRequestContractDetail = (
  params: SystemsByContractIdPayload
) => ({
  type: ContractDetail.FETCH_SYSTEMS_BY_CONTRACT_ID_REQUEST,
  payload: params,
});

export const fetchSystemsByContractIdSuccessContractDetail = (
  systems: ResponseWithMeta<System[]>
) => ({
  type: ContractDetail.FETCH_SYSTEMS_BY_CONTRACT_ID_SUCCESS,
  payload: systems,
});

export const loadingShowSystemsByContractIdContractDetail = () => ({
  type: ContractDetail.LOADING_SHOW_SYSTEMS_BY_CONTRACT_ID,
});

export const loadingHideSystemsByContractIdContractDetail = () => ({
  type: ContractDetail.LOADING_HIDE_SYSTEMS_BY_CONTRACT_ID,
});

export const setPageSystemsByContractIdContractDetail = (page: number) => ({
  type: ContractDetail.SET_SYSTEMS_BY_CONTRACT_ID_PAGE,
  payload: page,
});

export const setPageSizeSystemsByContractIdContractDetail = (
  pageSize: number
) => ({
  type: ContractDetail.SET_SIZE_PAGE_SYSTEMS_BY_CONTRACT_ID,
  payload: pageSize,
});

export const setFilterSystemsByContractIdContractDetail = (
  filter: SystemsFilter
) => ({
  type: ContractDetail.SET_SYSTEMS_BY_CONTRACT_ID_FILTER,
  payload: filter,
});

export const setActiveSystemByContractIdContractDetail = (id?: string) => ({
  type: ContractDetail.SET_ACTIVE_SYSTEM_BY_CONTRACT_ID,
  payload: id,
});

export const setSortSystemsByContractIdContractDetail = (sort: string) => ({
  type: ContractDetail.SET_SYSTEMS_BY_CONTRACT_ID_SORT,
  payload: sort,
});

export const resetSystemsByContractIdOnlyContractDetail = () => ({
  type: ContractDetail.RESET_ONLY_SYSTEMS_BY_CONTRACT_ID,
});

export const systemTransfer = (contract: Contract): SystemTransferAction => ({
  type: ContractDetail.SYSTEM_TRANSFER,
  payload: contract,
});

export const setCheckedSystemForTransfer = (system: System) => ({
  type: ContractDetail.SET_CHECKED_SYSTEM_FOR_TRANSFER,
  payload: system,
});

export const setAllCheckedSystemsForTransfer = (systems: System[]) => ({
  type: ContractDetail.SET_ALL_CHECKED_SYSTEMS_FOR_TRANSFER,
  payload: systems,
});

export const setTransferStatus = (payload: ProgressStatus) => ({
  type: ContractDetail.SET_TRANSFER_STATUS,
  payload,
});

export const setContractIdForTransfer = (id?: string) => ({
  type: ContractDetail.SET_CONTRACT_ID_FOR_TRANSFER,
  payload: id,
});

export const setSupportExtendedContract = (contract: Contract) => ({
  type: ContractDetail.SET_SUPPORT_EXTENDED_CONTRACT,
  payload: contract,
});

export const fetchSystemsForAddingToContractRequestContractDetail = (
  filter?: string
): FetchSystemsForAddingToContractRequestContractDetailAction => ({
  type: ContractDetail.FETCH_SYSTEMS_REQUEST,
  payload: filter,
});

export const fetchSystemsForAddingToContractSuccessContractDetail = (
  systems: System[]
): FetchSystemsForAddingToContractSuccessContractDetailAction => ({
  type: ContractDetail.FETCH_SYSTEMS_SUCCESS,
  payload: systems,
});

export const loadingShowSystemsForAddingToContractContractDetail =
  (): LoadingShowSystemsForAddingToContractContractDetailAction => ({
    type: ContractDetail.LOADING_SHOW_SYSTEMS,
  });

export const loadingHideSystemsForAddingToContractContractDetail =
  (): LoadingHideSystemsForAddingToContractContractDetailAction => ({
    type: ContractDetail.LOADING_HIDE_SYSTEMS,
  });

export const fetchSystemsForAddingSupplementaryAgreementRequestContractDetail =
  (filter?: string) => ({
    type: ContractDetail.FETCH_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT_REQUEST,
    payload: filter,
  });

export const fetchSystemsForAddingSupplementaryAgreementSuccessContractDetail =
  (data: System[]) => ({
    type: ContractDetail.FETCH_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT_SUCCESS,
    payload: data,
  });

export const loadingShowSystemsForAddingSupplementaryAgreementContractDetail =
  () => ({
    type: ContractDetail.LOADING_SHOW_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT,
  });

export const loadingHideSystemsForAddingSupplementaryAgreementContractDetail =
  () => ({
    type: ContractDetail.LOADING_HIDE_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT,
  });

export const resetContractDetailState = () => ({
  type: ContractDetail.RESET_CONTRACT_DETAIL_STATE,
});

export const showOrganizationsLoadingContractDetail =
  (): ShowOrganizationsLoadingContractDetailAction => ({
    type: ContractDetail.ORGANIZATIONS_LOADING_SHOW,
  });

export const hideOrganizationsLoadingContractDetail =
  (): HideOrganizationsLoadingContractDetailAction => ({
    type: ContractDetail.ORGANIZATIONS_LOADING_HIDE,
  });

export const fetchOrganizationsSuccessUpdateContractDetail = (
  data: ResponseWithMeta<Organization[]>
): FetchOrganizationsSuccessUpdateContractDetailAction => ({
  type: ContractDetail.FETCH_ORGANIZATIONS_UPDATE_SUCCESS,
  payload: data,
});

export const fetchOrganizationsJoinSuccessContractDetail = (
  data: ResponseWithMeta<Organization[]>
): FetchOrganizationsJoinSuccessContractDetailAction => ({
  type: ContractDetail.FETCH_ORGANIZATIONS_JOIN_SUCCESS,
  payload: data,
});

export const setCurrentOrganizationsPageContractDetail = (
  page: number
): SetOrganizationsPageContractDetailAction => ({
  type: ContractDetail.SET_CURRENT_ORGANIZATIONS_PAGE,
  payload: page,
});

export const setSelectedOrganizationIdContractDetail = (
  id?: string
): SetCurrentOrganizationIdContractDetailAction => ({
  type: ContractDetail.SET_ORGANIZATION_SELECTED,
  payload: id,
});

export const setOrganizationsFilterContractDetail = (
  data: OrganizationsFilter
) => ({
  type: ContractDetail.SET_ORGANIZATIONS_FILTER,
  payload: data,
});

export const fetchOrganizationsRequestContractDetail = (
  payload?: FetchOrganizations
): FetchOrganizationsRequestContractDetailAction => ({
  type: ContractDetail.FETCH_ORGANIZATIONS_REQUEST,
  payload,
});

export const showPartnerOrganizationsLoadingContractDetail =
  (): ShowPartnerOrganizationsLoadingContractDetailAction => ({
    type: ContractDetail.PARTNER_ORGANIZATIONS_LOADING_SHOW,
  });

export const hidePartnerOrganizationsLoadingContractDetail =
  (): HidePartnerOrganizationsLoadingContractDetailAction => ({
    type: ContractDetail.PARTNER_ORGANIZATIONS_LOADING_HIDE,
  });

export const fetchPartnerOrganizationsRequestContractDetail = (
  payload: OrganizationType[]
): FetchPartnerOrganizationsRequestContractDetailAction => ({
  type: ContractDetail.FETCH_PARTNER_ORGANIZATIONS_REQUEST,
  payload,
});

export const fetchPartnerOrganizationsSuccessContractDetail = (
  payload: ResponseWithMeta<Organization[]>
): FetchPartnerOrganizationsSuccessContractDetailAction => ({
  type: ContractDetail.FETCH_PARTNER_ORGANIZATIONS_SUCCESS,
  payload,
});

export const setOrganizationsSortContractDetail = (
  sort: string
): SetOrganizationsSortContractDetailAction => ({
  type: ContractDetail.SET_ORGANIZATIONS_SORT,
  payload: sort,
});

export const resetOrganizationOnlyStateContractDetail =
  (): ResetOrganizationOnlyStateContractDetailAction => ({
    type: ContractDetail.RESET_ORGANIZATION_ONLY_STATE,
  });

export const showUsersAllowedToAddToContractDetailLoading = () => ({
  type: ContractDetail.USERS_LOADING_SHOW,
});

export const hideUsersAllowedToAddToContractDetailLoading = () => ({
  type: ContractDetail.USERS_LOADING_HIDE,
});

export const fetchUsersAllowedToAddToContractDetailRequest = () => ({
  type: ContractDetail.FETCH_USERS_ALLOWED_TO_ADD_TO_CONTRACT_REQUEST,
});

export const fetchUsersAllowedToAddToContractDetailSuccess = (
  users: ResponseWithMeta<User[]>
) => ({
  type: ContractDetail.FETCH_USERS_ALLOWED_TO_ADD_TO_SUCCESS_REQUEST,
  payload: users,
});

export const setUsersPageContractDetail = (page: number) => ({
  type: ContractDetail.SET_USERS_PAGE,
  payload: page,
});

export const setUsersFilterContractDetail = (filter: UsersFilterData) => ({
  type: ContractDetail.SET_USERS_FILTER,
  payload: filter,
});

export const resetUsersOnlyStateContractDetail = () => ({
  type: ContractDetail.RESET_USERS_ALLOWED_TO_ADD_TO_CONTRACT_ONLY_STATE,
});

// attachments
export const showAttachmentsLoadingContractDetail = () => ({
  type: ContractDetail.ATTACHMENTS_LOADING_SHOW,
});

export const hideAttachmentsLoadingContractDetail = () => ({
  type: ContractDetail.ATTACHMENTS_LOADING_HIDE,
});

export const fetchContractAttachmentsRequestContractDetail = (id: string) => ({
  type: ContractDetail.FETCH_CONTRACT_ATTACHMENTS_REQUEST,
  payload: id,
});

export const fetchContractAttachmentsSuccessContractDetail = (
  attachments: Attachment[]
) => ({
  type: ContractDetail.FETCH_CONTRACT_ATTACHMENTS_SUCCESS,
  payload: attachments,
});

export const addAttachmentToContractDetailRequest = (data: FormData) => ({
  type: ContractDetail.ADD_ATTACHMENT_TO_CONTRACT_REQUEST,
  payload: data,
});

export const resetOnlyAttachmentsStateContractDetail = () => ({
  type: ContractDetail.RESET_ONLY_ATTACHMENTS_STATE,
});

export const fetchEnvironmentsBySystemRequest = (
  data: ContractDetailPayload
): FetchEnvironmentsBySystemRequestAction => ({
  type: ContractDetail.FETCH_ENVIRONMENTS_BY_SYSTEM_REQUEST,
  payload: data,
});

export const fetchEnvironmentsBySystemSuccess = (
  environments: ResponseWithMeta<Environment[]>
): FetchEnvironmentsBySystemSuccessAction => ({
  type: ContractDetail.FETCH_ENVIRONMENTS_BY_SYSTEM_SUCCESS,
  payload: environments,
});

export const setPageEnvironmentsBySystem = (
  page: number
): SetPageEnvironmentsBySystemAction => ({
  type: ContractDetail.SET_PAGE_ENVIRONMENTS_BY_SYSTEM,
  payload: page,
});

export const setSizeEnvironmentsBySystem = (
  page: number
): SetSizeEnvironmentsBySystemAction => ({
  type: ContractDetail.SET_SIZE_ENVIRONMENTS_BY_SYSTEM,
  payload: page,
});

export const setFilterEnvironmentsBySystem = (
  filter: EnvironmentsFilter
): SetFilterEnvironmentsBySystemAction => ({
  type: ContractDetail.SET_ENVIRONMENTS_BY_SYSTEM_FILTER,
  payload: filter,
});

export const setSortEnvironmentsBySystem = (
  sort: string
): SetSortEnvironmentsBySystemAction => ({
  type: ContractDetail.SET_SORT_ENVIRONMENTS_BY_SYSTEM,
  payload: sort,
});

export const loadingShowEnvironmentsBySystem = () => ({
  type: ContractDetail.LOADING_SHOW_ENVIRONMENTS_BY_SYSTEM,
});

export const loadingHideEnvironmentsBySystem = () => ({
  type: ContractDetail.LOADING_HIDE_ENVIRONMENTS_BY_SYSTEM,
});

export const setSystemSelectedByContract = (
  payload?: System
): SetSelectSystemByContractAction => ({
  type: ContractDetail.SET_SELECT_SYSTEM_BY_CONTRACT,
  payload,
});

export const fetchEnvironmentSettingsBySystemRequest = (
  payload?: ContractIdSystemIdArgs
): FetchEnvironmentSettingsBySystemRequestAction => ({
  type: ContractDetail.FETCH_ENVIRONMENT_SETTINGS_BY_SYSTEM_REQUEST,
  payload,
});

export const fetchEnvironmentSettingsBySystemSuccess = (
  environmentSettings: EnvironmentSetting[]
): FetchEnvironmentSettingsBySystemSuccessAction => ({
  type: ContractDetail.FETCH_ENVIRONMENT_SETTINGS_BY_SYSTEM_SUCCESS,
  payload: environmentSettings,
});

export const fetchUpdateEnvironmentSettingsRequest = (
  payload: UpdateEnvironmentSettings
): FetchUpdateEnvironmentSettingsRequestAction => ({
  type: ContractDetail.FETCH_UPDATE_ENVIRONMENT_SETTINGS_REQUEST,
  payload,
});

export const fetchWorkGroupsByContractBySystemRequestContractDetail = (
  data: ContractDetailPayload
) => ({
  type: ContractDetail.FETCH_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM_REQUEST,
  payload: data,
});

export const fetchWorkGroupsByContractBySystemSuccessContractDetail = (
  workGroups: ResponseWithMeta<WorkGroup[]>
) => ({
  type: ContractDetail.FETCH_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM_SUCCESS,
  payload: workGroups,
});

export const loadingShowWorkGroupsByContractBySystemContractDetail = () => ({
  type: ContractDetail.LOADING_SHOW_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM,
});

export const loadingHideWorkGroupsByContractBySystemContractDetail = () => ({
  type: ContractDetail.LOADING_HIDE_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM,
});

export const setPageWorkGroupsByContractBySystemContractDetail = (
  page: number
) => ({
  type: ContractDetail.SET_WORK_GROUPS_PAGE_BY_CONTRACT_BY_SYSTEM,
  payload: page,
});

export const setFilterWorkGroupsByContractBySystemContractDetail = (
  filter: WorkGroupFilter
) => ({
  type: ContractDetail.SET_WORK_GROUPS_FILTER_BY_CONTRACT_BY_SYSTEM,
  payload: filter,
});

export const setSizePageWorkGroupsByContractBySystemContractDetail = (
  pageSize: number
) => ({
  type: ContractDetail.SET_SIZE_PAGE_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM,
  payload: pageSize,
});

export const setSortWorkGroupsByContractBySystemContractDetail = (
  sort: string
) => ({
  type: ContractDetail.SET_WORK_GROUPS_SORT_BY_CONTRACT_BY_SYSTEM,
  payload: sort,
});

export const resetWorkGroupsByContractBySystemContractDetail = () => ({
  type: ContractDetail.RESET_ONLY_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM,
});

export const fetchWorkGroupsByContractRequestContractDetail = (
  data: string
) => ({
  type: ContractDetail.FETCH_WORK_GROUPS_BY_CONTRACT_REQUEST,
  payload: data,
});

export const fetchWorkGroupsByContractSuccessContractDetail = (
  workGroups: ResponseWithMeta<WorkGroup[]>
) => ({
  type: ContractDetail.FETCH_WORK_GROUPS_BY_CONTRACT_SUCCESS,
  payload: workGroups,
});

export const loadingShowWorkGroupsByContractContractDetail = () => ({
  type: ContractDetail.LOADING_SHOW_WORK_GROUPS_BY_CONTRACT,
});

export const loadingHideWorkGroupsByContractContractDetail = () => ({
  type: ContractDetail.LOADING_HIDE_WORK_GROUPS_BY_CONTRACT,
});

export const setPageWorkGroupsByContractContractDetail = (page: number) => ({
  type: ContractDetail.SET_WORK_GROUPS_PAGE_BY_CONTRACT,
  payload: page,
});

export const setSizePageWorkGroupsByContractContractDetail = (
  size: number
) => ({
  type: ContractDetail.SET_WORK_GROUPS_SIZE_PAGE_BY_CONTRACT,
  payload: size,
});

export const setFilterWorkGroupsByContractContractDetail = (
  filter: WorkGroupFilter
) => ({
  type: ContractDetail.SET_WORK_GROUPS_FILTER_BY_CONTRACT,
  payload: filter,
});

export const resetWorkGroupsByContractContractDetail = () => ({
  type: ContractDetail.RESET_ONLY_WORK_GROUPS_BY_CONTRACT,
});

export const updateWorkGroupsInContractInSystemContractDetail = (
  data: string[]
) => ({
  type: ContractDetail.UPDATE_WORK_GROUPS_IN_CONTRACT_IN_SYSTEM,
  payload: data,
});

export const loadingShowSLAPatternsContractDetail = () => ({
  type: ContractDetail.LOADING_SHOW_SLA_PATTERNS_REQUEST,
});

export const loadingHideSLAPatternsContractDetail = () => ({
  type: ContractDetail.LOADING_HIDE_SLA_PATTERNS_REQUEST,
});

export const fetchSLAPatternsRequestContractDetail = (payload?: string) => ({
  type: ContractDetail.FETCH_SLA_PATTERNS_REQUEST,
  payload,
});

export const fetchSLAPatternsSuccessContractDetail = (data: SLAType[]) => ({
  type: ContractDetail.FETCH_SLA_PATTERNS_SUCCESS,
  payload: data,
});

export const createSLAPatternRequestContractDetail = (
  payload: SLAPatternPayload
) => ({
  type: ContractDetail.CREATE_SLA_PATTERN_REQUEST,
  payload,
});

export const resetSLAPatternsContractDetail = () => ({
  type: ContractDetail.RESET_SLA_PATTERNS,
});

export const checkIsExistPatternTitleRequestContractDetail = (
  payload: string
) => ({
  type: ContractDetail.CHECK_IS_EXIST_PATTERN_TITLE_REQUEST,
  payload,
});

export const checkIsExistPatternTitleSuccessContractDetail = (
  isExist: boolean
) => ({
  type: ContractDetail.CHECK_IS_EXIST_PATTERN_TITLE_SUCCESS,
  payload: isExist,
});

export const fetchPatternByIdRequestContractDetail = (id: string) => ({
  type: ContractDetail.FETCH_PATTERN_BY_ID_REQUEST,
  payload: id,
});

export const fetchPatternByIdSuccessContractDetail = (sla: SLAType) => ({
  type: ContractDetail.FETCH_PATTERN_BY_ID_SUCCESS,
  payload: sla,
});

export const fetchSlaByContractIdBySystemIdRequestContractDetail = (
  data: ContractDetailPayload
) => ({
  type: ContractDetail.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST,
  payload: data,
});

export const fetchSlaByContractIdBySystemIdSuccessContractDetail = (
  sla: SLAType
) => ({
  type: ContractDetail.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS,
  payload: sla,
});

export const fetchTicketTypeSettingsRequestContractDetail = (
  payload: ContractIdSystemIdArgs
) => ({
  type: ContractDetail.FETCH_TICKET_TYPE_SETTINGS_REQUEST,
  payload,
});

export const fetchTicketTypeSettingsSuccessContractDetail = (
  payload: TicketTypeSettings[]
) => ({
  type: ContractDetail.FETCH_TICKET_TYPE_SETTINGS_SUCCESS,
  payload,
});

export const setLoadingTicketTypeSettingsContractDetail = (
  payload: boolean
) => ({
  type: ContractDetail.SET_LOADING_TICKET_TYPE_SETTINGS,
  payload,
});

export const fetchTicketTypesRequestContractDetail = (payload: string) => ({
  type: ContractDetail.FETCH_TICKET_TYPES_REQUEST,
  payload,
});

export const fetchTicketTypesSuccessContractDetail = (
  payload: TicketType[]
) => ({
  type: ContractDetail.FETCH_TICKET_TYPES_SUCCESS,
  payload,
});

export const setSelectedSystemSlaContractDetail = (payload?: string) => ({
  type: ContractDetail.SET_SELECTED_SYSTEM_SLA,
  payload,
});

export const loadingShowSlaByContractIdBySystemIdRequestContractDetail =
  () => ({
    type: ContractDetail.LOADING_SHOW_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST,
  });

export const loadingHideSlaByContractIdBySystemIdRequestContractDetail =
  () => ({
    type: ContractDetail.LOADING_HIDE_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST,
  });

export const attachSystemAndSLAToContractDetail = (
  data: AttachSystemAndSLAToContractType
) => ({
  type: ContractDetail.ATTACH_SYSTEM_AND_SLA_TO_CONTRACT,
  payload: data,
});

export const updateContractSLARequestContractDetail = (
  data: AttachSystemAndSLAToContractType
) => ({
  type: ContractDetail.UPDATE_CONTRACT_SLA_REQUEST,
  payload: data,
});
