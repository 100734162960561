import cn from 'clsx';
import _ from 'lodash';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import {
  checkIsExistTitleContainingRequest,
  createOrganization,
  CreateOrganizationData,
  editOrganization,
  getCurrentOrganization,
  getIsExistTitleContaining,
  Organization,
  OrganizationType,
  requests,
  TYPE_OPTIONS,
} from '@entities/organization';
import {
  Accordion,
  ApproveOrCancel,
  BottomButtonsBlock,
  Card,
  Input,
  resetActiveElementFocus,
  RouterHref,
  Select,
  SelectOption,
  TextArea,
  ToggleSwitch,
  Typography,
  TypographyVariants,
} from '@shared';

import { useOrganizationId } from '../../../../lib';
import { SaveOrganizationTitle } from '../SaveOrganizationTitle';

import styles from './OrganizationForm.module.scss';

interface OrganizationFormProps {
  className?: string;
}

export const OrganizationForm: FC<OrganizationFormProps> = ({ className }) => {
  const organization = useSelector(getCurrentOrganization);
  const isExistTitleContaining = useSelector(getIsExistTitleContaining);
  const isAccessToCreateOrganization = useSelector(
    getIsAccessedAction(ActionList.CreateCompany)
  );

  const organizationId = useOrganizationId();
  const mainLayoutSticky = document.getElementById('mainLayoutSticky');

  const [isModal, setIsModal] = useState<boolean>(false);
  const [isExistTitleModal, setIsExistTitleModal] = useState(false);
  const [isIdenticalData, setIsIdenticalData] = useState<boolean>(false);

  const toggleModal = () => setIsModal(!isModal);
  const toggleExistTitleModal = () => setIsExistTitleModal((prev) => !prev);

  const { push } = useHistory();

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm<CreateOrganizationData>({
    mode: 'onChange',
  });

  const currentFormValues = watch();

  const getInitialOrganizationType = () => {
    if (organization) {
      return {
        value: organization.type,
        title:
          TYPE_OPTIONS.find(
            (item: SelectOption<OrganizationType>) =>
              item.value === organization?.type
          )?.title || '',
      };
    }
    if (!organizationId) {
      return TYPE_OPTIONS[0];
    }
    return null;
  };

  useEffect(() => {
    if (organization && organizationId) {
      setValue('title', organization?.title);
      setValue('domain', organization?.domain);
      setValue('address', organization?.address);
      setValue('description', organization?.description);
      setValue(
        'emailTwoStepAuthIsEnable',
        organization?.emailTwoStepAuthIsEnable
      );
    }
    setValue('type', getInitialOrganizationType());
  }, [organization, organizationId]);

  const normalizeData = (data?: Organization) => {
    const newOrganizationData = {
      ...data,
      type: getInitialOrganizationType(),
    };
    delete newOrganizationData.id;
    delete newOrganizationData.workGroupList;
    delete newOrganizationData.contractList;
    delete newOrganizationData.dateCreate;
    return newOrganizationData;
  };

  const initialFormValues = normalizeData(organization);

  useEffect(() => {
    if (_.isEqual(currentFormValues, initialFormValues) && organizationId) {
      return setIsIdenticalData(true);
    }
    return setIsIdenticalData(false);
  }, [currentFormValues, initialFormValues, organizationId]);

  const dispatch = useDispatch();

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    dispatch(checkIsExistTitleContainingRequest(value));
  };

  const titleInputOptions = register('title', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Название организации не может быть длиннее 100 символов.',
    },
    onChange: handleInput,
    validate: async (value) => {
      if (value) {
        const isExistTitle = await requests.checkIsExistTitle(value);

        if (isExistTitle && value !== organization?.title) {
          return `Организация с таким названием "${value}" уже существует. 
          Для доступа к существующей организации необходимо обратиться к администратору.`;
        }
      }

      return true;
    },
  });

  const domainInputOptions = register('domain', {
    required: true,
    maxLength: {
      value: 255,
      message: 'Домен организации не может быть длиннее 255-ти символов.',
    },
  });

  const addressInputOptions = register('address', {
    required: true,
    maxLength: {
      value: 255,
      message: 'Адрес организации не может быть длиннее 255-ти символов.',
    },
  });

  const descriptionTextAreaOptions = register('description', {
    required: true,
    maxLength: {
      value: 512,
      message: 'Описание организации не может быть длиннее 512-ти символов.',
    },
  });

  const formSubmitHandler = handleSubmit((data) => {
    if (!organizationId) {
      dispatch(createOrganization(data));
      return;
    }
    dispatch(editOrganization({ ...data, id: organization?.id }));
    resetActiveElementFocus();
  });

  const onApproveModal = () => {
    push(RouterHref.AdminOrganizations);
    reset();
  };

  const onExistTitleApproveModal = () => {
    formSubmitHandler();
  };

  const onSave = () => {
    if (isExistTitleContaining) {
      setIsExistTitleModal(true);
      return;
    }

    formSubmitHandler();
  };

  return (
    <div className={cn(styles.organizationForm, className)}>
      <Card className={styles.organizationForm__formHeader}>
        <Typography
          variant={TypographyVariants.h4}
          className={styles.organizationForm__formHeaderTab}
        >
          Информация
        </Typography>
      </Card>
      <Card className={styles.organizationForm__formContent}>
        <form className={styles.organizationForm__form}>
          <Input
            {...titleInputOptions}
            label="Название организации"
            error={!!errors.title}
            errorMessage={errors.title?.message}
            disabled={!isAccessToCreateOrganization}
            className={cn(
              styles.organizationForm__input,
              styles.organizationForm__input_oneOfThree
            )}
          />
          <Input
            {...domainInputOptions}
            label="Домен"
            error={!!errors.domain}
            errorMessage={errors.domain?.message}
            disabled={!isAccessToCreateOrganization}
            className={cn(
              styles.organizationForm__input,
              styles.organizationForm__input_oneOfThree
            )}
          />
          <Input
            {...addressInputOptions}
            label="Адрес"
            error={!!errors.address}
            errorMessage={errors.address?.message}
            disabled={!isAccessToCreateOrganization}
            className={cn(
              styles.organizationForm__input,
              styles.organizationForm__input_oneOfThree
            )}
          />
          <Controller
            control={control}
            name="type"
            rules={{ required: true }}
            render={({ field }) => (
              <Select<OrganizationType>
                label="Тип"
                mobileModalTitle="тип"
                options={TYPE_OPTIONS}
                value={field.value}
                onChange={field.onChange}
                className={cn(
                  styles.organizationForm__input,
                  styles.organizationForm__input_oneOfThree
                )}
                isTooltip={false}
              />
            )}
          />
          <TextArea
            {...descriptionTextAreaOptions}
            disabled={!isAccessToCreateOrganization}
            label="Описание"
            error={!!errors.description}
            errorMessage={errors.description?.message}
          />
          <Accordion
            title="Авторизация"
            className={styles.organizationForm__accordion}
          >
            <div className={styles.organizationForm__accordionContent}>
              <Controller
                control={control}
                name="emailTwoStepAuthIsEnable"
                rules={{ deps: 'title' }}
                render={({ field }) => (
                  <ToggleSwitch
                    label="Двухфакторная аутентификация"
                    checked={!!field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
          </Accordion>
          <BottomButtonsBlock
            isOpen={
              isDirty &&
              isValid &&
              !isIdenticalData &&
              isAccessToCreateOrganization
            }
            parentNode={mainLayoutSticky}
            onCancel={toggleModal}
            onSave={onSave}
            disabledSubmit={
              !isDirty ||
              !isValid ||
              isIdenticalData ||
              !isAccessToCreateOrganization
            }
          />
        </form>
      </Card>
      <ApproveOrCancel
        onApprove={onApproveModal}
        isModal={isModal}
        toggleModal={toggleModal}
        text={`Вы уверены, что хотите отменить ${
          organizationId ? 'редактирование' : 'создание'
        } организации?`}
      />
      <ApproveOrCancel
        onApprove={onExistTitleApproveModal}
        isModal={isExistTitleModal && !!isExistTitleContaining}
        toggleModal={toggleExistTitleModal}
        text={<SaveOrganizationTitle title={currentFormValues.title ?? ''} />}
        approveTitle="Создать"
        cancelTitle="Отмена"
      />
    </div>
  );
};
