import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { Trigger, TriggerFieldsType } from '@entities/triggers';
import { KeyValueOption } from '@shared';

import { SELECT_ATTRIBUTES_MAP_TRIGGERS } from '../../config';

const NAME_MAP: Record<string, string> = {
  organization: 'organizations',
  system: 'systems',
};

export const getNormalizedAttributes = (trigger: Trigger) => {
  const pickedAttributes = _.omitBy(
    _.pick(trigger, [
      'organization',
      'system',
      'ticketTypes',
      'status',
      'environments',
      'clients',
      'ticketPriorities',
    ]),
    _.isEmpty
  );

  return Object.entries(pickedAttributes).map(([key, value]) => {
    const val = value as KeyValueOption[] | KeyValueOption;
    const title = SELECT_ATTRIBUTES_MAP_TRIGGERS[key as TriggerFieldsType];
    const isRequired = !Array.isArray(value);
    const attributeValue = NAME_MAP[key] || key;

    return {
      id: uuidv4(),
      isActive: true,
      required: isRequired,
      attribute: {
        title,
        value: attributeValue,
      },
      value: Array.isArray(val)
        ? val.map((item) => ({ title: item.value, value: item.key }))
        : { title: val.value, value: val.key },
    };
  });
};
