import { Reducer } from 'redux';

import { CustomField } from '@entities/custom-fields/model/types';
import { System } from '@entities/system/model';
import { DEFAULT_PAGINATION_PARAMS, PaginationType, ReducerMap } from '@shared';

import {
  DownloadingExcel,
  FetchCustomFieldsExportSuccessAction,
  FetchSystemsExportSuccessAction,
  GetTicketsCountRequestAction,
  IGetExportFieldsAction,
  SelectCustomFieldAction,
  SetExtraExportFields,
  SetTicketsCountAction,
  TicketsExportActionsTypes,
  TicketsExportType,
} from './actionTypes';
import { ExportFields, TicketsRequestData } from './types';

export interface TicketsExportReducerState {
  isExportPanelOpen?: boolean;
  exportFields?: ExportFields;
  ticketsCount?: number;
  ticketsRequestData?: TicketsRequestData;
  isDownloadingExcel?: boolean;
  systems?: System[];
  systemsPagination?: PaginationType;
  systemsLoading?: boolean;
  customFields?: CustomField[];
  customFieldsPagination?: PaginationType;
  customFieldsLoading?: boolean;
  selectedCustomFields?: CustomField[];
  extraExportFields?: CustomField[];
}

const initialState: TicketsExportReducerState = {
  isExportPanelOpen: false,
  isDownloadingExcel: false,
  systems: [],
  systemsPagination: DEFAULT_PAGINATION_PARAMS,
  systemsLoading: false,
  customFields: [],
  customFieldsPagination: DEFAULT_PAGINATION_PARAMS,
  customFieldsLoading: false,
  selectedCustomFields: [],
  extraExportFields: [],
};

const ticketsExportReducerMap: ReducerMap<
  TicketsExportReducerState,
  TicketsExportActionsTypes
> = {
  [TicketsExportType.OPEN_EXPORT_PANEL]: (state) => ({
    ...state,
    isExportPanelOpen: true,
  }),
  [TicketsExportType.CLOSE_EXPORT_PANEL]: (state) => ({
    ...state,
    isExportPanelOpen: false,
  }),
  [TicketsExportType.GET_EXPORT_FIELDS]: (state, action) => {
    const { payload } = action as IGetExportFieldsAction;
    return {
      ...state,
      exportFields: payload,
    };
  },
  [TicketsExportType.SET_TICKETS_COUNT]: (state, action) => {
    const { payload } = action as SetTicketsCountAction;
    return {
      ...state,
      ticketsCount: payload,
    };
  },
  [TicketsExportType.GET_TICKETS_COUNT_REQUEST]: (state, action) => {
    const { payload } = action as GetTicketsCountRequestAction;
    return {
      ...state,
      ticketsRequestData: payload,
    };
  },
  [TicketsExportType.DOWNLOADING_EXCEL]: (state, action) => {
    const { payload } = action as DownloadingExcel;
    return {
      ...state,
      isDownloadingExcel: payload,
    };
  },
  [TicketsExportType.FETCH_SYSTEMS_EXPORT_SUCCESS]: (state, action) => {
    const { payload } = action as FetchSystemsExportSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      systems: content,
      pagination: {
        ...state?.systemsPagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [TicketsExportType.SYSTEMS_EXPORT_LOADING_SHOW]: (state) => ({
    ...state,
    systemsLoading: true,
  }),
  [TicketsExportType.SYSTEMS_EXPORT_LOADING_HIDE]: (state) => ({
    ...state,
    systemsLoading: false,
  }),
  [TicketsExportType.FETCH_CUSTOM_FIELDS_EXPORT_SUCCESS]: (state, action) => {
    const { payload } = action as FetchCustomFieldsExportSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      customFields: content,
      pagination: {
        ...state?.customFieldsPagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [TicketsExportType.CUSTOM_FIELDS_EXPORT_LOADING_SHOW]: (state) => ({
    ...state,
    customFieldsLoading: true,
  }),
  [TicketsExportType.CUSTOM_FIELDS_EXPORT_LOADING_HIDE]: (state) => ({
    ...state,
    customFieldsLoading: false,
  }),
  [TicketsExportType.RESET_CUSTOM_FIELDS_EXPORT]: (state) => ({
    ...state,
    customFields: [],
  }),
  [TicketsExportType.SELECT_CUSTOM_FIELD]: (state, action) => {
    const { payload } = action as SelectCustomFieldAction;

    const isCustomField = state?.selectedCustomFields?.find(
      (customField) => customField.id === payload.id
    );

    return {
      ...state,
      selectedCustomFields: !isCustomField
        ? [...(state?.selectedCustomFields || []), payload]
        : state?.selectedCustomFields?.filter(
            (selectedCustomField) => selectedCustomField.id !== payload.id
          ),
    };
  },
  [TicketsExportType.SELECT_ALL_CUSTOM_FIELDS]: (state) => ({
    ...state,
    selectedCustomFields: state?.selectedCustomFields?.length
      ? []
      : state?.customFields,
  }),
  [TicketsExportType.RESET_SELECTED_CUSTOM_FIELDS]: (state) => ({
    ...state,
    selectedCustomFields: [],
  }),
  [TicketsExportType.SET_EXTRA_EXPORT_FIELDS]: (state, action) => {
    const { payload } = action as SetExtraExportFields;
    return {
      ...state,
      extraExportFields: payload,
    };
  },
  [TicketsExportType.RESET_TICKETS_EXPORT_STATE]: () => initialState,
};

export const ticketsExportSlice: Reducer<
  TicketsExportReducerState,
  TicketsExportActionsTypes
> = (state = initialState, action) => {
  const reducer = ticketsExportReducerMap[action.type];

  if (!reducer) {
    return state;
  }

  return reducer(state, action);
};
