import { createSelector } from 'reselect';

import { CustomFieldType } from '@entities/custom-fields';
import {
  convertKeyValueOptionToArraySelectOption,
  KeyValueOption,
  SelectOption,
} from '@shared';

import { AttributesForFilter, CustomField, TriggersState } from './types';

export const getTriggers = (state: TriggersState) => state.triggers.triggers;
export const getLoading = (state: TriggersState) => state.triggers.loading;
export const getCurrentTrigger = (state: TriggersState) =>
  state.triggers.trigger;
export const getCurrentTriggerId = (state: TriggersState) =>
  state.triggers.triggerId;
export const getFilterValues = (state: TriggersState) => state.triggers.filter;
export const getAttributesFields = (state: TriggersState) =>
  state.triggers.attributesFields;
export const getFiltersFields = (state: TriggersState) =>
  state.triggers.filtersFields;
export const getOrganization = (state: TriggersState) =>
  state.triggers.filtersFields?.organizations;
export const getSystems = (state: TriggersState) =>
  state.triggers.filtersFields?.systems || [];
export const getTicketTypes = (state: TriggersState) =>
  state.triggers.filtersFields?.ticketTypes || [];
export const getTicketPriorities = (state: TriggersState) =>
  state.triggers.filtersFields?.ticketPriorities || [];
export const getEnvironments = (state: TriggersState) =>
  state.triggers.filtersFields?.environments || [];
export const getStatus = (state: TriggersState) =>
  state.triggers.filtersFields?.status || [];
export const getClients = (state: TriggersState) =>
  state.triggers.filtersFields?.clients || [];
export const getCustomFields = (state: TriggersState) =>
  state.triggers.customFields || [];
export const getNextTicketStatus = (state: TriggersState) =>
  state.triggers.nextTicketStatus || [];

export const getCustomFieldsMap = createSelector<
  TriggersState,
  CustomField[] | undefined,
  Record<string, CustomFieldType>
>(
  [getCustomFields],
  (customFields) =>
    customFields?.reduce<Record<string, CustomFieldType>>(
      (acc, { id, customFieldType }) => {
        const result = { ...acc };
        result[id] = customFieldType;
        return result;
      },
      {}
    ) || {}
);

export const getNextTicketStatusOptions = createSelector<
  TriggersState,
  KeyValueOption[],
  SelectOption[]
>(
  [getNextTicketStatus],
  (nextTicketStatus): SelectOption[] =>
    convertKeyValueOptionToArraySelectOption(nextTicketStatus) || []
);

export const getAttributesForFilter = createSelector<
  TriggersState,
  KeyValueOption[] | KeyValueOption | undefined,
  AttributesForFilter
>(
  [
    getOrganization,
    getSystems,
    getTicketTypes,
    getTicketPriorities,
    getEnvironments,
    getStatus,
    getClients,
  ],
  (
    organizations,
    systems,
    ticketTypes,
    ticketPriorities,
    environments,
    status,
    clients
  ): AttributesForFilter => ({
    organizations:
      convertKeyValueOptionToArraySelectOption(organizations) || [],
    systems: convertKeyValueOptionToArraySelectOption(systems) || [],
    ticketTypes: convertKeyValueOptionToArraySelectOption(ticketTypes) || [],
    ticketPriorities:
      convertKeyValueOptionToArraySelectOption(ticketPriorities) || [],
    environments: convertKeyValueOptionToArraySelectOption(environments) || [],
    status: convertKeyValueOptionToArraySelectOption(status) || [],
    clients: convertKeyValueOptionToArraySelectOption(clients) || [],
  })
);

export const getPropsTriggers = (state: TriggersState) => ({
  pageNum: state.triggers.pagination?.pageNum,
  pageSize: state.triggers.pagination?.pageSize,
  loadingTriggers: getLoading(state),
  sortTriggers: state?.triggers.sort,
  totalElements: state.triggers.pagination?.totalElements,
});
