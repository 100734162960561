import { JiraIntegrations } from '@widgets/jira-integrations';

import { AdminsLayout } from '../AdminsLayout';

const JiraIntegrationsPage = () => (
  <AdminsLayout>
    <JiraIntegrations />
  </AdminsLayout>
);

export default JiraIntegrationsPage;
