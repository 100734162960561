import { getUrl } from '@shared';

export const endpoints = {
  getNotificationsProfile: () => getUrl('v1/system-notification/filter'),
  setAllCheckedNotificationsProfile: () =>
    getUrl('v1/system-notification/check-all'),
  getUncheckedNotificationsProfile: () =>
    getUrl('v1/system-notification/is-unchecked'),
  setCheckedNotifications: (id: string) =>
    getUrl(`v1/system-notification/check/${id}`),
  fetchNotificationsSettings: () => getUrl('user-notification-settings/all'),
  fetchNotificationsTabs: () => getUrl('v1/system-notification/tabs/'),
  getNews: () => getUrl('v1/news/filter'),
  createNews: () => getUrl('v1/news/manage'),
  editNews: (id: string) => getUrl(`v1/news/manage/${id}`),
  getCurrentNews: (id: string) => getUrl(`v1/news/${id}`),
  deleteNews: (id: string) => getUrl(`v1/news/manage/${id}`),
  getIsUncheckedNews: () => getUrl('v1/news/is-unchecked'),
  checkNews: (id: string) => getUrl(`v1/news/${id}/check`),
  getOrganizations: () => getUrl('organization/news-creating-form'),
  getWorkGroups: () => getUrl('work-group/news-creating-form'),
  checkHasUnpublishedNews: () => getUrl('v1/news/planned-for-user'),
  getSystemById: (id: string) => getUrl(`system/${id}`),
  getStorageById: (storageId?: string) => getUrl(`storages/${storageId}`),
};
