import { getUrl } from '@shared';

import {
  CurrentStorageFileCardRequest,
  DownloadStorageFileRequest,
} from '../../model';

export const storageEndpoints = {
  getStorage: (id?: string) => getUrl(`storages/${id}`),
  getStorageFileCards: (id?: string) => getUrl(`storages/files/${id}/filter`),
  addStorageFileCard: () => getUrl('storages/files/file-card'),
  deleteStorageFileCard: ({
    storageId,
    fileCardId,
  }: CurrentStorageFileCardRequest) =>
    getUrl(`storages/files/${storageId}/file-card/${fileCardId}`),
  editStorageFileCard: ({
    storageId,
    fileCardId,
  }: CurrentStorageFileCardRequest) =>
    getUrl(`storages/files/${storageId}/file-card/${fileCardId}`),
  getCurrentStorageFileCard: ({
    storageId,
    fileCardId,
  }: CurrentStorageFileCardRequest) =>
    getUrl(`storages/files/${storageId}/file-card/${fileCardId}`),
  downloadStorageFile: ({ storageId, fileId }: DownloadStorageFileRequest) =>
    getUrl(`storages/files/${storageId}/${fileId}`),
  uploadStorageFile: () => getUrl('storages/files/upload'),
};
