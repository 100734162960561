import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  addNewTicketTypeRequest,
  editJiraIntegration,
  editJiraSync,
  fetchInfoForJiraIntegration,
  fetchJiraFieldsRequest,
  fetchJiraPrioritiesById,
  fetchJiraPrioritiesByIntegrationIdRequest,
  fetchJiraPrioritiesRequest,
  fetchJiraStatusesById,
  fetchJiraStatusesByPropertyIdRequest,
  fetchJiraTicketTypeByIntegrationIdRequest,
  fetchJiraTicketTypesByIntegrationIdRequest,
  fetchSpFieldsRequest,
  fetchSPStatusesForCurrentRequest,
  fetchSystemPrioritiesRequest,
  FilterRulesValue,
  getCreateTicketTypesOptions,
  getCurrentJiraIntegrationId,
  getCurrentSystemId,
  getEditSyncStatusesOptions,
  getFieldsIntegrations,
  getIntegratedTicketTypesOptions,
  getJiraIntegration,
  getJiraPrioritiesForCreate,
  getJiraPrioritiesForCurrent,
  getJiraPrioritiesForEdit,
  getJiraPrioritiesOptions,
  getJiraStatusesByPropertyId,
  getJiraStatusesBySystemId,
  getJiraStatusesOptions,
  getJiraTicketTypeByIntegrationId,
  getJiraTicketTypesForCurrent,
  getLoadingJiraIntegrationInfo,
  getSpStatusesForCurrent,
  getSyncClientsOptions,
  getSyncEnvironmentsOptions,
  getSyncPrioritiesOptions,
  getSyncSpecialistsOptions,
  getSyncTicketTypesOptions,
  getSystemPriorities,
  JiraIntegrationEditData,
  JiraPriority,
  JiraStatus,
  JiraSynchronizationCreateForm,
  setCurrentTypeId,
} from '@entities/jira-integrations';
import { Priority } from '@entities/ticket';
import {
  getTitleFromValueType,
  getValueFromValueType,
  ValueType,
} from '@shared';

import {
  DEFAULT_JIRA_CURRENT_FORM_VALUES,
  DEFAULT_JIRA_SYNCHRONIZATION_FORM_VALUES,
} from '../../config/constants';
import {
  getJiraSyncFilter,
  getJiraSyncFilterForCurrentOptions,
} from '../../model/selectors';
import { JiraIntegrationTabType } from '../../model/types';
import {
  getJiraTicketTypesForCurrentOptions,
  getPreparedDataForAddNewTicketType,
  getPreparedDataForEditIntegration,
  getPreparedDataForEditSync,
  getPreparedPriorities,
  getStatusesByOrder,
} from '../utils';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useJiraIntegrationsCurrent = () => {
  const dispatch = useDispatch();

  const systemId = useSelector(getCurrentSystemId);
  const jiraIntegrationId = useSelector(getCurrentJiraIntegrationId);
  const loadingInfo = useSelector(getLoadingJiraIntegrationInfo);
  const jiraIntegration = useSelector(getJiraIntegration);
  const jiraTicketTypeByIntegrationId = useSelector(
    getJiraTicketTypeByIntegrationId
  );
  const systemPriorities = useSelector(getSystemPriorities);
  const jiraPrioritiesCurrent = useSelector(getJiraPrioritiesForCurrent);
  const jiraPrioritiesForEdit = useSelector(getJiraPrioritiesForEdit);
  const jiraPrioritiesForEditOptions = useSelector(() =>
    getJiraPrioritiesOptions(jiraPrioritiesForEdit)
  );
  const jiraPrioritiesForCreate = useSelector(getJiraPrioritiesForCreate);
  const jiraPrioritiesForCreateOptions = useSelector(() =>
    getJiraPrioritiesOptions(jiraPrioritiesForCreate)
  );
  const jiraTicketTypesForCurrent = useSelector(getJiraTicketTypesForCurrent);
  const jiraTicketTypesForCurrentOptions = useSelector(() =>
    getJiraTicketTypesForCurrentOptions(jiraTicketTypesForCurrent)
  );
  const jiraStatusesCurrent = useSelector(getJiraStatusesBySystemId);
  const jiraStatusesCurrentOptions = useSelector(() =>
    getJiraStatusesOptions(jiraStatusesCurrent)
  );
  const statusMapOptions = useSelector(getSpStatusesForCurrent);
  const fieldsIntegrations = useSelector(getFieldsIntegrations);
  const integratedTicketTypesOptions = useSelector(
    getIntegratedTicketTypesOptions
  );
  const currentTicketTypesOptions = useSelector(getCreateTicketTypesOptions);
  const statusesByOrder = getStatusesByOrder({
    statusMapOptions,
    jiraStatuses: jiraStatusesCurrent,
  });
  const jiraStatusesEdit = useSelector(getJiraStatusesByPropertyId);
  const jiraStatusesEditOptions = useSelector(() =>
    getJiraStatusesOptions(jiraStatusesEdit)
  );

  const jiraSyncFilter = useSelector(getJiraSyncFilter);
  const jiraSyncFilterCurrent = useSelector(getJiraSyncFilterForCurrentOptions);

  const syncTicketTypesOptions = useSelector(getSyncTicketTypesOptions);
  const syncPrioritiesOptions = useSelector(getSyncPrioritiesOptions);
  const syncEnvironmentsOptions = useSelector(getSyncEnvironmentsOptions);
  const syncStatusesOptions = useSelector(getEditSyncStatusesOptions);
  const syncSpecialistsOptions = useSelector(getSyncSpecialistsOptions);
  const syncClientsOptions = useSelector(getSyncClientsOptions);

  const filterRulesMap: FilterRulesValue = {
    TICKET_TYPE: syncTicketTypesOptions,
    TICKET_PRIORITY: syncPrioritiesOptions,
    TICKET_ENVIRONMENT: syncEnvironmentsOptions,
    TICKET_STATUS: syncStatusesOptions,
    TICKET_SPECIALIST: syncSpecialistsOptions,
    TICKET_CLIENT: syncClientsOptions,
  };

  const [isAddDisabled, setIsAddDisabled] = useState<boolean>(false);
  const [alertIsOpen, setAlertIsOpen] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [statuses, setStatuses] = useState<JiraStatus[]>([]);
  const [priorities, setPriorities] = useState<JiraPriority[]>([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isIntegrated, setIsIntegrated] = useState(false);
  const [tab, setTab] = useState<JiraIntegrationTabType>(
    JiraIntegrationTabType.integration
  );

  const isIntegrationTab = tab === JiraIntegrationTabType.integration;

  const jiraStatuses = isEditMode ? jiraStatusesEdit : jiraStatusesCurrent;

  const jiraStatusesOptions = isEditMode
    ? jiraStatusesEditOptions
    : jiraStatusesCurrentOptions;

  const ticketTypesOptions = isEditMode
    ? currentTicketTypesOptions
    : integratedTicketTypesOptions;

  const {
    control,
    formState: { dirtyFields },
    watch,
    resetField,
    handleSubmit,
    setValue,
  } = useForm<JiraIntegrationEditData>({
    mode: 'onChange',
    defaultValues: DEFAULT_JIRA_CURRENT_FORM_VALUES,
  });

  const syncDefaultValues = useMemo(
    () =>
      isEditMode
        ? jiraSyncFilterCurrent
        : DEFAULT_JIRA_SYNCHRONIZATION_FORM_VALUES,
    [jiraSyncFilter?.id]
  );

  const {
    control: syncControl,
    formState: { isDirty: syncIsDirty },
    watch: syncWatch,
    getValues: syncGetValues,
    reset: syncReset,
    handleSubmit: syncHandleSubmit,
  } = useForm<JiraSynchronizationCreateForm>({
    mode: 'onChange',
    defaultValues: syncDefaultValues,
  });

  const { ticketTypeId, jiraTicketType } = watch();
  const syncValue = syncWatch();

  const typeId = getValueFromValueType(ticketTypeId);

  const intergratedJiraTicketType = jiraTicketTypeByIntegrationId.filter(
    (item) => item.ticketType?.id === typeId
  );
  const isIntegratedCurrent = !!intergratedJiraTicketType.length;
  const equalJiraTicketType =
    getValueFromValueType(jiraTicketType) ===
    intergratedJiraTicketType[0]?.idType;

  const jiraTicketTypeOptions = getJiraTicketTypesForCurrentOptions(
    intergratedJiraTicketType
  );

  const addNewTicketType = isEditMode && !isIntegrated;

  const jiraPrioritiesOptions = addNewTicketType
    ? jiraPrioritiesForCreateOptions
    : jiraPrioritiesForEditOptions;

  const jiraPriorities = addNewTicketType
    ? jiraPrioritiesForCreate
    : jiraPrioritiesForEdit;

  const statusesIsEmpty = !statuses.every((status) => status?.idStatus);
  const prioritiesIsEmpty = !priorities.every(
    (priority) => priority?.idPriority
  );

  const getStatusesDisabled = () => {
    if (!addNewTicketType) {
      return isEqual(statusesByOrder, statuses) || statusesIsEmpty;
    }

    if (addNewTicketType) {
      return statusesIsEmpty;
    }

    return false;
  };

  const getPrioritiesDisabled = () => {
    if (!addNewTicketType) {
      return isEqual(getPreparedPriorities(jiraPrioritiesCurrent), priorities);
    }

    if (addNewTicketType) {
      return (
        systemPriorities?.length !== priorities.length || prioritiesIsEmpty
      );
    }

    return false;
  };

  const statusesDisabled = getStatusesDisabled();
  const prioritiesDisabled = getPrioritiesDisabled();

  const jiraTicketTypeDisabled = !dirtyFields.jiraTicketType;

  const integrationDisabledSubmit = addNewTicketType
    ? statusesDisabled || prioritiesDisabled || jiraTicketTypeDisabled
    : (statusesDisabled && prioritiesDisabled && jiraTicketTypeDisabled) ||
      statusesIsEmpty ||
      prioritiesIsEmpty;

  const syncDisabledSubmit = !syncIsDirty;

  const getIsPriorityAvailable = useCallback(
    (priority?: Priority[]) => {
      if (!priority) {
        return false;
      }

      return !!systemPriorities?.find((el) => el.value === priority[0]);
    },
    [systemPriorities]
  );

  const toggleTab = (activeKey: JiraIntegrationTabType) => {
    setTab(activeKey);
  };

  const togglePopover = () => {
    setIsPopoverOpen((prev) => !prev);
  };

  const onCloseAlert = () => {
    setAlertIsOpen(false);
  };

  const syncSetValueHandler = () => {
    if (jiraSyncFilterCurrent) {
      syncReset(jiraSyncFilterCurrent);
    }
  };

  const resetStatuses = () => {
    setStatuses((prevState) =>
      prevState.map(({ ticketStatusIds }) => ({
        ticketStatusIds,
      }))
    );
  };

  const resetPriorities = () => {
    setPriorities((prevState) =>
      prevState.map(({ ticketPriorities }) => ({
        ticketPriorities,
      }))
    );
  };

  const resetState = () => {
    resetStatuses();
    resetPriorities();
  };

  const toggleIsEditMode = () => {
    setIsEditMode((prev) => !prev);
  };

  const toggleIntegrationEditMode = () => {
    toggleIsEditMode();
    resetField('ticketTypeId');
    setIsPopoverOpen(false);
    resetState();
  };

  const toggleSyncEditMode = () => {
    toggleIsEditMode();
    syncSetValueHandler();
    setIsPopoverOpen(false);
  };

  const dropContentHandler = () => {
    if (isIntegrationTab) {
      toggleIntegrationEditMode();
      return;
    }
    toggleIsEditMode();
  };

  const setTypeId = (value?: string) => {
    dispatch(setCurrentTypeId(value));
  };

  const fetchJiraStatuses = () => {
    if (systemId && typeId) {
      dispatch(fetchJiraStatusesById({ systemId, typeId }));
      resetField('jiraTicketType');
    }
  };

  const onChangeStatuses = useCallback(
    (id: string) => (value: ValueType<number>) => {
      setStatuses((prevState) => {
        const result = jiraStatuses?.find(
          (item) => item.idStatus === getValueFromValueType(value)
        );
        const existingTicketStatusId = prevState?.find((item) =>
          item?.ticketStatusIds?.find((el) => el.id === id)
        );

        return prevState.map((status) => {
          const idMatched = status?.ticketStatusIds?.find((el) => el.id === id);

          if (idMatched) {
            return {
              ...result,
              ticketStatusIds: existingTicketStatusId?.ticketStatusIds,
            };
          }
          return status;
        });
      });
    },
    [setStatuses, jiraStatuses]
  );

  const onChangePriorities = useCallback(
    (priority?: Priority[]) => (value: ValueType) => {
      if (priority) {
        setPriorities((prevState) => {
          const result = jiraPriorities?.find(
            (item) => item.idPriority === getValueFromValueType(value)
          );

          const existingTicketPriorities = prevState?.find((item) =>
            item?.ticketPriorities?.find((el) => el.includes(priority[0]))
          );

          return prevState.map((item) => {
            const priorityMatched = item?.ticketPriorities?.find((el) =>
              el.includes(priority[0])
            );

            if (priorityMatched) {
              return {
                ...result,
                ticketPriorities: existingTicketPriorities?.ticketPriorities,
              };
            }
            return item;
          });
        });
      }
    },
    [setPriorities, jiraPriorities]
  );

  const onChangePrioritiesAddNewTicketType = useCallback(
    (priority?: Priority[]) => (value: ValueType) => {
      if (priority) {
        setPriorities((prevState) => {
          const title = getTitleFromValueType(value) || '';
          const result = jiraPriorities?.find((item) => item.name === title);
          const existingPriority = !!prevState?.find((item) =>
            item?.ticketPriorities?.includes(priority[0])
          );

          if (!existingPriority) {
            return [...prevState, { ...result, ticketPriorities: priority }];
          }

          return prevState.map((item) => {
            if (item?.ticketPriorities?.includes(priority[0])) {
              return { ...result, ticketPriorities: priority };
            }
            return item;
          });
        });
      }
    },
    [setPriorities, jiraPriorities]
  );

  const fetchInfoForCurrentJira = (id?: string) => {
    if (systemId && jiraIntegrationId && id) {
      dispatch(
        fetchInfoForJiraIntegration({
          systemId,
          jiraIntegrationId,
          typeId: id,
        })
      );
      dispatch(fetchSpFieldsRequest({ systemId, typeId: id }));
      dispatch(fetchJiraFieldsRequest({ systemId, typeId: id }));
      dispatch(
        fetchJiraPrioritiesByIntegrationIdRequest({ systemId, typeId: id })
      );
    }
  };

  const fetchInfoForEditJira = (id?: string) => {
    if (systemId && id) {
      dispatch(fetchJiraStatusesById({ systemId, typeId: id }));
      dispatch(fetchSPStatusesForCurrentRequest({ systemId, typeId: id }));
      dispatch(fetchJiraPrioritiesById({ systemId, typeId: id }));
      dispatch(
        fetchJiraPrioritiesRequest({
          login: jiraIntegration?.login,
          jiraUrl: jiraIntegration?.jiraUrl,
          projectKey: jiraIntegration?.projectKey,
        })
      );
    }
  };

  const fetchJiraStatusesByPropertyId = (value: string) => {
    if (jiraIntegrationId) {
      dispatch(
        fetchJiraStatusesByPropertyIdRequest({
          issueTypeId: value,
          propertyId: jiraIntegrationId,
        })
      );
    }
  };

  const onChangeTicketTypeId = (value: ValueType<string>) => {
    const id = getValueFromValueType(value);

    setTypeId(id);
    resetState();

    const integrated = !!jiraTicketTypeByIntegrationId.filter(
      (item) => item.ticketType?.id === id
    ).length;

    if (!integrated) {
      fetchInfoForEditJira(id);
      resetField('jiraTicketType');
    }

    if (integrated && isEditMode) {
      fetchInfoForCurrentJira(id);
    }

    if (isEditMode) {
      setIsIntegrated(integrated);
    }
  };

  useEffect(() => {
    if (jiraTicketType && !addNewTicketType) {
      fetchJiraStatusesByPropertyId(
        getValueFromValueType(jiraTicketType)?.toString() ?? ''
      );
    }
  }, [jiraTicketType, addNewTicketType]);

  useEffect(() => {
    if (!isEditMode) {
      fetchInfoForCurrentJira(typeId);
    }
  }, [systemId, jiraIntegrationId, typeId]);

  useEffect(() => {
    if (systemId) {
      dispatch(fetchSystemPrioritiesRequest(systemId));
    }
  }, [systemId]);

  useEffect(() => {
    if (jiraIntegrationId) {
      dispatch(fetchJiraTicketTypesByIntegrationIdRequest(jiraIntegrationId));
      dispatch(fetchJiraTicketTypeByIntegrationIdRequest(jiraIntegrationId));
    }
    setAlertIsOpen(true);
    setIsEditMode(false);
    resetField('ticketTypeId');

    setTab(JiraIntegrationTabType.integration);
    syncReset();
  }, [jiraIntegrationId]);

  useEffect(() => {
    if (jiraPrioritiesCurrent && isIntegratedCurrent) {
      setPriorities(getPreparedPriorities(jiraPrioritiesCurrent));
      setValue('jiraTicketType', jiraTicketTypeOptions[0]);
    }
  }, [jiraPrioritiesCurrent, isIntegratedCurrent]);

  useEffect(() => {
    syncSetValueHandler();
  }, [jiraSyncFilter?.id]);

  useEffect(() => {
    if (equalJiraTicketType) {
      setStatuses(statusesByOrder);
    }
  }, [statusMapOptions, jiraStatusesCurrent, equalJiraTicketType]);

  const editJiraIntegrationHandler = handleSubmit((data) => {
    const prepareData = getPreparedDataForEditIntegration({
      data,
      statuses,
      priorities,
      jiraTicketTypes: jiraTicketTypesForCurrent,
      jiraIntegration,
      systemId,
      jiraTicketTypeDisabled,
    });

    dispatch(editJiraIntegration(prepareData));
    setIsEditMode(false);
    setIsPopoverOpen(false);
  });

  const addNewTicketTypeHandler = handleSubmit((data) => {
    const id = getValueFromValueType(data.ticketTypeId) ?? '';

    const prepareData = getPreparedDataForAddNewTicketType({
      data,
      statuses,
      priorities,
    });

    dispatch(
      addNewTicketTypeRequest({
        jiraIntegrationId: jiraIntegration?.id ?? '',
        systemId: systemId ?? '',
        ticketTypeId: id,
        ...prepareData,
      })
    );
    setIsEditMode(false);
    setIsPopoverOpen(false);
    resetField('ticketTypeId');
  });

  const submitHandler = () => {
    if (isIntegrated) {
      editJiraIntegrationHandler();
      return;
    }

    addNewTicketTypeHandler();
  };

  const editJiraSynchronizationHandler = syncHandleSubmit((data) => {
    const prepareData = getPreparedDataForEditSync({
      jiraSyncFilter,
      data,
      filterRulesMap,
    });

    dispatch(editJiraSync(prepareData));
    setIsEditMode(false);
    setIsPopoverOpen(false);
  });

  return {
    state: {
      jiraIntegrationId,
      isAddDisabled,
      alertIsOpen,
      systemId,
      isEditMode,
      loadingInfo,
      control,
      ticketTypeId,
      jiraPrioritiesOptions,
      priorities,
      jiraTicketTypesForCurrentOptions,
      jiraStatusesOptions,
      statusMapOptions,
      statuses,
      integrationDisabledSubmit,
      syncDisabledSubmit,
      fieldsIntegrations,
      ticketTypesOptions,
      tab,
      synchronizationProps: {
        syncTicketTypesOptions,
        syncPrioritiesOptions,
        syncEnvironmentsOptions,
        syncStatusesOptions,
        syncSpecialistsOptions,
        syncClientsOptions,
        control: syncControl,
        getValues: syncGetValues,
        autoCreateTasks: syncValue.autoCreateTasks,
        considerFilters: syncValue.considerFilters,
      },
      isPopoverOpen,
      jiraTicketType,
      addNewTicketType,
      isIntegrated,
    },
    methods: {
      setIsAddDisabled,
      onCloseAlert,
      toggleIsEditMode,
      submitHandler,
      getIsPriorityAvailable,
      onChangePriorities: addNewTicketType
        ? onChangePrioritiesAddNewTicketType
        : onChangePriorities,
      onChangeStatuses,
      toggleIntegrationEditMode,
      toggleSyncEditMode,
      editJiraSynchronizationHandler,
      togglePopover,
      toggleTab,
      dropContentHandler,
      setIsPopoverOpen,
      fetchJiraStatuses,
      setValue,
      fetchJiraStatusesByPropertyId,
      resetStatuses,
      onChangeTicketTypeId,
    },
  };
};
