import { getUrl } from '@shared';

export const createTicketEndpoints = {
  createTicket: () => getUrl('ticket'),
  getEnvironments: () => getUrl('environment/filter'),
  getTicketTypes: (systemId: string) =>
    getUrl(`type/system/${systemId}/authUser`),
  getTickets: () => getUrl('ticket/filter'),
  getRequiredCustomFieldBySystemId: (systemId: string) =>
    getUrl(`custom-field/system/${systemId}/required`),
};
