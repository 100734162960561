import { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomFieldType } from '@entities/custom-fields';
import {
  createTicketsSettingFieldsRequest,
  getIsSettingFieldsOpen,
  getSettingFields,
  openSettingFields,
} from '@entities/ticket';
import { useSettingFieldList } from '@shared';

export const useTicketsSettingFieldsList = () => {
  const dispatch = useDispatch();

  const { defaultColumns, customColumns } = useSelector(getSettingFields) || {};

  const isSettingFieldsOpen = useSelector(getIsSettingFieldsOpen);

  const {
    dragFieldsStartHandler: dragDefaultFieldsStartHandler,
    dropFieldsHandler: dropDefaultFieldsHandler,
    checkedAllFields: checkedAllDefaultFields,
    onChangeFieldsList: onChangeDefaultFieldsList,
    fieldsList: defaultFieldsList,
    setFieldsList: setDefaultFieldsList,
  } = useSettingFieldList<CustomFieldType>({
    settingFields: defaultColumns,
    disabledField: 'Номер тикета',
  });

  const {
    dragFieldsStartHandler: dragCustomFieldsStartHandler,
    dropFieldsHandler: dropCustomFieldsHandler,
    checkedAllFields: checkedAllCustomFields,
    onChangeFieldsList: onChangeCustomFieldsList,
    fieldsList: customFieldsList,
    setFieldsList: setCustomFieldsList,
  } = useSettingFieldList<CustomFieldType>({
    settingFields: customColumns,
  });

  const formSubmitHandler = (event: MouseEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(
      createTicketsSettingFieldsRequest({
        defaultColumns: defaultFieldsList || [],
        customColumns: customFieldsList || [],
      })
    );
    dispatch(openSettingFields(false));
  };

  const toggleSettingFieldsModal = () => {
    dispatch(openSettingFields(!isSettingFieldsOpen));
  };

  return {
    defaultFieldsList,
    setDefaultFieldsList,
    dragDefaultFieldsStartHandler,
    dropDefaultFieldsHandler,
    checkedAllDefaultFields,
    onChangeDefaultFieldsList,
    customFieldsList,
    setCustomFieldsList,
    dragCustomFieldsStartHandler,
    dropCustomFieldsHandler,
    checkedAllCustomFields,
    onChangeCustomFieldsList,
    formSubmitHandler,
    isSettingFieldsOpen,
    toggleSettingFieldsModal,
  };
};
