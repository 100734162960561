import { SelectOption } from '@shared';

import {
  ContractStatus,
  ContractType,
  SupportType,
} from '../model/contract/types';
import { ContractsFilter } from '../model/contracts/types';

export const SECONDARY_CONTRACT_TYPE = {
  title: 'Вторичный',
  value: ContractType.SECONDARY,
};

export const SELECT_TYPES_LIST: SelectOption<ContractType>[] = [
  { title: 'Первичный', value: ContractType.PRIMARY },
  SECONDARY_CONTRACT_TYPE,
];

export const CONTRACT_STATUS_TITLE_MAP = {
  [ContractStatus.ACTIVE]: 'Активен',
  [ContractStatus.CLOSED]: 'Завершен',
  [ContractStatus.PLANNED]: 'Запланирован',
  [ContractStatus.NOT_SIGNED]: 'Не подписан',
};

export const CONTRACT_TYPE_TITLE_MAP = {
  [ContractType.PRIMARY]: 'Первичный',
  [ContractType.SECONDARY]: 'Вторичный',
  [ContractType.SUPPLEMENTARY_AGREEMENT]: 'Дополнительное соглашение',
};

export const SUPPORT_RADIO_TABS = [
  {
    title: 'Расширенная',
    value: SupportType.EXTENDED,
    description: `При переходе в статус «Запрос информации» таймер SLA ставится на 
        паузу и возобновляется, когда клиент возвращает тикет в статус «В работе».`,
  },
  {
    title: 'Лицензионная',
    value: SupportType.LICENSED,
    description: `При переходе в статус «Запрос информации» таймер SLA сбрасывается.`,
  },
];

export const DEFAULT_FILTER: ContractsFilter = {
  openDate: new Date('1995-12-17'),
  endDate: '',
  title: '',
  description: '',
  organizationId: null,
  type: null,
  status: null,
  number: '',
};
